import { IRoute } from "../../../@types/routes";
import { FreeEvFinder } from "../Electrify/pages/OpenEvFinder";
import { PaymentFeedbackEntry } from "../Electrify/pages/Payments/Feedback";
import { Checklist } from "./CheckList";
import { ElectrifyTeaser } from "./ElectrifyTeaser";



export const ElectrificationPlannerRoutes: IRoute[] = [

    {
        path: '/',
        component: Checklist,
    },
    {
        path: '/ev-finder',
        component: FreeEvFinder,
    },
    {
        path: '/electrify-teaser',
        component: ElectrifyTeaser
    },
    {
        path: '/subscriptions/payments/success',
        component: PaymentFeedbackEntry,
    },
]