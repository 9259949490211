import { ReactElement, useMemo } from "react";
import { ReactComponent as ArrowRight } from "../../../../../../assets/arrow_right_strong.svg";
import { ReactComponent as CalendarIcon } from "../../../../../../assets/calendar-icon-white.svg";
import { useTranslation } from "react-i18next";
import { useLayout } from "../../../../../../hooks/state/useLayout";

export function dateDifferenceInDays(
  minDate?: string | null,
  maxDate?: string | null
) {
  if (!minDate || !maxDate) return 0;

  var date1 = new Date(minDate);
  var date2 = new Date(maxDate);
  var differenceInMiliseconds = date2.getTime() - date1.getTime();
  if (!differenceInMiliseconds) return 0;
  return differenceInMiliseconds / (1000 * 3600 * 24);
}

export function DateDisplayer({
  minDate,
  maxDate,
  color,
}: {
  minDate?: string | null;
  maxDate?: string | null;
  color?: string;
}) {
  const { localeCode } = useLayout();
  const { t } = useTranslation("driverOverview");
  const dateDiff = dateDifferenceInDays(minDate, maxDate);

  return (
    <div className="flex items-center ml-4 mt-2">
      <div
        className={`px-1 mr-1 rounded ${
          color ? `${color} text-white` : "text-Black bg-Grey-tint"
        }`}
      >
        {minDate && new Date(minDate).toLocaleString(localeCode).split(",")[0]}
      </div>
      <ArrowRight />
      <div
        className={`px-1 ml-1 rounded ${
          color ? `${color} text-white` : "text-Black bg-Grey-tint"
        }`}
      >
        {maxDate && new Date(maxDate).toLocaleString(localeCode).split(",")[0]}
      </div>
      <div className="ml-2">
        {dateDiff?.toLocaleString(localeCode)} {t("tracking-data.period-days")}
      </div>
    </div>
  );
}

function AnalysisPeriodBar({
  minAnalysisPeriod,
  maxAnalysisPeriod,
  validPeriod,
  diff,
}: {
  minAnalysisPeriod?: string | null;
  maxAnalysisPeriod?: string | null;
  validPeriod?: { min?: string; max?: string } | null;
  diff: number;
}) {
  const startingPoint = Math.round(
    (dateDifferenceInDays(validPeriod?.min, minAnalysisPeriod) / diff) * 100
  );
  const barWidth = Math.round(
    (dateDifferenceInDays(minAnalysisPeriod, maxAnalysisPeriod) / diff) * 100
  );

  return (
    <div className="mt-6 mr-10 relative">
      <div className="h-2 w-full bg-Grey-tint rounded" />
      <div
        style={{ left: `${startingPoint}%` }}
        className="flex absolute top-0 w-full"
      >
        <div className="h-4 w-0.5 bg-Blueberry-dark-default mt-[-4px]" />
        <div
          style={{ width: `${barWidth}%` }}
          className=" bg-Blueberry-dark-default h-2"
        />
        <div className="h-4 w-0.5 bg-Blueberry-dark-default mt-[-4px]" />
      </div>
    </div>
  );
}

export function IconData({
  icon,
  label,
  value,
  color,
}: {
  icon: ReactElement;
  label: string;
  value: string | ReactElement;
  color?: string;
}) {
  return (
    <div className="flex">
      <div
        className={`p-4 ${
          color ? color : "bg-Blueberry-dark-default"
        } w-14 flex items-center justify-center rounded`}
      >
        {icon}
      </div>
      <div className="ml-4">
        <div className="text-Grey-dark text-xs font-bold">{label}</div>
        <div className="font-extrabold text-3xl">{value}</div>
      </div>
    </div>
  );
}

export function AnalysisPeriod({
  analysisPeriod,
  validPeriod,
}: {
  analysisPeriod?: { min?: string | null; max?: string | null } | null;
  validPeriod?: { min?: string; max?: string } | null;
}) {
  const { localeCode } = useLayout();
  const { t } = useTranslation("driverOverview");

  const diff = dateDifferenceInDays(validPeriod?.min, validPeriod?.max);

  const minAnalysisPeriod =
    analysisPeriod?.min === null ? validPeriod?.min : analysisPeriod?.min;
  const maxAnalysisPeriod =
    analysisPeriod?.max === null ? validPeriod?.max : analysisPeriod?.max;

  const displayPeriodBar = useMemo(() => {
    const atLeastOneAnalysisDateNotNull =
      analysisPeriod?.min || analysisPeriod?.max;
    const atleastOneAnalysisDateIsNotEqualToValidity = !(
      (analysisPeriod?.min == validPeriod?.min ||
        analysisPeriod?.min == null) &&
      (analysisPeriod?.max == validPeriod?.max || analysisPeriod?.max == null)
    );

    return (
      atLeastOneAnalysisDateNotNull &&
      atleastOneAnalysisDateIsNotEqualToValidity
    );
  }, [
    analysisPeriod?.max,
    analysisPeriod?.min,
    validPeriod?.min,
    validPeriod?.max,
  ]);

  return (
    <>
      {displayPeriodBar ? (
        <div className="mb-10">
          <div className="flex items-center">
            <div className="h-2 w-2 bg-Blueberry-dark-default rounded-sm mr-2" />
            <h1 className="font-bold">{t("tracking-data.analysis-period")}</h1>
          </div>
          <DateDisplayer
            minDate={minAnalysisPeriod}
            maxDate={maxAnalysisPeriod}
            color="bg-Blueberry-dark-default"
          />
          <AnalysisPeriodBar
            minAnalysisPeriod={minAnalysisPeriod}
            maxAnalysisPeriod={maxAnalysisPeriod}
            validPeriod={validPeriod}
            diff={diff}
          />
        </div>
      ) : (
        <IconData
          value={diff.toLocaleString(localeCode)}
          label={t("tracking-data.days")}
          icon={<CalendarIcon />}
        />
      )}
    </>
  );
}
