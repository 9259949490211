
import { BillingInfo } from "./BillingInfo";
import { AddressForm } from "../AddressForm";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonVariant } from "../../../../../../../../../electrify_frontend_common/components/Buttonv2/types";


export function AddressWithShipping({ paymentAddressProps }: { paymentAddressProps: any }) {

    const {
        loadingPaymentIntent,
        shippingAddress,
        setShippingAddress,
        sameBillingAddress,
        setSameBillingAddress,
        billingAddress,
        setBillingAddress,
        submitWithShipping,
        shippingErrors,
        billingErrors,
        resetBillingError,
        resetShippingError,
    } = paymentAddressProps;

    const { t } = useTranslation("driverPaymentSystem");

    return (
        <>
            <div className="mb-6 flex flex-col w-1/2">
                <div className="mb-3 font-bold">{t("address-form.shipping-address")}</div>
                <AddressForm
                    onChange={setShippingAddress}
                    value={shippingAddress}
                    errors={shippingErrors}
                    resetErrors={resetShippingError}
                />
            </div>

            <BillingInfo
                sameBillingAddress={sameBillingAddress}
                billingAddress={billingAddress}
                setSameBillingAddress={setSameBillingAddress}
                setBillingAddress={setBillingAddress}
                billingErrors={billingErrors}
                resetBillingError={resetBillingError}
            />

            <Button
                type="button"
                variant={ButtonVariant.PRIMARY}
                className='mt-10 w-1/2'
                onClick={() => {
                    submitWithShipping()
                }}>{t("submit")}</Button>
        </>
    )


}