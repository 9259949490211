


export function VehicleIcon({ color = "black" }: { color?: string }) {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_666_873)">
                <path d="M20.125 16.1665C20.4565 16.1665 20.7745 16.0348 21.0089 15.8004C21.2433 15.566 21.375 15.248 21.375 14.9165V13.0407C21.3747 12.6409 21.2159 12.2576 20.9333 11.9748L18.875 9.9165H5.125L3.06667 11.9748C2.78409 12.2576 2.62525 12.6409 2.625 13.0407V14.9165C2.625 15.248 2.7567 15.566 2.99112 15.8004C3.10719 15.9165 3.24499 16.0085 3.39665 16.0714C3.5483 16.1342 3.71085 16.1665 3.875 16.1665H20.125Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.76 12.4165H5.8575C6.18879 12.4166 6.5065 12.5482 6.74083 12.7823L7.625 13.6665" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M21.24 12.4165H18.1425C17.8112 12.4166 17.4935 12.5482 17.2592 12.7823L16.375 13.6665" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.125 9.91651L6.54167 6.13317C6.67569 5.77542 6.91585 5.46717 7.22996 5.24972C7.54407 5.03227 7.91713 4.91601 8.29917 4.91651H15.7008C16.0824 4.91652 16.455 5.03302 16.7686 5.25043C17.0822 5.46785 17.322 5.77582 17.4558 6.13317L18.8725 9.91651H5.125Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M5.125 9.9165L3.875 8.6665H2.625" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18.875 9.9165L20.125 8.6665H21.375" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M3.875 16.1665V17.4165C3.875 17.748 4.0067 18.066 4.24112 18.3004C4.47554 18.5348 4.79348 18.6665 5.125 18.6665C5.45652 18.6665 5.77446 18.5348 6.00888 18.3004C6.2433 18.066 6.375 17.748 6.375 17.4165V16.1665H3.875Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20.125 16.1665V17.4165C20.125 17.748 19.9933 18.066 19.7589 18.3004C19.5245 18.5348 19.2065 18.6665 18.875 18.6665C18.5435 18.6665 18.2255 18.5348 17.9911 18.3004C17.7567 18.066 17.625 17.748 17.625 17.4165V16.1665H20.125Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_666_873">
                    <rect width="20" height="20" fill="white" transform="translate(2 2)" />
                </clipPath>
            </defs>
        </svg>

    )

}