import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Alert, AlertTitle } from '@mui/material';
import { forwardRef, ChangeEvent, Ref } from 'react';


interface InputProps {
  disabled?: boolean;
  error?: boolean;
  icon?: any;
  customIcon?: JSX.Element;
  label?: string;
  value?: string | number;
  unit?: string;
  className?: string;
  Validationerror?: {
    path: string;
    message: string;
  }[];
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  type?: string;
  placeholder?: string;
  min?: string;
  max?: string;
  format?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      disabled,
      error,
      icon,
      customIcon,
      label,
      value,
      unit,
      className,
      Validationerror,
      ...props
    },
    ref: Ref<HTMLInputElement>
  ) => {
    return (
      <>
        <div className={'flex flex-col w-full mt-1 ' + className}>
          <label
            htmlFor={props.name}
            className='block text-h5 text-secondary-600 font-normal pl-2 truncate'
          >
            {label}
          </label>
          <div className='flex bg-transparent border border-secondary-200 rounded'>
            {icon ? (
              <span className='inline-flex items-center px-3 pr-0 text-secondary-300 text-sm'>
                <FontAwesomeIcon icon={icon} />
              </span>
            ) : null}
            {customIcon ? (
              <span className='inline-flex items-center px-1.5 text-secondary-300 text-sm'>
                {customIcon}
              </span>
            ) : null}

            {unit ? (
              <span className='inline-flex items-center px-3 text-secondary-500 text-sm'>
                {unit}
              </span>
            ) : null}

            <input
              {...props}
              ref={ref}
              value={value || ''}
              type={props.type || 'text'}
              onChange={props.onChange}
              name={props.name}
              className={`selectSingle selectMultiple flex-1 block w-full p-2 pl-2 bg-transparent  ${
                disabled
                  ? ' cursor-not-allowed bg-secondary-100 border-secondary-200 text-gray-900'
                  : ''
              } ${
                error
                  ? 'border-danger-500 text-red-danger focus:border-info-500 focus:ring-info-500'
                  : 'focus:border-secondary-400 focus:ring-0'
              } sm:text-sm`}
              placeholder={props.placeholder}
              disabled={disabled}
              min={props?.type === 'date' ? props.min : undefined}
              max={props?.type === 'date' ? props.max : undefined}
            />
          </div>
          {Validationerror && Validationerror[0]?.path === 'Price' && (
            <Alert severity='error'>
              <AlertTitle>Error</AlertTitle>
              {Validationerror[0]?.message}
            </Alert>
          )}
        </div>
      </>
    );
  }
);

export default Input;
