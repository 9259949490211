import { useState } from "react";
import { useTranslation } from "react-i18next";
import ProviderDataUpload from "./steps/ProviderDataUpload/DataUpload";
import DataAvailability from "./steps/DataAvailability";
import NoTrackingDataAvailable from "./steps/NoTrackingDataAvailable";
import TrackingDataAvailable from "./steps/TrackingDataAvailable";
import WebfleetIcon from "../../../../../assets/webfleet-logo-simple.svg";
import ModalWrapper from "../../../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../../../electrify_frontend_common/components/ModalWrapper/Headers";


export default function OnboardingModal({ startingStep, onCancel, goToPageProps, isOpen }: { startingStep: number, onCancel: () => void, goToPageProps: { action: () => void, text: string }, isOpen: boolean }) {
    const { t } = useTranslation("onboarding");
    const { t: tDpu } = useTranslation("dataProviderUpload");

    const [step, setStep] = useState(startingStep ? startingStep : 0);

    const [trackingDataAvailable, setTrackingDataAvailable] = useState(false);


    const steps = [
        {
            title: t("header-title"),
            message: t("header-description"),
            component: (
                <DataAvailability
                    hasTrackingDataSelect={() => {
                        setTrackingDataAvailable(true);
                        setStep(1);
                    }}
                    noTrackingDataSelect={() => {
                        setTrackingDataAvailable(false);
                        setStep(1);
                    }}
                />
            ),
            isLastItem: true,
            onBack: () => onCancel(),
        },
        {
            title: trackingDataAvailable
                ? t("tracking-2-title")
                : t("not-tracking-2-title"),
            message: t("header-description"),
            component: trackingDataAvailable ? (
                <TrackingDataAvailable
                    nextStep={() => setStep(2)}
                />
            ) : (
                <NoTrackingDataAvailable />
            ),
            onBack: () => setStep(0),
        },
        {
            title: tDpu("header-title"),
            message: tDpu("header-description"),
            icon: WebfleetIcon,
            component: <ProviderDataUpload onCancel={onCancel} />,
            onBack: () => setStep(1),
        },
    ];

    return (

        <ModalWrapper
            isOpen={isOpen}
            close={onCancel}
            className={"w-4/6"}>
            <>
                <ModalHeader
                    title={steps[step].title}
                    message={steps[step].message}
                    onBack={steps[step].onBack}
                    icon={steps[step].icon}
                    onCancel={onCancel}
                />
                <div className="overflow-auto h-full">{steps[step].component}</div>
            </>
        </ModalWrapper>

    );
}
