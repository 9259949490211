import Dropdown from "../../../../electrify_frontend_common/components/Dropdown";
import { ExpandIcon } from "../../../../electrify_frontend_common/icons/ExpandIcon";


export function VehicleInfoDropDown({
    placeholder,
    label,
    data,
    className,
    disabled,
  } : any) {
    return (
      <div className={`${className}`}>
        <div className="mb-2">
          <label className={"text-Grey-dark"}>{label}</label>
        </div>
        <div
          className={`border border-Grey-tint ${
            disabled ? "bg-Grey-background cursor-default " : "bg-white"
          } solid rounded text-base h-14 `}
        >
          <Dropdown
            disabled={disabled}
            placeholder={placeholder}
            data={data}
            icon={<ExpandIcon />}
          />
        </div>
      </div>
    );
  }
  
  