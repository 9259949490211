import { useEffect} from "react";
import ChartModal from "./ChartModal";

export function ExpandedChartWrapper({
  expandedMode,
  buildExpandedViewContent,
  closeExpanded,
  incrementPage,
  decrementPage,
  numberOfPages,
  page,
  setPage,
} : any) {


  useEffect(() => {
    setPage(expandedMode?.page);
  }, [expandedMode]);

  return (
    <>
      {page !== null ? (
        <ChartModal
          title={buildExpandedViewContent().getTitle()}
          open={expandedMode.open}
          onCancel={() => {
            closeExpanded();
            setPage(null);
          }}
          chart={buildExpandedViewContent().getChart()}
          incrementPage={incrementPage}
          decrementPage={decrementPage}
          numberOfPages={numberOfPages}
          previousChartName={buildExpandedViewContent(page - 1)?.getTitle()}
          nextChartName={buildExpandedViewContent(page + 1)?.getTitle()}
          currentPage={page}
          sortFunction={buildExpandedViewContent().getSortFunction? buildExpandedViewContent().getSortFunction() : null}
          isCrescent={buildExpandedViewContent().getIsCrescent? buildExpandedViewContent().getIsCrescent() : null}
        />
      ) : null}
    </>
  );
}
