
import { ReactComponent as CrossIcon } from "../../../../../../../assets/close-white.svg";
import { ReactComponent as ArrowRight } from "../../../../../../../assets/arrow_right_strong_white.svg";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../../electrify_frontend_common/components/Buttonv2/types";




export function Welcome({
    nextStep,
    close,
  }: {
    nextStep: () => void;
    close: () => void;
  }) {

    const {t} = useTranslation("electrificationPlanner");


    return (
        <div className="flex flex-col bg-electrification-planner-onboarding-background w-[1000px] items-center">
            <div className="flex w-full px-11 py-11 justify-end ">
                <CrossIcon className="cursor-pointer" onClick={close} />
            </div>
            <div className="flex flex-col font-bold text-white w-8/12 items-center">
                <div className="text-[2.5em] text-center mt-28 font-bold">
                    <div>{t("onboarding.welcomeStep.titlePt1")}</div>
                    <div>{t("onboarding.welcomeStep.titlePt2")}</div>
                    
                </div>
                <div className="flex text-center mt-8">{t("onboarding.welcomeStep.message")}</div>
                <div className="mt-8 flex justify-center pb-40">
                    <Button
                        variant={ButtonVariant.PRIMARY}
                        size={ButtonSize.LARGE}
                        onClick={nextStep}
                    >
                        <div className="flex items-center">
                        {t("onboarding.welcomeStep.button")} <ArrowRight className="ml-2" />
                        </div>
                    </Button>
                </div>
            </div>
        </div>
    )



}