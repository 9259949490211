


export function Co2EmissionsIcon({ color = "black", className }: { color?: string, className?: string }) {



    return (
        <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.43181 12.8727C9.43181 12.8727 10.109 14.2582 11.2485 14.1543C13.5585 13.9432 13.404 10.8727 14.2485 9.78823C13.5341 9.43247 12.7457 9.25078 11.9476 9.25802C11.1496 9.26526 10.3646 9.46123 9.65681 9.8299C8.46014 10.506 8.37514 11.6471 9.43181 12.8727V12.8727Z" stroke={color} strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.0683 11.4648C11.0683 11.4648 9.56833 11.6504 8.5 14.2593" stroke={color} strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.5833 7.59375V6.68875C12.5834 6.5793 12.5619 6.47091 12.52 6.36977C12.4782 6.26863 12.4168 6.17673 12.3394 6.09931L11.3333 5.09375H3L1.99389 6.09931C1.91652 6.17673 1.85516 6.26863 1.81331 6.36977C1.77146 6.47091 1.74995 6.5793 1.75 6.68875V8.42708C1.75 8.6481 1.8378 8.86006 1.99408 9.01634C2.15036 9.17262 2.36232 9.26042 2.58333 9.26042H7.58333" stroke={color} strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M11.3333 5.0931H3L3.64333 2.32921C3.6987 2.16335 3.80484 2.01912 3.94672 1.91692C4.0886 1.81472 4.25903 1.75974 4.43389 1.75977H9.89944C10.0743 1.75974 10.2447 1.81472 10.3866 1.91692C10.5285 2.01912 10.6346 2.16335 10.69 2.32921L11.3333 5.0931Z" stroke={color} strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.41797 6.75977H4.2513" stroke={color} strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.082 6.75977H10.9154" stroke={color} strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.2487 10.0931C4.2487 10.3141 4.1609 10.5261 4.00462 10.6824C3.84834 10.8386 3.63638 10.9264 3.41536 10.9264C3.19435 10.9264 2.98239 10.8386 2.82611 10.6824C2.66983 10.5261 2.58203 10.3141 2.58203 10.0931V9.25977H4.2487V10.0931Z" stroke={color} strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )

}