import { useTranslation } from "react-i18next";
import { Dataset, LocationDataset } from "../../@types/analysis";
import { DailyParkingSummary, IFleetAnalysisLocation, IFleetAnalysisLocationResults, ParkingDurationLocationShare } from "../../@types/fleet";
import { sortParkingTypeDataAscending, sortParkingTypeDataDescending } from "./fleetAnalysis";
import { defineColorScheme } from "../../common/components/Charts/ChartColors";
import { subtractOneMonthFromDate } from "../../electrify_frontend_common/utils";

export default function useHandleParkingData() {
    const { t } = useTranslation("parkingAnalysis");

    const threeColorSchemeTransparent = defineColorScheme(3, 0.5)
    const threeColorScheme = defineColorScheme(3)
    const fiveColorScheme = defineColorScheme(5)

    const handleData = (data: IFleetAnalysisLocationResults) => {
        const parkingTypeDatasets: Dataset[] = [
            {
                label: t("parking-type-label-1"),
                data: [],
                backgroundColor: fiveColorScheme[0],
            },
            {
                label: t("parking-type-label-2"),
                data: [],
                backgroundColor: fiveColorScheme[1],
            },
            {
                label: t("parking-type-label-3"),
                data: [],
                backgroundColor: fiveColorScheme[2],
            },
            {
                label: t("parking-type-label-4"),
                data: [],
                backgroundColor: fiveColorScheme[3],
            },
            {
                label: t("parking-type-label-5"),
                data: [],
                backgroundColor: fiveColorScheme[4],
            },
        ];

        let parkingWorkplaceOccupationStaysDatasets: LocationDataset[] = [];

        let parkingWorkplaceOccupationTimesDatasets: LocationDataset[] = [];

        const parkingWorkplaceOccupationMonthDatasets = data?.fleetAnalysisLocations?.map((location: IFleetAnalysisLocation) => ({
            data: [
                {
                    label: t("workplace-vehicles-count-max-vehicles-label-1"),
                    fill: true,
                    backgroundColor: threeColorSchemeTransparent[0],
                    borderColor: threeColorScheme[0],
                    lineTension: 0.1,
                    data: [] as number[],
                },
                {
                    label: t("workplace-vehicles-count-max-vehicles-label-2"),
                    fill: true,
                    backgroundColor: threeColorSchemeTransparent[1],
                    borderColor: threeColorScheme[1],
                    lineTension: 0.1,
                    data: [] as number[],
                },
                {
                    label: t("workplace-vehicles-count-max-vehicles-label-3"),
                    fill: true,
                    backgroundColor: "transparent",
                    borderColor: threeColorScheme[2],
                    lineTension: 0.1,
                    data: [] as number[],
                },
            ],
            locationName: location.locationName,
        }));

        const parkingWorkplaceOccupationYearDatasets = data?.fleetAnalysisLocations?.map((location: any) => ({
            data: [
                {
                    label: t("workplace-vehicles-count-max-vehicles-label-1"),
                    fill: true,
                    backgroundColor: threeColorSchemeTransparent[0],
                    borderColor: threeColorScheme[0],
                    lineTension: 0.1,
                    data: [] as number[],
                },
                {
                    label: t("workplace-vehicles-count-max-vehicles-label-2"),
                    fill: true,
                    backgroundColor: threeColorSchemeTransparent[1],
                    borderColor: threeColorScheme[1],
                    lineTension: 0.1,
                    data: [] as number[],
                },
                {
                    label: t("workplace-vehicles-count-max-vehicles-label-3"),
                    fill: true,
                    backgroundColor: "transparent",
                    borderColor: threeColorScheme[2],
                    lineTension: 0.1,
                    data: [] as number[],
                },
            ],
            locationName: location.locationName,
        }));

        const parkingWorkplaceOccupationMonthLabels: string[] = [];
        const parkingWorkplaceOccupationYearLabels: string[] = [];

        data?.parkingDurationLocationShares?.map((vehicle: ParkingDurationLocationShare) => {
            const vehicleLabel = vehicle.vehicleName;

            parkingTypeDatasets[0].data.push({
                value: vehicle?.workplace,
                label: vehicleLabel,
            });

            parkingTypeDatasets[1].data.push({
                value: vehicle?.home,
                label: vehicleLabel,
            });

            parkingTypeDatasets[2].data.push({
                value: vehicle?.public,
                label: vehicleLabel,
            });

            parkingTypeDatasets[3].data.push({
                value: vehicle?.noCharging,
                label: vehicleLabel,
            });

            parkingTypeDatasets[4].data.push({
                value: vehicle?.unknown,
                label: vehicleLabel,
            });
        }
        );

        data?.fleetAnalysisLocations?.map(
            (location: IFleetAnalysisLocation, i: number) => {
                const locationName = location?.locationName;

                const staysData = location?.staysAndDurationSummary?.stays;
                parkingWorkplaceOccupationStaysDatasets.push({
                    locationName: locationName,
                    data: [
                        staysData?.to1Day,
                        staysData?.from1To2Days,
                        staysData?.from2To3Days,
                        staysData?.from3To4Days,
                        staysData?.from4Days,
                    ],
                });

                const TimeData = location?.staysAndDurationSummary?.duration;
                parkingWorkplaceOccupationTimesDatasets.push({
                    locationName: locationName,
                    data: [
                        TimeData?.to1Hour,
                        TimeData?.from1To3Hours,
                        TimeData?.from3To5Hours,
                        TimeData?.from5Hours,
                    ],
                });

                const latestAnalysisDate = location?.dailyParkingSummary[location?.dailyParkingSummary?.length - 1]?.date;
                const oneMonthBackFromLatestAnalysisDate = subtractOneMonthFromDate(new Date(latestAnalysisDate));

                location?.dailyParkingSummary?.map(
                    (summaryItem: DailyParkingSummary) => {
                        if (new Date(summaryItem.date) > oneMonthBackFromLatestAnalysisDate) {
                            parkingWorkplaceOccupationMonthDatasets[i].data[0].data.push(summaryItem?.maxVehiclesSameTime);
                            parkingWorkplaceOccupationMonthDatasets[i].data[1].data.push(summaryItem?.vehiclesPerDay);
                            parkingWorkplaceOccupationMonthDatasets[i].data[2].data.push(summaryItem?.trackedVehicles);

                            const dayOfMonth = summaryItem?.date.split("-").pop() || "";

                            if (!parkingWorkplaceOccupationMonthLabels.includes(dayOfMonth))
                                parkingWorkplaceOccupationMonthLabels.push(dayOfMonth);

                        }

                        parkingWorkplaceOccupationYearDatasets[i].data[0].data.push(summaryItem?.maxVehiclesSameTime);
                        parkingWorkplaceOccupationYearDatasets[i].data[1].data.push(summaryItem?.vehiclesPerDay);
                        parkingWorkplaceOccupationYearDatasets[i].data[2].data.push(summaryItem?.trackedVehicles);

                        if (!parkingWorkplaceOccupationYearLabels.includes(summaryItem?.date))
                            parkingWorkplaceOccupationYearLabels.push(summaryItem?.date);

                    }
                );
            }
        );

        return {
            dataSets: {
                parkingTypeDatasets,
                parkingWorkplaceOccupationStaysDatasets,
                parkingWorkplaceOccupationTimesDatasets,
                parkingWorkplaceOccupationMonthDatasets,
                parkingWorkplaceOccupationYearDatasets,
            },
            labels: {
                parkingWorkplaceOccupationMonthLabels,
                parkingWorkplaceOccupationYearLabels,
            },
        };
    };

    const sortedData = (
        data: IFleetAnalysisLocationResults | null,
        sortCrescentMap: any,
        spliceBy?: number
    ) => {
        if (!data) return;

        const {
            dataSets: {
                parkingTypeDatasets,
                parkingWorkplaceOccupationStaysDatasets,
                parkingWorkplaceOccupationTimesDatasets,
                parkingWorkplaceOccupationMonthDatasets,
                parkingWorkplaceOccupationYearDatasets,
            },
            labels,
        } = handleData(data);

        sortCrescentMap.parkingType
            ? (() => {
                sortParkingTypeDataAscending(parkingTypeDatasets);
            })()
            : (() => {
                sortParkingTypeDataDescending(parkingTypeDatasets);
            })();

        const processedData = {
            dataSets: {
                parkingTypeDatasets,
                parkingWorkplaceOccupationStaysDatasets,
                parkingWorkplaceOccupationTimesDatasets,
                parkingWorkplaceOccupationMonthDatasets,
                parkingWorkplaceOccupationYearDatasets,
            },
            labels,
        };

        if (spliceBy) {
            processedData.dataSets.parkingTypeDatasets.map((content) =>
                content.data.splice(spliceBy)
            );
        }

        return processedData;
    };

    const processDataForChart = (dataObject: any) => {
        return {
            chartData: dataObject?.map((dt: any) => ({
                ...dt,
                data: dt.data?.map((d: any) => d?.value),
            })),
            labels: dataObject?.map((dt: any) =>
                dt.data?.map((d: any) => d?.label)
            )[0],
        };
    };

    return {
        handleData,
        sortedData,
        processDataForChart,
    };
}
