import { useState } from "react";
import { VehicleSelectionState } from "../../../../../../../@types/driver/simulations";
import { SimulationVehicleInfo } from "../../../../../../../@types";




export function useVehicleSimulationState(): VehicleSelectionState {

    const [originalDriverVehicle, setOriginalDriverVehicle] = useState<SimulationVehicleInfo | null>(null);
  
    const [simulationRequired, setSimulationRequired] = useState<string | null>(null);
  
    return {
      originalDriverVehicle: {
        value: originalDriverVehicle,
        setter: setOriginalDriverVehicle
      },
      simulationRequired: {
        value: simulationRequired,
        setter: setSimulationRequired
      },
    }
  }