
import { EvToDisplay } from "../../../../../../@types/driver/evSuitability";
import { Vehicle } from "../../../../../../@types/vehicle";
import { CarIconSelector } from "../../../../../../electrify_frontend_common/components/CarIconSelector";
import { BrandInfo } from "../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/BrandInfo";
import { ElectrificationFactorPlaceholder } from "../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/ElectrificationFactor";
import { NoBrandPlaceholder } from "../../Overview/FutureVehicleCard/components";
import { getImageServerWithFallback, replaceImgWithError } from "../../../../../../electrify_frontend_common/utils";
import { useLayout } from "../../../../../../hooks/state/useLayout";



export function SuitableEvCard({
  vehicle,
  color,
  userVehicle,
}: {
  vehicle?: EvToDisplay;
  color: { class: string; hex: string };
  userVehicle?: Vehicle;
}) {
  const { localeCode, currencySymbol } = useLayout();

  return (
    <div
      className={`rounded bg-White-shaded overflow-hidden p-4 flex flex-col w-full h-full border border-Grey-tint relative`}
    >
      <ElectrificationFactorPlaceholder
        electrificationFactor={vehicle?.electrificationFactor}
        defaultColor={vehicle ? undefined : color.hex}
      />
      {vehicle ? (
        <>
          <BrandInfo
            vehicleBrand={vehicle?.make}
            vehicleModel={vehicle?.model}
          />
          {/* <div className="flex h-full items-end">
                        <div className="flex  items-center font-bold text-xs text-Grey-dark">
                            <div>More infos</div>
                            <ArrowRight className="w-3 ml-1" />
                        </div>
                    </div> */}
          <div className="flex w-full h-full justify-center relative items-end">
            <div
              className={`flex w-full h-[28px] rounded-md ${color.class} text-white items-center pl-2`}
            >
              <div className="flex items-end">
                <span className="text-lg font-bold leading-none mr-[2px]">
                  {vehicle?.listPrice?.toLocaleString(localeCode)}
                </span>{" "}
                <span className="text-xs font-thin">{currencySymbol}</span>
              </div>
            </div>
            <div className="absolute bottom-0 right-0">
              {vehicle?.evId ?
                <img
                  width={180}
                  src={`${getImageServerWithFallback()}/images/vehicle/ev/small?evId=${vehicle?.evId
                    }`}
                  onError={(e) => replaceImgWithError(e, vehicle)}
                />
                : null}
            </div>
          </div>
        </>
      ) : (
        <>
          <NoBrandPlaceholder />
          {/* <div className="flex h-full items-end">
                        <div className="bg-Grey-light bg-opacity-40 h-5 w-[54px] mb-2 rounded-sm" />
                    </div> */}
          <div className="flex w-full h-full justify-center relative items-end">
            <div
              className={`flex w-full h-[28px] rounded-md bg-Grey-light bg-opacity-40 text-white items-center pl-2`}
            />

            <div className="absolute bottom-[-8px] right-[-20px] w-56">
              <img
                src={CarIconSelector(
                  userVehicle?.modelInfo?.bodyStyle,
                  userVehicle?.modelInfo?.category
                )}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
