import { useMutation, useQuery } from "@apollo/client";
import { VehicleSelectionQueries, VehicleSelectionState } from "../../../../../../../@types/driver/simulations";
import { SimulationVehicleInfo } from "../../../../../../../@types";
import { GET_SIMULATION_VEHICLE_INFO } from "../../../../../../../services/graphql/fleet/queries/simulation";
import { extractVehicleIdsFromSummaries } from "../../../../../../../common/utils";
import { UPDATE_SIMULATION_RESULTS } from "../../../../../../../services/graphql/fleet/mutations";





export function useVehicleSimulationQueriesAndMutations(vehicleSelectionState: VehicleSelectionState): VehicleSelectionQueries {

    const { loading: vehicleLoading, refetch: refetchSimulationVehiclesInfo } = useQuery<{ simulationVehicleInfo: SimulationVehicleInfo[] }>(
        GET_SIMULATION_VEHICLE_INFO,
        {
            onCompleted: ({ simulationVehicleInfo }) => {
                const simulationsToRequest: string[] = extractVehicleIdsFromSummaries(simulationVehicleInfo);

                vehicleSelectionState.simulationRequired.setter(simulationsToRequest[0]);
                vehicleSelectionState.originalDriverVehicle.setter(simulationVehicleInfo[0]);
            }
        }
    );

    const [updateSimulations] = useMutation<{ updateSimulationResults: { blockedSimulations: string[], vehicleIds: string[] } }>(UPDATE_SIMULATION_RESULTS, {
        onCompleted: ({ updateSimulationResults }) => {
            const newVal = vehicleSelectionState.originalDriverVehicle.value;
            if(!newVal?.vehicleId) return;
            const obj : SimulationVehicleInfo & {simulationIsReady: boolean} = { ...newVal, simulationIsReady: updateSimulationResults?.vehicleIds.includes(newVal?.vehicleId) }
            vehicleSelectionState.originalDriverVehicle.setter(obj);
        }
    });


    return {
        vehicleLoading,
        updateSimulations,
        refetchSimulationVehiclesInfo,
    }

}