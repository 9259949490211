import { useContext } from "react";
import { DriverEVFinderContext } from "../contexts/DriverEVFinderContext";
import { DriverEvFinderAlternativeVehicleCard } from "./DriverEvFinderVehicleCards/DriverEvFinderAlternativeVehicleCard";
import { DriverEvFinderFavoriteVehicleCard } from "./DriverEvFinderVehicleCards/DriverEvFinderFavoriteVehicleCard";
import { SimulationVehicleInfo } from "../../../../../../../@types";
import { AlternativeVehicle } from "../../../../../../../electrify_frontend_common/types";




export function DriverEvFinderVehicleList({ userVehicle }: { userVehicle: SimulationVehicleInfo }) {

    const {
        evFinderData,
        evFinderFavoritesData,
        favoritesOnly,
        selectAlternativeVehicle
    } = useContext(DriverEVFinderContext);

    const alternativeEVs = evFinderData?.vehicleEvAlternatives?.evAlternatives;
    const favoriteEVs = evFinderFavoritesData?.vehicleEvAlternatives?.evAlternatives;


    return (
        <div>{
            favoritesOnly ? <div className="flex grow overflow-auto w-full py-2">
                {favoriteEVs?.map((evs: Partial<AlternativeVehicle>) => (
                    <div key={evs.evId} className="mr-4">
                        <DriverEvFinderFavoriteVehicleCard
                            alternativeVehicle={evs}
                            onClick={() => {
                                selectAlternativeVehicle(evs.evId || "");
                            }}
                        />
                    </div>
                ))}
            </div> :
                <div className="grid grid-cols-3 gap-3">
                    {alternativeEVs
                        ? alternativeEVs?.map((item: Partial<AlternativeVehicle>) => (
                            <div
                                key={`${item._id}`}
                                className="col-span-1 ">
                                <DriverEvFinderAlternativeVehicleCard
                                    userVehicle={userVehicle}
                                    alternativeVehicle={item}
                                    onClick={() => {
                                        selectAlternativeVehicle(item.evId || "");
                                    }}
                                />
                            </div>
                        ))
                        : null}
                </div>
        }</div>
    )


}