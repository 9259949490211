import { ArrowRightIcon } from "../../icons/ArrowRightIcon";



export function ArrowButton({className, onClick, text} : {onClick : (e?: any) => void, className?: string, text: string}) {

    return (
        <div onClick={onClick} className={`${className} h-fit cursor-pointer hover:shadow bg-Grey-tint rounded flex px-2 p-1 text-xs items-center text-Grey-dark font-semibold`}>
            {text}
            <ArrowRightIcon className="ml-2" color={"#595E69"}/>
        </div>
    )

}