


export function ArrowRightIcon({ color, className }: { color?: string, className?: string }) {

    return (
        <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon">
                <path id="Vector" d="M3 8L13 8" stroke={color ? color : "#E1E4EB"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_2" d="M8.33333 12.6641L13 7.9974L8.33333 3.33073" stroke={color ? color : "#E1E4EB"} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    )
}