import { useContext } from "react";
import { VehicleComparatorContext } from "../../../../../../../../contexts/VehicleComparatorContext";
import { AlternativeVehicle } from "../../../../../../../../electrify_frontend_common/types";
import { FleetEvFinderFavoriteVehicleCard } from "../../FleetEvFinderVehicleCards/FleetEvFinderFavoriteVehicleCard";


export function FavoriteAlternativesList() {

    const {
        vehicle,
        evFinderFavoritesData,
        selectAlternative
    } = useContext(VehicleComparatorContext);

    const favoritesAlternativeEvs = evFinderFavoritesData?.vehicleEvAlternatives?.evAlternatives;

    return (
        <div className="flex grow overflow-auto w-full py-2">
            {favoritesAlternativeEvs
                ? favoritesAlternativeEvs?.map((item: Partial<AlternativeVehicle>) => (
                    <div key={item.evId} className="mr-4">
                        <FleetEvFinderFavoriteVehicleCard
                            key={`${item._id}`}
                            alternativeVehicle={item}
                            onClick={() => selectAlternative(item.evId || "")}
                        />
                    </div>
                ))
                : null}
        </div>
    )


}