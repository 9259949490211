import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import queryString from 'query-string';

export const LANGUAGES = {
	en: 'en',
	de: 'de',
};

/* Check existance of language on URL */
const urlLang = queryString.parse(window.location.search).lang as string;

// const languageOnUrl =
//   urlText && Object.values(LANGUAGES).includes(urlText) && urlText;

/* Check existance of language on localStorage */
const languageOnLocalStorage = localStorage.getItem('languageCode');

/* If its the first render of the page and there is a language selected on url, update local storage with correct language */
if (urlLang) {
	const urlSearchParams = new URLSearchParams(window.location.search);
	if (urlSearchParams.get('lang')) {
		urlSearchParams.delete('lang');
	}
	// Build the new URL with updated search parameters
	if (urlSearchParams.size === 0) {
		const newUrl = `${window.location.origin}${window.location.pathname}`;
		window.history.replaceState({}, document.title, newUrl);
	} else {
		window.history.replaceState(
			{},
			document.title,
			`${window.location.origin}${
				window.location.pathname
			}?${urlSearchParams.toString()}`,
		);
	}

	if (!languageOnLocalStorage) {
		localStorage.setItem('languageCode', urlLang);
	}
}

/* Select language with hierarchy: URL > languageOnLocalStorage > default EN */
const defaultLanguage = urlLang
	? urlLang
	: languageOnLocalStorage
	? languageOnLocalStorage
	: LANGUAGES.en;

document.documentElement.lang = defaultLanguage;

i18n.use(initReactI18next).init({
	lng: defaultLanguage,
	fallbackLng: LANGUAGES.en,
	resources: {
		en: {
			// Pages
			homepage: require('./static/texts/en/pages/homepage.json'),
			login: require('./static/texts/en/pages/login.json'),
			forgotPassword: require('./static/texts/en/pages/forgotPassword.json'),
			resetPassword: require('./static/texts/en/pages/resetPassword.json'),
			signup: require('./static/texts/en/pages/signup.json'),
			accountTypeSelection: require('./static/texts/en/pages/register/accountTypeSelection.json'),
			registerForm: require('./static/texts/en/pages/register/registerForm.json'),
			verifyEmail: require('./static/texts/en/pages/register/verifyEmail.json'),
			overview: require('./static/texts/en/pages/overview.json'),
			onboarding: require('./static/texts/en/pages/onboarding.json'),
			dataProviderUpload: require('./static/texts/en/pages/dataProviderUpload.json'),
			settingsBilling: require('./static/texts/en/pages/settingsBilling.json'),
			settingsSimulation: require('./static/texts/en/pages/settingsSimulation.json'),
			addresses: require('./static/texts/en/pages/addresses.json'),
			chartWrapper: require('./static/texts/en/pages/chartWrapper.json'),
			drivingAnalysis: require('./static/texts/en/pages/driving-analysis.json'),
			parkingAnalysis: require('./static/texts/en/pages/parking-analysis.json'),
			fleetSimulation: require('./static/texts/en/pages/fleet-simulation.json'),
			editVehicle: require('./static/texts/en/pages/editVehicle.json'),
			vehicles: require('./static/texts/en/pages/vehicles.json'),
			paymentProcess: require('./static/texts/en/pages/paymentProcess.json'),
			// Root folder
			common: require('./static/texts/en/common.json'),
			footer: require('./static/texts/en/footer.json'),
			notFound: require('./static/texts/en/pages/not-found.json'),
			// modals: require('./static/texts/en/modals.json'),
			// navbar: require('./static/texts/en/navbar.json'),
			

			// driver
			driverOnboarding: require('./static/texts/en/pages/driver/onboarding.json'),
			dongleRoadMap: require('./static/texts/en/pages/driver/dongleRoadMap.json'),
			tripSimulation: require('./static/texts/en/pages/driver/tripSimulation.json'),
			driverOverview: require('./static/texts/en/pages/driver/overview.json'),
			driverNavbar: require('./static/texts/en/pages/driver/navbar.json'),
			driverDrivingAnalysis: require('./static/texts/en/pages/driver/drivingAnalysis.json'),
			driverParkingAnalysis: require('./static/texts/en/pages/driver/parkingAnalysis.json'),
			scenarios: require('./static/texts/en/pages/driver/scenarios.json'),
			driverSettings: require('./static/texts/en/pages/driver/settings.json'),
			driverEvSuitability: require('./static/texts/en/pages/driver/evSuitability.json'),
			driverPaymentSystem: require('./static/texts/en/pages/driver/paymentSystem.json'),
			electrificationPlanner: require('./static/texts/en/pages/driver/electrificationPlanner.json'),

			// Shared components
			evFinder: require('./electrify_frontend_common/static/en/components/evFinder.json'),
			electrifyTeaser: require('./electrify_frontend_common/static/en/components/electrifyTeaser.json'),
		},
		de: {
			// Pages
			homepage: require('./static/texts/de/pages/homepage.json'),
			login: require('./static/texts/de/pages/login.json'),
			forgotPassword: require('./static/texts/de/pages/forgotPassword.json'),
			resetPassword: require('./static/texts/de/pages/resetPassword.json'),
			signup: require('./static/texts/de/pages/signup.json'),
			accountTypeSelection: require('./static/texts/de/pages/register/accountTypeSelection.json'),
			registerForm: require('./static/texts/de/pages/register/registerForm.json'),
			verifyEmail: require('./static/texts/de/pages/register/verifyEmail.json'),
			overview: require('./static/texts/de/pages/overview.json'),
			onboarding: require('./static/texts/de/pages/onboarding.json'),
			dataProviderUpload: require('./static/texts/de/pages/dataProviderUpload.json'),
			settingsBilling: require('./static/texts/de/pages/settingsBilling.json'),
			settingsSimulation: require('./static/texts/de/pages/settingsSimulation.json'),
			addresses: require('./static/texts/de/pages/addresses.json'),
			chartWrapper: require('./static/texts/de/pages/chartWrapper.json'),
			drivingAnalysis: require('./static/texts/de/pages/driving-analysis.json'),
			parkingAnalysis: require('./static/texts/de/pages/parking-analysis.json'),
			fleetSimulation: require('./static/texts/de/pages/fleet-simulation.json'),
			editVehicle: require('./static/texts/de/pages/editVehicle.json'),
			vehicles: require('./static/texts/de/pages/vehicles.json'),
			paymentProcess: require('./static/texts/de/pages/paymentProcess.json'),
			// Root folder
			common: require('./static/texts/de/common.json'),
			footer: require('./static/texts/de/footer.json'),
			notFound: require('./static/texts/de/pages/not-found.json'),

			// driver
			driverOnboarding: require('./static/texts/de/pages/driver/onboarding.json'),
			dongleRoadMap: require('./static/texts/de/pages/driver/dongleRoadMap.json'),
			driverNavbar: require('./static/texts/de/pages/driver/navbar.json'),
			driverOverview: require('./static/texts/de/pages/driver/overview.json'),
			tripSimulation: require('./static/texts/de/pages/driver/tripSimulation.json'),
			driverDrivingAnalysis: require('./static/texts/de/pages/driver/drivingAnalysis.json'),
			driverParkingAnalysis: require('./static/texts/de/pages/driver/parkingAnalysis.json'),
			scenarios: require('./static/texts/de/pages/driver/scenarios.json'),
			driverSettings: require('./static/texts/de/pages/driver/settings.json'),
			driverEvSuitability: require('./static/texts/de/pages/driver/evSuitability.json'),
			driverPaymentSystem: require('./static/texts/de/pages/driver/paymentSystem.json'),
			electrificationPlanner: require('./static/texts/de/pages/driver/electrificationPlanner.json'),

			// Shared components
			evFinder: require('./electrify_frontend_common/static/de/components/evFinder.json'),
			electrifyTeaser: require('./electrify_frontend_common/static/de/components/electrifyTeaser.json'),
		},
	},
	ns: ['common'],
	defaultNS: 'common',
	fallbackNS: 'common',
	debug: false,
	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
