
export function removeTypename(object : any) : any {
    if (Array.isArray(object)) {
      return object.map(removeTypename);
    } else if (typeof object === 'object' && object !== null) {
      const newObj : any = {};
      for (const key in object) {
        if (key !== '__typename') {
          newObj[key] = removeTypename(object[key]);
        }
      }
      return newObj;
    }
    return object;
  }
  