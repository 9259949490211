import { useTrackingData } from "../hooks/useTrackingData";
import { AnalysisPeriod, DateDisplayer, IconData } from "./components";
import { ReactComponent as RangeIcon } from "../../../../../../assets/driver_settings/range.svg";
import { ReactComponent as VehicleIcon } from "../../../../../../assets/vehicle-icon-white.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Vehicle } from "../../../../../../@types/vehicle";
import { useLayout } from "../../../../../../hooks/state/useLayout";


export function TrackingData({
  validPeriod,
  analysisPeriod,
  tripsAmountData,
  hideTitle,
}: {
  validPeriod?: { min?: string; max?: string } | null;
  analysisPeriod?: { min?: string | null; max?: string | null } | null;
  tripsAmountData?: {
    countOfTrips: number;
    totalDistance: number;
    yearlyMileage: number;
  };
  hideTitle?: boolean;
}) {
  const navigate = useNavigate();
  const { t } = useTranslation("driverOverview");

  
  const { localeCode } = useLayout();

  return (
    <div className="flex flex-col w-full h-full">
      {!hideTitle ? (
        <h1 className=" text-xl text-Black">{t("tracking-data.title")}</h1>
      ) : null}

      <div
        className="flex flex-col h-full border border-Grey-tint rounded p-4 mt-6 cursor-pointer hover:shadow"
        onClick={() => navigate("/settings?trip-data")}
      >
        <div className="flex items-center">
          <div className="h-2 w-2 bg-Grey-default rounded-sm mr-2" />
          <h1 className="font-bold">{t("tracking-data.tracking-period")}</h1>
        </div>
        <div className="mb-10">
          <DateDisplayer
            minDate={validPeriod?.min}
            maxDate={validPeriod?.max}
          />
        </div>

        <AnalysisPeriod
          validPeriod={validPeriod}
          analysisPeriod={analysisPeriod}
        />

        <div className="mt-4">
          <IconData
            value={tripsAmountData?.countOfTrips?.toLocaleString(localeCode) || "0"}
            label={t("tracking-data.trips")}
            icon={<RangeIcon />}
            color="bg-Blueberry-light-shade"
          />
        </div>

        <div className="mt-4">
          <IconData
            value={
              <div className="flex items-end">
                {tripsAmountData?.totalDistance?.toLocaleString(localeCode) ||
                  0}{" "}
                <span className="text-sm font-normal ml-1">km</span>
                {/* <WarningIcon className="ml-1.5" /> */}
              </div>
            }
            label={t("tracking-data.distance")}
            icon={<VehicleIcon />}
            color="bg-Blueberry-light-default"
          />
        </div>

        <div className="mt-10 flex items-center">
          <div className="text-md text-Grey-dark mr-2">
            {t("tracking-data.calculated-mileage")}
          </div>
          <div className=" text-2xl font-extrabold flex items-center">
            {tripsAmountData?.yearlyMileage?.toLocaleString(localeCode)}{" "}
            <span className="text-lg ml-1 font-normal">km</span>
          </div>
        </div>
      </div>
    </div>
  );
}
