import ChartWrapper from "../../../../../../../../common/components/Charts/ChartWrapper";
import { ChargingOptionsChart } from "./ChargingOptionsChart";
import { useTranslation } from "react-i18next";

export function ChargingOptions({
  data,
  openExpanded,
}: {
  data: any;
  openExpanded: () => void;
}) {
  const { t } = useTranslation("driverParkingAnalysis");

  return (
    <ChartWrapper
      title={t("parking-locations-charging.title")}
      expand={openExpanded}
    >
      <ChargingOptionsChart data={data} />
    </ChartWrapper>
  );
}
