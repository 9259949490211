import { gql } from "@apollo/client";

export const GET_SIMULATION_VEHICLE_INFO = gql`
  query GetSimulationVehicleInfo {
    simulationVehicleInfo {
      vehicleId
      vehicleName
      vehicleModelName
      vehicleBrand
      vehicleModel
      yearlyMileage
      yearlyFuelAndEnergyCosts
      yearlyCo2Emission
      simulationLastUpdated
      simulationIsReady
      isVehiclePaid
      vehicleInfo {
        modelInfo {
          category
          bodyStyle
        }
        
      }
    }
  }
`;

export const GET_VEHICLE_SELECTED_EV = gql`
  query GetVehicleSelectedEv($vehicleId: ID!) {
    vehicleSelectedEv(vehicleId: $vehicleId) {
      vehicleId
      selectedEv {
        _id
        evId
        category
        bodyStyle
        make
        model
        electrificationFactor
        yearlyFuelAndEnergyCosts
        yearlyCo2Emission
        listPrice
        isSelected
        isFavorited
        notInPreference
        hasHighestScore
        hasHighestElectrificationFactor
        hasLowestFuelAndEnergyCosts
        notRecommended
      }
    }
  }
`;

export const GET_SIMULATION_PREFERENCE = gql`
  query GetSimulationPreference {
    simulationPreference {
      setTemperature
      selectedBrands
    }
  }
`;

export const GET_ALTERNATIVE_EV_BRANDS = gql`
  query GetAlternativeEvBrands {
    alternativeEvBrands
  }
`;

export const GET_ALTERNATIVE_EV_DETAILS = gql`
  query GetAlternativeEvDetails($vehicleId: ID!, $evId: String!) {
    alternativeEvDetails(vehicleId: $vehicleId, evId: $evId) {
      vehicleId
      evId
      category
      bodyStyle
      yearlyEnergyConsumption
      yearlyFuelAndEnergyCosts
      model
      make
      listPrice
      electrificationFactor
      evModelData {
        coreData {
          availabilityStatus
          acChargePower
          dcMaximumChargePower
        }
        technicalData {
          acceleration
          topSpeed
          totalPowerKw
          totalPowerHp
          totalTorque
          propulsion
          testedRealConsumption100km
        }
        range {
          realRangeAverage
          realRangeHighwayWorst
          realRangeCityWorst
          realRangeCombinedWorst
          realRangeHighwayBest
          realRangeCityBest
          realRangeCombinedBest
          wltpRangeWorst
          wltpRangeBest
        }
        battery {
          nominalBatteryCapacity
          useableBatteryCapacity
          batteryType
        }
        charging {
          acPlugType
          acPlugLocation
          acChargePower
          acChargeTime
          acChargeSpeed
          dcPlugType
          dcPlugLocation
          dcMaximumChargePower
          dcChargeTime
          dcChargeSpeed
        }
        dimensionsAndWeight {
          length
          width
          widthWithMirrors
          height
          wheelbase
          weightUnladen
          grossVehicleWeight
          loadWeight
          loadVolume
          loadVolumeMax
          loadVolumeFrunk
        }
        miscellaneous {
          towHitchPossible
          seatsCount
          hasIsoFix
          isoFixSeatsCount
          hasRoofRails
          turningCircle
        }
      }
    }
  }
`;

export const GET_VEHICLE_EV_ALTERNATIVES = gql`
  query GetVehicleEvAlternatives(
    $vehicleId: ID!
    $onlyFavorites: Boolean
    $sortBy: String
    $page: Int
    $pageSize: Int
    $selectedFilters: SelectedFilters
  ) {
    vehicleEvAlternatives(
      vehicleId: $vehicleId
      onlyFavorites: $onlyFavorites
      sortBy: $sortBy
      page: $page
      pageSize: $pageSize
      selectedFilters: $selectedFilters
    ) {
      vehicleId
      page
      pageSize
      totalPagesCount
      evAlternatives {
        _id
        evId
        make
        model
        category
        availabilityStatus
        bodyStyle
        propulsion
        drivetrainType
        towHitchPossible
        seatsCount
        electrificationFactor
        yearlyFuelAndEnergyCosts
        yearlyCo2Emission
        listPrice
        isSelected
        isFavorited
        notInPreference
        loadWeight
        loadVolume
        totalTorque
        acChargeSpeed
        acChargePower
        dcChargeSpeed
        dcMaximumChargePower
        hasIsoFix
        isoFixSeatsCount
        supportsHpc
        supportsPnc
        supportsV2g
        realRangeAverage
        testedRealConsumption100km
        offerToDisplay {
            price
            url
        }
      }
      latestSortState
      latestFilterState {
        brands
        segments
        bodyStyles
        propulsion
        seats
        towing
        availabilityStatus
        listPrice {
          min
          max
        }
        loadWeight {
          min
          max
        }
        loadVolume {
          min
          max
        }
        topSpeed
        realRange
        realConsumption
        fastCharging
        plugAndCharge
        vehicleToGrid
        isofixAvailable
        roofRails
      }
    }
  }
`;
