import { useEffect, useMemo, useState } from "react";
import { CheckBox, QuestionStructure, RadioButton } from "../../../components/Sections/utils/forms";
import { ElectrificationPlannerSections, ElectrificationPlannerSubSections } from "../../../models/Section";
import { useMutation, useQuery } from "react-query";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

import { SelectionType } from "../../../components/Sections/utils";
import { getQuestionStructure, updateElectrificationPlanner } from "../../../../../../../services/rest/electrificationPlanner";
import { IElectrificationPlanner } from "../../../../../../../@types/driver/electrificationPlanner";
import { Spinner, SpinnerSize } from "../../../../../../../electrify_frontend_common/components/Spinner";
import { Button } from "../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonVariant } from "../../../../../../../electrify_frontend_common/components/Buttonv2/types";




export function QuestionStep({
    step,
    nextStep,
    prevStep,
    nextStepToStore,
    stepName,
    section,
    subsection,
}: {
    step: number,
    nextStep: (data?: IElectrificationPlanner) => void,
    prevStep: () => void,
    nextStepToStore?: (answer: string) => number
    stepName: string,
    section: ElectrificationPlannerSections,
    subsection: ElectrificationPlannerSubSections,
}) {

    const { t } = useTranslation("electrificationPlanner");
    const [questionStructure, setQuestionStructure] = useState<QuestionStructure | null>(null);

    useEffect(() => {
        return () => {
            setQuestionStructure(null)
        };
    }, [stepName])

    const mutation = useMutation(updateElectrificationPlanner, {
        onSuccess: (data) => {
            nextStep(data);
        }
    })

    const { isLoading } = useQuery<QuestionStructure>({
        queryKey: [stepName],
        queryFn: () => getQuestionStructure(
            section,
            subsection,
            stepName,
            true
        ),
        onSuccess: (data) => {
            setQuestionStructure(data);
        }
    });


    const initialValues = useMemo(() => {
        if (questionStructure?.selectionType === SelectionType.MULTIPLE) {
            return { [stepName]: questionStructure?.data || [] }
        }
        return { [stepName]: questionStructure?.data || '' }
    }, [questionStructure?.data, questionStructure?.selectionType, stepName, isLoading])



    if (!questionStructure || isLoading) return <div className="flex h-[400px] items-center justify-center w-[1000px]">
        <Spinner size={SpinnerSize.LARGE} />
    </div>;



    return (

        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                mutation.mutate({
                    section,
                    subsection,
                    data: {
                        answer: values,
                        dueDate: values?.dueDate
                    },
                    onboardingStep: nextStepToStore ? nextStepToStore(values[stepName] as string) : step + 1
                });
            }}
        >
            {({ values, setFieldValue }) => {
                return (
                    <Form className="flex flex-col p-6 px-10 w-[1000px]">
                        <>
                            <div className="text-lg mb-5 mt-2"><span className="text-Blueberry-dark-default">{t("onboarding.step")} {step}:</span> {t(`${section}.subsections.${subsection}.steps.${stepName}.title`)}</div>

                            <div className="mb-4 text-lg">{t(`${section}.subsections.${subsection}.steps.${stepName}.question`)}</div>
                            {
                                questionStructure?.options.map((value) => {


                                    return (<div key={value.text}>
                                        {
                                            questionStructure?.selectionType === SelectionType.SINGLE ?
                                                <div onClick={() => setFieldValue(stepName, value.text)} className={`flex w-full items-center justify-between`}>
                                                    <RadioButton
                                                        key={value.text}
                                                        name={stepName}
                                                        label={t(`${section}.subsections.${subsection}.steps.${stepName}.options.${value.text}`)}
                                                        value={value.text}
                                                    />
                                                </div>
                                                : <div onClick={() => {
                                                    const currentValue = values[stepName] as string[];
                                                    const selected = currentValue.includes(value.text);
                                                    if (selected) {
                                                        setFieldValue(stepName, currentValue.filter((v: string) => v !== value.text));
                                                        return;
                                                    }
                                                    currentValue.push(value.text);
                                                    setFieldValue(stepName, currentValue);
                                                }} className="flex items-center my-2 cursor-pointer">
                                                    <CheckBox
                                                        checked={values[stepName]?.includes(value.text) || false}
                                                        name={stepName}
                                                        onChange={() => null}
                                                        label={t(`${section}.subsections.${subsection}.steps.${stepName}.options.${value.text}`)}
                                                    />

                                                </div>
                                        }

                                    </div>
                                    )
                                })
                            }

                            <div className="flex w-full justify-between mt-12">
                                {step !== 1 ?
                                    <Button type="button" variant={ButtonVariant.SECONDRY} onClick={prevStep} className="mr-4">{"Back"}</Button>
                                    : <div />}

                                <Button disabled={!values[stepName]?.length} type="submit" onClick={() => null}>{"Next"}</Button>
                            </div>
                        </>
                    </Form>

                )
            }
            }

        </Formik >

    )


}