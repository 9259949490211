import { useLazyQuery, useReactiveVar } from "@apollo/client";
import { DataUploadHelper } from "../DataUploadHelper";
import { useEffect, useState } from "react";
import CopyReference from "./CopyReference";
import ConnectionMessage from "./ConnectionMessage";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../../../../../../../hooks/state/useUserData";
import { GET_VEHICLES_SIMPLE } from "../../../../../../../../services/graphql/fleet/queries";
import { SyncStep } from "../../../../../../../../@types";
import { Button } from "../../../../../../../../electrify_frontend_common/components/Buttonv2";


export enum ConnectionState {
  SUCCESS = "success",
  ERROR = "error",
}

export default function WebfleetSync({ onCancel }: { onCancel: () => void }) {
  const { refreshUserAccountInfo } = useUserData();

  const { t } = useTranslation("dataProviderUpload");
  const navigate = useNavigate();

  const [initialVehicleCount, setInitialVehicleCount] = useState(0);
  const [connectionState, setConnectionState] =
    useState<ConnectionState | null>(null);
  const [addedVehiclesCount, setAddedVehiclesCount] = useState(0);

  const [getVehicles] = useLazyQuery(GET_VEHICLES_SIMPLE);

  useEffect(() => {
    getVehicles().then((response) => {
      setInitialVehicleCount(response.data?.vehicles?.length || 0);
    });
  }, []);

  const checkConnection = () => {
    getVehicles().then((response) => {
      const updatedVehicleCount = response.data.vehicles.length;
      if (updatedVehicleCount > initialVehicleCount) {
        setConnectionState(ConnectionState.SUCCESS);
        setAddedVehiclesCount(updatedVehicleCount - initialVehicleCount);
        refreshUserAccountInfo();
        setTimeout(() => navigate("/"), 2000);
        return;
      }
      setConnectionState(ConnectionState.ERROR);
    });
  };

  const steps: SyncStep[] = [
    {
      name: "Step 1",
      content: (
        <div>
          {t("automatic-sync.helper-step-1.part-1")}{" "}
          <a
            href={t("automatic-sync.helper-step-1.part-2-url")}
            target="_blank"
            className="underline text-Blueberry-dark-default"
          >
            {t("automatic-sync.helper-step-1.part-2-text")}
          </a>{" "}
          {t("automatic-sync.helper-step-1.part-3")}
        </div>
      ),
    },
    {
      name: "Step 2",
      content: <div>{t("automatic-sync.helper-step-2")}</div>,
    },
    {
      name: "Step 3",
      content: <div>{t("automatic-sync.helper-step-3")}</div>,
    },
  ];

  return (
    <div>
      <DataUploadHelper text={t("automatic-sync.helper-title")} steps={steps} />
      <div className="p-10">
        <h2>{t("automatic-sync.title")}</h2>
        <span className="text-sm text-Grey-shade">
          {t("automatic-sync.message")}
        </span>

        <CopyReference connectionState={connectionState} />
        <ConnectionMessage
          newVehiclesCount={addedVehiclesCount}
          connectionState={connectionState}
        />
        <Button
          className="bg-Blueberry-dark-default mt-7 hover:bg-Blueberry-light-shade"
          onClick={checkConnection}
        >
          {t("automatic-sync.check-connection")}
        </Button>
        {connectionState === "success" ? (
          <div className="text-sm text-Grey-shade mt-6">
            {t("automatic-sync.success-text.part-1")}{" "}
            <a
              className="underline text-Blueberry-dark-default cursor-pointer"
              onClick={() => onCancel()}
            >
              {t("automatic-sync.success-text.vehicles-page")}
            </a>
            <br />
            <br />
            {t("automatic-sync.success-text.part-2")}
          </div>
        ) : null}
      </div>
    </div>
  );
}
