import { useEffect } from "react";
import { VehicleSelectionQueries, VehicleSelectionState } from "../../../../../../../@types/driver/simulations";





export function useVehicleSimulationEffects({
    vehicleSelectionState,
    vehicleSelectionQueriesAndMutations
}:
    {
        vehicleSelectionState: VehicleSelectionState,
        vehicleSelectionQueriesAndMutations: VehicleSelectionQueries
    }) {


    const simulationRequired = vehicleSelectionState.simulationRequired?.value;

    useEffect(() => {
        if (simulationRequired) {
            vehicleSelectionQueriesAndMutations.updateSimulations({
                variables: {
                    vehicleIds: [simulationRequired],
                },
            });
        }
    }, [simulationRequired]);

}