import { useTranslation } from "react-i18next";



export default function DataAvailability({
  hasTrackingDataSelect,
  noTrackingDataSelect,
} : {
  hasTrackingDataSelect : ()=> void,
  noTrackingDataSelect: ()=> void,
}) {

  const { t } = useTranslation("onboarding");

  return (
    <>
      <div className="p-10">
        <div className="mb-6">
          <h2 className="text-xl text-Black-default mb-4">{t("1-title")}</h2>
          <p className="text-Grey-shade text-sm">{t("1-description1")}</p>
          <p className="text-Grey-shade text-sm">{t("1-description2")}</p>
        </div>
        <div className="grid grid-cols-2 gap-x-6">
          <button
            className="p-6 rounded text-left border border-solid border-Grey-tint hover:bg-Grey-background"
            onClick={() => hasTrackingDataSelect()}
          >
            <h2 className="text-lg text-Blueberry-dark-default">
              {t("card-1-yes")}
            </h2>
            <p className="text-sm text-Grey-shade">{t("card-1-no")}</p>
          </button>
          <button
            className="p-6 rounded text-left border border-solid border-Grey-tint hover:bg-Grey-background"
            onClick={() => noTrackingDataSelect()}
          >
            <h2 className="text-lg text-Blueberry-dark-default">
              {t("card-2-yes")}
            </h2>
            <p className="text-sm text-Grey-shade">{t("card-2-no")}</p>
          </button>
        </div>
      </div>
    </>
  );
}
