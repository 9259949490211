import { useQuery as useRestQuery } from "react-query";
import { Dispatch, SetStateAction, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";

import toast from "react-hot-toast";
import { useUserData } from "../../../../../../hooks/state/useUserData";
import { Setting } from "../../../../../../@types/settings";
import { GET_SETTING } from "../../../../../../services/graphql/fleet/queries";
import { UPDATE_SETTING } from "../../../../../../services/graphql/fleet/mutations";
import { getDefaultFuelAndEnergyPricesForUser } from "../../../../../../services/rest/paymentProcess";
import { removeTypename } from "../../../../../../services/graphql/utils";


export function useEnergyAndFuelDetails(): {
  defaultPrices: { dieselPrice: number; petrolPrice: number } | undefined;
  saveFuelSettings: () => void;
  petrolPrice?: number | null;
  setPetrolPrice: Dispatch<SetStateAction<number | undefined | null>>;
  dieselPrice?: number | null;
  setDieselPrice: Dispatch<SetStateAction<number | undefined | null>>;
  petrolCo2?: number | null;
  setPetrolCo2: Dispatch<SetStateAction<number | undefined | null>>;
  dieselCo2?: number | null;
  setDieselCo2: Dispatch<SetStateAction<number | undefined | null>>;
  publicAcChargingPrice?: number | null;
  setPublicAcChargingPrice: Dispatch<SetStateAction<number | undefined | null>>;
  publicDcChargingPrice?: number | null;
  setPublicDcChargingPrice: Dispatch<SetStateAction<number | undefined | null>>;
  homeChargingPrice?: number | null;
  setHomeChargingPrice: Dispatch<SetStateAction<number | undefined | null>>;
  publicChargingCo2?: number | null;
  setPublicChargingCo2: Dispatch<SetStateAction<number | undefined | null>>;
  homeChargingCo2?: number | null;
  setHomeChargingCo2: Dispatch<SetStateAction<number | undefined | null>>;
} {

  const { updateUserSettings } = useUserData();

  const [petrolPrice, setPetrolPrice] = useState<number | null | undefined>(null);
  const [dieselPrice, setDieselPrice] = useState<number | null | undefined>(null);
  const [petrolCo2, setPetrolCo2] = useState<number | null | undefined>(null);
  const [dieselCo2, setDieselCo2] = useState<number | null | undefined>(null);
  const [publicAcChargingPrice, setPublicAcChargingPrice] = useState<number | null | undefined>(null);
  const [publicDcChargingPrice, setPublicDcChargingPrice] = useState<number | null | undefined>(null);
  const [homeChargingPrice, setHomeChargingPrice] = useState<number | null | undefined>(null);
  const [publicChargingCo2, setPublicChargingCo2] = useState<number | null | undefined>(null);
  const [homeChargingCo2, setHomeChargingCo2] = useState<number | null | undefined>(null);

  const { data: settingsData, refetch: refetchSettings } = useQuery<{ setting: Setting }>(GET_SETTING, {
    onCompleted: ({setting} : {setting : Setting}) => {
      updateUserSettings(setting || {});
      setPetrolPrice(setting?.simulation?.priceAndCo2?.petrol?.price)
      setDieselPrice(setting?.simulation?.priceAndCo2?.diesel?.price)
      setPetrolCo2(setting?.simulation?.priceAndCo2?.petrol?.co2);
      setDieselCo2(setting?.simulation?.priceAndCo2?.diesel?.co2);
      setPublicAcChargingPrice(setting?.simulation?.priceAndCo2?.charging?.public?.ac?.price);
      setPublicDcChargingPrice(setting?.simulation?.priceAndCo2?.charging?.public?.dc?.price)
      setHomeChargingPrice(setting?.simulation?.priceAndCo2?.charging?.home?.price)
      setPublicChargingCo2(setting.simulation?.priceAndCo2?.charging?.public?.co2)
      setHomeChargingCo2(setting.simulation?.priceAndCo2?.charging?.home?.co2)
    },
  });




  const [updateSetting] = useMutation(UPDATE_SETTING, {
    onCompleted: () => {
      toast.success("Successfully updated settings");
      refetchSettings();
    },
    onError: (error) => {
      toast.error("Failed to update settings");
      refetchSettings();
      console.log(error);
    },
  });

  const { data: defaultPrices } = useRestQuery<{
    dieselPrice: number;
    petrolPrice: number;
  }>("defaultPrices", getDefaultFuelAndEnergyPricesForUser, {
    onError: (error) => {
      console.log(">>>ERR: ", error);
    },
  });





  const saveFuelSettings = async () => {
    if (!settingsData?.setting) return;
    const settings = removeTypename(settingsData.setting);
    const settingInput: Setting = {
      ...settings,
      simulation: {
        priceAndCo2: {
          petrol: {
            price: petrolPrice,
            co2: petrolCo2
          },
          diesel: {
            price: dieselPrice,
            co2: dieselCo2
          },
          charging: {
            public: {
              ac: {
                price: publicAcChargingPrice,
              },
              dc: {
                price: publicDcChargingPrice
              },
              co2: publicChargingCo2
            },
            home: {
              price: homeChargingPrice,
              co2: homeChargingCo2
            }
          }
        }
      }
    };

    await updateSetting({
      variables: { setting: settingInput },
    });
  };

  return {
    defaultPrices,
    saveFuelSettings,
    petrolPrice,
    setPetrolPrice,
    dieselPrice,
    setDieselPrice,
    petrolCo2,
    setPetrolCo2,
    dieselCo2,
    setDieselCo2,
    publicAcChargingPrice,
    setPublicAcChargingPrice,
    publicDcChargingPrice,
    setPublicDcChargingPrice,
    homeChargingPrice,
    setHomeChargingPrice,
    publicChargingCo2,
    setPublicChargingCo2,
    homeChargingCo2,
    setHomeChargingCo2,
  };
}
