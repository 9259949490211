


export function ElectrificationPlannerIcon({ color = "black" }: { color?: string }) {
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Group">
                <path id="Vector" d="M13.1497 18.3092C13.1497 18.3092 14.1655 20.3875 15.8747 20.2317C19.3397 19.915 19.108 15.3092 20.3747 13.6825C19.3031 13.1489 18.1205 12.8763 16.9234 12.8872C15.7264 12.898 14.5489 13.192 13.4872 13.745C11.6922 14.7592 11.5647 16.4708 13.1497 18.3092V18.3092Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_2" d="M15.6005 16.1967C15.6005 16.1967 13.3505 16.475 11.748 20.3883" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_3" d="M17.875 10.3892V9.03167C17.8751 8.86749 17.8428 8.7049 17.78 8.55319C17.7173 8.40149 17.6252 8.26363 17.5092 8.1475L16 6.63917H3.5L1.99083 8.1475C1.87478 8.26363 1.78274 8.40149 1.71997 8.55319C1.6572 8.7049 1.62493 8.86749 1.625 9.03167V11.6392C1.625 11.9707 1.7567 12.2886 1.99112 12.5231C2.22554 12.7575 2.54348 12.8892 2.875 12.8892H10.375" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_4" d="M16 6.63917H3.5L4.465 2.49333C4.54806 2.24455 4.70726 2.02819 4.92008 1.8749C5.1329 1.7216 5.38855 1.63913 5.65083 1.63917H13.8492C14.1114 1.63913 14.3671 1.7216 14.5799 1.8749C14.7927 2.02819 14.9519 2.24455 15.035 2.49333L16 6.63917Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_5" d="M4.125 9.13916H5.375" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_6" d="M14.125 9.13916H15.375" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path id="Vector_7" d="M5.375 14.1392C5.375 14.4707 5.2433 14.7886 5.00888 15.023C4.77446 15.2575 4.45652 15.3892 4.125 15.3892C3.79348 15.3892 3.47554 15.2575 3.24112 15.023C3.0067 14.7886 2.875 14.4707 2.875 14.1392V12.8892H5.375V14.1392Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>

    )
}