import { useTranslation } from "react-i18next";
import { ReactComponent as SuccessImage } from "../../../../assets/success-image.svg";
import { Tariff } from "../../../../@types/paymentProcess";

export default function OrderCompleted({ onConfirm, tariff } : {onConfirm : ()=> void, tariff: Tariff}) {

  const {t} = useTranslation("paymentProcess");

  return (
    <div className="w-4/5 flex-col px-20 m-auto justify-center items-center">
      <div className="flex w-full justify-center mt-5">
        <SuccessImage />
      </div>
      <div className="w-full flex justify-center">
        <h1 className="text-3xl text-Black-default mb-2">
          {t("order-completed.success-message")}
        </h1>
      </div>

      <div className="mb-10 flex text-base text-Grey-shade text-center">
        <p>
          {t("order-completed.info-message.part-1")} {tariff.name} {t("order-completed.info-message.part-2")}
        </p>
      </div>

      <button
        onClick={onConfirm}
        className="w-full py-4 mb-10 bg-Blueberry-dark-default hover:bg-Blueberry-light-shade rounded text-lg text-white"
      >
        {t("order-completed.checkout-vehicles")}
      </button>
    </div>
  );
}
