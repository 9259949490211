import { useState } from "react";
import { Voucher } from "../../../../@types/driver/payments";
import { useMutation } from "react-query";
import { applyVoucher } from "../../../../services/rest/paymentProcess";



export function useVoucher({ setAgreedToProceed }: { setAgreedToProceed: (value: boolean) => void }): {
    voucherModalOpen: boolean,
    setVoucherModalOpen: (value: boolean) => void,
    usedVoucher?: Voucher,
    voucherRedeemedSuccessfully: boolean,
    verifyingVoucher: boolean,
    voucherCode?: string,
    setVoucherCode: (voucherCode?: string) => void,
    applyMutationFn: any,
    voucherRedemptionError: string | null,
    companyEmail: string | undefined
    setCompanyEmail: (value?: string) => void,
    resetError: ()=> void
} {
    
    const [voucherModalOpen, setVoucherModalOpen] = useState(false);
    const [usedVoucher, setUsedVoucher] = useState<Voucher | undefined>(localStorage.getItem("usedVoucher") ? JSON.parse(localStorage.getItem("usedVoucher") as string) : null);
    const [voucherRedeemedSuccessfully, setVoucherRedeemedSuccessfully] = useState(false);
    const [voucherRedemptionError, setVoucherRedemptionError] = useState<string | null>(null);
    const [verifyingVoucher, setVerifyingVoucher] = useState(false);
    const [voucherCode, setVoucherCode] = useState<string | undefined>();
    const [companyEmail, setCompanyEmail] = useState<string | undefined>();


    const applyVoucherMutation = useMutation(applyVoucher, {
        onSuccess: (data?: {
            clientSecret: string,
            paymentCompleted?: true,
            usedVoucher?: Voucher
        }) => {
            setVerifyingVoucher(false);
            setAgreedToProceed(true);
            if (data?.usedVoucher) {
                setUsedVoucher(data?.usedVoucher);
                setVoucherRedeemedSuccessfully(true);
            }
            if (data?.paymentCompleted) {
                // TODO send directly to payment feedback (DATA UPLOAD)
            }
        },
        onError: (e: any) => {
            const message = e?.response?.data?.message;
            console.log("Errror: ", message);
            setVoucherRedemptionError(message);
            setVerifyingVoucher(false);
        }
    });

    const applyMutationFn = ({
        companyEmail,
        voucherCode,
        tariffId
    }: {
        companyEmail?: string,
        voucherCode?: string,
        tariffId: number
    }) => {
        setVerifyingVoucher(true);
        applyVoucherMutation.mutate({ companyEmail, voucherCode, tariffId })
    }

    return {
        voucherModalOpen,
        setVoucherModalOpen,
        usedVoucher,
        voucherRedeemedSuccessfully,
        verifyingVoucher,
        voucherCode,
        setVoucherCode,
        applyMutationFn,
        voucherRedemptionError,
        resetError: ()=> setVoucherRedemptionError(null),
        companyEmail,
        setCompanyEmail
    }


}