import { ElectrificationPlannerStepStatus } from "../../models/Section"
import { AdditionalServiceSectionModel } from "../../models/AdditionalServicesSectionModel"
import { ChargingSectionModel } from "../../models/ChargingSectionModel"
import { VehicleSectionModel } from "../../models/VehicleSectionModel"


export function getSectionProgress(section: AdditionalServiceSectionModel | ChargingSectionModel | VehicleSectionModel | null) {

    if (section) {
        const done = Array.from(section.subsections.values()).reduce((acc, subsection) => {
            return acc + Array.from(subsection.values()).filter(step => step.status === ElectrificationPlannerStepStatus.DONE || step.status === ElectrificationPlannerStepStatus.NOT_APPLICABLE).length
        }, 0)
        const total = Array.from(section.subsections.values()).reduce((acc, section) => {
            return acc + section.size
        }, 0)
        return { done, total }
    }
    return { done: 1, total: 1 }

}