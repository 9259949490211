import { useTranslation } from "react-i18next";
import { DegreesToRadians } from "../EvScore/components";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ElectrifyPlannerContext } from "../../../../contexts/ElectrificationPlannerContext";

function CenterText({
  percentage,
  centroid,
}: {
  percentage: number;
  centroid: { x: number; y: number };
}) {
  const { t } = useTranslation("driverOverview");

  return (
    <>
      <text>
        <tspan
          fontSize={"3em"}
          fontWeight={"bold"}
          fill="#961F63"
          textAnchor="middle"
          x={centroid.x}
          y={centroid.y + 5}
        >
          {percentage}
        </tspan>
        <tspan fill="#961F63" fontSize={"1em"}>
          %
        </tspan>
      </text>
      <text
        fontSize={"0.8em"}
        fontWeight={"bold"}
        fill="#595E69"
        textAnchor="middle"
        x={centroid.x}
        y={centroid.y + 30}
      >
        {t("your-journey.description")}
      </text>
    </>
  );
}

export function ElectrificationProgress() {

  const navigate = useNavigate();

  const { t } = useTranslation("driverOverview");

  const { electrificationProgress } = useContext(ElectrifyPlannerContext);



  const initialAngle = -90;

  const radius = 100; // Radius of the circle
  const width = 2 * radius + 100;
  const height = 2 * radius + 20;

  const angle = 3.599 * electrificationProgress - 90;

  const centroid = {
    x: width / 2,
    y: radius + 8,
  };

  return (
    <div className="flex flex-col w-full h-full">
      <h1 className=" text-xl text-Black">{t("your-journey.title")}</h1>
      <div className="border w-full h-full mt-5 p-4 border-Grey-tint rounded bg-Grey-background cursor-pointer hover:shadow" onClick={()=> navigate("/electrification-planner")}>
        <div className="flex flex-col w-full h-full items-center">
          <div className="flex-col w-fit">
            <svg
              width={width}
              height={height}
              viewBox={`0 -10 ${width} ${height + 10}`}
            >
              <defs>
                <linearGradient id="arcGradient" gradientTransform="rotate(30)">
                  <stop offset="0%" stopColor="#9B0860" />
                  <stop offset="100%" stopColor="#B88198" />
                </linearGradient>
                {/* <linearGradient id="arcGradient" x1="200" y1="200" x2="100" y2="220" gradientUnits="userSpaceOnUse">
                                    <stop offset="0.173196" stop-color="#B88198" />
                                    <stop offset="1" stop-color="#9B0860" />
                                </linearGradient> */}
              </defs>
              <path
                d={`M${centroid.x},${centroid.y} M${centroid.x - radius * Math.cos(DegreesToRadians(initialAngle))
                  },${centroid.y + Math.sin(DegreesToRadians(initialAngle)) * radius
                  } 
                                A${radius},${radius} 0 ${angle + initialAngle < 0 ? "0" : "1"
                  } 1 
                                ${centroid.x +
                  radius * Math.cos(DegreesToRadians(angle))
                  },${centroid.y + Math.sin(DegreesToRadians(angle)) * radius
                  } M${centroid.x},${centroid.y}
                                `}
                fill={"none"}
                stroke={"url(#arcGradient)"}
                strokeWidth={25}
              />
              <path
                d={`M${centroid.x},${centroid.y} L${centroid.x -
                  (radius + 15) * Math.cos(DegreesToRadians(initialAngle))
                  },${centroid.y +
                  Math.sin(DegreesToRadians(initialAngle)) * (radius + 15)
                  }`}
                stroke={"#B88198"}
                strokeWidth={3}
              />
              <path
                d={`M${centroid.x},${centroid.y} L${centroid.x + (radius + 15) * Math.cos(DegreesToRadians(angle))
                  },${centroid.y + Math.sin(DegreesToRadians(angle)) * (radius + 15)
                  }`}
                stroke={"#9B0860"}
                strokeWidth={3}
              />
              <circle
                cx={centroid.x}
                cy={centroid.y}
                r={radius - 20}
                fill="#F7F8FA"
              />
              <CenterText percentage={electrificationProgress} centroid={centroid} />
            </svg>
          </div>
        </div>
      </div>
    </div>
  );
}
