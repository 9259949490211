import { Dispatch, ReactElement, SetStateAction, createContext, useCallback, useEffect, useMemo, useState } from "react";
import { SimulationVehicleInfo } from "../../@types";
import { SimulationPreference } from "../../@types/simulations";
import { useLayout } from "../../hooks/state/useLayout";
import { useTranslation } from "react-i18next";
import { useFleetSimulationService } from "./service";
import { Preferences } from "../../products/B2B/Electrify/pages/Simulation/Preferences";


export const FleetSimulationContext = createContext<{
    originalFleetVehicles: SimulationVehicleInfo[],
    setOriginalFleetVehicles: Dispatch<SetStateAction<SimulationVehicleInfo[]>> | null,
    requiredSimulations: string[],
    setRequiredSimulations: Dispatch<SetStateAction<string[]>> | null,
    preferences: SimulationPreference | null,
    setPreferences: Dispatch<SetStateAction<SimulationPreference | null>> | null,
    pageVehiclesCount: number,
    setPageVehiclesCount: Dispatch<SetStateAction<number>> | null,
    pageSelectedEvsLoaded: number,
    setPageSelectedEvsLoaded: Dispatch<SetStateAction<number>> | null,
    overviewData?: any,
    overviewDataLoading: boolean,
    vehiclesLoading: boolean,
    refetchSimulationVehiclesInfo: () => void,
    refetchOverviewData: () => void
}>({
    originalFleetVehicles: [],
    setOriginalFleetVehicles: null,
    requiredSimulations: [],
    setRequiredSimulations: null,
    preferences: null,
    setPreferences: null,
    pageVehiclesCount: 0,
    setPageVehiclesCount: null,
    pageSelectedEvsLoaded: 0,
    setPageSelectedEvsLoaded: null,
    overviewData: null,
    overviewDataLoading: false,
    vehiclesLoading: false,
    refetchSimulationVehiclesInfo: ()=> null,
    refetchOverviewData: ()=> null
});



export function FleetSimulationContextProvider({ children }: { children: ReactElement }) {

    const { t, i18n: { language } } = useTranslation("fleetSimulation");
    const { setHeader } = useLayout();
    const [originalFleetVehicles, setOriginalFleetVehicles] = useState<SimulationVehicleInfo[]>([]);
    const [requiredSimulations, setRequiredSimulations] = useState<string[]>([]);
    const [preferences, setPreferences] = useState<SimulationPreference | null>(null);
    const [pageVehiclesCount, setPageVehiclesCount] = useState(0);
    const [pageSelectedEvsLoaded, setPageSelectedEvsLoaded] = useState(0);

    const hasPaidVehicles = originalFleetVehicles.filter((v) => v.isVehiclePaid === true).length > 0;

    const {
        overviewData,
        overviewDataLoading,
        refetchOverviewData,
        vehiclesLoading,
        refetchSimulationVehiclesInfo,
        updateSimulations
    } = useFleetSimulationService({
        setPageVehiclesCount,
        setRequiredSimulations,
        originalFleetVehicles,
        setOriginalFleetVehicles,
        setPageSelectedEvsLoaded
    });

    useEffect(() => {
        setHeader({
            defaultHeaderTitle: t("page-title"),
            defaultHeaderDescription: t("page-subtitle"),
            defaultHeaderOptions: (
                <Preferences
                    hasPaidVehicles={hasPaidVehicles}
                    setPreferences={setPreferences}
                />
            ),
        });
    }, [language, hasPaidVehicles]);

    useEffect(() => {
        const overviewRefreshRequired = pageVehiclesCount > 0 && (pageSelectedEvsLoaded === pageVehiclesCount || pageSelectedEvsLoaded < 0)
        if (overviewRefreshRequired) {
            refetchOverviewData();
        }
    }, [pageVehiclesCount, pageSelectedEvsLoaded]);

    // TODO probably these effectes can be moved to the service
    useEffect(() => {
        if (preferences) {
            setOriginalFleetVehicles(
                (currentVehicles: SimulationVehicleInfo[]) => {
                    return currentVehicles?.map((cv: SimulationVehicleInfo) => ({
                        ...cv,
                        simulationIsReady: false,
                    }));
                }
            );
            updateSimulations();
        }
    }, [preferences?.selectedBrands, preferences?.setTemperature]);

    useEffect(() => {
        if (requiredSimulations?.length) {
            updateSimulations({
                variables: {
                    vehicleIds: requiredSimulations,
                },
            });
        }
    }, [requiredSimulations]);



    return (
        <FleetSimulationContext.Provider value={{
            originalFleetVehicles,
            setOriginalFleetVehicles,
            requiredSimulations,
            setRequiredSimulations,
            preferences,
            setPreferences,
            pageVehiclesCount,
            setPageVehiclesCount,
            pageSelectedEvsLoaded,
            setPageSelectedEvsLoaded,
            overviewData,
            overviewDataLoading,
            vehiclesLoading,
            refetchSimulationVehiclesInfo,
            refetchOverviewData
        }}>
            {children}
        </FleetSimulationContext.Provider>
    )

}