import { Overview } from './pages/Overview';
import Onboarding from './pages/Onboarding';
import Vehicles from './pages/Vehicles';
import DrivingAnalysis from './pages/Analysis/Vehicle';
import ParkingAnalysis from './pages/Analysis/Location';
import { FleetSimulation } from './pages/Simulation';
import { SimulationSettings } from './pages/Settings/SimulationSettings';
import { BillingSettings } from './pages/Settings/BillingSettings';
import { IRoute } from '../../../@types/routes';


export const ElectrifyFleetRoutes: IRoute[] = [
    {
        path: '/',
        component: Overview,
    },
    {
        path: '/onboarding',
        component: Onboarding,
    },
    {
        path: '/vehicles',
        component: Vehicles,
    },
    {
        path: '/analysis/vehicle',
        component: DrivingAnalysis,
    },
    {
        path: '/analysis/location',
        component: ParkingAnalysis,
    },
    {
        path: '/simulation',
        component: FleetSimulation,
    },
    {
        path: '/settings/simulation',
        component: SimulationSettings,
    },
    {
        path: '/settings/billing',
        component: BillingSettings,
    },
];
