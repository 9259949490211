


export function SuitabilityIcon({ color = "black" }: { color?: string }) {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="check-1 1">
                <path id="Vector" d="M19.9983 4L9.26737 19.3293C9.12578 19.5327 8.93788 19.6996 8.71914 19.8162C8.5004 19.9328 8.25709 19.9957 8.00926 19.9998C7.76143 20.0039 7.51617 19.949 7.29369 19.8397C7.07122 19.7305 6.87791 19.5699 6.72967 19.3712L4 15.7321" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>
    )



}