import { useMemo, useState } from "react";
import { SuitabilityRange } from "./SuitabilityRange";
import { ReactComponent as ArrowRightIcon } from "../../../../../../assets/arrow_right_strong.svg";
import { SuitableEvCard } from "./SuitableEvCard";
import { useTranslation } from "react-i18next";
import { ReactComponent as LightBulbIcon } from "../../../../../../assets/light-bulb.svg";
import { ReactComponent as CloseIcon } from "../../../../../../assets/close-blue.svg";
import { useNavigate } from "react-router-dom";
import { useLayout } from "../../../../../../hooks/state/useLayout";
import { SuitabilityType } from "../../../../../../enums";
import { ComfortCategoryData } from "../../../../../../@types/driver/evSuitability";
import { Vehicle } from "../../../../../../@types/vehicle";

function NoVehiclesBanner({ closeBanner }: { closeBanner: () => void }) {
  const { t, i18n } = useTranslation("driverEvSuitability");

  return (
    <div className="flex border border-Blue-astronaut p-2.5 justify-between bg-white mb-4">
      <div className="w-1/6 pt-1">
        <LightBulbIcon className="mr-3" />
      </div>
      <div className="text-Blue-astronaut text-base">
        {t("no-vehicle-banner")}
      </div>
      <div className="w-1/6 pt-1">
        <CloseIcon onClick={closeBanner} className="ml-3 cursor-pointer" />
      </div>
    </div>
  );
}

function EvNumber({
  fromPrice,
  evNumber,
}: {
  fromPrice?: number;
  evNumber?: number;
}) {
  const { localeCode, currencySymbol } = useLayout();
  const { t } = useTranslation("driverEvSuitability");
  const navigate = useNavigate();

  return (
    <div className="p-2">
      <div className="flex items-end">
        <div className="text-[60px] align-text-bottom leading-none">
          {evNumber || 0}
        </div>
        <div className="text-lg">EVs</div>
      </div>
      {fromPrice ? (
        <div className="text-lg text-Grey-dark">
          {" "}
          {t("from")} {fromPrice.toLocaleString(localeCode)} {currencySymbol}
        </div>
      ) : null}

      <div
        className="flex justify-between mt-4 items-center cursor-pointer"
        onClick={() => navigate("/ev-finder")}
      >
        <div className="text-Grey-dark underline">{t("explore-ev-finder")}</div>
        <ArrowRightIcon />
      </div>
    </div>
  );
}

export function SuitabilityCard({
  type,
  comfortCategory,
  userVehicle,
}: {
  type: SuitabilityType;
  comfortCategory?: ComfortCategoryData;
  userVehicle?: Vehicle;
}) {
  const { t, i18n } = useTranslation("driverEvSuitability");
  const language = i18n.language;
  const [hideBanner, setHideBanner] = useState(false);

  const displayNoOptionsBanner = !comfortCategory && !hideBanner;

  const cardProps = {
    [SuitabilityType.NO_RESTRICTIONS]: {
      title: t("no-restrictions.title"),
      color: {
        class: "bg-Green-default",
        hex: "#00AB58",
      },
      description: t("no-restrictions.description"),
    },
    [SuitabilityType.VERY_COMFORTABLE]: {
      title: t("very-comfortable.title"),
      color: {
        class: "bg-Green-secondary",
        hex: "#82BA3A",
      },

      description: t("very-comfortable.description"),
    },
    [SuitabilityType.FAIRLY_COMFORTABLE]: {
      title: t("fairly-comfortable.title"),
      color: {
        class: "bg-Blueberry-light-default",
        hex: "#B886A6",
      },
      description: t("fairly-comfortable.description"),
    },
    [SuitabilityType.ADAPTATIONS_NEEDED]: {
      title: t("adaptations-needed.title"),
      color: {
        class: "bg-Blueberry-dark-default",
        hex: "#830751",
      },
      description: t("adaptations-needed.description"),
    },
  };

  const props = useMemo(() => {
    return cardProps[type];
  }, [type, language]);

  return (
    <div className="flex flex-col w-full overflow-hidden rounded-md h-[400px]">
      <div className={`flex w-full h-[10px] ${props.color.class}`} />
      <div className="flex flex-col w-full h-full bg-Grey-background p-5 px-6 items-center">
        <h1 className="text-2xl font-normal mb-4">{props.title}</h1>
        {displayNoOptionsBanner ? (
          <NoVehiclesBanner closeBanner={() => setHideBanner(true)} />
        ) : (
          <>
            <div className="text-Grey-dark text-sm">{props.description}</div>
            <div className="w-1/3 mb-4">
              <SuitabilityRange type={type} color={props.color.class} />
            </div>
          </>
        )}
        <div className="flex w-full h-full justify-between items-end">
          <div className="w-1/3 mr-4">
            <EvNumber
              evNumber={comfortCategory?.countOfEvs}
              fromPrice={comfortCategory?.minimumPrice}
            />
          </div>
          <div className="w-2/3 h-full">
            <SuitableEvCard
              vehicle={comfortCategory?.evToDisplay}
              color={props.color}
              userVehicle={userVehicle}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
