import { Tooltip } from "../../../../../../electrify_frontend_common/components/Tooltip";
import { ReactComponent as InformationIcon } from "../../../../../../assets/information-icon-blueberry.svg";
import { useTranslation } from "react-i18next";

export function InformationBanner({
  tooltipContent,
}: {
  tooltipContent: string;
}) {
  const { t } = useTranslation("driverOnboarding");

  return (
    <div className="flex items-center mt-6">
      <div className="mr-2">{t("info-banner-disclaimer")}</div>
      <Tooltip placement="top-start" content={<div className=" w-52 ">{tooltipContent}</div>}>
        <InformationIcon />
      </Tooltip>
    </div>
  );
}
