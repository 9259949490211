


export function LightningIcon({ color = "black" }: { color?: string }) {


    return (
        <svg width="22" height="22" viewBox="5 5 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24.7176 17.1708C24.8321 16.9848 24.8948 16.7717 24.8994 16.5534C24.904 16.3351 24.8502 16.1195 24.7437 15.9289C24.6372 15.7382 24.4818 15.5795 24.2935 15.4689C24.1052 15.3584 23.8908 15.3001 23.6724 15.3H19.5V4.5L10.6824 18.8304C10.5679 19.0163 10.5051 19.2294 10.5005 19.4477C10.4959 19.6659 10.5496 19.8815 10.6562 20.0721C10.7627 20.2626 10.9182 20.4213 11.1065 20.5317C11.2949 20.6421 11.5093 20.7002 11.7276 20.7H15.9V31.5L24.7176 17.1708Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )

}