/* Third-party Imports */
import { useMutation } from "@apollo/client";

/* Local Imports */
import { useTranslation } from "react-i18next";
import { UPDATE_SETTING } from "../../services/graphql/fleet/mutations";

export default function useUpdateLanguage() {
    const { i18n } = useTranslation();

    const [updateSetting] = useMutation(UPDATE_SETTING, {
        onError: (error) => console.log("ERROR>>>", error),
    });

    const updateLanguage = async (newLang: string) => {
        try {
            i18n.changeLanguage(newLang);
            localStorage.setItem("languageCode", newLang);
            document.documentElement.lang = newLang;
            updateSetting({
                variables: {
                    setting: { language: newLang },
                },
            });
        } catch (error: any) {
            console.log("Error updating language");
        }
    };

    return {
        updateLanguage,
    };
}
