import { Table, TableCell, TableHead, TableRow } from "@mui/material";
import { DynamicTripRow } from "./rows";
import { useTranslation } from "react-i18next";
import { TripSimulationDetail } from "../../../../../../../@types/driver/simulations";


export function TripSimulationTable({ trips } : { trips: TripSimulationDetail[]}) {

    const { t } = useTranslation("tripSimulation");
   

    return (
            <Table
                className="mt-4 px-24"
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
            >
                <TableHead>
                    <TableRow hover={false} className="sticky top-0 bg-white">
                        <TableCell align="left" sortDirection={false}>{t("table.headers.before-trip")}</TableCell>
                        <TableCell align={"left"} padding={"normal"} sortDirection={false}>{t("table.headers.trip")}</TableCell>
                        <TableCell align={"left"} padding={"normal"} sortDirection={false}>{t("table.headers.trip-start")}</TableCell>
                        <TableCell align={"left"} padding={"normal"} sortDirection={false}>{t("table.headers.trip-duration")}</TableCell>
                        <TableCell align={"left"} padding={"normal"} sortDirection={false}>{t("table.headers.distance")}</TableCell>
                        <TableCell align={"left"} padding={"normal"} sortDirection={false}>{t("table.headers.from")}</TableCell>
                        <TableCell align={"left"} padding={"normal"} sortDirection={false}>{t("table.headers.to")}</TableCell>
                        <TableCell align={"left"} padding={"normal"} sortDirection={false}></TableCell>
                        <TableCell align={"left"} padding={"normal"} sortDirection={false}>{t("table.headers.after-trip")}</TableCell>
                    </TableRow>
                </TableHead>
                {trips?.map((trip) => {
                    return (<>
                        {<DynamicTripRow key={trip.eventNumber} trip={trip} />}
                    </>);
                })}
            </Table>
    )


}