import React, { ReactElement } from 'react'

export default function Form({
  className,
  title,
  description,
  children,
  onSubmit,
  maxWidth,
  id,
} : {
    className?: string,
    title?:string,
    description?: string,
    children: ReactElement,
    onSubmit?: () => void,
    maxWidth?: string,
    id?: string
}) {
  return (
    <div style={{ maxWidth: maxWidth || '14000px' }}>
      <div className="grid grid-rows-8 md:gap-6 gap-4">
        {(title || description) && (
          <div className="row-span-1">
            <div className="sm:px-0">
              <div>
                <h3 className="text-lg font-medium leading-6">{title}</h3>
              </div>
              {description && (
                <div className="mt-4">
                  <div className="text-sm text-secondary-700">
                    {description}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className={className || 'p-4 ring-secondary-200 ring-1'}>
          <div className="">
            <div className="space-y-6">
              <form
                id={id}
                onSubmit={onSubmit}
                className="grid grid-cols-6 gap-6 auto-cols-fr"
              >
                {children}
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
