import { useUserData } from '../../../../hooks/state/useUserData';
import { useNavigate } from 'react-router-dom';
import useAuthenticate from '../../../../hooks/effects/useAuthenticate';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../../electrify_frontend_common/components/Dropdown';

const buildName = (name?: string) => {
	if (!name) return '';
	const splitted = name.split(' ');
	if (splitted.length > 1) {
		const firstName = splitted[0];
		const lastName = `${splitted[splitted.length - 1].charAt(0)}.`;
		return `${firstName} ${lastName}`;
	}

	return name;
};

export default function UserDropdown() {
	const { user, setUser } = useUserData();
	const { logoutUser } = useAuthenticate({ setUser });

	const { t } = useTranslation('common');

	const navigate = useNavigate();

	const name = buildName(user?.name);

	return (
		// <div className="border rounded h-12 flex justify-center items-center mr-4 text-base border-Grey-tint text-Black-default">
		<Dropdown
			className="border rounded h-12 flex w-28 justify-center items-center mr-4 text-base border-Grey-tint text-Black-default"
			buttonClassName="w-28"
			// dropdownCustomClassName="flex w-full justify-center"
			itemsClassName="justify-center"
			placeholder={name}
			data={[
				{
					key: t('sign-out'),
					onClick: () => {
						localStorage.removeItem('accessToken');
						localStorage.removeItem('refreshToken');
						logoutUser();
						navigate('/');
					},
				},
			]}
		/>
	);
}
