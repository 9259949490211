

import { useQuery } from "@apollo/client";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as CalendarIcon } from '../../../assets/calendar-icon.svg';
import { GET_VEHICLE_VALID_ANALYSIS_PERIOD } from "../../../services/graphql/fleet/queries";
import Input from "../misc/Input";
import { useLayout } from "../../../hooks/state/useLayout";

function useDebounce() {

    const [triggered, setTriggered] = useState(false);

    const debounce = (func: any, wait: number) => {
        if(!triggered) {
            func();
            setTriggered(true);
            setTimeout(() => {
                setTriggered(false);
            }, wait);
        }
    }

    return {
        debounce
    }
}

export default function VehicleAnalysisPeriod({ 
    vehicleId,
    analysisFrom,
    analysisTo,
    onChangeAnalysisFrom,
    onChangeAnalysisTo
}: {
    vehicleId?: string,  
    analysisFrom?: string | null,
    analysisTo?: string | null,
    onChangeAnalysisFrom: (date?: string | null)   => void,
    onChangeAnalysisTo: (date?: string | null)   => void
}) {

    const { t } = useTranslation('editVehicle');
    const [validPeriod, setValidPeriod] = useState<{ min: string, max: string } | null>(null);
    const {localeCode} = useLayout();
    const { debounce } = useDebounce();

    useQuery(GET_VEHICLE_VALID_ANALYSIS_PERIOD, {
        variables: {
            id: vehicleId
        },
        onCompleted: (response) => {
            const data = response.vehicleSummary;
            const minSelectableDate = data.earliestTripDate.split('T')[0];
            const maxSelectableDate = data.latestTripDate.split('T')[0];
            setValidPeriod({ min: minSelectableDate, max: maxSelectableDate });
        }
    })

    const getValidAnalysisFrom = () =>  ({ min: validPeriod?.min, max: analysisTo ? analysisTo : validPeriod?.max })

    const getValidAnalysisTo = () => ({ min: analysisFrom ? analysisFrom : validPeriod?.min, max: validPeriod?.max });

    const takeInitialDateForAnalysisFrom = (e: any) => {
        console.log("======> Triggered analysis from")
        if (!e.target.checked) {
            console.log("Setting value: ", getValidAnalysisFrom().min);
            onChangeAnalysisFrom(getValidAnalysisFrom().min);
        } else {
            onChangeAnalysisFrom(null);
        }
    }

    const takeFinalDateForAnalysisTo = (e: any) => {
        console.log("======> Triggered analysis to")
        if (!e.target.checked) {
            onChangeAnalysisTo(getValidAnalysisTo().max);
        } else {
            onChangeAnalysisTo(null);
        }
    }



    return (
        
            <div className="flex w-full">
                
                <div className="w-1/2">
                    <Input
                        disabled={!analysisFrom}
                        value={analysisFrom || ""}
                        onChange={(e) => {
                            debounce(()=> onChangeAnalysisFrom(e.target.value), 200)
                        }}
                        className="p-2"
                        label={t('vehicle-analyse-from')}
                        type={'date'}
                        
                        min={getValidAnalysisFrom().min}
                        max={getValidAnalysisFrom().max}
                        customIcon={<CalendarIcon />}
                    />

                    <div className="p-2">
                        <input
                            type="checkbox"
                            className="mr-2 w-4 h-4 cursor-pointer text-Blueberry-dark-default bg-transparent rounded border-Blueberry-dark-default focus:ring-0 focus:outline-offset-0 focus:outline-0 focus:ring-offset-0"
                            checked={!analysisFrom}
                            onClick={takeInitialDateForAnalysisFrom}
                        />
                        <span>{t('take-initial-date')}</span>
                    </div>
                </div>
                <div className="w-1/2">
                    <Input
                        disabled={!analysisTo}
                        value={analysisTo || ""}
                        onChange={(e) => {
                            debounce(()=> onChangeAnalysisTo(e.target.value), 200);
                        }}
                        className="p-2"
                        label={t('vehicle-analyse-to')}
                        type={'date'}
                        min={getValidAnalysisTo().min}
                        max={getValidAnalysisTo().max}
                        customIcon={<CalendarIcon />}
                    />
                    <div className="p-2">
                        <input
                            type="checkbox"
                            className="mr-2 w-4 h-4 cursor-pointer text-Blueberry-dark-default bg-transparent rounded border-Blueberry-dark-default focus:ring-0 focus:outline-offset-0 focus:outline-0 focus:ring-offset-0"
                            checked={!analysisTo}
                            onClick={takeFinalDateForAnalysisTo}
                        />

                        <span>{t('take-ongoing-analysis')}</span>
                    </div>
                </div>

            </div>
    
    )

}