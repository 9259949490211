/* react imports */
import { useEffect, useState } from "react";

/* third party imports */
import { useTranslation } from "react-i18next";

/* local imports */
import OnboardingModal from "./OnboardingModal";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import onboardingLogo from "../../../../../assets/onboarding-circle.webp";
import chargylizeLogoBlack from "../../../../../assets/chargylize_logo_black.svg";

import { ReactComponent as ArrowRight } from "../../../../../assets/arrow_right_strong_white.svg";
import { useLayout } from "../../../../../hooks/state/useLayout";
import { useUserData } from "../../../../../hooks/state/useUserData";
import { Button } from "../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../electrify_frontend_common/components/Buttonv2/types";

export function OnboardingPage() {

    const { t } = useTranslation("driverOnboarding");
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    
    const { setHeader } = useLayout();
    const [onboardingModalOpened, setOnboardingModalOpened] = useState(false);
    const { user, refreshUserAccountInfo } = useUserData();

    useEffect(() => {
        setHeader({
            defaultHeaderTitle: "",
            defaultHeaderDescription: "",
            defaultHeaderOptions: undefined,
        });
        if(searchParams.get('force-open')) {
            setOnboardingModalOpened(true);
            searchParams.delete('force-open');
            setSearchParams(searchParams);
        }
    }, []);

    return (
        <>
            {onboardingModalOpened ? (
                <OnboardingModal
                    isOpen={onboardingModalOpened}
                    goToPageProps={{
                        action: () => navigate("/vehicles"),
                        text: "Go to vehicles",
                    }}
                    startingStep={user?.accountInfo?.lastOnboardingStep || 0}
                    onCancel={() => {
                        refreshUserAccountInfo();
                        setOnboardingModalOpened(false);
                    }}
                />
            ) : null}
            <div className="flex justify-center pt-12 px-40 text-center">
                <div className="flex flex-col items-center">
                    <img src={onboardingLogo} />
                    <p className="text-3xl mt-10 mb-4">
                        {t("welcome-page.hi")} {user?.name}
                    </p>
                    <div className="flex mb-8 items-center justify-center">
                        <p className="text-3xl mr-2">
                            {t("welcome-page.welcome")}{" "}
                        </p>
                        <img
                            width={250}
                            src={chargylizeLogoBlack}
                            alt="chargylize-logo"
                        />
                    </div>

                    <div className="flex flex-col items-center">
                        <p>{t("welcome-page.message-1")}</p>

                        <p className="pt-8">{t("welcome-page.message-2")}</p>
                    </div>

                    <div className="flex justify-center mt-12">
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.LARGE}
                            onClick={() => setOnboardingModalOpened(true)}
                        >
                            <div className="flex items-center">
                                {t("welcome-page.button-text")}{" "}
                                <ArrowRight className="ml-2" />
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
}
