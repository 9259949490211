import { WrapperGuard } from '../guards';
import { PublicRouter } from '../common/pages/public';
import { BrowserRouter as Router } from 'react-router-dom';
import { UserType } from '../enums';
import { useUserData } from '../hooks/state/useUserData';
import { useFetchAccountInfo } from '../hooks/effects/useFetchAccountInfo';
import { useLanguageSync } from '../hooks/effects/useLanguageSync';
import { B2CProductsGateway } from './B2C';
import { B2BProductsGateway } from './B2B';
import { B2CProductTreeContextProvider } from './B2C/contexts/B2CProductTreeContext';
import { Spinner } from '../electrify_frontend_common/components/Spinner';

function B2CProduct() {


    return (
        <B2CProductTreeContextProvider>
            <B2CProductsGateway />
        </B2CProductTreeContextProvider>
    )

}



export function ElectrifyUserTypeGateway() {

    useFetchAccountInfo();
    useLanguageSync();

    const { user } = useUserData();

    const availableProducts = {
        [UserType.FLEET]: <B2BProductsGateway />,
        [UserType.DRIVER]: <B2CProduct />,
    };


    return (
        <div className="h-screen overflow-hidden">
            <WrapperGuard
                accessCondition={!!user}
                guardView={<PublicRouter />}
                dependencies={[!!user]}
            >
                <Router>
                    {user?.user_type ? availableProducts[user?.user_type] : <Spinner />}
                </Router>
            </WrapperGuard>
        </div>
    );
}
