
import { Button } from '../../../../../../electrify_frontend_common/components/Buttonv2';
import { ButtonSize, ButtonVariant } from '../../../../../../electrify_frontend_common/components/Buttonv2/types';
import {ReactComponent as ArrowLeft} from '../../../../../../assets/arrow-left-grey.svg';

export function PrevButton({ text, onClick }: { text: string, onClick: ()=> void }) {



    return (
        <Button
            variant={ButtonVariant.TERTIARY}
            size={ButtonSize.LARGE}
            onClick={onClick}
        >
            <div className="flex items-center">
                <ArrowLeft className="mr-2"/>
                {text}
            </div>

        </Button>
    )


}