import { useContext } from "react";
import { FleetVehicleComparator } from "./FleetVehicleComparator";
import { FleetSimulationContext } from "../../../../../../contexts/FleetSimulation";
import { VehicleComparatorContextProvider } from "../../../../../../contexts/VehicleComparatorContext";


export function FleetVehicleComparatorList() {

  const { originalFleetVehicles } = useContext(FleetSimulationContext);

  return (

    <div
      className={`px-10 py-2 grid grid-rows-${originalFleetVehicles?.length} grid-row-flow`}
    >
      {originalFleetVehicles?.map((item, idx) => {
        return (
          <VehicleComparatorContextProvider
            key={item.vehicleId}
            vehicle={item}>
            <FleetVehicleComparator />
          </VehicleComparatorContextProvider>
        );
      })}
    </div>
  )

}
