import { ReactElement } from "react";


export function MetricsDisplay({
    Icon,
    value,
    units,
    property,
    backgroundColor = "light"
}: {
    Icon?: ReactElement;
    value: any;
    units: string;
    property: any;
    backgroundColor?: "light" | "dark";
}) {
    return (
        <div className="flex text-sm items-center">
            <div className="mr-4">{Icon}</div>
            <div className={`${backgroundColor === "light" ? "text-Grey-dark" : "text-white"}`}>
                <div>
                    <span className={`${backgroundColor === "light" ? "text-black" : "text-white"} font-bold`}>{value}</span> {units}
                </div>
                <div>{property}</div>
            </div>
        </div>
    );
};

export function Table({ Icon, title, data, footNote, backgroundColor = "light" }: { Icon: any, title: string, data: any, footNote?: any, backgroundColor?: "light" | "dark" }) {

    return (
        <div className="w-full flex mt-12">
            <div className="w-1/3">
                <div className="flex items-center">
                    <div className="mr-4">{Icon}</div>
                    <div className={`${backgroundColor === "light" ? "text-Blueberry-dark-default" : " text-Blueberry-light-default"}`}>{title}</div>
                </div>
            </div>

            <div className="w-2/3 text-sm">
                {data?.map((d: any, idx: number) => {
                    const isEven = idx % 2 == 0;
                    const color = isEven ?
                        backgroundColor === "light" ? "bg-Grey-background" : "bg-Black-default"
                    : null;

                    return (
                        <div
                            className={`flex p-2 py-1 ${backgroundColor == "light" ? "" : "text-white"} ${idx % 2 == 0 ? color : null
                                }`}
                        >
                            <div className="w-2/3">{d.prop}</div>
                            <div className="w-1/3">
                                <span className="font-bold mr-1">{d.value || "-"}</span>{" "}
                                {d.value ? d.units : null}
                            </div>
                        </div>
                    )
                })}
                <div className={` mt-5 text-xs ${backgroundColor === "light" ? "text-Grey-dark" : "text-white"}`}>{footNote}</div>
            </div>
        </div>
    );
}