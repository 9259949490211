import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ParkingTypeChart from "./ParkingType/ParkingTypeChart";
import WorkplaceParkingOccupationChart from "./WorkplaceParkingOccupation/WorkplaceParkingOccupationChart";
import { WorkplaceParkingOccupationMaxVehiclesChart } from "./WorkplaceParkingOccupationMaxVehicles/WorkplaceParkingOccupationMaxVehiclesChart";
import ChartModal from "../../../../../../../common/components/Charts/ChartModal";

export default function ExpandedParkingAnalysis({
  expandedMode,
  chartData,
  closeExpanded,
  incrementPage,
  decrementPage,
  numberOfPages,
  sortFunctions,
  sortStates,
} : {
  expandedMode: {page: number, open: boolean},
  chartData: any,
  closeExpanded: ()=> void,
  incrementPage: ()=> void,
  decrementPage: ()=> void,
  numberOfPages: number,
  sortFunctions: any,
  sortStates: any
}) {
  const { t } = useTranslation("parkingAnalysis");

  const [page, setPage] = useState<number | null>(null);

  console.log("Received graph data: ", chartData);

  useEffect(() => {
    setPage(expandedMode?.page);
  }, [expandedMode]);

  const buildExpandedViewContent = (customPage?: number) => {
    const caseVar = customPage ?? page;
    switch (caseVar) {
      case 0:
        return {
          getTitle: () => t("parking-type-title"),
          getChart: () => (
            <ParkingTypeChart
              data={chartData?.dataSets?.parkingTypeDatasets}
              isModal
            />
          ),
          getSortFunction: () => sortFunctions.parkingType,
          getIsCrescent: () => sortStates.parkingType,
        };
      case 1:
        return {
          getTitle: () => t("workplace-parking-occupation-title"),
          getChart: () => (
            <WorkplaceParkingOccupationChart
              data={{
                dataTime:
                chartData?.dataSets?.parkingWorkplaceOccupationTimesDatasets,
                dataStays:
                chartData?.dataSets?.parkingWorkplaceOccupationStaysDatasets,
              }}
              isModal
            />
          ),
          getIsCrescent: () => null,
        };
      case 2:
        return {
          getTitle: () => t("workplace-vehicles-count-title"),
          getChart: () => (
            <WorkplaceParkingOccupationMaxVehiclesChart
              data={{
                labelsMonth:
                chartData?.labels?.parkingWorkplaceOccupationMonthLabels,
                labelsYear:
                chartData?.labels?.parkingWorkplaceOccupationYearLabels,
                dataMonth:
                chartData?.dataSets?.parkingWorkplaceOccupationMonthDatasets,
                dataYear:
                chartData?.dataSets?.parkingWorkplaceOccupationYearDatasets,
              }}
              isModal
            />
          ),
          getIsCrescent: () => null,
        };
      default:
        return null;
    }
  };

  return (
    <>
      {page !== null ? (
        <ChartModal
          title={buildExpandedViewContent()?.getTitle()}
          open={expandedMode.open}
          onCancel={() => {
            closeExpanded();
            setPage(null);
          }}
          chart={buildExpandedViewContent()?.getChart()}
          incrementPage={incrementPage}
          decrementPage={decrementPage}
          numberOfPages={numberOfPages}
          previousChartName={buildExpandedViewContent(page - 1)?.getTitle()}
          nextChartName={buildExpandedViewContent(page + 1)?.getTitle()}
          currentPage={page}
          sortFunction={
            buildExpandedViewContent()?.getSortFunction 
              ? (buildExpandedViewContent()?.getSortFunction as any)()
              : null
          }
          isCrescent={buildExpandedViewContent()?.getIsCrescent()}
        />
      ) : null}
    </>
  );
}
