import { ChartOptions } from 'chart.js';

export const options = (localeCode: string, labels: string[]): ChartOptions<'bar'> => ({
    plugins: {
        tooltip: {
            callbacks: {
                title: function (tooltipItems) {
                    const date = new Date(tooltipItems[0].label);
                    return `${date.toLocaleDateString(localeCode)} (${date.toLocaleDateString(localeCode, { weekday: "long" })})`;
                },
                label: function (tooltipItem: any) {
                    return tooltipItem.raw.toLocaleString(localeCode, { maximumFractionDigits: 1 }) + " h";
                }
            }
        },
    },
    layout: {
        padding: {
            left: 5,
            right: 10,
            top: 50,
        }
    },
    scales: {
        x: {
            stacked: true,
            beginAtZero: true,
            border: {
                display: false,
            },
            grid: {
                display: true,
                drawOnChartArea: false,
                drawTicks: true,
                color: "border-Grey-dark",
            },
            ticks: {
                minRotation: 45,
                callback: function (value, index) {
                    const date = new Date(labels[index]);
                    return date.getDay() === 1 ? date.toLocaleDateString(localeCode) : null;
                },
                maxTicksLimit: 100,
            }
        },
        y: {
            stacked: true,
            beginAtZero: true,
            max: 24,
            grid: {
                display: false,
            },
            ticks: {
                callback: function (value) {
                    return value.toLocaleString(localeCode, { maximumFractionDigits: 1 }) + " h";
                },
                maxTicksLimit: 8,
            }
        }
    }
});
