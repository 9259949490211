import { TableCell, TableRow } from "@mui/material";
import { Battery, EmptyTableCell, LocationIcon, TooltipWrappedContent } from "../../components";
import { useTranslation } from "react-i18next";
import { ReactComponent as SuggestionCharging } from '../../../../../../../../../assets/suggestion-charging.svg';
import { TripSimulationDetail } from "../../../../../../../../../@types/driver/simulations";


export const ExtraChargeRow = ({ trip } : {trip : TripSimulationDetail}) => {

    const { t } = useTranslation("tripSimulation");

    return (
        <TableRow className={`bg-Blueberry-dark-default `}>
            <TableCell align={"left"} padding={"normal"}>
                <Battery percentage={trip?.startSoc > 0 ? trip?.startSoc : 0} />
            </TableCell>
            <TableCell align={"left"} padding={"normal"}>
                <TooltipWrappedContent text={t("charging-suggested")}><SuggestionCharging /></TooltipWrappedContent>
            </TableCell>
            <TableCell colSpan={3} align={"left"} padding={"normal"} className="text-white">
                {t("suggested-charge-amount", { charge: trip?.chargedEnergy.toFixed(1), startBattery: trip?.startSoc.toFixed(0), endBattery: trip?.endSoc?.toFixed(0), count: trip?.countOfExtraStops })}
            </TableCell>
            <EmptyTableCell />
            <EmptyTableCell />
            <TableCell align={"left"} padding={"normal"}>
                <LocationIcon locationType={trip?.chargingPossibility} />
            </TableCell>
            <TableCell align={"left"} padding={"normal"}>
                <Battery percentage={trip?.endSoc && trip?.endSoc > 0 ? trip?.endSoc : 0} eventType={trip.eventType} />
            </TableCell>
        </TableRow>
    )
}

export const ChargeRow = ({ trip } : {trip : TripSimulationDetail}) => {

    const { t } = useTranslation("tripSimulation");

    const locationType = trip?.locationType ? trip?.locationType?.charAt(0) + trip?.locationType.slice(1).toLowerCase() : '';

    return (
        <TableRow className={`bg-Grey-tint`} >
            <TableCell align={"left"} padding={"normal"}>
                <Battery percentage={trip?.startSoc > 0 ? trip?.startSoc : 0} />
            </TableCell>
            <EmptyTableCell />
            <TableCell colSpan={3} align={"left"} padding={"normal"}>
                {t("required-charge-amount", { charge: trip?.chargedEnergy.toFixed(1), startBattery: trip?.startSoc.toFixed(0), endBattery: trip?.endSoc?.toFixed(0), location: locationType })}
            </TableCell>
            <EmptyTableCell />
            <EmptyTableCell />
            <TableCell align={"left"} padding={"normal"}>
                <LocationIcon locationType={trip?.chargingPossibility} />
            </TableCell>
            <TableCell align={"left"} padding={"normal"}>
                <Battery percentage={trip?.endSoc && trip?.endSoc > 0 ? trip?.endSoc : 0} eventType={trip.eventType} />
            </TableCell>
        </TableRow>
    )
}