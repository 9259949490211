import { useState } from "react";

import { InformationBanner } from "../utils/InformationBanner";
import { DriverVehicleEditor } from "../../Settings/CurrentVehicle/VehicleEditor";
import { PrevButton } from "../utils/PrevButton";
import { NextButton } from "../utils/NextButton";
import { VehicleInfo } from "../../Settings/CurrentVehicle";
import { useTranslation } from "react-i18next";
import { VehicleWithYearlyStats } from "../../../../../../@types/vehicle";
import { Button } from "../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../electrify_frontend_common/components/Buttonv2/types";
import { EventBanner, EventState } from "../../../../../../common/components/misc/EventBanner";


export function VehicleModel({
  step,
  nextStep,
  prevStep,
  vehicle,
  refetchVehicle,
}: {
  step: number;
  nextStep: () => void;
  prevStep: () => void;
  vehicle?: VehicleWithYearlyStats;
  refetchVehicle: any;
}) {
  const [vehicleEditorOpen, setVehicleEditorOpen] = useState(false);
  const { t } = useTranslation("driverOnboarding");
  const { t: tS } = useTranslation("driverSettings");

  return (
    <>
      <DriverVehicleEditor
        isOpen={vehicleEditorOpen}
        vehicle={vehicle}
        close={() => {
          refetchVehicle();
          setVehicleEditorOpen(false);
        }}
      />

      <div className="p-10 w-[1000px]">
        <div className="text-xl">
          <span className="text-Blueberry-dark-default ">
            {t("step")} {step}:
          </span>{" "}
          {t("step-vehicle-model.title-1")}{" "}
          <span className="font-bold">{t("step-vehicle-model.title-2")}</span>{" "}
          {t("step-vehicle-model.title-3")}
        </div>
        <div className="flex w-5/6 text-sm text-Grey-dark py-2">
          {t("step-vehicle-model.message")}
        </div>
        {vehicle?.modelInfo?.brand ? (
          <div className="mt-8">
            <VehicleInfo
              warningOpen={false}
              openModal={() => null}
              closeWarning={() => null}
              vehicle={vehicle}
            />
          </div>
        ) : (
          <div className="flex bg-Grey-background justify-center w-full mt-2 py-20">
            <Button
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.LARGE}
              onClick={() => setVehicleEditorOpen(true)}
            >
              {t("step-vehicle-model.button-text")}
            </Button>
          </div>
        )}
        {vehicle?.modelInfo?.brand ? (
          <div className="mt-4">
            {vehicle?.modelInfo?.fuelConsumption?.combined ? (
              <EventBanner
                content={<>{t("step-vehicle-model.success-message")}</>}
                state={EventState.SUCCESS}
              />
            ) : (
              <EventBanner
                content={
                  <div>
                    <div className="mb-4">
                      {tS("current-vehicle.warning-no-fuel-1")}
                    </div>
                    <div>
                      {tS("current-vehicle.warning-no-fuel-2")}{" "}
                      <span
                        onClick={() => setVehicleEditorOpen(true)}
                        className=" cursor-pointer underline"
                      >
                        {tS("current-vehicle.warning-select-manually")}
                      </span>
                    </div>
                  </div>
                }
                state={EventState.WARNING}
              />
            )}
          </div>
        ) : (
          <InformationBanner tooltipContent="Vehicle model information" />
        )}

        <div className="flex justify-between mt-10">
          <PrevButton text={t("back")} onClick={prevStep} />
          <NextButton
            text={
              vehicle?.modelInfo?.fuelConsumption?.combined
                ? t("next")
                : t("skip")
            }
            active={!!vehicle?.modelInfo?.fuelConsumption?.combined}
            onClick={nextStep}
          />
        </div>
      </div>
    </>
  );
}
