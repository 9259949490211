

export const borderToRemove = (position?: "FIRST" | "LAST") => {
    if (position === "FIRST") {
        return 'border-r-0'
    } else if (position === "LAST") {
        return 'border-l-0'
    } else {
        return ''
    }
}

export const rounded = (position: "FIRST" | "LAST" | undefined, verticalPos: "TOP" | "BOTTOM") => {
    if (position == "FIRST") {
        if (verticalPos == "TOP") return 'rounded-tl'
        else if (verticalPos == "BOTTOM") return 'rounded-bl';
    } else if (position == "LAST") {
        if (verticalPos == "TOP") return 'rounded-tr'
        else if (verticalPos == "BOTTOM") return 'rounded-br';
    }
}
