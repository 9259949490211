import chargylizeLogoBlack from "../../../../assets/chargylize_logo_black.svg";
import { useNavigate } from "react-router-dom";

export function Logo({ className }: { className?: string }) {
    const navigate = useNavigate();

    return (
        <div className={className}>
            <img
                src={chargylizeLogoBlack}
                alt="chargylize-logo"
                className="h-6 hover:cursor-pointer"
                onClick={() => navigate("/")}
            />
        </div>
    );
}
