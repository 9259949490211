import { useTranslation } from "react-i18next";
import { IuseForm } from "../../../../../../../hooks/utils/useForm";
import { VehicleInput } from "../../../../../../../@types/vehicle";
import VehicleAnalysisPeriod from "../../../../../../../common/components/VehicleAnalysisPeriod";



export default function FleetVehicleAnalysisPeriod({ vehicleId, form }: { vehicleId: string, form: IuseForm<VehicleInput> }) {

    const { t } = useTranslation('editVehicle');


    return (
        <div className="h-auto px-8">
            <h1 className="text-xl mb-4">{t('vehicle-analysis-period')}</h1>
            <VehicleAnalysisPeriod
                vehicleId={vehicleId}
                analysisFrom={form.formState?.analysisPeriod?.from}
                analysisTo={form.formState?.analysisPeriod?.to}
                onChangeAnalysisFrom={(val?: string | null) => { form.updateFormState({ analysisPeriod : {from: val} }) }}
                onChangeAnalysisTo={(val?: string | null) => { form.updateFormState({ analysisPeriod : {to: val} }) }}
            />
        </div>
    )

}