

export function FuelCostsIcon({color = "black", className} : {color?: string, className?: string}) {


    return (
        <svg className={className} width="16" height="16" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.4375 3.9375H5.1875C4.49714 3.9375 3.9375 4.49714 3.9375 5.1875V30.8125C3.9375 31.5029 4.49714 32.0625 5.1875 32.0625H21.4375C22.1279 32.0625 22.6875 31.5029 22.6875 30.8125V5.1875C22.6875 4.49714 22.1279 3.9375 21.4375 3.9375Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.9375 9.5625C18.9375 9.06522 18.74 8.58831 18.3883 8.23667C18.0367 7.88504 17.5598 7.6875 17.0625 7.6875H9.5625C9.06522 7.6875 8.58831 7.88504 8.23667 8.23667C7.88504 8.58831 7.6875 9.06522 7.6875 9.5625C7.6875 10.0598 7.88504 10.5367 8.23667 10.8883C8.58831 11.24 9.06522 11.4375 9.5625 11.4375H17.0625C17.5598 11.4375 18.0367 11.24 18.3883 10.8883C18.74 10.5367 18.9375 10.0598 18.9375 9.5625Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.9375 15.1875H22.6875" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M22.6875 9.5625H24.5625C25.0598 9.5625 25.5367 9.76004 25.8883 10.1117C26.24 10.4633 26.4375 10.9402 26.4375 11.4375V30.1875C26.4375 30.6848 26.635 31.1617 26.9867 31.5133C27.3383 31.865 27.8152 32.0625 28.3125 32.0625C28.8098 32.0625 29.2867 31.865 29.6383 31.5133C29.99 31.1617 30.1875 30.6848 30.1875 30.1875V18.9375C30.1874 17.7141 30.5492 16.5181 31.2275 15.5L32.0625 14.25" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M30.1875 18.9375H32.0625" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )


}