import Header from "./Header/index";
import { createContext, Dispatch, ReactElement, SetStateAction, useState } from "react";
import { Logo } from "./Logo";


export const NavbarContext = createContext<{
    subMenuOpen: number | null,
    setSubMenuOpen: Dispatch<SetStateAction<number | null>>
}>({
    subMenuOpen: null,
    setSubMenuOpen: () => null
})

const NavbarContextProvider = ({ children }: { children: React.ReactNode }) => {

    const [subMenuOpen, setSubMenuOpen] = useState<number | null>(null);

    return (
        <NavbarContext.Provider value={{
            subMenuOpen,
            setSubMenuOpen
        }}>
            {children}
        </NavbarContext.Provider>
    )
}


export default function LayoutWrapper({ children, navbar }: { children: ReactElement, navbar: ReactElement }) {

    return (
        <div className="w-full h-full" style={{ backgroundColor: "#F7F8FA" }}>
            <div className="w-full h-full max-w-screen-am-wrap mx-auto flex flex-1 bg-Grey-background">
                <div className={"hidden sm:block sm:col-span-1 md:col-span-3 xl:w-[22%]"} >
                    <div className={`sm:px-6 md:px-10 lg:px-5 flex flex-col w-full`}>
                        <Logo className="pt-11 pb-10 pl-3" />
                        <NavbarContextProvider>
                            <> {navbar}</>
                        </NavbarContextProvider >
                    </div>
                </div>
                <div className="w-[100%] sm:col-span-11 md:col-span-9 xl:w-[78%] bg-white flex flex-col h-screen">
                    <Header navbar={
                        <div className={`sm:px-6 md:px-10 lg:px-5 flex flex-col w-full`}>
                            <Logo className="pt-11 pb-10 pl-3" />{navbar}
                        </div>
                    }/>
                    <div className="flex-grow overflow-auto">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}
