import EnFlagIcon from '../../../../assets/langs/English.svg';
import DeFlagIcon from '../../../../assets/langs/German.svg';
import PtFlagIcon from '../../../../assets/langs/Portuguese.svg';
import NlFlagIcon from '../../../../assets/langs/Dutch.svg';
import FrFlagIcon from '../../../../assets/langs/French.svg';
import EsFlagIcon from '../../../../assets/langs/Spanish.svg';
import ChFlasIcon from '../../../../assets/langs/Switzerland.svg';
import AtFlagIcon from '../../../../assets/langs/Austria.svg';
import { useTranslation } from 'react-i18next';
import useUpdateLanguage from '../../../../hooks/utils/useUpdateLanguage';
import Dropdown from '../../../../electrify_frontend_common/components/Dropdown';

export default function LanguageDropdown() {
	const { updateLanguage } = useUpdateLanguage();
	const { i18n } = useTranslation();

	const onClickLanguage = (item: string) => {
		updateLanguage(item);
	};

	const languageData = [
		{ icon: EnFlagIcon, languageCode: 'en' },
		{ icon: DeFlagIcon, languageCode: 'de' },
		//  {icon: PtFlagIcon, languageCode: 'pt'},
		//  {icon: NlFlagIcon, languageCode: 'nl'},
		//  {icon: FrFlagIcon, languageCode: 'fr'},
		//  {icon: EsFlagIcon, languageCode: 'es'},
		//  {icon: ChFlagIcon, languageCode: 'ch'},
		//  {icon: AtFlagIcon, languageCode: 'at'},

		// TODO add support for remaining languages when available
	];

	const getLanguagePlaceHolder = () => {
		switch (i18n.language) {
			case 'de':
				return <img src={DeFlagIcon} />;
			case 'en':
				return <img src={EnFlagIcon} />;
			case 'pt':
				return <img src={PtFlagIcon} />;
			case 'nl':
				return <img src={NlFlagIcon} />;
			case 'fr':
				return <img src={FrFlagIcon} />;
			case 'es':
				return <img src={EsFlagIcon} />;
			case 'ch':
				return <img src={ChFlasIcon} />;
			case 'at':
				return <img src={AtFlagIcon} />;

			// TODO add support for remaining languages when available
		}
	};

	return (
		<div className="border rounded mr-4 text-base flex items-center justify-center w-14 h-12 border-Grey-tint">
			<Dropdown
				className="w-full h-full justify-center items-center"
				itemsClassName="justify-center"
				placeholder={getLanguagePlaceHolder()}
				data={languageData.map((ld, i) => ({
					key: <img key={i} src={ld.icon} />,
					onClick: () => onClickLanguage(ld.languageCode),
				}))}
			/>
		</div>
	);
}
