import { useNavigate } from "react-router-dom";
import { ReactElement, SyntheticEvent, useContext, useMemo, useState } from "react";
import { CollapseIcon } from "../../../../../electrify_frontend_common/icons/CollapseIcon";
import { ExpandIcon } from "../../../../../electrify_frontend_common/icons/ExpandIcon";
import { NavbarContext } from "../..";


const colors = {
  hovered: "#000",
  active: "#AB096A",
  regular: "#595E69",
  disabled: "#BFBFBF",
}

export const useColors = ({
  disabled,
  hovered,
  activeCondition,
  hasSubMenus,
  expanded
}: {
  disabled: boolean,
  hovered: boolean,
  activeCondition: boolean,
  hasSubMenus: boolean,
  expanded?: boolean
}) => {


  const contentStyle = useMemo(() => {
    if (disabled) return { color: colors.disabled }
    if (hovered && !activeCondition && !expanded && !disabled) return { color: colors.hovered };
    if (activeCondition) return { color: colors.active, fontWeight: 'bold' };
    return { color: colors.regular };

  }, [disabled, hovered, activeCondition])


  const backgroundColor = useMemo(() => {
    if (!activeCondition && hovered && !expanded && !disabled) return "bg-Grey-default";
    if (activeCondition && !hasSubMenus) return "bg-Grey-tint";
  }, [activeCondition, hovered])

  return {
    contentStyle,
    backgroundColor
  }


}


function IconAndText({
  textStyle,
  Icon,
  title
}: {
  textStyle: { color: string, font?: string };
  Icon: ReactElement;
  title: string
}) {


  return (
    <div className="flex flex-row w-full items-center break-all">
      <div className="mr-4">
        {Icon}
      </div>
      <span style={textStyle} className={`flex-auto text-xl`}>
        {title}
      </span>
    </div>
  )

}


function SubMenuCollapseAndExpand({
  expanded,
  toggleSubMenus,
  hovered,
  activeCondition,
  disabled
}: {
  expanded: boolean,
  toggleSubMenus: (e: SyntheticEvent) => void,
  hovered: boolean,
  activeCondition: boolean,
  disabled: boolean
}) {

  const iconColor = useMemo(() => {
    if (disabled) return colors.disabled;
    if (hovered && !activeCondition) return colors.hovered;
    return activeCondition ? colors.active : colors.regular
  }, [hovered, activeCondition]);


  return (
    <div className={`hidden ml-2 md:flex justify-self-end`}>
      <div onClick={(e) => {
        if (disabled) return;
        toggleSubMenus(e)
      }}>
        <ExpandIcon className={`duration-300 ${expanded ? 'rotate-180' : ''}`} color={iconColor} /> 
      </div>
    </div>
  )


}


export default function NavbarItem({
  idx,
  to,
  disabled,
  title,
  activeCondition,
  Icon,
  subMenus,
  hidden
}: {
  idx: number,
  to: string,
  disabled: boolean,
  title: string,
  activeCondition: boolean,
  Icon: ({ color }: { color: string }) => ReactElement;
  subMenus?: ReactElement[],
  hidden?: boolean
}) {


  const navigate = useNavigate();

  const { subMenuOpen, setSubMenuOpen } = useContext(NavbarContext);

  const [hovered, setHovered] = useState(false);

  const toggleSubMenus = (e: SyntheticEvent) => {
    e.stopPropagation();
    if (subMenuOpen === idx) {
      setSubMenuOpen(null);
    } else {
      setSubMenuOpen(idx);
    }
  }

  const { contentStyle, backgroundColor } = useColors({
    disabled,
    hovered,
    activeCondition,
    hasSubMenus: !!subMenus,
    expanded: subMenuOpen === idx
  })

  if (hidden) return null;


  return (
    <>
      <section
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={(e) => {
          if (disabled) return;
          else {
            !!subMenus ? toggleSubMenus(e) : navigate(to)
          }
        }}
        className={`flex flex-row items-center justify-between w-full my-2 p-2  rounded-md  ${disabled ? '' : 'cursor-pointer'} ${backgroundColor}`}>


        <IconAndText
          textStyle={contentStyle}
          Icon={<Icon color={contentStyle.color} />}
          title={title}
        />


        {!!subMenus ?
          <SubMenuCollapseAndExpand
            disabled={disabled}
            expanded={(subMenuOpen === idx) && !disabled}
            toggleSubMenus={toggleSubMenus}
            hovered={hovered}
            activeCondition={activeCondition}
          />
          : null}
      </section>



      {!!subMenus ?
        <div
          style={{
            height: !!subMenus && subMenuOpen === idx ? 42 * subMenus?.length : 0,
            marginTop: !!subMenus && subMenuOpen === idx ? 8 : 0,
            // marginBottom: !!subMenus && subMenuOpen === idx ? 4 : 0,
          }}
          className={`border-l-2 border-Grey-default ml-4 transition-all duration-300 overflow-hidden flex flex-col justify-between`}>

          {subMenus?.map((SubMenu: ReactElement, idx: number) => {
            return (
              <div key={idx}>
                {SubMenu}
              </div>
            )
          })}
        </div>
        : null}



    </>
  );
}
