
import { useOpenEvDetails } from "./useOpenEvDetails";
import { ReactComponent as RealRangeIconSmall } from "../../../../../../electrify_frontend_common/assets/ev_details/real-range-white-small.svg";
import { ReactComponent as EnergyCostIconSmall } from "../../../../../../electrify_frontend_common/assets/ev_details/energy-cost-small.svg";
import { ReactComponent as ListPriceIconSmall } from "../../../../../../electrify_frontend_common/assets/ev_details/list-price-small.svg";
import { EvDetailsModal } from "../../../../../../electrify_frontend_common/components/EvDetails/EvDetailsModal";
import { MetricsDisplay } from "../../../../../../electrify_frontend_common/components/EvDetails/components";
import { ListPrice } from "../../../../../../electrify_frontend_common/icons/ListPrice";
import { RealRange } from "../../../../../../electrify_frontend_common/icons/RealRange";
import { EnergyCost } from "../../../../../../electrify_frontend_common/icons/EnergyCost";
import { VehicleDetailsTable } from "../../../../../../electrify_frontend_common/components/EvDetails/VehicleDetailsTable";





export function OpenEvFinderEvDetails({ evDetailsToCheck, closeModal }: { evDetailsToCheck: string, closeModal: () => void }) {


    const {
        t,
        isMobileView,
        mainStats,
        currencySymbol,
        vehicleData,
    } = useOpenEvDetails({ evId: evDetailsToCheck });


    return (
        <EvDetailsModal
            isOpen={!!evDetailsToCheck}
            onCancel={closeModal}
            vehicle={vehicleData}
            content={
                <>
                    <div className="grid grid-cols-3 gap-4 mt-12">
                        <MetricsDisplay
                            Icon={isMobileView ? <ListPriceIconSmall /> : <ListPrice />}
                            property={t("vehicleDetailedView.mainStats.listPrice")}
                            value={mainStats?.listPrice}
                            units={currencySymbol}
                        />
                        <MetricsDisplay
                            Icon={isMobileView ? <RealRangeIconSmall /> : <RealRange />}
                            property={`${t("vehicleDetailedView.mainStats.realRange")} *`}
                            value={mainStats?.realRange}
                            units={"km"}
                        />
                        <MetricsDisplay
                            Icon={isMobileView ? <EnergyCostIconSmall /> : <EnergyCost />}
                            property={t("vehicleDetailedView.mainStats.energyConsumption")}
                            value={mainStats?.energyConsumption}
                            units={`kWh / 100 km`}
                        />
                    </div>

                    <VehicleDetailsTable vehicleData={vehicleData}/>
                </>
            }
        />
    )

}


