
import { useTranslation } from "react-i18next";
import { ModalHeader } from "../../../../../../electrify_frontend_common/components/ModalWrapper/Headers";



export default function VehicleRestoreModal({ closeModal, onRestore } : {closeModal: ()=> void, onRestore: ()=> void}) {
  const { t } = useTranslation("vehicles");
  
  return (
    <div
      className="fixed top-0 left-0 h-screen w-screen flex justify-center items-center overflow-hidden pt-32 pb-96 "
      style={{
        margin: "0px",
        backgroundColor: "#9e9e9eb8",
        zIndex: "10000",
      }}
    >
      <div
        className="flex flex-col align-bottom bg-white text-left rounded overflow-auto shadow-xl h-full w-4/6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
        style={{
          height: "fit-content"
        }}
      >
        <ModalHeader
            title={t("restore-vehicle.title")}
            onCancel={closeModal}
            isLastStep
          />

          <div className="p-10">
            <p>{t("restore-vehicle.description")}</p>
            <div className="mt-14">
              <button
                className="px-24 py-5 bg-Blueberry-dark-default rounded text-lg text-white mr-4 border-2 border-solid border-Blueberry-dark-default"
                onClick={onRestore}
              >
                {t("restore-vehicle.restore-button")}
              </button>
            </div>
          </div>
        </div>
      </div>
  
  );
}
