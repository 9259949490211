
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useScreenSize } from "../../../../hooks/useScreenSize";
import Dropdown from "../../../../components/Dropdown";
import { ExpandIcon } from "../../../../icons/ExpandIcon";



function CollapsedSelectionSorter({
    sortBy,
    handleOnChangeSorter,
    optionsMap,
    backgroundColor = "light"
}: {
    sortBy: { label: string; key: string; order: "ASC" | "DESC" };
    handleOnChangeSorter: (v: any) => void;
    optionsMap: {
        label: string;
        key: string;
        order: "ASC" | "DESC";
    }[];
    backgroundColor?: "light" | "dark";
}) {

    const { t } = useTranslation("evFinder");

    const [collapsed, setCollapsed] = useState(true);


    return <>
        <div onClick={() => setCollapsed(!collapsed)} className="flex justify-between items-center text-white py-2">
            <span className="text-xs font-bold">{t("sorter.label")}</span>
            {collapsed ? <ExpandIcon color={backgroundColor === "light" ? undefined : "white"} /> : <div className="rotate-180"><ExpandIcon /></div>}
        </div>
        <div className={`${collapsed ? 'hidden' : 'block'}`}>
            <RegularSelectionSorter backgroundColor={backgroundColor} sortBy={sortBy} handleOnChangeSorter={handleOnChangeSorter} optionsMap={optionsMap} />
        </div>
    </>


}


function RegularSelectionSorter({
    sortBy,
    handleOnChangeSorter,
    optionsMap,
    backgroundColor = "light"
}: {
    sortBy: { label: string; key: string; order: "ASC" | "DESC" };
    handleOnChangeSorter: (v: any) => void;
    optionsMap: {
        label: string;
        key: string;
        order: "ASC" | "DESC";
    }[];
    backgroundColor?: "light" | "dark";
}) {

    const { t } = useTranslation("evFinder");

    return (
        <>

            <div className="flex w-auto text-xs h-10 items-center my-4 sm:py-0">
                <div
                    className={`border flex border-Grey-tint bg-white solid rounded h-full text-xs items-center w-full sm:1/2 md:w-52`}
                >
                    <Dropdown
                        className="w-full h-full bg-Black-background rounded"
                        data={optionsMap.map((v) => ({
                            key: t(`sorter.options.${v.label}`),
                            onClick: () => handleOnChangeSorter(v),
                        }))}
                        placeholder={t(`sorter.options.${sortBy.label}`)}
                        backgroundColor={backgroundColor}
                        icon={<ExpandIcon color={backgroundColor === "dark" ? "white" : undefined} />}
                    />
                </div>
            </div>
        </>
    );
}


export function SelectionSorter({
    sortBy,
    handleOnChangeSorter,
    optionsMap,
    backgroundColor = "light"
}: {
    sortBy: { label: string; key: string; order: "ASC" | "DESC" };
    handleOnChangeSorter: (v: any) => void;
    optionsMap: {
        label: string;
        key: string;
        order: "ASC" | "DESC";
    }[];
    backgroundColor?: "light" | "dark";
}) {

    const { t } = useTranslation("evFinder");

    const { widthIsLessThan1024 } = useScreenSize();


    return (
        <>
            {widthIsLessThan1024 ? <CollapsedSelectionSorter backgroundColor={backgroundColor} sortBy={sortBy} handleOnChangeSorter={handleOnChangeSorter} optionsMap={optionsMap} /> :
                <div className="flex items-center ">
                    <div className={` ${backgroundColor === "dark" ? "text-white" : "text-Grey-dark"} text-xs font-bold mr-2`}>{t("sorter.label")}</div>
                    <RegularSelectionSorter backgroundColor={backgroundColor} sortBy={sortBy} handleOnChangeSorter={handleOnChangeSorter} optionsMap={optionsMap} />
                </div>}
        </>
    )

}
