import { ReactElement, useCallback, useContext, useEffect, useMemo, useState } from "react";




export enum SelectionType {
    SINGLE = "single",
    MULTIPLE = "multiple"
}

export function SectionHeader({ icon, title, description, progress }: { icon: ReactElement, title: string, description: string, progress: { done: number, total: number } }) {
    return (
        <div className="h-24">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <div className="mr-2 mt-0.5">
                        {icon}
                    </div>
                    <div className="text-xl font-bold">
                        {title}
                    </div>
                </div>
                <div>
                    <span className="font-bold">{progress.done}</span><span className="text-sm">/{progress.total}</span>
                </div>
            </div>
            <div className="text-xs mt-5 text-Grey-dark">{description}</div>
        </div>
    )
}

export function SectionContentWrapper({ title, children }: { title: string, children: ReactElement | ReactElement[] }) {
    return (
        <div >
            <div className="grid grid-cols-4 items-center w-full h-10 p-2 border-b text-xs font-bold border-Grey-default">
                <div className="col-span-3" >{title}</div>
            </div>
            {children}
        </div>
    )
}





export function ElectrificationPlannerStepModalStructure({ leftSide, rightSide }: { leftSide: ReactElement, rightSide: ReactElement | null }) {

    return (

        <div className="grid grid-cols-5 gap-16 px-16 py-8 ">
            <div className="col-span-3 pr-12">
                {leftSide}
            </div>
            <div className="col-span-2">
                {rightSide}
            </div>
        </div>

    )

}

