import axios from "axios";
import { Tariff } from "../../../@types/paymentProcess";
import { SubscriptionState } from "../../../enums";
import { Product, UserPaymentInfo, Voucher } from "../../../@types/driver/payments";


export const agreeWithPayment = async (): Promise<any> => {
  try {
    await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/payments/driver/agree`
    );
  } catch (e) {
    console.log("Something went wrong: ", e);
  }

}

export const applyVoucher = async ({ companyEmail, voucherCode, tariffId }: { companyEmail?: string, voucherCode?: string, tariffId: number }): Promise<{ clientSecret: string, paymentCompleted?: true, usedVoucher?: Voucher } | undefined> => {


  return (await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/payments/driver/apply-voucher/${tariffId}`,
    {
      companyEmail,
      voucherCode
    }
  )).data

}

export const getTariffsForDriverUser = async (): Promise<Tariff[]> => {
  return (await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/payments/driver/tariffs/electrify`
  )).data;
};

// TODO Update name
export const getValidTariffForFleetUser = async (): Promise<Tariff> => {
  return (await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/payments/fleet/tariffs/electrify`
  )).data;
};

export const getActiveSubscriptionForVehicle = async (vehicleId: string): Promise<Tariff[]> => {
  return (await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/payments/fleet/subscriptions/vehicle/${vehicleId}`
  )).data;
};

export const createSubscriptionsForVehicles = async (tariffId: string, vehicleIds: string[]) => {
  return (await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/payments/fleet/subscriptions`,
    { tariffId, vehicleIds }
  )).data;
};

export const getDefaultFuelAndEnergyPricesForUser = async () => {
  return (await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/prices/fuel_and_energy/default`
  )).data;
};

export const getLiveMarketsForFleet = async () => {
  const productName = "electrify_fleet";
  return (await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/payments/fleet/tariffs/product/${productName}/live_markets`
  )).data;
};


export const confirmPaymentIntent = async (tariffId?: number): Promise<{ subscriptionId: string, clientSecret: string } | undefined> => {
  console.log("tariff id: ", tariffId);
  if (!tariffId) return;
  return (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/payments/driver/subscriptions/${tariffId}`, {})).data;
}

export const checkDriverVehicleSubscription = async (): Promise<{
  product: Product,
  state: SubscriptionState,
  clientSecret: string | null
}> => {
  return (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/payments/driver/subscriptions`)).data;
}

export const updateUserPaymentInfo = async (userInfo: UserPaymentInfo): Promise<{ subscriptionState: SubscriptionState, clientSecret: string | null }> => {
  return (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/payments/user-info`, { ...userInfo })).data;
}