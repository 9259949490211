import { TripSimulationTable } from "./TripSimulationTable";
import { ReactComponent as HomeCharging } from "../../../../../../assets/charging-home.svg";
import { ReactComponent as PublicCharging } from "../../../../../../assets/charging-public.svg";
import { ReactComponent as WorkCharging } from "../../../../../../assets/charging-work.svg";
import { useTranslation } from "react-i18next";
import {
  SimulationConfigurations,
  TableLegend,
} from "./TripSimulationTable/components";
import { useTripSimulation } from "./hooks/useTripSimulations";

export function TripsSimulation() {
  const { t } = useTranslation("tripSimulation");

  const {
    tripSimulationPreferencesState: {
      setIncludeExtraStops,
      setSelectedOptimization,
      includeExtraStops,
      selectedOptimization,
    },
    tripSimulationData,
  } = useTripSimulation();

  return (
    <div>
      <SimulationConfigurations
        setIncludeExtraStops={setIncludeExtraStops}
        setSelectedOptimization={setSelectedOptimization}
        selectedOptimization={selectedOptimization}
        includeExtraStops={includeExtraStops}
      />

      {tripSimulationData && tripSimulationData?.length > 0 ? (
        <TripSimulationTable trips={tripSimulationData} />
      ) : null}
      <TableLegend
        entries={[
          { icon: WorkCharging, label: t("legend.work-charging") },
          { icon: HomeCharging, label: t("legend.home-charging") },
          { icon: PublicCharging, label: t("legend.public-charging") },
        ]}
      />
    </div>
  );
}
