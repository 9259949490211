import { useTranslation } from "react-i18next";
import {ReactComponent as WebfleetLogo} from "../../../../../../assets/webfleet-logo.svg";

export default function TrackingDataAvailable({ nextStep } : {nextStep: ()=> void}) {
  const { t } = useTranslation("onboarding");

  return (
    <div className="p-10">
      <div className="grid grid-cols-2 gap-x-6">
        <button
          className="py-8 px-6 text-left rounded border border-solid border-Grey-tint hover:bg-Grey-background h-full w-full"
          onClick={() => {
            nextStep();
          }}
        >
          <WebfleetLogo className="mb-6"/>
          {/* <img src={webfleetLogo} alt="webfleet-logo" className="mb-6" /> */}
          <h2 className="text-xl text-Blueberry-dark-default mb-2">
            {t("tracking-webfleet-title")}
          </h2>
          <p className="text-sm text-Grey-shade mb-4">
            {t("tracking-webfleet-description")}
          </p>
          {/* <button className="w-full py-5 py-6 bg-Blueberry-light-default rounded text-lg text-white">
                    {t("button.select")}
                </button> */}
        </button>
        <a href="mailto:support@chargylize.com?subject=Electrify Support - Data provider not listed">
          <button className="py-10 px-6 text-left rounded border border-solid border-Grey-tint hover:bg-Grey-background h-full w-full">
            <h2 className="text-xl text-Blueberry-dark-default mb-2">
              {t("tracking-not-listed-title")}
            </h2>
            <p className="text-sm text-Grey-shade mb-6">
              {t("tracking-not-listed-description-p1")} <br />
              <br /> {t("tracking-not-listed-description-p2")}
            </p>
            {/* <button className="w-full py-5 py-6 bg-Blueberry-light-default rounded text-lg text-white">
                        {t("button.contact")}
                    </button> */}
          </button>
        </a>
      </div>
    </div>
  );
}
