import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthLayout from '../../components/AuthLayout';
import { PublicRoutes } from './routes';
import { IRoute } from '../../../@types/routes';

export function PublicRouter() {
	return (
		<BrowserRouter>
			<AuthLayout>
				<Routes>
					{PublicRoutes.map((pr: IRoute) => (
						<Route
							key={pr.path}
							path={pr.path}
							element={<pr.component />}
						/>
					))}
				</Routes>
			</AuthLayout>
		</BrowserRouter>
	);
}
