import { useTranslation } from "react-i18next";

import { ImageLayout, IncludedInProductLabel, ProductInformationLayout } from "./components";
import uploadImgMain from "../../../../../../../../assets/driver_payment/upload_1.webp";
import appLayoutImg from "../../../../../../../../assets/driver_payment/app_layout.webp";
import uploadImg from "../../../../../../../../assets/driver_payment/upload_2.webp";
import { usePriceCalculator } from "./usePriceCalculator";
import { Voucher } from "../../../../../../../../@types/driver/payments";



export function DriverDataUploadProductInfo({ price, currencySymbol, usedVoucher }: { price: number, currencySymbol: string, usedVoucher?: Voucher }) {

    const { t } = useTranslation("driverPaymentSystem");

    const { priceAfterDiscount } = usePriceCalculator({ price, voucher: usedVoucher });

    return (
        <div className="text-Grey-dark flex flex-col h-full">
            <ProductInformationLayout
                title={t("products.driverDataUpload.name")}
                description={t("products.driverDataUpload.description")}
                price={priceAfterDiscount}
                currencySymbol={currencySymbol}
                checkList={<>
                    <IncludedInProductLabel label={t("products.driverDataUpload.checklist.point1")} />
                    <IncludedInProductLabel label={t("products.driverDataUpload.checklist.point2")} />
                </>}
            />
            <ImageLayout imageMain={<img alt="upload-product" src={uploadImgMain} />} image1={<img className="w-14" src={uploadImg} alt="upload" />} image2={<img src={appLayoutImg} alt="app-layout" />} />
        </div>


    )


}