import { useTranslation } from "react-i18next";
import { DistanceAverageChart } from "./DistanceAverageChart";
import ChartWrapper from "../../../../../../../../common/components/Charts/ChartWrapper";

export function DistanceAverage({ data }: { data?: any }) {
  const { t } = useTranslation("driverDrivingAnalysis");

  return (
    <ChartWrapper title={t("distance-average.title")}>
      <DistanceAverageChart data={data} />
    </ChartWrapper>
  );
}
