import VehicleIcon from "../../../../../../../assets/overview-car.svg";
import RectangleIcon from "../../../../../../../assets/overview-graph-legend.svg";
import { DoughnutChart } from "../DoughnutChart";

export const VehicleTypesMetrics = ({
    fleetData,
    vehicleTypes,
    textContent,
    userFleet,
  }: any) => {
    const VehiclesCount = () => (
      <div className="flex w-full">
        <div className="mx-4">
          <p
            className={`text-3xl font-black mr-2 mb-2 leading-8 mt-2 ${userFleet ? "text-black" : "text-Blueberry-dark-default"
              }`}
          >
            {fleetData?.totalVehiclesCount}
          </p>
  
          <div className="flex items-center">
            <div className="mr-3.5">
              <div className="h-8 flex items-center">
                <img
                  className="h-2 my-auto mr-1 bg-Grey-default rounded-sm"
                  src={RectangleIcon}
                  alt="combustion-lengend"
                />
              </div>
              <div className="h-7 flex items-center">
                <img
                  className="h-2 my-auto mr-1 bg-Blueberry-light-default rounded-sm"
                  src={RectangleIcon}
                  alt="combustion-lengend"
                />
              </div>
              <div className="h-8 flex items-center">
                <img
                  className="h-2 my-auto mr-1 bg-Blueberry-dark-default rounded-sm"
                  src={RectangleIcon}
                  alt="combustion-lengend"
                />
              </div>
            </div>
            <div className="text-left">
              <p
                className={`text-xl font-semibold flex justify-start ${userFleet ? "text-black" : "text-Blueberry-dark-default"
                  }`}
              >
                {vehicleTypes?.ice}
              </p>
              <p
                className={`text-xl font-semibold flex justify-start ${userFleet ? "text-black" : "text-Blueberry-dark-default"
                  }`}
              >
                {vehicleTypes?.phev}
              </p>
              <p
                className={`text-xl font-semibold flex justify-start ${userFleet ? "text-black" : "text-Blueberry-dark-default"
                  }`}
              >
                {vehicleTypes?.bev}
              </p>
            </div>
          </div>
        </div>
        <div className="w-2/3 justify-start">
          {/* 3a col */}
          <p className="text-xl font-light leading-8 mb-2 mt-2 text-Blueberry-dark-default">
            {textContent.vehicles}
          </p>
          <p className="text-sm text-Grey-shade font-normal mb-1 leading-8">
            {textContent.combustion}
          </p>
          <p className="text-sm text-Grey-shade font-normal mb-1 leading-5">
            {textContent.hybrid}
          </p>
          <p className="text-sm text-Grey-shade font-normal mb-1 leading-8">
            {textContent.electric}
          </p>
        </div>
      </div>
    );
  
    return (
      <div className="sm:mb-10 sm:flex w-full">
        {/* 1a col */}
        <div className="flex sm:w-3/6">
          <div>
            <img src={VehicleIcon} alt="dashboard-icon" />
          </div>
          <VehiclesCount />
        </div>
  
        <div className="my-12 sm:mt-0 sm:w-2/6">
          {/* 4a col */}
  
          <DoughnutChart data ={
             {
              datasets: [
                {
                  backgroundColor: ["#A2ABBE", "#CE6AA7", "#830751"],
                  data: [
                    vehicleTypes?.ice,
                    vehicleTypes?.phev,
                    vehicleTypes?.bev,
                  ],
                },
              ],
              labels: [
                textContent.combustion,
                textContent.hybrid,
                textContent.electric,
              ],
            }
          }
          middleNumber={vehicleTypes?.bev}
          />
        </div>
      </div>
    );
  };