import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import PreferencesSection from "./PreferencesSection";
import { ReactComponent as SettingsIcon } from "../../../../../../assets/settings-icon.svg";

export function Preferences({
  hasPaidVehicles,
  setPreferences,
}: {
  hasPaidVehicles?: boolean;
  setPreferences: (d: any) => void;
}) {
  const { t } = useTranslation("fleetSimulation");

  const [showDropdown, setShowDropdown] = useState(false);
  const ref = useRef<any>(null);

  return (
    <div className="relative">
      <button
        ref={ref}
        className="text-Grey-dark text-sm	px-4 py-3 mr-2 border border-solid border-Grey-tint rounded flex items-center"
        onClick={() => setShowDropdown(!showDropdown)}
        disabled={!hasPaidVehicles}
      >
        <SettingsIcon className="mr-2" />
        {t("preferences.title")}
      </button>

      {showDropdown ? (
        <PreferencesSection
          buttonRef={ref}
          onApply={(data: any) => {
            setPreferences(data);
            setShowDropdown(false);
          }}
          close={() => setShowDropdown(false)}
        />
      ) : null}
    </div>
  );
}
