import { useState } from "react";
import { ContactUsModal } from "./ContactUsModal";
import { ButtonSize, ButtonVariant } from "../../../../../../../../../../electrify_frontend_common/components/Buttonv2/types";
import { VehicleCard } from "../../../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard";
import { Button } from "../../../../../../../../../../electrify_frontend_common/components/Buttonv2";
import { Trans, useTranslation } from "react-i18next";



export function VehicleSelectionNotAvailableCard({ onSkip }: { onSkip?: () => void }) {


    const { t } = useTranslation("electrificationPlanner");

    const [contactModalOpen, setContactModalOpen] = useState(false);

    const cardColor = {
        backgroundColor: "bg-Grey-background",
        smallFont: "text-Grey-dark",
        bigFont: "text-black-default",
        buttonVariant: ButtonVariant.SECONDRY,
    };



    return (
        <>
            {onSkip ? <ContactUsModal onSkip={onSkip} isOpen={contactModalOpen} close={() => setContactModalOpen(false)} /> : null}
            <VehicleCard
                hideOfferBanner
                moreInfoClick={()=> null}
                cardHeader={null}
                cardFooter={<Trans>
                    <div className="align-text-top text-xs ">
                        {t("misc.vehicleSelectionCard.vehicleNotAvailable")}
                    </div>
                </Trans>}
                button={<>
                    {onSkip ?
                        <Button
                            variant={cardColor.buttonVariant}
                            size={ButtonSize.SMALL}
                            onClick={(e) => {
                                e?.stopPropagation();
                                setContactModalOpen(true)
                            }}
                            className="flex w-full justify-center "
                        >
                            Confirm
                        </Button>
                        : null}
                </>
                }
            />
        </>
    )


}