import { Slider, Typography } from "@mui/material";
import { useMemo } from "react";



export function TemperatureSlider({ temperature, setTemperature, saveTemperature }: { temperature: number, setTemperature: (v: number) => void, saveTemperature: () => void}) {

    const marks = useMemo(() => [
        { value: -20, label: "-20" },
        { value: -15, label: "-15" },
        { value: -10, label: "-10" },
        { value: -5, label: "-5" },
        { value: 0, label: "0" },
        { value: 5, label: "5" },
        { value: 10, label: "10" },
        { value: 15, label: "15" },
        { value: 20, label: "20" },
        { value: 25, label: "25" },
        { value: 30, label: "30" },
        { value: 35, label: "35" },
        { value: 40, label: "40" },
    ], []);


    return (
        <>
            <Slider
                aria-label="Custom marks"
                defaultValue={0}
                value={temperature}
                step={5}
                valueLabelDisplay="auto"
                marks={marks}
                min={-20}
                max={40}
                sx={{
                    "& .MuiSlider-thumb": {
                        color: "#830751",
                    },
                    "& .MuiSlider-rail": {
                        color: "#ccc",
                    },
                    "& .MuiSlider-mark": {
                        display: "none",
                    },
                    "& .MuiSlider-track": {
                        opacity: 0
                    },
                }}
                onChange={(e: any) => setTemperature(e.target.value)}
                onChangeCommitted={() => saveTemperature()}
            />
          
        </>
    )


}