import { useMutation, useQuery } from "@apollo/client";
import { GET_FLEET_RESULTS_OVERVIEW } from "../../services/graphql/fleet/queries";
import { GET_SIMULATION_VEHICLE_INFO } from "../../services/graphql/fleet/queries/simulation";
import { Dispatch, SetStateAction } from "react";
import { SimulationVehicleInfo } from "../../@types";
import { UPDATE_SIMULATION_RESULTS } from "../../services/graphql/fleet/mutations";
import { extractVehicleIdsFromSummaries } from "../../common/utils";


export function useFleetSimulationService({
  setPageVehiclesCount,
  setRequiredSimulations,
  originalFleetVehicles,
  setOriginalFleetVehicles,
  setPageSelectedEvsLoaded
}: {
  setPageVehiclesCount: Dispatch<SetStateAction<number>>,
  setRequiredSimulations: Dispatch<SetStateAction<string[]>>,
  originalFleetVehicles: SimulationVehicleInfo[],
  setOriginalFleetVehicles: Dispatch<SetStateAction<SimulationVehicleInfo[]>>,
  setPageSelectedEvsLoaded: Dispatch<SetStateAction<number>>,
}) {

  const { loading: overviewDataLoading, data: overviewData, refetch: refetchOverviewData } = useQuery<{
    fleetResultsOverview: { currentFleet: any, simulatedFleet: any };
  }>(GET_FLEET_RESULTS_OVERVIEW);

  const { loading: vehiclesLoading, refetch: refetchSimulationVehiclesInfo } =
    useQuery<{ simulationVehicleInfo: SimulationVehicleInfo[] }>(
      GET_SIMULATION_VEHICLE_INFO,
      {
        onCompleted: ({ simulationVehicleInfo }) => {
          setPageVehiclesCount(simulationVehicleInfo.filter((veh: SimulationVehicleInfo) => veh.isVehiclePaid).length);
          const simulationsToRequest: string[] = extractVehicleIdsFromSummaries(simulationVehicleInfo);
          setRequiredSimulations(simulationsToRequest);
          setOriginalFleetVehicles(simulationVehicleInfo);
        },
      }
    );

  const [updateSimulations] = useMutation<{
    updateSimulationResults: {
      blockedSimulations: string[];
      vehicleIds: string[];
    };
  }>(UPDATE_SIMULATION_RESULTS, {
    onCompleted: ({ updateSimulationResults }) => {
      setPageSelectedEvsLoaded(0);
      const objs: Array<SimulationVehicleInfo & { simulationIsReady: boolean }> = originalFleetVehicles.map((nv: SimulationVehicleInfo) => ({ ...nv, simulationIsReady: updateSimulationResults?.vehicleIds.includes(nv.vehicleId) }));
      setOriginalFleetVehicles(objs);
    },
  });

  return {
    overviewData : overviewData?.fleetResultsOverview,
    overviewDataLoading,
    refetchOverviewData,
    vehiclesLoading,
    refetchSimulationVehiclesInfo,
    updateSimulations
  }


}