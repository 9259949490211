import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useUserData } from "../../../../../../../../hooks/state/useUserData";
import { Spinner, SpinnerSize } from "../../../../../../../../electrify_frontend_common/components/Spinner";
import { Button } from "../../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonVariant } from "../../../../../../../../electrify_frontend_common/components/Buttonv2/types";
import { ElectrifySubProducts } from "../../../../../../../../enums";
import { DriverPaymentGatewayContext } from "../../../../../../contexts/DriverPaymentGatewayContext";


export function Payment() {

    const stripe = useStripe();
    const elements = useElements();
    const { user } = useUserData();
    const [error, setError] = useState<string | undefined>();
    const [verifyingCard, setVerifyingCard] = useState(false);
    const { t } = useTranslation("driverPaymentSystem");
    const { usedVoucher } = useContext(DriverPaymentGatewayContext);


    const handleSubmit = async () => {

        if (!stripe || !elements) {
            return;
        }
        setError(undefined);
        setVerifyingCard(true);

        localStorage.setItem("usedVoucher", JSON.stringify(usedVoucher));
        if (user?.accountInfo?.preSelectedProduct === ElectrifySubProducts.DRIVER_DONGLE) {
            const result = await stripe.confirmSetup({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: process.env.REACT_APP_STRIPE_PAYMENT_REDIRECT as string,
                },
            });
            if (result.error) {
                setError(result.error.message);
                localStorage.removeItem("usedVoucher")
                return;
            }

        } else {

            const result = await stripe.confirmPayment({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                    return_url: process.env.REACT_APP_STRIPE_PAYMENT_REDIRECT as string,
                },
            });

            if (result.error) {
                setError(result.error.message);
                localStorage.removeItem("usedVoucher");
                return;
            }
        }

        console.log("Setting voucher!!!", usedVoucher);

        setVerifyingCard(false);

    };


    return (
        <>
            <div className="py-3 font-bold">{t("checkout.payment-method")}</div>
            <PaymentElement
                options={{
                    wallets: {
                        googlePay: "never",
                        applePay: "never"
                    }
                }}
            />
            {error ?
                <div className="text-red-500 mt-4 font-bold">
                    <div>{error}</div>
                    <div className="font-normal text-sm">{t("checkout.error")}</div>
                </div>
                : null}
            {verifyingCard ?
                <div className="flex w-full justify-center items-center mt-4">
                    <Spinner size={SpinnerSize.MEDIUM} />
                    <div className="ml-2">{t("checkout.verifying")}</div>
                </div>
                : null}
            <div className="flex w-full px-44">
                <Button
                    type="button"
                    variant={ButtonVariant.PRIMARY}
                    className='mt-10 w-full' disabled={!stripe}
                    onClick={handleSubmit}>{t("submit")}</Button>
            </div>
        </>
    )


}