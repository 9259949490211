import { useContext, useState } from "react";
import {  SelectionType } from "../../utils";
import { ElectrificationPlannerSections, ElectrificationPlannerStepStatus, ElectrificationPlannerStepType, ElectrificationPlannerVehicleSectionSubSections } from "../../../../models/Section";

import { UseMutationResult, useMutation, useQuery } from "react-query";

import { GenericElectrificationPlannerDropdown, ModalForm } from "../../utils/forms";
import { useTranslation } from "react-i18next";
import { getQuestionStructure, updateElectrificationPlanner } from "../../../../../../../../services/rest/electrificationPlanner";
import { IElectrificationPlanner } from "../../../../../../../../@types/driver/electrificationPlanner";
import { Button } from "../../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../../../electrify_frontend_common/components/Buttonv2/types";
import { ElectrifyPlannerContext } from "../../../../../../contexts/ElectrificationPlannerContext";
import ModalWrapper from "../../../../../../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../../../../../../electrify_frontend_common/components/ModalWrapper/Headers";


function useVehicleOrderAddictionalValues() {

    const [ownershipRefinement, setOwnershipRefinement] = useState<any>(null);

    const [financingRefinement, setFinancingRefinement] = useState<any>(null);

    const [vehicleAgeRefinement, setVehicleAgeRefinement] = useState<any>(null);

    const getValue = (value: string | string[] | null) => {
        if (Array.isArray(value)) {
            return value.length === 1 ? value[0] : null
        }
        return value;
    }

    useQuery<{
        selectionType: SelectionType,
        options: {
            text: string,
            effect: ElectrificationPlannerStepStatus
        }[],
        question: string,
        type: ElectrificationPlannerStepType,
        data: string | string[] | null,
        dueDate?: string
    }>(['ownershipType'], () => getQuestionStructure(
        ElectrificationPlannerSections.VEHICLE_SECTION,
        ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION,
        'ownershipType'
    ), {
        onSuccess: (data) => {
            setOwnershipRefinement(getValue(data.data));
        }
    });

    useQuery<{
        selectionType: SelectionType,
        options: {
            text: string,
            effect: ElectrificationPlannerStepStatus
        }[],
        question: string,
        type: ElectrificationPlannerStepType,
        data: string | string[] | null,
        dueDate?: string
    }>(['financingType'], () => getQuestionStructure(
        ElectrificationPlannerSections.VEHICLE_SECTION,
        ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION,
        'financingType'
    ), {
        onSuccess: (data) => {
            setFinancingRefinement(getValue(data.data));
        }
    })

    useQuery<{
        selectionType: SelectionType,
        options: {
            text: string,
            effect: ElectrificationPlannerStepStatus
        }[],
        question: string,
        type: ElectrificationPlannerStepType,
        data: string | string[] | null,
        dueDate?: string
    }>(['vehicleAge'], () => getQuestionStructure(
        ElectrificationPlannerSections.VEHICLE_SECTION,
        ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION,
        'vehicleAge'
    ), {
        onSuccess: (data) => {
            setVehicleAgeRefinement(getValue(data.data));
        }
    })

    return {
        ownershipRefinement,
        financingRefinement,
        vehicleAgeRefinement,
        setOwnershipRefinement,
        setFinancingRefinement,
        setVehicleAgeRefinement
    }

}



export function RefinementModalContent({
    submitMutation,
    formValues
}: {
    submitMutation: UseMutationResult<IElectrificationPlanner, unknown, any, unknown>,
    formValues: {
        answer: {
            vehicleOrder: string[]
        },
        dueDate?: string,
        additionalValues?: {}
    }
}) {

    const {
        ownershipRefinement,
        financingRefinement,
        vehicleAgeRefinement,
        setOwnershipRefinement,
        setFinancingRefinement,
        setVehicleAgeRefinement
    } = useVehicleOrderAddictionalValues();

    const {t} = useTranslation("electrificationPlanner");


    const selectionMissing = !ownershipRefinement || !financingRefinement || !vehicleAgeRefinement;


    return (
        <div className="p-6">
            <div>{t("vehicleSection.subsections.purchase.steps.vehicleOrder.refinement.info1")}</div>
            <div>{t("vehicleSection.subsections.purchase.steps.vehicleOrder.refinement.info2")}</div>

            <GenericElectrificationPlannerDropdown
                section={ElectrificationPlannerSections.VEHICLE_SECTION}
                subsection={ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION}
                stepName="ownershipType"
                placeholder={ownershipRefinement}
                valueSetter={(value) => setOwnershipRefinement(value)}
                optionsFilter={(option) => { return option?.text !== "notDecidedYet"}}
            />
            <GenericElectrificationPlannerDropdown
                section={ElectrificationPlannerSections.VEHICLE_SECTION}
                subsection={ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION}
                stepName="financingType"
                placeholder={financingRefinement}
                valueSetter={(value) => setFinancingRefinement(value)}
            />
            <GenericElectrificationPlannerDropdown
                section={ElectrificationPlannerSections.VEHICLE_SECTION}
                subsection={ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION}
                stepName="vehicleAge"
                placeholder={vehicleAgeRefinement}
                valueSetter={(value) => setVehicleAgeRefinement(value)}
            />

            <Button
                className="mt-12"
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.LARGE}
                disabled={selectionMissing}
                onClick={() => {
                    submitMutation.mutate({
                        section: ElectrificationPlannerSections.VEHICLE_SECTION,
                        subsection: ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION,
                        data: {
                            answer: formValues.answer,
                            dueDate: formValues.dueDate,
                            additionalValues: {
                                ...formValues.additionalValues,
                                ownershipType: ownershipRefinement,
                                financingType: financingRefinement,
                                vehicleAge: vehicleAgeRefinement
                            }
                        }
                    });
                }}
            >
                <div className="px-12" >
                {t("vehicleSection.subsections.purchase.steps.vehicleOrder.refinement.button")}
                </div>
            </Button>
        </div>
    )

}


export function VehicleOrderModalContent({ closeModal }: { closeModal: () => void }) {


    const { refetch } = useContext(ElectrifyPlannerContext);
    const [refinementModalOpen, setRefinementModalOpen] = useState(false);
    const [formValues, setFormValues] = useState<any>(null);

    const mutation = useMutation(async (input: any) => {
        return await updateElectrificationPlanner(input)
    }, {
        onSuccess: () => {
            refetch();
            closeModal();
        }
    })


    return (
        <>
            <ModalWrapper className="w-8/12" close={() => setRefinementModalOpen(false)} isOpen={refinementModalOpen}>
                <>
                    <ModalHeader onCancel={() => setRefinementModalOpen(false)} title="Refinement" />
                    <RefinementModalContent
                        formValues={formValues}
                        submitMutation={mutation}

                    />
                </>
            </ModalWrapper>
            <ModalForm

                closeModal={closeModal}
                section={ElectrificationPlannerSections.VEHICLE_SECTION}
                subsection={ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION}
                stepName="vehicleOrder"
                alternativeAction={(values) => {
                    console.log("Alternative action: ", values);
                    if (values.vehicleOrder === "ordered") {
                        setFormValues({
                            answer: {
                                ...values,
                            },
                            dueDate: values.dueDate
                        });
                        setRefinementModalOpen(true);
                        return;
                    }
                    mutation.mutate({
                        section: ElectrificationPlannerSections.VEHICLE_SECTION,
                        subsection: ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION,
                        data: {
                            answer: {
                                ...values
                            },
                            dueDate: values.dueDate,

                        }
                    });
                }}
            />
        </>
    )

}