import { useEffect, useMemo, useState } from "react";

import { useLazyQuery, useQuery } from "@apollo/client";
import { LatLngExpression } from "leaflet";
import { ReactComponent as HomeLocation } from '../../../../../../assets/driver_overview/home-grey.svg';
import { ReactComponent as WorkLocation } from '../../../../../../assets/driver_overview/workplace-grey.svg';
import { ReactComponent as ExpandIcon } from '../../../../../../assets/dropdown-icon-grey.svg';
import { PublicLocation } from "../../../../../../@types";
import { Location } from "../../../../../../@types/settings";
import { GET_ADDRESSES } from "../../../../../../services/graphql/fleet/queries";
import { GET_CHARGING_LOCATIONS } from "../../../../../../services/graphql/driver/queries";

export function usePublicChargingData(vehicleId?: string) {

    const [addresses, setAddresses] = useState<Location[]>([]);
    const [selectedAddress, setSelectedAddress] = useState<Location>({ id: undefined, lat: 0, lon: 0, locationType: undefined });
    const [currentBounds, setCurrentBounds] = useState<{ southWest: { lat: number, lon: number }, northEast: { lat: number, lon: number } } | null>(null);
    const [chargingPoints, setChargingPoints] = useState<PublicLocation[]>([]);

    const { loading: homeAddressesLoading } = useQuery<{ addresses: Location[] }>(GET_ADDRESSES, {
        variables: {
            locationType: "HOME",
            vehicleId: vehicleId
        },
        onCompleted: (data) => {
            setAddresses((currentAddresses) => [...currentAddresses, ...data.addresses]);
            if (data.addresses[0]) {
                setSelectedAddress(data.addresses[0]);
            }
            else {
                setSelectedAddress({ id: undefined, lat: 0, lon: 0, locationType: undefined })
            }
            getWorkplaceAddresses();
        }
    })

    const { refetch: refetchChargingPoints } = useQuery<{ chargingLocations: PublicLocation[] }>(GET_CHARGING_LOCATIONS, {
        skip: !currentBounds,
        variables: {
            mapCorners: currentBounds
        },
        onCompleted: (data) => {
            setChargingPoints([...data?.chargingLocations]);
        }
    })

    useEffect(() => {
        setChargingPoints([]);
    }, [selectedAddress])


    useEffect(() => {
        if (currentBounds)
            refetchChargingPoints();
    }, [currentBounds])

    const [getWorkplaceAddresses, { loading: workplaceLocationsLoading }] = useLazyQuery<{ addresses: Location[] }>(GET_ADDRESSES, {
        variables: {
            locationType: "WORKPLACE",
            vehicleId: vehicleId
        }, onCompleted: (data) => {
            setAddresses((currentAddresses) => [...currentAddresses, ...data.addresses]);
        }
    })

    const selectorPlaceholder = useMemo(() => {
        const address = addresses.find((a) => a.id === selectedAddress?.id);
        return (
            <div className='flex w-full items-center justify-between'>
                <div className='flex items-center'>
                    <div className='mr-2'>
                        {address?.locationType === "WORKPLACE" ? <WorkLocation /> : <HomeLocation />}
                    </div>
                    {address?.name}
                </div>
                <ExpandIcon className='h-5' />
            </div>
        )
    }, [selectedAddress])

    return {
        addresses,
        homeAddressesLoading,
        workplaceLocationsLoading,
        selectedAddress,
        setSelectedAddress,
        chargingPoints,
        selectorPlaceholder,
        setCurrentBounds,
        currentBounds
    }


}