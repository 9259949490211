export enum UserType {
    FLEET = 'electrify_fleet',
    DRIVER = 'electrify_driver'
}


export enum CountryCode {
    AUSTRIA = "AT",
    BELGIUM = "BE",
    BULGARIA = "BG",
    SWITZERLAND = "CH",
    CZECH_REPUBLIC = "CZ",
    GERMANY = "DE",
    DENMARK = "DK",
    SPAIN = "ES",
    FINLAND = "FI",
    FRANCE = "FR",
    UNITED_KINGDOM = "GB",
    GREECE = "GR",
    CROATIA = "HR",
    HUNGARY = "HU",
    IRELAND = "IE",
    ICELAND = "IS",
    ITALY = "IT",
    LUXEMBOURG = "LU",
    LATVIA = "LV",
    NETHERLANDS = "NL",
    NORWAY = "NO",
    NEW_ZEALAND = "NZ",
    POLAND = "PL",
    PORTUGAL = "PT",
    ROMANIA = "RO",
    SWEDEN = "SE",
    SLOVENIA = "SI",
    SLOVAKIA = "SK",
}



export enum SuitabilityType {

    NO_RESTRICTIONS = "NO_RESTRICTIONS",
    VERY_COMFORTABLE = "VERY_COMFORTABLE",
    FAIRLY_COMFORTABLE = "FAIRLY_COMFORTABLE",
    ADAPTATIONS_NEEDED = "ADAPTATION_NEEDED"
}


export enum SubscriptionState {
    NON_EXISTANT = "NON_EXISTANT",
    PAYMENT_FAILED = "PAYMENT_FAILED",
    VALID = "VALID"
}


export enum ElectrifySubProducts {
    DRIVER_DONGLE = "driver_dongle",
    DRIVER_DATA_UPLOAD = "driver_data_upload",
}

export enum RedemptionType {
    CODE = 'code',
    COMPANY_DOMAIN = 'company_domain'
};

export enum PaymentSource {
    INTERNAL = 'Internal',
    STRIPE = 'Stripe'
};
