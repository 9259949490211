


export function MetricsArrowDown({color="white", className} : {color?: string, className?: string}) {



    return (
        <svg className={className} width="13" height="13" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 3L8 13" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M3.33337 8.33333L8.00004 13L12.6667 8.33333" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )


}