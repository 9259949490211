import { Milestone, PlatformAccessIcon } from "./components"
import { DongleWelcomeSteps, IMilestoneStep, useDongleProductWelcome } from "./useDongleProductWelcome"
import chargylizeLogoBlack from "../../../../../../assets/chargylize_logo_black.svg"
import { OnboardingBanner } from "../../OnboardingPage/OnboardingBanner";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useLayout } from "../../../../../../hooks/state/useLayout";


function RoadMapSection({ steps }: { steps: DongleWelcomeSteps }) {


    return (
        <div className="flex flex-col items-center mt-1 h-full">
            <Milestone step={steps.dongleOrder} />
            <Milestone step={steps.dongleShipment} />
            <Milestone step={steps.dongleInstallation} />
            <Milestone step={steps.minimumRequirements} />
            <div className="fles w-full justify-start">
                <PlatformAccessIcon />
            </div>
        </div>
    )

}


export function DongleProductWelcome() {

    const {t, i18n} = useTranslation("dongleRoadMap")

    const { setHeader } = useLayout();

    const { steps, currentIncompleteStepComponent, incrementStep } = useDongleProductWelcome();

    useEffect(() => {
        setHeader({
          defaultHeaderTitle: "",
          defaultHeaderDescription: "",
        });
      }, [i18n.language]);

    return (
        <div className="flex flex-col w-full h-full ">
            <h1 className="flex items-center px-32 pt-8">
                <span className="text-[32px] mr-2">{t("welcome")}</span>
                <img
                    src={chargylizeLogoBlack}
                    alt="chargylize-logo"
                    className="h-10 mt-1"
                    // onClick={() => incrementStep()}
                />
            </h1>
            <OnboardingBanner completeButtonText={"Finish onboarding"}/>

            <div className="grid grid-cols-3 gap-12 h-full px-32 pt-4 pb-12">
                <div className="col-span-2">
                    <div className="flex flex-col w-full h-full p-5 px-10 rounded-lg bg-Grey-background items-center justify-center border border-Grey-tint">
                        {currentIncompleteStepComponent}
                    </div>
                </div>

                <div className="col-span-1">
                    <RoadMapSection steps={steps} />
                </div>

            </div>
        </div>
    )


}