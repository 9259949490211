import { useTranslation } from "react-i18next";
import Input from "../../misc/Input"
import { useEffect, useState } from "react";
import { vinRegex } from "../../../../static/constants";
import { useForm } from "../../../../hooks/utils/useForm";
import { DocumentNode, useMutation } from "@apollo/client";
import { Vehicle } from "../../../../@types/vehicle";
import { Button } from "../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../electrify_frontend_common/components/Buttonv2/types";


const VinError = ({ msg }: { msg?: string }) => {
    return (
        <div className="mt-2 border text-Red-default p-4 bg-gray-500">{msg}</div>
    );
};

export function VinVehicleInput({
    onSuccessUpdate,
    vehicleData,
    mutation
}: {
    onSuccessUpdate: () => void,
    vehicleData: Vehicle | undefined,
    mutation: DocumentNode
}) {
    const vinForm = useForm<{ modelInfo: { vin?: string, useVin?: boolean } }>();

    useEffect(() => {
        console.log("vehicleData: ", vehicleData);
        if (vehicleData?.modelInfo?.useVin) {
            vinForm.updateFormState({
                modelInfo: {
                    vin: vehicleData?.modelInfo?.vin,
                }
            });
        }
    }, [vehicleData]);

    const { t } = useTranslation("editVehicle");

    const [responseError, setResponseError] = useState(undefined);
    const [vinEmpty, setVinEmpty] = useState(false);
    const [vinInvalid, setVinInvalid] = useState(false);

    const [updateVehicle] = useMutation(
        mutation,
        {
            onCompleted: (data) => {
                onSuccessUpdate();
            },
            onError: (e) => {
                console.log("Error: ", e.graphQLErrors[0].message);
                setResponseError(e.graphQLErrors[0].message as any);
            },
        }
    );


    const getResponseErrorMessage = () => {
        if (responseError === "ERROR_ON_VIN_DECODER_CALL") {
            return t("not-found");
        } else if (responseError === "NOT_ENOUGH_DATA") {
            return t("not-enough-data");
        } else {
            return responseError;
        }
    };

    const isVinValid = () => {
        return vinRegex.test(vinForm.formState?.modelInfo?.vin || "") || !vinForm.formState?.modelInfo?.vin;
    };

    const handleSubmit = () => {
        if (!vinForm.formState?.modelInfo?.vin || vinForm.formState?.modelInfo?.vin.length == 0) {
            setVinEmpty(true);
            setTimeout(() => {
                setVinEmpty(false);
            }, 3000);
            return;
        } else if (!isVinValid()) {
            setVinInvalid(true);
            setTimeout(() => {
                setVinInvalid(false);
            }, 3000);
            return;
        }

        const updatedInfo = {
            ...vinForm.formState, modelInfo: {
                ...vinForm.formState?.modelInfo,
                useVin: true
            }
        };
        updateVehicle({
            variables: {
                id: vehicleData?.id,
                vehicle: updatedInfo,
            },
        });


    };

    const handleChange = (e: any) => {
        vinForm.updateFormState({
            ...vinForm.formState,
            modelInfo: {
                ...vinForm.formState?.modelInfo,
                useVin: true,
                vin: e.target.value,
            }

        });
    };

    return (
        <>
            <Input
                disabled={false}
                label={t("vin-number")}
                name="billingAddressCompany"
                value={vinForm.formState?.modelInfo?.vin}
                onChange={handleChange}
            />
            {vinEmpty ? <VinError msg={t("empty-vin")} /> : null}
            {vinInvalid ? <VinError msg={t("invalid-vin")} /> : null}
            {responseError ? <VinError msg={getResponseErrorMessage()} /> : null}
            <div className="mt-5">
                <Button
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.LARGE}
                    disabled={!vinForm.formState?.modelInfo?.vin}
                    onClick={handleSubmit}>
                    {t("vehicle-save")}
                </Button>
            </div>
        </>
    )

}