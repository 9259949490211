import { useContext, useMemo, useState } from "react";
import { ElectrificationPlannerSections, ElectrificationPlannerStepStatus, ElectrificationPlannerVehicleSectionSubSections } from "../../../../models/Section";

import { useMutation } from "react-query";

import { InsuranceModalContent } from "./VehicleRegistrationModalContent";
import { ModalForm } from "../../utils/forms";

import { RefinementModalContent } from "./VehicleOrderModalContent";
import { ElectrifyPlannerContext } from "../../../../../../contexts/ElectrificationPlannerContext";
import { updateElectrificationPlanner } from "../../../../../../../../services/rest/electrificationPlanner";
import ModalWrapper from "../../../../../../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../../../../../../electrify_frontend_common/components/ModalWrapper/Headers";








export function VehicleDeliveryModalContent({ closeModal }: { closeModal: () => void }) {

    const { refetch, electrificationPlanner } = useContext(ElectrifyPlannerContext);

    const [refinementModalOpen, setRefinementModalOpen] = useState(false);
    const [insuranceModalOpen, setInsuranceModalOpen] = useState(false);
    const [formValues, setFormValues] = useState<any>(null);

    const mutation = useMutation(async (input: any) => {
        return await updateElectrificationPlanner(input)
    }, {
        onSuccess: () => {
            refetch();
            closeModal();
        }
    })

    const shouldRefineInsurance = useMemo(()=>{
        if(!electrificationPlanner) return;
        return electrificationPlanner[ElectrificationPlannerSections.VEHICLE_SECTION]?.[ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION]?.vehicleRegistration.status !== ElectrificationPlannerStepStatus.DONE
    },[electrificationPlanner])


    return (
        <>
             <ModalWrapper className="w-8/12" close={() => setInsuranceModalOpen(false)} isOpen={insuranceModalOpen}>
                <>
                    <ModalHeader onCancel={() => setInsuranceModalOpen(false)} title="Car Insurance" />
                    <InsuranceModalContent
                        closeModal={closeModal}
                        submitMutation={mutation}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        openVehicleOrderRefinement={() => setRefinementModalOpen(true)}
                    />
                </>
            </ModalWrapper>
            <ModalWrapper className="w-8/12" close={() => setRefinementModalOpen(false)} isOpen={refinementModalOpen}>
                <>
                    <ModalHeader onCancel={() => setRefinementModalOpen(false)} title="Refinement" />
                    <RefinementModalContent
                        formValues={formValues}
                        submitMutation={mutation}
                    />
                </>
            </ModalWrapper>

            <ModalForm
                closeModal={closeModal}
                section={ElectrificationPlannerSections.VEHICLE_SECTION}
                subsection={ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION}
                stepName="vehicleDelivery"
                alternativeAction={(values) => {
                    if(shouldRefineInsurance && values.vehicleDelivery === "delivered") {
                        setFormValues({
                            answer: {
                                ...values,
                            },
                            dueDate: values.dueDate
                        });
                        setInsuranceModalOpen(true);
                        return;
                    }

                    mutation.mutate({
                        section: ElectrificationPlannerSections.VEHICLE_SECTION,
                        subsection: ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION,
                        data: {
                            answer: {
                                ...values
                            },
                            dueDate: values.dueDate,

                        }
                    });
                }}
            />

        </>


    )



}