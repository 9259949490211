
import { ReactComponent as TodoIcon } from "../../../../../../assets/electrification_planner/todo_icon_new.svg";
import { ReactComponent as DoneIcon } from "../../../../../../assets/electrification_planner/done_icon.svg";
import { ReactComponent as PartiallyDone1Icon } from "../../../../../../assets/electrification_planner/partially_done_1_icon.svg";
import { ReactComponent as PartiallyDone2Icon } from "../../../../../../assets/electrification_planner/partially_done_2_icon.svg";
import { ReactComponent as PartiallyDone3Icon } from "../../../../../../assets/electrification_planner/partially_done_3_icon.svg";
import { ReactComponent as NotApplicableIcon } from "../../../../../../assets/electrification_planner/not_applicable_icon.svg";
import { ReactComponent as WarningIcon } from "../../../../../../assets/warning.svg";
import { useContext, useMemo, useState } from "react";
import { ElectrificationPlannerStepStatus, ElectrificationPlannertaskExpirationState, ElectrifyPlannerStep } from "../../models/Section";
import { useTranslation } from "react-i18next";
import { ModalForm } from "../Sections/utils/forms";
import { ElectrifyPlannerContext } from "../../../../contexts/ElectrificationPlannerContext";
import ModalWrapper from "../../../../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../../../../electrify_frontend_common/components/ModalWrapper/Headers";



export function PlannerStep({
    children,
    status = ElectrificationPlannerStepStatus.TODO,
    taskExpirationState,
    modal,
    sectionName,
    subsectionName,
    stepName,
    onWaitForThirdParty
}: ElectrifyPlannerStep) {

    const [modalOpen, setModalOpen] = useState(false);
    const { t } = useTranslation("electrificationPlanner");


    const { refetch } = useContext(ElectrifyPlannerContext);

    const closeModal = () => setModalOpen(false);

    const stateComponent = useMemo(() => {
        let stateSymbol;
        switch (status) {
            case ElectrificationPlannerStepStatus.DONE:
                stateSymbol = <DoneIcon  className="w-[18px] h-[18px]"/>
                break;
            case ElectrificationPlannerStepStatus.TODO:
                stateSymbol = <TodoIcon className="w-[18px] h-[18px]"/>
                break;
            case ElectrificationPlannerStepStatus.PARTIALLY_DONE_1:
                stateSymbol = <PartiallyDone1Icon  className="w-[18px] h-[18px]"/>
                break;
            case ElectrificationPlannerStepStatus.PARTIALLY_DONE_2:
                stateSymbol = <PartiallyDone2Icon className="w-[18px] h-[18px]"/>
                break;
            case ElectrificationPlannerStepStatus.PARTIALLY_DONE_3:
                stateSymbol = <PartiallyDone3Icon  className="w-[18px] h-[18px]"/>
                break;
            case ElectrificationPlannerStepStatus.NOT_APPLICABLE:
                stateSymbol = <NotApplicableIcon  className="w-[18px] h-[18px]"/>
                break;
        }
        if (status !== ElectrificationPlannerStepStatus.DONE && status !== ElectrificationPlannerStepStatus.NOT_APPLICABLE) {
            if (taskExpirationState === ElectrificationPlannertaskExpirationState.OVERDUE && !onWaitForThirdParty) {
                return <div className="flex items-center">
                    <WarningIcon className="h-4 w-5 mr-1 cursor-pointer" />
                    {stateSymbol}
                </div>

            }
        }
        return stateSymbol;

    }, [status, taskExpirationState])

    const styleFromState = useMemo(() => {
        if (status === ElectrificationPlannerStepStatus.NOT_APPLICABLE) {
            return "bg-Grey-tint line-through text-Grey-shade"
        } else if (status !== ElectrificationPlannerStepStatus.DONE) {
            switch (taskExpirationState) {
                case ElectrificationPlannertaskExpirationState.OVERDUE_WARNING:
                    return "border-Blueberry-dark-default"
                case ElectrificationPlannertaskExpirationState.OVERDUE:
                    return "border-Blueberry-dark-default bg-Blueberry-light-background"
                default:
                    return "border-Grey-default"
            }
        }
        return "border-Grey-default"

    }, [status, taskExpirationState])

    return (
        <>
            <ModalWrapper
                isOpen={modalOpen}
                close={closeModal}
                className="w-9/12"
            >
                <>
                    <ModalHeader
                        title={t(`${sectionName}.subsections.${subsectionName}.steps.${stepName}.title`)}
                        onCancel={closeModal}
                    />
                    {
                        modal ?
                            modal(closeModal, refetch) :
                            <ModalForm
                                closeModal={closeModal}
                                section={sectionName}
                                subsection={subsectionName}
                                stepName={stepName}
                            />
                    }
                </>
            </ModalWrapper>
            <div
                onClick={() => setModalOpen(true)}
                className={`border  rounded p-2 flex flex-col w-full my-2 cursor-pointer ${styleFromState}`}>
                <div className="flex items-center justify-between ">
                    <div className="text-xs">{t(`${sectionName}.subsections.${subsectionName}.steps.${stepName}.title`)}</div>
                    {stateComponent}
                </div>
                {children}
            </div>
        </>
    )

}