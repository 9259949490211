
import { useContext, useState } from "react";

import { FreeEvFinderVehicleCard } from "../../../../../electrify_frontend_common/components/EvFinder/Versions[A-B]/FreeEvFinderVehicleCard";
import { FreeEvFinderContext, alternativesSortMap } from "./FreeEvFinderContext";
import { OpenEvFinderEvDetails } from "./EvDetailsModal";
import { useTranslation } from "react-i18next";
import ChartButtonHeader from "../../../../../common/components/Charts/ChartButtonHeader";
import { Spinner, SpinnerSize } from "../../../../../electrify_frontend_common/components/Spinner";
import { AlternativeVehicle } from "../../../../../electrify_frontend_common/types";
import { SelectionSorter } from "../../../../../electrify_frontend_common/components/EvFinder/Versions[A-B]/components";
import { PaginationSelector } from "../../../../../electrify_frontend_common/components/EvFinder/PaginationSelector";
import { useLayout } from "../../../../../hooks/state/useLayout";


export function VehicleList() {


    const {localeCode} = useLayout();

    const {
        evDetailsToCheck,
        setEvDetailsToCheck,
        totalPagesCount,
        totalVehiclesCount,
        vehiclesList,
        isLoading,
        sortBy,
        updateSortBy,
        setPage,
        page,
        favoriteVehiclesList,
        favoriteVehiclesCount,
        favoritesOnly,
        setFavoritesOnly,
        toggleFavorite,
        togglingFavoriteEvId
    } = useContext(FreeEvFinderContext);

    const { t } = useTranslation("evFinder");


    const vehiclesToDisplay = favoritesOnly ? favoriteVehiclesList : vehiclesList;

    return (
        <>
            {evDetailsToCheck ?
                <OpenEvFinderEvDetails evDetailsToCheck={evDetailsToCheck} closeModal={() => setEvDetailsToCheck(null)} />
                : null}

            <div className="md:flex pb-4 md:mb-2 md:items-center justify-between">
                <ChartButtonHeader
                    button1={{
                        onClick: () => {
                            setFavoritesOnly(false);
                        },
                        label: `${t("headerButtons.allVehicles")}`,
                        vehiclesCount: totalVehiclesCount,
                    }}
                    button2={{
                        onClick: () => {
                            setFavoritesOnly(true);
                        },
                        label: `${t("headerButtons.favoritesOnly")}`,
                        vehiclesCount: favoriteVehiclesCount,
                    }}
                    checkProp={!favoritesOnly}
                />
                <div className="ml-4">
                    <SelectionSorter
                        sortBy={sortBy}
                        handleOnChangeSorter={updateSortBy}
                        optionsMap={alternativesSortMap}
                    />
                </div>
            </div>

            <div id="vehicle-list" className="flex flex-col w-full h-full pb-6 sm:pb-0">
                <div className={`grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4`}>
                    {isLoading ? (
                        <div className={`flex flex-col col-span-3 h-96 items-center justify-center`}>
                            <Spinner size={SpinnerSize.LARGE} />
                        </div>
                    ) : null}
                    {!isLoading ?
                        <>
                            {vehiclesToDisplay?.map((evs: Partial<AlternativeVehicle>, idx: number) => (
                                <div key={evs.evId} className="col-span-1">
                                    <FreeEvFinderVehicleCard
                                        vehicle={evs}
                                        onClick={() => setEvDetailsToCheck(evs.evId || "")}
                                        favoriteVehiclesList={favoriteVehiclesList}
                                        toggleFavorite={toggleFavorite}
                                        localeCode={localeCode}
                                        togglingFavorite={togglingFavoriteEvId === evs.evId}
                                    />
                                </div>
                            ))}

                        </> : null}


                </div>
                {favoritesOnly ? null : <PaginationSelector
                    currentPage={page}
                    setPage={setPage}
                    totalPagesCount={totalPagesCount}
                />}

            </div>
        </>

    )

}