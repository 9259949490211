import { useState } from "react"
import { IMilestoneStep } from "../useDongleProductWelcome"
import { ReactComponent as OrderConfirmed } from "../../../../../../../assets/dongle_order/order_confirmed.svg"
import { ReactComponent as DongleBox } from "../../../../../../../assets/dongle_order/dongle_box.svg"
import { ReactComponent as TripsReceived } from "../../../../../../../assets/dongle_order/trips_received.svg"
import { ReactComponent as DongleSetupCompleted } from "../../../../../../../assets/dongle_order/dongle_setup_completed.svg"
import { useMutation } from "react-query"
import { useNavigate } from "react-router-dom"
import OnboardingModal from "../../../OnboardingPage/OnboardingModal"
import { useTranslation } from "react-i18next"
import { useUserData } from "../../../../../../../hooks/state/useUserData"
import { useLayout } from "../../../../../../../hooks/state/useLayout"
import { completeDongleLeasingOnboarding } from "../../../../../../../services/rest/driverDongle"
import { ArrowRightIcon } from "../../../../../../../electrify_frontend_common/components/ArrowRight"


export function DongleOrderedIcon({ color = "#FFF" }: { color?: string }) {

    return (
        <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.1 17C4.6325 17 4.23229 16.8335 3.89937 16.5006C3.56646 16.1677 3.4 15.7675 3.4 15.3C3.4 14.8325 3.56646 14.4323 3.89937 14.0994C4.23229 13.7665 4.6325 13.6 5.1 13.6C5.5675 13.6 5.96771 13.7665 6.30062 14.0994C6.63354 14.4323 6.8 14.8325 6.8 15.3C6.8 15.7675 6.63354 16.1677 6.30062 16.5006C5.96771 16.8335 5.5675 17 5.1 17ZM13.6 17C13.1325 17 12.7323 16.8335 12.3994 16.5006C12.0665 16.1677 11.9 15.7675 11.9 15.3C11.9 14.8325 12.0665 14.4323 12.3994 14.0994C12.7323 13.7665 13.1325 13.6 13.6 13.6C14.0675 13.6 14.4677 13.7665 14.8006 14.0994C15.1335 14.4323 15.3 14.8325 15.3 15.3C15.3 15.7675 15.1335 16.1677 14.8006 16.5006C14.4677 16.8335 14.0675 17 13.6 17ZM4.3775 3.4L6.4175 7.65H12.3675L14.705 3.4H4.3775ZM3.57 1.7H16.1075C16.4333 1.7 16.6812 1.84521 16.8512 2.13563C17.0212 2.42604 17.0283 2.72 16.8725 3.0175L13.855 8.4575C13.6992 8.74083 13.4902 8.96042 13.2281 9.11625C12.966 9.27208 12.6792 9.35 12.3675 9.35H6.035L5.1 11.05H15.3V12.75H5.1C4.4625 12.75 3.98083 12.4702 3.655 11.9106C3.32917 11.351 3.315 10.795 3.6125 10.2425L4.76 8.16L1.7 1.7H0V0H2.7625L3.57 1.7Z" fill={color} />
        </svg>
    )

}

export function DongleShippedIcon({ color = "#FFF" }: { color?: string }) {

    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.05 17.5C2.5825 17.5 2.18229 17.3335 1.84938 17.0006C1.51646 16.6677 1.35 16.2675 1.35 15.8V6.21625C1.095 6.06042 0.889583 5.85854 0.73375 5.61063C0.577917 5.36271 0.5 5.07583 0.5 4.75V2.2C0.5 1.7325 0.666458 1.33229 0.999375 0.999375C1.33229 0.666458 1.7325 0.5 2.2 0.5H15.8C16.2675 0.5 16.6677 0.666458 17.0006 0.999375C17.3335 1.33229 17.5 1.7325 17.5 2.2V4.75C17.5 5.07583 17.4221 5.36271 17.2663 5.61063C17.1104 5.85854 16.905 6.06042 16.65 6.21625V15.8C16.65 16.2675 16.4835 16.6677 16.1506 17.0006C15.8177 17.3335 15.4175 17.5 14.95 17.5H3.05ZM3.05 6.45V15.8H14.95V6.45H3.05ZM2.2 4.75H15.8V2.2H2.2V4.75ZM6.45 10.7H11.55V9H6.45V10.7Z" fill={color} />
        </svg>
    )

}

export function DonglePluggedIcon({ color = "#FFF" }: { color?: string }) {

    return (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 14V15C3 15.2833 2.90417 15.5208 2.7125 15.7125C2.52083 15.9042 2.28333 16 2 16H1C0.716667 16 0.479167 15.9042 0.2875 15.7125C0.0958333 15.5208 0 15.2833 0 15V7L2.1 1C2.2 0.7 2.37917 0.458333 2.6375 0.275C2.89583 0.0916667 3.18333 0 3.5 0H14.5C14.8167 0 15.1042 0.0916667 15.3625 0.275C15.6208 0.458333 15.8 0.7 15.9 1L18 7V15C18 15.2833 17.9042 15.5208 17.7125 15.7125C17.5208 15.9042 17.2833 16 17 16H16C15.7167 16 15.4792 15.9042 15.2875 15.7125C15.0958 15.5208 15 15.2833 15 15V14H3ZM2.8 5H15.2L14.15 2H3.85L2.8 5ZM4.5 11C4.91667 11 5.27083 10.8542 5.5625 10.5625C5.85417 10.2708 6 9.91667 6 9.5C6 9.08333 5.85417 8.72917 5.5625 8.4375C5.27083 8.14583 4.91667 8 4.5 8C4.08333 8 3.72917 8.14583 3.4375 8.4375C3.14583 8.72917 3 9.08333 3 9.5C3 9.91667 3.14583 10.2708 3.4375 10.5625C3.72917 10.8542 4.08333 11 4.5 11ZM13.5 11C13.9167 11 14.2708 10.8542 14.5625 10.5625C14.8542 10.2708 15 9.91667 15 9.5C15 9.08333 14.8542 8.72917 14.5625 8.4375C14.2708 8.14583 13.9167 8 13.5 8C13.0833 8 12.7292 8.14583 12.4375 8.4375C12.1458 8.72917 12 9.08333 12 9.5C12 9.91667 12.1458 10.2708 12.4375 10.5625C12.7292 10.8542 13.0833 11 13.5 11ZM2 12H16V7H2V12Z" fill={color} />
        </svg>
    )
}

export function MinimumRequirementsIcon({ color = "#FFF" }: { color?: string }) {
    return (
        <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 18.5C11.3 18.5 13.3042 17.7375 15.0125 16.2125C16.7208 14.6875 17.7 12.7833 17.95 10.5H15.9C15.6667 12.2333 14.8958 13.6667 13.5875 14.8C12.2792 15.9333 10.75 16.5 9 16.5C7.05 16.5 5.39583 15.8208 4.0375 14.4625C2.67917 13.1042 2 11.45 2 9.5C2 7.55 2.67917 5.89583 4.0375 4.5375C5.39583 3.17917 7.05 2.5 9 2.5C10.15 2.5 11.225 2.76667 12.225 3.3C13.225 3.83333 14.0667 4.56667 14.75 5.5H12V7.5H18V1.5H16V3.85C15.15 2.78333 14.1125 1.95833 12.8875 1.375C11.6625 0.791667 10.3667 0.5 9 0.5C7.75 0.5 6.57917 0.7375 5.4875 1.2125C4.39583 1.6875 3.44583 2.32917 2.6375 3.1375C1.82917 3.94583 1.1875 4.89583 0.7125 5.9875C0.237499 7.07917 0 8.25 0 9.5C0 10.75 0.237499 11.9208 0.7125 13.0125C1.1875 14.1042 1.82917 15.0542 2.6375 15.8625C3.44583 16.6708 4.39583 17.3125 5.4875 17.7875C6.57917 18.2625 7.75 18.5 9 18.5ZM6.2 13.7L10 9.9V4.5H8V9.1L4.8 12.3L6.2 13.7Z" fill={color} />
        </svg>

    )
}

export function PlatformAccessIcon({ color = "#78194F" }: { color?: string }) {

    return (
        <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38.3206 52.2957C38.3206 52.2957 41.0718 57.9246 45.7008 57.5025C55.0852 56.6449 54.4578 44.1707 57.8883 39.7652C54.9862 38.3199 51.7832 37.5818 48.5412 37.6112C45.2992 37.6406 42.1102 38.4367 39.2347 39.9344C34.3732 42.6812 34.0279 47.3169 38.3206 52.2957V52.2957Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M44.9583 46.5742C44.9583 46.5742 38.8645 47.328 34.5244 57.9267" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M51.1198 30.8457V27.1691C51.12 26.7245 51.0326 26.2841 50.8626 25.8733C50.6926 25.4624 50.4433 25.089 50.129 24.7745L46.0417 20.6895H12.1875L8.10017 24.7745C7.78586 25.089 7.53658 25.4624 7.36657 25.8733C7.19657 26.2841 7.10917 26.7245 7.10938 27.1691V34.2311C7.10938 35.129 7.46605 35.9901 8.10094 36.625C8.73583 37.2599 9.59692 37.6165 10.4948 37.6165H30.8073" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M46.0417 20.6894H12.1875L14.801 9.46107C15.026 8.78728 15.4572 8.20132 16.0336 7.78614C16.6099 7.37097 17.3023 7.14761 18.0127 7.14771H40.2165C40.9268 7.14761 41.6192 7.37097 42.1956 7.78614C42.772 8.20132 43.2032 8.78728 43.4281 9.46107L46.0417 20.6894Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M13.8804 27.4602H17.2658" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40.9634 27.4602H44.3488" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M17.2655 41.0019C17.2655 41.8997 16.9088 42.7608 16.2739 43.3957C15.639 44.0306 14.7779 44.3873 13.88 44.3873C12.9822 44.3873 12.1211 44.0306 11.4862 43.3957C10.8513 42.7608 10.4946 41.8997 10.4946 41.0019V37.6165H17.2655V41.0019Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )
}


export function Milestone<T>({ step }: { step: IMilestoneStep<T> }) {

    const { t, i18n } = useTranslation("dongleRoadMap")

    return (
        <div className="flex justify-between w-full h-1/4 items-center">

            <div className="flex flex-col items-center h-full pl-2">
                <div className={`${step.completed ? 'bg-Blueberry-dark-default' : 'bg-white'} mt-[-4px] border border-Blueberry-dark-default h-10 w-10 rounded-full flex items-center justify-center z-10`}>
                    {step.milestoneIcon}
                </div>
                <div className="grid grid-cols-2 mt-[-4px] flex-1 z-0">
                    <div className={`flex flex-col h-full col-span-1 border-r border-dashed border-white w-2 ${step.completed ? 'bg-Blueberry-light-default' : ' bg-Grey-tint'}`}></div>
                    <div className={`flex flex-col h-full col-span-1 ${step.completed ? 'bg-Blueberry-light-default' : ' bg-Grey-tint'}`}></div>
                </div>
            </div>

            <div className="flex w-full h-full ml-5 mt-1">{t(step.message)}</div>
        </div>
    )
}


export function OrderCompleted() {

    const { t } = useTranslation("dongleRoadMap");
    const { user, refreshUserAccountInfo } = useUserData();
    const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);

    return (
        <>
            {onboardingModalOpen ? (
                <OnboardingModal
                    isOpen={onboardingModalOpen}
                    startingStep={user?.accountInfo?.lastOnboardingStep || 0}
                    onCancel={() => {
                        refreshUserAccountInfo();
                        setOnboardingModalOpen(false);
                    }}
                    completeButtonText="Finish onboarding"
                />
            ) : null}
            <div className="flex flex-col items-center justify-center">
                <OrderConfirmed />
                <h1 className="text-[32px] mt-8">{t("order-completed.title")}</h1>
                <div className="text-lg mt-4 text-center">
                    {t("order-completed.message-1")}
                </div>

                <div className="text-lg mt-5 text-center">
                    {t("order-completed.message-2.pt-1")} <span className="font-bold">{t("order-completed.message-2.pt-2")}</span>
                </div>
            </div>
        </>
    )

}

export function ShipmentComplete({ shipmentDate }: { shipmentDate?: string }) {

    const { t } = useTranslation("dongleRoadMap");
    const { localeCode } = useLayout();

    return (
        <div className="flex flex-col items-center justify-center">
            <DongleBox />
            <h1 className="text-[32px] mt-8">{t("shipment-completed.title")}</h1>
            <div className="text-lg mt-4 text-center">
                {t("shipment-completed.message-1.pt-1")} [{new Date(shipmentDate || Date.now()).toLocaleString(localeCode).split(', ')[0]}]. {t("shipment-completed.message-1.pt-2")} <span className="font-bold">{t("shipment-completed.message-1.pt-3")}</span>
            </div>
            <div className="text-lg mt-4 text-center">
                {t("shipment-completed.message-2")}
            </div>
            <div className="text-lg mt-4 text-center">
                {t("shipment-completed.message-3")}
            </div>
        </div>
    )

}


export function InitialDataReceived({
    missingDays,
    missingTrips,
    missingDistance
}: {
    missingDays?: number,
    missingTrips?: number,
    missingDistance?: number
}) {

    const { t } = useTranslation("dongleRoadMap");

    const getInfoString = () => {
        if (missingDays) {
            return `[${missingDays}] ${t("initial-data-received.missing-days")}`
        } else if (missingTrips) {
            return `[${missingTrips}] ${t("initial-data-received.missing-trips")}`
        } else if (missingDistance) {
            return `[${missingDistance}] ${t("initial-data-received.missing-distance")}`
        }
    }

    return (
        <div className="flex flex-col items-center justify-center">
            <TripsReceived />
            <h1 className="text-[32px] mt-8 text-center">{t("initial-data-received.title")}</h1>
            <div className="text-lg mt-4 text-center">
                {t("initial-data-received.message-1")}
            </div>
            <div className="text-lg mt-4 text-center">
                {t("initial-data-received.message-2")}
            </div>
            <div className="text-lg mt-4 text-center font-bold">
                {getInfoString()}
            </div>
            <div className="text-lg mt-4 text-center">
                {t("initial-data-received.message-3")}
            </div>
        </div>
    )


}

export function MinimumRequirementsMet() {

    const { t } = useTranslation("dongleRoadMap");
    const { refreshUserAccountInfo } = useUserData();
    const navigate = useNavigate();

    const completeMutation = useMutation(completeDongleLeasingOnboarding, {
        onSuccess: async (data) => {
            await refreshUserAccountInfo();
            if (data.dongleRoadMapCompleted) {
                const onboardedAtLeastStarted = data.onboarded || data.lastOnboardingStep;
                if (onboardedAtLeastStarted) {
                    navigate('/');
                    return;
                }
                navigate('/onboarding?force-open=true');
            }

        }
    })

    return (
        <div className="flex flex-col items-center justify-center">
            <DongleSetupCompleted />
            <h1 className="text-[32px] mt-8 text-center">{t("minimum-requirements-met.title")}</h1>
            <div className="text-lg mt-4 text-center">
                {t("minimum-requirements-met.message-1")}
            </div>
            <div
                onClick={() => completeMutation.mutate()}
                className="text-Blueberry-dark-default underline font-bold mt-6 flex items-center cursor-pointer">
                <div className="mr-1">{t("minimum-requirements-met.message-2")}</div>
                <ArrowRightIcon color={"#78194F"} />
            </div>
        </div>
    )

}