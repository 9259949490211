/* third party imports */
import { useTranslation } from "react-i18next";

/* local imports */
import YearlyMileageGraph from "./YearlyMileageChart";
import ChartWrapper from "../../../../../../../../common/components/Charts/ChartWrapper";

export default function YearlyMileage({
  data,
  sort,
  openExpanded,
  sortedCrescent,
} : {
  data: any,
  sort: ()=> void,
  openExpanded: () => void,
  sortedCrescent?: boolean
}) {
  const { t } = useTranslation("drivingAnalysis");

  return (
    <ChartWrapper
      title={t("yearly-mileage-title")}
      sort={sort}
      expand={openExpanded}
      sortedCrescent={sortedCrescent}
    >
      <YearlyMileageGraph data={data} />
    </ChartWrapper>
  );
}
