import { useTranslation } from "react-i18next";
import { DistanceSegmentChart } from "./DistanceSegmentChart";
import ChartWrapper from "../../../../../../../../common/components/Charts/ChartWrapper";

export function DistanceSegments({
    perTrip,
    perDay,
    openExpanded,
}: {
    perTrip: any;
    perDay: any;
    openExpanded: () => void;
}) {
    const { t } = useTranslation("driverDrivingAnalysis");

    return (
        <ChartWrapper title={t("distance-segments.title")} expand={openExpanded}>
            <DistanceSegmentChart data={{ perDay: perDay, perTrip: perTrip }} />
        </ChartWrapper>
    );
}
