

export function ProgressBar({steps, step}: {steps:any[], step: number}) {


    return (
        <div className="flex justify-between p-10 pb-0">
            {steps.filter((_,idx) => idx > 0 && idx < steps.length -1 ).map((v, idx: number) => <div className={`h-1 w-full mx-1 ${step > idx ? 'bg-Blueberry-dark-default' : 'bg-Grey-tint'}`}></div>)}
        </div>
    )


}