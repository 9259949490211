import { IRoute } from '../../../@types/routes';
import ForgotPassword from './ForgotPassword';
import Login from './Login';
import { NotFound } from './NotFound';
import ResetPassword from './ResetPassword';
import SignupAccountPicker from './Signup';
import DriverSignup from './Signup/DriverSignup';
import FleetSignup from './Signup/FleetSignup';
import VerifyEmail from './VerifyEmail';

export const PublicRoutes: IRoute[] = [
	{
		path: '/',
		component: Login,
	},
	{
		path: 'sign-up/electrify_fleet',
		component: FleetSignup,
	},
	{
		path: 'sign-up/electrify_driver',
		component: DriverSignup,
	},
	{
		path: '/sign-up/verify-email/*',
		component: VerifyEmail,
	},
	{
		path: 'sign-up',
		component: SignupAccountPicker,
	},
	{
		path: 'forgot-password',
		component: ForgotPassword,
	},
	{
		path: 'reset-password',
		component: ResetPassword,
	},
	// {
	// 	path: '*',
	// 	component: NotFound,
	// },
];
