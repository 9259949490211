import { useMutation } from "@apollo/client";
import { useEffect } from "react";
import { SELECT_SUB_PRODUCT } from "../../../../services/graphql/driver/mutations";
import { useUserData } from "../../../../hooks/state/useUserData";
import { useNavigate } from "react-router-dom";



export function useProductUpdate() {

    const { user, refreshUserAccountInfo } = useUserData();
    const navigate = useNavigate();

    const [selectSubProduct] = useMutation(SELECT_SUB_PRODUCT, {
        onCompleted: () => {
            localStorage.removeItem('selected-product')
            refreshUserAccountInfo();
        },
    });
    
    useEffect(() => {
        // Get query parameters
        const selectedProduct = localStorage.getItem('selected-product');
        if (selectedProduct) {
            navigate("/");
        }
        if (!!user?.accountInfo && !!selectedProduct && (selectedProduct !== user?.accountInfo?.preSelectedProduct)) {
            selectSubProduct({
                variables: {
                    product: selectedProduct,
                },
            });
        }

    }, [user]);

}
