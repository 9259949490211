import { useState } from "react";
import { getImageServerWithFallback } from "../../../../utils";

const NoBrandImagePlaceHolder = () => (
    <div className="w-10 h-10 rounded-full bg-Grey-light bg-opacity-40 mr-3" />
);

export function BrandInfo({
    vehicleBrand,
    vehicleModel,
}: {
    vehicleBrand?: string | null;
    vehicleModel?: string | null;
}) {
    
    const [displayBrandImage, setDisplayBrandImage] = useState(true);
    const replaceBrandImgWithEPlaceholder = () => setDisplayBrandImage(false);

    return (
        <div className="flex items-start">
            {displayBrandImage && vehicleBrand ? (
                <img
                    className="mr-3 w-10 object-contain aspect-square"
                    src={`${getImageServerWithFallback()}/images/logo/oem?name=${vehicleBrand}`}
                    loading="lazy"
                    onError={replaceBrandImgWithEPlaceholder}
                    alt="vehicle-brand"
                />
            ) : (
                <NoBrandImagePlaceHolder />
            )}
            <div>
                <div className="text-xs text-Grey-default">{vehicleBrand}</div>
                <div className="text-sm text-black">{vehicleModel}</div>
            </div>
        </div>
    );
}
