import { useMemo } from "react";
import { PublicLocation } from "../../../../../../@types";

const MAX_VERTICAL_THRESHOLD = 0.08;
const MAX_SIDE_THRESHOLD = 0.20;

export function useTooltipPositioner(cp: PublicLocation, centerPoint: any, currentBounds: {
    southWest: {
        lat: number;
        lon: number;
    };
    northEast: {
        lat: number;
        lon: number;
    };
}) {

    const horizontalSpan = currentBounds?.northEast.lon - currentBounds?.southWest?.lon;
    const verticalSpan = currentBounds?.northEast.lat - currentBounds?.southWest?.lat;


    const coordinatesOnTheUpperQuadrants = useMemo(() => {
        return (cp.lat - centerPoint[0]) / verticalSpan > MAX_VERTICAL_THRESHOLD;
    }, [cp.lat, centerPoint[0]])

    const coordinatedOnTheRightQuadrants = useMemo(() => {
        return (cp.lon - centerPoint[1]) / horizontalSpan > MAX_SIDE_THRESHOLD;
    }, [cp.lon, centerPoint[1]])

    const coordinateOnTheLeftQuadrants = useMemo(() => {
        return (cp.lon - centerPoint[1]) / horizontalSpan < -MAX_SIDE_THRESHOLD;
    },[cp.lon, centerPoint[1]])


    const verticalOffset = useMemo(() => {
        if (coordinatesOnTheUpperQuadrants) return 'top-2';
        return 'top-[-200px]';
    }, [cp.lat, cp.lon, centerPoint])


    const horizontalOffset = useMemo(() => {
        if (coordinateOnTheLeftQuadrants) return 'ml-[100px]';
        else if (coordinatedOnTheRightQuadrants) {
            return 'ml-[-84px]'
        };
        return ''
    }, [cp.lat, cp.lon, centerPoint])

    
    const arrow = useMemo(() => {
        const triangleUp = 'w-0 h-0 border-l-[5px] border-l-transparent border-b-[10px] border-b-Grey-dark border-r-[5px] border-r-transparent';
        const triangleDown = 'w-0 h-0 border-l-[5px] border-l-transparent border-t-[10px] border-t-Grey-dark border-r-[5px] border-r-transparent'
        const possibleArrows = {
            upperRight: `top-[-20%] right-[5%] ${triangleUp}`,
            upperCenter: `top-[-20%] right-[48%] ${triangleUp}`,
            upperLeft: `top-[-20%] left-[-5%]  ${triangleUp}`,
            bottomRight: `bottom-[-20%] right-[5%] ${triangleDown}`,
            bottomCenter: `bottom-[-20%] right-[48%] ${triangleDown}`,
            bottomLeft: `bottom-[-20%] left-[-5%] ${triangleDown}`,
        }
        let arrow;
        if (coordinatedOnTheRightQuadrants) {
            if (coordinatesOnTheUpperQuadrants) {
                arrow = possibleArrows.upperRight;
            } else {
                arrow = possibleArrows.bottomRight;
            }
        } else if (coordinateOnTheLeftQuadrants) {
            if (coordinatesOnTheUpperQuadrants) {
                arrow = possibleArrows.upperLeft;
            } else {
                arrow = possibleArrows.bottomLeft;
            }
        } else {
            if (coordinatesOnTheUpperQuadrants) {
                arrow = possibleArrows.upperCenter;
            } else {
                arrow = possibleArrows.bottomCenter;
            }
        }

        return (<div className={`absolute ${arrow}`} />)
    }, [cp.lat, cp.lon, centerPoint]);


    return { arrow, horizontalOffset, verticalOffset }

}