import { useMemo } from "react"
import { Voucher } from "../../../../../../../../@types/driver/payments";



export function usePriceCalculator({ price, voucher }: { price: number, voucher?: Voucher }) {


    const priceAfterDiscount = useMemo(() => {
      
        if (voucher?.percentOff) {
            return (price - (price * (voucher?.percentOff / 100)));
        } else if(voucher?.amountOff) {
            return (price - voucher?.amountOff);
        }
        return price;

    }, [voucher?.percentOff, voucher?.amountOff, price])

    return {
        priceAfterDiscount
    }



}