import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { PaymentState } from "./PaymentState";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../../../../../hooks/state/useUserData";
import { DriverPaymentGatewayContext } from "../../../../contexts/DriverPaymentGatewayContext";
import { Tariff } from "../../../../../../@types/paymentProcess";
import { getTariffsForDriverUser } from "../../../../../../services/rest/paymentProcess";
import { Spinner } from "../../../../../../electrify_frontend_common/components/Spinner";
import ModalWrapper from "../../../../../../electrify_frontend_common/components/ModalWrapper";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY || "");


export function PaymentCompleted() {

    const { user } = useUserData();
    const [purchasedTariff, setPurchasedTariff] = useState<Tariff | null>(null);

    const { clientSecret } = useContext(DriverPaymentGatewayContext);
    const { data: tariffs } = useQuery<Tariff[]>(['tariffs'], getTariffsForDriverUser);


    useEffect(() => {
        if (user?.accountInfo?.preSelectedProduct && tariffs) {
            const preselectedTariff = tariffs.filter((t: Tariff) => t.product.name === user?.accountInfo?.preSelectedProduct)[0];
            if (preselectedTariff) {
                setPurchasedTariff(preselectedTariff);
            }
        }
    }, [user, tariffs,])


    if (!clientSecret || !purchasedTariff) return <Spinner />;

    return (

        <ModalWrapper
            isOpen={true}
            close={() => null}
            className="w-full h-full">
            <Elements stripe={stripePromise} options={{ clientSecret }}>
                <PaymentState tariff={purchasedTariff} clientSecret={clientSecret} />
            </Elements>
        </ModalWrapper >

    )
}

export function PaymentFeedbackEntry() {

    const navigate = useNavigate();
    const { openPaymentFeedback } = useContext(DriverPaymentGatewayContext);

    const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret'
    ) || new URLSearchParams(window.location.search).get(
        'setup_intent_client_secret'
    ) || null;

    useEffect(() => {
        if (clientSecret) {
            openPaymentFeedback(clientSecret);
            navigate('/');
        }
    }, [clientSecret, openPaymentFeedback]);

    return <></>;
}