import { Route, Routes } from "react-router-dom";
import { useProductUpdate } from "./hooks/useProductUpdate";
import { useOnboardingRedirects } from "./hooks/useOnboardingRedirects";
import LayoutWrapper from "../../../common/components/LayoutWrapper";
import { IRoute } from "../../../@types/routes";
import { DriverPaymentGatewayContext, DriverPaymentGatewayContextProvider } from "../contexts/DriverPaymentGatewayContext";
import { ElectrifyDriverRoutes } from "./routes";
import NavbarItem from "../../../common/components/LayoutWrapper/Navbar/NavbarItem/NavbarItem";
import { DashboardIcon } from "../../../electrify_frontend_common/icons/DashboardIcon";
import { AnalysisIcon } from "../../../electrify_frontend_common/icons/AnalysisIcon";
import { NavbarSubItem } from "../../../common/components/LayoutWrapper/Navbar/NavbarItem/NavbarSubItem";
import { useTranslation } from "react-i18next";
import { useNavbar } from "../../../common/components/LayoutWrapper/Navbar/useNavbar";
import { SuitabilityIcon } from "../../../electrify_frontend_common/icons/SuitabilityIcon";
import { VehicleIcon } from "../../../electrify_frontend_common/icons/VehicleIcon";
import { SimulationIcon } from "../../../electrify_frontend_common/icons/SimulationIcon";
import { ElectrificationPlannerIcon } from "../../../electrify_frontend_common/icons/ElectrificationPlannerIcon";
import { SettingsIcon } from "../../../electrify_frontend_common/icons/SettingsIcon";
import { useContext, useMemo } from "react";
import { ElectrificationPlannerDriver } from "../ElectrificationPlanner";
import { useUserData } from "../../../hooks/state/useUserData";


function ElectrifyDriverNavbar() {

    const { t } = useTranslation("driverNavbar");
    const {routeToForce} = useOnboardingRedirects();

    const {user} = useUserData();

    const {
        mainPathName,
        subPathName
    } = useNavbar();


    const unusableProduct = !user?.accountInfo?.usableProduct;

    return (
        <>
            <NavbarItem
                idx={1}
                disabled={false}
                to={routeToForce || "/"}
                title={t("overview")}
                activeCondition={routeToForce ? mainPathName === routeToForce : mainPathName === ""}
                Icon={DashboardIcon}
            />

            <NavbarItem
                idx={2}
                disabled={unusableProduct}
                to={`/insights/driving`}
                title={t("insights")}
                activeCondition={mainPathName === "insights"}
                Icon={AnalysisIcon}
                // hidden={true}
                subMenus={[
                    <NavbarSubItem
                        disabled={unusableProduct}
                        to={`insights/driving`}
                        title={t("driving-analysis")}
                        activeCondition={
                            mainPathName === "insights" && subPathName === "driving"
                        }
                    />,
                    <NavbarSubItem
                        disabled={unusableProduct}
                        to={`/insights/parking`}
                        title={t("parking-analysis")}
                        activeCondition={
                            mainPathName === "insights" && subPathName === "parking"
                        }
                    />
                ]}
            />

            <NavbarItem
                idx={3}
                disabled={unusableProduct}
                to={"/suitability"}
                title={t("suitability")}
                activeCondition={mainPathName === "suitability"}
                Icon={SuitabilityIcon}
            />

            <NavbarItem
                idx={4}
                disabled={false}
                to={`/ev-finder`}
                title={t("vehicle-selection")}
                activeCondition={mainPathName === "ev-finder"}
                Icon={VehicleIcon}
            />

            <NavbarItem
                idx={5}
                disabled={unusableProduct}
                to={`/simulation/scenarios`}
                title={t("simulation")}
                activeCondition={mainPathName === "simulation"}
                Icon={SimulationIcon}
                subMenus={[

                    <NavbarSubItem
                        disabled={unusableProduct}
                        to={`/simulation/scenarios`}
                        title={t("scenarios")}
                        activeCondition={
                            mainPathName === "simulation" && subPathName === "scenarios"
                        }
                    />,
                    <NavbarSubItem
                        disabled={unusableProduct}
                        to={`/simulation/trips`}
                        title={t("trip-simulation")}
                        activeCondition={
                            mainPathName === "simulation" && subPathName === "trips"
                        }
                    />]
                }
            />
            <NavbarItem
                idx={6}
                disabled={false}
                to={"/electrification-planner"}
                title={t("electrification-planner")}
                activeCondition={mainPathName === "electrification-planner"}
                Icon={ElectrificationPlannerIcon}
                subMenus={[
                    <NavbarSubItem
                        disabled={false}
                        to={`electrification-planner`}
                        title={t("checklist")}
                        activeCondition={
                            mainPathName === "electrification-planner" && !subPathName
                        }
                    />,
                    <NavbarSubItem
                        disabled={true}
                        to={`/electrification-planner/next-tasks`}
                        title={t("next-tasks")}
                        activeCondition={
                            mainPathName === "electrification-planner" && subPathName === "next-tasks"
                        }
                    />
                ]
                }
            />
            <NavbarItem
                idx={7}
                disabled={unusableProduct}
                to={`/settings`}
                title={t("settings")}
                activeCondition={mainPathName === "settings"}
                Icon={SettingsIcon}
            />
        </>
    )


}

function ElectrifyDriverContent() {

    

    return (
        <LayoutWrapper navbar={<ElectrifyDriverNavbar />}>
            <Routes>
                {ElectrifyDriverRoutes?.map((dr: IRoute) => <Route key={dr.path} path={dr.path} element={<dr.component />} />)}
            </Routes>
        </LayoutWrapper>
    )
}


function ElectrifyDriverWithFallback() {

    const { isProductPaid } = useContext(DriverPaymentGatewayContext);


    const availableProduct = useMemo(() => {
        if (isProductPaid) return <ElectrifyDriverContent />
        return <ElectrificationPlannerDriver />
    }, [isProductPaid]);

    return (
        <>{availableProduct}</>
    )

}


export function ElectrifyDriver() {

    return (
        <DriverPaymentGatewayContextProvider>
            <ElectrifyDriverWithFallback />
        </DriverPaymentGatewayContextProvider>
    )
}