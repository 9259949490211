

export const sortDataAscending = (datasets: any) => {
    datasets.map((dataset: any) => dataset.data?.sort((a: any, b: any) => a.value - b.value));
};

export const sortDataDescending = (datasets: any) => {
    datasets.map((dataset: any) => dataset.data?.sort((a: any, b: any) => b.value - a.value));
};

export const sortParkingTypeDataBasedOnLabels = (sortedLabels: any, objectToSort: any) => {
    for (let i = 1; i < objectToSort.length; i++) {
        const array: any = [];
        sortedLabels.forEach((label: any) => {
            const idx = objectToSort[i]?.data.findIndex((d: any) => d.label === label);
            array.push(objectToSort[i]?.data[idx]);
        });
        objectToSort[i].data = array;
    }
};

export const sortParkingTypeDataAscending = (parkingTypeData: any) => {
    sortDataAscending([parkingTypeData[0]]);

    const parkingTypeDataSortedLabels = parkingTypeData[0].data.map(
        (d: any) => d.label
    );

    sortParkingTypeDataBasedOnLabels(
        parkingTypeDataSortedLabels,
        parkingTypeData
    );
};

export const sortParkingTypeDataDescending = (parkingTypeData: any) => {
    sortDataDescending([parkingTypeData[0]]);

    const parkingTypeDataSortedLabels = parkingTypeData[0].data.map(
        (d: any) => d.label
    );

    sortParkingTypeDataBasedOnLabels(
        parkingTypeDataSortedLabels,
        parkingTypeData
    );
};

