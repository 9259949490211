import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowDownIcon } from "../../../../../../assets/overview-arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../../../assets/overview-arrow-up.svg";
import { ReactElement, useContext, useMemo } from "react";
import { ComparisonProp } from "../../../../../../@types";
import { useLayout } from "../../../../../../hooks/state/useLayout";
import { FleetSimulationContext } from "../../../../../../contexts/FleetSimulation";
import { Spinner, SpinnerSize } from "../../../../../../electrify_frontend_common/components/Spinner";


const getcomparisonProps = (
  comparison: string,
  localeCode: string
): ComparisonProp => {
  const val = parseFloat(comparison);
  const displayValue = parseFloat(comparison).toLocaleString(localeCode);

  if (val > 0) {
    return {
      icon: <ArrowUpIcon className="ml-1" />,
      backgroundColor: "bg-Red-default",
      value: `+${displayValue}%`,
    };
  } else if (val < 0) {
    return {
      icon: <ArrowDownIcon className="ml-1" />,
      backgroundColor: "bg-Green-default",
      value: `${displayValue}%`,
    };
  } else {
    return {
      icon: null,
      backgroundColor: "bg-Grey-default",
      value: `${displayValue}%`,
    };
  }
};


export function MetricWithSpinnerDefault({
  value,
  loading
}: {
  value?: ReactElement,
  loading: boolean
}) {

  return (
    <div>
      {!loading ? value : <Spinner size={SpinnerSize.SMALL} />}
    </div>
  )

}

export function FleetMetricsComparator() {

  const { t } = useTranslation("fleetSimulation");
  const { currencySymbol, localeCode } = useLayout();

  const { overviewData, overviewDataLoading } = useContext(FleetSimulationContext);

  const currentFleet = overviewData?.currentFleet || {};
  const simulatedFleet = overviewData?.simulatedFleet || {};

  const yearlyMileageComparisonProps: ComparisonProp = useMemo(
    () =>
      getcomparisonProps(
        simulatedFleet?.yearlyFuelAndEnergyCostsChange,
        localeCode
      ),
    [simulatedFleet?.yearlyFuelAndEnergyCostsChange, localeCode]
  );
  const yearlyCo2EmissionComparisonProps: ComparisonProp = useMemo(
    () =>
      getcomparisonProps(simulatedFleet?.yearlyCo2EmissionChange, localeCode),
    [simulatedFleet?.yearlyCo2EmissionChange, localeCode]
  );

  return (
    <>

      <div className="sticky top-0 bg-white z-20">
        <div className="px-10 pt-6 pb-2 grid grid-cols-9">
          <div className="col-span-4">
            <h2 className="text-xl text-Grey-dark mb-4 font-normal">
              {t("current.analysis")}
            </h2>
            <h3 className="text-xs font-bold text-Grey-dark mb-1">
              {t("current.cost")}
            </h3>
            <h4 className="mb-6">
              <MetricWithSpinnerDefault
                value={<>
                  <span className="text-2xl font-black text-Grey-dark">
                    {currentFleet?.yearlyFuelAndEnergyCosts?.toLocaleString(
                      localeCode
                    )}
                  </span>
                  <span className="text-base font-light text-Grey-dark">
                    {" "}
                    {currencySymbol}/{t("time-unit")}
                  </span>
                </>}
                loading={overviewDataLoading}
              />

            </h4>
            <h3 className="text-xs font-bold text-Grey-dark mb-1">
              {t("current.co2")}
            </h3>
            <h4 className="mb-6">
              <MetricWithSpinnerDefault
                value={<>
                  <span className="text-2xl font-black	text-Grey-dark">
                    {currentFleet?.yearlyCo2Emission?.toLocaleString(localeCode)}
                  </span>
                  <span className="text-base font-light text-Grey-dark">
                    {" "}
                    kg/{t("time-unit")}
                  </span>
                </>}
                loading={overviewDataLoading}
              />

            </h4>
          </div>
          <div className="col-span-5">
            <h2 className="text-xl text-Grey-dark mb-4 font-normal">
              {t("simulation.simulation")}
            </h2>
            <h3 className="text-xs font-bold text-Grey-dark mb-1">
              {t("simulation.cost")}
            </h3>
            <div className="mb-6 flex items-center">
              <MetricWithSpinnerDefault
                value={<>
                  <span className="text-2xl font-black text-Blueberry-dark-default">
                    {simulatedFleet?.yearlyFuelAndEnergyCosts?.toLocaleString(
                      localeCode
                    )}
                  </span>
                  <span className="text-base font-light text-Blueberry-dark-default pt-1 pl-2">
                    {currencySymbol}/{t("time-unit")}
                  </span>
                </>}
                loading={overviewDataLoading}
              />

              {simulatedFleet?.yearlyFuelAndEnergyCostsChange != null ? (
                <div
                  className={`rounded flex items-center p-1 ml-4 pl-2 font-bold text-white text-base ${yearlyMileageComparisonProps.backgroundColor}`}
                >
                  {yearlyMileageComparisonProps.value}{" "}
                  {yearlyMileageComparisonProps?.icon}
                </div>
              ) : null}
            </div>
            <h3 className="text-xs font-bold text-Grey-dark mb-1">
              {t("simulation.co2")}
            </h3>
            <div className="mb-6 flex items-center">
              <MetricWithSpinnerDefault
                value={<>
                  <span className="text-2xl font-black	text-Blueberry-dark-default">
                    {simulatedFleet?.yearlyCo2Emission?.toLocaleString(localeCode)}
                  </span>
                  <span className="text-base font-light text-Blueberry-dark-default pt-1 pl-2">
                    kg/{t("time-unit")}
                  </span>
                </>}
                loading={overviewDataLoading}
              />

              {simulatedFleet?.yearlyCo2EmissionChange != null ? (
                <div
                  className={`rounded flex items-center p-1 ml-4 pl-2 font-bold text-white text-base ${yearlyCo2EmissionComparisonProps.backgroundColor}`}
                >
                  {yearlyCo2EmissionComparisonProps.value}{" "}
                  {yearlyCo2EmissionComparisonProps?.icon}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

    </>
  );
}
