import { useContext, useMemo, useState } from "react";
import Input from "../../../../../../../../common/components/misc/Input";
import { Button } from "../../../../../../../../electrify_frontend_common/components/Buttonv2";
import { DriverPaymentGatewayContext } from "../../../../../../contexts/DriverPaymentGatewayContext";
import { PaymentContext } from "../../../../../../../../contexts/PaymentContext";
import { ButtonSize, ButtonVariant } from "../../../../../../../../electrify_frontend_common/components/Buttonv2/types";
import ModalWrapper from "../../../../../../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../../../../../../electrify_frontend_common/components/ModalWrapper/Headers";
import { Spinner, SpinnerSize } from "../../../../../../../../electrify_frontend_common/components/Spinner";
import ChartButtonHeader from "../../../../../../../../common/components/Charts/ChartButtonHeader";



function VoucherRedemptionOptions({ option }: { option: "code" | "email" }) {

    const {
        voucherCode,
        setVoucherCode,
        companyEmail,
        setCompanyEmail,
        applyVoucher,
        voucherRedemptionError,
        resetError
    } = useContext(DriverPaymentGatewayContext);

    const { selectedTariff } = useContext(PaymentContext);

    return (
        <>
            {option === "email" ?
                <>
                    <h3 className="font-bold mb-2">Company email</h3>
                    <span className="text-sm text-Grey-shade">Please insert you company email so that we can redeem a voucher to your account</span>
                    <Input
                        onChange={(e) => setCompanyEmail(e.target.value)}
                        value={companyEmail || ""}
                        error={!!voucherRedemptionError}
                    />
                    {voucherRedemptionError ? <div className="text-red-500 text-sm">{voucherRedemptionError}</div> : null}

                </>
                : <>
                    <h3 className="font-bold mb-2">Voucher code</h3>
                    <span className="text-sm text-Grey-shade">Please insert a voucher code</span>
                    <div className="flex items-center w-full justify-center p-6">
                        <div className="text-xl text-center font-bold">Chargylize -</div>
                        <div className="w-1/6 ml-2">
                            <input
                                type="text"
                                className="border border-Grey-shade rounded-md p-1 w-full font-bold text-center text-xl"
                                value={voucherCode || ""}
                                onChange={(e) => {
                                    setVoucherCode(e.target.value?.toUpperCase())
                                    resetError();
                                }}
                                maxLength={6}
                            />

                        </div>

                    </div>
                    {voucherRedemptionError ? <div className="text-red-500 text-sm">{voucherRedemptionError}</div> : null}

                </>}

            <Button
                type="button"
                onClick={() => applyVoucher({ voucherCode, companyEmail, tariffId: selectedTariff?.id || 0 })}
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.LARGE}
                disabled={!!((voucherCode?.length || 0) < 6 || voucherRedemptionError)}
                className="w-full my-1">
                Redeem voucher
            </Button>
        </>
    )



}


export function Voucher() {

    const {
        voucherModalOpen,
        setVoucherModalOpen,
        setVoucherCode,
        setCompanyEmail,
        verifyingVoucher,
        voucherRedeemedSuccessfully,
    } = useContext(DriverPaymentGatewayContext);

    const [redemptionOption, setRedemptionOption] = useState<"code" | "email">("code");


    return (
        <ModalWrapper isOpen={voucherModalOpen} close={() => setVoucherModalOpen(false)} className="w-1/2">
            <div >
                <ModalHeader title="Chargylize Vouchers" onCancel={() => setVoucherModalOpen(false)} />
                <div className="p-10">
                    {/* <div className="flex items-center mb-6">
                        <ChartButtonHeader
                            button1={{
                                onClick: () => {
                                    setCompanyEmail(undefined);
                                    setRedemptionOption("code")},
                                label: "Code",
                            }}
                            button2={{
                                onClick: () => {
                                    setVoucherCode(undefined);
                                    setRedemptionOption("email")
                                },
                                label: "Company email",
                            }}
                            checkProp={redemptionOption === "code"}
                        />
                    </div> */}
                    {!verifyingVoucher ?
                        <>
                            {
                                voucherRedeemedSuccessfully ?
                                    <div>
                                        <div>Voucher redeemed successfully!!</div>
                                        <Button
                                            type="button"
                                            onClick={() => setVoucherModalOpen(false)}
                                            variant={ButtonVariant.PRIMARY}
                                            size={ButtonSize.LARGE}
                                            className="w-full my-1"
                                        >Continue</Button>
                                    </div>
                                    : <VoucherRedemptionOptions
                                        option={redemptionOption}
                                    />
                            }
                        </>
                        : <div className="flex items-center">
                            <Spinner size={SpinnerSize.MEDIUM} />
                            <div className="ml-2">Verifying voucher...</div>
                        </div>}

                </div>
            </div>
        </ModalWrapper>
    )


}