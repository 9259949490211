import { gql } from "@apollo/client";

export const UPDATE_VEHICLE = gql`
  mutation UpdateVehicle($id: ID!, $vehicle: VehicleInput!) {
    updateVehicle(id: $id, vehicle: $vehicle) {
    id
    name
    userId
    yearlyTax
    yearlyMaintenanceCost
    yearlyInsuranceCost
    plannedReplacementDate
    originalInfo {
      name
      vehicleNumber
      licensePlate
      driverName
    }
    modelInfo {
      useVin
      vin
      modelId
      brand
      variant
      model
      fuelType
      fuelConsumption {
        combined
        city
        highway
      }
      category
      segment
      bodyStyle
      propulsion
      towHitchPossible
      seatsCount
      loadVolume
      loadWeight
      modelDataFromVin
    }
    archived
    deletionRequestedAt
    analysisPeriod {
      to
      from
    }
    }
  }
`;

export const UPDATE_SETTING = gql`
  mutation UpdateSetting($setting: SettingInput!) {
    updateSetting(setting: $setting) {
      userId
    billingInfo {
      name
      company
      email
      taxId
      address {
        street
        streetNr
        zip
        city
        country
      }
    }
    simulation {
      priceAndCo2 {
        petrol {
          price
        }
        diesel {
          price
        }
        charging {
          home {
            price
            co2
          }
          public {
            ac {
              price
            }
            dc {
              price
            }
            co2
          }
        }
      }
    }
    language
    referenceNumber
    }
  }
`;

export const DELETE_VEHICLE = gql`
  mutation DeleteVehicle($id: ID!) {
    deleteVehicle(id: $id) {
      id
      archived
    }
  }
`;
export const RESTORE_VEHICLE = gql`
  mutation RestoreVehicle($id: ID!) {
    restoreVehicle(id: $id) {
      id
    }
  }
`;

export const DELETE_ADDRESS = gql`
  mutation DeleteAddress($id: ID!) {
    deleteAddress(id: $id)
  }
`;

export const CREATE_ADDRESS = gql`
  mutation CreateAddress($address: LocationInput!, $vehicleId: ID) {
    createAddress(address: $address, vehicleId: $vehicleId) {
      id
      valid
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation UpdateAddress($id: ID!, $address: LocationInput!) {
    updateAddress(id: $id, address: $address) {
      id
      name
      street
      streetNr
      zip
      city
      country
      power
      valid
      locationType
    }
  }
`;

export const UPDATE_SIMULATION_RESULTS = gql`
  mutation UpdateSimulationResults($vehicleIds: [ID]) {
    updateSimulationResults(vehicleIds: $vehicleIds) {
      vehicleIds
      blockedSimulations
    }
  }
`;
