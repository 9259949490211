import { VehicleSelectionQueries, VehicleSelectionState } from "../../../../../../../@types/driver/simulations";
import { useVehicleSimulationEffects } from "./useVehicleSimulationEffects";
import { useVehicleSimulationQueriesAndMutations } from "./useVehicleSimulationQueriesAndMutations";
import { useVehicleSimulationState } from "./useVehicleSimulationState";


export function useVehicleSimulation() : {
  vehicleSelectionState: VehicleSelectionState,
  vehicleSelectionQueriesAndMutations: VehicleSelectionQueries
} {

  const vehicleSelectionState: VehicleSelectionState = useVehicleSimulationState();
  const vehicleSelectionQueriesAndMutations = useVehicleSimulationQueriesAndMutations(vehicleSelectionState);
  useVehicleSimulationEffects({ vehicleSelectionState, vehicleSelectionQueriesAndMutations});


  return {
    vehicleSelectionQueriesAndMutations,
    vehicleSelectionState
  };

}