import { Route, Routes, useNavigate } from 'react-router-dom';
import LayoutWrapper from '../../../common/components/LayoutWrapper';
import { ElectrifyFleetRoutes } from './routes';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavbar } from '../../../common/components/LayoutWrapper/Navbar/useNavbar';
import { useUserData } from '../../../hooks/state/useUserData';
import NavbarItem from '../../../common/components/LayoutWrapper/Navbar/NavbarItem/NavbarItem';
import { DashboardIcon } from '../../../electrify_frontend_common/icons/DashboardIcon';
import { VehicleIcon } from '../../../electrify_frontend_common/icons/VehicleIcon';
import { AnalysisIcon } from '../../../electrify_frontend_common/icons/AnalysisIcon';
import { NavbarSubItem } from '../../../common/components/LayoutWrapper/Navbar/NavbarItem/NavbarSubItem';
import { SimulationIcon } from '../../../electrify_frontend_common/icons/SimulationIcon';
import { SettingsIcon } from '../../../electrify_frontend_common/icons/SettingsIcon';

function ElectrifyFleetNavbar(): ReactElement {

    const { t } = useTranslation("overview");

    const navigate = useNavigate();

    const {
        mainPathName,
        subPathName
    } = useNavbar();

    const { user } = useUserData();

    const onboarded = user?.accountInfo?.onboarded;
    const usableProduct = user?.accountInfo?.usableProduct;

    if (onboarded === false && mainPathName !== "onboarding") {
        navigate("onboarding");
    }

    return <>

        <NavbarItem
            idx={1}
            disabled={!usableProduct}
            to={"/"}
            title={t("overview")}
            activeCondition={mainPathName === ""}
            Icon={DashboardIcon}
        />

        <NavbarItem
            idx={2}
            disabled={!onboarded}
            to={"/vehicles"}
            title={t("vehicles")}
            activeCondition={mainPathName === "vehicles"}
            Icon={VehicleIcon}
        />

        <NavbarItem
            idx={3}
            disabled={!usableProduct}
            to={`/analysis/vehicle`}
            title={t("fleet-analysis")}
            activeCondition={mainPathName === "analysis"}
            Icon={AnalysisIcon}
            subMenus={[
                <NavbarSubItem
                    disabled={!usableProduct}
                    to={`analysis/vehicle`}
                    title={t("driving-analysis")}
                    activeCondition={
                        mainPathName === "analysis" && subPathName === "vehicle"
                    }
                />,
                <NavbarSubItem
                    disabled={!usableProduct}
                    to={`/analysis/location`}
                    title={t("parking-analysis")}
                    activeCondition={
                        mainPathName === "analysis" && subPathName === "location"
                    }
                />
            ]
            }
        />

        <NavbarItem
            idx={4}
            disabled={!usableProduct}
            to={`/simulation`}
            title={t("fleet-simulation")}
            activeCondition={mainPathName === "simulation"}
            Icon={SimulationIcon}
        />

        <NavbarItem
            idx={5}
            disabled={!usableProduct}
            to={"/settings/simulation"}
            title={t("settings")}
            activeCondition={mainPathName === "settings"}
            Icon={SettingsIcon}
            subMenus={[
                <NavbarSubItem
                    disabled={!usableProduct}
                    to={"/settings/simulation"}
                    title={t("settings-simulations")}
                    activeCondition={
                        mainPathName === "settings" && subPathName === "simulation"
                    }
                />,
                <NavbarSubItem
                    disabled={!usableProduct}
                    to={"/settings/billing"}
                    title={t("settings-billing")}
                    activeCondition={
                        mainPathName === "settings" && subPathName === "billing"
                    }
                />
            ]
            }
        />
    </>
}



export function ElectrifyFleet() {

    return (
        <LayoutWrapper
            navbar={<ElectrifyFleetNavbar />}
        >
            <Routes>
                {ElectrifyFleetRoutes.map(fr => (
                    <Route
                        key={fr.path}
                        path={fr.path}
                        element={<fr.component />}
                    />
                ))}
            </Routes>
        </LayoutWrapper>
    );
}
