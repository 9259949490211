import { gql } from "@apollo/client";

export const GET_DRIVER_VEHICLE = gql`
    query DriverVehicle {
        driverVehicle {
            id
            name
            userId
            yearlyTax
            yearlyMaintenanceCost
            yearlyInsuranceCost
            plannedReplacementDate
            originalInfo {
                name
                vehicleNumber
                licensePlate
                driverName
            }
            modelInfo {
                useVin
                vin
                modelId
                brand
                variant
                model
                fuelType
                fuelConsumption {
                    combined
                    city
                    highway
                }
                category
                segment
                bodyStyle
                propulsion
                towHitchPossible
                seatsCount
                loadVolume
                loadWeight
            }
            # consentDateTime
            archived
            deletionRequestedAt
            # subscriptionActive
            # lastUpdated
            analysisPeriod {
                to
                from
            }
            yearlyCo2Emission
            yearlyFuelAndEnergyCosts
            yearlyMileage
        }
    }
`;

export const GET_VEHICLE_CONSUMPTIONS_COSTS_AND_EMISSIONS = gql`
    query GetVehicleConsumptionsCostsAndEmissions {
        vehicleConsumptionsCostsAndEmissions {
            yearlyFuelAndEnergyCosts
            yearlyCo2Emission
        }
    }
`;

export const GET_VEHICLE_SIMULATION_PREFERENCES = gql`
    query GetVehicleSimulationPreferences {
        vehicleSimulationPreferences {
            setTemperature
            socBuffer
            setLoadWeight
        }
    }
`;

export const GET_TRIP_SIMULATION = gql`
    query GetTripSimulation(
        $optimizationMethod: String
        $includeExtraStops: Boolean
    ) {
        tripsSimulationDetails(
            optimizationMethod: $optimizationMethod
            includeExtraStops: $includeExtraStops
        ) {
            vehicleId
            eventType
            eventNumber
            tripNumber
            isExtraStop
            countOfExtraStops
            locationType
            fullyElectrified
            startDateTime
            duration
            distance
            startAddress
            endAddress
            startSoc
            endSoc
            chargedEnergy
        }
    }
`;

export const GET_DRIVING_ANALYSYS = gql`
    query GetDrivingAnalysis($sortOrder: String) {
        driverResultsDrivingAnalysis(sortOrder: $sortOrder) {
            distancesPerDayAndWeek {
                perWeek {
                    weekNumber
                    distance
                }
                perDay {
                    date
                    distance
                }
            }
            distanceLengthSegments {
                perDay {
                    distanceTo9Km
                    distanceFrom10To49Km
                    distanceFrom50To99Km
                    distanceFrom100To299Km
                    distanceFrom300Km
                }
                perTrip {
                    distanceTo9Km
                    distanceFrom10To49Km
                    distanceFrom50To99Km
                    distanceFrom100To299Km
                    distanceFrom300Km
                }
            }
            distanceWeekDistribution {
                weekdays
                weekends
            }
            averageDistanceWeekDistribution {
                weekdays
                weekends
                all
            }
            sortedTrips {
                label
                distance
            }
        }
    }
`;

export const GET_PARKING_ANALYSIS = gql`
    query GetParkingAnalysis {
        driverResultsParkingAnalysis {
            durationDistribution {
                driving
                parking
            }
            parkingDurationLocationDistribution {
                workplace
                home
                public
                unknown
                noCharging
            }
            dailyParkingDurationLocationDistribution {
                date
                home
                public
                workplace
            }
        }
    }
`;

export const GET_SIMULATION_SCENARIOS = gql`
    query GetSimulationScenarios {
        simulationScenarios {
            vehicleId
            eagerCharging {
                chargingSessions {
                    home
                    workplace
                    public
                    extra
                }
                chargedEnergy {
                    home
                    workplace
                    public
                    extra
                }
                energyCosts {
                    home
                    workplace
                    public
                    extra
                }
                energyCo2Emissions {
                    home
                    workplace
                    public
                    extra
                }
                tripsEndSoc {
                    to19
                    from20To39
                    from40To59
                    from60To79
                    from80
                }
            }
            minimumCosts {
                chargingSessions {
                    home
                    workplace
                    public
                    extra
                }
                chargedEnergy {
                    home
                    workplace
                    public
                    extra
                }
                energyCosts {
                    home
                    workplace
                    public
                    extra
                }
                energyCo2Emissions {
                    home
                    workplace
                    public
                    extra
                }
                tripsEndSoc {
                    to19
                    from20To39
                    from40To59
                    from60To79
                    from80
                }
            }
            minimumSessions {
                chargingSessions {
                    home
                    workplace
                    public
                    extra
                }
                chargedEnergy {
                    home
                    workplace
                    public
                    extra
                }
                energyCosts {
                    home
                    workplace
                    public
                    extra
                }
                energyCo2Emissions {
                    home
                    workplace
                    public
                    extra
                }
                tripsEndSoc {
                    to19
                    from20To39
                    from40To59
                    from60To79
                    from80
                }
            }
        }
    }
`;

export const GET_CHARGING_LOCATIONS = gql`
    query getChargingPoints($mapCorners: MapCorners!) {
        chargingLocations(mapCorners: $mapCorners) {
            lat
            lon
            id
            name
            powerType
            minimumPower
            maximumPower
            countOfEvses
        }
    }
`;

export const GET_TRIPS_AMOUNT_AND_DISTANCES = gql`
    query TripsAmountAndDistances {
        tripsAmountAndDistances {
            countOfTrips
            totalDistance
            yearlyMileage
        }
    }
`;

export const GET_EV_SUITABILITY = gql`
    query GetEvSuitability($onlyFilteredAlternatives: Boolean) {
        evSuitability(onlyFilteredAlternatives: $onlyFilteredAlternatives) {
            noRestrictions {
                countOfEvs
                minimumPrice
                evToDisplay {
                    evId
                    electrificationFactor
                    make
                    model
                    listPrice
                }
            }
            veryComfortable {
                countOfEvs
                minimumPrice
                evToDisplay {
                    evId
                    electrificationFactor
                    make
                    model
                    listPrice
                }
            }
            fairlyComfortable {
                countOfEvs
                minimumPrice
                evToDisplay {
                    evId
                    electrificationFactor
                    make
                    model
                    listPrice
                }
            }
            adaptationsNeeded {
                countOfEvs
                minimumPrice
                evToDisplay {
                    evId
                    electrificationFactor
                    make
                    model
                    listPrice
                }
            }
        }
    }
`;

export const GET_EV_SCORE = gql`
    query GetEvScore {
        evScore {
            electrificationFactor
        }
    }
`;
