import { ReactElement, createContext, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation, useQuery as useRestQuery } from 'react-query';
import { agreeWithPayment, applyVoucher, checkDriverVehicleSubscription } from '../../../../services/rest/paymentProcess';
import { SubscriptionState } from '../../../../enums';
import { useUserData } from '../../../../hooks/state/useUserData';
import { Payments } from '../../Electrify/pages/Payments';
import { PaymentCompleted } from '../../Electrify/pages/Payments/Feedback';
import { B2CProductTreeContext } from '../B2CProductTreeContext';
import { Voucher } from '../../../../@types/driver/payments';
import { useVoucher } from './useVoucher';


type VehicleSubscription = {
	product: any,
	state: SubscriptionState,
	clientSecret: string | null
}

export const DriverPaymentGatewayContext = createContext<{
	isProductPaid: boolean;
	isPaymentModalOpen: boolean;
	closePaymentModal: () => void;
	openPaymentModal: () => void;
	clientSecret: string | null;
	openPaymentFeedback: (clinetSecret: string) => void;
	refreshVehicleSubscriptionState: any;
	exitPayment: any;
	goBackToPayment: any;
	agreedToProceed: boolean;
	agreeWithPayment: () => void;
	applyVoucher: ({ voucherCode, companyEmail, tariffId }: { voucherCode?: string, companyEmail?: string, tariffId: number }) => void;
	voucherCode?: string;
	setVoucherCode: (voucherCode?: string) => void;
	companyEmail?: string;
	setCompanyEmail: (value?: string) => void;
	verifyingVoucher: boolean;
	usedVoucher?: Voucher;
	voucherModalOpen: boolean,
	setVoucherModalOpen: (value: boolean) => void,
	voucherRedeemedSuccessfully: boolean,
	voucherRedemptionError: string | null,
	resetError: () => void
}>({
	isProductPaid: false,
	isPaymentModalOpen: false,
	closePaymentModal: () => null,
	openPaymentModal: () => null,
	openPaymentFeedback: () => null,
	clientSecret: null,
	refreshVehicleSubscriptionState: null,
	exitPayment: null,
	goBackToPayment: null,
	agreedToProceed: false,
	agreeWithPayment: () => null,
	applyVoucher: ({ voucherCode, companyEmail, tariffId }: { voucherCode?: string, companyEmail?: string, tariffId: number }) => null,
	voucherCode: undefined,
	setVoucherCode: () => null,
	companyEmail: undefined,
	setCompanyEmail: () => null,
	verifyingVoucher: false,
	usedVoucher: undefined,
	voucherModalOpen: false,
	setVoucherModalOpen: () => null,
	voucherRedeemedSuccessfully: false,
	voucherRedemptionError: null,
	resetError: ()=> null
});

export const DriverPaymentGatewayContextProvider = ({
	children,
}: {
	children: ReactElement;
}) => {

	const { user } = useUserData();

	const { refetchUserSubscription } = useContext(B2CProductTreeContext);

	const location = useLocation();

	const [isPaymentModalOpen, setIsPaymentModalOpen] = useState<boolean>(false);

	const [isPaymentFeedbackOpen, setIsPaymentFeedbackOpen] = useState<boolean>(false);

	const [clientSecret, setClientSecret] = useState<string | null>(null);

	const [isProductPaid, setIsProductPaid] = useState(false);

	const [agreedToProceed, setAgreedToProceed] = useState(false);

	const {
		voucherModalOpen,
		setVoucherModalOpen,
		usedVoucher,
		voucherRedeemedSuccessfully,
		verifyingVoucher,
		voucherCode,
		setVoucherCode,
		companyEmail,
		setCompanyEmail,
		applyMutationFn,
		voucherRedemptionError,
		resetError
	} = useVoucher({ setAgreedToProceed });


	const manageSubscriptionState = (
		vehicleSubscription: VehicleSubscription | undefined,
	) => {
		const subscriptionIsNotExistant = !vehicleSubscription || vehicleSubscription.state === SubscriptionState.NON_EXISTANT;
		if (subscriptionIsNotExistant) {
			setIsProductPaid(false);
		} else if (
			!vehicleSubscription ||
			vehicleSubscription.state ===
			SubscriptionState.PAYMENT_FAILED
		) {
			setIsProductPaid(false);
			setClientSecret(vehicleSubscription?.clientSecret || null);
			setIsPaymentFeedbackOpen(true);
		} else {
			setIsProductPaid(true);
			refetchUserSubscription();
		}
	}


	const agreeWithPaymentMutation = useMutation(agreeWithPayment, {
		onSuccess: () => {
			setAgreedToProceed(true)
		}
	});



	const closePaymentModal = () => {
		setIsPaymentModalOpen(false)
	};
	const openPaymentModal = () => {
		setIsPaymentModalOpen(true)
	};

	const {
		isLoading: isLoadingPaidVehicles,
		refetch: refreshVehicleSubscriptionState,
	} = useRestQuery<{
		product: any,
		state: SubscriptionState,
		clientSecret: string | null
	}>(['tariff', location.pathname, user?.accountInfo?.preSelectedProduct], checkDriverVehicleSubscription, {
		onSuccess: vehicleSubscription => {
			manageSubscriptionState(vehicleSubscription);
		},
	});


	useEffect(() => {
		const noProductSelected = !user?.accountInfo?.preSelectedProduct;
		const productSelectedButNotPaid = user?.accountInfo?.preSelectedProduct && !isProductPaid;
		if (productSelectedButNotPaid) {
			openPaymentModal();
		} else if (noProductSelected) {
			closePaymentModal();
			closePaymentFeedback();
		} else if (isProductPaid) {
			closePaymentModal();
		}
	}, [isProductPaid, user?.accountInfo?.preSelectedProduct])


	useEffect(() => {
		if (!isPaymentModalOpen) setAgreedToProceed(false)
	}, [isPaymentModalOpen])




	const exitPayment = () => {
		setClientSecret(null);
		refreshVehicleSubscriptionState();
		localStorage.removeItem("usedVoucher");
	};

	const openPaymentFeedback = (clientSecret: string) => {
		setClientSecret(clientSecret);
		setIsPaymentFeedbackOpen(true);
	};

	const closePaymentFeedback = () => {
		setIsPaymentFeedbackOpen(false);
	}

	const goBackToPayment = () => {
		setAgreedToProceed(true);
		setIsPaymentFeedbackOpen(false);
		setIsPaymentModalOpen(true);
	};

	const displayPaymentProcess = isPaymentModalOpen && !isLoadingPaidVehicles;

	const displayPaymentFeedback = isPaymentFeedbackOpen && clientSecret && !isLoadingPaidVehicles;


	return (
		<DriverPaymentGatewayContext.Provider
			value={{
				isProductPaid,
				isPaymentModalOpen,
				closePaymentModal,
				openPaymentModal,
				clientSecret,
				refreshVehicleSubscriptionState,
				exitPayment,
				openPaymentFeedback,
				goBackToPayment,
				agreedToProceed,
				agreeWithPayment: agreeWithPaymentMutation.mutate,
				applyVoucher: applyMutationFn,
				voucherModalOpen,
				setVoucherModalOpen,
				usedVoucher,
				voucherRedeemedSuccessfully,
				verifyingVoucher,
				voucherCode,
				setVoucherCode,
				companyEmail,
				setCompanyEmail,
				voucherRedemptionError,
				resetError
			}}
		>
			{displayPaymentProcess ? <Payments /> : null}
			{displayPaymentFeedback ? <PaymentCompleted /> : null}
			{children}
		</DriverPaymentGatewayContext.Provider>
	);
};
