import { createContext, ReactElement, useState } from "react";
import { useUserData } from "../../../hooks/state/useUserData";
import { useQuery } from "react-query";
import { Product } from "../../../@types/driver/payments";
import { ElectrifySubProducts, SubscriptionState } from "../../../enums";
import { checkDriverVehicleSubscription } from "../../../services/rest/paymentProcess";



export const B2CProductTreeContext = createContext<{
    productFamily: string | null;
    product: string | null;
    refetchUserSubscription: () => void,
    isUserSubscriptionInfoLoading: boolean
}>({
    productFamily: null,
    product: null,
    refetchUserSubscription: () => null,
    isUserSubscriptionInfoLoading: false
});





export const B2CProductTreeContextProvider = ({
    children,
}: {
    children: ReactElement;
}) => {

    const { user } = useUserData();


    const [productFamily, setProductFamily] = useState<string | null>(null);
    const [product, setProduct] = useState<string | null>(null);

    const { refetch: refetchUserSubscription, isLoading: isUserSubscriptionInfoLoading } = useQuery<{
        product: Product,
        state: SubscriptionState,
        clientSecret: string | null
    }>(['product', user?.accountInfo, user?.accountInfo?.preSelectedProduct], checkDriverVehicleSubscription, {
        onSuccess: (data) => {
            if (data.product?.productFamily) {
                setProductFamily(data.product?.productFamily);
                setProduct(data.product?.name);
                return;
            }

            if (user?.accountInfo?.preSelectedProduct === ElectrifySubProducts.DRIVER_DONGLE ||
                user?.accountInfo?.preSelectedProduct === ElectrifySubProducts.DRIVER_DATA_UPLOAD) {
                setProductFamily("electrify");
                setProduct(user?.accountInfo?.preSelectedProduct);
                return;
            }

            setProductFamily("electrification_planner");

        }
    });



    return (
        <B2CProductTreeContext.Provider
            value={{
                product,
                productFamily,
                refetchUserSubscription,
                isUserSubscriptionInfoLoading
            }}
        >
            {children}
        </B2CProductTreeContext.Provider>
    );
};
