import { useTranslation } from "react-i18next";
import webfleetLogo from "../../../../../../assets/webfleet-logo.svg";

export default function NoTrackingDataAvailable() {
  const { t } = useTranslation("onboarding");

  return (
    <div className="p-10">
      {/* <h2 className="text-xl text-Black-default mb-6">
      {t("not-tracking-2-title")}
    </h2> */}
      <div className="grid grid-cols-2 gap-x-6">
        <a
          href={t("not-tracking-webfleet-url")}
          target="_blank"
          rel="noreferrer"
        >
          <button className="py-10 px-6 text-left rounded border border-solid border-Grey-tint hover:bg-Grey-background h-full w-full">
            <img src={webfleetLogo} alt="webfleet-logo" className="mb-6" />
            <h2 className="text-xl text-Blueberry-dark-default mb-2">
              {t("not-tracking-webfleet-title")}
            </h2>
            <p className="text-sm text-Grey-shade mb-6">
              {t("not-tracking-webfleet-description")}
            </p>
            {/* <button className="w-full py-5 py-6 bg-Blueberry-light-default rounded text-lg text-white">
            {t("button.select")}
          </button> */}
          </button>
        </a>
        <a href="mailto:support@chargylize.com?subject=Electrify Support - New partnerships">
          <button className="py-10 px-6 text-left rounded border border-solid border-Grey-tint hover:bg-Grey-background h-full w-full">
            <h2 className="text-xl text-Blueberry-dark-default mb-2">
              {t("not-tracking-not-listed-title")}
            </h2>
            <p className="text-sm text-Grey-shade mb-6">
              {t("not-tracking-not-listed-description")}
            </p>
            {/* <button className="w-full py-5 py-6 bg-Blueberry-light-default rounded text-lg text-white">
            {t("button.contact")}
          </button> */}
          </button>
        </a>
      </div>
    </div>
  );
}
