import { useTranslation } from "react-i18next";
import { TimeShareChart } from "./TimeShareChart";
import ChartWrapper from "../../../../../../../../common/components/Charts/ChartWrapper";

export function TimeShare({
  openExpanded,
  driving,
  parking,
}: {
  openExpanded: () => void;
  driving: any;
  parking: any;
}) {
  const { t } = useTranslation("driverParkingAnalysis");

  return (
    <ChartWrapper title={t("parking.title")} expand={openExpanded}>
      <TimeShareChart data={{ parking, driving }} />
    </ChartWrapper>
  );
}
