import { useEffect } from "react";
import { useTranslation } from "react-i18next";


import { FuelAndEnergy } from "./FuelAndEnergyDetails";
import { useLayout } from "../../../../../hooks/state/useLayout";
import Addresses from "../../../../../common/components/Address";

export function SimulationSettings() {
  const { t, i18n } = useTranslation("settingsSimulation");
  const language = i18n.language;
  const { setHeader } = useLayout();

  useEffect(() => {
    setHeader({
      defaultHeaderTitle: t("page-title"),
      defaultHeaderDescription: t("page-subtitle"),
    });
  }, [language]);



  return (
        <>
          <div className="p-10">
            <Addresses />
            <FuelAndEnergy />
          </div>
        </>
  );
}
