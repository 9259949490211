import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Arrow, BackgroundLines, EvScoreGauge, InnerLine } from "./components";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { Spinner, SpinnerSize } from "../../../../../../electrify_frontend_common/components/Spinner";
import { GET_EV_SCORE } from "../../../../../../services/graphql/driver/queries";


export function EvScore() {
  const { t } = useTranslation("driverOverview");
  const navigate = useNavigate();

  const [maxScore, setMaxScore] = useState(0);

  const { loading } = useQuery(GET_EV_SCORE, {
    onCompleted: (data) => {
      setMaxScore(data?.evScore?.electrificationFactor);
    },
  });

  const radius = 100; // Radius of the circle
  const width = 2 * radius + 100;
  const height = 2 * radius;

  const centroid = {
    x: width / 2,
    y: radius + 8,
  };

  const message = useMemo(() => {
    let message;
    if (maxScore === 100) {
      message = t("ev-score.message-100").split(". ");
    } else if (maxScore >= 90) {
      message = t("ev-score.message-90-99").split(". ");
    } else if (maxScore >= 76) {
      message = t("ev-score.message-76-89").split(". ");
    } else if (maxScore >= 51) {
      message = t("ev-score.message-51-75").split(". ");
    } else {
      message = t("ev-score.message-0-50").split(". ");
    }
    return (
      <div className="flex flex-col w-full items-center">
        <div>{message[0]}</div>
        <div>{message[1]}</div>
      </div>
    );
  }, [maxScore]);

  return (
    <div className="flex flex-col w-full h-full">
      <h1 className=" text-xl text-Black">{t("ev-score.title")}</h1>
      <div
        className="border w-full h-full mt-5 p-4 border-Grey-tint rounded bg-Grey-background cursor-pointer hover:shadow"
        onClick={() => navigate("/suitability")}
      >
        {loading ? (
          <div className="flex flex-col w-full h-full items-center justify-center">
            <Spinner size={SpinnerSize.MEDIUM} />
          </div>
        ) : (
          <>
            <>{message}</>
            <div className="flex flex-col w-full h-full items-center">
              <div className="flex-col w-fit">
                <svg
                  width={width}
                  viewBox={`0 -20 ${width} ${height - 20}`}
                  className="mt-2"
                >
                  <EvScoreGauge radius={radius} centroid={centroid} />
                  <BackgroundLines
                    radius={radius + 10}
                    centroid={centroid}
                    percentage={maxScore}
                  />
                  <InnerLine radius={radius - 15} centroid={centroid} />
                  <Arrow
                    radius={radius - 22}
                    centroid={centroid}
                    maxScore={maxScore}
                  />
                  <text>
                    <tspan
                      textAnchor="middle"
                      fontSize="3em"
                      fontWeight="bold"
                      color="#2C2C31"
                      x={centroid.x + 5}
                      y={centroid.y}
                    >
                      {maxScore}
                    </tspan>
                    <tspan fontSize="1em"> %</tspan>
                  </text>
                  {/* <text>
                    <tspan
                      textAnchor="middle"
                      fontSize="1em"
                      fontWeight="thin"
                      color="#2C2C31"
                      x={centroid.x - 10}
                      y={centroid.y + 25}
                    >
                      Out of 100%
                    </tspan>
                  </text>
                  <InfoIcon x={centroid.x + 38} y={centroid.y + 11} /> */}
                </svg>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
