import { useTranslation } from "react-i18next";
import { Tariff } from "../../../../../@types/paymentProcess";
import { ReactElement } from "react";
import { useLayout } from "../../../../../hooks/state/useLayout";
import { IuseSelectedVehicles } from "../../../../../hooks/utils/useSelectedVehicles";

export default function Calculator({
  title,
  message,
  vehiclesHook,
  tariff,
  tariffLoading,
  childComponent,
  childButton,
}: {
  title: string;
  message?: string;
  vehiclesHook: IuseSelectedVehicles;
  tariff: Tariff;
  tariffLoading?: boolean;
  childComponent?: ReactElement;
  childButton?: ReactElement;
}) {
  const { localeCode, currencySymbol } = useLayout();
  const { t } = useTranslation("paymentProcess");
  const subtotalAmount =
    Math.ceil(tariff?.price * vehiclesHook.selectedVehicles.length * 100) / 100;
  const vatAmount =
    Math.ceil(
      tariff?.price *
        vehiclesHook.selectedVehicles.length *
        (tariff?.vat / 100) *
        100
    ) / 100;

  return (
    <div className="shadow-xl p-2 h-full">
      <h2 className="text-xl pl-6 pt-6 mb-4">{title}</h2>
      {message ? <span className="text-Grey-shade pl-6">{message}</span> : null}

      {!tariffLoading && tariff ? (
        <div className="bg-Grey-background p-3 pt-4 m-2">
          <h1 className="text-md text-Blueberry-dark-default">
            {t("calculator.your-order")}:
          </h1>
          <hr className="border-solid border-Grey-tint mt-4" />
          <div className="flex mt-4 justify-between text-xs text-gray-tint">
            <span className="text-xs text-Grey-dark">
              <span className="text-xs text-Blueberry-dark-default">
                {vehiclesHook.selectedVehicles.length.toLocaleString(
                  localeCode
                )}
                x{" "}
              </span>
              {t("calculator.selected-vehicles")}
            </span>
            <span className="text-xs text-Grey-dark">
              {(
                (Math.ceil(tariff?.price * 100) / 100).toFixed(2) as any
              ).toLocaleString(localeCode) + currencySymbol}
              /{t("calculator.month")}
            </span>
          </div>
          <hr className="border-solid border-Grey-tint mt-5" />
          <div className="text-sm text-gray-tint mt-3">
            <div className="flex justify-between text-Grey-dark">
              <span>{t("calculator.subtotal")}:</span>
              <span>
                {(subtotalAmount.toFixed(2) as any).toLocaleString(localeCode) +
                  " " +
                  currencySymbol}
              </span>
            </div>
            <div className="flex justify-between mt-2 text-Grey-dark">
              <span>{t("calculator.vat")} (19%):</span>
              <span>
                {(vatAmount.toFixed(2) as any).toLocaleString(localeCode) +
                  " " +
                  currencySymbol}
              </span>
            </div>
          </div>
          <hr className="border-solid border-Grey-tint mt-3" />
          <div className="text-sm text-Grey-dark mt-3">
            <div className="flex justify-between">
              <span>{t("calculator.total")}:</span>
              <span>
                {(
                  (subtotalAmount + vatAmount).toFixed(2) as any
                ).toLocaleString(localeCode) +
                  " " +
                  currencySymbol}
              </span>
            </div>
          </div>
          {childButton ? (
            <div className="mt-4">
              <hr className="border-solid border-Grey-tint mb-6" />
              {childButton}
            </div>
          ) : null}
        </div>
      ) : null}

      {childComponent}
    </div>
  );
}
