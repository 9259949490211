import { ButtonInput, ButtonSize, ButtonVariant } from "./types";
import "./index.css"

export function Button({ variant = ButtonVariant.PRIMARY, size = ButtonSize.LARGE, type='submit', className, disabled, onClick, children }: ButtonInput ) {

    return (<button
        type={type}
        disabled={disabled}
        className={`btn ${size} ${variant} ${className}`}
        onClick={onClick}
    >
        {children}
    </button>)

}