import { LatLngExpression } from "leaflet";
import { Marker, Popup, useMap } from "react-leaflet";

import L from "leaflet";
import HomeAddressIcon from "../../../../../../assets/driver_overview/home-marker.svg";
import WorkplaceAddressIcon from "../../../../../../assets/driver_overview/workplace-marker.svg";
import ChargerLocationIcon from "../../../../../../assets/driver_overview/charger-marker.svg";
import { useTooltipPositioner } from "./useTooltipPositioner";
import { useTranslation } from "react-i18next";
import { PublicLocation } from "../../../../../../@types";
import { Location } from "../../../../../../@types/settings";

export function PublicChargersMarkers({
  cp,
  centerPoint,
  currentBounds,
}: {
  cp: PublicLocation;
  centerPoint?: LatLngExpression;
  currentBounds: {
    southWest: {
      lat: number;
      lon: number;
    };
    northEast: {
      lat: number;
      lon: number;
    };
  };
}) {
  const { t } = useTranslation("driverOverview");

  const { arrow, verticalOffset, horizontalOffset } = useTooltipPositioner(
    cp,
    centerPoint,
    currentBounds
  );

  const chargerMarker = L.icon({
    iconUrl: ChargerLocationIcon,
    iconSize: [40, 40],
    iconAnchor: [18, 40],
  });

  return (
    <Marker
      autoPan={false}
      eventHandlers={{
        mouseover: (event) => event.target.openPopup(),
        mouseout: (event) => event.target.closePopup(),
        mousedown: (event) => event.originalEvent.preventDefault(),
      }}
      position={[cp.lat, cp.lon]}
      icon={chargerMarker}
    >
      <Popup
        autoPan={false}
        keepInView={true}
        className={`${verticalOffset} ${horizontalOffset} mb-12 z-50`}
      >
        <div className=" text-white flex flex-col w-full bg-Grey-dark relative">
          <div className=" underline mb-3">{cp.name}</div>
          <div className="mb-1">
            {t("public-charging.map.evse-count")}: {cp.countOfEvses}
          </div>
          <div className="mb-1">
            {t("public-charging.map.power-type")}: {cp.powerType.join(" & ")}
          </div>
          <div className="mb-2">
            {t("public-charging.map.charging-speed")}:{" "}
            {cp.minimumPower === cp.maximumPower
              ? cp.maximumPower
              : `${cp.minimumPower} - ${cp.maximumPower}`}{" "}
            <span className="font-bold">kW</span>
          </div>
          <div className="text-[0.7em] max-w-1/2">
            {t("public-charging.map.info")}
          </div>
          {arrow}
        </div>
      </Popup>
    </Marker>
  );
}

export function CentralMarker({
  selectedAddress,
}: {
  selectedAddress: Location;
}) {
  const map = useMap();
  const homeMarker = L.icon({
    iconUrl: HomeAddressIcon,
    iconSize: [40, 40],
    iconAnchor: [18, 40],
  });

  const workplaceMarker = L.icon({
    iconUrl: WorkplaceAddressIcon,
    iconSize: [40, 40],
    iconAnchor: [18, 40],
  });
  return (
    <Marker
      autoPan={false}
      eventHandlers={{
        mouseover: (event) => event.target.openPopup(),
        mouseout: (event) => event.target.closePopup(),
      }}
      position={[selectedAddress.lat, selectedAddress.lon]}
      icon={
        selectedAddress.locationType === "HOME" ? homeMarker : workplaceMarker
      }
    >
      <Popup className="mb-14 ">
        <div className="relative text-white">
          <div className="underline mb-3">{selectedAddress.name}</div>
          {selectedAddress.power ? (
            <div>
              Charging speed {selectedAddress.power}{" "}
              <span className="font-bold">kW</span>
            </div>
          ) : (
            <div>No Charging Possibility</div>
          )}
          <div className="absolute top-[130%] right-[45%] w-0 h-0 border-l-[5px] border-l-transparent border-t-[10px] border-Grey-dark border-r-[5px] border-r-transparent"></div>
        </div>
      </Popup>
    </Marker>
  );
}
