import { useState, createRef, useEffect, useRef, ReactElement } from 'react'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Input from '../Input'
import useOutsideAlerter from '../../../../hooks/utils/useOutsideClickAlerter'
import { Spinner } from '../../../../electrify_frontend_common/components/Spinner'

export default function Select({
  handleChange,
  selectedItem,
  placeholder,
  data,
  className,
  label,
  disabled,
  textStyle,
  allowSearch,
  loading,
  Validationerror,
  buttonClassName,
  error
}: any) {

  const [active, setActive] = useState(false)
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, () => {
    setActive(false)
  })
  const [search, setSearch] = useState('')
  const inputRef = createRef<any>()

  useEffect(() => {
    if (active) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus()
      }
    }
  }, [active])

  return (
    <>
      <div
        ref={wrapperRef}
        className={
          'flex flex-col h-auto text-h5 selectSingle justify-center' + className
        }
        style={{
          zIndex: '1000',
        }}
      >
        {label && <label className='text-h5 pl-2'>{label}</label>}
        <div className={`relative max-h-15 border  rounded ${error ? 'border-danger-500' : 'border-secondary-200'} ${buttonClassName || ""}`}>
          <button
            className={
              ' w-full focus:outline-none trans flex items-center  justify-between space-x-2 px-3 p-2 selectSingle ' +
              (!disabled ? 'cursor-pointer' : 'cursor-default')
            }
            disabled={disabled}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setActive(!active)
            }}
          >
            <div
              className={
                ` truncate selectSingle text-Grey-dark ${textStyle || ''}` +
                (!disabled ? ' cursor-pointer' : ' cursor-default')
              }
            >
              {(selectedItem && selectedItem.key) || placeholder}
            </div>
            <div
              className={
                ' inline-flex items-center px-3 max-h-15 selectSingle ' +
                (!disabled ? 'cursor-pointer' : 'cursor-default')
              }
            >
              {!disabled && (
                <FontAwesomeIcon
                  className='pointer-events-none'
                  icon={active ? faAngleUp : faAngleDown}
                />
              )}
            </div>
          </button>
          <ul
            className={
              ' absolute max-h-64 w-full top-16 flex flex-col shadow trans rounded origin-bottom-end z-40 bg-white selectSingle overflow-auto  ' +
              (active
                ? 'opacity-100 scale-100 visible'
                : 'opacity-0 scale-75 hidden')
            }
          >
            {allowSearch && (
              <li className='px-3 py-2 selectSingleSearch'>
                <Input
                  ref={inputRef}
                  value={search}
                  onChange={(e) => setSearch(e.currentTarget.value)}
                  placeholder='Search'
                />
              </li>
            )}
            {loading ? (
              <div className='w-full flex relative py-6'>
                <Spinner />
              </div>
            ) : (
              data?.filter((item: any) => {
                  if (allowSearch && search) {
                    const keywords = search.split(' ')
                    for (let i = 0; i < keywords.length; i++) {
                      const keyword = keywords[i]
                      if (
                        !item.key
                          .toString()
                          .toLowerCase()
                          .includes(keyword?.toLowerCase())
                      )
                        return false
                    }
                  }
                  return true
                })
                .map((item: any, i: number) => {
                  const selected =
                    (selectedItem && selectedItem.value) === item.value
                  return (
                    <Item
                      key={i}
                      onClick={() => {
                        if (!selected) {
                          handleChange(item)
                          setActive(false);
                        } else {
                          handleChange(undefined)
                          setActive(false)
                        }
                      }}
                      selected={selected}
                    >
                      {item.key}
                    </Item>
                  )
                })
            )}
          </ul>
        </div>
        {Validationerror && Validationerror[0].path === 'UnitID' && (
          <Alert severity='error'>
            <AlertTitle>Error</AlertTitle>
            {Validationerror[0].message}
          </Alert>
        )}
      </div>
    </>
  )
}

function Item({ selected, onClick, children }: { selected: boolean, onClick: () => void, children: ReactElement }) {
  return (
    <li
      onClick={onClick}
      className={
        ' text-h5 cursor-pointer trans px-3 py-2 hover:bg-secondary-100 selectSingle ' +
        (selected && 'opacity-50 bg-secondary-50')
      }

    >
      {children}
    </li>
  )
}
