import { useMemo, useRef } from "react";


export type TooltipPlacements = "left-top" |
    "left-bottom" |
    "right-top" |
    "right-bottom" |
    "top-start" |
    "bottom-end" |
    "bottom-start" |
    "top-end";

const ARROW_SIZE = 6;


const LeftArrow = ({
    offsets,
}: {
    offsets: { top?: number, bottom?: number };
}) => {
    return (
        <div
            style={{
                left: -ARROW_SIZE,
                ...offsets
            }}
            className="absolute w-0 h-0 
    border-t-[6px] border-t-transparent
    border-r-[9px] border-Grey-dark
    border-b-[6px] border-b-transparent"
        ></div>
    );
};

const RightArrow = ({
    offsets,
}: {
    offsets: { top?: number, bottom?: number };
}) => {
    return (
        <div
            style={{
                right: -ARROW_SIZE,
                ...offsets
            }}
            className="absolute w-0 h-0 
    border-t-[6px] border-t-transparent
    border-l-[9px] border-Grey-dark
    border-b-[6px] border-b-transparent"
        ></div>
    );
};

const TopArrow = ({
    offsets,
}: {
    offsets: { left?: number, right?: number };
}) => {
    return (
        <div
            style={{
                top: -ARROW_SIZE,
                ...offsets
            }}
            className="absolute w-0 h-0 
	border-l-[6px] border-l-transparent
	border-b-[9px] border-Grey-dark
	border-r-[6px] border-r-transparent"
        ></div>
    );
}

const BottomArrow = ({
    offsets,
}: {
    offsets: { left?: number, right?: number };
}) => {
    return (
        <div
            style={{
                bottom: -ARROW_SIZE,
                ...offsets
            }}
            className="absolute w-0 h-0 
	border-l-[6px] border-l-transparent
	border-t-[9px] border-Grey-dark
	border-r-[6px] border-r-transparent"
        ></div>
    );
}


export function useTooltipPlacement({ childElement, placement }: { childElement: Element | null, placement: TooltipPlacements }) {

    const TOOLTIP_MARGIN = ARROW_SIZE + 4;
    const tooltipRef = useRef<any>(null);

    const tooltipPlacingStyle = useMemo(() => {
        const childElementRect = childElement?.getBoundingClientRect();
        const tooltipElementRect = tooltipRef?.current?.getBoundingClientRect();
        
        const tooltipWidth = tooltipElementRect?.width;
        const tooltipHeight = tooltipElementRect?.height;

        const topTopOffset = (childElementRect?.top || 0)  - (tooltipHeight || 0) - TOOLTIP_MARGIN;
        const bottomBottomOffset = (childElementRect?.top || 0) + (childElementRect?.height || 0) + TOOLTIP_MARGIN;
        
        const endOffset= (childElementRect?.left || 0) - (tooltipWidth - (childElementRect?.width || 0)) + ARROW_SIZE;
        const startOffset = (childElementRect?.left || 0) - ARROW_SIZE;

        const sideTopOffset = (childElementRect?.top || 0) - (tooltipHeight - (childElementRect?.height || 0)) + ARROW_SIZE;
        const sideBottomOffset = (childElementRect?.top || 0) - ARROW_SIZE;
        const rightHorizontalOffset = (childElementRect?.left || 0) + (childElementRect?.width || 0) + TOOLTIP_MARGIN;

        const leftHorizontalOffset = (childElementRect?.left || 0) - (tooltipElementRect?.width || 0) - TOOLTIP_MARGIN;

        switch (placement) {
            case "left-bottom":
                return {
                    left: leftHorizontalOffset,
                    top: sideBottomOffset,
                };
            case "left-top":
                return {
                    left: leftHorizontalOffset,
                    top: sideTopOffset,
                }
            case "right-bottom":
                return {
                    left: rightHorizontalOffset,
                    top: sideBottomOffset,
                };
            case "right-top":
                return {
                    left: rightHorizontalOffset,
                    top: sideTopOffset,
                }
            case "top-start":
                return {
                    left: startOffset,
                    top: topTopOffset,
                }
            case "top-end":
                return {
                    left: endOffset,
                    top: topTopOffset,
                }
            case "bottom-start":
                return {
                    left: startOffset,
                    top: bottomBottomOffset,
                }
            case "bottom-end":
                return {
                    left: endOffset,
                    top: bottomBottomOffset,
                }

        }

    }, [placement, childElement?.getBoundingClientRect()])



    const arrowFromPlacement = useMemo(() => {
        if (!childElement) return;
        switch (placement) {
            case "left-bottom":
                return <RightArrow offsets={{ top: childElement!.clientHeight / 2 }} />
            case "left-top":
                return <RightArrow offsets={{ bottom: childElement!.clientHeight / 2 }} />
            case "right-bottom":
                return <LeftArrow offsets={{ top: childElement!.clientHeight / 2 }} />
            case "right-top":
                return <LeftArrow offsets={{ bottom: childElement!.clientHeight / 2  }} />
            case "top-start":
                return <BottomArrow offsets={{ left: childElement!.clientWidth / 2 }} />
            case "top-end":
                return <BottomArrow offsets={{ right: (childElement!.clientWidth) / 2  }} />
            case "bottom-start":
                return <TopArrow offsets={{ left: childElement!.clientWidth / 2 }} />
            case "bottom-end":
                return <TopArrow offsets={{ right: (childElement!.clientWidth) / 2 + ARROW_SIZE / 2 }} />
        }

    }, [placement, tooltipPlacingStyle,])

    return {
        tooltipPlacingStyle,
        tooltipRef,
        arrowFromPlacement
    }


}