import { useCallback, useMemo } from "react";
import { LocationTypes, Scenario, TripEndSoC, VehicleConsumptionsCostsAndEmissions } from "../../../../../../../@types/driver/simulations";

export function useScenarioLocalCompute({
    scenarioData,
    consumptionCostsAndEmissions,
}: {
    scenarioData?: Scenario;
    consumptionCostsAndEmissions: VehicleConsumptionsCostsAndEmissions;
}) {
    const reduceNumericValuesFromObject = useCallback(
        (obj?: LocationTypes | TripEndSoC | number): number => {
            if (!obj) return 0;
            if (typeof obj === "number") return obj;
            return Object.entries(obj)
                .map(([k, v]) => v)
                .filter((v) => typeof v === "number")
                .reduce((acc, curr) => acc + curr, 0);
        },
        []
    );

    const comparisonMetrics = useMemo(() => {
        if (!scenarioData || !consumptionCostsAndEmissions) return null;
        return {
            energyCostsComparison: Math.round(
                ((reduceNumericValuesFromObject(scenarioData?.energyCosts) -
                    consumptionCostsAndEmissions.yearlyFuelAndEnergyCosts) /
                    consumptionCostsAndEmissions.yearlyFuelAndEnergyCosts) *
                    100
            ),
            co2EmissionsComparison: Math.round(
                ((reduceNumericValuesFromObject(
                    scenarioData?.energyCo2Emissions
                ) -
                    consumptionCostsAndEmissions.yearlyCo2Emission) /
                    consumptionCostsAndEmissions.yearlyCo2Emission) *
                    100
            ),
        };
    }, [consumptionCostsAndEmissions, scenarioData]);

    return {
        comparisonMetrics,
        reduceNumericValuesFromObject,
    };
}
