import { SuitabilityType } from "../../../../../../enums"

const ranges : {[k in SuitabilityType] : {min?: number, max: number}} = {
    [SuitabilityType.NO_RESTRICTIONS]: {
        max: 100
    },
    [SuitabilityType.VERY_COMFORTABLE]: {
        min: 90,
        max: 99
    },
    [SuitabilityType.FAIRLY_COMFORTABLE]: {
        min: 75,
        max: 89
    },
    [SuitabilityType.ADAPTATIONS_NEEDED]: {
        min: 51,
        max: 75
    }
}

export function SuitabilityRange({ type, color }: { type: SuitabilityType, color: string }) {

  
    const renderRanges = () => {

        const width = (t: SuitabilityType) => {
            return `${ranges[t].max - (ranges[t].min || 99)}%`
        }

        return (
            <>
                <div className={`absolute flex w-full border-Grey-default h-[8px] ${type=== SuitabilityType.NO_RESTRICTIONS ? color : ''}`}
                    style={{
                        width: width(SuitabilityType.NO_RESTRICTIONS),
                        position: 'absolute',
                        right: `${100 - ranges[SuitabilityType.NO_RESTRICTIONS].max}%`
                    }}>
                </div>
                <div className={`absolute flex w-full border-r border-Grey-default h-[8px] ${type=== SuitabilityType.VERY_COMFORTABLE ? color : ''}`}
                    style={{
                        width: width(SuitabilityType.VERY_COMFORTABLE),
                        position: 'absolute',
                        right: `${100 - ranges[SuitabilityType.VERY_COMFORTABLE].max}%`
                    }}>
                </div>
                <div className={`absolute flex w-full border-r border-Grey-default h-[8px] ${type=== SuitabilityType.FAIRLY_COMFORTABLE ? color : ''}`}
                    style={{
                        width: width(SuitabilityType.FAIRLY_COMFORTABLE),
                        position: 'absolute',
                        right: `${100 - ranges[SuitabilityType.FAIRLY_COMFORTABLE].max}%`
                    }}>
                </div>
                <div className={`absolute flex w-full border-x border-Grey-default h-[8px] ${type=== SuitabilityType.ADAPTATIONS_NEEDED ? color : ''}`}
                    style={{
                        width: width(SuitabilityType.ADAPTATIONS_NEEDED),
                        position: 'absolute',
                        right: `${100 - ranges[SuitabilityType.ADAPTATIONS_NEEDED].max}%`
                    }}>
                </div>

            </>
        )

    }




    return (
        <div className="flex flex-col w-full items-center">
            <div className="text-Grey-dark text-sm mt-2">
                EV  Score = {ranges[type].min ? `${ranges[type].min} - ${ranges[type].max}%` : `${ranges[type].max}%`}
            </div>
            <div className="flex w-full items-center justify-between text-Grey-default text-xs">
                <div className="mr-1">0%</div>
                <div className="w-full h-[10px] bg-opacity-0 border border-Grey-default relative">
                    {renderRanges()}
                </div>
                <div className="ml-1">100%</div>
            </div>
        </div>
    )



}