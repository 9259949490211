import { ReactElement } from "react";
import { PlannerStep } from "../components/Step";

export type ElectrifyPlannerStep = {
    name: string;
    status: ElectrificationPlannerStepStatus;
    data?: string;
    taskExpirationState?: ElectrificationPlannertaskExpirationState;
    sectionName: ElectrificationPlannerSections,
    subsectionName: ElectrificationPlannerSubSections,
    stepName: string
    notApplicableBy?: {
        sectionName: ElectrificationPlannerSections,
        subsectionName: ElectrificationPlannerSubSections,
        stepName: string
    },
    children?: ReactElement;
    modal: (close: () => void, refetch: () => void) => ReactElement,
    onWaitForThirdParty?: boolean

}

export type Subsection = Map<string, ElectrifyPlannerStep>;

export enum ElectrificationPlannertaskExpirationState {
    OVERDUE_WARNING = "overdueWarning",
    OVERDUE = "expired",
}

export enum ElectrificationPlannerStepStatus {
    DONE = "done", // 2
    TODO = "todo", // 0
    PARTIALLY_DONE_1 = "partiallyDone1", // 11
    PARTIALLY_DONE_2 = "partiallyDone2", // 12
    PARTIALLY_DONE_3 = "partiallyDone3", // 13
    NOT_APPLICABLE = "notApplicable", // -1
    REFINED = "refined",
}

export enum ElectrificationPlannerStepType {
    ACTION = 'action',
    INFO = 'info',
}
export enum ElectrificationPlannerSections {
    VEHICLE_SECTION = 'vehicleSection',
    CHARGING_SECTION = 'chargingSection',
    ADDITIONAL_SERVICES_SECTION = 'additionalServicesSection',
}

export type ElectrificationPlannerSubSections = ElectrificationPlannerVehicleSectionSubSections |
    ElectrificationPlannerChargingSectionSubSections |
    ElectrificationPlannerAdditionalServicesSectionSubSections;

export enum ElectrificationPlannerVehicleSectionSubSections {
    PREFERENCES_SUB_SECTION = 'preferences',
    PURCHASE_SUB_SECTION = 'purchase',
    ACCESSORIES_SUB_SECTION = 'accessories',
}

export enum ElectrificationPlannerChargingSectionSubSections {
    HOME_CHARGING = 'homeCharging',
    WALLBOX = 'wallbox',
    ENERGY = 'energy',
    WORKPLACE_CHARGING = 'workplaceCharging',
    PUBLIC_CHARGING = 'publicCharging',
}

export enum ElectrificationPlannerAdditionalServicesSectionSubSections {
    ADMINISTRATION = 'administration',
    VEHICLE_DATA = 'vehicleData',
    SEARCH_AND_NAVIGATION = 'searchAndNavigation',
    FINANCIAL_REWARDS = 'financialRewards',
}
// TODO try to propagate types into subsection to constraint accepted step keys based on subsection key
export abstract class Section<T extends ElectrificationPlannerSubSections> {

    name: ElectrificationPlannerSections;
    subsections: Map<T, Subsection>;

    constructor(name: ElectrificationPlannerSections, subsections: Map<T, Subsection>) {
        this.name = name;
        this.subsections = subsections;
    }

    protected static bootstrapDate(date?: string): string {
        return date || (new Date(Date.now()).toISOString().split('T')[0]);
    }

    public renderSubsection(subsectionKey: T) {
        return (
            <div>
                {Array.from((this.subsections.get(subsectionKey) as Subsection).entries()).map(([key, value]) => {
                    return (
                        <PlannerStep
                            key={key}
                            name={value.name}
                            taskExpirationState={value.taskExpirationState}
                            status={value.status}
                            modal={value.modal}
                            sectionName={this.name}
                            subsectionName={subsectionKey}
                            stepName={key}
                            onWaitForThirdParty={value.onWaitForThirdParty}
                        >
                            {value?.children}
                        </PlannerStep>
                    )
                })}
            </div>
        )

    }

}