import { useMemo } from "react";
import { getImageServerWithFallback, replaceImgWithError } from "../../utils";
import { CarIconSelector } from "../CarIconSelector";
import { AlternativeEletricVehicleDetails } from "../../types";



export function VehicleImageWithFallback({
    vehicle,
    onClick,
    className,
    size = "medium"
}: {
    vehicle?: Partial<AlternativeEletricVehicleDetails>
    onClick?: (e: any) => void,
    className: string,
    size?: 'small' | 'medium' | 'large'
}) {

    const imageUrl = useMemo(()=> {
        return `${getImageServerWithFallback()}/images/vehicle/ev/${size}?evId=${vehicle?.evId}`;
    },[size, vehicle?.evId])


    if (vehicle && !vehicle?.evId) return null;

    const replaceImgWithError = (e: any) => {
        e.target.onerror = null;
        e.target.src = CarIconSelector(
            vehicle?.bodyStyle,
            vehicle?.category
        );
        e.target.className = "object-contain";
    };


    return (
        <img
            onClick={onClick}
            src={imageUrl}
            loading="lazy"
            onError={replaceImgWithError}
            alt="recomended-electric-vehicle"
            className={`object-contain ${className} ${onClick ? 'cursor-pointer' : ''}`}
        />
    )
}