

export function ListPrice({color = "black"} : {color?: string}) {


    return (
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.2707 36.7083C17.993 36.9861 17.6457 37.125 17.2291 37.125C16.8124 37.125 16.4652 36.9861 16.1874 36.7083L1.29157 21.8125C1.01379 21.5347 0.883585 21.1788 0.900946 20.7448C0.918307 20.3108 1.08324 19.9375 1.39574 19.625L19.7291 1.29167C19.8688 1.16434 20.0339 1.06307 20.2245 0.987864C20.415 0.912621 20.5971 0.875 20.7707 0.875H35.6666C36.0485 0.875 36.3871 1.02257 36.6822 1.31771C36.9773 1.61285 37.1249 1.95139 37.1249 2.33333V17.2292C37.1249 17.4427 37.0815 17.6436 36.9947 17.832C36.9079 18.0204 36.7777 18.2014 36.6041 18.375L18.2707 36.7083ZM30.4521 9.3125C30.9423 9.3125 31.361 9.14094 31.7082 8.79781C32.0555 8.45465 32.2291 8.03799 32.2291 7.54781C32.2291 7.0576 32.0575 6.63889 31.7144 6.29167C31.3712 5.94445 30.9546 5.77083 30.4644 5.77083C29.9742 5.77083 29.5555 5.9424 29.2082 6.28552C28.861 6.62868 28.6874 7.04535 28.6874 7.53552C28.6874 8.02573 28.859 8.44445 29.2021 8.79167C29.5453 9.13889 29.9619 9.3125 30.4521 9.3125ZM17.2524 35.6667L35.6666 17.2292V2.33333H20.7474L2.33324 20.7708L17.2524 35.6667Z" fill={color} />
        </svg>
    )
}


