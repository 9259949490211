import { createContext } from 'react';
import { Tariff } from '../@types/paymentProcess';
import { UseMutateFunction } from 'react-query';

export const PaymentContext = createContext<{
	selectedTariff: Tariff | undefined;
	setSelectedTariff: any;
	clientSecret: string | null;
	setClientSecret: any;
	loadingPaymentIntent: boolean;
	setLoadingPaymentIntent: any;
	tariffs: Tariff[] | undefined;
	noMatchBetweenPreselectedProductAndValidTarifs: boolean;
	confirmPayment:
		| UseMutateFunction<
				{ subscriptionId: string; clientSecret: string } | undefined,
				unknown,
				number | undefined,
				unknown
		  >
		| (() => void);
}>({
	selectedTariff: undefined,
	setSelectedTariff: null,
	clientSecret: null,
	setClientSecret: null,
	loadingPaymentIntent: false,
	setLoadingPaymentIntent: null,
	tariffs: undefined,
	confirmPayment: () => null,
	noMatchBetweenPreselectedProductAndValidTarifs: false,
});
