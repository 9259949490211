import { Route, Routes } from "react-router-dom";
import LayoutWrapper from "../../../common/components/LayoutWrapper";
import { ElectrificationPlannerRoutes } from "./routes";
import NavbarItem from "../../../common/components/LayoutWrapper/Navbar/NavbarItem/NavbarItem";
import { ElectrificationPlannerIcon } from "../../../electrify_frontend_common/icons/ElectrificationPlannerIcon";
import { NavbarSubItem } from "../../../common/components/LayoutWrapper/Navbar/NavbarItem/NavbarSubItem";
import { useNavbar } from "../../../common/components/LayoutWrapper/Navbar/useNavbar";
import { useUserData } from "../../../hooks/state/useUserData";
import { VehicleIcon } from "../../../electrify_frontend_common/icons/VehicleIcon";
import { useTranslation } from "react-i18next";
import { LightningIcon } from "../../../electrify_frontend_common/icons/LightningIcon";
import { useContext } from "react";
import { B2CProductTreeContext } from "../contexts/B2CProductTreeContext";
import { Spinner, SpinnerSize } from "../../../electrify_frontend_common/components/Spinner";



function FetchingUserData() {


    return (
        <div className="flex flex-col flex-1 h-full w-full p-8">

            <div className="flex flex-col flex-1 h-full w-full items-center justify-center">
                <Spinner size={SpinnerSize.LARGE} />
                <div className="text-Blueberry-dark-default text-xl">Fetching account information...</div>
            </div>
        </div >
    )

}


function ElectrificationPlannerNavbar() {

    const { user } = useUserData();
    const { t } = useTranslation("driverNavbar");


    const {
        mainPathName,
        subPathName
    } = useNavbar();


    return (
        <>
            <NavbarItem
                idx={1}
                disabled={false}
                to={"/electrify-teaser"}
                title={t("electrify")}
                activeCondition={mainPathName === "electrify-teaser"}
                Icon={LightningIcon}
            />
            <NavbarItem
                idx={2}
                disabled={false}
                to={"/"}
                title={t("electrification-planner")}
                activeCondition={mainPathName === ""}
                Icon={ElectrificationPlannerIcon}
                subMenus={[
                    <NavbarSubItem
                        disabled={false}
                        to={``}
                        title={t("checklist")}
                        activeCondition={
                            mainPathName === "" && !subPathName
                        }
                    />,
                    <NavbarSubItem
                        disabled={true}
                        to={`/next-tasks`}
                        title={t("next-tasks")}
                        activeCondition={
                            mainPathName === "electrification-planner" && subPathName === "next-tasks"
                        }
                    />
                ]
                }
            />
            <NavbarItem
                idx={3}
                disabled={false}
                to={`/ev-finder`}
                title={t("vehicle-selection")}
                activeCondition={mainPathName === "ev-finder"}
                Icon={VehicleIcon}
            />

        </>
    )

}


export function ElectrificationPlannerDriver() {

    const { user } = useUserData();
    const { isUserSubscriptionInfoLoading, productFamily } = useContext(B2CProductTreeContext);


    const displayProduct = !!user?.accountInfo && !isUserSubscriptionInfoLoading && productFamily;


    return (
        <LayoutWrapper navbar={<>{<ElectrificationPlannerNavbar />}</>} >
            <>{displayProduct ?
                <Routes>
                    {ElectrificationPlannerRoutes?.map((dr) => <Route key={dr.path} path={dr.path} element={<dr.component />} />)}
                </Routes> : <FetchingUserData />
            }</>
        </LayoutWrapper>
    )
}