import { gql } from "@apollo/client";


export const GET_VEHICLE_SETTINGS = gql`
  query GetVehicleSettings($vehicleId: ID!) {
    vehicleSettings (id: $vehicleId) {
        simulation {
        filters {
          latest {
            listPrice {
              max
            }
          }
        }
      }
    }
  }
`;