import { Dispatch, SetStateAction, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { IuseForm } from "../../../../hooks/utils/useForm";
import { VehicleInput } from "../../../../@types/vehicle";


export function MatchedVehiclesTable({
  vehicles,
  manualInputForm,
  initialModelId,
  setInitialModelId,
}: {
  vehicles: any[],
  manualInputForm: IuseForm<VehicleInput>,
  initialModelId?: number | null,
  setInitialModelId: Dispatch<SetStateAction<number | null | undefined>>
}) {

  const { t } = useTranslation("editVehicle");

  useEffect(() => {
    manualInputForm.updateFormState({
      ...manualInputForm.formState,
      modelInfo: {
        ...manualInputForm?.formState?.modelInfo,
        modelId: initialModelId
      }
    });
    setInitialModelId(null);
  }, []);

  useEffect(() => {
    if (vehicles.length === 1) {
      selectVehicleModel(vehicles[0]["ID"]);
    }
  }, [vehicles]);

  const selectVehicleModel = (modelId: number) => {
    manualInputForm.updateFormState({
      ...manualInputForm.formState,
      modelInfo: {
        ...manualInputForm?.formState?.modelInfo,
        modelId: modelId
      }
    });
  };

  const isVehicleSelected = (modelId: number) => {
    return manualInputForm.formState?.modelInfo?.modelId === modelId;
  };

  return (
    <>
      {vehicles.length > 1 ? <div className="mb-10 text-Grey-dark w-1/2 text-sm pl-2">
        {t("manual.multiple-vehicles-message")}
      </div> : null}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell><div className="text-Grey-dark font-bold">{t("manual.table.col-1")}</div></TableCell>
            <TableCell><div className="text-Grey-dark font-bold">{t("manual.table.col-2")}</div></TableCell>
            <TableCell><div className="text-Grey-dark font-bold">{t("manual.table.col-3")}</div></TableCell>
            <TableCell><div className="text-Grey-dark font-bold">{t("manual.table.col-4")}</div></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {vehicles.map((v: any) => (
            <TableRow
              key={v["ID"]}
              className={`hover:bg-Grey-background cursor-pointer ${isVehicleSelected(v["ID"]) ? "bg-Grey-background " : ""
                }`}
              onClick={() => selectVehicleModel(v["ID"])}
            >
              <TableCell><div className={`py-1 ${isVehicleSelected(v["ID"]) ? "text-Blueberry-dark-default" : ""}`}>{v["Engine subversion"]}</div></TableCell>
              <TableCell><div className={isVehicleSelected(v["ID"]) ? "text-Blueberry-dark-default" : ""}>{v["Production period"]}</div></TableCell>
              <TableCell><div className={isVehicleSelected(v["ID"]) ? "text-Blueberry-dark-default" : ""}>{v["Total consumption"]}</div></TableCell>
              <TableCell><div className={isVehicleSelected(v["ID"]) ? "text-Blueberry-dark-default" : ""}>{v["Power (HP)"]}</div></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>

  );
}