import { useContext, useMemo, useState } from "react";

import { ElectrificationPlannerAdditionalServicesSectionSubSections, ElectrificationPlannerSections, ElectrificationPlannerStepStatus, ElectrificationPlannerVehicleSectionSubSections } from "../../../../models/Section";

import { UseMutationResult, useMutation } from "react-query";
import { RefinementModalContent } from "./VehicleOrderModalContent";
import { ModalForm } from "../../utils/forms";
import { IElectrificationPlanner } from "../../../../../../../../@types/driver/electrificationPlanner";
import { ElectrifyPlannerContext } from "../../../../../../contexts/ElectrificationPlannerContext";
import { updateElectrificationPlanner } from "../../../../../../../../services/rest/electrificationPlanner";
import ModalWrapper from "../../../../../../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../../../../../../electrify_frontend_common/components/ModalWrapper/Headers";
import { useTranslation } from "react-i18next";



export function InsuranceModalContent({
    submitMutation,
    formValues,
    setFormValues,
    closeModal,
    openVehicleOrderRefinement
}: {
    submitMutation: UseMutationResult<IElectrificationPlanner, unknown, any, unknown>,
    formValues: {
        answer: {
            vehicleOrder: string[]
        },
        dueDate?: string
    },
    setFormValues: (values: any) => void,
    closeModal: () => void;
    openVehicleOrderRefinement: () => void
}) {

    const { electrificationPlanner } = useContext(ElectrifyPlannerContext);


    const shouldRefineOrder = useMemo(() => {
        if (!electrificationPlanner) return;

        return !(electrificationPlanner[ElectrificationPlannerSections.VEHICLE_SECTION]?.[ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION]?.vehicleOrder.status === ElectrificationPlannerStepStatus.DONE
            && electrificationPlanner[ElectrificationPlannerSections.VEHICLE_SECTION]?.[ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION]?.ownershipType.status === ElectrificationPlannerStepStatus.DONE
            && electrificationPlanner[ElectrificationPlannerSections.VEHICLE_SECTION]?.[ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION]?.financingType.status === ElectrificationPlannerStepStatus.DONE
            && electrificationPlanner[ElectrificationPlannerSections.VEHICLE_SECTION]?.[ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION]?.vehicleAge.status === ElectrificationPlannerStepStatus.DONE)
    }, [electrificationPlanner])


    return (
        <div className="p-6">
            <ModalForm
                closeModal={closeModal}
                stepName="carInsurance"
                section={ElectrificationPlannerSections.ADDITIONAL_SERVICES_SECTION}
                subsection={ElectrificationPlannerAdditionalServicesSectionSubSections.ADMINISTRATION}
                alternativeAction={(values) => {
                    if (shouldRefineOrder) {
                        setFormValues({
                            ...formValues,
                            additionalValues: {
                                ...values
                            }
                        })
                        openVehicleOrderRefinement();
                    } else {
                        submitMutation.mutate({
                            section: ElectrificationPlannerSections.VEHICLE_SECTION,
                            subsection: ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION,
                            data: {
                                answer: formValues.answer,
                                dueDate: formValues.dueDate,
                                additionalValues: {
                                    ...values
                                }
                            }
                        });
                    }
                }}
            />
        </div>
    )

}




export function VehicleRegistrationModalContent({ closeModal }: { closeModal: () => void }) {

    const {t} = useTranslation("electrificationPlanner");

    const { refetch, electrificationPlanner } = useContext(ElectrifyPlannerContext);

    const [refinementModalOpen, setRefinementModalOpen] = useState(false);
    const [insuranceModalOpen, setInsuranceModalOpen] = useState(false);
    const [formValues, setFormValues] = useState<any>(null);

    const mutation = useMutation(async (input: any) => {
        return await updateElectrificationPlanner(input)
    }, {
        onSuccess: () => {
            refetch();
            closeModal();
        }
    })


    const shouldRefineInsurance = useMemo(() => {
        if(!electrificationPlanner) return;
        return electrificationPlanner[ElectrificationPlannerSections.VEHICLE_SECTION]?.[ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION]?.vehicleRegistration.status !== ElectrificationPlannerStepStatus.DONE
    }, [electrificationPlanner]); 

    return (
        <>

            <ModalWrapper className="w-8/12" close={() => setInsuranceModalOpen(false)} isOpen={insuranceModalOpen}>
                <>
                    <ModalHeader onCancel={() => setInsuranceModalOpen(false)} title={t("additionalServicesSection.subsections.administration.steps.carInsurance.title")} />
                    <InsuranceModalContent
                        closeModal={closeModal}
                        submitMutation={mutation}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        openVehicleOrderRefinement={() => setRefinementModalOpen(true)}
                    />
                </>
            </ModalWrapper>
            <ModalWrapper className="w-8/12" close={() => setRefinementModalOpen(false)} isOpen={refinementModalOpen}>
                <>
                    <ModalHeader onCancel={() => setRefinementModalOpen(false)} title={t("misc.refinement")} />
                    <RefinementModalContent
                        formValues={formValues}
                        submitMutation={mutation}
                    />
                </>
            </ModalWrapper>
            <ModalForm 
                closeModal={closeModal}
                section={ElectrificationPlannerSections.VEHICLE_SECTION}
                subsection={ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION}
                stepName="vehicleRegistration"
                alternativeAction={(values) => {
                    if (values.vehicleRegistration === "registeredVehicle" && shouldRefineInsurance) {
                        setFormValues({
                            answer: {
                                ...values,
                            },
                            dueDate: values.dueDate
                        });
                        setInsuranceModalOpen(true);
                        return;
                    }
                    mutation.mutate({
                        section: ElectrificationPlannerSections.VEHICLE_SECTION,
                        subsection: ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION,
                        data: {
                            answer: {
                                ...values
                            },
                            dueDate: values.dueDate,

                        }
                    });
                }}
            />
        </>
    )

}