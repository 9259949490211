import { ReactComponent as SortIcon } from "../../../assets/sort-icon.svg";
import { ReactComponent as SortIconRev } from "../../../assets/sort-icon-rev.svg";
import { useTranslation } from "react-i18next";

export default function SortButton({ sort, hideText, isCrescent } : {sort: ()=> void, hideText?: boolean, isCrescent?: boolean}) {
    
  const { t } = useTranslation("chartWrapper");

  return (
    <span
      className="mr-6 flex items-center text-xs cursor-pointer"
      onClick={sort}
    >
      {isCrescent ? (
        <>
          <SortIcon className="mr-2" />
          {!hideText && t("high-to-low")}
        </>
      ) : (
        <>
          <SortIconRev className="mr-2" />
          {!hideText && t("low-to-high")}
        </>
      )}
    </span>
  );
}
