import { useContext } from "react"
import { useTranslation } from "react-i18next";
import { ElectrifyPlannerContext } from "../../../contexts/ElectrificationPlannerContext";


export function PercentageProgressBar({percentage = 0, height = 1} : {percentage?: number, height?: number}) {

    return (
        <div className={`flex w-full h-${height} bg-Grey-light relative mt-3`}>
            <div
                className={`flex h-${height} absolute top-0 left-0 bg-Blueberry-dark-default transition-all duration-300`}
                style={{
                    width: `${percentage}%`
                }}
            ></div>
        </div>
    )

}

export function ElectrificationProgress() {

    const { t, i18n } = useTranslation("electrificationPlanner");

    const { electrificationProgress } = useContext(ElectrifyPlannerContext);

    return (
        <div>
            <div className="flex w-full justify-between items-center">
                <h1 className="text-lg font-bold">{t("yourElectrificationJourney")}</h1>
                <div className="flex items-center">
                    <span className="text-2xl text-Blueberry-dark-default"><span className="font-bold ">{electrificationProgress}</span><span className="font-light">%</span></span>
                    <span className="text-[9px] font-bold text-Grey-dark ml-2">{t("ofYourJourney").toUpperCase()}</span>
                </div>
            </div>

            <PercentageProgressBar percentage={electrificationProgress}/>
        </div>
    )



}