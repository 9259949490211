import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLayout } from "../../../../../hooks/state/useLayout";
import { BillingForm } from "../../../../../common/components/BillingForm";


export function BillingSettings() {
  const { t, i18n } = useTranslation("settingsBilling");
  const language = i18n.language;
  const { setHeader } = useLayout();

  useEffect(() => {
    setHeader({
      defaultHeaderTitle: t("page-title"),
      defaultHeaderDescription: t("page-subtitle"),
    });
  }, [language]);

  return (
    <>
      <BillingForm buttonText="Save changes" />
    </>
  );
}
