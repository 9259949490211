import { useState } from "react";
import { ReactComponent as CrossIcon } from "../../../../../../../assets/close.svg";
import { ReactComponent as EditIcon } from "../../../../../../../assets/edit-icon.svg";
import Input from "../../../../../../../common/components/misc/Input";
import { IuseForm } from "../../../../../../../hooks/utils/useForm";
import { Vehicle, VehicleInput } from "../../../../../../../@types/vehicle";




export default function EditVehicleHeader({ form, vehicle, onClose }: { form: IuseForm<VehicleInput>, vehicle?: Vehicle, onClose: () => void }) {

    const [editName, setEditName] = useState(false);

    return (
        <div className="h-auto flex justify-between w-full" style={{ boxShadow: "0px 4px 24px 0px #00000014" }}>

            <div className="p-8 pl-9">
                {editName ? <Input
                    value={form.formState.name}
                    onChange={(e) => form.updateFormState({ name: e.target?.value })}
                /> :
                    <div className="flex items-center">

                        <h1 className="text-xl text-Blueberry-dark-default">{vehicle?.name ? vehicle.name : vehicle?.originalInfo?.name}</h1>
                        <div className="cursor-pointer">
                            <EditIcon onClick={() => {
                                form.updateFormState({ name: vehicle?.name ? vehicle.name : vehicle?.originalInfo?.name })
                                setEditName(true)
                            }} className="ml-3" />
                        </div>
                    </div>}


                {vehicle?.name ? <h2 className="text-xs text-Grey-dark mt-2">{vehicle?.originalInfo?.name}</h2> : null}



            </div>
            <div className="flex items-center">
                <div className="ml-10">
                    <CrossIcon
                        className="cursor-pointer mr-8"
                        onClick={() => {
                            onClose()
                        }}
                    />
                </div>
            </div>
        </div>
    )
}