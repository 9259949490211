import { MetricsArrowDown } from "../../../../icons/MetricsArrowDown";
import { MetricsArrowUp } from "../../../../icons/MetricsArrowUp";
import { Tooltip } from "../../../Tooltip";


export function MetricWithComparison({
    icon,
    value,
    compareValue,
    unit,
    tooltipTitle,
    localeCode
}: {
    icon: any;
    value: number;
    compareValue: number;
    unit: string;
    tooltipTitle: string;
    localeCode: string;
}) {



    const comparisonInPercentage = Math.round(((value - compareValue) / compareValue) * 100);

    return (
        <div className="flex items-center justify-between">
            <Metric
                icon={icon}
                value={value}
                unit={unit}
                tooltipTitle={tooltipTitle}
                localeCode={localeCode}
            />
            <div className={`text-white flex items-center rounded px-1 pl-2 py-0.5 text-xs ml-3 font-bold ${comparisonInPercentage < 0 ? "bg-green-600" : "bg-red-500"}`}>
                <div>{comparisonInPercentage}%</div>
                {comparisonInPercentage < 0 ? <MetricsArrowDown className="ml-0.5" /> : <MetricsArrowUp className="ml-0.5" />}
            </div>
        </div>
    )

}



export function Metric({
    icon,
    value,
    unit,
    tooltipTitle,
    localeCode
}: {
    icon: any;
    value: number;
    unit: string;
    tooltipTitle: string;
    localeCode: string
}) {



    return (
        <div className="flex items-center my-1">
            <Tooltip content={<>{tooltipTitle}</>} placement="top-start">
                <>
                    {icon}
                    <div className="flex items-center ml-4">
                        <span
                            className={`text-right font-bold text-xs ml-2 `}
                        >
                            {value?.toLocaleString(localeCode) || "?"} <span className="font-normal">{unit}</span>
                        </span>
                    </div>
                </>
            </Tooltip>

        </div>
    );
}

