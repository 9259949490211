import { useLocation } from "react-router-dom";



export function useNavbar() {

    const location = useLocation();
    const mainPathName = location.pathname.split('/')[1];
    const subPathName = location.pathname.split('/')[2];


    return {
        location,
        mainPathName,
        subPathName
    }


}