import { useState } from "react";
import { UnsubscribedVehicle } from "../../@types";

export interface IuseSelectedVehicles {
  selectedVehicles: UnsubscribedVehicle[],
  addVehicles: (v: UnsubscribedVehicle[]) => void,
  removeVehicle: (v: UnsubscribedVehicle) => void,
  isVehicleSelected: (v: UnsubscribedVehicle) => boolean,
  resetSelectedVehicles: () => void,
}


export const useSelectedVehicles = (): IuseSelectedVehicles  => {
  const [selectedVehicles, updateSelectedVehicles] = useState<UnsubscribedVehicle[]>([]);

  const addVehicles = (vehicles: UnsubscribedVehicle[]) => {
    const vehiclesToAdd = [];
    for (let vehicle of vehicles) {
      if (!selectedVehicles.map((v: UnsubscribedVehicle) => v.vehicleId).includes(vehicle.vehicleId)) vehiclesToAdd.push(vehicle);
    }
    updateSelectedVehicles([...selectedVehicles, ...vehiclesToAdd]);
  };

  const removeVehicle = (vehicle: UnsubscribedVehicle) => {
    const existingVehicle = selectedVehicles.find(
      (v) => v.vehicleId === vehicle.vehicleId
    );

    if (!existingVehicle) return;

    updateSelectedVehicles(
      selectedVehicles.filter((v) => v.vehicleId !== vehicle.vehicleId)
    );
  };

  const isVehicleSelected = (vehicle: UnsubscribedVehicle) : boolean => {
    return !!selectedVehicles.find((v) => v.vehicleId === vehicle.vehicleId);
  };

  const resetSelectedVehicles = () => {
    updateSelectedVehicles([]);
  };

  return {
    selectedVehicles,
    addVehicles,
    removeVehicle,
    isVehicleSelected,
    resetSelectedVehicles,
  };
};