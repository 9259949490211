import { ElectrificationPlannerSections, ElectrificationPlannerVehicleSectionSubSections, Section } from "./Section";

import { VehicleOrderModalContent } from "../components/Sections/VehicleSection/modals/VehicleOrderModalContent";
import { VehicleRegistrationModalContent } from "../components/Sections/VehicleSection/modals/VehicleRegistrationModalContent";
import { VehicleDeliveryModalContent } from "../components/Sections/VehicleSection/modals/VehicleDeliveryModalContent";
import { VehicleSelectionModalContent } from "../components/Sections/VehicleSection/modals/VehicleSelectionModal";
import { VehicleSelectionStep } from "../components/Step/custom/VehicleSelectionStep";
import { IElectrificationPlannerVehicleSection } from "../../../../../@types/driver/electrificationPlanner";


type VehicleSectionSubsections = ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION |
    ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION |
    ElectrificationPlannerVehicleSectionSubSections.ACCESSORIES_SUB_SECTION;


export class VehicleSectionModel extends Section<VehicleSectionSubsections> {


    constructor(vehicleSection: IElectrificationPlannerVehicleSection) {

        const preferencesSubsection = new Map([
            ['ownershipType', vehicleSection[ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION].ownershipType],
            ['financingType', vehicleSection[ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION].financingType],
            ['vehicleAge', vehicleSection[ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION].vehicleAge]
        ]);

        const purchaseSubSection = new Map([
            ['vehicleModelSelection', {
                ...vehicleSection[ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION].vehicleModelSelection,
                children: <VehicleSelectionStep />,
                modal: (close: () => void) => <VehicleSelectionModalContent closeModal={close} />,
            }],
            ['vehicleOrder', {
                ...vehicleSection[ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION].vehicleOrder,
                modal: (close: () => void) => <VehicleOrderModalContent closeModal={close} />,

            }],
            ['vehicleRegistration', {
                ...vehicleSection[ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION].vehicleRegistration,
                modal: (close: () => void) => <VehicleRegistrationModalContent closeModal={close} />,
            }],
            ['vehicleDelivery', {
                ...vehicleSection[ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION].vehicleDelivery,
                modal: (close: () => void) => <VehicleDeliveryModalContent closeModal={close} />,
            }]
        ]);

        const accessoriesSubSection = new Map([
            ['winterTires', vehicleSection[ElectrificationPlannerVehicleSectionSubSections.ACCESSORIES_SUB_SECTION].winterTires],
            ['chargingCable', vehicleSection[ElectrificationPlannerVehicleSectionSubSections.ACCESSORIES_SUB_SECTION].chargingCable],
            ['mobileCharger', vehicleSection[ElectrificationPlannerVehicleSectionSubSections.ACCESSORIES_SUB_SECTION].mobileCharger],
            ['chargingCableBag', vehicleSection[ElectrificationPlannerVehicleSectionSubSections.ACCESSORIES_SUB_SECTION].chargingCableBag]
        ])

        super(
            ElectrificationPlannerSections.VEHICLE_SECTION,
            new Map([
                [ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION, preferencesSubsection],
                [ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION, purchaseSubSection],
                [ElectrificationPlannerVehicleSectionSubSections.ACCESSORIES_SUB_SECTION, accessoriesSubSection]
            ]))
    }

}
