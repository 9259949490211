import { gql } from "@apollo/client";

export const UPDATE_SELECTED_EV = gql`
  mutation UpdateSelectedEv($vehicleId: ID!, $selectedEvId: String) {
    updateSelectedEv(vehicleId: $vehicleId, selectedEvId: $selectedEvId) {
      vehicleId
      selectedEv {
        evId
        make
        model
        listPrice
        electrificationFactor
        yearlyFuelAndEnergyCosts
        yearlyCo2Emission
        isSelected
        bodyStyle
        category
        isFavorited
        notInPreference
        hasHighestScore
        hasHighestElectrificationFactor
        hasLowestFuelAndEnergyCosts
        notRecommended
      }
    }
  }
`;

export const ADD_NEW_FAVORITE_EV = gql`
  mutation AddFavoriteEv($vehicleId: ID!, $evIdToAdd: String!) {
    addFavoriteEv(vehicleId: $vehicleId, evIdToAdd: $evIdToAdd) {
      _id
      evId
      make
      model
      availabilityStatus
      category
      bodyStyle
      propulsion
      drivetrainType
      towHitchPossible
      seatsCount
      electrificationFactor
      yearlyFuelAndEnergyCosts
      yearlyCo2Emission
      listPrice
      isSelected
      isFavorited
      notInPreference
      hasHighestScore
      hasHighestElectrificationFactor
      hasLowestFuelAndEnergyCosts
      notRecommended
    }
  }
`;

export const REMOVE_FAVORITE_EV = gql`
  mutation RemoveFavoriteEv($vehicleId: ID!, $evIdToRemove: String!) {
    removeFavoriteEv(vehicleId: $vehicleId, evIdToRemove: $evIdToRemove) {
      _id
      evId
      make
      model
      availabilityStatus
      category
      bodyStyle
      propulsion
      drivetrainType
      towHitchPossible
      seatsCount
      electrificationFactor
      yearlyFuelAndEnergyCosts
      yearlyCo2Emission
      listPrice
      isSelected
      isFavorited
      notInPreference
      hasHighestScore
      hasHighestElectrificationFactor
      hasLowestFuelAndEnergyCosts
      notRecommended
    }
  }
`;

export const UPDATE_FLEET_SIMULATION_PREFERENCES = gql`
  mutation UpdateSimulationPreferences(
    $preference: SimulationPreferenceInput!
  ) {
    updateFleetSimulationPreferences(preference: $preference) {
      setTemperature
      selectedBrands
    }
  }
`;
