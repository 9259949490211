
export function BooleanOption({
    value,
    onChange,
    label,
  }: {
    value: boolean | null;
    onChange: (v: boolean) => void;
    label: string;
  }) {
    return (
      <div className="flex items-center my-2 text-xs">
        <input
          className="w-5 h-5 cursor-pointer text-Blueberry-dark-default bg-white rounded border-Blueberry-dark-default focus:ring-0 focus:outline-offset-0 focus:outline-0 focus:ring-offset-0"
          type="checkbox"
          checked={value || false}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span className="ml-2">{label}</span>
      </div>
    );
  }