import { useTranslation } from "react-i18next";
import { useLayout } from "../../../../../../../../hooks/state/useLayout";
import { ImageLayout, IncludedInProductLabel, ProductInformationLayout } from "./components";
import dongleBoxImg from "../../../../../../../../assets/driver_payment/dongle_box.webp";
import dongleAppLayoutImg from "../../../../../../../../assets/driver_payment/app_layout.webp";
import dongleImg from "../../../../../../../../assets/driver_payment/dongle.webp";
import { usePriceCalculator } from "./usePriceCalculator";
import { Voucher } from "../../../../../../../../@types/driver/payments";



export function DriverDongleProductInfo({  currencySymbol, usedVoucher, tariffPrices }: {  currencySymbol: string, usedVoucher?: Voucher, tariffPrices: {
    price: number;
    invoiceCurrency: {
        symbol: string;
    };
}[] }) {


    const { t } = useTranslation("driverPaymentSystem");

    const { localeCode } = useLayout();

   const {priceAfterDiscount} = usePriceCalculator({price : tariffPrices[0].price, voucher: usedVoucher});

    return (
        <div className="text-Grey-dark flex flex-col h-full justify-between">
            <ProductInformationLayout
                title={t("products.driverDongle.name")}
                price={priceAfterDiscount}
                description={t("products.driverDongle.description")}
                currencySymbol={currencySymbol}
                additionalInfo={<div className="flex items-center text-Grey-dark font-bold">
                    <div>{t("products.driverDongle.inStock")}</div>
                    <div className="mx-1">|</div>
                    <div>{t("products.driverDongle.nowAvailable")}</div>
                </div>}
                checkList={<>
                    <IncludedInProductLabel label={t("products.driverDongle.checklist.point1")} />
                    <IncludedInProductLabel label={t("products.driverDongle.checklist.point2")} />
                    <IncludedInProductLabel label={t("products.driverDongle.checklist.point3", {
                        price: tariffPrices[1]?.price?.toLocaleString(localeCode, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        }),
                        currencySymbol
                    })} />
                </>}
            />

            <ImageLayout imageMain={<img alt="dongle-box" src={dongleBoxImg} />} image1={<img alt="dongle-dimensions" className="w-14" src={dongleImg} />} image2={<img alt="app-layout" src={dongleAppLayoutImg} />} />

        </div>


    )


}