import { useEffect, useState } from "react";

type SortingState<T> = {[key in keyof Partial<T>] : 'asc' | 'desc' | null }

interface IuseLocalColumnFilterInput<T> {
    initialState: SortingState<T>,
    sortingSubjectValue: T[]
}



export default function useLocalColumnFilters<T>({initialState, sortingSubjectValue} : IuseLocalColumnFilterInput<T>) {

    const [sortingState, setSortingState] = useState<{[key in keyof Partial<T>] : 'asc' | 'desc' | null }>(initialState)

    const [sortingSubject, setSortingSubject] = useState<T[]>(sortingSubjectValue)


    const applySorting = (subject : T[]) => {
        const activeFilterKey : keyof T = (Object.keys(sortingState).filter((k: string) => sortingState[(k as keyof T)])[0] as keyof T);
        const activeFilterVal = sortingState[activeFilterKey] === 'asc' ? 1 : -1;
        const valToSort = [...subject];
        valToSort.sort((a : T, b : T) => {
            if(a[activeFilterKey] > b[activeFilterKey]) return activeFilterVal;
            if(a[activeFilterKey] < b[activeFilterKey]) return -activeFilterVal;
            return 0;
        });

        setSortingSubject(valToSort);
    }

  
    useEffect(()=> {

        applySorting(sortingSubject);

    },[sortingState])


    const updateSortingState = (propName : keyof T) => {
        const newSortState = !sortingState[propName] ? 'asc' : (sortingState[propName] === 'asc' ? 'desc' : 'asc');
        const updatedState : SortingState<T> = {} as SortingState<T>;
        Object.keys(sortingState).forEach((k: string)=> updatedState[(k as keyof T)] = null);
        updatedState[propName] = newSortState;
        setSortingState({...sortingState, ...updatedState})
    }

    const updateSortingSubject = (updatedSortingSubject : T[]) => {
        applySorting(updatedSortingSubject);
    }


    return {sortingState, sortingSubject, updateSortingState, updateSortingSubject}
}