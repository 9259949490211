import { LazyQueryExecFunction, OperationVariables } from "@apollo/client";
import { useEffect } from "react";
import { OptimizationType } from "./useTripSimulationPreferencesState";

export function useTripSimulationEffects({
  fetchTripsSimulationDetails,
  selectedOptimization,
  includeExtraStops,
}: {
  fetchTripsSimulationDetails: LazyQueryExecFunction<any, OperationVariables>;
  selectedOptimization: OptimizationType;
  includeExtraStops: boolean;
}) {
  useEffect(() => {
    fetchTripsSimulationDetails();
  }, [selectedOptimization, includeExtraStops]);
}
