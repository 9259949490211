import { useContext } from "react";
import { SimulationVehicleInfo } from "../../../../../../../@types";
import VehicleCard from "../../../../../../../common/components/VehicleSimulationAndSelection/VehicleCards/VehicleCard";
import { Spinner } from "../../../../../../../electrify_frontend_common/components/Spinner";
import NoEvSelectedCard from "../../../../../../../common/components/VehicleSimulationAndSelection/VehicleCards/NoEvSelectedCard";
import { DriverEVFinderContext } from "../contexts/DriverEVFinderContext";
import { DriverEvFinderSelectedVehicleCard } from "./DriverEvFinderVehicleCards/DriverEvFinderSelectedVehicleCard";




export default function SelectedVehicle({
  vehicle,
  onClickAlternatives,
}: {
  vehicle: SimulationVehicleInfo;
  onClickAlternatives: () => void;
}) {

  const {
    selectedEvLoading,
    selectedAlternativeElectricVehicle,
  } = useContext(DriverEVFinderContext)

  return (
    <>
      <div className="grid grid-cols-9 mb-10">
        <div className="col-span-4">
          <VehicleCard vehicle={vehicle} />
        </div>
        <div className="col-span-5">

          {selectedEvLoading || !vehicle.simulationIsReady ? (
            <Spinner />
          ) : selectedAlternativeElectricVehicle ? (
            <DriverEvFinderSelectedVehicleCard
                    userVehicle={vehicle}
                    alternativeVehicle={selectedAlternativeElectricVehicle}
                    onClick={()=> onClickAlternatives()}
                />
          ) : (
            <NoEvSelectedCard
              onClickAlternatives={() => {
                if (onClickAlternatives) {
                  onClickAlternatives();
                  return;
                }
              }}
              vehicle={vehicle}
            />
          )}

        </div>
      </div>
    </>
  );
}
