import Calculator from "./utils/Calculator";
import { Disclaimer } from "./utils/Disclaimer";
import { ReactComponent as SortIcon } from "../../../../assets/sort-icon.svg";
import { ReactComponent as SortIconRev } from "../../../../assets/sort-icon-rev.svg";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UnsubscribedVehicle } from "../../../../@types";
import { Tariff } from "../../../../@types/paymentProcess";
import { IuseSelectedVehicles } from "../../../../hooks/utils/useSelectedVehicles";
import useLocalColumnFilters from "../../../../hooks/utils/useLocalColumnFilters";
import { useLayout } from "../../../../hooks/state/useLayout";
import { Spinner } from "../../../../electrify_frontend_common/components/Spinner";

function getSortIcon(sortingState: any, key: string, onClick: () => void) {
  const className = "h-full ml-2";
  return sortingState[key] ? (
    sortingState[key] === "asc" ? (
      <SortIcon className={className} onClick={onClick} />
    ) : (
      <SortIconRev className={className} onClick={onClick} />
    )
  ) : (
    <SortIconRev className={className} onClick={onClick} />
  );
}

function VehiclePickerCard({
  unsubscribedVehicles,
  vehiclesHook,
  tariff,
  unsubscribedVehiclesLoading,
  tariffLoading,
  defaultSelectedVehicles,
}: {
  unsubscribedVehicles: UnsubscribedVehicle[];
  vehiclesHook: IuseSelectedVehicles;
  tariff: Tariff;
  unsubscribedVehiclesLoading: boolean;
  tariffLoading: boolean;
  defaultSelectedVehicles: UnsubscribedVehicle[];
}) {
  const { t } = useTranslation("paymentProcess");
  const { localeCode } = useLayout();

  const {
    sortingState,
    sortingSubject: sortedVehicles,
    updateSortingState: sortVehicleByProp,
    updateSortingSubject,
  } = useLocalColumnFilters<UnsubscribedVehicle>({
    initialState: {
      vehicleName: "asc",
      countOfTrips: null,
      totalDistance: null,
    },
    sortingSubjectValue: unsubscribedVehicles,
  });

  useEffect(() => {
    updateSortingSubject(unsubscribedVehicles);
  }, [unsubscribedVehicles]);

  useEffect(() => {
    if (defaultSelectedVehicles.length)
      vehiclesHook.addVehicles(defaultSelectedVehicles);
  }, []);

  const handleVehicleSelection = (event: any, uv: UnsubscribedVehicle) => {
    const selected = event.target.checked;
    if (selected) {
      vehiclesHook.addVehicles([uv]);
    } else {
      vehiclesHook.removeVehicle(uv);
    }
  };

  return (
    <div className="shadow-xl p-5 h-full">
      <div className="h-32 ">
        {!tariffLoading && tariff ? (
          <>
            <div className="pl-6 pt-6 mb-4 text-xl">
              {t("vehicle-selection.select-to-upgrade")} {tariff.name}:
            </div>
            <div className="pl-6 mt-1 mb-6 text-md text-Grey-dark">
              {t("vehicle-selection.click-checkbox")}
            </div>
          </>
        ) : (
          <Spinner />
        )}

        <div className="flex justify-around">
          <div className="mr-8"></div>
          <div className="w-1/3 text-Grey-shade flex">
            {t("vehicle-selection.vehicle")}{" "}
            {getSortIcon(sortingState, "vehicleName", () =>
              sortVehicleByProp("vehicleName")
            )}{" "}
          </div>
          <div className="w-1/4 text-Grey-shade justify-center flex">
            {t("vehicle-selection.trips")}{" "}
            {getSortIcon(sortingState, "countOfTrips", () =>
              sortVehicleByProp("countOfTrips")
            )}{" "}
          </div>
          <div className="w-1/4 text-Grey-shade justify-end flex">
            {t("vehicle-selection.distance")}{" "}
            {getSortIcon(sortingState, "totalDistance", () =>
              sortVehicleByProp("totalDistance")
            )}
          </div>
        </div>
      </div>
      {!unsubscribedVehiclesLoading && sortedVehicles ? (
        <div className="mt-5 h-72 overflow-y-auto overflow-x-hidden">
          {sortedVehicles.map((uv: UnsubscribedVehicle, idx: number) => (
            <div
              key={uv.vehicleId}
              className="flex justify-around border-solid border-2 p-4 mt-2"
            >
              <input
                type="checkbox"
                checked={vehiclesHook.isVehicleSelected(uv) || false}
                onChange={(e) => handleVehicleSelection(e, uv)}
                className="mr-2 w-4 h-4 text-Blueberry-dark-default bg-transparent rounded border-Blueberry-dark-default focus:ring-0 focus:outline-offset-0 focus:outline-0 focus:ring-offset-0"
              />
              <span className="w-1/3 text-Grey-shade ">{uv.vehicleName}</span>

              <span className="w-1/4  text-Grey-shade justify-center flex">
                {uv?.countOfTrips?.toLocaleString(localeCode)}
              </span>

              <span className="w-1/4 text-Grey-shade justify-end flex">
                {uv?.totalDistance?.toLocaleString(localeCode)} km
              </span>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <Spinner />
        </div>
      )}
    </div>
  );
}

export function VehicleSelection({
  onConfirm,
  unsubscribedVehicles,
  vehiclesHook,
  tariff,
  unsubscribedVehiclesLoading,
  tariffLoading,
  defaultSelectedVehicles,
}: {
  onConfirm: () => void;
  unsubscribedVehicles: UnsubscribedVehicle[];
  vehiclesHook: IuseSelectedVehicles;
  tariff: Tariff;
  unsubscribedVehiclesLoading: boolean;
  tariffLoading: boolean;
  defaultSelectedVehicles: UnsubscribedVehicle[];
}) {
  const { t } = useTranslation("paymentProcess");

  const disabled = () => {
    return vehiclesHook.selectedVehicles.length === 0;
  };

  return (
    <>
      <div className="flex justify-between">
        <div className="w-full m-4 mr-1">
          <VehiclePickerCard
            unsubscribedVehicles={unsubscribedVehicles}
            vehiclesHook={vehiclesHook}
            tariff={tariff}
            unsubscribedVehiclesLoading={unsubscribedVehiclesLoading}
            tariffLoading={tariffLoading}
            defaultSelectedVehicles={defaultSelectedVehicles}
          />
        </div>
        <div className="w-1/3 m-4">
          <Calculator
            title={t("vehicle-selection.calculator-title")}
            message={t("vehicle-selection.calculator-message")}
            vehiclesHook={vehiclesHook}
            tariff={tariff}
            tariffLoading={tariffLoading}
            childComponent={
              <Disclaimer duration={tariff?.subscription_duration_months} />
            }
          />
        </div>
      </div>
      <div className=" mt-1 ml-4">
        <button
          className={`bg-Blueberry-dark-default hover:${
            disabled() ? "bg-gray-800" : "bg-Blueberry-light-shade"
          } w-1/4 p-5 text-white rounded`}
          onClick={onConfirm}
          disabled={disabled()}
        >
          <div>{t("confirm")}</div>
        </button>
      </div>
    </>
  );
}
