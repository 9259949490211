import { useContext, useEffect, useMemo, useState } from "react";

import { useNavigate } from "react-router-dom";
import { useMutation, useQuery as useRestQuery } from "react-query";

import { VehicleSelectionCard } from "./VehicleSelectionCard";
import { VehicleSelectionNotAvailableCard } from "./VehicleSelectionNotAvailable/VehicleSelectionNotAvailableCard";

import { ElectrificationPlannerSections, ElectrificationPlannerVehicleSectionSubSections } from "../../../../../models/Section";

import { useTranslation } from "react-i18next";
import { useUserData } from "../../../../../../../../../hooks/state/useUserData";
import { ElectrifyPlannerContext } from "../../../../../../../contexts/ElectrificationPlannerContext";
import { updateElectrificationPlanner } from "../../../../../../../../../services/rest/electrificationPlanner";
import { getEvs } from "../../../../../../../../../electrify_frontend_common/services";
import ModalWrapper from "../../../../../../../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../../../../../../../electrify_frontend_common/components/ModalWrapper/Headers";
import { Button } from "../../../../../../../../../electrify_frontend_common/components/Buttonv2";
import { Spinner, SpinnerSize } from "../../../../../../../../../electrify_frontend_common/components/Spinner";
import { OpenEvFinderEvDetails } from "../../../../../../../Electrify/pages/OpenEvFinder/EvDetailsModal";
import { AlternativeVehicle } from "../../../../../../../../../electrify_frontend_common/types";
import { useQuery } from "@apollo/client";
import { EVAlternativesResponse } from "../../../../../../../../../@types/simulations";
import { GET_VEHICLE_EV_ALTERNATIVES } from "../../../../../../../../../services/graphql/fleet/queries/simulation";
import { useVehicleSimulation } from "../../../../../../../Electrify/pages/EvFinder/DriverEvFinder/hooks/useVehicleSimulation";
import { DriverPaymentGatewayContext } from "../../../../../../../contexts/DriverPaymentGatewayContext";



function useFavoriteVehicles() {

    const { user } = useUserData();
    const { isProductPaid } = useContext(DriverPaymentGatewayContext);


    const { vehicleSelectionState } = useVehicleSimulation();
    const driverVehicle = vehicleSelectionState.originalDriverVehicle.value;


    const { data, isLoading } = useRestQuery(
        ["vehicles"],
        () =>
            getEvs({
                userId: user?.sub,
                favoritesOnly: true,
                userCountryCode: 'DE',
            }),
        {
            onSuccess: (data) => {
                console.log("Got favorites: ", data);
            }
        }
    );

    const {
        data: evFinderFavoritesData,
        loading: favoritesLoading,
    } = useQuery<{ vehicleEvAlternatives: EVAlternativesResponse }>(GET_VEHICLE_EV_ALTERNATIVES, {
        variables: {
            vehicleId: driverVehicle?.vehicleId,
            onlyFavorites: true,
        },
        skip: !user?.accountInfo?.usableProduct,
    });


    return {
        favoriteVehicles: (user?.accountInfo?.usableProduct && isProductPaid) ? evFinderFavoritesData?.vehicleEvAlternatives?.evAlternatives : data?.electricVehicles,
        isLoading: (user?.accountInfo?.usableProduct && isProductPaid) ? favoritesLoading : isLoading
    }

}


export function VehicleSelectionModalContent({ closeModal }: { closeModal: () => void }) {

    const { t } = useTranslation("electrificationPlanner");

    const [noFavoritesWarningModalOpen, setNoFavoritesWarningModalOpen] = useState(false);
    const navigate = useNavigate();

    const [evDetailsToCheck, setEvDetailsToCheck] = useState<string | undefined>();

    const { refetch, electrificationPlanner } = useContext(ElectrifyPlannerContext);

    const mutation = useMutation(async (input: any) => {
        return await updateElectrificationPlanner(input)
    }, {
        onSuccess: () => {
            refetch();
            closeModal();
        }
    });

    const pickedVehicleId = useMemo(() => {
        if (!electrificationPlanner) return;
        return electrificationPlanner[ElectrificationPlannerSections.VEHICLE_SECTION]?.[ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION]?.vehicleModelSelection.data;
    }, [electrificationPlanner])


    const {
        isLoading,
        favoriteVehicles
    } = useFavoriteVehicles();


    useEffect(() => {
        if (!isLoading && favoriteVehicles?.length === 0) {
            setNoFavoritesWarningModalOpen(true);
        } else {
            setNoFavoritesWarningModalOpen(false);
        }

    }, [isLoading, favoriteVehicles?.length])



    return (
        <>
            {evDetailsToCheck ?
                <OpenEvFinderEvDetails evDetailsToCheck={evDetailsToCheck} closeModal={() => setEvDetailsToCheck(undefined)} />
                : null}
            <ModalWrapper className="w-8/12" close={() => setNoFavoritesWarningModalOpen(false)} isOpen={noFavoritesWarningModalOpen}>
                <>
                    <ModalHeader onCancel={() => setNoFavoritesWarningModalOpen(false)} title={t("vehicleSection.subsections.purchase.steps.vehicleModelSelection.noVehiclesModal.title")} />
                    <div className="px-16 py-8 w-2/3">
                        <div>{t("vehicleSection.subsections.purchase.steps.vehicleModelSelection.noVehiclesModal.description1")}</div>
                        <div>{t("vehicleSection.subsections.purchase.steps.vehicleModelSelection.noVehiclesModal.description2")}</div>

                        <div className="mt-5 mb-8">{t("vehicleSection.subsections.purchase.steps.vehicleModelSelection.noVehiclesModal.description3")}</div>
                        <Button onClick={() => navigate('/ev-finder')}>{t("vehicleSection.subsections.purchase.steps.vehicleModelSelection.noVehiclesModal.button")}</Button>
                    </div>
                </>
            </ModalWrapper>
            <div className="px-16 py-8 overflow-y-auto">
                <div>{t("vehicleSection.subsections.purchase.steps.vehicleModelSelection.selectionModal.description1")}</div>
                <div className="text-xs mt-2" >{t("vehicleSection.subsections.purchase.steps.vehicleModelSelection.selectionModal.description2.pt1")} <span onClick={() => navigate('/ev-finder')} className="text-Blueberry-dark-default cursor-pointer">{t("vehicleSection.subsections.purchase.steps.vehicleModelSelection.selectionModal.description2.evFinderPage")}</span> {t("vehicleSection.subsections.purchase.steps.vehicleModelSelection.selectionModal.description2.pt2")}</div>
                <div className="mt-5 grid grid-cols-3 gap-4 overflow-y-auto">

                    {!isLoading ?
                        <>
                            {favoriteVehicles?.map((evs: Partial<AlternativeVehicle>) => (
                                <div key={evs.evId} className="col-span-1">
                                    <VehicleSelectionCard
                                        vehicle={evs}
                                        selected={evs.evId === pickedVehicleId}
                                        onMoreInfoClick={() => setEvDetailsToCheck(evs.evId)}
                                        onSelect={() => mutation.mutate({
                                            section: ElectrificationPlannerSections.VEHICLE_SECTION,
                                            subsection: ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION,
                                            data: {
                                                answer: {
                                                    vehicleModelSelection: evs.evId
                                                },

                                            }
                                        })
                                        }
                                    />
                                </div>
                            ))}
                            <div className="col-span-1">
                                <VehicleSelectionNotAvailableCard onSkip={
                                    () => mutation.mutate({
                                        section: ElectrificationPlannerSections.VEHICLE_SECTION,
                                        subsection: ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION,
                                        data: {
                                            answer: {
                                                vehicleModelSelection: "unavailable"
                                            }
                                        }
                                    })
                                } />
                            </div>
                        </> : <Spinner size={SpinnerSize.LARGE} />}
                </div>
            </div>
        </>
    )


}