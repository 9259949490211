import cabrioletIcon from "../../../assets/car_icons/cabriolet.svg";
import hatchbackIcon from "../../../assets/car_icons/hatchback.svg";
import minivanIcon from "../../../assets/car_icons/minivan.svg";
import sedanIcon from "../../../assets/car_icons/sedan.svg";
import suvIcon from "../../../assets/car_icons/suv.svg";
import vanIcon from "../../../assets/car_icons/van.svg";
import wagonIcon from "../../../assets/car_icons/wagon.svg";

export default function CarIconSelector(bodyStyle?: string, category?: string) {
  switch (true) {
    case bodyStyle === "Cabriolet":
      return cabrioletIcon;
    case bodyStyle === "Hatchback":
      return hatchbackIcon;
    case bodyStyle === "Small Passenger Van":
      return minivanIcon;
    case bodyStyle === "Sedan":
      return sedanIcon;
    case bodyStyle === "SUV":
      return suvIcon;
    case bodyStyle === "Van":
      return vanIcon;
    case bodyStyle === "Station/Estate":
      return wagonIcon;
    case category === "N1":
      return vanIcon;
    default:
      return sedanIcon;
  }
}
