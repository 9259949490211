import { useScreenSize } from "../../../../hooks/useScreenSize";
import { IFilters } from "../../../../types";
import { CollapsableFilters } from "./CollapsableFilters";
import { RegularFilters } from "./RegularFilters";



export function Filters({
    filters,
    updateFilters,
    resetFilters,
    backgroundColor,
    currencySymbol
}: {
    backgroundColor: "light" | "dark",
    filters: IFilters,
    updateFilters: (filters: Partial<IFilters>) => void,
    resetFilters: () => void,
    currencySymbol: string
}) {

    const { widthIsLessThan1024 } = useScreenSize();

    return (
        <>
            {widthIsLessThan1024 ?
                <CollapsableFilters currencySymbol={currencySymbol} filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} backgroundColor={backgroundColor} /> :
                <RegularFilters
                    filters={filters}
                    updateFilters={updateFilters}
                    resetFilters={resetFilters}
                    backgroundColor={backgroundColor}
                    currencySymbol={currencySymbol}
                />}
        </>
    )


}