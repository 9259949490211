import axios from "axios";
import { MilestoneStepStatus } from "../../../products/B2C/Electrify/pages/DriverDongle/DongleProductWelcome/useDongleProductWelcome";



export async function getDongleLeasingOrderStatus(): Promise<{
  dongleOrder: {
    status: MilestoneStepStatus,
    nextStepInfo?: undefined
  },
  dongleShipment: {
    status: MilestoneStepStatus,
    nextStepInfo?: {
      shipmentDate: Date
    }
  },
  dongleInstallation: {
    status: MilestoneStepStatus,
    nextStepInfo?: undefined
  },
  minimumRequirements: {
    status: MilestoneStepStatus,
    nextStepInfo?: undefined
  }
}> {
  return (await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/dongle-leasing/road-map/status`
  )).data;
}

export async function completeDongleLeasingOnboarding() {
  return (await axios.post(
    `${process.env.REACT_APP_BACKEND_URL}/dongle-leasing/road-map/complete`
  )).data;
}