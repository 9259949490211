import { useTranslation } from "react-i18next";
import { Button } from "../../../../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonVariant } from "../../../../../../../../../../electrify_frontend_common/components/Buttonv2/types";
import ModalWrapper from "../../../../../../../../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../../../../../../../../electrify_frontend_common/components/ModalWrapper/Headers";





export function ContactUsModal({ isOpen, close, onSkip }: { isOpen: boolean, close: () => void, onSkip: () => void }) {

    const {t} = useTranslation("electrificationPlanner");



    return (
        <ModalWrapper isOpen={isOpen} close={close}>
            <>
                <ModalHeader title={t("misc.contactUs.title")} onCancel={close} />
                <div className="p-8 w-2/3">
                    <div className="mb-3">{t("misc.contactUs.message1")}</div>
                    <div>{t("misc.contactUs.message2")}</div>
                    <div className="flex items-center mt-5">
                        <a href="mailto:support@chargylize.com?subject=Electrify Support">
                            <Button
                                className="mr-6"
                                variant={ButtonVariant.PRIMARY}
                                onClick={onSkip}
                            >
                                {t("misc.contactUs.goToContactForm")}
                                
                            </Button>
                        </a>
                        <Button
                            variant={ButtonVariant.SECONDRY}
                            onClick={onSkip}
                        >
                            <div className="px-6">
                            {t("misc.contactUs.skip")}
                            </div>
                        </Button>
                    </div>
                </div>
            </>
        </ModalWrapper>
    )


}