import { useTranslation } from "react-i18next";
import { EnergyDetails } from "./EnergyDetails";
import { FuelDetails } from "./FuelDetails";
import { useQuery } from "@apollo/client";
import { useEnergyAndFuelDetails } from "../../../../../B2B/Electrify/pages/Settings/FuelAndEnergyDetails/useEnergyAndFuelDetails";
import { GET_DRIVER_VEHICLE } from "../../../../../../services/graphql/driver/queries";

export function FuelAndEnergyDetails() {

  const {t} = useTranslation("driverSettings");
  const energyAndFuelDetails = useEnergyAndFuelDetails();
  const {data} = useQuery(GET_DRIVER_VEHICLE);

  return (
    <>
      <h2 className="text-2xl font-normal mt-10 mb-6">
        {t("fuel-and-energy.title")}
      </h2>
      <div className="grid grid-cols-3 gap-6">
        <div className="flex flex-col col-span-1">
          <FuelDetails energyAndFuelDetails={energyAndFuelDetails} vehicle={data?.driverVehicle} />
        </div>

        <div className="col-span-1">
          <EnergyDetails energyAndFuelDetails={energyAndFuelDetails}/>
        </div>
      </div>
    </>
  );
}
