import { ReactElement } from "react";
import { QueryClient, QueryClientProvider } from "react-query";


const reactQueryClient = new QueryClient();


export const RestQueryClientProvider = ({children}: {children: ReactElement}) => {
    return (
        <QueryClientProvider client={reactQueryClient}>
            {children}
        </QueryClientProvider>
    )
}


