import Calculator from "./utils/Calculator";
import { ReactComponent as VehicleIcon } from "../../../../assets/vehicle-icon-active.svg";
import { Disclaimer } from "./utils/Disclaimer";
import { useTranslation } from "react-i18next";
import { Tariff } from "../../../../@types/paymentProcess";
import { IuseSelectedVehicles } from "../../../../hooks/utils/useSelectedVehicles";
import { useLayout } from "../../../../hooks/state/useLayout";

function NewPremiumOrders({
  vehiclesHook,
  tariff,
}: {
  vehiclesHook: IuseSelectedVehicles;
  tariff: Tariff;
}) {
  const { t } = useTranslation("paymentProcess");
  const { currencySymbol } = useLayout();

  return (
    <div className="shadow-xl p-2 h-full">
      <h1 className="text-xl pl-6 pt-6">
        {t("checkout-process.number-of-new")} {tariff.name} {t("orders")}
      </h1>
      <div className="flex justify-between m-5 border-solid border-2 p-5">
        <div className="flex">
          <VehicleIcon className="mr-2" />
          <div className="text-Grey-dark">
            <span className="text-Blueberry-dark-default">
              {vehiclesHook.selectedVehicles.length}
            </span>{" "}
            {t("checkout-process.vehicles")}
          </div>
        </div>
        <div className="text-Grey-dark">
          {tariff.price}
          {currencySymbol} / {t("checkout-process.vehicle")}
        </div>
      </div>
      <div className="px-2">
        <Disclaimer duration={tariff.subscription_duration_months} />
      </div>
    </div>
  );
}

export default function CheckoutProcess({
  onConfirm,
  vehiclesHook,
  tariff,
}: {
  onConfirm: () => void;
  vehiclesHook: IuseSelectedVehicles;
  tariff: Tariff;
}) {
  const { t } = useTranslation("paymentProcess");

  return (
    <>
      <div className="flex justify-between h-full">
        <div className="w-1/2 m-5">
          <NewPremiumOrders vehiclesHook={vehiclesHook} tariff={tariff} />
        </div>
        <div className="w-1/2 m-5">
          <Calculator
            title={t("checkout-process.calculator-title")}
            vehiclesHook={vehiclesHook}
            tariff={tariff}
            childButton={
              <button
                className={`bg-Blueberry-dark-default hover:bg-Blueberry-light-shade p-5 w-full text-white rounded`}
                onClick={onConfirm}
              >
                {t("checkout-process.order-now")}
              </button>
            }
          />
        </div>
      </div>
    </>
  );
}
