

export enum SpinnerSize {
    SMALL,
    MEDIUM,
    LARGE
}

export function Spinner({size = SpinnerSize.SMALL} : {size?: SpinnerSize}) {

    const sizes = {
        [SpinnerSize.SMALL] : 'h5 w-5',
        [SpinnerSize.MEDIUM] : 'h-8 w-8',
        [SpinnerSize.LARGE] : 'h-12 w-12'
    }

    return (
        <svg className={`animate-spin ${sizes[size]}`} viewBox="0 0 40 40">
            <circle cx="20" cy="20" r="14" fill="transparent" stroke="#B886A6" strokeOpacity="0.7"
                strokeWidth="4"></circle>
            <circle cx="20" cy="20" r="14" fill="transparent" stroke="#830751" strokeOpacity="1" strokeWidth="4"
                strokeDasharray="60 100" strokeDashoffset="25"></circle>
        </svg>
    )

}