import { useQuery } from "@apollo/client";
import { useState } from "react";
import { ReactComponent as ConnectionIcon } from "../../../../../../../../assets/connection-icon.svg";
import { ConnectionState } from "./WebfleetSync";
import { GET_RFERENCE_NUMBER } from "../../../../../../../../services/graphql/fleet/queries";
import { Button } from "../../../../../../../../electrify_frontend_common/components/Buttonv2";


export default function CopyReference({
  connectionState,
}: {
  connectionState: ConnectionState | null;
}) {
  const [copiedToClipBoard, setCopiedToClipboard] = useState(false);

  const { data } = useQuery(GET_RFERENCE_NUMBER);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(data?.setting?.referenceNumber);
    setCopiedToClipboard(true);
    setTimeout(() => {
      setCopiedToClipboard(false);
    }, 1000);
  };

  const getCopyComponentStyle = () => {
    return connectionState
      ? connectionState === ConnectionState.SUCCESS
        ? "border-Green-default"
        : "border-Red-default"
      : "border-Grey-shade";
  };

  return (
    <div
      className={`border ${getCopyComponentStyle()} rounded flex w-1/3 mt-7`}
    >
      <div
        className={`w-1/5 rounded-l items-center bg-Grey-background flex justify-center border-r border-Grey-shade`}
      >
        <ConnectionIcon />
      </div>
      <div className="p-1 flex w-full items-center justify-between">
        <div className="text-Grey-shade ml-2">
          {data?.setting?.referenceNumber}
        </div>
        <Button
          className="bg-Grey-shade text-white rounded p-3 px-4"
          onClick={() => copyToClipboard()}
        >
          {copiedToClipBoard ? "Copied" : "Copy"}
        </Button>
      </div>
    </div>
  );
}
