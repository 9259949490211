import { useLazyQuery } from "@apollo/client";

import { TripSimulationPreferencesState } from "./useTripSimulationPreferencesState";
import { TripSimulationDetail } from "../../../../../../../@types/driver/simulations";
import { GET_TRIP_SIMULATION } from "../../../../../../../services/graphql/driver/queries";

export function useTripSimulationData({
  includeExtraStops,
  selectedOptimization,
}: TripSimulationPreferencesState) {
  const [fetchTripsSimulationDetails, { data }] = useLazyQuery<{
    tripsSimulationDetails: TripSimulationDetail[];
  }>(GET_TRIP_SIMULATION, {
    variables: {
      includeExtraStops: includeExtraStops,
      optimizationMethod: selectedOptimization,
    },
  });

  return {
    tripSimulationData: data?.tripsSimulationDetails,
    fetchTripsSimulationDetails,
  };
}
