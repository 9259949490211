import { ReactElement } from "react";
import { ReactComponent as ExpandIcon } from "../../../assets/expand-icon.svg";
import { useTranslation } from "react-i18next";
import SortButton from "./SortButton";

export default function ChartWrapper({
  title,
  sort,
  expand,
  children,
  sortedCrescent,
} : {
    title: string,
    sort?: ()=> void,
    expand?: ()=> void,
    children: ReactElement,
    sortedCrescent?: boolean
}) {
  const { t } = useTranslation("chartWrapper");

  return (
    <div className="border-solid border-2	mb-10 border-Grey-tint rounded">
      <div className="p-6 flex justify-between">
        <h1 className="text-xl text-Blueberry-dark-default">{title}</h1>
        <div className="flex">
          {sort ? <SortButton sort={sort} isCrescent={sortedCrescent} /> : null}
          <span
            className="flex items-center text-xs cursor-pointer"
            onClick={expand}
          >
            <ExpandIcon className="mr-2" />
            {t("expand-chart")}
          </span>
        </div>
      </div>
      <hr className="border-solid border-Grey-tint" />
      <div className="p-6">{children}</div>
    </div>
  );
}
