import { useTranslation } from "react-i18next";
import arrowRightIcon from "../../../../assets/arrow-right-icon.svg";
import { useLayout } from "../../../../hooks/state/useLayout";
import { SimulationVehicleInfo } from "../../../../@types";
import { BrandInfo } from "../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/BrandInfo";
import { ReactElement } from "react";
import { Range } from "../../../../electrify_frontend_common/icons/Range";
import { FuelCostsIcon } from "../../../../electrify_frontend_common/icons/FuelCostsIcon";
import { Co2EmissionsIcon } from "../../../../electrify_frontend_common/icons/Co2EmissionsIcon";

function VehicleProp({ icon, label, value, unit }: { icon: ReactElement, label: string, value: string, unit: string }) {

  const { t } = useTranslation("fleetSimulation");

  return (
    <div className="flex justify-between items-center mb-2">
      <div className="flex items-center text-xs">
        {icon}
        <div className="ml-2">{label}</div>
      </div>
      <div className="flex items-center">
        <span className="text-right font-bold text-xs">
          {value}
        </span>
        <span className="text-right text-xs ml-1">
          {unit}
        </span>
      </div>
    </div>
  )

}



const VehicleCard = ({ vehicle }: { vehicle: SimulationVehicleInfo }) => {

  const { t } = useTranslation("fleetSimulation");
  const { localeCode, currencySymbol } = useLayout();

  const {
    vehicleId,
    vehicleName,
    vehicleModelName,
    yearlyMileage,
    yearlyFuelAndEnergyCosts,
    yearlyCo2Emission,
  } = vehicle;


  return (
    <div key={vehicleId} className="flex justify-between items-center h-60">
      <div
        className="p-6 py-4 border border-Grey-tint mr-5 w-full rounded flex flex-col h-full bg-Grey-background justify-between"

      >
        <BrandInfo
          vehicleBrand={vehicle.vehicleBrand}
          vehicleModel={vehicle.vehicleModel}
        />
        <div className="text-Black-default font-bold	text-lg">{vehicleName}</div>
        <div >
          <VehicleProp
            icon={<Range />}
            label={t("current-vehicle.mileage")}
            value={yearlyMileage?.toLocaleString(localeCode) || ""}
            unit={"km"}

          />

          <VehicleProp
            icon={<FuelCostsIcon />}
            label={t("current-vehicle.cost")}
            value={yearlyFuelAndEnergyCosts?.toLocaleString(localeCode) || ""}
            unit={`${currencySymbol}/${t("time-unit")}`}

          />

          <VehicleProp
            icon={<Co2EmissionsIcon />}
            label={t("current-vehicle.co2")}
            value= {yearlyCo2Emission?.toLocaleString(localeCode) || ""}
            unit={`kg/${t("time-unit")}`}

          />

          {/* <div className="flex justify-between items-center mb-2">
            <span className="text-left text-xs">
              {t("current-vehicle.co2")}
            </span>
            <div className="flex items-center">
              <span className="text-right font-bold text-xs">
                {yearlyCo2Emission?.toLocaleString(localeCode) || ""}
              </span>
              <span className="text-right text-xs ml-1">
                kg/{t("time-unit")}
              </span>
            </div>
          </div> */}
        </div>
      </div>
      <img src={arrowRightIcon} alt="arrow-right" className="mr-5 inline" />
    </div>
  );
};

export default VehicleCard;
