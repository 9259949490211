import { useTranslation } from "react-i18next";
import { DistancesChart } from "./DistancesChart";
import ChartWrapper from "../../../../../../../../common/components/Charts/ChartWrapper";

export function Distances({ perDay, perWeek, openExpanded }: any) {
  const { t } = useTranslation("driverDrivingAnalysis");

  return (
    <ChartWrapper title={t("distances")} expand={openExpanded}>
      <DistancesChart perDay={perDay} perWeek={perWeek} />
    </ChartWrapper>
  );
}
