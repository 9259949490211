import { Bar } from "react-chartjs-2";
import { options } from "./ChartConfigs";
import { useTranslation } from "react-i18next";
import { useLayout } from "../../../../../../../../hooks/state/useLayout";
import { defineColorScheme } from "../../../../../../../../common/components/Charts/ChartColors";

export function ChargingOptionsChart({ data }: { data: any }) {
    const { t } = useTranslation("driverParkingAnalysis");
    const { localeCode } = useLayout();

    const colorScheme = defineColorScheme(3)
    const labels = data.map((d: any) => d.date);
    const datasets = [
        {
            data: data.map((d: any) => d.home),
            minBarLength: 1,
            backgroundColor: colorScheme[0],
            label: t("parking-location-types.types.home"),
        },
        {
            data: data.map((d: any) => d.workplace),
            minBarLength: 1,
            backgroundColor: colorScheme[1],
            label: t("parking-location-types.types.workplace"),
        },
        {
            data: data.map((d: any) => d.public),
            minBarLength: 1,
            backgroundColor: colorScheme[2],
            label: t("parking-location-types.types.public"),
        },
    ];

    return (
        <div className="flex flex-col h-full">
            {data ? (
                <Bar
                    options={options(localeCode, labels)}
                    data={{ labels, datasets }}
                />
            ) : null}
        </div>
    );
}
