/* React Imports */
import { ReactElement } from 'react';

/* Third-party Imports */

/* Local Imports */
import BackgroundImage from '../../../assets/auth_background.webp';
import chargylizeLogoWhite from '../../../assets/chargylize_logo_white.svg';
import LanguageDropdown from '../LayoutWrapper/Header/LanguageDropdown';

export default function AuthLayout({
	children,
	showLanguageDropdown = true,
}: {
	children: ReactElement;
	showLanguageDropdown?: boolean;
}) {
	return (
		<div className="w-full h-screen grid  lg:grid-cols-12 lg:mx-0 lg:gap-x-16 md:grid-cols-6 md:mx-6 md:gap-x-16 grid-cols-2 mx-6 gap-x-16 ">
			<div className="overflow-hidden lg:h-full lg:col-span-6 lg:-ml-12 md:col-span-6 md:h-60 h-40 col-span-2 -ml-6 w-full">
				<img
					src={BackgroundImage}
					className="lg:object-cover lg:h-full w-full"
					alt="authentication-bg"
				/>
			</div>
			<div className="lg:flex-none lg:hidden lg:absolute top-0 left-0 md:col-span-6 col-span-2 -ml-6 flex justify-center content-center">
				<img
					src={chargylizeLogoWhite}
					className="absolute lg:mx-20 md:top-20 md:w-48 w-36 top-24 hover:cursor-pointer"
					alt="chargylize-logo"
					onClick={() =>
						window.open('https://www.chargylize.com/', '_self')
					}
				/>
			</div>
			<img
				src={chargylizeLogoWhite}
				className="absolute left-20 top-20 w-48 hidden lg:block hover:cursor-pointer"
				alt="chargylize-logo"
				onClick={() =>
					window.open('https://www.chargylize.com/', '_self')
				}
			/>
			<div className="lg:-ml-16 md:col-span-6 col-span-2 h-full overflow-auto	">
				<div className="flex justify-between">
					<div></div>
					{showLanguageDropdown ? (
						<div className="mt-10 mr-16">
							<LanguageDropdown />
						</div>
					) : null}
				</div>
				<div className="lg:pr-40 lg:pl-28 lg:pt-16 mx-auto h-5/6 w-full ">
					{children}
				</div>
			</div>
		</div>
	);
}
