import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { TripEndSoC } from "../../../../../../../@types/driver/simulations";
import { useLayout } from "../../../../../../../hooks/state/useLayout";
import { defineColorScheme } from "../../../../../../../common/components/Charts/ChartColors";


export function BatteryLevelChart({
    tripsEndSoc,
    maxBatteryLevelPercentageAll,
    setMaxBatteryLevelPercentageAll
}: {
    tripsEndSoc?: TripEndSoC
    maxBatteryLevelPercentageAll: number
    setMaxBatteryLevelPercentageAll: any
}) {
    const { t } = useTranslation("scenarios");
    const { localeCode } = useLayout();

    if (!tripsEndSoc) return null;

    const data: number[] = [tripsEndSoc.from80, tripsEndSoc.from60To79, tripsEndSoc.from40To59, tripsEndSoc.from20To39, tripsEndSoc.to19]

    const totalSum: number = data.reduce((partialSum, curr) => partialSum + curr, 0)
    const dataPercentage: number[] = data.map(value => Math.round((value / totalSum) * 100))

    const maximumPercentage: number = Math.max(...dataPercentage)
    if (maximumPercentage > maxBatteryLevelPercentageAll) setMaxBatteryLevelPercentageAll(maximumPercentage)

    return <Bar options={{
        plugins: {
            tooltip: {
                callbacks: {
                    label: function (tooltipItem: any) {
                        console.log(tooltipItem)
                        return `${tooltipItem.raw.toLocaleString(localeCode)}% (${tooltipItem.label})`;
                    }
                }
            },
            legend: { display: false },
        },
        layout: { padding: 0 },
        scales: {
            x: {
                border: { display: false },
                grid: { display: false },
                ticks: { display: false }
            },
            y: {
                max: maxBatteryLevelPercentageAll,
                border: { display: false },
                grid: { display: false },
                ticks: { display: false }
            }
        }
    }} data={{
        datasets: [
            {
                data: dataPercentage,
                backgroundColor: defineColorScheme(5),
                minBarLength: 1,
                barPercentage: 1.0,
                categoryPercentage: 1.0,
            },
        ],
        labels: [t('labels.from80'), t('labels.from60To79'), t('labels.from40To59'), t('labels.from20To39'), t('labels.to19')]
    }}
    />;
}
