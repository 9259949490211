import { useState } from "react";

const getFromNestedStringKey = (obj: any, keys: string) => {
  let value = obj;
  const splittedKeys = keys.split(".");
  for (var i = 0; i < splittedKeys.length; i++) {
    if(!value) return;
    value = value[splittedKeys[i]];
  }
  return value;
}

function updateFromNestedStringKey(obj: any, keys: string, newValue: any){
  const splittedKeys = keys.split(".");
  for (var i = 0; i < splittedKeys.length - 1; i++) {
    if(!obj) return;
    obj = obj[splittedKeys[i]];
  }

  obj[splittedKeys[splittedKeys.length - 1]] = newValue;
}

interface StringKey {
  [key: string]: any;
}

export default function useFilters() {

  const [filtersOptions, setFiltersOptions] = useState({});

  const [activeFilters, setActiveFilters] = useState({});

  const updateActiveFilters = (key: string, value: any) => {
    setActiveFilters((activeFilter) => {
      let copy: any = { ...activeFilter };
      updateFromNestedStringKey(copy, key, value);
      return copy;
    });
  };

  const readFilterValue = (keys: string) => {
    return activeFilters ? getFromNestedStringKey(activeFilters, keys) : null;
  };

  const readFilterOptions = (keys: string) => {
    return filtersOptions ? getFromNestedStringKey(filtersOptions, keys) : null;
  };

  return {
    filtersOptions,
    setFiltersOptions,
    activeFilters,
    setActiveFilters,
    updateActiveFilters,
    readFilterValue,
    readFilterOptions,
  };
}
