/*
  Auxilliar functions for sorting purposes - May be refactored to own "utils" file 
*/

import { useTranslation } from "react-i18next";
import { Dataset } from "../../@types/analysis";
import { IFleetAnalysisVehicle } from "../../@types/fleet";
import { defineColorScheme } from "../../common/components/Charts/ChartColors";

const sortDataAscending = (datasets: any) => {
    datasets.map((dataset: any) =>
        dataset.data?.sort((a: any, b: any) => a.value - b.value)
    );
};
const sortDataDescending = (datasets: any) => {
    datasets.map((dataset: any) =>
        dataset.data?.sort((a: any, b: any) => b.value - a.value)
    );
};

const sortSegmentedDataBasedOnLabels = (
    sortedLabels: any,
    objectToSort: any
) => {
    for (let i = 1; i < objectToSort.length; i++) {
        const array: any[] = [];
        sortedLabels.forEach((label: string) => {
            const idx = objectToSort[i]?.data.findIndex(
                (d: any) => d.label === label
            );
            array.push(objectToSort[i]?.data[idx]);
        });
        objectToSort[i].data = array;
    }
};

const computeSortedPrivatePercentage = (dataset: any) => {
    dataset[0].data?.forEach((d: any, i: number) => {
        const businessData = dataset[0]?.data[i];
        dataset[1].data.push({
            value: 100 - businessData.value,
            label: businessData.label,
        });
    });
};

const sortSegmentedDataAscending = (tripData: any, dayData: any) => {
    sortDataAscending([tripData[0]]);

    const segmentedDataPerTripSortedLabels = tripData[0].data.map(
        (d: any) => d.label
    );

    sortSegmentedDataBasedOnLabels(segmentedDataPerTripSortedLabels, tripData);

    sortDataAscending([dayData[0]]);

    const segmentedDataPerDaySortedLabels = dayData[0].data.map(
        (d: any) => d.label
    );

    sortSegmentedDataBasedOnLabels(segmentedDataPerDaySortedLabels, dayData);
};

const sortSegmentedDataDescending = (tripData: any, dayData: any) => {
    sortDataDescending([tripData[0]]);

    const segmentedDataPerTripSortedLabels = tripData[0].data.map(
        (d: any) => d.label
    );

    sortSegmentedDataBasedOnLabels(segmentedDataPerTripSortedLabels, tripData);

    sortDataDescending([dayData[0]]);

    const segmentedDataPerDaySortedLabels = dayData[0].data.map(
        (d: any) => d.label
    );

    sortSegmentedDataBasedOnLabels(segmentedDataPerDaySortedLabels, dayData);
};

export default function useHandleDrivingData() {
    const { t } = useTranslation("drivingAnalysis");

    const twoColorScheme = defineColorScheme(2)
    const fiveColorScheme = defineColorScheme(5)

    const handleData = (data: IFleetAnalysisVehicle[]) => {
        const yearlyMileageDataset: Dataset[] = [
            {
                label: t("yearly-mileage-title"),
                data: data?.map((item: IFleetAnalysisVehicle) => ({
                    label: item.vehicleName,
                    value: item.yearlyMileage,
                })),
            },
        ];
        const averageDistancePerTripDatasets: Dataset[] = [
            {
                label: t("average-distance-title"),
                data: data?.map((item: IFleetAnalysisVehicle) => ({
                    label: item.vehicleName,
                    value: item.averageDistance.perTrip,
                })),
            },
        ];

        const averageDistancePerDayDatasets: Dataset[] = [
            {
                label: t("average-distance-title"),
                data: data?.map((item: IFleetAnalysisVehicle) => ({
                    label: item.vehicleName,
                    value: item.averageDistance.perDay,
                })),
            },
        ];

        const longestDistancesPerTripDatasets: Dataset[] = [
            {
                label: t("longest-distance-label-1"),
                data: [],
                backgroundColor: fiveColorScheme[0],
            },
            {
                label: t("longest-distance-label-2"),
                data: [],
                backgroundColor: fiveColorScheme[1],
            },
            {
                label: t("longest-distance-label-3"),
                data: [],
                backgroundColor: fiveColorScheme[2],
            },
            {
                label: t("longest-distance-label-4"),
                data: [],
                backgroundColor: fiveColorScheme[3],
            },
            {
                label: t("longest-distance-label-5"),
                data: [],
                backgroundColor: fiveColorScheme[4],
            },
        ];

        const longestDistancesPerDayDatasets: Dataset[] = [
            {
                label: t("longest-distance-label-1"),
                data: [],
                backgroundColor: fiveColorScheme[0],
            },
            {
                label: t("longest-distance-label-2"),
                data: [],
                backgroundColor: fiveColorScheme[1],
            },
            {
                label: t("longest-distance-label-3"),
                data: [],
                backgroundColor: fiveColorScheme[2],
            },
            {
                label: t("longest-distance-label-4"),
                data: [],
                backgroundColor: fiveColorScheme[3],
            },
            {
                label: t("longest-distance-label-5"),
                data: [],
                backgroundColor: fiveColorScheme[4],
            },
        ];

        const distanceSegmentsPerTripDatasets: Dataset[] = [
            {
                label: t("distance-segment-label-1"),
                data: [],
                backgroundColor: fiveColorScheme[0],
            },
            {
                label: t("distance-segment-label-2"),
                data: [],
                backgroundColor: fiveColorScheme[1],
            },
            {
                label: t("distance-segment-label-3"),
                data: [],
                backgroundColor: fiveColorScheme[2],
            },
            {
                label: t("distance-segment-label-4"),
                data: [],
                backgroundColor: fiveColorScheme[3],
            },
            {
                label: t("distance-segment-label-5"),
                data: [],
                backgroundColor: fiveColorScheme[4],
            },
        ];

        const distanceSegmentsPerDayDatasets: Dataset[] = [
            {
                label: t("distance-segment-label-1"),
                data: [],
                backgroundColor: fiveColorScheme[0],
            },
            {
                label: t("distance-segment-label-2"),
                data: [],
                backgroundColor: fiveColorScheme[1],
            },
            {
                label: t("distance-segment-label-3"),
                data: [],
                backgroundColor: fiveColorScheme[2],
            },
            {
                label: t("distance-segment-label-4"),
                data: [],
                backgroundColor: fiveColorScheme[3],
            },
            {
                label: t("distance-segment-label-5"),
                data: [],
                backgroundColor: fiveColorScheme[4],
            },
        ];

        const businessPrivateDistanceDatasets: Dataset[] = [
            {
                label: t("business-private-label-1"),
                data: [],
                backgroundColor: twoColorScheme[0],
            },
            {
                label: t("business-private-label-2"),
                data: [],
                backgroundColor: twoColorScheme[1],
            },
        ];

        const businessPrivatePerTripDatasets: Dataset[] = [
            {
                label: t("business-private-label-1"),
                data: [],
                backgroundColor: twoColorScheme[0],
            },
            {
                label: t("business-private-label-2"),
                data: [],
                backgroundColor: twoColorScheme[1],
            },
        ];

        const drivingParkingDataset: Dataset[] = [
            {
                label: t("driving-parking-label"),
                data: [],
                backgroundColor: twoColorScheme[0],
            },
        ];
        data.map((vehicle: IFleetAnalysisVehicle, i: number) => {
            const vehicleLabel = vehicle.vehicleName;

            businessPrivatePerTripDatasets[0].data.push({
                value: vehicle?.businessUsageShare.trips,
                label: vehicleLabel,
            });

            businessPrivateDistanceDatasets[0].data.push({
                value: vehicle?.businessUsageShare.distance,
                label: vehicleLabel,
            });

            distanceSegmentsPerTripDatasets[0].data.push({
                value: vehicle?.distanceLengthSegments?.perTrip?.to9Km,
                label: vehicleLabel,
            });
            distanceSegmentsPerTripDatasets[1].data.push({
                value: vehicle?.distanceLengthSegments?.perTrip?.from10To49Km,
                label: vehicleLabel,
            });
            distanceSegmentsPerTripDatasets[2].data.push({
                value: vehicle?.distanceLengthSegments?.perTrip?.from50To99Km,
                label: vehicleLabel,
            });
            distanceSegmentsPerTripDatasets[3].data.push({
                value: vehicle?.distanceLengthSegments?.perTrip?.from100To299Km,
                label: vehicleLabel,
            });
            distanceSegmentsPerTripDatasets[4].data.push({
                value: vehicle?.distanceLengthSegments?.perTrip?.from300Km,
                label: vehicleLabel,
            });

            distanceSegmentsPerDayDatasets[0].data.push({
                value: vehicle?.distanceLengthSegments.perDay?.to9Km,
                label: vehicleLabel,
            });
            distanceSegmentsPerDayDatasets[1].data.push({
                value: vehicle?.distanceLengthSegments.perDay?.from10To49Km,
                label: vehicleLabel,
            });
            distanceSegmentsPerDayDatasets[2].data.push({
                value: vehicle?.distanceLengthSegments.perDay?.from50To99Km,
                label: vehicleLabel,
            });
            distanceSegmentsPerDayDatasets[3].data.push({
                value: vehicle?.distanceLengthSegments.perDay?.from100To299Km,
                label: vehicleLabel,
            });
            distanceSegmentsPerDayDatasets[4].data.push({
                value: vehicle?.distanceLengthSegments.perDay?.from300Km,
                label: vehicleLabel,
            });

            drivingParkingDataset[0].data.push({
                value: vehicle.drivingShare,
                label: vehicleLabel,
            });

            longestDistancesPerDayDatasets[0].data.push({
                value: vehicle?.longestDistances.perDay?.[0],
                label: vehicleLabel,
            });
            longestDistancesPerDayDatasets[1].data.push({
                value: vehicle?.longestDistances.perDay?.[1],
                label: vehicleLabel,
            });
            longestDistancesPerDayDatasets[2].data.push({
                value: vehicle?.longestDistances.perDay?.[2],
                label: vehicleLabel,
            });
            longestDistancesPerDayDatasets[3].data.push({
                value: vehicle?.longestDistances.perDay?.[3],
                label: vehicleLabel,
            });
            longestDistancesPerDayDatasets[4].data.push({
                value: vehicle?.longestDistances.perDay?.[4],
                label: vehicleLabel,
            });

            longestDistancesPerTripDatasets[0].data.push({
                value: vehicle?.longestDistances.perTrip?.[0],
                label: vehicleLabel,
            });
            longestDistancesPerTripDatasets[1].data.push({
                value: vehicle?.longestDistances.perTrip?.[1],
                label: vehicleLabel,
            });
            longestDistancesPerTripDatasets[2].data.push({
                value: vehicle?.longestDistances.perTrip?.[2],
                label: vehicleLabel,
            });
            longestDistancesPerTripDatasets[3].data.push({
                value: vehicle?.longestDistances.perTrip?.[3],
                label: vehicleLabel,
            });
            longestDistancesPerTripDatasets[4].data.push({
                value: vehicle?.longestDistances.perTrip?.[4],
                label: vehicleLabel,
            });
        });

        return {
            dataSets: {
                averageDistancePerTripDatasets,
                averageDistancePerDayDatasets,
                longestDistancesPerTripDatasets,
                longestDistancesPerDayDatasets,
                distanceSegmentsPerTripDatasets,
                distanceSegmentsPerDayDatasets,
                businessPrivatePerTripDatasets,
                businessPrivateDistanceDatasets,
                drivingParkingDataset,
                yearlyMileageDataset,
            },
        };
    };

    const sortedData = (
        data: IFleetAnalysisVehicle[],
        sortCrescentMap: any,
        spliceBy?: number
    ) => {
        const {
            dataSets: {
                averageDistancePerTripDatasets,
                averageDistancePerDayDatasets,
                longestDistancesPerTripDatasets,
                longestDistancesPerDayDatasets,
                distanceSegmentsPerTripDatasets,
                distanceSegmentsPerDayDatasets,
                businessPrivatePerTripDatasets,
                businessPrivateDistanceDatasets,
                drivingParkingDataset,
                yearlyMileageDataset,
            },
        } = handleData(data);

        sortCrescentMap.averageDistance
            ? (() => {
                sortDataAscending(averageDistancePerTripDatasets);
                sortDataAscending(averageDistancePerDayDatasets);
            })()
            : (() => {
                sortDataDescending(averageDistancePerTripDatasets);
                sortDataDescending(averageDistancePerDayDatasets);
            })();

        sortCrescentMap.yearlyMileage
            ? (() => {
                sortDataAscending(yearlyMileageDataset);
            })()
            : (() => {
                sortDataDescending(yearlyMileageDataset);
            })();

        sortCrescentMap.longestDistance
            ? (() => {
                sortSegmentedDataAscending(
                    longestDistancesPerTripDatasets,
                    longestDistancesPerDayDatasets
                );
            })()
            : (() => {
                sortSegmentedDataDescending(
                    longestDistancesPerTripDatasets,
                    longestDistancesPerDayDatasets
                );
            })();

        sortCrescentMap.distanceSegment
            ? (() => {
                sortSegmentedDataAscending(
                    distanceSegmentsPerTripDatasets,
                    distanceSegmentsPerDayDatasets
                );
            })()
            : (() => {
                sortSegmentedDataDescending(
                    distanceSegmentsPerTripDatasets,
                    distanceSegmentsPerDayDatasets
                );
            })();

        sortCrescentMap.businessPrivate
            ? (() => {
                sortDataAscending(businessPrivatePerTripDatasets);
                computeSortedPrivatePercentage(businessPrivatePerTripDatasets);

                sortDataAscending([businessPrivateDistanceDatasets[0]]);
                computeSortedPrivatePercentage(businessPrivateDistanceDatasets);
            })()
            : (() => {
                sortDataDescending(businessPrivatePerTripDatasets);
                computeSortedPrivatePercentage(businessPrivatePerTripDatasets);

                sortDataDescending(businessPrivateDistanceDatasets);
                computeSortedPrivatePercentage(businessPrivateDistanceDatasets);
            })();

        sortCrescentMap.drivingParking
            ? (() => {
                sortDataAscending(drivingParkingDataset);
            })()
            : (() => {
                sortDataDescending(drivingParkingDataset);
            })();

        const datasets = {
            averageDistancePerTripDatasets,
            averageDistancePerDayDatasets,
            longestDistancesPerTripDatasets,
            longestDistancesPerDayDatasets,
            distanceSegmentsPerTripDatasets,
            distanceSegmentsPerDayDatasets,
            businessPrivatePerTripDatasets,
            businessPrivateDistanceDatasets,
            drivingParkingDataset,
            yearlyMileageDataset,
        };

        if (spliceBy) {
            Object.entries(datasets).map(([_, dataset]) => {
                dataset.map((content) => content.data.splice(spliceBy));
            });
        }

        return {
            dataSets: datasets,
        };
    };

    const processDataForChart = (dataObject: any) => {
        if (!dataObject?.trip) {
            return {
                chartData: dataObject?.map((dt: any) => ({
                    ...dt,
                    data: dt.data.map((d: any) => d.value),
                })),
                labels: dataObject?.map((dt: any) =>
                    dt.data.map((d: any) => d.label)
                )[0],
            };
        }

        const tripData = [
            ...dataObject?.trip?.map((dt: any) => ({
                ...dt,
                data: dt.data.map((d: any) => d.value),
                barPercentage: 1.0,
            })),
        ];

        const dayData = [
            ...dataObject?.day?.map((dt: any) => ({
                ...dt,
                data: dt.data.map((d: any) => d.value),
                barPercentage: 1.0,
            })),
        ];

        const tripLabels = [
            ...dataObject?.trip?.map((dt: any) => dt.data.map((d: any) => d.label)),
        ][0];

        const dayLabels = [
            ...dataObject?.day?.map((dt: any) => dt.data.map((d: any) => d.label)),
        ][0];

        return { tripData, dayData, tripLabels, dayLabels };
    };

    return {
        handleData,
        sortedData,
        processDataForChart,
    };
}
