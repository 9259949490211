import { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { defineColorScheme } from "../../../../../../../../common/components/Charts/ChartColors";
import ChartButtonHeader from "../../../../../../../../common/components/Charts/ChartButtonHeader";
import { NoWorkplaceDataAvailable } from "../NoWorkplaceDataPlaceholder"


export default function WorkplaceParkingOccupationChart({ data, isModal }: { data: any, isModal?: boolean }) {
    const { t } = useTranslation("parkingAnalysis");

    const [isTime, setIsTime] = useState(false);
    const [location, setLocation] = useState(0);

    const dataToUse = JSON.parse(JSON.stringify({ ...data }));

    const { dataTime, dataStays } = dataToUse;

    const selectedDataSet = isTime ? dataTime : dataStays;

    if (!Array.isArray(selectedDataSet) || selectedDataSet.length === 0) return <NoWorkplaceDataAvailable noDataText="No Locations found" />

    const selectedLocationData = selectedDataSet?.[location]?.data;

    const totalSum = selectedLocationData?.reduce((totalValue: any, currentValue: any) => totalValue + currentValue);

    const locationHasData = totalSum > 0;

    const parkingWorkplaceOccupationTimesLabels = [
        t("workplace-parking-occupation-times-label-1"),
        t("workplace-parking-occupation-times-label-2"),
        t("workplace-parking-occupation-times-label-3"),
        t("workplace-parking-occupation-times-label-4"),
    ];

    const parkingWorkplaceOccupationStaysLabels = [
        t("workplace-parking-occupation-stays-label-1"),
        t("workplace-parking-occupation-stays-label-2"),
        t("workplace-parking-occupation-stays-label-3"),
        t("workplace-parking-occupation-stays-label-4"),
        t("workplace-parking-occupation-stays-label-5"),
    ];

    const graphData = {
        labels: isTime
            ? parkingWorkplaceOccupationTimesLabels
            : parkingWorkplaceOccupationStaysLabels,
        datasets: [
            {
                backgroundColor: defineColorScheme(5),
                data: selectedLocationData,
            },
        ],
    };

    return (
        <div className="flex flex-col h-full">
            <div className="flex justify-between h-10 mb-6">
                <ChartButtonHeader
                    button1={{
                        onClick: () => setIsTime(false),
                        label: t("workplace-stays-button"),
                    }}
                    button2={{
                        onClick: () => setIsTime(true),
                        label: t("workplace-idle-times-button"),
                    }}
                    checkProp={!isTime}
                />

                <select
                    className="h-10 text-sm rounded"
                    onChange={(e: any) => setLocation(e.target.value)}
                >
                    {selectedDataSet?.map((location: any, i: number) => {
                        return (
                            <option key={i} value={i}>
                                {location?.locationName}
                            </option>
                        );
                    })}
                </select>
            </div>
            <span className="mb-6 text-sm">
                {`${isTime
                    ? t("workplace-parking-occupation-description-times")
                    : t("workplace-parking-occupation-description-stays")
                    } ${selectedDataSet?.[location]?.locationName}`}
            </span>
            <div
                className="flex-grow flex items-center justify-center"
                style={{ minHeight: isModal ? 0 : "370px" }}
            >
                {locationHasData ?
                    <Doughnut
                        options={{
                            cutout: '50%',
                            plugins: {
                                tooltip: {
                                    callbacks: {
                                        label: (tooltipItem) => `${Math.round((tooltipItem.raw as number / totalSum) * 100)}%`,
                                    },
                                },
                            },
                        }}
                        data={{
                            labels: isTime ? parkingWorkplaceOccupationTimesLabels : parkingWorkplaceOccupationStaysLabels,
                            datasets: [{ backgroundColor: defineColorScheme(5), data: selectedLocationData }],
                        }}
                    /> : <NoWorkplaceDataAvailable noDataText="No data found for location" />
                }
            </div>
        </div>
    );
}
