import { Circle, MapContainer, Marker, TileLayer, useMap } from 'react-leaflet';
import L, { LatLngExpression } from 'leaflet';

import { Dispatch, MutableRefObject, SetStateAction, useEffect, useRef } from 'react';
import { ReactComponent as HomeLocation } from '../../../../../../assets/driver_overview/home-grey.svg';
import { ReactComponent as WorkLocation } from '../../../../../../assets/driver_overview/workplace-grey.svg';
import { usePublicChargingData } from '../hooks/usePublicChargingData';
import { CentralMarker, PublicChargersMarkers } from './markers';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../../../../electrify_frontend_common/components/Dropdown';
import { Spinner } from '../../../../../../electrify_frontend_common/components/Spinner';


function FlyToMap({ selectedAddressId, setCurrentBounds, boundRef }: {
    selectedAddressId?: string;
    setCurrentBounds: Dispatch<SetStateAction<{ northEast: { lat: number; lon: number }; southWest: { lat: number; lon: number }; } | null>>;
    boundRef: MutableRefObject<L.Circle<any> | null>;
}) {
    const map = useMap();

    map.on('moveend', () => {
        const bounds = map.getBounds();

        setCurrentBounds({
            northEast: { lat: bounds.getNorthEast().lat, lon: bounds.getNorthEast().lng },
            southWest: { lat: bounds.getSouthWest().lat, lon: bounds.getSouthWest().lng },
        });
    });

    useEffect(() => {
        if (boundRef.current) map.fitBounds(boundRef.current.getBounds());
    }, [selectedAddressId]);

    return null;
}

export function PublicCharging({ vehicleId }: { vehicleId?: string }) {
    const {
        chargingPoints,
        addresses,
        homeAddressesLoading,
        workplaceLocationsLoading,
        selectedAddress,
        setSelectedAddress,
        selectorPlaceholder,
        currentBounds,
        setCurrentBounds,
    } = usePublicChargingData(vehicleId);

    const circleRef = useRef<L.Circle | null>(null);
    const { t } = useTranslation('driverOverview');

    if (!selectedAddress?.lat || !selectedAddress.lon) return null;

    const mapCenterPoint = [selectedAddress.lat + 0.0002, selectedAddress.lon] as LatLngExpression

    const circleMarkOne = L.divIcon({
        html: '250m',
        className: 'bg-transparent text-[1.2em] text-Blueberry-dark-default',
    });
    const circleMarkTwo = L.divIcon({
        html: '500m',
        className: 'bg-transparent text-[1.2em] text-Blueberry-dark-default',
    });

    return <div className="flex flex-col w-full h-full">
        <h1 className=" text-xl text-Black">{t('public-charging.title')}</h1>
        <div className="flex w-full mt-6 z-50">
            <Dropdown
                className="w-full z-50"
                buttonClassName="border border-Grey-tint rounded py-0 flex w-full"
                placeholder={selectorPlaceholder}
                data={addresses.map(a => ({
                    key: <div className="flex items-center justify-between">
                        <div className="mr-2">{a.locationType === 'WORKPLACE' ? <WorkLocation /> : <HomeLocation />}</div>
                        {a.name}
                    </div>,
                    onClick: () => setSelectedAddress(a),
                }))}
            />
        </div>
        <div className="border border-Grey-tint rounded h-full mt-3 z-40">
            {homeAddressesLoading || workplaceLocationsLoading ? <Spinner /> : addresses?.length > 0 ? <MapContainer
                id="map"
                style={{ height: '100%', width: '100%' }}
                center={mapCenterPoint}
                zoom={15}
                zoomSnap={0}
                zoomControl={false}
                doubleClickZoom={false}
                scrollWheelZoom={false}
                dragging={false}
            >
                <TileLayer
                    attribution='&copy; <a href="https://carto.com/attributions">CARTO</a>'
                    url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                />
                <Circle
                    className="cursor-auto"
                    ref={circleRef}
                    center={[selectedAddress.lat, selectedAddress.lon]}
                    pathOptions={{ color: '#B886A6', fillColor: '#B886A6', stroke: false }}
                    radius={500}
                />
                <Circle
                    className="cursor-auto"
                    center={[selectedAddress.lat, selectedAddress.lon]}
                    pathOptions={{ fillColor: '#961F63', stroke: false }}
                    radius={250}
                />
                <Marker
                    icon={circleMarkOne}
                    position={[selectedAddress.lat - 0.0007, selectedAddress.lon + 0.0012]}
                />
                <Marker
                    icon={circleMarkTwo}
                    position={[selectedAddress.lat - 0.0025, selectedAddress.lon + 0.0035]}
                />

                <CentralMarker selectedAddress={selectedAddress} />

                {chargingPoints.length > 0 && currentBounds
                    ? chargingPoints?.map(cp => <PublicChargersMarkers
                        key={cp.id}
                        cp={cp}
                        centerPoint={mapCenterPoint}
                        currentBounds={currentBounds}
                    />) : null}
                <FlyToMap
                    selectedAddressId={selectedAddress.id}
                    setCurrentBounds={setCurrentBounds}
                    boundRef={circleRef}
                />

                {/* Uncomment to access the map's scale */}
                {/* <ScaleControl /> */}
            </MapContainer> : null}
        </div>
    </div>;
}
