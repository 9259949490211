import { ReactComponent as EmissionsIcon } from "../../../../../../../assets/electrification-planner-white.svg";
import { ReactComponent as RangeIcon } from "../../../../../../../assets/driver_settings/range.svg";
import { ReactComponent as CapacityIcon } from "../../../../../../../assets/driver_settings/capacity.svg";
import { ReactComponent as ArrowDown } from "../../../../../../../assets/overview-arrow-down.svg";
import { ReactComponent as ArrowUp } from "../../../../../../../assets/overview-arrow-up.svg";
import { useMemo } from "react";
import { useLayout } from "../../../../../../../hooks/state/useLayout";


const NoBrandImagePlaceHolder = () => (
    <div className="w-10 h-10 rounded-full bg-Grey-light bg-opacity-40 mr-3" />
  );
  
  export enum InfoType {
    TOTAL_DISTANCE = "TOTAL_DISTANCE",
    COSTS = "COSTS",
    EMISSIONS = "EMISSIONS",
    ELECTRIFICATION = "ELECTRIFICATION",
  }
  
  export function InfoSquare({
    type,
    value,
    comparison,
    customLabel,
  }: {
    type: InfoType;
    value: string;
    comparison?: number;
    customLabel?: string;
  }) {
    const { currencySymbol } = useLayout();
  
    const bgColor = {
      [InfoType.TOTAL_DISTANCE]: "bg-Blueberry-dark-default",
      [InfoType.ELECTRIFICATION]: "bg-Blueberry-dark-default",
      [InfoType.COSTS]: "bg-Blueberry-light-shade",
      [InfoType.EMISSIONS]: "bg-Blueberry-light-default",
    };
  
    const icon = {
      [InfoType.TOTAL_DISTANCE]: <RangeIcon />,
      [InfoType.ELECTRIFICATION]: <RangeIcon />,
      [InfoType.COSTS]: <CapacityIcon />,
      [InfoType.EMISSIONS]: <EmissionsIcon />,
    };
  
    const label = {
      [InfoType.TOTAL_DISTANCE]: "km",
      [InfoType.ELECTRIFICATION]: "%",
      [InfoType.COSTS]: `${currencySymbol}/year`,
      [InfoType.EMISSIONS]: "kg/year",
    };
  
    const arrow = useMemo(() => {
      if (comparison === undefined) return;
      else if (comparison < 0) return <ArrowDown />;
      else if (comparison > 0) return <ArrowUp />;
      return;
    }, [comparison]);
  
    const color = useMemo(() => {
      if (comparison === undefined) return "";
      else if (comparison < 0) return "bg-green-600";
      else if (comparison > 0) return "bg-red-500";
      return "bg-Grey-dark";
    }, [comparison]);
  
    return (
      <div
        className={`flex flex-col h-[75px] w-[75px] items-center justify-center text-white text-sm rounded-md mt-1 mx-1 ${bgColor[type]} px-1`}
      >
        {icon[type]}
        <div className="mt-1 px-1 flex w-full text-center justify-center font-bold">
          {value} {customLabel ? customLabel : label[type]}
        </div>
  
        {comparison !== undefined ? (
          <div className={`px-1 flex items-center rounded text-sm ${color}`}>
            {comparison} % {arrow}
          </div>
        ) : null}
      </div>
    );
  }
  
  export function NoBrandPlaceholder() {
    return (
      <div className="flex items-center ">
        <NoBrandImagePlaceHolder />
        <div>
          <div className="w-12 h-[18px] rounded-sm bg-Grey-light bg-opacity-40 mb-2" />
          <div className="w-32 h-[14px] rounded-sm bg-Grey-light bg-opacity-40" />
        </div>
      </div>
    );
  }
  
