/* react imports */

/* third party imports */
import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

/* local imports */
import distanceIcon from "../../../../../assets/overview-distance.svg";
import { ReactComponent as InformationIcon } from "../../../../../assets/information-icon-blueberry.svg";
import { useEffect } from "react";
import { ReportPanel } from "./ReportPanel";
import { GET_FLEET_RESULTS_OVERVIEW } from "../../../../../services/graphql/fleet/queries";
import { useLayout } from "../../../../../hooks/state/useLayout";
import { Tooltip } from "../../../../../electrify_frontend_common/components/Tooltip";



export function Overview() {
    const { t, i18n } = useTranslation("overview");
    const language = i18n.language;

    const { data: overviewData } = useQuery(GET_FLEET_RESULTS_OVERVIEW, {
        onError: (err) => console.log("ERRRR>>>", err),
    });

    const { setHeader, localeCode } = useLayout();

    useEffect(() => {
        setHeader({
            defaultHeaderTitle: t("page-title"),
            defaultHeaderDescription: t("page-subtitle"),
        });
    }, [language]);

    const fleetResultsOverviewData = overviewData?.fleetResultsOverview || {};
    const currentFleet = fleetResultsOverviewData?.currentFleet || {};
    const currentFleetVehicleTypes = currentFleet?.vehicleTypes || {};
    const simulatedFleet = fleetResultsOverviewData?.simulatedFleet || {};
    const simulatedFleetVehicleTypes = simulatedFleet?.vehicleTypes || {};

    //   return null;
    return (
        <>
            <div className="xl:flex xl:flex-row w-full">
                {/* Left panel */}
                <ReportPanel
                    className={"border-r border-solid border-Grey-tint"}
                    userFleet
                    vehicleTypes={currentFleetVehicleTypes}
                    fleetData={currentFleet}
                    textContent={{
                        title: t("current.analysis"),
                        fleet: t("current.fleet"),
                        vehicles: t("current.vehicles"),
                        combustion: t("current.combustion"),
                        hybrid: t("current.hybrid"),
                        electric: t("current.electric"),
                        cost: t("current.cost"),
                        co2: t("current.co2"),
                    }}
                    tooltips={[
                        <Tooltip
                            content={<div className="whitespace-nowrap">{t("current.fuel-and-energy-tooltip")}</div>}
                            placement="top-start"
                        >
                            <InformationIcon />
                        </Tooltip>,
                        <Tooltip
                            content={<div className="whitespace-nowrap">{t("current.co2-tooltip")}</div>}
                            placement="top-start"
                        >
                            <InformationIcon />
                        </Tooltip>,
                    ]}
                    specificMetric={
                        <div className="flex mb-10">
                            <div className="">
                                <img src={distanceIcon} alt="dashboard-icon" />
                            </div>
                            <div className=" ml-4">
                                <p className="text-xs	text-Grey-shade font-bold	">
                                    {t("current.distance")}
                                </p>
                                <div className="flex items-center">
                                    <p className="text-3xl font-extrabold text-black">
                                        {currentFleet?.yearlyMileage >= 0
                                            ? currentFleet?.yearlyMileage.toLocaleString(
                                                localeCode
                                            )
                                            : ""}
                                        <span className="text-xl ml-2 font-light">
                                            km
                                        </span>
                                    </p>
                                    <div className="ml-2 mt-2">
                                        <Tooltip
                                            content={<div className="whitespace-nowrap">{t("current.distance-tooltip")}</div>}
                                            placement="top-start"
                                        >
                                            <InformationIcon />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                />

                {/* Right panel */}
                <ReportPanel
                    vehicleTypes={simulatedFleetVehicleTypes}
                    fleetData={simulatedFleet}
                    textContent={{
                        title: t("simulation.simulation"),
                        fleet: t("simulation.fleet"),
                        vehicles: t("simulation.vehicles"),
                        combustion: t("simulation.combustion"),
                        hybrid: t("simulation.hybrid"),
                        electric: t("simulation.electric"),
                        cost: t("simulation.cost"),
                        co2: t("simulation.co2"),
                    }}
                    specificMetric={
                        <div className="flex mb-10">
                            <div className="">
                                <img src={distanceIcon} alt="dashboard-icon" />
                            </div>
                            <div className="ml-4">
                                <p className="text-xs	text-Grey-shade font-bold	">
                                    {t("simulation.electrification")}
                                </p>
                                <div className="flex items-center">
                                    <p className="text-3xl font-extrabold text-Blueberry-dark-default ">
                                        {simulatedFleet?.electrificationFactor}{" "}
                                        <span className="text-xl font-light text-Blueberry-dark-default">
                                            {t(
                                                "simulation.electrification-factor"
                                            )}
                                        </span>
                                    </p>
                                    <div className="ml-2 mt-2">
                                        <Tooltip
                                            content={<div className=" w-64">{t("simulation.electrification-tooltip")}</div>}
                                            placement="bottom-end"
                                        >
                                            <InformationIcon />
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                />
            </div>
        </>
    );
}
