import { ReactComponent as NoWorkplaceAddresses } from "../../../../../../assets/no-work-addresses.svg";
import { InformationBanner } from "../utils/InformationBanner";

import { ReactComponent as PlusIcon } from "../../../../../../assets/plus-icon-white.svg";

import { PrevButton } from "../utils/PrevButton";
import { NextButton } from "../utils/NextButton";
import { useQuery } from "@apollo/client";

import { useState } from "react";

import { ReactComponent as PlusIconGrey } from "../../../../../../assets/plus-icon.svg";
import { useTranslation } from "react-i18next";
import { VehicleWithYearlyStats } from "../../../../../../@types/vehicle";
import { GET_ADDRESSES } from "../../../../../../services/graphql/fleet/queries";
import LocationModal from "../../../../../../common/components/Address/AddressCard/modals/LocationModal";
import { Button } from "../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../electrify_frontend_common/components/Buttonv2/types";
import AddressCard from "../../../../../../common/components/Address/AddressCard";
import { Location } from "../../../../../../@types/settings";


export function WorkplaceLocations({
  step,
  nextStep,
  prevStep,
  vehicle,
}: {
  step: number;
  nextStep: () => void;
  prevStep: () => void;
  vehicle?: VehicleWithYearlyStats;
}) {
  const { t } = useTranslation("driverOnboarding");

  const [locationsModalOpen, setLocationsModalOpen] = useState(false);

  const { data: workplaceLocations, refetch: refetchWorkplaceLocations } =
    useQuery<{ addresses: Location[] }>(GET_ADDRESSES, {
      variables: {
        locationType: "WORKPLACE",
        vehicleId: vehicle?.id,
      },
    });

  return (
    <>
      {locationsModalOpen ? (
        <LocationModal
          isOpen={locationsModalOpen}
          className="w-[1000px]"
          vehicleId={vehicle?.id}
          closeModal={() => {
            refetchWorkplaceLocations();
            setLocationsModalOpen(false);
          }}
          locationType={"WORKPLACE"}
          isDriver={true}
        />
      ) : null}
      <div className="p-10 w-[1000px]">
        <div className="text-xl">
          <span className="text-Blueberry-dark-default ">
            {t("step")} {step}:
          </span>{" "}
          {t("step-work-location.title-1")}{" "}
          <span className="font-bold">{t("step-work-location.title-2")}</span>{" "}
          {t("step-work-location.title-3")}
        </div>
        <div className="flex items-center justify-between text-sm text-Grey-dark py-2">
          <div className="flex w-2/3">{t("step-work-location.message")}</div>
          {workplaceLocations?.addresses?.length ? (
            <Button
              onClick={() => setLocationsModalOpen(true)}
              variant={ButtonVariant.TERTIARY}
              size={ButtonSize.MEDIUM}
            >
              <div className="flex">
                <PlusIconGrey className="mr-2" />
                <span>{t("step-work-location.button-text")}</span>
              </div>
            </Button>
          ) : null}
        </div>

        {!workplaceLocations?.addresses.length ? (
          <div className="flex flex-col bg-Grey-background justify-center items-center w-full mt-2 py-20">
            <NoWorkplaceAddresses className="mb-4" />
            <Button
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.LARGE}
              onClick={() => setLocationsModalOpen(true)}
            >
              <div className="flex items-center">
                <PlusIcon className="mr-2" />{" "}
                {t("step-work-location.button-text")}
              </div>
            </Button>
          </div>
        ) : (
          <div className="grid grid-cols-3 gap-2 mt-4">
            {workplaceLocations?.addresses?.map((item: Location) => {
              return (
                <AddressCard
                  address={item}
                  key={item.id}
                  refetch={refetchWorkplaceLocations}
                  locationType={"WORKPLACE"}
                />
              );
            })}
          </div>
        )}

        {!workplaceLocations?.addresses.length ? (
          <InformationBanner tooltipContent="Workplace locations info" />
        ) : null}

        <div className="flex justify-between mt-10">
          <PrevButton text={t("back")} onClick={prevStep} />
          <NextButton
            text={workplaceLocations?.addresses?.length ? t("next") : t("skip")}
            active={!!workplaceLocations?.addresses?.length}
            onClick={nextStep}
          />
        </div>
      </div>
    </>
  );
}
