

export enum PasswordResetErrors {
    INVALID_TOKEN ='INVALID_TOKEN',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    PASSWORDS_DONT_MATCH = 'PASSWORDS_DONT_MATCH',
    KEYCLOAK_ERROR = 'KEYCLOAK_ERROR'
};


export enum EmailErrors  {
    VALIDATION_ERROR =  'VALIDATION_ERROR',
    USER_NOT_FOUND = 'USER_NOT_FOUND',
    ALREADY_VALIDATED =  'ALREADY_VALIDATED',
    SENDING_ERROR = 'SENDING_ERROR'
};