import { ReactComponent as SuccessImage } from "../../../../../../assets/success-image.svg";

import { ReactComponent as ArrowRightWhite } from "../../../../../../assets/arrow_right_strong_white.svg";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../../../../../hooks/state/useUserData";
import { FINISH_ONBOARDING } from "../../../../../../services/graphql/driver/mutations";
import { Button } from "../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../electrify_frontend_common/components/Buttonv2/types";
import { CloseIcon } from "../../../../../../electrify_frontend_common/icons/CloseIcon";

export function FinalStep({ close, completeButtonText }: { close: () => void, completeButtonText?: string }) {
  const { t } = useTranslation("driverOnboarding");
  const { refreshUserAccountInfo } = useUserData();
  const navigate = useNavigate();

  const [onboardUser] = useMutation<{ onboardUser: { id?: string } }>(
    FINISH_ONBOARDING,
    {
      onCompleted: async () => {
        await refreshUserAccountInfo();
        close();
        navigate('/');
      },
    }
  );

  return (
    <div className="p-10 w-[1000px]">
      <div className="flex justify-end">
        <CloseIcon onClick={()=> close()} />
      </div>
      <div className="flex flex-col w-full items-center justify-center">
        <div className="flex flex-col items-center justify-center w-2/3">
          <SuccessImage />
          <div className=" text-3xl text-center mt-3">
            {t("step-final.message-1")}
          </div>
          <div className="text-Grey-dark mt-8">{t("step-final.message-2")}</div>
          <Button
            className="mt-8 mb-12"
            onClick={() => {
              onboardUser();
            }}
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.LARGE}
          >
            <div className="flex items-center text-lg">
              {completeButtonText ? completeButtonText : t("step-final.button-text")}
              <ArrowRightWhite className="ml-2" />
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}
