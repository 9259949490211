


export function NoValidTariffForSelectedProduct() {




    return (
        <div className="flex w-full h-full items-center justify-center">
            Apologies, the selected product does not have a valid tariff at the moment... Please contact support or pick another product...
        </div>
    )



}