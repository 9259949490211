import { Dispatch, SetStateAction } from "react";
import { ReactComponent as PetrolIcon } from "../../../../../../../assets/petrol-icon.svg";
import { useTranslation } from "react-i18next";
import { useLayout } from "../../../../../../../hooks/state/useLayout";

const valueIsNumeric =(v: number | null | undefined) : boolean => {

  return v !== null && v!== undefined;
}

export function SettingsInput({
  label,
  units,
  value,
  setValue,
  defaultValue,
  saveSettings,
}: {
  label: string;
  units: string;
  defaultValue: number;
  setValue: Dispatch<SetStateAction<number | null | undefined>>;
  saveSettings: () => void;
  value?: number | null;
}) {
  const { localeCode } = useLayout();

  return (
    <div className="flex flex-col items-center w-full">
      <span className="mb-4 text-sm font-bold text-Grey-dark">{label}</span>
      <div className="flex items-center border border-solid border-Grey-tint text-xs text-center rounded h-10 py-1 mx-2 bg-white">
        <input
          className="text-center ml-3 focus:ring-0 w-4/6 h-3/4 text-black border-none"
          lang={localeCode}
          type="number"
          min="0"
          step="0.01"
          value={valueIsNumeric(value) ? value as number : ""}
          placeholder={defaultValue.toLocaleString(localeCode)}
          onInput={(e: any) => setValue(parseFloat(e.target.value))}
          onBlur={() => saveSettings()}
        />
        <div
          className={`ml-[-20px] text-md z-20 ${
            valueIsNumeric(value) ? "text-black" : "text-Grey-default"
          }`}
        >
          {units}
        </div>
      </div>
    </div>
  );
}

export function FuelDetails({
  energyAndFuelDetails,
}: {
  energyAndFuelDetails: any;
}) {
  
  const { t } = useTranslation("settingsSimulation");

  if (!energyAndFuelDetails.defaultPrices) return null;

  const priceLabel = `${energyAndFuelDetails.defaultPrices.currencySymbol} / l`;

  return (
    <div className="flex flex-col h-full w-full rounded-md border border-Grey-tint">
      <div className="h-2 bg-Grey-tint" />
      <div className="flex flex-col items-center py-4">
        <PetrolIcon />
        <span className="mt-2 text-lg font-medium">
          {t("fuel-energy-table.fuel.header")}
        </span>
      </div>
      <div className="py-4 px-5 flex flex-col pb-32  items-center border-t border-Grey-tint">
        <span className="font-medium text-lg mb-6">
          {t("fuel-energy-table.price")}
        </span>
        <div className="flex w-full justify-between ">
          <SettingsInput
            saveSettings={energyAndFuelDetails.saveFuelSettings}
            label={t("fuel-energy-table.fuel.petrol")}
            units={priceLabel}
            value={energyAndFuelDetails.petrolPrice}
            setValue={energyAndFuelDetails.setPetrolPrice}
            defaultValue={energyAndFuelDetails.defaultPrices.petrolPrice}
          />
          <SettingsInput
            saveSettings={energyAndFuelDetails.saveFuelSettings}
            label={t("fuel-energy-table.fuel.diesel")}
            units={priceLabel}
            value={energyAndFuelDetails.dieselPrice}
            setValue={energyAndFuelDetails.setDieselPrice}
            defaultValue={energyAndFuelDetails.defaultPrices.dieselPrice}
          />
        </div>
      </div>
      <div className="py-4 px-5 flex flex-col items-center border-t border-Grey-tint">
        <span className="font-medium text-lg mb-6">
          {t("fuel-energy-table.emissions")}
        </span>
        <div className="flex w-full justify-between">
          <SettingsInput
            saveSettings={energyAndFuelDetails.saveFuelSettings}
            label={t("fuel-energy-table.fuel.petrol")}
            units={"g / l"}
            value={energyAndFuelDetails.petrolCo2}
            setValue={energyAndFuelDetails.setPetrolCo2}
            defaultValue={energyAndFuelDetails.defaultPrices.petrolCo2Emissions}
          />
          <SettingsInput
            saveSettings={energyAndFuelDetails.saveFuelSettings}
            label={t("fuel-energy-table.fuel.diesel")}
            units={"g / l"}
            value={energyAndFuelDetails.dieselCo2}
            setValue={energyAndFuelDetails.setDieselCo2}
            defaultValue={energyAndFuelDetails.defaultPrices.dieselCo2Emissions}
          />
        </div>
      </div>
    </div>
  );
}
