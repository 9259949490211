


import { IElectrificationPlannerChargingSection } from "../../../../../@types/driver/electrificationPlanner";
import { NotApplicableWithExplanationModalContent } from "../components/Sections/ChargingSection/modals/NotApplicableWithExplanationModalContent";
import { ElectrificationPlannerChargingSectionSubSections, ElectrificationPlannerSections, Section } from "./Section";



type ChargingSectionSubsections = ElectrificationPlannerChargingSectionSubSections.ENERGY |
    ElectrificationPlannerChargingSectionSubSections.WALLBOX |
    ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING |
    ElectrificationPlannerChargingSectionSubSections.PUBLIC_CHARGING |
    ElectrificationPlannerChargingSectionSubSections.WORKPLACE_CHARGING |
    ElectrificationPlannerChargingSectionSubSections.PUBLIC_CHARGING;

export class ChargingSectionModel extends Section<ChargingSectionSubsections> {


    constructor(chargingSection: IElectrificationPlannerChargingSection) {

        const homeCharging = new Map([
            ['parkingSituation', chargingSection[ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING].parkingSituation],
            ['chargingSituation', {
                ...chargingSection[ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING].chargingSituation,
                modal: (close: () => void) => <NotApplicableWithExplanationModalContent
                    
                    sectionName={ElectrificationPlannerSections.CHARGING_SECTION}
                    subsectionName={ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING}
                    stepName='chargingSituation'
                    closeModal={close}
                />,
            }],
            ['employerSituation', chargingSection[ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING].employerSituation]
        ]);


        const wallbox = new Map([
            ['serviceProviderSelection', {
                ...chargingSection[ElectrificationPlannerChargingSectionSubSections.WALLBOX].serviceProviderSelection,
                modal: (close: () => void) => <NotApplicableWithExplanationModalContent
                    
                    sectionName={ElectrificationPlannerSections.CHARGING_SECTION}
                    subsectionName={ElectrificationPlannerChargingSectionSubSections.WALLBOX}
                    explainParent={{
                        sectionName: ElectrificationPlannerSections.CHARGING_SECTION,
                        subsectionName: ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING,
                        stepName: 'chargingSituation'
                    }}
                    stepName='serviceProviderSelection'
                    closeModal={close}
                />,
            }],
            ['onsiteCheck', {
                ...chargingSection[ElectrificationPlannerChargingSectionSubSections.WALLBOX].onsiteCheck,
                modal: (close: () => void) => <NotApplicableWithExplanationModalContent
                    
                    sectionName={ElectrificationPlannerSections.CHARGING_SECTION}
                    subsectionName={ElectrificationPlannerChargingSectionSubSections.WALLBOX}
                    explainParent={{
                        sectionName: ElectrificationPlannerSections.CHARGING_SECTION,
                        subsectionName: ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING,
                        stepName: 'chargingSituation'
                    }}
                    stepName='onsiteCheck'
                    closeModal={close}
                />,
            }],
            ['communityAssociationApproval', {
                ...chargingSection[ElectrificationPlannerChargingSectionSubSections.WALLBOX].communityAssociationApproval,
                modal: (close: () => void) => <NotApplicableWithExplanationModalContent
                    
                    sectionName={ElectrificationPlannerSections.CHARGING_SECTION}
                    subsectionName={ElectrificationPlannerChargingSectionSubSections.WALLBOX}
                    explainParent={{
                        sectionName: ElectrificationPlannerSections.CHARGING_SECTION,
                        subsectionName: ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING,
                        stepName: 'chargingSituation'
                    }}
                    stepName='communityAssociationApproval'
                    closeModal={close}
                />,
            }],
            ['wallboxSelection', {
                ...chargingSection[ElectrificationPlannerChargingSectionSubSections.WALLBOX].wallboxSelection,
                modal: (close: () => void) => <NotApplicableWithExplanationModalContent
                    
                    sectionName={ElectrificationPlannerSections.CHARGING_SECTION}
                    subsectionName={ElectrificationPlannerChargingSectionSubSections.WALLBOX}
                    explainParent={{
                        sectionName: ElectrificationPlannerSections.CHARGING_SECTION,
                        subsectionName: ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING,
                        stepName: 'chargingSituation'
                    }}
                    stepName='wallboxSelection'
                    closeModal={close}
                />,
            }],
            ['netOperatorApproval', {
                ...chargingSection[ElectrificationPlannerChargingSectionSubSections.WALLBOX].netOperatorApproval,
                modal: (close: () => void) => <NotApplicableWithExplanationModalContent
                    
                    sectionName={ElectrificationPlannerSections.CHARGING_SECTION}
                    subsectionName={ElectrificationPlannerChargingSectionSubSections.WALLBOX}
                    explainParent={{
                        sectionName: ElectrificationPlannerSections.CHARGING_SECTION,
                        subsectionName: ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING,
                        stepName: 'chargingSituation'
                    }}
                    stepName='netOperatorApproval'
                    closeModal={close}
                />,
            }],
            ['wallboxOrder', {
                ...chargingSection[ElectrificationPlannerChargingSectionSubSections.WALLBOX].wallboxOrder,
                modal: (close: () => void) => <NotApplicableWithExplanationModalContent
                    
                    sectionName={ElectrificationPlannerSections.CHARGING_SECTION}
                    subsectionName={ElectrificationPlannerChargingSectionSubSections.WALLBOX}
                    explainParent={{
                        sectionName: ElectrificationPlannerSections.CHARGING_SECTION,
                        subsectionName: ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING,
                        stepName: 'chargingSituation'
                    }}
                    stepName='wallboxOrder'
                    closeModal={close}
                />,
            }],
            ['wallboxInstallation', {
                ...chargingSection[ElectrificationPlannerChargingSectionSubSections.WALLBOX].wallboxInstallation,
                modal: (close: () => void) => <NotApplicableWithExplanationModalContent
                    
                    sectionName={ElectrificationPlannerSections.CHARGING_SECTION}
                    subsectionName={ElectrificationPlannerChargingSectionSubSections.WALLBOX}
                    explainParent={{
                        sectionName: ElectrificationPlannerSections.CHARGING_SECTION,
                        subsectionName: ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING,
                        stepName: 'chargingSituation'
                    }}
                    stepName='wallboxInstallation'
                    closeModal={close}
                />,
            }],
            ['netOperatorRegistration', {
                ...chargingSection[ElectrificationPlannerChargingSectionSubSections.WALLBOX].netOperatorRegistration,
                modal: (close: () => void) => <NotApplicableWithExplanationModalContent
                    
                    sectionName={ElectrificationPlannerSections.CHARGING_SECTION}
                    subsectionName={ElectrificationPlannerChargingSectionSubSections.WALLBOX}
                    explainParent={{
                        sectionName: ElectrificationPlannerSections.CHARGING_SECTION,
                        subsectionName: ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING,
                        stepName: 'chargingSituation'
                    }}
                    stepName='netOperatorRegistration'
                    closeModal={close}
                />,
            }]
        ]);

        const energy = new Map([
            ['energyContract', chargingSection[ElectrificationPlannerChargingSectionSubSections.ENERGY].energyContract],
            ['photovoltaicSystem', chargingSection[ElectrificationPlannerChargingSectionSubSections.ENERGY].photovoltaicSystem],
            ['energyStorage', chargingSection[ElectrificationPlannerChargingSectionSubSections.ENERGY].energyStorage],
            ['energyManagementSystem', chargingSection[ElectrificationPlannerChargingSectionSubSections.ENERGY].energyManagementSystem],
        ]);

        const workplaceCharging = new Map([
            ['chargingAvailability', {
                ...chargingSection[ElectrificationPlannerChargingSectionSubSections.WORKPLACE_CHARGING].chargingAvailability,
                modal: (close: () => void) => <NotApplicableWithExplanationModalContent
                    sectionName={ElectrificationPlannerSections.CHARGING_SECTION}
                    subsectionName={ElectrificationPlannerChargingSectionSubSections.WORKPLACE_CHARGING}
                    stepName='chargingAvailability'
                    closeModal={close}
                />
            }],
            ['chargingAccess', chargingSection[ElectrificationPlannerChargingSectionSubSections.WORKPLACE_CHARGING].chargingAccess],
        ]);

        const publicCharging = new Map([
            ['chargingService', chargingSection[ElectrificationPlannerChargingSectionSubSections.PUBLIC_CHARGING].chargingService],
            ['plugAndChargeConfiguration', chargingSection[ElectrificationPlannerChargingSectionSubSections.PUBLIC_CHARGING].plugAndChargeConfiguration],
        ]);


        super(
            ElectrificationPlannerSections.CHARGING_SECTION,
            new Map([
                [ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING, homeCharging],
                [ElectrificationPlannerChargingSectionSubSections.WALLBOX, wallbox],
                [ElectrificationPlannerChargingSectionSubSections.ENERGY, energy],
                [ElectrificationPlannerChargingSectionSubSections.WORKPLACE_CHARGING, workplaceCharging],
                [ElectrificationPlannerChargingSectionSubSections.PUBLIC_CHARGING, publicCharging],
            ]))
    }


}



