import { Dispatch, SetStateAction, useState } from "react";
import { useLocation } from "react-router-dom";

export enum OptimizationType {
  EAGER_CHARGING = "EAGER_CHARGING",
  MINIMUM_COSTS = "MINIMUM_COSTS",
  MINIMUM_SESSIONS = "MINIMUM_SESSIONS",
}

export type TripSimulationPreferencesState = {
  query?: string;
  includeExtraStops: boolean;
  setIncludeExtraStops: Dispatch<SetStateAction<boolean>>;
  selectedOptimization: OptimizationType;
  setSelectedOptimization: Dispatch<SetStateAction<OptimizationType>>;
};

export function useTripSimulationPreferencesState(): TripSimulationPreferencesState {
  const location = useLocation();
  const query = location.search?.split("?")[1];

  const optimizationFromQuery = (() => {
    if (query === "minimum-risk") return OptimizationType.EAGER_CHARGING;
    if (query === "minimum-cost") return OptimizationType.MINIMUM_COSTS;
    if (query === "minimum-sessions") return OptimizationType.MINIMUM_SESSIONS;
    return null;
  })();

  const [includeExtraStops, setIncludeExtraStops] = useState(
    optimizationFromQuery ? true : false
  );
  const [selectedOptimization, setSelectedOptimization] = useState(
    optimizationFromQuery
      ? optimizationFromQuery
      : OptimizationType.EAGER_CHARGING
  );

  return {
    query,
    includeExtraStops,
    setIncludeExtraStops,
    selectedOptimization,
    setSelectedOptimization,
  };
}
