export enum DataProvisioningMethod {
    MANUAL_UPLOAD = "manualUpload",
    VIA_CONNECTION = "viaConnection"
}

export enum FuelType {
    PETROL = "Petrol",
    DIESEL = "Diesel"
}

export enum Category {
    M1 = "M1",
    N1 = "N1"
}

export enum Segment {
    MINI = "Mini",
    SMALL = "Small",
    MEDIUM = "Medium",
    LARGE = "Large",
    EXECUTIVE = "Executive",
    LUXURY = "Luxury"
}

export enum BodyStyle {
    HACHBACK = "Hachback",
    STATION_ESTATE = "Station/Estate",
    SEDAN = "Sedan",
    SUV = "SUV",
    SMALL_VAN = "Small Passenger Van",
    CABRIOLET = "Cabriolet",
}

export enum DriveTrainPropulsion {
    FWD = "FWD",
    RWD = "RWD",
    '4x4' = "4x4"
}