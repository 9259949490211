import { ReactElement } from "react";


export function FilterSection ({
    title,
    children,
  }: {
    title: string;
    children: ReactElement;
  }) {
    return (
      <div className="flex flex-col w-full mt-6">
        <div className="text-Grey-dark text-sm mb-2">{title}</div>
        <div className="flex flex-col">{children}</div>
      </div>
    );
  };