import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ManualModal from "./modals/ManualModal";
import VinModal from "./modals/VinModal";
import { Vehicle } from "../../../../../../../@types/vehicle";
import { Spinner } from "../../../../../../../electrify_frontend_common/components/Spinner";
import { Button } from "../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../../electrify_frontend_common/components/Buttonv2/types";


export default function VehicleInformationModal({
  vehicleId,
  vehicleData,
  vinError,
  refetchVehicleData
}: { vehicleId: string, vehicleData?: Vehicle, vinError: boolean, refetchVehicleData: () => void }) {
  const [useVin, setUseVin] = useState(false);
  const [useManual, setUseManual] = useState(false);
  const { t } = useTranslation("editVehicle");

  useEffect(() => {
    if (!useVin && !useManual) refetchVehicleData();
  }, [useVin, useManual])

  if (!vehicleData) return <Spinner />

  return (
    <div className="h-auto p-8">
      {useVin ? (
        <VinModal
          useVin={useVin}
          vehicleData={vehicleData}
          onClose={() => setUseVin(false)}
        />
      ) : null}
      {useManual ? (
        <ManualModal
          useManual={useManual}
          vehicleData={vehicleData}
          onClose={() => setUseManual(false)}
        />
      ) : null}

      <div>
        <h1 className="text-xl mb-4">{t("vehicle-information")}</h1>
        <div className="p-8 px-4 border border-Blueberry-dark-default rounded w-1/3">
          {vehicleData ? (
            <p className="text-sm text-Grey-shade mb-2">
              {t("current-vehicle-placeholder")}
            </p>
          ) : null}
          {vehicleData
            ? vehicleData?.vehicleModelName
            : t("no-vehicle-data-provided")}
        </div>
        <div className="text-Grey-dark text-sm mt-4">
          {t("vehicle-info-message")}
        </div>

        <div className="flex mt-5">
          <Button onClick={() => setUseVin(true)} size={ButtonSize.MEDIUM} variant={useVin || (!!vehicleData?.modelInfo?.useVin) ? ButtonVariant.PRIMARY : ButtonVariant.SECONDRY}>
            {t("add-vin-number")}
          </Button>
          <Button className="ml-4" onClick={() => setUseManual(true)} size={ButtonSize.MEDIUM} variant={useManual || (vehicleData && !vehicleData?.modelInfo?.useVin) ? ButtonVariant.PRIMARY : ButtonVariant.SECONDRY}>
            {t("add-manually")}
          </Button>

        </div>
      </div>
    </div>
  );
}
