

export function Range({color = "black", className} : {color?: string, className?: string}) {


    return (
        <svg className={className} width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9088_752)">
                <path d="M11.0508 3.24585C11.1049 3.24585 11.1569 3.26736 11.1951 3.30565C11.2334 3.34394 11.2549 3.39587 11.2549 3.45002" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M10.8457 3.45002C10.8457 3.39587 10.8672 3.34394 10.9055 3.30565C10.9438 3.26736 10.9957 3.24585 11.0499 3.24585" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.0499 3.65412C10.9957 3.65412 10.9438 3.63261 10.9055 3.59432C10.8672 3.55603 10.8457 3.5041 10.8457 3.44995" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.2549 3.44995C11.2549 3.5041 11.2334 3.55603 11.1951 3.59432C11.1569 3.63261 11.1049 3.65412 11.0508 3.65412" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M11.0496 1C11.6994 1 12.3226 1.25812 12.782 1.71759C13.2415 2.17705 13.4996 2.80022 13.4996 3.45C13.4996 4.49588 12.0361 6.48637 11.3714 7.33624C11.3332 7.38512 11.2844 7.42466 11.2286 7.45185C11.1729 7.47904 11.1116 7.49318 11.0496 7.49318C10.9876 7.49318 10.9264 7.47904 10.8706 7.45185C10.8149 7.42466 10.766 7.38512 10.7278 7.33624C10.0631 6.48582 8.59961 4.49588 8.59961 3.45C8.59961 2.80022 8.85773 2.17705 9.3172 1.71759C9.77666 1.25812 10.3998 1 11.0496 1V1Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M1.25 13.2499L4.51667 5.08325" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M12.6841 13.2501L11.0508 9.16675" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.9668 13.25V12.4333" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.9668 9.98341V9.16675" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6.9668 6.71657V5.8999" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_9088_752">
                    <rect width="13.3333" height="13.3333" fill="white" transform="translate(0.833984 0.333252)" />
                </clipPath>
            </defs>
        </svg>

    )

}