
import { ReactComponent as CongratulationsIcon } from '../../../../../../../assets/electrification_planner/congratulations.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../../../../assets/arrow_right_strong_white.svg';

import { useMutation } from 'react-query';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ElectrifyPlannerContext } from '../../../../../contexts/ElectrificationPlannerContext';
import { useUserData } from '../../../../../../../hooks/state/useUserData';
import { completeElectrificationPlannerOnboarding } from '../../../../../../../services/rest/electrificationPlanner';
import { Button } from '../../../../../../../electrify_frontend_common/components/Buttonv2';
import { ButtonSize, ButtonVariant } from '../../../../../../../electrify_frontend_common/components/Buttonv2/types';


export function Congratulations() {

    const {t} = useTranslation("electrificationPlanner");
    const { refetch } = useContext(ElectrifyPlannerContext);
    const { refreshUserAccountInfo } = useUserData();

    const completeMutation = useMutation({
        mutationFn: completeElectrificationPlannerOnboarding,
        onSuccess: () => {
            refreshUserAccountInfo();
            refetch();
        }
    });

    return (
        <div className="flex flex-col p-6 px-10 w-[1000px] items-center justify-center">
            {/* <div className='flex w-full justify-end'>
                <CloseIcon />
            </div> */}
            <div className='p-12 flex flex-col items-center justify-center'>
                <div className='mt-8 mb-4 flex flex-col items-center justify-center'>
                    <CongratulationsIcon />
                    <div className='text-2xl mt-6'>{t("onboarding.congratulationsStep.title")}</div>
                    <div className='text-2xl'>{t("onboarding.congratulationsStep.message1")}</div>
                    <div className='text-Grey-shade mt-6'>{t("onboarding.congratulationsStep.message2")}</div>
                </div>
                <Button
                    className='mt-5'
                    type="button"
                    variant={ButtonVariant.PRIMARY}
                    size={ButtonSize.MEDIUM}
                    onClick={() => completeMutation.mutate()}
                >
                    <div className='flex items-center '>
                        <div>{t("onboarding.congratulationsStep.button")}</div>
                        <ArrowRightIcon className='ml-3' />
                    </div>
                </Button>
            </div>
        </div>
    )


}