import { CheckoutForm } from "./steps/Checkout";
import { useContext, useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useUserData } from "../../../../../hooks/state/useUserData";
import { DriverPaymentGatewayContext } from "../../../contexts/DriverPaymentGatewayContext";
import { Tariff } from "../../../../../@types/paymentProcess";
import { checkDriverVehicleSubscription, confirmPaymentIntent, getTariffsForDriverUser } from "../../../../../services/rest/paymentProcess";
import { SubscriptionState } from "../../../../../enums";
import ModalWrapper from "../../../../../electrify_frontend_common/components/ModalWrapper";
import { PaymentContext } from "../../../../../contexts/PaymentContext";


export function Payments() {

    const { user } = useUserData();

    const { clientSecret: existingClientSecret, isPaymentModalOpen, closePaymentModal } = useContext(DriverPaymentGatewayContext);

    const [clientSecret, setClientSecret] = useState<string | null>(existingClientSecret || null);

    const [selectedTariff, setSelectedTariff] = useState<Tariff | undefined>(undefined);

    const [loadingPaymentIntent, setLoadingPaymentIntent] = useState(false);

    const [paymentIntentRequested, setPaymentIntentRequested] = useState(false);

    const [noMatchBetweenPreselectedProductAndValidTarifs, setNoMatchBetweenPreselectedProductAndValidTarifs] = useState(false);

    const { data: tariffs } = useQuery<Tariff[]>(['tariffs'], getTariffsForDriverUser);
    
    const { data: vehicleSubscription } = useQuery<{
        product: any,
        state: SubscriptionState,
        clientSecret: string | null
    }>(["userPaidVehicles"], checkDriverVehicleSubscription);

    const productAndTariffsLoaded = !!user?.accountInfo?.preSelectedProduct && !!tariffs;


    const confirmPaymentMutation = useMutation(confirmPaymentIntent, {
        onSuccess: (data) => {
            if (!data) return;
            setClientSecret(data.clientSecret);
            setLoadingPaymentIntent(false);
        }
    });

    useEffect(() => {
        if (productAndTariffsLoaded && (!vehicleSubscription || vehicleSubscription.state === SubscriptionState.NON_EXISTANT || vehicleSubscription.state === SubscriptionState.PAYMENT_FAILED)) {
            const matchingTariff = tariffs.filter((t: Tariff) => t.product.name === user?.accountInfo?.preSelectedProduct)[0];
            if(user?.accountInfo?.preSelectedProduct && !matchingTariff) {
                setNoMatchBetweenPreselectedProductAndValidTarifs(true);
                return;
            }
            if (matchingTariff && !paymentIntentRequested) {
                setSelectedTariff(matchingTariff);
                setPaymentIntentRequested(true);
            }
        }
    }, [user, tariffs, vehicleSubscription])


    return (
        <ModalWrapper
            isOpen={isPaymentModalOpen}
            close={() => {
                closePaymentModal()
            }}
            className="w-full h-full">
            <PaymentContext.Provider value={{
                clientSecret,
                setClientSecret,
                selectedTariff,
                loadingPaymentIntent,
                setLoadingPaymentIntent,
                setSelectedTariff,
                tariffs,
                confirmPayment: confirmPaymentMutation.mutate,
                noMatchBetweenPreselectedProductAndValidTarifs
            }}>
                <CheckoutForm />
            </PaymentContext.Provider>
        </ModalWrapper>
    )


}