import { gql } from "@apollo/client";

export const CREATE_BUSINESS_ACCOUNT = gql`
  mutation CreateUser(
    $newUser: UserInput!
  ) {
    createUser(
      newUser: $newUser
    ) {
      email
      username
      product
    }
  }
`;

export const CREATE_PERSONAL_ACCOUNT = gql`
  mutation CreateUser(
    $newUser: UserInput!
  ) {
    createUser(
      newUser: $newUser
    ) {
      email
      username
      product
    }
  
  }
`;