/* React imports */
import { useEffect, useMemo, useState } from "react";

/* Third party imports */
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";

/* Local imports */


import VehicleTariff from "./components/VehicleTariff";
import EditVehicleHeader from "./components/EditVehicleHeader";
import VehicleHomeLocations from "./components/VehicleHomeLocations";
import VehicleAnalysisPeriod from "./components/FleetVehicleAnalysisPeriod";
import VehicleAnualCosts from "./components/VehicleAnualCosts";
import VehicleInformationModal from "./components/VehicleInformationModal";
import { Vehicle, VehicleInput } from "../../../../../../@types/vehicle";
import { GET_VEHICLE_DATA } from "../../../../../../services/graphql/fleet/queries";
import { useForm } from "../../../../../../hooks/utils/useForm";
import { UPDATE_VEHICLE } from "../../../../../../services/graphql/fleet/mutations";
import ModalWrapper from "../../../../../../electrify_frontend_common/components/ModalWrapper";
import { Button } from "../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../electrify_frontend_common/components/Buttonv2/types";


export default function VehicleEditor({ vehicleId, onClose }: { vehicleId: string, onClose: () => void }) {

  const [updateVinError, setUpdateVinError] = useState(false);
  const { t } = useTranslation("editVehicle");
  const form = useForm<VehicleInput>();


  const { data: vehicleData, refetch: refetchVehicleData } = useQuery<{ vehicle: Vehicle }>(GET_VEHICLE_DATA, {
    variables: { id: vehicleId },
    onCompleted: (data: { vehicle: Vehicle }) => {
      const vehicleData = data.vehicle;
      form.updateFormState({
        modelInfo: {
          modelId: vehicleData?.modelInfo?.modelId,
          brand: vehicleData?.modelInfo?.brand,
          model: vehicleData?.modelInfo?.model,
          variant: vehicleData?.modelInfo?.variant,
        },
        name: vehicleData?.name,
        useVin: vehicleData?.useVin,
        vin: vehicleData?.vin,
        analysisPeriod: {
          from: vehicleData?.analysisPeriod?.from?.split("T")[0],
          to: vehicleData?.analysisPeriod?.to?.split("T")[0],
        },
        yearlyTax: vehicleData.yearlyTax,
        yearlyMaintenanceCost: vehicleData.yearlyMaintenanceCost,
        yearlyInsuranceCost: vehicleData.yearlyInsuranceCost,
        plannedReplacementDate: vehicleData.plannedReplacementDate?.split(
          "T"
        )[0],
      });
    },
    onError: (error) => console.log("ERRRR>>>", error),
  });

  const [updateVehicle, { loading: updateLoading }] = useMutation(
    UPDATE_VEHICLE,
    {
      onCompleted: (data) => {
        onClose();
      },
      onError: (e) => {
        if (e.message.includes("Vin")) {
          setUpdateVinError(true);
        }
      },
    }
  );

  const processVehicleToUpdate = () => {
    const updatedInfo = { ...form.formState };
    return {
      variables: {
        id: vehicleId,
        vehicle: updatedInfo,
      },
    };
  };

  const processedVehicle = useMemo(() => {
    return vehicleData?.vehicle;
  }, [vehicleData]);

  useEffect(() => {
    if (updateVinError) {
      setUpdateVinError(false);
    }
  }, [form.formState]);

  return (
    <ModalWrapper
      isOpen={!!vehicleId}
      close={onClose}
      className="w-1/2">

      <>
        <EditVehicleHeader
          form={form}
          vehicle={processedVehicle}
          onClose={onClose}
        />
        <div className="max-h-full overflow-y-auto">
          <div className="pt-5">
            <VehicleTariff vehicleId={vehicleId} />

            <div className="mt-8">
              <VehicleInformationModal
                vehicleId={vehicleId}
                vehicleData={processedVehicle}
                vinError={updateVinError}
                refetchVehicleData={refetchVehicleData}
              />
            </div>

            <div className="mt-8">
              <VehicleHomeLocations vehicleId={vehicleId} />
            </div>

            <div className="mt-8">
              <VehicleAnalysisPeriod vehicleId={vehicleId} form={form} />
            </div>

            <div className="mt-8">
              <VehicleAnualCosts form={form} />
            </div>

            <div className="flex mt-8 px-8 pb-8 justify-start">
              <Button
                variant={ButtonVariant.PRIMARY}
                size={ButtonSize.MEDIUM}
                onClick={() => updateVehicle(processVehicleToUpdate())}
              >
                <div className="px-10">
                  {t("vehicle-save")}
                </div>
              </Button>
            </div>
          </div>
        </div>
      </>
    </ModalWrapper>
  );
}
