

export function DashboardIcon({color = "black"} : {color?: string}) {



    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke={color} xmlns="http://www.w3.org/2000/svg">
            <path stroke={color} d="M10.75 12.4382C10.7504 12.6542 10.6651 12.8616 10.5128 13.0147C10.3604 13.1678 10.1535 13.2542 9.9375 13.2549H3.4375C3.33051 13.2547 3.22462 13.2334 3.12587 13.1922C3.02712 13.151 2.93745 13.0908 2.86199 13.0149C2.78653 12.9391 2.72676 12.8491 2.6861 12.7502C2.64543 12.6512 2.62467 12.5452 2.625 12.4382V3.44655C2.62478 3.33952 2.64564 3.2335 2.6864 3.13454C2.72716 3.03557 2.787 2.9456 2.86253 2.86977C2.93805 2.79393 3.02778 2.73372 3.12657 2.69256C3.22537 2.6514 3.33131 2.6301 3.43833 2.62988L9.93833 2.64072C10.1542 2.6416 10.3609 2.72808 10.5131 2.88119C10.6652 3.0343 10.7504 3.24152 10.75 3.45738V12.4382Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path stroke={color} d="M21.3717 7.45324C21.3701 7.66726 21.2837 7.87191 21.1313 8.02223C20.979 8.17255 20.7732 8.25623 20.5592 8.2549H14.0592C13.9532 8.25567 13.848 8.23554 13.7498 8.19566C13.6516 8.15578 13.5622 8.09693 13.4868 8.02247C13.4113 7.94802 13.3513 7.85942 13.3101 7.76175C13.2689 7.66408 13.2473 7.55924 13.2467 7.45324V3.4424C13.248 3.22861 13.3341 3.02408 13.4862 2.87376C13.6382 2.72344 13.8437 2.63963 14.0575 2.64074L20.5575 2.6299C20.7717 2.62835 20.9778 2.71191 21.1305 2.86223C21.2832 3.01255 21.3699 3.21733 21.3717 3.43157V7.45324Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path stroke={color} d="M10.75 20.5757C10.7493 20.682 10.7278 20.7871 10.6865 20.885C10.6452 20.9829 10.5851 21.0718 10.5095 21.1464C10.4339 21.2211 10.3443 21.2802 10.2459 21.3202C10.1474 21.3603 10.0421 21.3806 9.93583 21.3799L3.43583 21.3691C3.22181 21.37 3.0162 21.2858 2.86416 21.1352C2.71212 20.9846 2.6261 20.7797 2.625 20.5657V16.5582C2.62555 16.4521 2.647 16.3472 2.68814 16.2493C2.72928 16.1515 2.78929 16.0628 2.86475 15.9882C2.94021 15.9136 3.02963 15.8546 3.12791 15.8145C3.22619 15.7745 3.33139 15.7542 3.4375 15.7549H9.9375C10.1517 15.7538 10.3577 15.8378 10.51 15.9884C10.6624 16.139 10.7487 16.344 10.75 16.5582V20.5757Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path stroke={color} d="M14.06 21.3799C13.9529 21.3797 13.8469 21.3583 13.748 21.3171C13.6492 21.2759 13.5594 21.2156 13.4839 21.1397C13.4084 21.0638 13.3485 20.9737 13.3078 20.8747C13.2671 20.7756 13.2463 20.6695 13.2467 20.5624V11.5724C13.2463 11.4654 13.2671 11.3593 13.3077 11.2603C13.3484 11.1613 13.4082 11.0713 13.4836 10.9954C13.5591 10.9194 13.6487 10.8591 13.7475 10.8179C13.8462 10.7766 13.9521 10.7552 14.0592 10.7549H20.5592C20.6662 10.7552 20.7721 10.7766 20.8709 10.8179C20.9696 10.8591 21.0593 10.9194 21.1347 10.9954C21.2102 11.0713 21.2699 11.1613 21.3106 11.2603C21.3512 11.3593 21.372 11.4654 21.3717 11.5724V20.5515C21.3721 20.7675 21.2869 20.9748 21.1348 21.128C20.9826 21.2813 20.7759 21.3679 20.56 21.369L14.06 21.3799Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )


}