import { ReactElement, createContext, useEffect, useState } from 'react';
import { User } from '../@types';
import useAuthenticate from '../hooks/effects/useAuthenticate';

export const AuthContext = createContext<{
	user: User | null;
	setUser: any;
}>({ user: null, setUser: null });

export const AuthContextProvider = ({
	children,
}: {
	children: ReactElement;
}) => {
	const [user, setUser] = useState<User | null>(null);

	const { authenticateWithToken } = useAuthenticate({ setUser });

	useEffect(() => {
		if (!user) authenticateWithToken();
	}, [user]);

	return (
		<AuthContext.Provider value={{ user, setUser }}>
			{children}
		</AuthContext.Provider>
	);
};
