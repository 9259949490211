import { ReactComponent as ArrowDownIcon } from "../../../../assets/overview-arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../assets/overview-arrow-up.svg";
import { ComparisonProp } from "../../../../@types";
import { useMemo } from "react";
import { useLayout } from "../../../../hooks/state/useLayout";

const getcomparisonProps = (
    localeCode: string,
    comparison?: string | number
): ComparisonProp => {
    const val = typeof comparison === "number" ? comparison : parseFloat(comparison || "0");
    const displayValue = val.toLocaleString(localeCode);

    if (val > 0) {
        return {
            icon: <ArrowUpIcon className="ml-1" />,
            backgroundColor: "bg-Red-default",
            value: `+${displayValue}%`,
        };
    } else if (val < 0) {
        return {
            icon: <ArrowDownIcon className="ml-1" />,
            backgroundColor: "bg-Green-default",
            value: `${displayValue}%`,
        };
    } else {
        return {
            icon: null,
            backgroundColor: "bg-Grey-default",
            value: `${displayValue}%`,
        };
    }
};

export function ComparisonBox({ comparison }: { comparison?: number | string }) {
    const { localeCode } = useLayout();

    const comparisonProp: ComparisonProp = useMemo(
        () => getcomparisonProps(localeCode, comparison),
        [comparison, localeCode]
    );

    return <div className={`rounded relative z-40 flex items-center p-1 pl-2 font-bold text-white text-base ${comparisonProp.backgroundColor}`}>
        {comparisonProp.value} {comparisonProp?.icon}
    </div>
}
