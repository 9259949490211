import { useTranslation } from "react-i18next";
import Select from "../misc/Select";
import { CountryCode } from "../../../enums";


export function CountrySelector({ selectedCountry, countryList, handleChange, error, variant = 'small' }: { selectedCountry: string | null, countryList: { key: string, value: string | CountryCode }[], handleChange: (item: any) => void, error?: boolean, variant?: 'small' | 'large' }) {


    const { t } = useTranslation("settingsBilling");

    const selectedItem = {key: countryList?.find((x: { key: string, value: string | CountryCode }) => x.value === selectedCountry)?.key, value: selectedCountry || null};


    return (
        <>
            <label className={'text-Grey-dark mb-2'}>
                {t("country-label")}
            </label>
            <Select
                allowSearch
                name="billingAddressCountry"
                placeholder={t("country-placeholder")}
                // className="p-0"
                buttonClassName={`bg-white text-md rounded h-16 flex items-center `}
                data={countryList}
                error={error}
                textStyle={variant === 'small' ? '' : 'text-lg text-black'}
                handleChange={handleChange}
                selectedItem={selectedItem}
            />
        </>
    )

}