import { useMemo } from "react";
import { useTranslation } from "react-i18next";



export function useFilterValues(vehicleCategory?: "M1" | "N1") {

  const { t, i18n } = useTranslation("evFinder");


  const priceSliderProperties = useMemo(() => {
    return {
      max: 105000,
      min: 20000,
      step: 5000
    }
  }, [vehicleCategory])

  const availabilityOptions = useMemo(
    () => [
      {
        label: `${t("filters.availability.options.pastModels")}`,
        value: "PAST",
      },
      {
        label: `${t("filters.availability.options.currentModels")}`,
        value: "CURRENT",
      },
      {
        label: `${t("filters.availability.options.comingSoon")}`,
        value: "FUTURE",
      },
    ],
    [i18n.language]
  );

  const topSpeedOptions = useMemo(
    () => [
      { label: "> 140 km/h", value: 140 },
      { label: "> 170 km/h", value: 170 },
      { label: "> 200 km/h", value: 200 },
      { label: "> 250 km/h", value: 250 },
    ],
    []
  );

  const loadVolumeSliderProperties = useMemo(() => {
    if (vehicleCategory === "M1") {
      return {
        max: 730,
        min: 220,
        step: 30
      }
    } else {
      return {
        max: 730,
        min: 220,
        step: 30
      }
    }
  }, [vehicleCategory])


  const loadWeightSliderProperties = useMemo(() => {
    if (vehicleCategory === "M1") {
      return {
        max: 670,
        min: 330,
        step: 20
      }
    } else {
      return {
        max: 670,
        min: 330,
        step: 20
      }
    }

  }, [vehicleCategory])

  const driveTrainOptions = useMemo(() => [
    { label: "FWD", value: "FWD" },
    { label: "RWD", value: "RWD" },
    { label: "4x4", value: "4x4" },
  ], [])

  const realRangeOptions = useMemo(
    () => [
      { label: "> 250 km", value: 250 },
      { label: "> 300 km", value: 300 },
      { label: "> 400 km", value: 400 },
      { label: "> 500 km", value: 500 },
    ],
    []
  );

  const realConsumptionOptions = useMemo(
    () => [
      { label: "< 16 kWh", value: 16 },
      { label: "< 18 kWh", value: 18 },
      { label: "< 20 kWh", value: 20 },
      { label: "< 22 kWh", value: 22 },
    ],
    []
  );

  const loadWeightOptions = useMemo(
    () => [
      { label: "> 400 kg", value: 400 },
      { label: "> 500 kg", value: 500 },
      { label: "> 600 kg", value: 600 },
      { label: "> 700 kg", value: 700 },
    ],
    []
  );

  const loadVolumeOptions = useMemo(
    () => [
      { label: "> 350 l", value: 350 },
      { label: "> 400 l", value: 400 },
      { label: "> 500 l", value: 500 },
      { label: "> 700 l", value: 700 },
    ],
    []
  );

  const seatsOptions = useMemo(
    () => [
      { label: "2", value: 2 },
      { label: "4+", value: 4 },
      { label: "5+", value: 5 },
      { label: "7+", value: 7 },
    ],
    []
  );



  return {
    priceSliderProperties,
    driveTrainOptions,
    availabilityOptions,
    topSpeedOptions,
    realRangeOptions,
    realConsumptionOptions,
    loadWeightOptions,
    loadVolumeOptions,
    loadVolumeSliderProperties,
    loadWeightSliderProperties,
    seatsOptions,
  }

}

