import { use } from "i18next";
import { Spinner, SpinnerSize } from "../../../../../../../../electrify_frontend_common/components/Spinner";
import { CloseIcon } from "../../../../../../../../electrify_frontend_common/icons/CloseIcon";
import { AddressWithShipping } from "./AddressWithShipping";
import { AddressWithoutShipping } from "./AddressWithoutShipping";
import { usePaymentAddress } from "./usePaymentAddress";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { DriverPaymentGatewayContext } from "../../../../../../contexts/DriverPaymentGatewayContext";
import { ElectrifySubProducts } from "../../../../../../../../enums";

export function AddressConfirmation() {

    const paymentAddressProps = usePaymentAddress();
    const { loadingPaymentIntent, selectedTariff } = paymentAddressProps;

    const { closePaymentModal } = useContext(DriverPaymentGatewayContext);

    const { t } = useTranslation("driverPaymentSystem");

    return (
        <div className="flex flex-col w-full h-full items-center max-h-full overflow-auto py-4 pb-10">

            {loadingPaymentIntent ? <div className="flex flex-col grow items-center justify-center">
                <Spinner size={SpinnerSize.LARGE} />
                <div className="mt-4">{t("address-form.preparing-payment")}</div>
            </div> : <div className="flex flex-col w-full items-center">
                <div className="flex w-full justify-end p-4 px-8"><CloseIcon onClick={closePaymentModal} /></div>
                {selectedTariff?.product?.name === ElectrifySubProducts.DRIVER_DONGLE ?
                    <AddressWithShipping paymentAddressProps={paymentAddressProps} />
                    : <AddressWithoutShipping paymentAddressProps={paymentAddressProps} />}
            </div>}
        </div>
    )


}