import { useState } from "react";
import { NextButton } from "../utils/NextButton";
import { PrevButton } from "../utils/PrevButton";
import { SocBuffer } from "../../Settings/ChargingPreferences";
import { useMutation, useQuery } from "@apollo/client";

import { useTranslation } from "react-i18next";
import { GET_VEHICLE_SIMULATION_PREFERENCES } from "../../../../../../services/graphql/driver/queries";
import { UPDATE_VEHICLE_SIMULATION_PREFERENCES } from "../../../../../../services/graphql/driver/mutations";

export function ChargingPreferences({
  step,
  nextStep,
  prevStep,
}: {
  step: number;
  nextStep: () => void;
  prevStep: () => void;
}) {
  const { t } = useTranslation("driverOnboarding");

  const [socBuffer, setSocBuffer] = useState(5);
  const [setTemperature, setSetTemperature] = useState(20);

  useQuery(GET_VEHICLE_SIMULATION_PREFERENCES, {
    onCompleted: (data) => {
      const prefs = data.vehicleSimulationPreferences;
      setSocBuffer(prefs.socBuffer || 0);
      setSetTemperature(prefs.setTemperature || 0);
    },
  });

  const [updateSimulationMutation] = useMutation(
    UPDATE_VEHICLE_SIMULATION_PREFERENCES
  );

  return (
    <>
      <div className="p-10 w-[1000px]">
        <div className="text-xl">
          <span className="text-Blueberry-dark-default ">
            {t("step")} {step}:
          </span>{" "}
          {t("step-charging.title-1")}{" "}
          <span className="font-bold">{t("step-charging.title-2")}</span>{" "}
          {t("step-charging.title-3")}
        </div>
        <div className="flex w-5/6 text-sm text-Grey-dark py-2">
          {t("step-charging.message")}
        </div>
        <div className="text-lg mb-2 mt-4">{t("step-charging.label")}</div>
        <div className="flex items-center">
          <div className="w-1/3">
            <SocBuffer
              socBuffer={socBuffer}
              setSocBuffer={(v: number) => {
                setSocBuffer(v);
                updateSimulationMutation({
                  variables: {
                    vehicleSimulationPreferences: {
                      socBuffer: v,
                      setTemperature: setTemperature,
                    },
                  },
                });
              }}
            />
          </div>
        </div>

        <div className="flex justify-between mt-10">
          <PrevButton text={t("back")} onClick={prevStep} />
          <NextButton text={t("next")} active={true} onClick={nextStep} />
        </div>
      </div>
    </>
  );
}
