import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { LayoutContext } from "../../contexts/LayoutContext";
import { getParamByISO } from "iso-country-currency";
import { useUserData } from "../state/useUserData";
import useUpdateLanguage from "../utils/useUpdateLanguage";

export const useLanguageSync = () => {
  const { user } = useUserData();
  const { i18n } = useTranslation();
  const language = i18n.language;

  const { setCurrencySymbol, setLocaleCode } = useContext(LayoutContext);
  const { updateLanguage } = useUpdateLanguage();

  useEffect(() => {
    const countryCode = user?.accountInfo?.countryCode || "DE";
    const currencySymbol = getParamByISO(countryCode, "symbol");
    setCurrencySymbol(currencySymbol);
    const oldLang = i18n.language;
    const newLang = user?.accountInfo?.languageCode;
    const localeCode = `${language}-${countryCode}`;
    setLocaleCode(localeCode);

    if (newLang && newLang !== oldLang) updateLanguage(language);
  }, [user, language]);
};
