import { TripRow } from "./TripRow";
import { ChargeRow, ExtraChargeRow } from "./ChargingRows";
import { TripSimulationDetail } from "../../../../../../../../@types/driver/simulations";


export function DynamicTripRow({ trip }: { trip: TripSimulationDetail }) {
    if (trip.eventType == 'CHARGING' && trip.isExtraStop) return <ExtraChargeRow trip={trip} />
    if (trip.eventType == 'CHARGING') return <ChargeRow trip={trip} />
    return <TripRow trip={trip} />
}
