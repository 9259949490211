
import FuelIcon from "../../../../../../assets/overview-fuel.svg";
import Co2Icon from "../../../../../../assets/overview-co2.svg";
import { VehicleTypesMetrics } from "./metrics/VehicleTypesMetrics";
import { MetricComparison } from "./metrics/MetricsComparison";
import { useLayout } from "../../../../../../hooks/state/useLayout";




export function ReportPanel({
    className,
    userFleet,
    vehicleTypes,
    fleetData,
    textContent,
    specificMetric,
    tooltips,
}: any) {
    // TODO add currencySymbol from context
    const { currencySymbol } = useLayout();

    return (
        <div className={`w-full xl:w-1/2 p-10 ${className}`}>
            <div className="mb-6">
                <p className="text-xs	text-Grey-shade font-bold mb-2">{textContent.title}</p>
                <p className="text-xl font-medium text-black">{textContent.fleet}</p>
            </div>

            <VehicleTypesMetrics
                textContent={textContent}
                vehicleTypes={vehicleTypes}
                fleetData={fleetData}
                userFleet={userFleet}
            />

            {specificMetric}

            <MetricComparison
                icon={FuelIcon}
                title={textContent.cost}
                value={fleetData?.yearlyFuelAndEnergyCosts}
                units={currencySymbol}
                comparison={userFleet ? null : fleetData?.yearlyFuelAndEnergyCostsChange}
                tooltip={tooltips ? tooltips[0] : null}
            />

            <MetricComparison
                icon={Co2Icon}
                title={textContent.co2}
                value={fleetData?.yearlyCo2Emission}
                units={"kg"}
                comparison={userFleet ? null : fleetData?.yearlyCo2EmissionChange}
                tooltip={tooltips ? tooltips[1] : null}
            />
        </div>
    );
}
