import { ElectrifyFleet } from "./Electrify";



export function B2BProductsGateway() {



    return (
        <ElectrifyFleet/>
    ) 



}