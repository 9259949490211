import { useTranslation } from "react-i18next";


export type OfferToDisplay = {
    price?: number | null;
    url?: string | null
};

export function OfferBanner({ offerToDisplay, trackingCall }: { offerToDisplay?: OfferToDisplay, trackingCall?: (callback: () => void) => void }) {

    const { t } = useTranslation("evFinder");

    const offerToDisplayExists = !!(offerToDisplay?.price && offerToDisplay?.url);

    return <div
        id="tradetracker-offer"
        className={`h-[34px] max-h-[34px] overflow-hidden flex w-full bg-Blueberry-light-shade text-white cursor-pointer items-center justify-center text-sm absolute top-[212px] left-0 first-letter font-light
                         ${offerToDisplayExists ? 'bg-Blueberry-light-shade  cursor-pointer underline' : 'bg-Grey-default hover:cursor-not-allowed'}`}
        onClick={() => {
            if (!offerToDisplayExists) return;
            if (trackingCall) {
                trackingCall(() => window.open(offerToDisplay?.url || "", "_blank"));
            } else {
                window.open(offerToDisplay?.url || "", "_blank");
            }
        }}
    >
        {offerToDisplayExists ? t("vehicleCard.banner.leasingFrom", { price: offerToDisplay.price }) : t("vehicleCard.banner.noOffer")}
    </ div>

}