/* React import */
import { useEffect, useMemo, useState } from "react";

/* Third party imports */
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { useQuery as useRestQuery } from "react-query";
import { emailRegex } from "../../../static/constants";
import { getLiveMarketsForFleet } from "../../../services/rest/paymentProcess";
import { GET_SETTING } from "../../../services/graphql/fleet/queries";
import { UPDATE_SETTING } from "../../../services/graphql/fleet/mutations";
import { useUserData } from "../../../hooks/state/useUserData";
import { CountrySelector } from "../CountrySelector";
import { Setting } from "../../../@types/settings";
import { removeTypename } from "../../../services/graphql/utils";
import { Spinner } from "../../../electrify_frontend_common/components/Spinner";

export function BillingForm({
  buttonText,
  onUpdateAction,
}: {
  buttonText: string;
  onUpdateAction?: () => void;
}) {
  const { t, i18n } = useTranslation("settingsBilling");
  const [formData, setFormData] = useState({} as Setting);

  const { user, refreshUserAccountInfo, updateUserSettings } = useUserData();

  const languageCode = i18n.language;

  const regionName = new Intl.DisplayNames([languageCode], { type: "region" });

  const [displayFormErrors, setDisplayFormErrors] = useState(false);

  const [invalidEmail, setInvalidEmail] = useState(false);

  const updateFormValidity = () => {
    const validity = {} as any;
    validity["billingAddressCompany"] = !!formData.billingInfo?.company;
    validity["billingAddressName"] = !!formData.billingInfo?.name;
    validity["billingAddressEmail"] = !!formData.billingInfo?.email;
    validity["billingAddressStreet"] = !!formData.billingInfo?.address?.street;
    validity["billingAddressStreetNr"] = !!formData.billingInfo?.address?.streetNr;
    validity["billingAddressZip"] = !!formData.billingInfo?.address?.zip;
    validity["billingAddressCity"] = !!formData.billingInfo?.address?.city;
    validity["billingAddressCountry"] = !!formData.billingInfo?.address?.country;
    return validity;
  };

  const { data: liveMarketsData } = useRestQuery(
    ["liveMarkets"],
    getLiveMarketsForFleet
  );

  const countryList = useMemo<{ key: string; value: string }[]>(
    () =>
      liveMarketsData
        ?.map((countryCode: string) => {
          return {
            value: countryCode,
            key: regionName.of(countryCode),
          };
        })
        .sort((a: any, b: any) => (a.key > b.key ? 1 : -1)),
    [liveMarketsData]
  );

  const { loading } = useQuery<{ setting: Setting }>(GET_SETTING, {
    onCompleted: ({ setting }) => {
      setFormData(setting);
      updateUserSettings(setting || {});
    },
  });

  const [formValidity, setFormValidity] = useState<any>({});

  useEffect(() => {
    if (formData) setFormValidity(updateFormValidity());
  }, [formData]);

  const isFormValid = () => {
    let isValid = true;
    Object.entries(formValidity).forEach(([k, v]) => {
      if (!v) isValid = false;
    });
    return isValid;
  };

  const isEmailValid = () => {
    return emailRegex.test(formData?.billingInfo?.email || '');
  };

  const [updateSetting] = useMutation(UPDATE_SETTING, {
    onCompleted: () => {
      refreshUserAccountInfo();
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleChange = (e: any) => {
    let value = e.target.value;
    if (e.target.type === "number") value = parseFloat(e.target.value);
    updateUserSettings({ [e.target.name]: value });
    setFormData({ ...formData, [e.target.name]: value });
  };

  const updateSettings = () => {
    setDisplayFormErrors(false);
    setInvalidEmail(false);

    if (!isFormValid()) {
      setDisplayFormErrors(true);

      return;
    }

    if (!isEmailValid()) {
      setInvalidEmail(true);

      return;
    }

    updateSetting({
      variables: { setting: removeTypename(formData) },
    });
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className="px-10 py-12">
            <div className="w-full bg-Grey-background rounded">
              <div className="grid grid-cols-12 gap-5 px-10 py-8">
                {/* 1st row */}
                <div className="col-span-6 flex flex-col ">
                  <label className="text-Grey-dark mb-2">
                    {t("company-name")}
                  </label>
                  <input
                    className={`border boder-solid ${displayFormErrors &&
                      !formValidity["billingAddressCompany"]
                      ? "border-danger-500"
                      : "border-Grey-tint"
                      } px-6 py-5 bg-white text-lg rounded`}
                    name="billingAddressCompany"
                    value={formData?.billingInfo?.company || ""}
                    onChange={(e: any) => setFormData({
                      ...formData,
                      billingInfo: {
                        ...formData.billingInfo,
                        company: e.target.value
                      }
                    })}
                  />
                </div>
                <div className="col-span-6 flex flex-col ">
                  <label className="text-Grey-dark mb-2">{t("tax-id")}</label>
                  <input
                    className="border boder-solid border-Grey-tint px-6 py-5 bg-white text-lg rounded"
                    name="billingAddressTaxId"
                    value={formData?.billingInfo?.taxId || ""}
                    onChange={(e: any) => setFormData({
                      ...formData,
                      billingInfo: {
                        ...formData.billingInfo,
                        taxId: e.target.value
                      }
                    })}
                  />
                </div>
                {/* 2nd row */}
                <div className="col-span-6 flex flex-col ">
                  <label className="text-Grey-dark mb-2">{t("contact")}</label>
                  <input
                    className={`border boder-solid ${displayFormErrors && !formValidity["billingAddressName"]
                      ? "border-danger-500"
                      : "border-Grey-tint"
                      } px-6 py-5 bg-white text-lg rounded`}
                    name="billingAddressName"
                    value={formData?.billingInfo?.name || ""}
                    onChange={(e: any) => setFormData({
                      ...formData,
                      billingInfo: {
                        ...formData.billingInfo,
                        name: e.target.value
                      }
                    })}
                  />
                </div>
                <div className="col-span-6 flex flex-col ">
                  <label className="text-Grey-dark mb-2">{t("email")}</label>
                  <input
                    className={`border boder-solid ${(displayFormErrors &&
                      !formValidity["billingAddressEmail"]) ||
                      invalidEmail
                      ? "border-danger-500"
                      : "border-Grey-tint"
                      } px-6 py-5 bg-white text-lg rounded`}
                    name="billingAddressEmail"
                    value={formData?.billingInfo?.email || ""}
                    onChange={(e: any) => setFormData({
                      ...formData,
                      billingInfo: {
                        ...formData.billingInfo,
                        email: e.target.value
                      }
                    })}
                  />
                </div>
                {/* 3rd row */}
                <div className="col-span-8 flex flex-col ">
                  <label className="text-Grey-dark mb-2">{t("street")}</label>
                  <input
                    className={`border boder-solid ${displayFormErrors && !formValidity["billingAddressStreet"]
                      ? "border-danger-500"
                      : "border-Grey-tint"
                      } px-6 py-5 bg-white text-lg rounded`}
                    name="billingAddressStreet"
                    value={formData?.billingInfo?.address?.street || ""}
                    onChange={(e: any) => setFormData({
                      ...formData,
                      billingInfo: {
                        ...formData.billingInfo,
                        address: {
                          ...formData.billingInfo?.address,
                          street: e.target.value
                        }
                      }
                    })}
                  />
                </div>
                <div className="col-span-4 flex flex-col ">
                  <label className="text-Grey-dark mb-2">
                    {t("house-number")}
                  </label>
                  <input
                    className={`border boder-solid ${displayFormErrors &&
                      !formValidity["billingAddressStreetNr"]
                      ? "border-danger-500"
                      : "border-Grey-tint"
                      } px-6 py-5 bg-white text-lg rounded`}
                    name="billingAddressStreetNr"
                    value={formData?.billingInfo?.address?.streetNr || ""}
                    onChange={(e: any) => setFormData({
                      ...formData,
                      billingInfo: {
                        ...formData.billingInfo,
                        address: {
                          ...formData.billingInfo?.address,
                          streetNr: e.target.value
                        }
                      }
                    })}
                  />
                </div>
                {/* 4th row */}
                <div className="col-span-3 flex flex-col ">
                  <label className="text-Grey-dark mb-2">
                    {t("postal-code")}
                  </label>
                  <input
                    className={`border boder-solid ${displayFormErrors && !formValidity["billingAddressZip"]
                      ? "border-danger-500"
                      : "border-Grey-tint"
                      } px-6 py-5 bg-white text-lg rounded`}
                    name="billingAddressZip"
                    value={formData?.billingInfo?.address?.zip || ""}
                    onChange={(e: any) => setFormData({
                      ...formData,
                      billingInfo: {
                        ...formData.billingInfo,
                        address: {
                          ...formData.billingInfo?.address,
                          zip: e.target.value
                        }
                      }
                    })}
                  />
                </div>
                <div className="col-span-4 flex flex-col ">
                  <label className="text-Grey-dark mb-2">{t("city")}</label>
                  <input
                    className={`border boder-solid ${displayFormErrors && !formValidity["billingAddressCity"]
                      ? "border-danger-500"
                      : "border-Grey-tint"
                      } px-6 py-5 bg-white text-lg rounded`}
                    name="billingAddressCity"
                    value={formData?.billingInfo?.address?.city || ""}
                    onChange={(e: any) => setFormData({
                      ...formData,
                      billingInfo: {
                        ...formData.billingInfo,
                        address: {
                          ...formData.billingInfo?.address,
                          city: e.target.value
                        }
                      }
                    })}
                  />
                </div>
                <div className="col-span-5 flex flex-col ">
                  <CountrySelector
                    variant='large'
                    error={
                      displayFormErrors &&
                      !formValidity["billingAddressCountry"]
                    }
                    handleChange={(item: any) => {
                      updateUserSettings({
                        ...formData,
                        billingInfo: {
                          ...formData.billingInfo,
                          address: {
                            ...formData.billingInfo?.address,
                            country: item ? item.value : null,
                          }
                        }
                      });
                      setFormData({
                        ...formData,
                        billingInfo: {
                          ...formData.billingInfo,
                          address: {
                            ...formData.billingInfo?.address,
                            country: item ? item.value : null,
                          }
                        }
                      });
                    }}
                    countryList={countryList}
                    selectedCountry={
                      user?.settings?.billingInfo?.address?.country || null
                    }
                  />
                </div>
              </div>
            </div>
            {displayFormErrors ? (
              <div className="text-md rounded bg-Grey-background text-Red-default mt-6 py-4 px-6">
                {t("input-error")}
              </div>
            ) : null}
            {invalidEmail ? (
              <div className="text-md rounded bg-Grey-background text-Red-default mt-6 py-4 px-6">
                {t("email-error")}
              </div>
            ) : null}
            <button
              className="mt-10 px-12 py-6 bg-Blueberry-dark-default rounded text-lg text-white border-2 border-solid border-Blueberry-dark-default"
              onClick={() => {
                updateSettings();
                if (onUpdateAction) onUpdateAction();
              }}
            >
              {buttonText ? buttonText : t("save-button")}
            </button>
          </div>
        </>
      )}
    </>
  );
}
