import { useStripe } from "@stripe/react-stripe-js";
import { useContext, useEffect, useMemo, useState } from "react";
import { ProductInfo } from "../steps/Checkout/ProductInfo";
import { ReactComponent as Arrow } from '../../../../../../assets/arrow_right_strong_white.svg';
import { useTranslation } from "react-i18next";
import { DriverPaymentGatewayContext } from "../../../../contexts/DriverPaymentGatewayContext";
import { Button } from "../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../electrify_frontend_common/components/Buttonv2/types";
import { Tariff } from "../../../../../../@types/paymentProcess";
import { useUserData } from "../../../../../../hooks/state/useUserData";
import { Spinner, SpinnerSize } from "../../../../../../electrify_frontend_common/components/Spinner";
import { ElectrifySubProducts } from "../../../../../../enums";

enum State {
    SUCCESS = "SUCCESS",
    PROCESSING = "PROCESSING",
    REQUIRES_PAYMENT_METHOD = "REQUIRES_PAYMENT_METHOD",
    FAILED = "FAILED"
}

function Success() {

    const { exitPayment } = useContext(DriverPaymentGatewayContext);
    const { t } = useTranslation("driverPaymentSystem");


    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50" height="50" >
                <circle cx="25" cy="25" r="23" stroke="#4CAF50" strokeWidth="3" fill="white" />
                <path d="M14 25l7 7L36 18" stroke="#4CAF50" strokeWidth="3" fill="none" />
            </svg>
            <div className="mt-4">{t("feedback.payment-successful")}</div>

            <Button
                className="mt-8"
                onClick={() => {
                    exitPayment();
                }} variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE}>
                <div className="flex items-center"> Continue to platform <Arrow className="ml-2" /></div>

            </Button>
        </>
    )
}

function Failed() {

    const { goBackToPayment } = useContext(DriverPaymentGatewayContext);
    const { t } = useTranslation("driverPaymentSystem");


    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50" height="50">
                <circle cx="25" cy="25" r="23" stroke="#F44336" strokeWidth="3" fill="white" />
                <path d="M16 16l18 18" stroke="#F44336" strokeWidth="3" fill="none" />
                <path d="M16 34l18-18" stroke="#F44336" strokeWidth="3" fill="none" />
            </svg>

            <div className="mt-4">{t("feedback.payment-failed")}</div>
            <Button
                className="mt-8"
                onClick={() => {
                    goBackToPayment();
                }} variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE}>
                <div className="flex items-center"> Insert alternative payment method <Arrow className="ml-2" /></div>

            </Button>
        </>
    )
}


function Processing() {

    const { exitPayment } = useContext(DriverPaymentGatewayContext);

    const { t } = useTranslation("driverPaymentSystem");

    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="50" height="50">
                <circle cx="25" cy="25" r="23" stroke="#757575" strokeWidth="3" fill="white" />

                <circle cx="25" cy="25" r="12" stroke="#757575" strokeWidth="3" fill="#757575" />
            </svg>
            <div className="mt-4">{t("feedback.payment-processing")}</div>
            <div className="mt-2 text-sm">You can still use our platform while the payment is getting processed</div>

            <Button
                className="mt-8"
                onClick={() => {
                    exitPayment()
                }} variant={ButtonVariant.PRIMARY} size={ButtonSize.LARGE}>
                <div className="flex items-center"> Continue to platform <Arrow className="ml-2" /></div>

            </Button>
        </>
    )

}

export function PaymentState({ tariff, clientSecret }: { tariff: Tariff, clientSecret: string }) {

    const stripe = useStripe();
    const { user } = useUserData();
    const [state, setState] = useState<State | null>(State.FAILED);

    const [isLoadingState, setIsLoadingState] = useState(true);


    // TODO to be replaced by webhook or other deterministic mechanism to guarantee our database has synced with Stripe
    useEffect(() => {
        const timeout = setTimeout(() => {
            setIsLoadingState(false);
        }, 4000);

        return () => {
            clearTimeout(timeout);
        }
    }, [])


    const checkPaymentIntention = () => {
        if (user?.accountInfo?.preSelectedProduct === ElectrifySubProducts.DRIVER_DONGLE) {
            if (stripe && clientSecret) {
                stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {

                    switch (setupIntent?.status) {
                        case 'succeeded':
                            setState(State.SUCCESS)
                            break;
                        case 'processing':
                            setState(State.PROCESSING)
                            break;
                        case 'requires_payment_method':
                            setState(State.REQUIRES_PAYMENT_METHOD)
                            break;
                        default:
                            setState(State.FAILED);
                            break;
                    }
                });
            }
        } else {
            if (stripe && clientSecret) {
                stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {

                    switch (paymentIntent?.status) {
                        case 'succeeded':
                            setState(State.SUCCESS)
                            break;
                        case 'processing':
                            setState(State.PROCESSING)
                            break;
                        case 'requires_payment_method':
                            setState(State.REQUIRES_PAYMENT_METHOD)
                            break;
                        default:
                            setState(State.FAILED);
                            break;
                    }
                });
            }
        }

    }

    const content = useMemo(() => {
        return {
            [State.SUCCESS]: <Success />,
            [State.PROCESSING]: <Processing />,
            [State.REQUIRES_PAYMENT_METHOD]: <Failed />,
            [State.FAILED]: <Failed />
        }
    }, [isLoadingState])


    useEffect(() => {
        checkPaymentIntention();
    }, [stripe])


    return (
        <div className='flex flex-col w-full h-full overflow-hidden'>
            <div className='grid grid-cols-2 h-full'>
                <ProductInfo tariff={tariff} />
                <div className="flex flex-col w-full h-full justify-center items-center">
                    {isLoadingState ?
                        <div className="flex flex-col w-full items-center">
                            <Spinner size={SpinnerSize.LARGE} />
                            <div className=" mt-5">Loading payment info</div>
                        </div> : <>{state ? content[state] : <Spinner />}</>}

                </div>
            </div>
        </div>
    )


}