import { ReactElement, ReactNode, useRef, useState } from 'react';
import { TooltipPlacements, useTooltipPlacement } from './useTooltipPlacement';


function ToolTipBody({ content, hovered, placement, childElement }: {
    content: ReactNode,
    hovered: boolean,
    placement: TooltipPlacements,
    childElement: Element,
}) {

    const { tooltipPlacingStyle, tooltipRef, arrowFromPlacement } = useTooltipPlacement({ childElement, placement })


    return (
        <div
            ref={tooltipRef}
            style={{
                position: 'fixed',
                opacity: hovered ? 0.9 : 0,
                top: hovered ? tooltipPlacingStyle.top : -1000,
                left: hovered ? tooltipPlacingStyle.left : -1000,

            }}
            className={`${hovered ? 'z-50' : '-z-10'} p-3 bg-Grey-dark transition-opacity duration-500 rounded-md  text-white`}
        >
            {arrowFromPlacement}
            <div className='text-xs' >{content}</div>
        </div>
    )

}

export function Tooltip({
    children,
    content,
    placement,
    className, // NOTE: Get rid of this. This is a workaround for the div wrapper that sometimes messed teh style of the child element in structures such as grid (check (Address Card Power Squares for ref))
    disabled
}: {
    children: ReactElement;
    content: ReactElement;
    placement: TooltipPlacements;
    className?: string;
    disabled?: boolean;
}) {
    const [hovered, setHovered] = useState(false);
    const childRef = useRef<any>(null);

    return <>
        <ToolTipBody
            childElement={childRef?.current}
            content={content}
            hovered={hovered}
            placement={placement}
        />
        <div
            ref={childRef}
            className={`flex ${disabled ? '' : 'cursor-pointer'} w-fit z-10 ${className || ""}`}
            onMouseEnter={() => { if (!disabled) setHovered(true) }}
            onMouseLeave={() => setHovered(false)}
        >
            {children}
        </div>
    </>;
}
