import { useTranslation } from "react-i18next"
import { BooleanOption } from "../../../../../electrify_frontend_common/components/EvFinder/FilterComponents/BooleanOption"



export function CheckboxSection({ filtersHandler, handleFilterChange }: { filtersHandler: any, handleFilterChange: any }) {

    const { t } = useTranslation("evFinder");

    return (
        <div className="mt-4">
            <BooleanOption
                value={filtersHandler?.readFilterValue("fastCharging")}
                onChange={(newValue: boolean) => {
                    console.log("New value: ", newValue);
                    handleFilterChange({ key: "fastCharging", newValue })
                }}
                label={t("filters.vehicleFeatures.checkboxes.fastCharging")}
            />

            <BooleanOption
                value={filtersHandler?.readFilterValue("plugAndCharge")}
                onChange={(newValue: boolean) => {
                    handleFilterChange({ key: "plugAndCharge", newValue })
                }}
                label={t("filters.vehicleFeatures.checkboxes.plugAndCharge")}
            />

            <BooleanOption
                value={filtersHandler?.readFilterValue("vehicleToGrid")}
                onChange={(newValue: boolean) => {
                    handleFilterChange({ key: "vehicleToGrid", newValue })
                }}
                label={t("filters.vehicleFeatures.checkboxes.vehicleToGrid")}
            />

            <BooleanOption
                value={filtersHandler?.readFilterValue("isofixAvailable")}
                onChange={(newValue: boolean) => {
                    handleFilterChange({ key: "isofixAvailable", newValue })
                }}
                label={t("filters.vehicleFeatures.checkboxes.isofix")}
            />

            <BooleanOption
                value={filtersHandler?.readFilterValue("towing")}
                onChange={(newValue: boolean) => {
                    handleFilterChange({ key: "towing", newValue })
                }}
                label={t("filters.vehicleFeatures.checkboxes.towHitch")}
            />

            <BooleanOption
                value={filtersHandler?.readFilterValue("roofRails")}
                onChange={(newValue: boolean) => {
                    handleFilterChange({ key: "roofRails", newValue })
                }}
                label={t("filters.vehicleFeatures.checkboxes.roofRails")}
            />

        </div>
    )


}