import { useTranslation } from "react-i18next";
import ParkingTypeGraph from "./ParkingTypeChart";
import ChartWrapper from "../../../../../../../../common/components/Charts/ChartWrapper";

export default function ParkingType({
  data,
  sort,
  openExpanded,
  sortedCrescent,
}: {
  data: any,
  sort: () => void,
  openExpanded: () => void,
  sortedCrescent?: boolean
}) {
  const { t } = useTranslation("parkingAnalysis");

  return (
    <ChartWrapper
      title={t("parking-type-title")}
      sort={sort}
      expand={openExpanded}
      sortedCrescent={sortedCrescent}
    >
      <ParkingTypeGraph data={data} />
    </ChartWrapper>
  );
}
