import { CurrentVehicle } from "./CurrentVehicle";
import { TripData } from "./TripData";
import { useQuery } from "@apollo/client";
import { Locations } from "./Locations";
import { FuelAndEnergyDetails } from "./FuelAndEnergyDetails";
import { ChargingPreferences } from "./ChargingPreferences";
import { useSettingsRefsAutoFocus } from "./hooks";

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLayout } from "../../../../../hooks/state/useLayout";
import { useUserData } from "../../../../../hooks/state/useUserData";
import { Vehicle } from "../../../../../@types/vehicle";
import { GET_DRIVER_VEHICLE } from "../../../../../services/graphql/driver/queries";
import { Setting } from "../../../../../@types/settings";
import { GET_SETTING } from "../../../../../services/graphql/fleet/queries";


export function Settings() {

  const { t, i18n } = useTranslation("driverSettings");

  const language  = i18n.language;

  const { setHeader } = useLayout();

  useEffect(() => {
    setHeader({
      defaultHeaderTitle: t("page-title"),
      defaultHeaderDescription: t("page-description"),
    });
  }, [language])


  const { updateUserSettings } = useUserData();

  const { tripDataRef } = useSettingsRefsAutoFocus();

  const { data, refetch } = useQuery<{ driverVehicle: Vehicle }>(GET_DRIVER_VEHICLE);

  const { data: settingsData } = useQuery<{setting: Setting}>(GET_SETTING, {
    onCompleted: (data) => {
      updateUserSettings(data.setting || {});
    },
  });

  return (
    <div className="p-10">
      <CurrentVehicle vehicle={data?.driverVehicle} refetchVehicle={refetch} />
      <div ref={tripDataRef}>
        <TripData refetchVehicle={refetch} vehicle={data?.driverVehicle} />
      </div>
      <Locations vehicle={data?.driverVehicle} />
      <FuelAndEnergyDetails />
      <ChargingPreferences />
    </div>
  );
}
