import { useTranslation } from "react-i18next";
import { useContext, useEffect } from "react";
import { ElectrificationProgress } from "./components/ElectrificationProgress";
import { VehicleDeliveryDate } from "./components/VehicleDeliveryDate";
import { VehicleSection } from "./components/Sections/VehicleSection";
import { ChargingSection } from "./components/Sections/ChargingSection";
import { AdditionalServicesSection } from "./components/Sections/AdditionalServicesSection";

import { ElectrificationPlannerOnboarding } from "./Onboarding";
import { useUserData } from "../../../../hooks/state/useUserData";
import { useLayout } from "../../../../hooks/state/useLayout";
import { ElectrifyPlannerContextProvider } from "../../contexts/ElectrificationPlannerContext";
import { WrapperGuard } from "../../../../guards";




export function Checklist() {

    const { user } = useUserData();

    const { t, i18n } = useTranslation("electrificationPlanner");
    const language = i18n.language;

    const { setHeader } = useLayout();

    useEffect(() => {
        setHeader({
            defaultHeaderTitle: t("title"),
            defaultHeaderDescription: t("description"),
        });
    }, [language]);


    return (

        <ElectrifyPlannerContextProvider>
            <WrapperGuard
                guardView={<ElectrificationPlannerOnboarding />}
                dependencies={[user?.accountInfo?.electrificationPlannerOnboarding?.complete]}
                accessCondition={!!user?.accountInfo?.electrificationPlannerOnboarding?.complete}
            >
            <div className="p-10 flex flex-col flex-1">
                <ElectrificationProgress />

                <VehicleDeliveryDate />

                <div className="grid grid-cols-3 h-full mt-5 gap-4">
                    <div className="col-span-1 border border-Grey-default px-4 py-6 rounded">
                        <VehicleSection />
                    </div>
                    <div className="col-span-1 border border-Grey-default px-4 py-6 rounded">
                        <ChargingSection />
                    </div>
                    <div className="col-span-1 border border-Grey-default px-4 py-6 rounded">
                        <AdditionalServicesSection />
                    </div>

                </div>
            </div>
            </WrapperGuard >
        </ElectrifyPlannerContextProvider>
       
    )


}