import { Tooltip } from "../../../../../electrify_frontend_common/components/Tooltip";


export const ChargingPowerSquare = ({
  placeholder,
  value,
  addressPower,
  classNames,
  onClick,
  t,
}: {
  placeholder: string;
  value: number | null;
  addressPower: number | null;
  classNames?: string;
  onClick: (value: number | null) => void;
  t: any;
}) => {
  const isSelected = addressPower === value;
  return (
    <Tooltip
      className="w-full"
      content={<div className="whitespace-nowrap">{value
        ? isSelected
          ? t("address-card.power-tooltip.power-selected")
          : t("address-card.power-tooltip.change-power")
        : t("address-card.power-tooltip.no-charge-point")}</div>}

      placement="top-start"
    >
      <div
        onClick={isSelected ? () => null : () => onClick(value)}
        className={`border border-solid border-Grey-tint flex justify-center items-center ${isSelected
          ? "text-white bg-Blueberry-dark-default"
          : "bg-white cursor-pointer hover:bg-Blueberry-light-default"
          } ${classNames ? classNames : ""}`}
      >

        <>{placeholder}</>

      </div>
    </Tooltip>
  );
};
