


export function Price({color = "black", className} : {color?: string, className?: string}) {



    return (
        <svg className={className} width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.76096 10.1551C6.04349 10.1551 6.31872 10.134 6.58664 10.0919C6.85456 10.0498 7.1103 9.99131 7.35386 9.91644L7.5 11.0394C7.22721 11.1049 6.94224 11.1564 6.64509 11.1938C6.34795 11.2313 6.04836 11.25 5.74635 11.25C5.16667 11.25 4.6357 11.1658 4.15344 10.9973C3.67119 10.8242 3.2547 10.5692 2.90397 10.2323C2.55324 9.89071 2.28045 9.46725 2.0856 8.9619C1.89562 8.45187 1.80063 7.85762 1.80063 7.17914V4.84191C1.80063 4.15876 1.89562 3.56217 2.0856 3.05214C2.27557 2.54211 2.54593 2.11631 2.89666 1.77473C3.24739 1.43316 3.66145 1.17814 4.13883 1.00969C4.62109 0.836564 5.15449 0.75 5.73904 0.75C6.04593 0.75 6.34064 0.771056 6.62317 0.813168C6.91058 0.850602 7.20285 0.902072 7.5 0.96758L7.35386 2.09759C7.11517 2.02273 6.85943 1.96424 6.58664 1.92213C6.31385 1.87533 6.03619 1.85194 5.75365 1.85194C5.36395 1.85194 5.01079 1.91277 4.69415 2.03443C4.38239 2.15608 4.11447 2.33857 3.8904 2.58188C3.67119 2.8252 3.50313 3.13402 3.38622 3.50836C3.26931 3.88269 3.21086 4.32253 3.21086 4.82787V7.17914C3.21086 7.68449 3.26931 8.12433 3.38622 8.49866C3.508 8.87299 3.68093 9.18182 3.90501 9.42513C4.12909 9.66845 4.39701 9.85094 4.70877 9.97259C5.0254 10.0943 5.37613 10.1551 5.76096 10.1551ZM6.0167 4.60328V5.48061H0.5V4.60328H6.0167ZM6.0167 6.43516V7.30548H0.5V6.43516H6.0167Z" fill={color} />
        </svg>
    )


}