import { Dispatch, SetStateAction } from "react";
import { useLayout } from "../../../../hooks/state/useLayout";

export function LabeledInput({
  unitsLabel,
  value,
  setValue,
  defaultValue,
  onBlur,
}: {
  unitsLabel: string;
  defaultValue: number;
  setValue: Dispatch<SetStateAction<number | null | undefined>>;
  value: number | null | undefined;
  onBlur?: () => void;
}) {
  const { localeCode } = useLayout();

  return (
    <div className="flex items-center border border-solid border-Grey-tint text-xs text-center rounded h-10 py-1 ">
      <input
        className={`text-center  focus:ring-0 w-4/6 h-3/4 text-black  border-none`}
        type="number"
        value={typeof value == "number" ? value : ""}
        placeholder={defaultValue.toLocaleString(localeCode)}
        onInput={(e: any) => {
          if (e.target.value === "") {
            setValue(null);
          } else if (isNaN(parseFloat(e.target.value))) {
            setValue(e.target.value);
          } else {
            setValue(parseFloat(e.target.value));
          }
        }}
        onBlur={() => {
          if (typeof value == "number" || value == undefined) {
            if (onBlur) onBlur();
          }
        }}
      />
      <div
        className={`ml-[-20px] text-md z-20 ${
          !value ? "text-Grey-default" : "text-black"
        }`}
      >
        {unitsLabel}
      </div>
    </div>
  );
}
