/* react imports */
import { useState } from "react";

/* third party imports */

/* local imports */
import { useTranslation } from "react-i18next";
import WebfleetSync from "./SyncProcess/WebfleetSync";
import { FleetManualDataUpload } from "./FleetManualDataUpload";

enum UploadMethods {
  SYNC = "sync",
  MANUAL = "manual"
}

export default function DataUpload({ onCancel }: { onCancel: () => void}) {

  const { t } = useTranslation("dataProviderUpload");

  const [uploadMethod, setUploadMethod] = useState<UploadMethods | null>(null);

  return (
    <>
      <hr className="border-solid border-Grey-tint" />
      <div className="p-10">
        <div className="mb-6">
          <h2 className="text-xl text-Black-default mb-4">
            {t("source-title")}
          </h2>
          <p className="text-Grey-shade text-sm">{t("source-description")}</p>
        </div>
        <div className="grid grid-cols-2 gap-x-6">
          <button
            className={`p-6 rounded text-left border ${uploadMethod != UploadMethods.SYNC
              ? "border-Grey-tint"
              : "border-Grey-shade bg-Grey-tint"
              } hover:bg-Grey-background`}
            onClick={() => setUploadMethod(UploadMethods.SYNC)}
          >
            <h2
              className={`${uploadMethod === UploadMethods.SYNC
                ? " text-lg text-Blueberry-dark-default "
                : " text-lg text-Grey-shade "
                }`}
            >
              {t("source-1-title")}
            </h2>
            <p className="text-sm text-Grey-shade">
              {t("source-1-description")}
            </p>
          </button>
          <button
            className={`p-6 rounded text-left border ${uploadMethod != UploadMethods.MANUAL
              ? "border-Grey-tint"
              : "border-Grey-shade bg-Grey-tint"
              } hover:bg-Grey-background`}
            onClick={() => setUploadMethod(UploadMethods.MANUAL)}
          >
            <h2
              className={`${uploadMethod === UploadMethods.MANUAL
                ? " text-lg text-Blueberry-dark-default "
                : " text-lg text-Grey-shade "
                }`}
            >
              {t("source-2-title")}
            </h2>
            <p className="text-sm text-Grey-shade">
              {t("source-2-description")}
            </p>
          </button>
        </div>
      </div>

      {uploadMethod ? (
        uploadMethod === "sync" ? (
          <WebfleetSync onCancel={onCancel} />
        ) : (
          <FleetManualDataUpload close={onCancel}/>

          // onCancel={onCancel} goToPage={goToPageProps.action} goToPageText={goToPageProps.text}
        )
      ) : null}
    </>
  );
}
