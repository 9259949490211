import { useContext, useMemo } from "react";
import { SectionContentWrapper, SectionHeader } from "../utils";
import { ElectrificationPlannerChargingSectionSubSections } from "../../../models/Section";
import { getSectionProgress } from "../getSectionProgress";
import { useTranslation } from "react-i18next";
import { ElectrifyPlannerContext } from "../../../../../contexts/ElectrificationPlannerContext";

function ChargingIcon() {

    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_6597_68492)">
                <path d="M16.4783 11.9472C16.5545 11.8232 16.5964 11.6811 16.5994 11.5356C16.6025 11.39 16.5667 11.2463 16.4957 11.1192C16.4247 10.9922 16.321 10.8863 16.1955 10.8126C16.07 10.7389 15.927 10.7 15.7815 10.7H12.9999V3.5L7.12147 13.0536C7.04513 13.1775 7.00326 13.3196 7.00018 13.4651C6.9971 13.6106 7.03293 13.7543 7.10395 13.8814C7.17497 14.0084 7.27863 14.1142 7.4042 14.1878C7.52977 14.2614 7.67272 14.3001 7.81827 14.3H10.5999V21.5L16.4783 11.9472Z" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_6597_68492">
                    <rect width="20" height="20" fill="white" transform="translate(2 2.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}

export function ChargingSection() {

    const { chargingSection } = useContext(ElectrifyPlannerContext);

    const {t} = useTranslation('electrificationPlanner');


    const progress = useMemo(() => {
        return getSectionProgress(chargingSection)
    }, [chargingSection]);

    if (!chargingSection) return null;

    return (
        <div>
            <SectionHeader progress={progress} icon={<ChargingIcon />} title={t("chargingSection.title")} description={t("chargingSection.description")} />
            <SectionContentWrapper title={t("chargingSection.subsections.homeCharging.title").toUpperCase()} >
                {chargingSection?.renderSubsection(ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING)}
            </SectionContentWrapper>
            <SectionContentWrapper title={t("chargingSection.subsections.wallbox.title").toUpperCase()} >
                {chargingSection?.renderSubsection(ElectrificationPlannerChargingSectionSubSections.WALLBOX)}
            </SectionContentWrapper>
            <SectionContentWrapper title={t("chargingSection.subsections.energy.title").toUpperCase()} >
                {chargingSection.renderSubsection(ElectrificationPlannerChargingSectionSubSections.ENERGY)}
            </SectionContentWrapper>
            <SectionContentWrapper title={t("chargingSection.subsections.workplaceCharging.title").toUpperCase()} >
                {chargingSection.renderSubsection(ElectrificationPlannerChargingSectionSubSections.WORKPLACE_CHARGING)}
            </SectionContentWrapper>
            <SectionContentWrapper title={t("chargingSection.subsections.publicCharging.title").toUpperCase()} >
                {chargingSection.renderSubsection(ElectrificationPlannerChargingSectionSubSections.PUBLIC_CHARGING)}
            </SectionContentWrapper>
        </div>
    )

}