import { CloseIcon } from "../../../../../../icons/CloseIcon";


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = (backgroundColor: "light" | "dark") => ({
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            backgroundColor: backgroundColor === "light" ? "white" : "#1e1e21",
        },
    },
    disableScrollLock: true,
    marginThreshold: null,
    autoFocus: false
});

export const SearchInput = ({ filter, setFilter, backgroundColor}: { filter: string, setFilter: (value: string) => void, backgroundColor?: "dark" | "light" }) => {

    console.log("BackgroundColor", backgroundColor);
    return (
        <div className="flex w-full">
            <div
                className={`w-full ${backgroundColor === "dark" ? "bg-Black-background" : "bg-white"} p-2`}
                onClick={(e) => {
                    e.preventDefault();
                }}
                onClickCapture={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <input
                    type="text"
                    className={`w-full text-sm ${backgroundColor === "dark" ? "bg-Black-background text-white border-white" : "bg-white text-Black-background border-Black-background"}  rounded-sm`}
                    placeholder="Search"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                />
            </div>
        </div>
    )

}

export const SelectedBrand = ({ value, brand, handleOnChangeDropdown }: { value: string[], brand: string, handleOnChangeDropdown: (value: string[]) => void }) => (
    <div
        key={brand}
        className="p-1 m-0.5 px-2 text-xs rounded-full bg-gray-600 w-fit flex items-center cursor-pointer"
    >
        <div>{brand}</div>
        <div className="w-3.5 h-3.5 rounded-full bg-gray-400 flex items-center justify-center ml-2 hover:opacity-70">
            
            <CloseIcon
                onMouseDown={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    const newValues = value!.filter((v: string) => v !== brand);
                    handleOnChangeDropdown(newValues);
                }}
                className="max-w-3"
            />
        </div>
    </div>
);
