import { useMutation } from "react-query";
import { useContext, useState } from "react";
import { PaymentContext } from "../../../../../../../../contexts/PaymentContext";
import { useUserData } from "../../../../../../../../hooks/state/useUserData";
import { Address } from "../../../../../../../../@types";
import { updateUserPaymentInfo } from "../../../../../../../../services/rest/paymentProcess";





export function usePaymentAddress() {

    const { confirmPayment, selectedTariff, loadingPaymentIntent, setLoadingPaymentIntent } = useContext(PaymentContext);
    const { user } = useUserData();

    const billingInfo = user?.accountInfo?.billingInfo;
    const shippingInfo = user?.accountInfo?.shippingInfo;

    const [shippingAddress, setShippingAddress] = useState<{ address: Address, name: string } | undefined>(shippingInfo);
    const [billingAddress, setBillingAddress] = useState<{ address: Address, name?: string } | undefined>(billingInfo);

    const [billingErrors, setBillingErrors] = useState<any>({
        name: false,
        street: false,
        streetNr: false,
        zip: false,
        city: false,
        country: false
    });

    const [shippingErrors, setShippingErrors] = useState<any>({
        name: false,
        street: false,
        streetNr: false,
        zip: false,
        city: false,
        country: false
    })

    const billingIsSameAsShipping = () => {
        return (billingInfo?.address?.street === shippingInfo?.address?.street) &&
            (billingInfo?.address?.others === shippingInfo?.address?.others) &&
            (billingInfo?.address?.streetNr === shippingInfo?.address?.streetNr) &&
            (billingInfo?.address?.city === shippingInfo?.address?.city) &&
            (billingInfo?.address?.country === shippingInfo?.address?.country) &&
            (billingInfo?.address?.zip === shippingInfo?.address?.zip)
    }

    const [sameBillingAddress, setSameBillingAddress] = useState<boolean>(billingIsSameAsShipping());


    const updateUserPaymentDetailsMutation = useMutation(updateUserPaymentInfo, {
        onSuccess: () => {
            confirmPayment(selectedTariff?.id);
        }, onError: (e: any) => {
            const error = e?.response?.data;
            if (error.statusCode == 400 && error.message == "Not a valid address") {
                console.log("Setting invalid address!!");
            };
        }
    })

    const isPropFilled = (prop: any) => !prop || prop?.length == 0;

    const canSubmitWithShipping = () => {
        const newBillingErrors = {
            name: isPropFilled(billingAddress?.name),
            street: isPropFilled(billingAddress?.address?.street),
            streetNr: isPropFilled(billingAddress?.address?.streetNr),
            city: isPropFilled(billingAddress?.address?.city),
            country: isPropFilled(billingAddress?.address?.country),
            zip: isPropFilled(billingAddress?.address?.zip)
        };

        const newShippingErrors = {
            name: isPropFilled(shippingAddress?.name),
            street: isPropFilled(shippingAddress?.address?.street),
            streetNr: isPropFilled(shippingAddress?.address?.streetNr),
            city: isPropFilled(shippingAddress?.address?.city),
            country: isPropFilled(shippingAddress?.address?.country),
            zip: isPropFilled(shippingAddress?.address?.zip)
        }

        setBillingErrors(newBillingErrors);
        setShippingErrors(newShippingErrors);

        let anyError = false;
        const obj = sameBillingAddress ? newShippingErrors : { ...newShippingErrors, ...newBillingErrors };

        Object.entries(obj).forEach(([k, v]) => {
            if (v) {
                console.log("Error on: ", k);
                anyError = true;
            }
        })

        return !anyError;
    }

    const canSubmitWithoutShipping = () => {
        const newShippingErrors = {
            name: isPropFilled(billingAddress?.name),
            street: isPropFilled(billingAddress?.address?.street),
            streetNr: isPropFilled(billingAddress?.address?.streetNr),
            city: isPropFilled(billingAddress?.address?.city),
            country: isPropFilled(billingAddress?.address?.country),
            zip: isPropFilled(billingAddress?.address?.zip)
        };

        setBillingErrors(newShippingErrors);

        let anyError = false;
        Object.entries(newShippingErrors).forEach(([k, v]) => {
            if (v) {
                anyError = true;
            }
        });

        return !anyError;
    }

    const submitWithoutShipping = () => {

        const canSubmit = canSubmitWithoutShipping();

        if (canSubmit) {
            setLoadingPaymentIntent(true);
            updateUserPaymentDetailsMutation.mutate({
                billingInfo: {
                    name: billingAddress?.name,
                    address: billingAddress?.address
                }
            });
        }
    }


    const submitWithShipping = () => {

        const canSubmit = canSubmitWithShipping();

        if (canSubmit) {
            setLoadingPaymentIntent(true);
            updateUserPaymentDetailsMutation.mutate({
                billingInfo: {
                    name: sameBillingAddress ? shippingAddress?.name : billingAddress?.name,
                    address: sameBillingAddress ? shippingAddress?.address : billingAddress?.address
                },
                shippingInfo: {
                    name: shippingAddress?.name,
                    address: shippingAddress?.address
                }
            })
        }

    }

    const resetShippingError = (key: string) => {
        setShippingErrors((se: any) => {
            const currentErrors = se;
            currentErrors[key] = false;
            return currentErrors;
        })
    };

    const resetBillingError = (key : string) => {
        setBillingErrors((se: any) => {
            const currentErrors = se;
            currentErrors[key] = false;
            return currentErrors;
        })
    }

    return {
        confirmPayment,
        updateUserPaymentInfo: updateUserPaymentDetailsMutation.mutate,
        selectedTariff,
        loadingPaymentIntent,
        setLoadingPaymentIntent,
        shippingAddress,
        setShippingAddress,
        billingAddress,
        setBillingAddress,
        sameBillingAddress,
        setSameBillingAddress,
        billingErrors,
        submitWithShipping,
        shippingErrors,
        submitWithoutShipping,
        resetBillingError,
        resetShippingError,
    }

}