import { useQuery } from "@apollo/client";

import { useTranslation } from "react-i18next";
import { useState } from "react";

import { ReactComponent as PlusIcon } from '../../../../../../../assets/plus-icon.svg';
import { GET_ADDRESSES } from "../../../../../../../services/graphql/fleet/queries";
import LocationModal from "../../../../../../../common/components/Address/AddressCard/modals/LocationModal";
import { ButtonSize, ButtonVariant } from "../../../../../../../electrify_frontend_common/components/Buttonv2/types";
import { Button } from "../../../../../../../electrify_frontend_common/components/Buttonv2";
import AddressCard from "../../../../../../../common/components/Address/AddressCard";
import { Location } from "../../../../../../../@types/settings";


export default function VehicleHomeLocations({
  vehicleId,
}: {
  vehicleId: string;
}) {
  const { t } = useTranslation("editVehicle");

  const [newAddressOpen, setNewAddressOpen] = useState(false);

  const {
    data: addressesData,
    refetch,
  } = useQuery(GET_ADDRESSES, {
    variables: {
      vehicleId: vehicleId,
      locationType: "HOME",
    },
  });

  return (
    <>
      {newAddressOpen ? (
        <LocationModal
          isOpen={newAddressOpen}
          locationType={"HOME"}
          vehicleId={vehicleId}
          closeModal={() => {
            setNewAddressOpen(false);
            refetch();
          }}
        />
      ) : null}
      <div className="h-auto px-8">
        <div className="flex items-center">
          <div className="w-2/3 mb-4">
            <h1 className="text-xl mb-4">{t("vehicle-addresses")}</h1>
            <span className="text-sm text-Grey-shade">
              {t("vehicle-addresses-message")}
            </span>
          </div>
          <div className="w-1/3 flex justify-end  max-h-fit">
            <Button onClick={() => setNewAddressOpen(true)} variant={ButtonVariant.TERTIARY} size={ButtonSize.SMALL}>
              <div className="flex items-center">
                <PlusIcon className="mr-2" />
                <span className="text-Grey-dark">{t("add-vehicle-address")}</span>
              </div>
            </Button>

          </div>
        </div>

        <div className="flex">
          {addressesData &&
            addressesData.addresses?.map((va: Location) => (
              <div className="p-2">
                <AddressCard
                  refetch={refetch}
                  key={va.id}
                  address={va}
                  locationType={"HOME"}
                />
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
