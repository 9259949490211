import { useState } from "react";
import { ReactComponent as CollapseIcon } from '../../../../../assets/collapse-icon.svg';
import { ReactComponent as ExpandIcon } from '../../../../../assets/expand-icon.svg';
import { RegularFilters } from "../RegularFilters";
import { IFilters } from "../../../../../types";



export function CollapsableFilters({
    backgroundColor,
    filters,
    updateFilters,
    resetFilters,
    currencySymbol
}: {
    filters: IFilters,
    updateFilters: (filters: Partial<IFilters>) => void,
    resetFilters: () => void,
    backgroundColor: "light" | "dark",
    currencySymbol: string
}) {

    const [collapsed, setCollapsed] = useState(true);


    return (
        <>
            <div onClick={() => setCollapsed(!collapsed)} className={`flex justify-between items-center ${backgroundColor === "dark" ? "text-white" : "text-black"} py-2`}>
                <span className="text-xs font-bold">FILTERS</span>
                {collapsed ? <ExpandIcon /> : <CollapseIcon />}
            </div>
            <div className={`${collapsed ? 'hidden' : 'block'}`}>
                <RegularFilters currencySymbol={currencySymbol} filters={filters} updateFilters={updateFilters} resetFilters={resetFilters} backgroundColor={backgroundColor} />
            </div>
        </>
    )


} 