import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";


export function useSettingsRefsAutoFocus() {

    const location = useLocation();
    const search = location.search?.split('?')[1];
    const currentVehicleRef = useRef<any>(null);
    const tripDataRef = useRef<any>(null);

    useEffect(() => {
        let currentRef;
        switch (search) {
            case 'trip-data':
                currentRef = tripDataRef;
                break;
        }

        if (currentRef?.current) 
            currentRef?.current?.scrollIntoView({ behavior: 'smooth' });
        
    }, [search, tripDataRef?.current])

    return {
        currentVehicleRef,
        tripDataRef,
    }


}