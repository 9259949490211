/* React imports */
import { useEffect, useState } from "react";

/* Third party imports */
import queryString from "query-string";
import { useTranslation } from "react-i18next";

/* Local imports */
import { useNavigate } from "react-router-dom";
import { GenericErrorPage } from "../../errors/GenericError";
import { checkResetTokenValidity, resetPassword } from "../../../../services/keycloak";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PasswordInput } from "../Signup/components/utils";
import { PasswordResetErrors } from "../../../../errors";
import { Button } from "../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../electrify_frontend_common/components/Buttonv2/types";


export default function ResetPassword() {
  const { t } = useTranslation("resetPassword");
  const { token } = queryString.parse(window.location.search);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const [resetTokenExpired, setResetTokenExpired] = useState(false);



  useEffect(() => {
    checkResetTokenValidity({
      token: token,
    })
      .then((response: any) =>
        console.log("Token is valid! Response is: ", response)
      )
      .catch(({ response }: { response: any }) => {
        if (response?.status === 401) {
          setResetTokenExpired(true);
        }
      });
  }, []);



  const onSubmit = async (password: string, confirmPassword: string) => {
    setLoading(true);
    if (password === confirmPassword) {
      try {
        await resetPassword({
          token: token,
          password: password,
          confirmPassword: confirmPassword,
        });
        setError('');
        setSuccess(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      } catch (error: any) {
        const errorMessage = error.response?.message;
        if (errorMessage === PasswordResetErrors.USER_NOT_FOUND) {
          setResetTokenExpired(true);
        }
        
        else if (errorMessage === PasswordResetErrors.KEYCLOAK_ERROR) {
          setError(t("errors.could-not-reset"));
        } else if (errorMessage === PasswordResetErrors.INVALID_TOKEN) {
          setResetTokenExpired(true);
        } else {
          setError(t("errors.could-not-reset"));
        }
      }
    } else {
      setError(t("passwords-dont-match"));
    }
    setLoading(false);
  };


  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('password-required')
        .min(8, 'password-format')
        .matches(/[a-z]/, 'password-format')
        .matches(/[A-Z]/, 'password-format')
        .matches(/[0-9]/, 'password-format')
        .matches(/[!@#$%^&*(),.?":{}|<>]/, 'password-format'),
      confirmPassword: Yup.string()
        .test('passwords-match', 'Passwords must match', function (value) {
          return this.parent.password === value
        })
    }),
    onSubmit: (values) => {
      onSubmit(values.password, values.confirmPassword);
    },
  });

  return (
    <>
      {resetTokenExpired ? (
        <GenericErrorPage
          title={t("errors.link-expired")}
          text={t("errors.link-expired-message")}
          onClickButton={() => {
            navigate("/forgot-password");
          }}
          buttonText={t("reset")}
        />
      ) : (
        <>
          {success ? (
            <>
              <div className="w-full flex justify-start className='mb-10'">
                <h1 className="text-3xl text-Black-default mb-2">
                  {t("success.title")}
                </h1>
              </div>
              <div className="w-full">
                <p className="text-base text-Grey-shade">
                  {t("success.description")}
                </p>
              </div>
            </>
          ) : (
            <form className="h-full" onSubmit={formik.handleSubmit}>
              <div className="w-full flex justify-start">
                <h1 className="text-3xl text-Black-default mb-2">
                  {t("title")}
                </h1>
              </div>
              <div className="mb-10 text-base text-Grey-shade">
                <p>{t("description1")}</p>
                <p>{t("description2")}</p>
              </div>

              <div className="w-full flex flex-col space-y-6">
                <PasswordInput
                  name="password"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  value={formik.values.password}
                  placeholder={t("new-password")}
                  label={t("new-password")}
                  error={formik.errors.password ? t(`${formik.errors.password}`) : undefined}
                  touched={formik.touched.password}
                />
                <PasswordInput
                  name="confirmPassword"
                  handleChange={formik.handleChange}
                  handleBlur={formik.handleBlur}
                  value={formik.values.confirmPassword}
                  placeholder={t("repeat-new-password")}
                  hideStrengthBar
                  label={t("repeat-new-password")}
                  error={formik.errors.confirmPassword ? t(`${formik.errors.confirmPassword}`) : undefined}
                  touched={formik.touched.confirmPassword}
                />

                <Button
                  variant={ButtonVariant.PRIMARY}
                  size={ButtonSize.LARGE}
                  onClick={() => null}
                >
                  {t("reset")}
                </Button>

              </div>
            </form>
          )}
        </>
      )}
    </>
  );
}
