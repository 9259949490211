
import { useTranslation } from "react-i18next";

import ModalWrapper from "../../../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../../../electrify_frontend_common/components/ModalWrapper/Headers";
import { Button } from "../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../electrify_frontend_common/components/Buttonv2/types";


export default function DeleteModal({ isOpen, closeModal, onDelete }: { isOpen: boolean, closeModal: () => void, onDelete: () => void }) {

  const { t } = useTranslation("addresses");

  return (
    <ModalWrapper
      isOpen={isOpen}
      close={closeModal}
      className="w-1/2">
      <div className="w-full">
        <ModalHeader
          title={t("delete-location.title")}
          onCancel={closeModal}
          isLastStep
        />

        <div className="p-10">
          <p>{t("delete-location.description")}</p>
          <div className="mt-10">
            <Button
              onClick={onDelete}
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.LARGE}
            >
              <div className="px-10">
                {t("delete-location.delete-button")}
              </div>
            </Button>

          </div>
        </div>
      </div>

    </ModalWrapper>
  );
}
