import { TimeShare } from "./charts/TimeShare";
import { LocationType } from "./charts/LocationType";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { LocationTypeChart } from "./charts/LocationType/LocationTypeChart";
import { ChargingOptions } from "./charts/ChargingOptions";
import { ChargingOptionsChart } from "./charts/ChargingOptions/ChargingOptionsChart";
import { useTranslation } from "react-i18next";

import { TimeShareChart } from "./charts/TimeShare/TimeShareChart";
import useManagePaginatedModal from "../../../../../../hooks/utils/useManagePaginatedModal";
import { useLayout } from "../../../../../../hooks/state/useLayout";
import { GET_PARKING_ANALYSIS } from "../../../../../../services/graphql/driver/queries";
import { ExpandedChartWrapper } from "../../../../../../common/components/Charts/ExpandedChartWrapper";

const NUMBER_OF_PAGES = 3;

export function ParkingAnalysis() {
    const { t } = useTranslation("driverParkingAnalysis");
    const { i18n } = useTranslation();
    const language = i18n.language;

    const [parkingAnalysisData, setParkingAnalysisData] = useState<any>(null);
    const [page, setPage] = useState(null);

    const { expandedMode, setExpandedMode, incrementPage, decrementPage } =
        useManagePaginatedModal(NUMBER_OF_PAGES);

    const { setHeader } = useLayout();

    useEffect(() => {
        setHeader({
            defaultHeaderTitle: t("page-title"),
            defaultHeaderDescription: t("page-subtitle"),
        });
    }, [language]);

    useQuery(GET_PARKING_ANALYSIS, {
        onCompleted: (data) => {
            setParkingAnalysisData(data?.driverResultsParkingAnalysis);
        },
    });

    const buildExpandedViewContent = (customPage?: number) => {
        const caseVar = customPage ?? page;
        switch (caseVar) {
            case 0:
                return {
                    getTitle: () => t("parking.title"),
                    getChart: () => (
                        <TimeShareChart
                            data={{
                                parking: parkingAnalysisData?.durationDistribution.parking,
                                driving: parkingAnalysisData?.durationDistribution.driving,
                            }}
                            isModal
                        />
                    ),
                };
            case 1:
                return {
                    getTitle: () => t("parking-location-types.title"),
                    getChart: () => (
                        <LocationTypeChart
                            data={parkingAnalysisData?.parkingDurationLocationDistribution}
                            isModal
                        />
                    ),
                };
            case 2:
                return {
                    getTitle: () => t("parking-locations-charging.title"),
                    getChart: () => (
                        <ChargingOptionsChart
                            data={parkingAnalysisData?.dailyParkingDurationLocationDistribution}
                        />
                    ),
                };

            default:
                return null;
        }
    };

    return (
        <>
            {expandedMode.open ? (
                <ExpandedChartWrapper
                    buildExpandedViewContent={buildExpandedViewContent}
                    expandedMode={expandedMode}
                    closeExpanded={() => setExpandedMode({ open: false, page: 0 })}
                    incrementPage={() => incrementPage()}
                    decrementPage={() => decrementPage()}
                    numberOfPages={NUMBER_OF_PAGES}
                    page={page}
                    setPage={setPage}
                />
            ) : null}
            <div className="p-10">
                {parkingAnalysisData ? (
                    <div className="w-full flex justify-between">
                        <div className="w-1/2 h-full pr-4">
                            <TimeShare
                                openExpanded={() => setExpandedMode({ open: true, page: 0 })}
                                parking={parkingAnalysisData.durationDistribution.parking}
                                driving={parkingAnalysisData.durationDistribution.driving}
                            />
                        </div>
                        <div className="w-1/2 h-full pl-4">
                            <LocationType
                                openExpanded={() => setExpandedMode({ open: true, page: 1 })}
                                data={parkingAnalysisData.parkingDurationLocationDistribution}
                            />
                        </div>
                    </div>
                ) : null}

                {parkingAnalysisData ? (
                    <ChargingOptions
                        openExpanded={() => setExpandedMode({ open: true, page: 2 })}
                        data={parkingAnalysisData.dailyParkingDurationLocationDistribution}
                    />
                ) : null}
            </div>
        </>
    );
}
