import ChartWrapper from "../../../../../../../../common/components/Charts/ChartWrapper";
import { LocationTypeChart } from "./LocationTypeChart";
import { useTranslation } from "react-i18next";

export function LocationType({
  data,
  openExpanded,
}: {
  data: any;
  openExpanded: () => void;
}) {
  const { t } = useTranslation("driverParkingAnalysis");

  return (
    <ChartWrapper
      title={t("parking-location-types.title")}
      expand={openExpanded}
    >
      <LocationTypeChart data={data} />
    </ChartWrapper>
  );
}
