import { useTranslation } from "react-i18next";
import { EnergyDetails } from "./EnergyDetails";
import { FuelDetails } from "./FuelDetails";
import { useEnergyAndFuelDetails } from "./useEnergyAndFuelDetails";

export function FuelAndEnergy() {
  
  
  
  const { t } = useTranslation("settingsSimulation");

  const energyAndFuelDetails = useEnergyAndFuelDetails();
  // ToDo: We need to componentize the input for co2 emission (int) and price (float) and reuse it everywhere.
  return (
    <>
      <h2 className="text-2xl font-normal mb-12">{t("fuel-energy-title")}</h2>

      <div className="grid grid-cols-3 gap-6">
        <div className="flex flex-col col-span-1">
          <FuelDetails energyAndFuelDetails={energyAndFuelDetails} />
        </div>

        <div className="col-span-1">
          <EnergyDetails energyAndFuelDetails={energyAndFuelDetails} />
        </div>
      </div>
    </>
  );
}
