import { useContext, useState } from "react";

import onboardingLogo from "../../../../../assets/electrification_planner/onboarding_logo.webp";

import { useTranslation } from "react-i18next";
import OnboardingModal from "./OnboardingModal";
import { useUserData } from "../../../../../hooks/state/useUserData";
import { Button } from "../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../electrify_frontend_common/components/Buttonv2/types";
import { ArrowRightIcon } from "../../../../../electrify_frontend_common/components/ArrowRight";



export function ElectrificationPlannerOnboarding() {


    const { t } = useTranslation("electrificationPlanner");
    const [onboardingModalOpened, setOnboardingModalOpened] = useState(false);
    const { user, refreshUserAccountInfo } = useUserData();


    return (
        <>
            {onboardingModalOpened ? (
                <OnboardingModal
                    isOpen={onboardingModalOpened}
                    startingStep={user?.accountInfo?.electrificationPlannerOnboarding?.currentStep || 0}
                    onCancel={() => {
                        refreshUserAccountInfo();
                        setOnboardingModalOpened(false);
                    }}
                />
            ) : null}
            <div className="flex justify-center pt-12 px-40 text-center">
                <div className="flex flex-col items-center">
                    <img src={onboardingLogo} />
                    <p className="text-3xl mt-10 mb-4">
                        {t("onboarding.hi")} {user?.name}
                    </p>
                    <div className="flex mb-8 items-center justify-center">
                        <div className="text-3xl mr-2">
                            {t("onboarding.welcome")}{" "}
                        </div>
                        <div className="text-3xl font-black">
                            {t("onboarding.electrificationPlanner")}
                        </div>
                    </div>

                    <div className="flex flex-col items-center">
                        <p>{t("onboarding.message")}</p>

                    </div>

                    <div className="flex justify-center mt-12">
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.LARGE}
                            onClick={() => setOnboardingModalOpened(true)}
                        >
                            <div className="flex items-center">
                                {t("onboarding.startButton")}{" "}
                                <ArrowRightIcon className="ml-2" />
                            </div>
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )


}