import { useTranslation } from "react-i18next";
import { ReactComponent as EventIcon } from "../../../../../../assets/driver_overview/event.svg";
import { ReactComponent as LightningIcon } from "../../../../../../assets/driver_overview/lightning-black.svg";
import { ReactComponent as PlusIcon } from "../../../../../../assets/plus-icon-black.svg";

const STEPS = [
  {
    title: "CHARGING",
    label: "Onside check",
    icon: LightningIcon,
  },
  {
    title: "ADDITIONAL SERVICES",
    label: "Installation partner",
    icon: PlusIcon,
  },
  {
    title: "CHARGING",
    label: "Energy contract",
    icon: LightningIcon,
  },
];

const Step = ({
  idx,
  title,
  label,
  Icon,
}: {
  idx: number;
  title: string;
  label: string;
  Icon: any;
}) => {
  return (
    <div
      className={`relative flex flex-col w-full  ${idx < STEPS.length - 1
        ? "border border-white border-l-Blueberry-dark-default"
        : ""
        } `}
    >
      <div className="absolute top-[-5px] left-[-10px] flex items-center justify-center w-5 h-5 rounded-full border border-Blueberry-dark-default bg-white text-black text-xs font-bold">
        {idx}
      </div>
      <div className="flex items-center w-full ml-4 mt-[-7px]">
        <Icon className="mr-2" />
        {title}
      </div>
      <div className="p-3">
        <div className="border border-Grey-default rounded p-2 text-Grey-dark">
          <div className="flex w-full items-center justify-between mb-2 text-black">
            {label}
            <div className="w-5 h-5 rounded-full border border-Grey-default bg-white"></div>
          </div>
          <div className="flex text-sm w-full p-1 border border-Grey-default border-dashed items-center justify-center rounded">
            to be filled in
          </div>
          <div className="flex w-full items-center mt-2 ">
            <EventIcon className="mr-1" />
            <div className="font-thin text-xs">Due date: July 25, 2023</div>
          </div>
        </div>
        <div className="flex text-Grey-dark w-full text-sm mt-2">
          Please Change status, its overdue
        </div>
      </div>
    </div>
  );
};

export function ElectrificationPlanner() {
  const { t } = useTranslation("driverOverview");

  return (
    <div className="flex flex-col w-full ">
      <h1 className=" text-xl text-Black">
        {t("electrification-planner.title")}
      </h1>
      <div className="max-h-[470px] overflow-y-hidden border border-Grey-tint rounded mt-6 p-4 relative">
        {STEPS.map((d, idx) => (
          <Step
            key={idx}
            idx={idx}
            title={d.title}
            label={d.label}
            Icon={d.icon}
          />
        ))}
        <div
          // style={{
          //   backdropFilter: "blur(5px)",
          //   zIndex: "1001"
          // }}
          className="absolute top-0 left-0 bg-gray-300 w-full h-full rounded bg-opacity-50 backdrop-blur-[2px] flex items-center justify-center">
          <span className="text-2xl text-Grey-dark font-bold opacity-100">
            {t("comming-soon")}
          </span>
        </div>
      </div>

    </div>
  );
}
