/* react imports */
import React, { useEffect, useState } from "react";

/* third party imports */
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

/* local imports */
import AverageDistance from "./charts/AverageDistance/AverageDistance";
import LongestDistance from "./charts/LongestDistance/LongestDistance";
import DistanceSegments from "./charts/DistanceSegments/DistanceSegments";
import BusinessPrivate from "./charts/BusinessPrivate/BusinessPrivate";
import DrivingParking from "./charts/DrivingParking/DrivingParking";
import YearlyMileage from "./charts/YearlyMileage/YearlyMileage";
import { ExpandedDrivingAnalysis } from "./charts/ExpandedDrivingAnalysis";
import useHandleDrivingData from "../../../../../../hooks/utils/useHandleDrivingData";
import { useLayout } from "../../../../../../hooks/state/useLayout";
import useManagePaginatedModal from "../../../../../../hooks/utils/useManagePaginatedModal";
import { IFleetAnalysisVehicle } from "../../../../../../@types/fleet";
import { GET_FLEET_RESULTS_VEHICLE_ANALYSIS } from "../../../../../../services/graphql/fleet/queries";
import { Spinner } from "../../../../../../electrify_frontend_common/components/Spinner";


const NUMBER_OF_PAGES = 6; // Number of available pages

const sortMapInitialState = {
    averageDistance: false,
    longestDistance: false,
    distanceSegment: false,
    businessPrivate: false,
    drivingParking: false,
    yearlyMileage: false,
};

export default function DrivingAnalysis() {
    const { t, i18n } = useTranslation("drivingAnalysis");
    const { sortedData } = useHandleDrivingData();
    const language = i18n.language;

    const { setHeader } = useLayout();

    useEffect(() => {
        setHeader({
            defaultHeaderTitle: t("page-title"),
            defaultHeaderDescription: t("page-subtitle")
        });
    }, [language])

    const [sortCrescentMap, setSortCrescentMap] = useState(sortMapInitialState);

    const {
        expandedMode,
        setExpandedMode,
        incrementPage,
        decrementPage,
    } = useManagePaginatedModal(NUMBER_OF_PAGES);

    const {
        data: fleetResultsVehicleAnalysisData,
        loading: fleetResultsDrivingAnalysisLoading,
    } = useQuery<{ fleetResultsVehicleAnalysis: IFleetAnalysisVehicle[] }>(GET_FLEET_RESULTS_VEHICLE_ANALYSIS, {
        onCompleted: (data) => console.log("ONCOMPLETED_::::", data),
        onError: (err) => console.log("ERRRR>>>", err),
    });

    const fleetResultsVehicleAnalysis =
        (fleetResultsVehicleAnalysisData &&
            fleetResultsVehicleAnalysisData.fleetResultsVehicleAnalysis) ||
        [];

    const graphData = sortedData(fleetResultsVehicleAnalysis, sortCrescentMap, 6);

    const expandedGraphData = sortedData(
        fleetResultsVehicleAnalysis,
        sortCrescentMap
    );

    if (fleetResultsDrivingAnalysisLoading && !graphData) return <Spinner />;

    return (
        <>

            {expandedMode.open ? (
                <ExpandedDrivingAnalysis
                    graphData={expandedGraphData}
                    expandedMode={expandedMode}
                    closeExpanded={() => setExpandedMode({ open: false, page: 0 })}
                    incrementPage={() => incrementPage()}
                    decrementPage={() => decrementPage()}
                    numberOfPages={NUMBER_OF_PAGES}
                    sortStates={{
                        yearlyMileage: sortCrescentMap.yearlyMileage,
                        averageDistance: sortCrescentMap.averageDistance,
                        longestDistance: sortCrescentMap.longestDistance,
                        distanceSegment: sortCrescentMap.distanceSegment,
                        businessPrivate: sortCrescentMap.businessPrivate,
                        drivingParking: sortCrescentMap.drivingParking,
                    }}
                    sortFunctions={{
                        yearlyMileage: () =>
                            setSortCrescentMap({
                                ...sortCrescentMap,
                                yearlyMileage: !sortCrescentMap.yearlyMileage,
                            }),
                        averageDistance: () =>
                            setSortCrescentMap({
                                ...sortCrescentMap,
                                averageDistance: !sortCrescentMap.averageDistance,
                            }),
                        longestDistance: () =>
                            setSortCrescentMap({
                                ...sortCrescentMap,
                                longestDistance: !sortCrescentMap.longestDistance,
                            }),
                        distanceSegment: () =>
                            setSortCrescentMap({
                                ...sortCrescentMap,
                                distanceSegment: !sortCrescentMap.distanceSegment,
                            }),
                        businessPrivate: () =>
                            setSortCrescentMap({
                                ...sortCrescentMap,
                                businessPrivate: !sortCrescentMap.businessPrivate,
                            }),
                        drivingParking: () =>
                            setSortCrescentMap({
                                ...sortCrescentMap,
                                drivingParking: !sortCrescentMap.drivingParking,
                            }),
                    }}
                />
            ) : null}

            <div className="p-10">
                <YearlyMileage
                    openExpanded={() => setExpandedMode({ open: true, page: 0 })}
                    data={graphData?.dataSets?.yearlyMileageDataset}
                    sortedCrescent={sortCrescentMap.yearlyMileage}
                    sort={() =>
                        setSortCrescentMap({
                            ...sortCrescentMap,
                            yearlyMileage: !sortCrescentMap.yearlyMileage,
                        })
                    }
                />

                <AverageDistance
                    openExpanded={() => setExpandedMode({ open: true, page: 1 })}
                    dataDay={graphData?.dataSets?.averageDistancePerDayDatasets}
                    dataTrip={graphData?.dataSets?.averageDistancePerTripDatasets}
                    sortedCrescent={sortCrescentMap.averageDistance}
                    sort={() =>
                        setSortCrescentMap({
                            ...sortCrescentMap,
                            averageDistance: !sortCrescentMap.averageDistance,
                        })
                    }
                />

                <LongestDistance
                    openExpanded={() => setExpandedMode({ open: true, page: 2 })}
                    dataDay={graphData?.dataSets?.longestDistancesPerDayDatasets}
                    dataTrip={graphData?.dataSets?.longestDistancesPerTripDatasets}
                    sortedCrescent={sortCrescentMap.longestDistance}
                    sort={() =>
                        setSortCrescentMap({
                            ...sortCrescentMap,
                            longestDistance: !sortCrescentMap.longestDistance,
                        })
                    }
                />

                <DistanceSegments
                    openExpanded={() => setExpandedMode({ open: true, page: 3 })}
                    dataDay={graphData?.dataSets?.distanceSegmentsPerDayDatasets}
                    dataTrip={graphData?.dataSets?.distanceSegmentsPerTripDatasets}
                    sortedCrescent={sortCrescentMap.distanceSegment}
                    sort={() =>
                        setSortCrescentMap({
                            ...sortCrescentMap,
                            distanceSegment: !sortCrescentMap.distanceSegment,
                        })
                    }
                />

                <BusinessPrivate
                    openExpanded={() => setExpandedMode({ open: true, page: 4 })}
                    dataDay={graphData?.dataSets?.businessPrivateDistanceDatasets}
                    dataTrip={graphData?.dataSets?.businessPrivatePerTripDatasets}
                    sortedCrescent={sortCrescentMap.businessPrivate}
                    sort={() =>
                        setSortCrescentMap({
                            ...sortCrescentMap,
                            businessPrivate: !sortCrescentMap.businessPrivate,
                        })
                    }
                />

                <DrivingParking
                    openExpanded={() => setExpandedMode({ open: true, page: 5 })}
                    data={graphData?.dataSets?.drivingParkingDataset}
                    sortedCrescent={sortCrescentMap.drivingParking}
                    sort={() =>
                        setSortCrescentMap({
                            ...sortCrescentMap,
                            drivingParking: !sortCrescentMap.drivingParking,
                        })
                    }
                />
            </div>
        </>
    );
}
