

export function RealRange({ color = "black" }: { color?: string }) {

    return (
        <svg width="52" height="48" viewBox="0 0 52 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.333 9.1875C41.554 9.1875 41.766 9.26816 41.9223 9.41175C42.0785 9.55533 42.1663 9.75007 42.1663 9.95312" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M40.5 9.95312C40.5 9.75007 40.5878 9.55533 40.7441 9.41175C40.9004 9.26816 41.1123 9.1875 41.3333 9.1875" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M41.3333 10.7187C41.1123 10.7187 40.9004 10.6381 40.7441 10.4945C40.5878 10.3509 40.5 10.1562 40.5 9.95312" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M42.1663 9.95312C42.1663 10.1562 42.0785 10.3509 41.9223 10.4945C41.766 10.6381 41.554 10.7187 41.333 10.7187" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M41.3331 0.765625C43.9852 0.765625 46.5288 1.73359 48.4042 3.45658C50.2795 5.17957 51.3331 7.51645 51.3331 9.95313C51.3331 13.8752 45.3597 21.3395 42.6464 24.5265C42.4905 24.7098 42.2912 24.8581 42.0637 24.9601C41.8361 25.062 41.5862 25.115 41.3331 25.115C41.0799 25.115 40.83 25.062 40.6025 24.9601C40.3749 24.8581 40.1756 24.7098 40.0197 24.5265C37.3064 21.3375 31.333 13.8752 31.333 9.95313C31.333 7.51645 32.3866 5.17957 34.262 3.45658C36.1373 1.73359 38.6809 0.765625 41.3331 0.765625V0.765625Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M1.33301 46.7031L14.6664 16.0781" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M47.9997 46.7031L41.333 31.3906" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24.666 46.7031V43.6406" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24.666 34.4531V31.3906" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            <path d="M24.666 22.2031V19.1406" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )

}