import { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { defineColorScheme } from "../../../../../../../../common/components/Charts/ChartColors";
import ChartButtonHeader from "../../../../../../../../common/components/Charts/ChartButtonHeader";


const keys = ['distanceTo9Km', 'distanceFrom10To49Km', 'distanceFrom50To99Km', 'distanceFrom100To299Km', 'distanceFrom300Km']

export function DistanceSegmentChart({ data, isModal }: { data: any; isModal?: boolean; }) {
    const { t } = useTranslation("driverDrivingAnalysis");

    const [isTrip, setIsTrip] = useState(true);

    if (!data) return null;

    const selectedData = isTrip ? data?.perTrip : data?.perDay
    const dataToUse = keys.map(key => selectedData[key])

    const totalSum = dataToUse.reduce((c: any, v: any) => c + v, 0) as number;

    const graphData = {
        labels: keys.map(key => t(`distance-segments.${key}`)),
        datasets: [
            {
                backgroundColor: defineColorScheme(5),
                data: dataToUse,
            },
        ],
    };

    return (
        <div className="flex flex-col h-full">
            <ChartButtonHeader
                button1={{
                    onClick: () => setIsTrip(true),
                    label: t("per-trip-button"),
                }}
                button2={{
                    onClick: () => setIsTrip(false),
                    label: t("per-day-button"),
                }}
                checkProp={isTrip}
            />
            {data ? (
                <div className={!isModal ? "h-96" : "flex flex-col h-full"}>
                    <Doughnut
                        options={{
                            cutout: '50%',
                            plugins: {
                                tooltip: {
                                    callbacks: {
                                        label: function (tooltipItem) {
                                            return `${Math.round((tooltipItem.raw as number / totalSum) * 100)}%`;
                                        },
                                    },
                                },
                            },
                        }}
                        data={graphData}
                    />
                </div>
            ) : null}
        </div>
    );
}
