import { useContext, useState } from "react"
import { ReactComponent as InformationIcon } from '../../../../../assets/information-icon-blueberry.svg'
import { useTranslation } from "react-i18next";

import { useMutation } from "react-query";
import { ElectrifyPlannerContext } from "../../../contexts/ElectrificationPlannerContext";
import { updateDeliveryDate } from "../../../../../services/rest/electrificationPlanner";
import ModalWrapper from "../../../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../../../electrify_frontend_common/components/ModalWrapper/Headers";
import { Button } from "../../../../../electrify_frontend_common/components/Buttonv2";



function EditIcon() {

    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_6597_69024" maskUnits="userSpaceOnUse" x="0" y="0" width="14" height="14">
                <rect width="14" height="14" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_6597_69024)">
                <path d="M2.91667 11.0827H3.73333L8.76458 6.05143L7.94792 5.23477L2.91667 10.266V11.0827ZM11.2583 5.2056L8.77917 2.7556L9.59583 1.93893C9.81944 1.71532 10.0941 1.60352 10.4198 1.60352C10.7455 1.60352 11.0201 1.71532 11.2438 1.93893L12.0604 2.7556C12.284 2.97921 12.4007 3.249 12.4104 3.56497C12.4201 3.88095 12.3132 4.15074 12.0896 4.37435L11.2583 5.2056ZM10.4125 6.06602L4.22917 12.2493H1.75V9.77018L7.93333 3.58685L10.4125 6.06602Z" fill="#2C2C31" />
            </g>
        </svg>
    )
}

function VehicleDeliveryModal({
    isOpen,
    close,
}: {
    isOpen: boolean,
    close: () => void;
}) {

    const { t } = useTranslation("electrificationPlanner");

    const { refetch, electrificationPlanner } = useContext(ElectrifyPlannerContext);

    const [date, setDate] = useState(electrificationPlanner?.vehicleDeliveryDate?.split("T")[0] || (new Date()).toISOString().split("T")[0]);

    const [updateDatesAccordingly, setUpdateDatesAccordingly] = useState(true);

    const dateUpdateMutation = useMutation(updateDeliveryDate, {
        onSuccess: () => {
            refetch();
            close();
        }
    });


    return (
        <ModalWrapper
            isOpen={isOpen}
            close={close}
        >
            <div className="w-[900px]">
                <ModalHeader
                    onCancel={close}
                    title="Change delivery date" />

                <div className="p-8 ">
                    <div className="mb-3">{t("deliveryDate.newDeliveryDate")}</div>
                    <div className="flex items-center text-sm text-Grey-shade mb-3">
                        {t("deliveryDate.name")}
                        <InformationIcon className="ml-2" />
                    </div>
                    <input
                        value={date}
                        type="date"
                        min={new Date().toISOString().split("T")[0]}
                        className="w-1/2 h-10 border border-Grey-tint rounded px-2"
                        onChange={(e) => {
                            setDate(e.target.value);
                        }}
                    />
                    <div className="mt-8 mb-6">
                        <div className="mt-6 mb-4">{t("deliveryDate.dateSettingLogic.title")}</div>
                        <div className="flex items-start">
                            <input
                                className="mr-3 w-5 h-5 cursor-pointer text-Blueberry-dark-default bg-transparent rounded border-Grey-default focus:ring-0 focus:outline-offset-0 focus:outline-0 focus:ring-offset-0"
                                type="checkbox"
                                onChange={(e) => setUpdateDatesAccordingly(e.target.checked)}
                                checked={updateDatesAccordingly}
                                name={""}
                            />
                            <div className="text-sm text-Grey-shade">
                                <div>{t("deliveryDate.dateSettingLogic.checkboxLabel")}</div>
                                <div className="my-4"><span className="underline">{t("deliveryDate.dateSettingLogic.example.title")}</span> {t("deliveryDate.dateSettingLogic.example.description")}</div>
                                <div className="text-Blueberry-dark-default">{t("deliveryDate.dateSettingLogic.recommendation")}</div>
                            </div>
                        </div>
                    </div>
                    <Button type="submit" onClick={() => dateUpdateMutation.mutate({ deliveryDate: date, updateDatesAccordingly })}>{"Confirm"}</Button>
                </div>
            </div>
        </ModalWrapper>
    )

}



export function VehicleDeliveryDate() {

    const { electrificationPlanner } = useContext(ElectrifyPlannerContext);

    const [deliveryDateModalOpen, setDeliveryDateModalOpen] = useState(false);
    const { resetElectrificationPlanner } = useContext(ElectrifyPlannerContext);

    const { t, i18n } = useTranslation("electrificationPlanner");

    return (
        <div className={`mt-4 flex w-full justify-end items-center `}>
            {deliveryDateModalOpen ?
                <VehicleDeliveryModal
                    isOpen={deliveryDateModalOpen}
                    close={() => setDeliveryDateModalOpen(false)}
                /> : null
            }
            {process.env.NODE_ENV === "development" ?
                <div className="border rounded cursor-pointer border-Blueberry-dark-default px-2 mr-6" onClick={() => {
                    resetElectrificationPlanner();
                }}>Reset</div>
                : null}

            <div className="flex items-center text-xs">
                <InformationIcon className="mr-4" />
                <div className="font-bold">{t("plannedDateForEVehicle")}: </div>
                <div onClick={() => setDeliveryDateModalOpen(true)} className="ml-1 cursor-pointer flex items-center">
                    <span className=" text-Blueberry-light-shade font-normal">{electrificationPlanner?.vehicleDeliveryDate?.split("T")[0] || (new Date()).toISOString().split("T")[0]}</span>
                    <EditIcon />
                </div>
            </div>
        </div>

    )



}