import { IElectrificationPlannerAdditionalServicesSection } from "../../../../../@types/driver/electrificationPlanner";
import { ElectrificationPlannerAdditionalServicesSectionSubSections, ElectrificationPlannerSections, Section, Subsection } from "./Section";

type AdditionalServicesSectionSubSections =
    ElectrificationPlannerAdditionalServicesSectionSubSections.ADMINISTRATION |
    ElectrificationPlannerAdditionalServicesSectionSubSections.VEHICLE_DATA |
    ElectrificationPlannerAdditionalServicesSectionSubSections.SEARCH_AND_NAVIGATION |
    ElectrificationPlannerAdditionalServicesSectionSubSections.FINANCIAL_REWARDS;

export class AdditionalServiceSectionModel extends Section<AdditionalServicesSectionSubSections> {


    constructor(additionalServiceSection: IElectrificationPlannerAdditionalServicesSection) {

        const administration = new Map([
            ['carInsurance', additionalServiceSection[ElectrificationPlannerAdditionalServicesSectionSubSections.ADMINISTRATION].carInsurance],
            ['parkingPermit', additionalServiceSection[ElectrificationPlannerAdditionalServicesSectionSubSections.ADMINISTRATION].parkingPermit],
        ]);

        const vehicleData = new Map([
            ['connectedService', additionalServiceSection[ElectrificationPlannerAdditionalServicesSectionSubSections.VEHICLE_DATA].connectedService],
            ['advancedCarDataAccess', additionalServiceSection[ElectrificationPlannerAdditionalServicesSectionSubSections.VEHICLE_DATA].advancedCarDataAccess],
            ['logbook', additionalServiceSection[ElectrificationPlannerAdditionalServicesSectionSubSections.VEHICLE_DATA].logbook],
        ]);

        const searchAndNavigation = new Map([
            ['chargingStationFinder', additionalServiceSection[ElectrificationPlannerAdditionalServicesSectionSubSections.SEARCH_AND_NAVIGATION].chargingStationFinder],
            ['eroutePlanner', additionalServiceSection[ElectrificationPlannerAdditionalServicesSectionSubSections.SEARCH_AND_NAVIGATION].eroutePlanner],
            ['chargeCostOptimizer', additionalServiceSection[ElectrificationPlannerAdditionalServicesSectionSubSections.SEARCH_AND_NAVIGATION].chargeCostOptimizer],
        ]);

        const financialRewards = new Map([
            ['thgQuota', additionalServiceSection[ElectrificationPlannerAdditionalServicesSectionSubSections.FINANCIAL_REWARDS].thgQuota],
        ]);


        super(
            ElectrificationPlannerSections.ADDITIONAL_SERVICES_SECTION,
            new Map([
                [ElectrificationPlannerAdditionalServicesSectionSubSections.ADMINISTRATION, administration],
                [ElectrificationPlannerAdditionalServicesSectionSubSections.VEHICLE_DATA, vehicleData],
                [ElectrificationPlannerAdditionalServicesSectionSubSections.SEARCH_AND_NAVIGATION, searchAndNavigation],
                [ElectrificationPlannerAdditionalServicesSectionSubSections.FINANCIAL_REWARDS, financialRewards]
            ]))
    }


}



