import {
  FormControl,
  MenuItem,
  Select,
  TableCell,
  Tooltip,
} from "@mui/material";
import {
  Dispatch,
  ReactElement,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as HomeCharging } from "../../../../../../../../assets/charging-home.svg";
import { ReactComponent as PublicCharging } from "../../../../../../../../assets/charging-public.svg";
import { ReactComponent as WorkCharging } from "../../../../../../../../assets/charging-work.svg";
import { OptimizationType } from "../../hooks/useTripSimulationPreferencesState";
import Dropdown from "../../../../../../../../electrify_frontend_common/components/Dropdown";
import { ExpandIcon } from "../../../../../../../../electrify_frontend_common/icons/ExpandIcon";

export const EmptyTableCell = () => (
  <TableCell align={"center"} padding={"normal"} />
);

export const TooltipWrappedContent = ({
  children,
  text,
}: {
  children: ReactElement;
  text: string;
}) => (
  <Tooltip title={text} arrow placement="right">
    {children}
  </Tooltip>
);

export const TableLegend = ({
  entries,
}: {
  entries: {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    label: string;
  }[];
}) => {
  return (
    <div className="flex w-full py-7 px-10 border border-Grey-default">
      {entries.map((e) => {
        return (
          <div className="flex mr-6 items-center">
            <e.icon />
            <span className="ml-3 text-sm">{e.label}</span>
          </div>
        );
      })}
    </div>
  );
};

export const SimulationConfigurations = ({
  selectedOptimization,
  includeExtraStops,
  setIncludeExtraStops,
  setSelectedOptimization,
}: {
  selectedOptimization: OptimizationType;
  includeExtraStops: boolean;
  setIncludeExtraStops: Dispatch<SetStateAction<boolean>>;
  setSelectedOptimization: Dispatch<SetStateAction<OptimizationType>>;
}) => {
  const { t } = useTranslation("tripSimulation");

  const handleOptimizerChange = (optimizationMethod: any) => {
    setSelectedOptimization(optimizationMethod);
  };

  const handleIncludeExtraStopChange = (includeExtraStops: boolean) => {
    console.log(includeExtraStops);
    setIncludeExtraStops(includeExtraStops);
  };

  const optimizationOptions = [
    {
      value: OptimizationType.EAGER_CHARGING,
      label: t("configuration.optimization-options.minimum-risk"),
    },
    {
      value: OptimizationType.MINIMUM_COSTS,
      label: t("configuration.optimization-options.minimum-costs"),
    },
    {
      value: OptimizationType.MINIMUM_SESSIONS,
      label: t("configuration.optimization-options.minimum-sessions"),
    },
  ];

  const placeholder =
    optimizationOptions[
      optimizationOptions.findIndex((e) => e.value == selectedOptimization)
    ]?.label;

  return (
    <div className="flex p-10 items-center text-sm ">
      <div className="flex items-center mr-10 text-Grey-default">
        <span className="mr-6">{t("configuration.optimization-label")}</span>
        <div
          className={`border text-black flex border-Grey-tint bg-white solid rounded h-full text-xs items-center w-52`}
        >
        <Dropdown
          className="w-full h-full"
          placeholder={placeholder}
          data={optimizationOptions.map((oo) => ({
            key: oo.label,
            onClick: () => handleOptimizerChange(oo.value),
          }))}
          icon={<ExpandIcon color="black" />}
        />
        </div>
        {/* <FormControl size="small">
          <Select
            className="w-44 border-Grey-default"
            value={selectedOptimization}
            onChange={(e) => handleOptimizerChange(e.target.value)}
            placeholder={placeholder}
          >
            {optimizationOptions.map((oo) => (
              <MenuItem value={oo.value} placeholder={placeholder}>
                {oo.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      </div>
      <div className="flex items-center border  max-w-fit border-Grey-tint rounded-md p-2 py-2">
        <input
          checked={includeExtraStops}
          onClick={(e: any) => handleIncludeExtraStopChange(e.target.checked)}
          type="checkbox"
          className="focus:border-none focus:ring-0 text-Blueberry-dark-default"
        />
        <div className="ml-2">
          {t("configuration.additional-charging-checkbox-label")}
        </div>
      </div>
    </div>
  );
};

export const LocationIcon = ({
  locationType,
}: {
  locationType: "WORK" | "HOME" | "PUBLIC" | "NaN";
}) => {
  if (locationType === "WORK") return <WorkCharging />;
  if (locationType === "HOME") return <HomeCharging />;
  if (locationType === "PUBLIC") return <PublicCharging />;
  return null;
};

const batteryColors = {
  low: "#DBC2D2",
  "medium-low": "#B886A6",
  medium: "#974B7B",
  "medium-high": "#78194F",
  full: "#00AB58",
};

const BatteryDisplayInfo = (percentage: number) => {
  if (percentage < 30) return { pos: 27, color: "black" };
  if (percentage < 40) return { pos: 30, color: "black" };
  if (percentage < 50) return { pos: 34, color: "black" };
  if (percentage < 60) return { pos: 15, color: "white" };
  if (percentage < 70) return { pos: 17, color: "white" };
  return { pos: 27, color: "white" };
};

const EmptyBatteryIcon = () => (
  <>
    <line
      y1="-1"
      x2="11.2435"
      y2="-1"
      transform="matrix(0.838593 0.544758 -0.529015 0.848612 14 10.375)"
      stroke="#2C2C31"
      strokeWidth="2"
    />
    <line
      y1="-1"
      x2="11.2435"
      y2="-1"
      transform="matrix(0.838593 0.544758 -0.529015 0.848612 22.5703 9.5)"
      stroke="#2C2C31"
      strokeWidth="2"
    />
    <line
      y1="-1"
      x2="7.05228"
      y2="-1"
      transform="matrix(0.121543 0.992586 -0.991957 0.126575 22.5703 9.5)"
      stroke="#2C2C31"
      strokeWidth="2"
    />
  </>
);

export const Battery = ({
  percentage,
  eventType,
}: {
  percentage: number;
  eventType?: string;
}) => {
  const width = 47;

  const batteryWidth = () => (width * (percentage / 100)).toFixed(1);
  const batteryDisplayInfo = BatteryDisplayInfo(percentage);

  const getBatteryColor = () => {
    if (eventType === "CHARGING") return batteryColors["full"];
    if (percentage == 0) return;
    if (percentage < 20) {
      return batteryColors.low;
    }
    if (percentage < 40) {
      return batteryColors["medium-low"];
    }
    if (percentage < 60) {
      return batteryColors["medium"];
    }
    return batteryColors["medium-high"];
  };

  return (
    <svg
      width="52"
      height="height"
      viewBox="0 0 52 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="48" height="23" fill="white" />
      <rect x="0.5" y="0.5" width="48" height="24" stroke="#2C2C31" />
      <rect
        width={batteryWidth()}
        height="23"
        transform="translate(1 1)"
        fill={getBatteryColor()}
      />
      {percentage == 0 ? (
        <EmptyBatteryIcon />
      ) : (
        <text
          fill={batteryDisplayInfo.color}
          font-size="0.8em"
          x={batteryDisplayInfo.pos}
          y="17"
          text-anchor="middle"
        >
          {percentage.toFixed(0)}%
        </text>
      )}
      <rect
        x="49.5"
        y="8.5"
        width="1.72746"
        height="9.21"
        fill="#2C2C31"
        stroke="#2C2C31"
      />
    </svg>
  );
};
