/* react imports */

/* third party imports */
import { useTranslation } from "react-i18next";

/* local imports */
import LongestDistanceChart from "./LongestDistanceChart";
import ChartWrapper from "../../../../../../../../common/components/Charts/ChartWrapper";

export default function LongestDistance({
  dataTrip,
  dataDay,
  sort,
  openExpanded,
  sortedCrescent,
} : {
  dataTrip: any,
  dataDay: any,
  sort: ()=> void,
  openExpanded: ()=> void,
  sortedCrescent?: boolean
}) {
  const { t } = useTranslation("drivingAnalysis");

  return (
    <ChartWrapper
      title={t("longest-distance-title")}
      sort={sort}
      expand={openExpanded}
      sortedCrescent={sortedCrescent}
    >
      <LongestDistanceChart data={{ day: dataDay, trip: dataTrip }} />
    </ChartWrapper>
  );
}
