import { ReactComponent as VehicleIcon } from '../../../../../../../../assets/electrification_planner/vehicle_icon.svg';
import { ReactComponent as ArrowIcon } from '../../../../../../../../assets/arrow_right_strong_white.svg';
import { useContext, useState } from 'react';
import { ElectrificationPlannerSections, ElectrificationPlannerVehicleSectionSubSections } from '../../../../models/Section';
import { useQuery } from 'react-query';
import { VehicleSelectionNotAvailableCard } from '../../../Sections/VehicleSection/modals/VehicleSelectionModal/VehicleSelectionNotAvailable/VehicleSelectionNotAvailableCard';
import { useTranslation } from 'react-i18next';
import { ElectrifyPlannerContext } from '../../../../../../contexts/ElectrificationPlannerContext';
import { getEvDetails } from '../../../../../../../../electrify_frontend_common/services';
import { Spinner, SpinnerSize } from '../../../../../../../../electrify_frontend_common/components/Spinner';
import { Button } from '../../../../../../../../electrify_frontend_common/components/Buttonv2';
import { ButtonSize, ButtonVariant } from '../../../../../../../../electrify_frontend_common/components/Buttonv2/types';
import { FreeEvFinderContext } from '../../../../../../Electrify/pages/OpenEvFinder/FreeEvFinderContext';
import { OpenEvFinderEvDetails } from '../../../../../../Electrify/pages/OpenEvFinder/EvDetailsModal';
import { SelectedVehicleThumbnail } from './SelectedVehicleThumbnail';
import { VehicleSelectionNotAvailableThumbnail } from './VehicleSelectionNotAvailablThumbnail';




export function VehicleSelectionStep() {

    const { electrificationPlanner } = useContext(ElectrifyPlannerContext);
    const { userCountryCode } = useContext(FreeEvFinderContext);

    const [evDetailsToCheck, setEvDetailsToCheck] = useState<string | undefined>();

    const { t } = useTranslation("electrificationPlanner");

    const selectedVehicle = electrificationPlanner?.[ElectrificationPlannerSections.VEHICLE_SECTION]?.[ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION]?.vehicleModelSelection?.data;


    const { data, isLoading } = useQuery(['selectedVehicle', selectedVehicle, userCountryCode],
        () => getEvDetails(selectedVehicle || "", userCountryCode || "DE"),
        {
            enabled: !!selectedVehicle?.length && selectedVehicle !== "unavailable",
        }
    )


    return (
        <>
            {evDetailsToCheck ?
                <OpenEvFinderEvDetails evDetailsToCheck={evDetailsToCheck} closeModal={() => setEvDetailsToCheck(undefined)} />
                : null}

            <div className='mt-2'>

                {!selectedVehicle ?
                    <div className="bg-Grey-background border border-Grey-default rounded flex flex-col items-center justify-center w-full h-52">
                        {isLoading ? <Spinner size={SpinnerSize.LARGE} /> : <>
                            <div className=" p-3">
                                <VehicleIcon />
                            </div>
                            <Button onClick={() => null} variant={ButtonVariant.PRIMARY} size={ButtonSize.SMALL} >
                                <div className="flex items-center">
                                    <span>{t("vehicleSection.subsections.purchase.steps.vehicleModelSelection.stepChild.button")}</span>
                                    <ArrowIcon className="ml-2" />
                                </div>
                            </Button>
                        </>}
                    </div>
                    : <div className='flex flex-col w-full items-center overflow-hidden '>
                        {selectedVehicle && data ?
                            <SelectedVehicleThumbnail
                                vehicle={data}
                                onMoreInfoClick={()=> setEvDetailsToCheck(data.evId)}
                            /> :
                            <VehicleSelectionNotAvailableThumbnail/>
                        }
                    </div>}
            </div>
        </>

    )


}