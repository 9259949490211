import { useTranslation } from "react-i18next";
import { Tooltip } from "../../Tooltip";


export function ElectrificationFactorPlaceholder({
    electrificationFactor,
    defaultColor
  }: {
    electrificationFactor?: number;
    defaultColor?: string;
  }) {

    const { t } = useTranslation("evFinder");
   
  
    return (
  
      <div className="h-fit w-fit absolute top-0 right-0 z-10">
        <svg
          width="79"
          height="67"
          viewBox="0 0 79 67"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_7782_183536)">
            <path
              d="M11.7485 -4.03564L78.6905 -2.04626L78.2047 58.1599L11.7485 -4.03564Z"
              fill={ defaultColor || "#830751"}
            />
          </g>
          <defs>
            <filter
              id="filter0_d_7782_183536"
              x="7.74854"
              y="-4.03564"
              width="74.942"
              height="70.1953"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_7782_183536"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_7782_183536"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
        {electrificationFactor ? (
          <div className="absolute top-2.5 right-1">
            <Tooltip
              content={<div className="whitespace-nowrap">{t("vehicleCard.tooltips.electrificationFactor")}</div>}
              placement="bottom-end"
            >
              <span className={`font-bold text-xs text-white`}>
                {electrificationFactor}%
              </span>
            </Tooltip>
          </div>
        ) : null}
      </div>
  
    );
  }