


export function MetricsArrowUp({ color = "white", className }: { color?: string, className?: string }) {

    return (
        <svg
            className={className}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            version="1.1"
            id="svg6">
            <defs
                id="defs10" />

            <path
                d="M 8,13 V 3"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                id="path2" />
            <path
                d="M 3.33337,7.66667 8.00004,3 12.6667,7.66667"
                stroke={color}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                id="path4" />
        </svg>
    )

}