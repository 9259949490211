


export function Consumption({ color = "black", className }: { color?: string, className?: string }) {



    return (
        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_9088_746)">
                <path d="M10.4868 6.63147C10.5377 6.54881 10.5655 6.45408 10.5676 6.35705C10.5696 6.26003 10.5457 6.16421 10.4984 6.07949C10.4511 5.99477 10.382 5.92421 10.2983 5.87508C10.2146 5.82595 10.1193 5.80003 10.0223 5.8H8.16788V1L4.24895 7.36907C4.19806 7.45168 4.17014 7.54639 4.16809 7.6434C4.16604 7.74041 4.18992 7.83621 4.23727 7.92091C4.28462 8.00561 4.35372 8.07613 4.43744 8.12519C4.52115 8.17425 4.61645 8.20008 4.71348 8.2H6.56788V13L10.4868 6.63147Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_9088_746">
                    <rect width="13.3333" height="13.3333" fill="white" transform="translate(0.833984 0.333252)" />
                </clipPath>
            </defs>
        </svg>

    )

}