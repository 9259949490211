import { useTranslation } from "react-i18next";
import { AddressForm } from "../AddressForm";
import { Button } from "../../../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonVariant } from "../../../../../../../../../electrify_frontend_common/components/Buttonv2/types";


export function AddressWithoutShipping({ paymentAddressProps }: { paymentAddressProps: any }) {

    const {
        loadingPaymentIntent,
        billingAddress,
        setBillingAddress,
        billingErrors,
        submitWithoutShipping,
        resetBillingError,
    } = paymentAddressProps;

    const { t } = useTranslation("driverPaymentSystem");

    return (
        <>
            <div className="mb-6 flex flex-col w-1/2">
                <div className="mb-3 font-bold">{t("address-form.billing-address")}</div>
                <AddressForm
                    onChange={setBillingAddress}
                    value={billingAddress}
                    errors={billingErrors}
                    resetErrors={resetBillingError}
                />
            </div>
            <Button
                type="button"
                variant={ButtonVariant.PRIMARY}
                className='mt-10 w-1/2'
                onClick={() => {
                    submitWithoutShipping()
                }}>{t("submit")}</Button>
        </>
    )


}