import { useTranslation } from "react-i18next";
import { BooleanOption } from "../../FilterComponents/BooleanOption";
import { IFilters } from "../../../../types";

export function CheckboxSection({filters, updateFilters} : {filters : IFilters, updateFilters: (filters: Partial<IFilters>) => void}) {
  
  const { t } = useTranslation("evFinder");


  return (
    <>
      <BooleanOption
        value={filters["4x4"]}
        onChange={(value: boolean) => {
          updateFilters({"4x4" : value});
        }}
        label={t("filters.vehicleFeatures.checkboxes.4wd")}
      />

      <BooleanOption
        value={filters.fastCharging}
        onChange={(value: boolean) => {
          updateFilters({fastCharging : value});
        }}
        label={t("filters.vehicleFeatures.checkboxes.fastCharging")}
      />

      <BooleanOption
        value={filters.plugAndCharge}
        onChange={(value: boolean) => {
          updateFilters({plugAndCharge : value});
        }}
        label={t("filters.vehicleFeatures.checkboxes.plugAndCharge")}
      />

      <BooleanOption
        value={filters.vehicleToGrid}
        onChange={(value: boolean) => {
          updateFilters({vehicleToGrid : value});
        }}
        label={t("filters.vehicleFeatures.checkboxes.vehicleToGrid")}
      />

      <BooleanOption
        value={filters.isofixAvailable}
        onChange={(value: boolean) => {
          updateFilters({isofixAvailable : value});
        }}
        label={t("filters.vehicleFeatures.checkboxes.isofix")}
      />

      <BooleanOption
        value={filters.towing}
        onChange={(value: boolean) => {
          updateFilters({towing : value});
        }}
        label={t("filters.vehicleFeatures.checkboxes.towHitch")}
      />

      <BooleanOption
        value={filters.roofRails}
        onChange={(value: boolean) => {
          updateFilters({roofRails : value});
        }}
        label={t("filters.vehicleFeatures.checkboxes.roofRails")}
      />
    </>
  );
}
