import { useState } from "react";
import { NextButton } from "../utils/NextButton";
import { PrevButton } from "../utils/PrevButton";
import { Slider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { VehicleWithYearlyStats } from "../../../../../../@types/vehicle";
import { GET_VEHICLE_SETTINGS } from "../../../../../../services/graphql/common/queries";
import { useLayout } from "../../../../../../hooks/state/useLayout";
import { UPDATE_VEHICLE_MAX_PRICE } from "../../../../../../services/graphql/driver/mutations";


export function BudgetPreferences({
  step,
  nextStep,
  prevStep,
  vehicle,
}: {
  step: number;
  nextStep: () => void;
  prevStep: () => void;
  vehicle?: VehicleWithYearlyStats;
}) {
  const [budget, setBudget] = useState<number | null>(90000);

  const { currencySymbol } = useLayout();

  const { t } = useTranslation("driverOnboarding");

  useQuery(GET_VEHICLE_SETTINGS, {
    variables: { vehicleId: vehicle?.id },
    onCompleted: (data) => {
      console.log(
        "Got data: ",
        data.vehicleSettings?.simulation?.filters?.latest?.listPrice?.max
      );
      setBudget(data.vehicleSettings?.simulation?.filters?.latest?.listPrice?.max);
    },
  });

  const [updateVehicleMaxPrice] = useMutation(UPDATE_VEHICLE_MAX_PRICE, {
    variables: {
      vehicleSettings: {
        vehicleId: vehicle?.id,
        simulation: {
          latest: {
            listPrice: {
              max: budget,
            },
          },
        },
      },
    },
    onCompleted: () => nextStep(),
  });

  const marks = [
    { value: 30000, label: "30k" },
    { value: 40000, label: "40k" },
    { value: 50000, label: "50k" },
    { value: 60000, label: "60k" },
    { value: 70000, label: "70k" },
    { value: 80000, label: "80k" },
    { value: 90000, label: "90k" },
    { value: 100000, label: "100k" },
  ];

  const minValue = marks[0].value;
  const maxValue = marks[marks.length - 1].value;
  const noBudget = budget === null;

  return (
    <>
      <div className="p-10 w-[1000px]">
        <div className="text-xl">
          <span className="text-Blueberry-dark-default ">
            {t("step")}
            {step}:
          </span>{" "}
          {t("step-budget.title-1")}{" "}
          <span className="font-bold">{t("step-budget.title-2")}</span>{" "}
          {t("step-budget.title-3")}
        </div>
        <div className="flex w-5/6 text-sm text-Grey-dark py-2">
          {t("step-budget.message")}
        </div>
        <div className="text-lg mb-2 mt-4">{t("step-budget.label")}</div>
        <div className="flex items-center">
          <div className="w-1/3">
            <Slider
              aria-label="Custom marks"
              defaultValue={0}
              value={!noBudget ? budget : maxValue}
              step={5000}
              disabled={noBudget}
              valueLabelDisplay="auto"
              marks={marks}
              min={minValue}
              max={maxValue}
              sx={{
                "& .MuiSlider-thumb": {
                  color: noBudget ? "#777" : "#830751",
                },
                "& .MuiSlider-rail": {
                  color: "#ccc",
                  height: "8px",
                },
                "& .MuiSlider-track": {
                  color: noBudget ? "#777" : "#830751",
                  height: "6px",
                },
                "& .MuiSlider-mark": {
                  display: "none",
                },
              }}
              onChange={(e: any) => setBudget(e.target.value)}
            />
            <div className="flex items-center">
              <input
                onBlur={() => {
                  if (!noBudget && (!budget || budget < 30000)) {
                    setBudget(minValue);
                  }
                }}
                className={`border rounded border-Grey-tint p-2 mt-2 w-1/3 ${
                  noBudget ? "text-Grey-dark bg-Grey-background" : ""
                }`}
                placeholder="Input budget"
                disabled={noBudget}
                value={!noBudget ? budget : "-"}
                onChange={(e: any) => setBudget(parseInt(e.target.value || 0))}
              />
              <div className="ml-[-20px] flex items-center mt-2">
                {currencySymbol}
              </div>
            </div>
          </div>
          <div className="flex items-center border border-Grey-tint rounded px-4 py-2 ml-12 text-Grey-dark text-xs">
            <input
              type="checkbox"
              checked={noBudget}
              onChange={(e) => {
                setBudget(!noBudget ? null : maxValue);
              }}
              className="mr-2 w-4 h-4 text-Blueberry-dark-default bg-transparent rounded border-Blueberry-dark-default focus:ring-0 focus:outline-offset-0 focus:outline-0 focus:ring-offset-0"
            />

            {t("step-budget.label-2")}
          </div>
        </div>

        <div className="flex justify-between mt-10">
          <PrevButton text={t("back")} onClick={prevStep} />
          <NextButton
            text={t("next")}
            active={true}
            onClick={() => {
              updateVehicleMaxPrice();
            }}
          />
        </div>
      </div>
    </>
  );
}
