/* react imports */
import { useEffect, useState } from "react";

/* third party imports */
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";

/* local imports */
import ParkingType from "./charts/ParkingType/ParkingType";

import WorkplaceParkingOccupation from "./charts/WorkplaceParkingOccupation/WorkplaceParkingOccupation";
import WorkplaceParkingOccupationMaxVehicles from "./charts/WorkplaceParkingOccupationMaxVehicles/WorkplaceParkingOccupationMaxVehicles";
import ExpandedParkingAnalysis from "./charts/ExpandedParkingAnalysis";
import useHandleParkingData from "../../../../../../hooks/utils/useHandleParkingData";
import { useLayout } from "../../../../../../hooks/state/useLayout";
import useManagePaginatedModal from "../../../../../../hooks/utils/useManagePaginatedModal";
import { IFleetAnalysisLocationResults } from "../../../../../../@types/fleet";
import { GET_FLEET_RESULTS_LOCATION_ANALYSIS } from "../../../../../../services/graphql/fleet/queries";
import { Spinner } from "../../../../../../electrify_frontend_common/components/Spinner";


const NUMBER_OF_PAGES = 3; // Number of available pages

const sortMapInitialState = {
    parkingType: false,
};

export default function ParkingAnalysis() {
    const { t, i18n } = useTranslation("parkingAnalysis");
    const { sortedData } = useHandleParkingData();
    const language = i18n.language;
    const { setHeader } = useLayout();
    const [sortCrescentMap, setSortCrescentMap] = useState(sortMapInitialState);

    useEffect(() => setHeader({ defaultHeaderTitle: t("page-title"), defaultHeaderDescription: t("page-subtitle") }), [language])

    const { expandedMode, setExpandedMode, incrementPage, decrementPage } = useManagePaginatedModal(NUMBER_OF_PAGES);

    const { data: fleetResultsParkingAnalysisData, loading: fleetResultsParkingAnalysisLoading } = useQuery<{
        fleetResultsLocationAnalysis: IFleetAnalysisLocationResults
    }>(GET_FLEET_RESULTS_LOCATION_ANALYSIS, { onError: (err) => console.log("ERRRR>>>", err) });

    const fleetResultsParkingAnalysis = fleetResultsParkingAnalysisData?.fleetResultsLocationAnalysis || null;

    const graphData = sortedData(fleetResultsParkingAnalysis, sortCrescentMap, 6);

    if (fleetResultsParkingAnalysisLoading && !graphData) return <Spinner />;

    const labels = graphData?.labels;
    const datasets = graphData?.dataSets;

    const expandedChartData = sortedData(fleetResultsParkingAnalysis, sortCrescentMap);

    return <>
        {expandedMode.open ? (
            <ExpandedParkingAnalysis
                chartData={expandedChartData}
                expandedMode={expandedMode}
                closeExpanded={() => setExpandedMode({ open: false, page: 0 })}
                incrementPage={() => incrementPage()}
                decrementPage={() => decrementPage()}
                numberOfPages={NUMBER_OF_PAGES}
                sortStates={{
                    parkingType: sortCrescentMap.parkingType,
                }}
                sortFunctions={{
                    parkingType: () =>
                        setSortCrescentMap({
                            ...sortCrescentMap,
                            parkingType: !sortCrescentMap.parkingType,
                        }),
                }}
            />
        ) : null}

        <div className="p-10">
            <ParkingType
                openExpanded={() => setExpandedMode({ open: true, page: 0 })}
                data={datasets?.parkingTypeDatasets}
                sortedCrescent={sortCrescentMap.parkingType}
                sort={() =>
                    setSortCrescentMap({
                        ...sortCrescentMap,
                        parkingType: !sortCrescentMap.parkingType,
                    })
                }
            />
            <WorkplaceParkingOccupation
                openExpanded={() => setExpandedMode({ open: true, page: 1 })}
                dataTime={datasets?.parkingWorkplaceOccupationTimesDatasets}
                dataStays={datasets?.parkingWorkplaceOccupationStaysDatasets}
            />

            <WorkplaceParkingOccupationMaxVehicles
                openExpanded={() => setExpandedMode({ open: true, page: 2 })}
                labelsMonth={labels?.parkingWorkplaceOccupationMonthLabels}
                labelsYear={labels?.parkingWorkplaceOccupationYearLabels}
                dataMonth={datasets?.parkingWorkplaceOccupationMonthDatasets}
                dataYear={datasets?.parkingWorkplaceOccupationYearDatasets}
            />
        </div>
    </>
}
