import { ReactComponent as NoWorkplaceAddresses } from '../../../../../../../assets/workplace-location-img.svg';

export function NoWorkplaceDataAvailable({ noDataText }: { noDataText: string }) {
    return <div className="flex items-center justify-center min-h-[370px] h-full w-full">
        <div className="flex flex-col items-center justify-center">
            <NoWorkplaceAddresses />
            <p className="text-center mt-2">{noDataText}</p>
        </div>
    </div>
}
