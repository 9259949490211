import { Overview } from "./pages/Overview";
import { DrivingAnalysis } from "./pages/Insights/Driving";
import { ParkingAnalysis } from "./pages/Insights/Parking";
import { EvSuitability } from "./pages/EvSuitability";
import { Scenarios } from "./pages/Simulation/Scenarios";
import { TripsSimulation } from "./pages/Simulation/Trips";
import { Settings } from "./pages/Settings";
import { OnboardingPage } from "./pages/OnboardingPage";
import { PaymentFeedbackEntry } from "./pages/Payments/Feedback";
import { DongleProductWelcome } from "./pages/DriverDongle/DongleProductWelcome";
import { IRoute } from "../../../@types/routes";
import { EvFinder } from "./pages/EvFinder";
import { Checklist } from "../ElectrificationPlanner/CheckList";


export const ElectrifyDriverRoutes: IRoute[] = [{
    path: '/',
    component: Overview,
},
{
    path: '/insights/driving',
    component: DrivingAnalysis
},
{
    path: '/insights/parking',
    component: ParkingAnalysis
},
{
    path: '/electrification-planner',
    component: Checklist,
    availableByDefault: true
},
{
    path: '/suitability',
    component: EvSuitability
},
{
    path: '/ev-finder',
    component: EvFinder,
    availableByDefault: true
},
{
    path: '/simulation/scenarios',
    component: Scenarios
},
{
    path: '/simulation/trips',
    component: TripsSimulation
},
{
    path: '/settings',
    component: Settings
},
{
    path: '/onboarding',
    component: OnboardingPage,
},
{
    path: '/subscriptions/payments/success',
    component: PaymentFeedbackEntry,
},
{
    path: '/dongle-welcome',
    component: DongleProductWelcome
}]