import { Trans, useTranslation } from "react-i18next";
import { CarIconSelector } from "../../../../../../../../electrify_frontend_common/components/CarIconSelector";


export function VehicleSelectionNotAvailableThumbnail() {


    const { t } = useTranslation("electrificationPlanner");

    const replaceImgWithError = (e: any) => {
        e.target.onerror = null;
        e.target.src = CarIconSelector(undefined, undefined);
        e.target.className = "object-contain";
    };

    return (
        <div className={` bg-white border border-Grey-tint rounded overflow-hidden relative w-full h-52`}>
            <div className="flex w-full h-4/6 items-center justify-center p-8 pt-12">
                <img
                    src={``}
                    loading="lazy"
                    onError={replaceImgWithError}
                    alt="recomended-electric-vehicle"
                    className={`object-contain max-h-24`}
                />
            </div>
            <div className="flex flex-col h-2/6 w-full bg-Grey-background border-Grey-tint text-center justify-center px-2">
                <Trans>
                    <div className="align-text-center text-xs ">
                        {t("misc.vehicleSelectionCard.vehicleNotAvailable")}
                    </div>
                </Trans>
            </div>
        </div>
    )




}