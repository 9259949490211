import { StripeAddressElementChangeEvent } from "@stripe/stripe-js";
import { Dispatch, SetStateAction } from "react";
import { AddressForm } from "../AddressForm";
import { useTranslation } from "react-i18next";



export function BillingInfo({ sameBillingAddress, setSameBillingAddress, setBillingAddress, billingAddress, billingErrors, resetBillingError }: {
    sameBillingAddress: boolean,
    setSameBillingAddress: Dispatch<SetStateAction<boolean>>,
    setBillingAddress: (v: StripeAddressElementChangeEvent | null) => void,
    billingAddress: any,
    billingErrors: any,
    resetBillingError: any,
}) {

    const {t} = useTranslation("driverPaymentSystem")

    return (
        <>
            <div className="my-3 flex items-center w-1/2">
                <input
                    type="checkbox"
                    className="mr-2 w-4 h-4 text-Blueberry-dark-default bg-transparent rounded border-Blueberry-dark-default focus:ring-0 focus:outline-offset-0 focus:outline-0 focus:ring-offset-0"
                    onChange={() => {
                        setSameBillingAddress(!sameBillingAddress)
                    }}
                    checked={sameBillingAddress}
                />
                <label className="text-base text-Grey-shade">
                    {t("address-form.billing-same-as-shipping")}
                </label>
            </div>

            <div className={`flex flex-col w-1/2 transition-height duration-500 ease-in-out ${!sameBillingAddress ? 'h-fit' : 'h-0 '}`}>
                {!sameBillingAddress ?
                    <>
                        <div className="mb-2 font-bold">{t("address-form.billing-address")}</div>
                        <AddressForm
                            onChange={setBillingAddress}
                            value={billingAddress}
                            errors={billingErrors}
                            resetErrors={resetBillingError}
                        />
                    </>
                    : null}

            </div>

        </>
    )


}