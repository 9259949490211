import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { defineColorScheme } from "../../../../../../../../common/components/Charts/ChartColors";

const keys = ['parking', 'driving']

export function TimeShareChart({
    data,
    isModal,
}: {
    data: any;
    isModal?: boolean;
}) {
    const { t } = useTranslation("driverParkingAnalysis");
    const dataToUse = keys.map(key => data[key])

    const totalSum = dataToUse.reduce((c: any, v: any) => c + v, 0) as number;

    const graphData = {
        labels: keys.map(key => t(`parking.${key}`)),
        datasets: [
            {
                label: "Duration",
                backgroundColor: defineColorScheme(2),
                data: dataToUse,
            },
        ],
    };

    return (
        <div className="flex flex-col h-full">
            <h2 className="text-sm mb-5 text-Grey-dark">
                {t("parking.description")}
            </h2>
            {data ? (
                <div className={`${!isModal ? "h-96 pb-5" : "flex flex-col h-full"}`}>
                    <Doughnut
                        options={{
                            cutout: '50%',
                            plugins: {
                                tooltip: {
                                    callbacks: {
                                        label: function (tooltipItem) {
                                            return `${Math.round((tooltipItem.raw as number / totalSum) * 100)}%`;
                                        },
                                    },
                                },
                            },
                        }}
                        data={graphData}
                    />
                </div>
            ) : null}
        </div>
    );
}
