import { useTranslation } from "react-i18next";
import { defineColorScheme } from "../../../../../../../common/components/Charts/ChartColors";

function RowHeader({
    className = "",
    headerText
}: {
    className?: string;
    headerText: string;
}) {
    return <>
        <div className={`flex items-center justify-end p-4 ${className}`}>{headerText}</div>
        <hr className="border-Grey-tint" />
    </>
}

function LegendItem({ text, color }: { text: string; color: string }) {
    return <div className="flex items-center w-36">
        <div className="h-2 w-2 rounded-sm mr-4" style={{ backgroundColor: color }} />{text}
    </div>
}

function Legend({
    className = "",
    headerText,
    itemsTextList,
    footer,
}: {
    className?: string;
    headerText: string;
    itemsTextList: string[]
    footer?: string;
}) {
    const colorScheme = defineColorScheme(itemsTextList.length)

    return <>
        <div className={`flex flex-col items-end justify-center p-4 ${className}`}>
            <div>{headerText}</div>
            <div>{itemsTextList.map((itemText, index) => <LegendItem text={itemText} color={colorScheme[index]} />)}</div>
            {footer ? <div className="text-sm italic mt-10">{footer}</div> : null}
        </div>
        <hr className="border-Grey-tint" />
    </>
}

export function Labels() {
    const { t } = useTranslation("scenarios");

    return (
        <div className="flex flex-col h-full text-Grey-dark ">
            <RowHeader className="h-28" headerText="" />
            <RowHeader headerText={t("labels.total-charging-events")} />
            <RowHeader headerText={t("labels.additional-charging-events")} />
            <RowHeader headerText={t("labels.charging-costs")} />
            <RowHeader headerText={t("labels.co2-emissions")} />
            <Legend
                className="h-44"
                headerText={t("labels.battery-level-at-end")}
                itemsTextList={[t("labels.from80"), t("labels.from60To79"), t("labels.from40To59"), t("labels.from20To39"), t("labels.to19")]}
            />
            <hr className="border-Grey-tint" />
            <Legend
                className="grow"
                headerText={t("labels.charged-energy-by-location")}
                itemsTextList={[t("labels.home"), t("labels.workplace"), t("labels.public")]}
                footer={t("labels.bottom-note")}
            />
        </div>
    );
}
