

export function AnalysisIcon({color = "black"}: {color?: string}) {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_666_787)">
                <path d="M14.4983 21.3774H9.49833L10.1233 17.6274H13.8733L14.4983 21.3774Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.62333 21.3774H16.3733" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18.8733 2.62744H5.12333C3.74262 2.62744 2.62333 3.74673 2.62333 5.12744V15.1274C2.62333 16.5082 3.74262 17.6274 5.12333 17.6274H18.8733C20.254 17.6274 21.3733 16.5082 21.3733 15.1274V5.12744C21.3733 3.74673 20.254 2.62744 18.8733 2.62744Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.62333 11.3774L5.93167 8.06994C5.98971 8.01186 6.05863 7.96579 6.13448 7.93436C6.21033 7.90292 6.29164 7.88674 6.37375 7.88674C6.45586 7.88674 6.53716 7.90292 6.61302 7.93436C6.68887 7.96579 6.75779 8.01186 6.81583 8.06994L8.43167 9.68577C8.48971 9.74385 8.55862 9.78993 8.63448 9.82136C8.71033 9.8528 8.79164 9.86898 8.87375 9.86898C8.95586 9.86898 9.03716 9.8528 9.11302 9.82136C9.18887 9.78993 9.25779 9.74385 9.31583 9.68577L12.6233 6.37744" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.62333 15.1274H21.3733" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.8733 10.1274V12.0024" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M18.8733 10.1274V12.0024" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M16.3733 8.25244V12.0024" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_666_787">
                    <rect width="20" height="20" fill="white" transform="translate(2 2)" />
                </clipPath>
            </defs>
        </svg>

    )


}