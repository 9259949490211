import { useEffect, useState } from "react";

export type IuseForm<T> = {formState: Partial<T>, updateFormState: (obj: Partial<T>) => void, resetFormState: () => void}

export function useForm<T>() : IuseForm<T>  {

    const [formState, setFormState] = useState<Partial<T>>({} as T);

    const updateFormState = (obj :Partial<T>) => { 
        setFormState({...formState, ...obj});
    } 

    const resetFormState = () => {
        setFormState({} as T);
    }
  

    return {formState, updateFormState, resetFormState};

}