import { useTranslation } from 'react-i18next';

import { borderToRemove } from '../utils';
import { useMemo } from 'react';
import { useLayout } from '../../../../../../../hooks/state/useLayout';
import { LocationTypes } from '../../../../../../../@types/driver/simulations';
import { Tooltip } from '../../../../../../../electrify_frontend_common/components/Tooltip';
import { ComparisonBox } from '../../../../../../../common/components/misc/ComparisonBox';

const tooltipRow = ({
    value,
    units,
    key,
    sum,
    t,
}: {
    value: number;
    units: string;
    key: string;
    sum: number;
    t: any;
}) => {
    return (
        <tr key={key} className='whitespace-nowrap'>
            <td className="p-1">{t(`labels.${key}`)}</td>
            <td className="p-1 text-right">
                {value}
                {units}
                <span className="text-xs font-thin">{` (${Math.round(
                    (value / sum) * 100,
                )}%)`}</span>
            </td>
        </tr>
    );
};

const TooltipContent = ({
    dataToConsider,
    units,
    sum,
    t,
}: {
    dataToConsider: [string, number][];
    units?: string;
    sum: number;
    t: any;
}) => {
    const displayUnit = units && units !== '%' ? ` ${units}` : '';

    return (
        <div >
            <table className="text-sm">
                <thead className="pb-2 whitespace-nowrap">
                    <tr>
                        <th>{t('tooltip.title')}</th>
                    </tr>
                </thead>
                <tbody>
                    {dataToConsider.map(([key, value]) =>
                        tooltipRow({
                            value,
                            units: displayUnit,
                            key,
                            sum,
                            t,
                        }),
                    )}
                </tbody>
            </table>
        </div>
    );
};

const DisplayValue = ({ value, units, sum }: { value?: number; units?: string; sum?: number; }) => {
    const { localeCode } = useLayout();

    if (units && sum)
        return (
            <div>
                {(units === '%'
                    ? Math.round(((value || 0) / sum) * 100)
                    : sum
                ).toLocaleString(localeCode)}{' '}
                {units}
            </div>
        );

    return <div>{(sum || value)?.toLocaleString(localeCode)}</div>
};

const BuildComponentWithTooltip = ({
    value,
    rawValues,
    comparisonValue,
    position,
    units,
    t,
}: {
    value?: number;
    rawValues?: LocationTypes | number;
    comparisonValue?: number;
    position?: 'FIRST' | 'LAST';
    units?: string;
    t: any;
}) => {
    const dataToConsider: [string, number][] = Object.entries(rawValues || {}).filter(([k, v]) => typeof v === 'number');

    const sum: number = useMemo(
        () => dataToConsider.reduce((acc, curr) => acc + curr[1], 0),
        [dataToConsider],
    );

    return (
        <Tooltip
            placement={position === 'LAST' ? "left-bottom" : "right-bottom"}
            content={
                <TooltipContent
                    units={units}
                    dataToConsider={dataToConsider}
                    sum={sum}
                    t={t}
                />
            }
        >
            <div className="flex z-auto items-center">
                <DisplayValue
                    value={value}
                    units={units}
                    sum={sum}
                />
                {typeof comparisonValue == "number" ? <div className='ml-4'><ComparisonBox comparison={comparisonValue} /></div> : null}
            </div>
        </Tooltip>

    );
}

const BuildComponent = ({
    value,
    rawValues,
    comparisonValue,
    position,
    units,
}: {
    value?: number;
    rawValues?: LocationTypes | number;
    comparisonValue?: number;
    position?: 'FIRST' | 'LAST';
    units?: string;
}) => {
    const { t } = useTranslation('scenarios');

    if (typeof rawValues === "object") return BuildComponentWithTooltip({ value, rawValues, comparisonValue, position, units, t })

    return <div className="flex z-40 items-center">
        <DisplayValue value={rawValues} />
    </div>
};

export function MetricCell({
    value,
    rawValues,
    comparisonValue,
    position,
    units,
}: {
    value?: number;
    rawValues?: LocationTypes | number;
    comparisonValue?: number;
    position?: 'FIRST' | 'LAST';
    units?: string;
}) {
    if (rawValues === null || rawValues === undefined) return null;

    return (
        <div
            className={`flex items-center justify-center ${comparisonValue ? 'p-3' : 'p-4'
                }  border-x  border-Grey-default text-Grey-dark ${borderToRemove(
                    position,
                )}`}
        >
            {BuildComponent({
                value,
                rawValues,
                comparisonValue,
                position,
                units,
            })}
        </div>
    );
}
