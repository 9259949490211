import { ChartOptions } from 'chart.js';

export const options = (localeCode: string, labels: string[], isDaily?: boolean): ChartOptions<'bar'> => ({
    plugins: {
        tooltip: {
            callbacks: {
                title: function (tooltipItems) {
                    if (isDaily) {
                        const date = new Date(tooltipItems[0].label);
                        return `${date.toLocaleDateString(localeCode)} (${date.toLocaleDateString(localeCode, { weekday: "long" })})`;
                    }
                    return tooltipItems[0].label;
                },
                label: function (tooltipItem: any) {
                    return tooltipItem.raw.toLocaleString(localeCode, { maximumFractionDigits: 1 }) + " km";
                }
            }
        },
        legend: { display: false },
    },
    layout: {
        padding: {
            left: 5,
            right: 10,
            top: 50,
        }
    },
    scales: {
        x: {
            border: { display: false },
            grid: {
                display: true,
                drawOnChartArea: false,
                drawTicks: isDaily,
                color: "border-Grey-dark",
            },
            ticks: {
                minRotation: 45,
                callback: function (value, index) {
                    const val = labels[index]
                    if (isDaily) {
                        const date = new Date(val);
                        return date.getDay() === 1 ? date.toLocaleDateString(localeCode) : null;
                    }
                    return val;
                },
            },
        },
        y: {
            grid: { display: false },
            ticks: {
                callback: function (value) {
                    return value.toLocaleString(localeCode, { maximumFractionDigits: 1 }) + " km";
                },
                maxTicksLimit: 8,
            }
        }
    }
});