import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { ApolloQueryResult, OperationVariables, useQuery } from "@apollo/client";

import { EvAlternativeLatestFiltersState, EvAlternativesFiltersValues, EVAlternativesResponse } from "../../../../../../../../@types/simulations";
import useFilters from "../../../../../../../../hooks/utils/FiltersHook";
import { GET_VEHICLE_EV_ALTERNATIVES } from "../../../../../../../../services/graphql/fleet/queries/simulation";
import { COUNT_VEHICLE_EV_ALTERNATIVES } from "../../../../../../../../services/graphql/fleet/queries";



export function initFilters({ latestFilterState, filtersHandler }: {
    latestFilterState: EvAlternativeLatestFiltersState,
    filtersHandler: any
}) {

    // filtersHandler.setFiltersOptions(availableFilters);
    filtersHandler.updateActiveFilters("listPrice", {
        min: latestFilterState?.listPrice?.min,
        max: latestFilterState?.listPrice?.max,
    });

    filtersHandler.updateActiveFilters("loadWeight", {
        min: latestFilterState?.loadWeight?.min,
        max: latestFilterState?.loadWeight?.max,
    });

    filtersHandler.updateActiveFilters("loadVolume", {
        min: latestFilterState?.loadVolume?.min,
        max: latestFilterState?.loadVolume?.max,
    });

    filtersHandler.updateActiveFilters(
        "brands",
        latestFilterState?.brands || []
    );
    filtersHandler.updateActiveFilters(
        "segments",
        latestFilterState?.segments || []
    );
    filtersHandler.updateActiveFilters(
        "bodyStyles",
        latestFilterState?.bodyStyles || []
    );

    filtersHandler.updateActiveFilters("propulsion", latestFilterState.propulsion);
    filtersHandler.updateActiveFilters("seats", latestFilterState.seats);
    filtersHandler.updateActiveFilters("towing", latestFilterState.towing);
    filtersHandler.updateActiveFilters("availabilityStatus", latestFilterState.availabilityStatus);
    filtersHandler.updateActiveFilters("topSpeed", latestFilterState.topSpeed);
    filtersHandler.updateActiveFilters("realRange", latestFilterState.realRange);
    filtersHandler.updateActiveFilters("realConsumption", latestFilterState.realConsumption);
    filtersHandler.updateActiveFilters("fastCharging", latestFilterState.fastCharging);
    filtersHandler.updateActiveFilters("plugAndCharge", latestFilterState.plugAndCharge);
    filtersHandler.updateActiveFilters("vehicleToGrid", latestFilterState.vehicleToGrid);
    filtersHandler.updateActiveFilters("isofixAvailable", latestFilterState.isofixAvailable);
    filtersHandler.updateActiveFilters("roofRails", latestFilterState.roofRails);
}


export function resetFilters({ filtersHandler }: { filtersHandler: any }) {
    filtersHandler.updateActiveFilters("listPrice", {
        min: null,
        max: null,
    });
    filtersHandler.updateActiveFilters("loadVolume", {
        min: null,
        max: null
    });
    filtersHandler.updateActiveFilters("loadWeight", {
        min: null,
        max: null
    });
    filtersHandler.updateActiveFilters("brands", []);
    filtersHandler.updateActiveFilters("bodyStyles", []);

    filtersHandler.updateActiveFilters("topSpeed", null);
    filtersHandler.updateActiveFilters("realRange", null);
    filtersHandler.updateActiveFilters("realConsumption", null);
    
    filtersHandler.updateActiveFilters("propulsion", null);
    filtersHandler.updateActiveFilters("seats", null);
    filtersHandler.updateActiveFilters("towing", null);
    filtersHandler.updateActiveFilters("availabilityStatus", null);
    filtersHandler.updateActiveFilters("fastCharging", null);
    filtersHandler.updateActiveFilters("plugAndCharge", null);
    filtersHandler.updateActiveFilters("vehicleToGrid", null);
    filtersHandler.updateActiveFilters("isofixAvailable", null);
    filtersHandler.updateActiveFilters("roofRails", null);

};

export function useAlternativeVehicles(vehicleId: string): {
    evFinderData: { vehicleEvAlternatives: EVAlternativesResponse; } | undefined,
    evFinderFavoritesData: { vehicleEvAlternatives: EVAlternativesResponse; } | undefined,
    loading: boolean,
    sortBy: string,
    setSortBy: Dispatch<SetStateAction<string>>,
    currentPage: number,
    setCurrentPage: Dispatch<SetStateAction<number>>,
    setFavoritesOnly: Dispatch<SetStateAction<boolean>>,
    favoritesOnly: boolean,
    alternativeEvsCount: { vehicleEvAlternativesCount: { totalCount: number, favoritesCount: number } },
    filtersHandler: any,
    refetchEvAlternatives: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<{ vehicleEvAlternatives: EVAlternativesResponse }>>,
    refetchFavorites: (variables?: Partial<OperationVariables> | undefined) => Promise<ApolloQueryResult<{ vehicleEvAlternatives: EVAlternativesResponse }>>,
    resetFiltersAndRefetch: () => void,
    recountVehicles: () => Promise<ApolloQueryResult<any>>,
    togglingFavoriteEvId: string | undefined,
    setTogglingFavoriteEvId: Dispatch<SetStateAction<string | undefined>>

} {

    const [favoritesOnly, setFavoritesOnly] = useState(false);
    const [togglingFavoriteEvId, setTogglingFavoriteEvId] = useState<string | undefined>();

    const filtersHandler = useFilters();

    const [currentPage, setCurrentPage] = useState(1);

    const [sortBy, setSortBy] = useState<string>("");

    const [filtersSetup, setFiltersSetup] = useState(false);


    useEffect(() => {
        setCurrentPage(1);
    }, [favoritesOnly, sortBy]);

    const {
        data: alternativeEvsCount,
        refetch: recountVehicles,
    } = useQuery(COUNT_VEHICLE_EV_ALTERNATIVES, {
        variables: {
            vehicleId: vehicleId,
        },
    });


    const {
        data: evFinderData,
        loading,
        refetch: refetchEvAlternatives,
    } = useQuery<{ vehicleEvAlternatives: EVAlternativesResponse }>(GET_VEHICLE_EV_ALTERNATIVES, {
        variables: {
            vehicleId: vehicleId,
            onlyFavorites: false,
            page: currentPage,
            sortBy,
        },
        onCompleted: ({ vehicleEvAlternatives }) => {
            if (!filtersSetup) {
                initFilters({
                    latestFilterState: vehicleEvAlternatives?.latestFilterState,
                    filtersHandler
                });
                setFiltersSetup(true);
            }

            recountVehicles();
            setCurrentPage(vehicleEvAlternatives?.page);
            setSortBy(vehicleEvAlternatives?.latestSortState);
            setTogglingFavoriteEvId(undefined);
        },
    });

    const {
        data: evFinderFavoritesData,
        loading: favoritesLoading,
        refetch: refetchFavorites
    } = useQuery<{ vehicleEvAlternatives: EVAlternativesResponse }>(GET_VEHICLE_EV_ALTERNATIVES, {
        variables: {
            vehicleId: vehicleId,
            onlyFavorites: true,
            page: currentPage,
            sortBy
        },
        onCompleted: () => {
            recountVehicles();
        },
    });






    const resetFiltersAndRefetch = () => {
        resetFilters({
            filtersHandler
        })

        refetchEvAlternatives({
            vehicleId: vehicleId,
            onlyFavorites: favoritesOnly,
            page: currentPage,
            selectedFilters: {
                brands: [],
                bodyStyles: [],
                propulsion: null,
                seats: null,
                availabilityStatus: null,
                listPrice: {
                    min: null,
                    max: null,
                },
                loadVolume: {
                    min: null,
                    max: null
                },
                loadWeight: {
                    min: null,
                    max: null
                },
                fastCharging: null,
                plugAndCharge: null,
                vehicleToGrid: null,
                isofixAvailable: null,
                towing: null,
                roofRails: null
            },
        });
    }


    const onClickApplyFilters = () => {

        let filters = filtersHandler.activeFilters;
        if ((filtersHandler.activeFilters as any)["brands"]?.includes("All")) { filters = { ...filters, brands: (filtersHandler.activeFilters as any)["brands"]?.filter((it: any) => !(it === "All")) } }
        console.log("FIlters updated!!!!");

        setCurrentPage(1);
        refetchEvAlternatives({
            vehicleId: vehicleId,
            onlyFavorites: favoritesOnly,
            page: currentPage,
            selectedFilters: !!Object.keys(filters).length ? filters : null,
        } as any);
    }


    useEffect(() => {
        onClickApplyFilters();
    }, [filtersHandler.activeFilters])


    return {
        evFinderData,
        evFinderFavoritesData,
        loading,
        sortBy,
        setSortBy,
        currentPage,
        setCurrentPage,
        setFavoritesOnly,
        favoritesOnly,
        alternativeEvsCount,
        filtersHandler,
        refetchEvAlternatives,
        refetchFavorites,
        resetFiltersAndRefetch,
        recountVehicles,
        togglingFavoriteEvId,
        setTogglingFavoriteEvId
    };



}