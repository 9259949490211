import { useEffect, useState } from "react";
import { ReactComponent as LowestRisk } from "../../../../../../assets/driver_scenarios/lowest-risk.svg";
import { ReactComponent as LowestCost } from "../../../../../../assets/driver_scenarios/lowest-cost.svg";
import { ReactComponent as LowestChargingEvents } from "../../../../../../assets/driver_scenarios/lowest-charging-events.svg";
import { ScenarioPanel } from "./components/ScenarioPanel";
import { useScenariosData } from "./hooks/useScenariosData";
import { useTranslation } from "react-i18next";
import { Labels } from "./components/Labels";
import { Consumptions } from "./components/Consumptions";
import { useLayout } from "../../../../../../hooks/state/useLayout";

export function Scenarios() {
    const { t, i18n } = useTranslation("scenarios");
    const language = i18n.language;

    const { setHeader } = useLayout();

    const [maxBatteryLevelPercentageAll, setMaxBatteryLevelPercentageAll] = useState(1)

    useEffect(() => {
        setHeader({
            defaultHeaderTitle: t("title"),
            defaultHeaderDescription: t("description"),
        });
    }, [language]);

    const { scenariosData, vehicleConsumptionsCostsAndEmissions } =
        useScenariosData();

    return (
        <div className="p-10 max-w-full ">
            <div className="grid grid-cols-4 h-full">
                <div className="flex flex-col h-full col-span-1"><Labels /></div>
                <div className="col-span-1">
                    <ScenarioPanel
                        navigationQuery={"minimum-risk"}
                        scenarioData={scenariosData?.eagerCharging}
                        scenarioIdx={1}
                        scenarioTitle={t("lowest-risk.title")}
                        scenarioDescription={t("lowest-risk.description")}
                        scenarioTooltip={t("lowest-risk.tooltip")}
                        Icon={LowestRisk}
                        position="FIRST"
                        consumptionCostsAndEmissions={vehicleConsumptionsCostsAndEmissions}
                        maxBatteryLevelPercentageAll={maxBatteryLevelPercentageAll}
                        setMaxBatteryLevelPercentageAll={setMaxBatteryLevelPercentageAll}
                    />
                </div>
                <div className="col-span-1">
                    <ScenarioPanel
                        navigationQuery={"minimum-cost"}
                        scenarioData={scenariosData?.minimumCosts}
                        scenarioIdx={2}
                        scenarioTitle={t("lowest-cost.title")}
                        scenarioDescription={t("lowest-cost.description")}
                        scenarioTooltip={t("lowest-cost.tooltip")}
                        Icon={LowestCost}
                        consumptionCostsAndEmissions={vehicleConsumptionsCostsAndEmissions}
                        maxBatteryLevelPercentageAll={maxBatteryLevelPercentageAll}
                        setMaxBatteryLevelPercentageAll={setMaxBatteryLevelPercentageAll}
                    />
                </div>
                <div className="col-span-1">
                    <ScenarioPanel
                        navigationQuery={"minimum-sessions"}
                        scenarioData={scenariosData?.minimumSessions}
                        scenarioIdx={3}
                        scenarioTitle={t("lowest-charging-events.title")}
                        scenarioDescription={t("lowest-charging-events.description")}
                        scenarioTooltip={t("lowest-charging-events.tooltip")}
                        Icon={LowestChargingEvents}
                        position="LAST"
                        consumptionCostsAndEmissions={vehicleConsumptionsCostsAndEmissions}
                        maxBatteryLevelPercentageAll={maxBatteryLevelPercentageAll}
                        setMaxBatteryLevelPercentageAll={setMaxBatteryLevelPercentageAll}
                    />
                </div>
            </div>
            <Consumptions
                consumptionCostsAndEmissions={vehicleConsumptionsCostsAndEmissions}
            />
        </div>
    );
}
