import { useState } from "react";
import { Bar } from "react-chartjs-2";
import { options } from "./ChartConfigs";
import { useTranslation } from "react-i18next";
import { defineColorScheme } from "../../../../../../../../common/components/Charts/ChartColors";
import { useLayout } from "../../../../../../../../hooks/state/useLayout";
import ChartButtonHeader from "../../../../../../../../common/components/Charts/ChartButtonHeader";


const buildDatasets = (isDaily: boolean, perDay: any, perWeek: any) => {
    if (isDaily) {
        const colorScheme = defineColorScheme(2)

        const labels = perDay.map((d: any) => d.date);
        const datasets = [
            {
                data: perDay.map((d: any) => d.distance),
                minBarLength: 1,
                backgroundColor: perDay.map((d: any) => {
                    const weekDay = new Date(d.date).getDay();
                    return weekDay === 0 || weekDay === 6 ? colorScheme[1] : colorScheme[0];
                }),
            },
        ];
        return { labels, datasets };
    }

    const labels = perWeek.map((d: any) => d.weekNumber);
    const datasets = [
        {
            data: perWeek.map((d: any) => d.distance),
            minBarLength: 1,
            backgroundColor: defineColorScheme(1),
        },
    ];

    return { labels, datasets };
};

export function DistancesChart({ perDay, perWeek }: { perDay: any; perWeek: any; }) {
    const [isDaily, setIsDaily] = useState(true);
    const { t } = useTranslation("driverDrivingAnalysis");

    const { localeCode } = useLayout();

    const displayData = buildDatasets(isDaily, perDay, perWeek);

    return (
        <div className="flex flex-col h-full">
            <ChartButtonHeader
                button1={{
                    onClick: () => setIsDaily(true),
                    label: t("per-day-button"),
                }}
                button2={{
                    onClick: () => setIsDaily(false),
                    label: t("per-week-button"),
                }}
                checkProp={isDaily}
            />
            {displayData ? <div
                className="flex-grow flex items-center justify-center"
                style={{ minHeight: "500px" }}
            >
                <Bar options={options(localeCode, displayData.labels, isDaily)} data={displayData} />
            </div> : null}
        </div>
    );
}
