import { ReactComponent as VehicleIcon } from '../../../../../../../assets/vehicle-icon-black.svg';
import { SectionContentWrapper, SectionHeader } from '../utils';
import { useContext, useMemo } from 'react';
import { ElectrificationPlannerVehicleSectionSubSections } from '../../../models/Section';
import { getSectionProgress } from '../getSectionProgress';
import { useTranslation } from 'react-i18next';
import { ElectrifyPlannerContext } from '../../../../../contexts/ElectrificationPlannerContext';



export function VehicleSection() {

    const { vehicleSection } = useContext(ElectrifyPlannerContext);
    const {t} = useTranslation('electrificationPlanner');
    
    const progress = useMemo(()=> {
        return getSectionProgress(vehicleSection)
    },[vehicleSection]);

    if (!vehicleSection) return null;

    return (
        <div>
            <SectionHeader progress={progress} icon={<VehicleIcon />} title={t("vehicleSection.title")} description={t("vehicleSection.description")} />
            <SectionContentWrapper title={t("vehicleSection.subsections.preferences.title").toUpperCase()} >
                {vehicleSection.renderSubsection(ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION)}
            </SectionContentWrapper>
            <SectionContentWrapper title={t("vehicleSection.subsections.purchase.title").toUpperCase()} >
                {vehicleSection.renderSubsection(ElectrificationPlannerVehicleSectionSubSections.PURCHASE_SUB_SECTION)}
            </SectionContentWrapper>
            <SectionContentWrapper title={t("vehicleSection.subsections.accessories.title").toUpperCase()}  >
                <div> </div>
                {vehicleSection.renderSubsection(ElectrificationPlannerVehicleSectionSubSections.ACCESSORIES_SUB_SECTION)}
            </SectionContentWrapper>
        </div>
    )

}