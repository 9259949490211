import { useContext, useEffect, useState } from "react";
import { useUserData } from "../../../../hooks/state/useUserData";
import { Button } from "../../../../electrify_frontend_common/components/Buttonv2";
import { DriverPaymentGatewayContext } from "../../contexts/DriverPaymentGatewayContext";
import { useLayout } from "../../../../hooks/state/useLayout";
import { useTranslation } from "react-i18next";


function SelectedProductElectrifyTeaser() {

    const { user } = useUserData();

    const preselectedProduct = user?.accountInfo?.preSelectedProduct;

    const { localeCode } = useLayout();

    const langCode = localeCode.split("-")[0];

    const { openPaymentModal, agreeWithPayment } = useContext(DriverPaymentGatewayContext);


    return (
        <div>
            <div>
                <div>You have selected <span className="font-bold">{preselectedProduct}</span>, you may proceed with it's payment at any time to fully unlock Electrify's potential</div>
                <Button
                    onClick={() => {
                        openPaymentModal();
                        agreeWithPayment()
                    }}
                >Proceed with payment</Button>
            </div>
            <div>
                <div>You may also switch the selected product through our website while you haven't proceed with the payment processo</div>
                <Button
                    onClick={() => {
                        window.open(`https://chargylize.webflow.io/${langCode}/drivers/electrify-driver#pricing`, "_blank")
                    }}
                >Switch products</Button>
            </div>
        </div>
    )

}



export function ElectrifyTeaser() {

    const {t, i18n} = useTranslation("electrifyTeaser");
    const { setHeader, localeCode } = useLayout();
    const langCode = localeCode.split("-")[0];
    const language = i18n.language;

    const { user } = useUserData();
    const preselectedProduct = user?.accountInfo?.preSelectedProduct;

    
    useEffect(() => {
        setHeader({
            defaultHeaderTitle: `${t("title")}`,
            defaultHeaderDescription: `${t("description")}`,
        });
    }, [language]);


    return (
        <div className="flex flex-col flex-1 h-full w-full items-center justify-center">
            {preselectedProduct ?
                <SelectedProductElectrifyTeaser /> :
                <div>
                    <div>Pick an electrify product</div>
                    <Button
                        onClick={() => {
                            window.open(`https://chargylize.webflow.io/${langCode}/drivers/electrify-driver#pricing`, "_blank")
                        }}
                    >Pick a product</Button>
                </div>}

        </div>
    )


}