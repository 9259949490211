import { ReactElement, useRef } from "react";
import { useOutsideAlerter } from "../../hooks/useOutsideClickAlerter";



export default function ModalWrapper({ children, isOpen, close, className, outterClassName }: { children: ReactElement, isOpen: boolean, close: () => void, className?: string, outterClassName?: string }) {

    const modalRef = useRef<any>(null);

    useOutsideAlerter(modalRef, close);

    return (<>
        {
            isOpen ?
                <div className={`z-50 backdrop-blur-sm absolute top-0 right-0 h-screen w-screen flex justify-center items-center overflow-hidden pt-8 pb-12 bg-info-400 bg-opacity-50 ${outterClassName}`} >
                    <div
                        ref={modalRef}
                        className={`flex flex-col align-bottom bg-white text-left rounded overflow-hidden shadow-xl max-w-screen-xl h-auto max-h-full max-w-screen  ${className}`}
                        role="dialog"
                        aria-modal="true"
                        aria-labelledby="modal-headline"
                    >
                        {children}
                    </div>
                </div> : null
        }
    </>
    )

}
