import { ReactElement } from 'react';
import { RestQueryClientProvider } from './react-query-client';
import { GraphQLClientProvider } from './apollo-client';

export const APIClientsProviders = ({
	children,
}: {
	children: ReactElement;
}) => {
	return (
		<GraphQLClientProvider>
			<RestQueryClientProvider>{children}</RestQueryClientProvider>
		</GraphQLClientProvider>
	);
};
