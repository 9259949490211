



import { useContext } from "react";

import { useTranslation } from "react-i18next";
import { ButtonSize, ButtonVariant } from "../../../../../../../electrify_frontend_common/components/Buttonv2/types";
import { Range } from "../../../../../../../electrify_frontend_common/icons/Range";
import { Consumption } from "../../../../../../../electrify_frontend_common/icons/Consumption";
import { Price } from "../../../../../../../electrify_frontend_common/icons/Price";
import { SelectedVehicleCard } from "../../../../../../../electrify_frontend_common/components/VehicleCards/SelectedVehicleCard";
import { ArrowButton } from "../../../../../../../electrify_frontend_common/components/ArrowButton";
import { BrandInfo } from "../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/BrandInfo";
import { Metric, MetricWithComparison } from "../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/Metric";
import { Button } from "../../../../../../../electrify_frontend_common/components/Buttonv2";
import { VehicleComparatorContext } from "../../../../../../../contexts/VehicleComparatorContext";
import FavoriteButton from "../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/FavoriteButton";
import { ElectrificationFactorPlaceholder } from "../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/ElectrificationFactor";
import { FuelCostsIcon } from "../../../../../../../electrify_frontend_common/icons/FuelCostsIcon";
import { Co2EmissionsIcon } from "../../../../../../../electrify_frontend_common/icons/Co2EmissionsIcon";
import { CloseIcon } from "../../../../../../../electrify_frontend_common/icons/CloseIcon";
import { AlternativeVehicle } from "../../../../../../../electrify_frontend_common/types";
import { useLayout } from "../../../../../../../hooks/state/useLayout";



export function FleetEvFinderSelectedVehicleCard({
    alternativeVehicle,
}: {
    alternativeVehicle: Partial<AlternativeVehicle>,
}) {

    const { t } = useTranslation("evFinder");

    const { localeCode } = useLayout();

    const {
        vehicle,
        setIsAlternativesOpen,
        setEvDetailQueryParams,
        addNewFavoriteVehicle,
        removeFavoriteVehicle,
        removeAlternative,
        togglingFavoriteEvId
    } = useContext(VehicleComparatorContext);


    const isSelected = alternativeVehicle.isSelected;

    const cardColor = {
        backgroundColor: isSelected ? "bg-Blueberry-dark-default" : "bg-Grey-background",
        bigFont: "text-black-default",
        text: "text-Black-default",
        buttonVariant: ButtonVariant.SECONDRY,
        icons: {
            range: <Range className="w-4" />,
            consumption: <Consumption />,
            price: <Price className="w-4" />,
        },
    };


    return (
        <SelectedVehicleCard
            vehicle={alternativeVehicle}
            moreInfoClick={(e) => setEvDetailQueryParams({
                vehicleId: vehicle.vehicleId,
                evId: alternativeVehicle.evId || "",
            })}
            leftSideActions={
                <div className="flex w-full px-3 items-center justify-between">
                    <FavoriteButton
                        alternativeVehicle={alternativeVehicle}
                        addToFavorites={() => addNewFavoriteVehicle(alternativeVehicle.evId || "")}
                        removeFromFavorites={() => removeFavoriteVehicle(alternativeVehicle.evId || "")}
                        isFavorited={!!alternativeVehicle.isFavorited}
                        isLoading={togglingFavoriteEvId === alternativeVehicle.evId}
                    />
                    <ArrowButton
                        text={t("vehicleCard.moreInfo")}
                        onClick={() => setEvDetailQueryParams({
                            vehicleId: vehicle.vehicleId,
                            evId: alternativeVehicle.evId || "",
                        })}
                    />

                </div>
            }
            cardHeader={<div className="flex w-full  justify-between relative">
                <div className="p-3 max-w-[90%]">
                    <BrandInfo
                        vehicleBrand={alternativeVehicle?.make}
                        vehicleModel={alternativeVehicle?.model}
                    />
                </div>
                <ElectrificationFactorPlaceholder
                    electrificationFactor={alternativeVehicle?.electrificationFactor || 0}
                />
            </div>}
            rightSideContent={<div className="flex flex-col h-full justify-between">
                <div>
                    <div className="flex w-full justify-end">
                        <CloseIcon
                            className="cursor-pointer"
                            onClick={() => removeAlternative()}
                        />
                    </div>
                    <div className="mt-4">

                        <Metric
                            localeCode={localeCode}
                            icon={cardColor.icons.price}
                            value={alternativeVehicle.listPrice || 0}
                            unit={"€"}
                            tooltipTitle={t("vehicleCard.tooltips.listPrice")}
                        />
                        <MetricWithComparison
                            localeCode={localeCode}
                            icon={<FuelCostsIcon />}
                            value={alternativeVehicle.yearlyFuelAndEnergyCosts || 0}
                            compareValue={vehicle.yearlyFuelAndEnergyCosts || 0}
                            unit={`€ / year`}
                            tooltipTitle={t("vehicleCard.tooltips.energyCost")}
                        />
                        <MetricWithComparison
                            localeCode={localeCode}
                            icon={<Co2EmissionsIcon />}
                            value={alternativeVehicle.yearlyCo2Emission || 0}
                            compareValue={vehicle.yearlyCo2Emission || 0}
                            unit={"kg / year"}
                            tooltipTitle={t("vehicleCard.tooltips.co2Emissions")}
                        />

                    </div>
                </div>
                <div className="mb-4">
                    <Button
                        variant={cardColor.buttonVariant}
                        size={ButtonSize.SMALL}
                        onClick={(e) => {
                            e?.stopPropagation();
                            setIsAlternativesOpen(true);
                        }}
                        className="flex w-full justify-center"
                    >
                        {t("vehicleCard.button.changeVehicle")}
                    </Button>
                </div>

            </div>}

        />
    )


}