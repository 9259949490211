import { ReactElement, createContext, useMemo, useState } from "react";
import { useMutation, useQuery as useRestQuery } from "react-query";
import { IElectrificationPlanner } from "../../../@types/driver/electrificationPlanner";

import { getElectrificationPlanner, resetElectrificationPlanner } from "../../../services/rest/electrificationPlanner";
import { VehicleSectionModel } from "../ElectrificationPlanner/CheckList/models/VehicleSectionModel";
import { ChargingSectionModel } from "../ElectrificationPlanner/CheckList/models/ChargingSectionModel";
import { AdditionalServiceSectionModel } from "../ElectrificationPlanner/CheckList/models/AdditionalServicesSectionModel";
import { getSectionProgress } from "../ElectrificationPlanner/CheckList/components/Sections/getSectionProgress";
import { IFilters } from "../../../electrify_frontend_common/types";





export const ElectrifyPlannerContext = createContext<{
    refetch: () => void,
    electrificationPlanner: Partial<IElectrificationPlanner> | null,
    vehicleSection: VehicleSectionModel | null,
    chargingSection: ChargingSectionModel | null,
    additionalServicesSection: AdditionalServiceSectionModel | null,
    electrificationProgress: number,
    resetElectrificationPlanner: () => void,
    favoriteEvIds: string[],

}>({
    electrificationPlanner: null,
    refetch: () => null,
    vehicleSection: null,
    chargingSection: null,
    additionalServicesSection: null,
    electrificationProgress: 0,
    resetElectrificationPlanner: () => null,
    favoriteEvIds: [],

});


function useElectrifyPlanner() {


    const [vehicleSection, setVehicleSection] = useState<VehicleSectionModel | null>(null);

    const [chargingSection, setChargingSection] = useState<ChargingSectionModel | null>(null);

    const [additionalServicesSection, setAdditionalServicesSection] = useState<AdditionalServiceSectionModel | null>(null);

    const [electrificationPlanner, setElectrificationPlanner] = useState<Partial<IElectrificationPlanner> | null>({
        vehicleSection: undefined,
        chargingSection: undefined,
        additionalServicesSection: undefined
    });

    const { refetch } = useRestQuery<IElectrificationPlanner>('electrification-planner', getElectrificationPlanner, {
        onSuccess: (data) => {
            setElectrificationPlanner(data);
            setVehicleSection(new VehicleSectionModel(data.vehicleSection));
            setChargingSection(new ChargingSectionModel(data.chargingSection));
            setAdditionalServicesSection(new AdditionalServiceSectionModel(data.additionalServicesSection));
        }
    });

    const resetMutaion = useMutation(resetElectrificationPlanner, {
        onSuccess: () => refetch()
    });


    const electrificationProgress = useMemo(() => {
        const { done: doneVehicle, total: totalVehicle } = getSectionProgress(vehicleSection);
        const { done: doneCharging, total: totalCharging } = getSectionProgress(chargingSection);
        const { done: doneAdditionalServices, total: totalAdditionalServices } = getSectionProgress(additionalServicesSection);
        const done = doneVehicle + doneCharging + doneAdditionalServices;
        const total = totalVehicle + totalCharging + totalAdditionalServices;
        return Math.ceil((done / total) * 100);

    }, [
        vehicleSection,
        chargingSection,
        additionalServicesSection
    ])


    return {
        electrificationPlanner,
        refetch,
        resetElectrificationPlanner: () => resetMutaion.mutate(),
        vehicleSection,
        chargingSection,
        additionalServicesSection,
        electrificationProgress,
        favoriteEvIds: electrificationPlanner?.favoriteEvIds || [],
    }
}





export const ElectrifyPlannerContextProvider = ({ children }: { children: ReactElement }) => {

    const {
        refetch,
        vehicleSection,
        chargingSection,
        additionalServicesSection,
        electrificationProgress,
        resetElectrificationPlanner,
        electrificationPlanner,
        favoriteEvIds
    } = useElectrifyPlanner();


    return (
        <ElectrifyPlannerContext.Provider
            value={{
                electrificationPlanner,
                resetElectrificationPlanner,
                refetch,
                vehicleSection,
                chargingSection,
                additionalServicesSection,
                electrificationProgress,
                favoriteEvIds,
            }}
        >
            {children}
        </ElectrifyPlannerContext.Provider>
    );



}