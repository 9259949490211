import { FleetSimulationContextProvider } from "../../../../../contexts/FleetSimulation";
import { FleetMetricsComparator } from "./FleetMetricsComparator";
import { FleetVehicleComparatorList } from "./FleetVehicleComparatorList";


export function FleetSimulation() {

  return (
    <FleetSimulationContextProvider>
      <>
        <FleetMetricsComparator />
        <FleetVehicleComparatorList />
      </>
    </FleetSimulationContextProvider>
  );
}
