import { ReactComponent as CrossIcon } from "../../../../assets/mobile-nav-cross.svg";
import SupportButton from "../Header/SupportButton";
import LanguageDropdown from "../Header/LanguageDropdown";
import UserDropdown from "../Header/UserDropdown";
import { ReactElement } from "react";

export default function DropdownNavbar({ setDropdownOpen, navbar } : {setDropdownOpen: any, navbar: ReactElement}) {
    
  
    return (
        <div
            className="absolute top-0 w-full sm:hidden z-50 p-10"
            style={{ backgroundColor: "#F7F8FA" }}
        >
            <div className="flex mb-20 justify-between content-center h-full">
                <div className="flex">
                    <LanguageDropdown />
                    <UserDropdown  />
                </div>
                <CrossIcon
                    className="cursor-pointer mt-2"
                    onClick={() => setDropdownOpen(false)}
                />
            </div>
            <div className="w-full justify-between">{navbar}</div>
            
            <div className="mx-10 mt-16">
                <hr className="border-solid border-Grey-tint mb-6" />
                <div className="flex justify-center w-full h-full">
                    <SupportButton
                        className={`h-14 border-solid rounded px-4 w-full justify-center`}
                    />
                </div>
            </div>
        </div>
    );
}
