import { useTranslation } from "react-i18next";
import ModalWrapper from "../../../../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../../../../electrify_frontend_common/components/ModalWrapper/Headers";
import { Button } from "../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../electrify_frontend_common/components/Buttonv2/types";




export default function VehicleDeleteModal({ isOpen, closeModal, onDelete, onArchive }: { isOpen: boolean; closeModal: () => void, onDelete: () => void, onArchive: () => void }) {
  const { t } = useTranslation("vehicles");

  return (
    <ModalWrapper
      className="w-1/2"
      isOpen={isOpen}
      close={closeModal}
    >
      <>
        <ModalHeader
          title={t("delete-vehicle.title")}
          onCancel={closeModal}
          isLastStep
        />

        <div className="p-10">
          <p>{t("delete-vehicle.description")}</p>
          <div className="mt-10">
            <Button
              className="mr-4"
              onClick={onDelete}
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.LARGE}
            >
              <div className="px-5">{t("delete-vehicle.delete-button")}</div>
            </Button>

            <Button
              onClick={onArchive}
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.LARGE}
            >
              <div className="px-5">{t("delete-vehicle.archive-button")}</div>
            </Button>

          </div>
        </div>
      </>
    </ModalWrapper>
  );
}
