import { Bar } from "react-chartjs-2";
import { useLayout } from "../../../../../../../../hooks/state/useLayout";
import { defineColorScheme } from "../../../../../../../../common/components/Charts/ChartColors";


export function DistanceLongestChart({ data, isModal }: { data: any; isModal?: boolean; }) {
    const { localeCode } = useLayout();

    const labels = isModal
        ? data.map((d: any) => d.label)
        : data.map((d: any) => d.label).slice(0, 10);
        
    const distances = [
        {
            data: isModal
                ? data.map((d: any) => d.distance)
                : data.map((d: any) => d.distance).slice(0, 10),
            backgroundColor: defineColorScheme(isModal ? 1 : 10),
        },
    ];

    return (
        <div className={`flex flex-col h-full ${isModal ? "h-screen" : ""}`}>
            <Bar
                options={{
                    indexAxis: 'y',
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function (tooltipItems: any) {
                                    return `${parseFloat(tooltipItems.parsed.x).toLocaleString(localeCode, { maximumFractionDigits: 3 })} km`;
                                },
                            },
                        },
                        legend: {
                            display: false,
                        },
                    },
                    scales: {
                        x: {
                            border: { display: false },
                            ticks: {
                                callback: function (value: any, index: number) {
                                    return index % 2 === 0 ? `${parseFloat(value).toLocaleString(localeCode, { maximumFractionDigits: 3 })} km` : "";
                                },
                                maxTicksLimit: 25,
                            },

                        },
                        y: {
                            border: { display: false },
                            grid: {
                                drawTicks: false,
                                drawOnChartArea: false,
                            },
                        },
                    },
                }}
                data={{
                    labels,
                    datasets: distances,
                }}
            />
        </div>
    );
}
