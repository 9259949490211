
import { Button } from '../../../../../../electrify_frontend_common/components/Buttonv2';
import { ButtonSize, ButtonVariant } from '../../../../../../electrify_frontend_common/components/Buttonv2/types';
import {ReactComponent as ArrowRightWhite} from '../../../../../../assets/arrow_right_strong_white.svg';
import {ReactComponent as ArrowRightDark} from '../../../../../../assets/right-arrow-icon-active.svg';


export function NextButton({ active, text, onClick }: { active: boolean, text: string, onClick: ()=> void }) {


    return (
        <Button
            variant={active? ButtonVariant.PRIMARY : ButtonVariant.SECONDRY}
            size={ButtonSize.LARGE}
            onClick={onClick}
        >
            <div className="flex items-center">
                {text}
                {active ? <ArrowRightWhite className="ml-2"/> : <ArrowRightDark className="ml-2"/>}
                
            </div>

        </Button>
    )


}