import { useTranslation } from "react-i18next";
import { DistanceSegments } from "./charts/DistanceSegments";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Distances } from "./charts/Distances";
import { DistancesChart } from "./charts/Distances/DistancesChart";
import { DistanceShareChart } from "./charts/DistanceShare/DistanceShareChart";
import { DistanceShare } from "./charts/DistanceShare";
import { DistanceAverage } from "./charts/DistanceAverage";
import { DistanceLongest } from "./charts/DistanceLongest";
import { DistanceLongestChart } from "./charts/DistanceLongest/DistanceLongestChart";
import useManagePaginatedModal from "../../../../../../hooks/utils/useManagePaginatedModal";
import { useLayout } from "../../../../../../hooks/state/useLayout";
import { GET_DRIVING_ANALYSYS } from "../../../../../../services/graphql/driver/queries";
import { DistanceSegmentChart } from "./charts/DistanceSegments/DistanceSegmentChart";
import { ExpandedChartWrapper } from "../../../../../../common/components/Charts/ExpandedChartWrapper";


const NUMBER_OF_PAGES = 4;

export function DrivingAnalysis() {
    const { t, i18n } = useTranslation("driverDrivingAnalysis");
    const language = i18n.language;

    const [drivingAnalysisData, setDrivingAnalysisData] = useState<any>(null);
    const [page, setPage] = useState(null);
    const [longestDistanceSortOrder, setLongestDistanceSortOrder] =
        useState("DESC");

    const { expandedMode, setExpandedMode, incrementPage, decrementPage } =
        useManagePaginatedModal(NUMBER_OF_PAGES);

    const { setHeader } = useLayout();

    useEffect(() => {
        setHeader({
            defaultHeaderTitle: t("page-title"),
            defaultHeaderDescription: t("page-subtitle"),
        });
    }, [language]);

    const { refetch } = useQuery(GET_DRIVING_ANALYSYS, {
        variables: { sortOrder: longestDistanceSortOrder },
        onCompleted: (data) => {
            setDrivingAnalysisData(data?.driverResultsDrivingAnalysis);
        },
    });

    useEffect(() => {
        refetch();
    }, [longestDistanceSortOrder]);

    const buildExpandedViewContent = (customPage?: number) => {
        const caseVar = customPage ?? page;
        switch (caseVar) {
            case 0:
                return {
                    getTitle: () => t("distances"),
                    getChart: () => (
                        <DistancesChart
                            perDay={drivingAnalysisData?.distancesPerDayAndWeek.perDay}
                            perWeek={drivingAnalysisData?.distancesPerDayAndWeek.perWeek}
                        />
                    ),
                };
            case 1:
                return {
                    getTitle: () => t("distance-segments.title"),
                    getChart: () => (
                        <DistanceSegmentChart
                            data={{
                                perDay: drivingAnalysisData?.distanceLengthSegments.perDay,
                                perTrip: drivingAnalysisData?.distanceLengthSegments.perTrip,
                            }}
                            isModal
                        />
                    ),
                };
            case 2:
                return {
                    getTitle: () => t("distance-share.title"),
                    getChart: () => (
                        <DistanceShareChart
                            data={{
                                weekdays:
                                    drivingAnalysisData?.distanceWeekDistribution.weekdays,
                                weekends:
                                    drivingAnalysisData?.distanceWeekDistribution.weekends,
                            }}
                        />
                    ),
                };
            case 3:
                return {
                    getTitle: () => t("distance-longest"),
                    getChart: () => (
                        <DistanceLongestChart
                            data={drivingAnalysisData?.sortedTrips}
                            isModal
                        />
                    ),
                    getSortFunction: () => () =>
                        setLongestDistanceSortOrder((curr) =>
                            curr == "ASC" ? "DESC" : "ASC"
                        ),
                    getIsCrescent: () => longestDistanceSortOrder == "ASC",
                };
            default:
                return null;
        }
    };

    return (
        <>
            {expandedMode.open ? (
                <ExpandedChartWrapper
                    buildExpandedViewContent={buildExpandedViewContent}
                    expandedMode={expandedMode}
                    closeExpanded={() => setExpandedMode({ open: false, page: 0 })}
                    incrementPage={() => incrementPage()}
                    decrementPage={() => decrementPage()}
                    numberOfPages={NUMBER_OF_PAGES}
                    page={page}
                    setPage={setPage}
                />
            ) : null}
            <div className="p-10">
                {drivingAnalysisData ? (
                    <Distances
                        openExpanded={() => setExpandedMode({ open: true, page: 0 })}
                        perDay={drivingAnalysisData.distancesPerDayAndWeek.perDay}
                        perWeek={drivingAnalysisData.distancesPerDayAndWeek.perWeek}
                    />
                ) : null}

                {drivingAnalysisData ? (
                    <DistanceSegments
                        openExpanded={() => setExpandedMode({ open: true, page: 1 })}
                        perDay={drivingAnalysisData.distanceLengthSegments.perDay}
                        perTrip={drivingAnalysisData.distanceLengthSegments.perTrip}
                    />
                ) : null}

                {drivingAnalysisData ? (
                    <div className="flex justify-between w-full">
                        <div className="w-1/2 pr-4">
                            <DistanceShare
                                openExpanded={() => setExpandedMode({ open: true, page: 2 })}
                                weekdays={drivingAnalysisData.distanceWeekDistribution.weekdays}
                                weekends={drivingAnalysisData.distanceWeekDistribution.weekends}
                            />
                        </div>
                        <div className="w-1/2 pl-4">
                            <DistanceAverage
                                data={drivingAnalysisData.averageDistanceWeekDistribution}
                            />
                        </div>
                    </div>
                ) : null}

                {drivingAnalysisData ? (
                    <DistanceLongest
                        openExpanded={() => setExpandedMode({ open: true, page: 3 })}
                        data={drivingAnalysisData.sortedTrips}
                        sortedCrescent={longestDistanceSortOrder == "ASC"}
                        sort={() =>
                            setLongestDistanceSortOrder((curr) =>
                                curr == "ASC" ? "DESC" : "ASC"
                            )
                        }
                    />
                ) : null}
            </div>
        </>
    );
}
