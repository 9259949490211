import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // TODO remove these
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"; // TDO remove these
import { ReactComponent as CloseIcon } from "../../assets/close.svg";

export const ModalHeader = ({
  title,
  message,
  icon,
  onCancel,
  onBack,
  isLastStep,
}: {
  title: string,
  message?: string,
  icon?: string,
  onCancel: () => void,
  onBack?: () => void,
  isLastStep?: boolean
}) => (
  <div
    className="p-10 flex justify-between h-auto"
    style={{ boxShadow: "0px 4px 24px 0px #00000014" }}
  >
    <div className="flex justify-around items-center">
      {!isLastStep && onBack ? (
        <div className="mr-5">
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={faArrowLeft}
            onClick={onBack}
          />
        </div>
      ) : null}
      <img className="mr-5" src={icon} />

      <div>
        <h1 className="text-xl text-Blueberry-dark-default">{title}</h1>
        <span className="text-sm text-Grey-shade">{message}</span>
      </div>
    </div>

    <div className="flex items-center">
      <div className="ml-10">
        <CloseIcon className="cursor-pointer" onClick={onCancel} />
      </div>
    </div>
  </div>
);


export default function SimpleModalHeader({ title, subTitle, onCancel, className }: { title: string, subTitle?: string, onCancel: () => void, className?: string }) {
  return (

    <div
      className={`p-10 flex w-full justify-between items-center ${className}`}
      style={{ boxShadow: "0px 4px 24px 0px #00000014" }}
    >
      <div>
        <h1 className="text-xl text-Blueberry-dark-default">{title || ''}</h1>
        <span className="text-md text-Grey-dark">{subTitle}</span>
      </div>
      <div className="flex items-center">
        <div className="ml-10">
          <CloseIcon className="cursor-pointer" onClick={(e) => {
            e.stopPropagation();
            onCancel()
          }} />
        </div>
      </div>
    </div>

  );
};