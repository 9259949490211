import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useLayout } from "../../../../../../../../hooks/state/useLayout";
import useHandleDrivingData from "../../../../../../../../hooks/utils/useHandleDrivingData";


export default function YearlyMileageChart({
    data,
    isModal,
}: {
    data: any;
    isModal?: boolean;
}) {
    const { t } = useTranslation("drivingAnalysis");
    const { localeCode } = useLayout();

    const { processDataForChart } = useHandleDrivingData();

    const { chartData, labels } = processDataForChart(data);

    return (
        <div className="flex flex-col h-full">
            <span className="mb-6 text-sm">{t("yearly-mileage-description")}</span>
            <div
                className="flex-grow flex items-center justify-center"
                style={{ minHeight: isModal ? "500px" : "370px" }}
            >
                <Bar
                    options={{
                        indexAxis: 'y',
                        plugins: {
                            tooltip: {
                                displayColors: false,
                                callbacks: {
                                    label: function (tooltipItems: any) {
                                        return `${parseFloat(tooltipItems.parsed.x).toLocaleString(localeCode)} km`;
                                    },
                                },
                            },
                            legend: { display: false },
                        },
                        scales: {
                            x: {
                                border: { display: false },
                                ticks: {
                                    callback: function (value: any, index: number) {
                                        return index % 2 === 0 ? `${parseFloat(value).toLocaleString(localeCode)} km` : "";
                                    },
                                    maxTicksLimit: 25,
                                },

                            },
                            y: {
                                border: { display: false },
                                grid: {
                                    drawTicks: false,
                                    drawOnChartArea: false,
                                },
                            },
                        },
                    }}
                    data={{
                        labels: labels,
                        datasets: chartData,
                    }}
                />
            </div>
        </div>
    );
}
