import { ConnectionState } from "./WebfleetSync";



export default function ConnectionMessage({
  connectionState,
  newVehiclesCount,
} : {
  connectionState: ConnectionState | null,
  newVehiclesCount: number
}) {
  if (!connectionState) return null;

  return (
    <div
      className={`bg-Grey-tint w-1/3 rounded p-4 mt-4 ${
        connectionState === ConnectionState.SUCCESS
          ? "text-Green-default"
          : "text-Red-default"
      }`}
    >
      {connectionState === ConnectionState.SUCCESS
        ? `${newVehiclesCount} new vehicles have been added!`
        : "Connection not possible; Please check for details on Webfleet site"}
    </div>
  );
}
