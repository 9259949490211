import { useCallback, useState } from 'react';
import axios from 'axios';
import {
	getAccessToken,
	getUserInfo,
	refreshAccessToken,
} from '../../services/keycloak';
import jwt_decode from 'jwt-decode';

export default function useAuthenticate({ setUser }: { setUser: any }) {
	const [loadingAuthentication, setLoadingAuthentication] = useState(true);

	const authenticateWithCredentials = useCallback(
		async (username: string, password: string): Promise<boolean> => {
			const data = await getAccessToken({
				username: username,
				password: password,
				client_id: 'App',
				grant_type: 'password',
			});

			if (data.access_token) {
				const accessToken = data.access_token;
				const refreshToken = data.refresh_token;
				localStorage.setItem('accessToken', accessToken);
				localStorage.setItem('refreshToken', refreshToken);
				setupUser(accessToken);

				return true;
			}
			return false;
		},
		[],
	);

	const logoutUser = useCallback(() => {
		axios.defaults.headers['authorization'] = '';
		localStorage.removeItem('accessToken');
		localStorage.removeItem('refreshToken');
		setUser(null);
		if (loadingAuthentication) {
			setLoadingAuthentication(false);
		}
		return;
	}, []);

	const setupUser = useCallback(async (accessToken: string) => {
		axios.defaults.headers['authorization'] = 'Bearer ' + accessToken;
		const userInfo = await getUserInfo();
		if (!userInfo) {
			console.log('RefreshToken: ', refreshToken);
			if (localStorage.getItem('refreshToken')) {
				refreshToken();
			}
			return;
		}

		// Fetch product and based on that check whether payment should be prompted
		
		const decodedInfo = jwt_decode(accessToken) as any;
		setUser({ ...decodedInfo, ...userInfo });
	}, []);

	const authenticateWithToken = useCallback(async () => {
		const accessToken = localStorage.getItem('accessToken');
		if (accessToken) {
			setupUser(accessToken);
			setLoadingAuthentication(false);
			return;
		}
		refreshToken();
	}, []);

	const refreshToken = useCallback(async () => {
		console.log('Refreshing token... ');
		const refreshToken = localStorage.getItem('refreshToken');

		if (!refreshToken) {
			logoutUser();
			return;
		}
		console.log('===> Refreshing token');
		const data = await refreshAccessToken(refreshToken);
		if (data) {
			const accessTokenNew = data.access_token;
			const refreshTokenNew = data.refresh_token;
			localStorage.setItem('refreshToken', refreshTokenNew);
			localStorage.setItem('accessToken', accessTokenNew);
			console.log('=======> WIll refetch user info');
			setupUser(accessTokenNew);
			setLoadingAuthentication(false);
			return;
		}
		logoutUser();
	}, []);

	return {
		authenticateWithCredentials,
		authenticateWithToken,
		refreshToken,
		logoutUser,
	};
}
