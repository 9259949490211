import { useContext, useMemo } from "react";
import { SectionContentWrapper, SectionHeader } from "../utils";
import { ElectrificationPlannerAdditionalServicesSectionSubSections, ElectrificationPlannerStepStatus } from "../../../models/Section";
import { getSectionProgress } from "../getSectionProgress";
import { useTranslation } from "react-i18next";
import { ElectrifyPlannerContext } from "../../../../../contexts/ElectrificationPlannerContext";

function PlusIcon() {

    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3 12.5H21" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12 3.5V21.5" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )

}

export function AdditionalServicesSection() {


    const { t } = useTranslation("electrificationPlanner");

    const { additionalServicesSection } = useContext(ElectrifyPlannerContext);

    const progress = useMemo(() => {
        return getSectionProgress(additionalServicesSection)
    }, [additionalServicesSection]);


    if (!additionalServicesSection) return null;

    return (<div>
        <SectionHeader icon={<PlusIcon />} title={t("additionalServicesSection.title")} description={t("additionalServicesSection.description")} progress={progress} />
        <SectionContentWrapper title={t("additionalServicesSection.subsections.administration.title").toUpperCase()}>
            {additionalServicesSection?.renderSubsection(ElectrificationPlannerAdditionalServicesSectionSubSections.ADMINISTRATION)}
        </SectionContentWrapper>
        <SectionContentWrapper title={t("additionalServicesSection.subsections.vehicleData.title").toUpperCase()}>
            {additionalServicesSection?.renderSubsection(ElectrificationPlannerAdditionalServicesSectionSubSections.VEHICLE_DATA)}
        </SectionContentWrapper>
        <SectionContentWrapper title={t("additionalServicesSection.subsections.searchAndNavigation.title").toUpperCase()}>
            {additionalServicesSection?.renderSubsection(ElectrificationPlannerAdditionalServicesSectionSubSections.SEARCH_AND_NAVIGATION)}
        </SectionContentWrapper>
        <SectionContentWrapper title={t("additionalServicesSection.subsections.financialRewards.title").toUpperCase()}>
            {additionalServicesSection?.renderSubsection(ElectrificationPlannerAdditionalServicesSectionSubSections.FINANCIAL_REWARDS)}
        </SectionContentWrapper>
    </div>
    )


}