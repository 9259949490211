import axios from "axios";
import { AlternativeEletricVehicleDetails, IFilters } from "../types";


export async function getEvs(query: {
    sortBy?: { key: string; order: "ASC" | "DESC" };
    selectedFilters?: IFilters;
    page?: number;
    pageSize?: number;
    userCountryCode: string;
    userId?: string;
    favoritesOnly?: boolean;
  }): Promise<{
    page: number;
    pageSize: number;
    totalPagesCount: number;
    electricVehicles: any[];
    totalVehiclesCount: number;
    filters: IFilters;
    sortBy: { key: string; order: "ASC" | "DESC" };
  }> {
    return (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/ev-finder/all-evs`, query)).data;
  }
  

  export async function getEvDetails(
    evId: string,
    userCountryCode: string
  ): Promise<AlternativeEletricVehicleDetails> {
    return (
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/ev-finder/ev-details/`, {
        params: {
          evId,
          userCountryCode,
        },
      })
    ).data;
  }
  
  export async function getAllBrands(userCountryCode: string) {
    return (
      await axios.get(`${process.env.REACT_APP_BACKEND_URL}/ev-finder/brands`, {
        params: {
          userCountryCode,
        },
      })
    ).data;
  }
  

  export const getFilters = async () => {
    return (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/ev-finder/filters`)).data;
  }

export const patchFilters = async (input: { filters: IFilters, sortBy: { key: string; order: "ASC" | "DESC"; } }) => {
  return (await axios.patch(
      `${process.env.REACT_APP_BACKEND_URL}/ev-finder/filters`,
      {...input}
  )).data;
}