import { ReactElement } from "react"



export function VehicleListAndFiltersWrapper({
    vehicleList,
    filters
}: {
    vehicleList: ReactElement,
    filters: ReactElement
}) {




    return (
        <div className="grid grid-cols-12 w-full gap-8 p-6">
            <div className={`col-span-9`}>
                {vehicleList}
            </div>
            <div className={`col-span-3`}>
                {filters}
            </div>
        </div>
    )



}