import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import OnboardingModal from "../OnboardingModal";
import { ReactComponent as CloseIcon } from "../../../../../../assets/close.svg";
import { useUserData } from "../../../../../../hooks/state/useUserData";
import { ArrowRightIcon } from "../../../../../../electrify_frontend_common/components/ArrowRight";


export function OnboardingBanner({
    completeButtonText
}: {
    completeButtonText?: string;
}) {

    const { t, i18n } = useTranslation("driverOnboarding");

    const [onboardingBannerVisible, setOnboardingBannerVisible] = useState(true);
    const [onboardingModalOpen, setOnboardingModalOpen] = useState(false);

    const { user, refreshUserAccountInfo } = useUserData();

    const contentBasedOnOnboardingStatus = useMemo(() => {
        if (!user?.accountInfo?.lastOnboardingStep && !user?.accountInfo?.dongleRoadMapCompleted) {
            return (
                <div>
                    <div>
                        {t("banners.to-be-started-onboarding.part-1", { name: user?.name })}
                    </div>
                    <div className="flex items-center">
                        {t("banners.to-be-started-onboarding.part-2")}{" "}
                        <span
                            onClick={() => setOnboardingModalOpen(true)}
                            className="text-Blueberry-dark-default underline ml-2 cursor-pointer"
                        >
                            {t("banners.to-be-started-onboarding.action")}
                        </span>
                        <ArrowRightIcon className="ml-1" color={"#830751"} />
                    </div>
                </div>
            )
        }
        return <div>
            <div>
                {t("banners.incomplete-onboarding.part-1", { name: user?.name })}
            </div>
            <div className="flex items-center">
                {t("banners.incomplete-onboarding.part-2")}{" "}
                <span
                    onClick={() => setOnboardingModalOpen(true)}
                    className="text-Blueberry-dark-default underline ml-2 cursor-pointer"
                >
                    {t("banners.incomplete-onboarding.action")}
                </span>
                <ArrowRightIcon className="ml-1" color={"#830751"} />
            </div>
        </div>
    }, [user?.accountInfo?.lastOnboardingStep, i18n.language])


    return (
        <>
            {onboardingModalOpen ? (
                <OnboardingModal
                    isOpen={onboardingModalOpen}
                    startingStep={user?.accountInfo?.lastOnboardingStep || 0}
                    onCancel={() => {
                        refreshUserAccountInfo();
                        setOnboardingModalOpen(false);
                    }}
                    completeButtonText={completeButtonText}
                />
            ) : null}
            {!user?.accountInfo?.onboarded && onboardingBannerVisible ? (
                <div className="bg-Grey-tint justify-between flex w-full mt-5 px-10 py-3">
                    {contentBasedOnOnboardingStatus}
                    <CloseIcon
                        className="cursor-pointer"
                        onClick={() => setOnboardingBannerVisible(false)}
                    />
                </div>
            ) : null}
        </>
    )

}