import { useContext } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useLazyQuery } from "@apollo/client";
import { GET_USER_ACCOUNT_INFO } from "../../services/graphql/fleet/queries";
import { User } from "../../@types";
import { Setting } from "../../@types/settings";

export const useUserData = () => {

    const { user, setUser } = useContext(AuthContext);

    const [refreshUserAccountInfo] = useLazyQuery(
        GET_USER_ACCOUNT_INFO,
        {
          onCompleted: (data) => {
            setUser((user: User) => ({
                ...user,
                accountInfo: {
                    ...user.accountInfo,
                    ...data.userAccountInfo
                }
            }))
           
          },
          onError: (err) => console.log("ERRRR>>>", err),
        }
      );
    
    const updateUserSettings = (newSettings: Partial<Setting>) => {
        setUser((user: User) => ({
            ...user,
            settings: {
                ...user.settings,
                ...newSettings
            }
        }))
    }

    return {
        user,
        setUser,
        updateUserSettings,
        refreshUserAccountInfo,
     
    }
}