import { useContext } from "react";
import { AlternativesModal } from "../AlternativesModal";
import { VehicleComparatorContext } from "../../../../../../../contexts/VehicleComparatorContext";
import { PaymentProcessModal } from "../../../../../../../common/components/PaymentProcess/PaymentProcessModal";
import { EvDetailedViewModal } from "../../../../../../../common/components/VehicleSimulationAndSelection/EvDetailedViewModal";
import VehicleCard from "../../../../../../../common/components/VehicleSimulationAndSelection/VehicleCards/VehicleCard";
import { Spinner } from "../../../../../../../electrify_frontend_common/components/Spinner";
import NoEvSelectedCard from "../../../../../../../common/components/VehicleSimulationAndSelection/VehicleCards/NoEvSelectedCard";
import VehicleNotPaidCard from "../../../../../../../common/components/VehicleSimulationAndSelection/VehicleCards/VehicleNotPaidCard";
import { FleetEvFinderSelectedVehicleCard } from "../FleetEvFinderVehicleCards/FleetEvFinderSelectedVehicleCard";


export function FleetVehicleComparator() {

  const {
    vehicle,
    refetchOverviewData,
    planUpgradeModalOpen,
    setPlanUpgradeModalOpen,
    selectedVehicleToUpgrade,
    setSelectedVehicleToUpgrade,
    evDetailQueryParams,
    setIsAlternativesOpen,
    selectedEvLoading,
    setEvDetailQueryParams,
    selectedAlternativeElectricVehicle,
    refetchSimulationVehiclesInfo
  } = useContext(VehicleComparatorContext);


  return (
    <>
      {planUpgradeModalOpen && selectedVehicleToUpgrade ? (
        <PaymentProcessModal
          open={planUpgradeModalOpen}
          onCancel={() => setPlanUpgradeModalOpen(false)}
          onFinalizeProcess={async () => {
            setPlanUpgradeModalOpen(false);
            setSelectedVehicleToUpgrade(null);
            refetchSimulationVehiclesInfo();
            refetchOverviewData(); // Refetch overview
            
          }}
          vehicleId={selectedVehicleToUpgrade}
        />
      ) : null}

      <AlternativesModal />


      {evDetailQueryParams ? (
        <EvDetailedViewModal
          isOpen={!!evDetailQueryParams}
          onCancel={() =>
            setEvDetailQueryParams(null)
          }
          vehicleId={vehicle?.vehicleId}
          evDetailQueryParams={evDetailQueryParams.evId}
        />
      ) : null}

      <div className="grid grid-cols-9 mb-10">
        <div className="col-span-4">
          <VehicleCard vehicle={vehicle} />
        </div>
        <div className="col-span-5">
          {vehicle?.isVehiclePaid ? (
            selectedEvLoading || !vehicle?.simulationIsReady ? (
              <Spinner />
            ) : selectedAlternativeElectricVehicle ? (
              <FleetEvFinderSelectedVehicleCard
                alternativeVehicle={selectedAlternativeElectricVehicle}               
              />
            ) : (
              <NoEvSelectedCard
                onClickAlternatives={() => { setIsAlternativesOpen(true) }}
                vehicle={vehicle}
              />
            )
          ) : (
            <VehicleNotPaidCard
              onUpgradeToPremium={() => {
                setPlanUpgradeModalOpen(true);
                setSelectedVehicleToUpgrade(vehicle?.vehicleId || null);
              }}
              vehicle={vehicle}
            />
          )}
        </div>
      </div>
    </>
  );
}
