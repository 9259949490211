import { useEffect, useState } from "react";

import { SuitabilityCard } from "./SuitabilityCard";
import { ReactComponent as InformationIcon } from "../../../../../assets/information-icon-blueberry.svg";
import { useQuery } from "@apollo/client";

import { useTranslation } from "react-i18next";
import { useLayout } from "../../../../../hooks/state/useLayout";
import { Tooltip } from "../../../../../electrify_frontend_common/components/Tooltip";
import { IEvSuitability } from "../../../../../@types/driver/evSuitability";
import { GET_DRIVER_VEHICLE, GET_EV_SUITABILITY } from "../../../../../services/graphql/driver/queries";
import { Vehicle } from "../../../../../@types/vehicle";
import { SuitabilityType } from "../../../../../enums";


export function EvSuitability() {
    const { t, i18n } = useTranslation("driverEvSuitability");
    const language = i18n.language;
    const { setHeader } = useLayout();

    const [displayResultsWithFilters, setDisplayResultsWithFilters] = useState(
        localStorage.getItem("ev-suitability-check") === "true" ? true : false
    );

    useEffect(() => {
        setHeader({
            defaultHeaderTitle: t("header.title"),
            defaultHeaderDescription: t("header.description"),
            defaultHeaderOptions: (
                <div className="text-Grey-dark">
                    <div>{t("header.filter-title")}</div>
                    <div className="p-4 py-2 mt-1 border border-Grey-tint rounded ">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center text-xs mr-4">
                                <input
                                    type="checkbox"
                                    className="mr-2 w-4 h-4 cursor-pointer text-Blueberry-dark-default bg-transparent rounded border-Blueberry-dark-default focus:ring-0 focus:outline-offset-0 focus:outline-0 focus:ring-offset-0"
                                    checked={displayResultsWithFilters}
                                    onClick={() => {
                                        setDisplayResultsWithFilters(
                                            !displayResultsWithFilters
                                        );
                                        localStorage.setItem(
                                            "ev-suitability-check",
                                            !displayResultsWithFilters == true
                                                ? "true"
                                                : "false"
                                        );
                                    }}
                                />
                                <div>{t("header.filter-label")}</div>
                            </div>
                            <Tooltip
                                content={<div className="w-56">{t("header.filter-tooltip")}</div>}
                                placement="bottom-start"
                            >
                                {<InformationIcon className="ml-1" />}
                            </Tooltip>
                        </div>
                    </div>
                </div>
            ),
        });
    }, [displayResultsWithFilters, language]);

    useEffect(() => {
        console.log("Display with filters: ", displayResultsWithFilters);
    }, [displayResultsWithFilters]);

    const { data } = useQuery<{ evSuitability: IEvSuitability }>(
        GET_EV_SUITABILITY,
        {
            variables: {
                onlyFilteredAlternatives: displayResultsWithFilters,
            },
        }
    );

    const { data: userVehicle } = useQuery<{ driverVehicle: Vehicle }>(
        GET_DRIVER_VEHICLE
    );

    return (
        <div className="grid grid-cols-2 gap-10 h-fit p-10">
            <SuitabilityCard
                userVehicle={userVehicle?.driverVehicle}
                type={SuitabilityType.NO_RESTRICTIONS}
                comfortCategory={data?.evSuitability?.noRestrictions}
            />

            <SuitabilityCard
                userVehicle={userVehicle?.driverVehicle}
                type={SuitabilityType.VERY_COMFORTABLE}
                comfortCategory={data?.evSuitability?.veryComfortable}
            />

            <SuitabilityCard
                userVehicle={userVehicle?.driverVehicle}
                type={SuitabilityType.FAIRLY_COMFORTABLE}
                comfortCategory={data?.evSuitability?.fairlyComfortable}
            />

            <SuitabilityCard
                userVehicle={userVehicle?.driverVehicle}
                type={SuitabilityType.ADAPTATIONS_NEEDED}
                comfortCategory={data?.evSuitability?.adaptationsNeeded}
            />
        </div>
    );
}
