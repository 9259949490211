import axios from 'axios'
import qs from 'qs'
import { User } from '../../@types'

export async function getUserInfo(): Promise<User | null> {
    try {
        const res = await axios({
            url:
                process.env.REACT_APP_KEYCLOAK_URL +
                '/auth/realms/thaltegos/protocol/openid-connect/userinfo',
            method: 'GET',
        })
        return res.data
    } catch (error) {
        return null;
    }
}

export async function getAccessToken(payload: any): Promise<{ access_token: string, refresh_token: string }> {
    // eslint-disable-next-line no-useless-catch
    try {
        const res = await axios({
            url:
                process.env.REACT_APP_KEYCLOAK_URL +
                '/auth/realms/thaltegos/protocol/openid-connect/token',
            method: 'POST',
            data: qs.stringify(payload),
        })
        return res.data
    } catch (error) {
        throw error
    }
}

export async function refreshAccessToken(refreshToken: string): Promise<{ access_token: string, refresh_token: string } | null> {
    try {
        const response = await axios({
            url:
                process.env.REACT_APP_KEYCLOAK_URL +
                '/auth/realms/thaltegos/protocol/openid-connect/token',
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            data: qs.stringify({
                client_id: 'App',
                grant_type: 'refresh_token',
                refresh_token: refreshToken,
            }),
        })
        return response.data
    } catch (error) {
        return null;
    }
}

export async function resetTempPassword(payload: any) {
    try {
        const response = await axios({
            url:
                process.env.REACT_APP_BACKEND_URL +
                'auth/password/resetTempPassword',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            data: payload,
        })
        return response.data
    } catch (error) {
        return
    }
}

export async function updateAccountInfo(payload: any) {
    const response = await axios({
        url: process.env.REACT_APP_KEYCLOAK_URL + '/auth/realms/thaltegos/account/',
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        data: payload,
    })
    return response.data
}

export async function createNewPassword(payload: any) {
    const response = await axios({
        url:
            process.env.REACT_APP_KEYCLOAK_URL +
            '/auth/realms/thaltegos/account/credentials/password/',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        data: payload,
    })
    return response.data
}

export async function forgotPassword(payload: any) {
    const response = await axios({
        url: process.env.REACT_APP_BACKEND_URL + '/auth/password/forgot',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        data: payload,
    })
    return response.data
}

export async function checkResetTokenValidity(payload: any) {
    const response = await axios({
        url: process.env.REACT_APP_BACKEND_URL + '/auth/password/check-reset-token',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        data: payload,
    })
    return response.data

}


export async function resetPassword(payload: any) {
    const response = await axios({
        url: process.env.REACT_APP_BACKEND_URL + '/auth/password/reset',
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        data: payload,
    })
    return response.data
}

export async function verifyEmail(payload: any) {
    try {
        const response = await axios({
            url: process.env.REACT_APP_BACKEND_URL + '/auth/verify-email',
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            data: { token: payload },
        })
        return response.data
    } catch (e) {
        console.log("SOmething went wrong while verifying the email");
    }

}
