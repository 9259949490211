import { useProductUpdate } from "./Electrify/hooks/useProductUpdate";
import { useContext, useMemo } from "react";
import { ElectrifyDriver } from "./Electrify";
import { ElectrificationPlannerDriver } from "./ElectrificationPlanner";
import { B2CProductTreeContext } from "./contexts/B2CProductTreeContext";


export function B2CProductsGateway() {

    useProductUpdate();

    const { productFamily } = useContext(B2CProductTreeContext);


    const selectedB2CProduct = useMemo(() => {
        if (productFamily === "electrify") {
            return <ElectrifyDriver />
        } else if (productFamily === "electrification_planner") {
            return <ElectrificationPlannerDriver />
        } else {
            return <ElectrificationPlannerDriver />
        }
    }, [productFamily])


    return (
        <>{selectedB2CProduct} </>
    )
}