import { Slider, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../styles";


export function GenericSlider({
    value,
    min,
    max,
    step,
    onChangeMain,
    onChangeTextInput1,
    onChangeTextInput2,
    symbol,
    title,
    className,
    backgroundColor
}: {
    value: number[];
    min: number;
    max: number;
    step: number;
    onChangeMain: (event: Event, newValue: number | number[]) => void;
    onChangeTextInput1?: any;
    onChangeTextInput2?: any;
    symbol: string;
    title?: string;
    className?: string;
    backgroundColor?: "light" | "dark";
}) {

    const styles = useStyles();
    const { i18n, t } = useTranslation("evFinder");

    const localeCode = i18n.language;

    const formattedValue1 = !value[0]
        ? null
        : `${symbol} ${value[0]?.toLocaleString(localeCode) || 0}`;

    const formattedValue2 =
        value[1] === max
            ? null
            : `${symbol} ${value[1]?.toLocaleString(localeCode) || 0}`;

    return (
        <div className={className}>
            <h5 className="text-Grey-dark text-sm mb-2">{title}</h5>
            <Slider
                classes={styles}
                value={value}
                min={min}
                step={step}
                max={max}
                onChange={onChangeMain}
                valueLabelDisplay="auto"
                valueLabelFormat={(value) =>
                    value === min
                        ? t("filters.listPrice.min-unlimited")
                        : value === max
                            ? t("filters.listPrice.max-unlimited")
                            : ` ${symbol} ${value.toLocaleString(localeCode)}`
                }
                color="secondary"
                sx={{
                    "& .MuiSlider-thumb": {
                        color: "#830751",
                    },
                    "& .MuiSlider-track": {
                        color: "#830751",
                        opacity: 1,
                    },
                    "& .MuiSlider-rail": {
                        color: "#DDD",
                        opacity: 1,
                    },
                }}
            />
            <div className="flex w-full justify-between">
                <div className="w-full mr-1 md:mr-0 md:w-5/12">
                    <TextField
                        variant="outlined"
                        disabled
                        sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: backgroundColor === "dark" ? "white" : "black",
                            },
                        }}
                        value={
                            value[0] === min
                                ? t("filters.listPrice.min-unlimited")
                                : formattedValue1
                        }
                        onChange={onChangeTextInput1}
                        InputProps={{
                            style: {
                                fontSize: "0.75rem",
                                border: "1px solid white",
                                color: "white",
                            },
                        }}
                    />
                </div>
                <div className="w-full ml-1 md:ml-0 md:w-5/12">
                    <TextField
                        variant="outlined"
                        disabled
                        sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: backgroundColor === "dark" ? "white" : "black",
                            },
                        }}
                        value={
                            value[1] === max
                                ? t("filters.listPrice.max-unlimited")
                                : formattedValue2
                        }
                        onChange={onChangeTextInput2}
                        InputProps={{
                            style: {
                                fontSize: "0.75rem",
                                border: "1px solid white",
                                color: "white",
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
}