import { TFunction } from "i18next";
import { DriveTrainPropulsion } from "../../../types";

export const translateAvailability = (t: TFunction<"evFinder", undefined>, availability?: string): string => {
    switch (availability) {
        case "CURRENT":
            return t("vehicleDetailedView.coreData.availabilityStatus.current");
        case "FUTURE":
            return t("vehicleDetailedView.coreData.availabilityStatus.future");
        case "PAST":
            return t("vehicleDetailedView.coreData.availabilityStatus.past");
        default:
            return "-"
    }
}


export const translateBatteryType = (t: TFunction<"evFinder",undefined> ,batteryType?: string): string => {
    switch (batteryType) {
        case "LITHIUM_ION":
            return t("vehicleDetailedView.battery.types.lithiumIon");
        default:
            return "-"
    }
}

export const translatePropulsion = (t: TFunction<"evFinder",undefined>, propulsion?: string): string => {
    switch (propulsion) {
        case DriveTrainPropulsion.FWD:
            return t("vehicleDetailedView.technicalData.propulsions.frontWheelDrive");
        case DriveTrainPropulsion.RWD:
            return t("vehicleDetailedView.technicalData.propulsions.rearWheelDrive");
        case DriveTrainPropulsion["4x4"]:
            return t("vehicleDetailedView.technicalData.propulsions.allWheelDrive");
        default:
            return '-'
    }
}

export const translatePortLocations = (t: TFunction<"evFinder",undefined>, location?: string): string => {
    switch (location) {
        case "FRONT_LEFT":
            return t("vehicleDetailedView.charging.portLocations.frontLeft");
        case "FRONT_RIGHT":
            return t("vehicleDetailedView.charging.portLocations.frontRight");
        case "FRONT_MIDDLE":
            return t("vehicleDetailedView.charging.portLocations.frontMiddle");
        case "MIDDLE_LEFT":
            return t("vehicleDetailedView.charging.portLocations.middleLeft");
        case "MIDDLE_RIGHT":
            return t("vehicleDetailedView.charging.portLocations.middleRight");
        case "REAR_LEFT":
            return t("vehicleDetailedView.charging.portLocations.rearLeft");
        case "REAR_RIGHT":
            return t("vehicleDetailedView.charging.portLocations.rearRight");
        default:
            return "-"
    }
}


export const translateBooleanValues = (t: TFunction<"evFinder",undefined> ,values: boolean | undefined): string => {
    return values ? t("vehicleDetailedView.booleanValues.yes") : t("vehicleDetailedView.booleanValues.no");
}
