import ChartWrapper from "../../../../../../../../common/components/Charts/ChartWrapper";
import { DistanceLongestChart } from "./DistanceLongestChart";
import { useTranslation } from "react-i18next";

export function DistanceLongest({
  data,
  openExpanded,
  sort,
  sortedCrescent,
}: any) {
  const { t } = useTranslation("driverDrivingAnalysis");

  return (
    <ChartWrapper
      sortedCrescent={sortedCrescent}
      sort={sort}
      title={t("distance-longest")}
      expand={openExpanded}
    >
      <div className="h-96">
        <DistanceLongestChart data={data} />
      </div>
    </ChartWrapper>
  );
}
