
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { VehicleComparatorContext } from "../../../../../../../contexts/VehicleComparatorContext";
import ModalWrapper from "../../../../../../../electrify_frontend_common/components/ModalWrapper";
import SimpleModalHeader from "../../../../../../../electrify_frontend_common/components/ModalWrapper/Headers";
import { AlternativeVehicles } from "./AlternativeVehicles";

export function AlternativesModal () {

  const { t } = useTranslation("fleetSimulation");

  const {
    vehicle,
    getVehicleSelectedEv,
    isAlternativesOpen: isOpen,
    setIsAlternativesOpen,
  } = useContext(VehicleComparatorContext);

  const onCancel = () => {
    setIsAlternativesOpen(false);
    getVehicleSelectedEv({
      variables: {
        vehicleId: vehicle?.vehicleId,
        renewCalculation: false,
      },
    });
  }

  if(!vehicle) return null;

  return (
    <ModalWrapper
      isOpen={isOpen}
      close={()=> null}
      className="w-5/6">
      <>
        <SimpleModalHeader title={t("alternatives-selection.title")} onCancel={onCancel} />
        <div className="px-6 pb-5 w-full overflow-y-scroll">
          <AlternativeVehicles/>
        </div>
      </>
    </ModalWrapper>
  );
};

