import { useState } from "react";


export default function useManagePaginatedModal(numberOfPages : number) {

    // State and logic for expanded view
  const [expandedMode, setExpandedMode] = useState<{page: number, open: boolean}>({ page: 0, open: false });

  const incrementPage = () =>
    setExpandedMode({
      ...expandedMode,
      page:
        expandedMode.page + 1 < numberOfPages
          ? expandedMode.page + 1
          : expandedMode.page,
    });

  const decrementPage = () =>
    setExpandedMode({
      ...expandedMode,
      page: expandedMode.page - 1 >= 0 ? expandedMode.page - 1 : 0,
    });

    return {    
        expandedMode, setExpandedMode, incrementPage, decrementPage
    }

}