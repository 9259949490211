import { useEffect, useMemo, useState } from "react";



export function useScreenSize() {

    const [widthIsLessThan1024, setWidthIsLessThan1024] = useState(window.innerWidth < 1024);
    const [isSmallDesktopView, setIsSmallDesktopView] = useState(window.innerWidth < 1440 && window.innerWidth >= 1024);
    const [isTabletView, setIsTabletView] = useState(window.innerWidth < 1440 && window.innerWidth >= 768);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const reactToScreenSizeChange = () => {
        setWindowWidth(window.innerWidth);
        setWidthIsLessThan1024(window.innerWidth < 1024);
        setIsTabletView(window.innerWidth < 1024 && window.innerWidth >= 768);
        setIsMobileView(window.innerWidth < 768);
    }

    useEffect(()=> {
        window.addEventListener("resize", reactToScreenSizeChange)
        return () => {
            window.removeEventListener("resize", reactToScreenSizeChange)
        }
    },[])
    

    return {
        widthIsLessThan1024,
        windowWidth,
        isTabletView,
        isMobileView,
        isSmallDesktopView
    };

}