/* React imports */
import { useEffect, useState } from "react";

/* Third party imports */
import { useQuery, useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
} from "@mui/material";

/* Local imports */
import VehicleDeleteModal from "./VehicleEditor/DeleteVehicleModal";
import VehicleRestoreModal from "./VehicleEditor/RestoreVehicleModal";

// import { useModal } from "components/Modal/ModalService";
import { ReactComponent as HasHomeCharging } from "../../../../../assets/has-home-charging.svg";
import { ReactComponent as NoHomeCharging } from "../../../../../assets/no-home-charging.svg";
import { ReactComponent as OptionsDropdownIcon } from "../../../../../assets/options-dropdown-icon.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/deleted-icon.svg";
import { ReactComponent as ArchivedIcon } from "../../../../../assets/archived-icon.svg";
import { ReactComponent as LowDataQualityIcon } from "../../../../../assets/low-data-quality-icon.svg";
import { ReactComponent as PlusIcon } from "../../../../../assets/plus-icon.svg";
import { ReactComponent as UpgradePlanIcon } from "../../../../../assets/upgrade-plan-icon.svg";

import OnboardingModal from "../Onboarding/OnboardingModal";
import VehicleEditor from "./VehicleEditor";

import { useNavigate } from "react-router-dom";
import { VehicleSummary } from "../../../../../@types";
import { useLayout } from "../../../../../hooks/state/useLayout";
import { GET_VEHICLES_SUMMARY } from "../../../../../services/graphql/fleet/queries";
import { DELETE_VEHICLE, RESTORE_VEHICLE, UPDATE_VEHICLE } from "../../../../../services/graphql/fleet/mutations";
import { PaymentProcessModal } from "../../../../../common/components/PaymentProcess/PaymentProcessModal";
import Dropdown from "../../../../../electrify_frontend_common/components/Dropdown";


export default function Vehicles() {
  // const showModal = useModal();
  const { t, i18n } = useTranslation("vehicles");
  const language = i18n.language;
  const navigate = useNavigate();

  const [editorOpen, setEditorOpen] = useState(false);

  const [planUpgradeModalOpen, setPlanUpgradeModalOpen] = useState(false);
  const [dataSyncModalOpen, setDataSyncModalOpen] = useState(false);
  const [vehicleToEdit, setVehicleToEdit] = useState<string | null>(null);
  const [vehicleToDelete, setVehicleToDelete] = useState<VehicleSummary | null>(
    null
  );
  const [vehicleToRestore, setVehicleToRestore] =
    useState<VehicleSummary | null>(null);
  const [sortBy, setSortBy] = useState({
    sortByColumn: "vehicleName",
    sortAscending: 1,
  });

  useEffect(() => {
    if (vehicleToEdit) setEditorOpen(true);
  }, [vehicleToEdit]);

  const { setHeader, localeCode } = useLayout();

  useEffect(() => {
    setHeader({
      defaultHeaderTitle: t(`page-title`),
      defaultHeaderDescription: t(`page-subtitle`),
      defaultHeaderOptions: (
        <div className="flex">
          <button
            className="text-Grey-dark text-sm	px-4 py-3 mr-2 border border-solid border-Grey-tint rounded flex items-center"
            onClick={() => {
              setDataSyncModalOpen(true);
              // window.location.href = "/electrify/onboarding";
            }}
          >
            <PlusIcon className="mr-2" />
            {t(`add-vehicle-button`)}
          </button>
          <button
            className="text-white text-sm	leading-6	px-4 py-3 mr-2 flex items-center bg-Blueberry-dark-default rounded"
            onClick={() => setPlanUpgradeModalOpen(true)}
          >
            <UpgradePlanIcon className="mr-2" />
            {t(`upgrade-plan-button`)}
          </button>
        </div>
      ),
    });
  }, [language]);

  const { data: vehiclesSummaryData, refetch: refetchVehicleSummaryData } =
    useQuery(GET_VEHICLES_SUMMARY, {
      variables: {
        sortBy: sortBy?.sortByColumn,
        sortAscending: sortBy?.sortAscending,
      },
      onError: (error) => console.log("ERRRR>>>", error),
    });

  const [deleteVehicle] = useMutation(DELETE_VEHICLE, {
    update(cache, { data: { deleteVehicle } }) {
      cache.modify({
        fields: {
          vehicles(existingVehicles = []) {
            return [
              ...existingVehicles.filter(
                (item: any) => item.__ref !== "Vehicle:" + deleteVehicle.id
              ),
            ];
          },
        },
      });
    },
  });

  const [updateVehicle] = useMutation(UPDATE_VEHICLE);

  const [restoreVehicle] = useMutation(RESTORE_VEHICLE, {
    onCompleted: () => setVehicleToRestore(null),
    onError: (error) => console.log("ERRRR>>>", error),
  });

  const allVehiclesSummary = vehiclesSummaryData?.vehiclesSummary;
  const vehiclesCount = allVehiclesSummary?.length;

  const tableHeaders = [
    { property: "vehicleName", title: t(`vehicle`) },
    { property: "vehicleModelName", title: t(`model`) },
    { property: "totalDistance", title: t(`distance`) },
    { property: "earliestTripDate", title: t(`first-trip`) },
    { property: "latestTripDate", title: t(`last-trip`) },
    { property: "hasHomeCharging", title: t(`home-charging`) },
    { property: "tariffName", title: t(`chargylize-plan`) },
  ];

  const archiveVehicle = async (vehicle: VehicleSummary) => {
    await updateVehicle({
      variables: {
        id: vehicle?.vehicleId,
        vehicle: { archived: true },
      },
    });
    setVehicleToDelete(null);
    refetchVehicleSummaryData();
  };

  const unArchiveVehicle = async (vehicle: VehicleSummary) => {
    await updateVehicle({
      variables: {
        id: vehicle?.vehicleId,
        vehicle: { archived: false },
      },
    });
    refetchVehicleSummaryData();
  };

  const deleteSelectedVehicle = async (vehicle: VehicleSummary) => {
    await deleteVehicle({
      variables: {
        id: vehicle.vehicleId,
      },
    });
    setVehicleToDelete(null);
    refetchVehicleSummaryData();
  };

  const restoreSelectedVehicle = async (vehicle: VehicleSummary) => {
    await restoreVehicle({
      variables: {
        id: vehicle.vehicleId,
      },
    });
    setVehicleToDelete(null);
    refetchVehicleSummaryData();
  };

  const renderVehicleStatus = (vehicle: VehicleSummary) => {
    if (vehicle?.deletionDate)
      return {
        icon: <DeleteIcon />,
        style: "bg-Light-purple text-Blueberry-dark-default text-xs",
        tooltip:
          t("tooltips.deleted") +
          new Date(vehicle.deletionDate).toLocaleDateString(localeCode) +
          ".",
      };
    else if (vehicle?.archived)
      return {
        icon: <ArchivedIcon />,
        style: "bg-Grey-background text-Grey-default text-xs",
        tooltip: t("tooltips.archived"),
      };
    else if (vehicle?.qualityDataAmount === "Low")
      return {
        icon: <LowDataQualityIcon />,
        style: "text-xs text-Grey-dark",
        tooltip: t("tooltips.low-data-quality-amount"),
      };
    else if (vehicle?.qualityDataAmount === "Medium")
      return {
        icon: <LowDataQualityIcon />,
        style: "text-xs text-Grey-dark",
        tooltip: t("tooltips.medium-data-quality-amount"),
      };
    else if (vehicle?.qualityContinuity === "Low")
      return {
        icon: <LowDataQualityIcon />,
        style: "text-xs text-Grey-dark",
        tooltip: t("tooltips.low-data-continutity"),
      };
    else if (vehicle?.qualityContinuity === "Medium")
      return {
        icon: <LowDataQualityIcon />,
        style: "text-xs text-Grey-dark",
        tooltip: t("tooltips.medium-data-continutity"),
      };
    return {
      icon: undefined,
      style: "text-xs text-Grey-dark",
      Tooltip: undefined,
    };
  };

  const vehicleDropdownOptions = (vehicle: VehicleSummary) => {
    const result = [
      {
        key: t("dropdown-options.edit"),
        onClick: () => setVehicleToEdit(vehicle?.vehicleId),
      },
    ];

    if (vehicle?.deletionDate) {
      result.push({
        key: t("dropdown-options.restore"),
        onClick: () => setVehicleToRestore(vehicle),
      });
    } else {
      result.push({
        key: t("dropdown-options.delete"),
        onClick: () => setVehicleToDelete(vehicle),
      });
    }

    if (vehicle?.archived) {
      result.push({
        key: t("dropdown-options.unarchive"),
        onClick: () => unArchiveVehicle(vehicle),
      });
    } else if (!vehicle?.archived && !vehicle?.deletionDate) {
      result.push({
        key: t("dropdown-options.archive"),
        onClick: () => archiveVehicle(vehicle),
      });
    }

    return result;
  };

  return (
    <div>
      {planUpgradeModalOpen ? (
        <PaymentProcessModal
          open={planUpgradeModalOpen}
          onCancel={() => setPlanUpgradeModalOpen(false)}
          onFinalizeProcess={async () => {
            setPlanUpgradeModalOpen(false);
            await refetchVehicleSummaryData();
          }}
        />
      ) : null}

      {dataSyncModalOpen ? (
        <OnboardingModal
          startingStep={0}
          isOpen={dataSyncModalOpen}
          goToPageProps={{
            action: ()=> navigate("/vehicles"),
            text: "Go to vehicles"
          }}
          onCancel={() => {
            setDataSyncModalOpen(false);
            refetchVehicleSummaryData();
          }}
        />
      ) : null}

      {vehicleToEdit ? (
        <VehicleEditor
          vehicleId={vehicleToEdit}
          onClose={() => {
            setVehicleToEdit(null);
            refetchVehicleSummaryData();
          }}
        />
      ) : null}

      {vehicleToDelete && (
        <VehicleDeleteModal
          isOpen={!!vehicleToDelete}
          onDelete={() => deleteSelectedVehicle(vehicleToDelete)}
          closeModal={() => setVehicleToDelete(null)}
          onArchive={() => archiveVehicle(vehicleToDelete)}
        />
      )}

      {vehicleToRestore && (
        <VehicleRestoreModal
          onRestore={() => restoreSelectedVehicle(vehicleToRestore)}
          closeModal={() => setVehicleToRestore(null)}
        />
      )}

      {vehiclesCount > 0 ? (
        <Table
          className="mt-4 px-24"
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <TableHead>
            <TableRow hover={false} className="sticky top-0 bg-white">
              {tableHeaders?.map((item, i) => (
                <TableCell
                  key={i}
                  align={"center"}
                  padding={"normal"}
                  sortDirection={false}
                >
                  <TableSortLabel
                    active={sortBy.sortByColumn === item?.property}
                    direction={
                      sortBy.sortByColumn === item?.property
                        ? sortBy.sortAscending === 1
                          ? "asc"
                          : "desc"
                        : "asc"
                    }
                    onClick={() =>
                      setSortBy({
                        sortByColumn: item?.property,
                        sortAscending:
                          sortBy.sortByColumn === item?.property
                            ? -1 * sortBy.sortAscending
                            : 1,
                      })
                    }
                  >
                    <span className="text-xs font-bold text-Grey-dark">
                      {item?.title}
                    </span>
                  </TableSortLabel>
                </TableCell>
              ))}
              <TableCell
                key={1}
                align={"center"}
                padding={"normal"}
                sortDirection={false}
              ></TableCell>
              <TableCell
                key={2}
                align={"center"}
                padding={"normal"}
                sortDirection={false}
              ></TableCell>
            </TableRow>
          </TableHead>
          {allVehiclesSummary?.map(
            (vehicleSummary: VehicleSummary, i: number) => {
              const vehicleStatus = renderVehicleStatus(vehicleSummary);
              const vehicleStyle = vehicleStatus?.style;
              return (
                <TableRow hover={true} key={`${vehicleSummary?.vehicleId}`}>
                  <TableCell
                    align={"center"}
                    padding={"normal"}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    {vehicleSummary?.vehicleName || "-"}
                  </TableCell>
                  <TableCell
                    align={"center"}
                    padding={"normal"}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    {vehicleSummary?.vehicleModelName || "-"}
                  </TableCell>
                  <TableCell
                    align={"center"}
                    padding={"normal"}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    {vehicleSummary?.totalDistance?.toLocaleString(localeCode) +
                      " km"}
                  </TableCell>
                  <TableCell
                    align={"center"}
                    padding={"normal"}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    {vehicleSummary?.earliestTripDate
                      ? new Date(
                          vehicleSummary?.earliestTripDate
                        ).toLocaleDateString(localeCode)
                      : "-"}
                  </TableCell>
                  <TableCell
                    align={"center"}
                    padding={"normal"}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    {vehicleSummary?.latestTripDate
                      ? new Date(
                          vehicleSummary.latestTripDate
                        ).toLocaleDateString(localeCode)
                      : "-"}
                  </TableCell>
                  <TableCell
                    align={"center"}
                    padding={"normal"}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    <span className="flex justify-center">
                      {vehicleSummary?.hasHomeCharging ? (
                        <Tooltip
                          title={t("tooltips.has-home-charger")}
                          arrow
                          placement="top-start"
                        >
                          {<HasHomeCharging />}
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={t("tooltips.has-no-home-charger")}
                          arrow
                          placement="top-start"
                        >
                          {<NoHomeCharging />}
                        </Tooltip>
                      )}
                    </span>
                  </TableCell>
                  <TableCell
                    align={"center"}
                    padding={"normal"}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    {vehicleSummary?.tariffName || "-"}
                  </TableCell>
                  <TableCell
                    align={"right"}
                    // padding={'normal'}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    {vehicleStatus?.tooltip ? (
                      <Tooltip
                        title={vehicleStatus?.tooltip}
                        arrow
                        placement="top-start"
                      >
                        {vehicleStatus?.icon}
                      </Tooltip>
                    ) : (
                      vehicleStatus?.icon
                    )}
                  </TableCell>
                  <TableCell
                    align={"right"}
                    padding={"normal"}
                    sortDirection={false}
                    className={vehicleStyle}
                  >
                    <Dropdown
                      className={"w-16"}
                      placeholder={
                        <span className="flex justify-end">
                          <OptionsDropdownIcon />
                        </span>
                      }
                      data={vehicleDropdownOptions(vehicleSummary)}
                      loadUpwards={vehiclesCount > 8 && i >= vehiclesCount - 2}
                    />
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </Table>
      ) : null}
    </div>
  );
}
