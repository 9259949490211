import { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { forgotPassword } from '../../../../services/keycloak'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { RegistrationFormInput } from '../Signup/components/utils'
import { PasswordResetErrors } from '../../../../errors'
import { Button } from '../../../../electrify_frontend_common/components/Buttonv2'
import { ButtonSize, ButtonVariant } from '../../../../electrify_frontend_common/components/Buttonv2/types'

export default function ForgotPassword() {
  const { t } = useTranslation('forgotPassword')

  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(false)


  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup
        .string()
        .email(t("email-invalid"))
        .required("email-required")
    }),
    onSubmit: (values) => {
      submit(values.email);
    }
  })


  const submit = async (value: string) => {

    setLoading(true)
    try {
      await forgotPassword({
        emailOrUsername: value,
        language: localStorage.getItem('languageCode') || 'en'
      })
      setSuccess(true)
    } catch (error: any) {
      if (
        error?.response?.data?.message === PasswordResetErrors.USER_NOT_FOUND
      ) {
        formik.setErrors({ email: t('non-existing-email') })
      } else {
        formik.setErrors({ email: t('unknown-error') })
      }
    } finally {
      setLoading(false)
    }

  }

  return (
    <>
      {success ?
        <>
          <div className="w-full flex justify-start mb-10">
            <h1 className='text-3xl text-Black-default'>{t('success.title')}</h1>
          </div>
          <div className='w-full'>
            <p className='text-base text-Grey-shade'>{t('success.description')}</p>
          </div>
        </>
        :
        <form
          className="h-full relative"
          onSubmit={formik.handleSubmit}
        >
          <div className="w-full flex justify-start">
            <h1 className='text-3xl text-Black-default mb-2'>{t('title')}</h1>
          </div>
          <div className='mb-10 text-base text-Grey-shade'>
            <p>{t('description1')}</p>
            <p>{t('description2')}</p>
          </div>
          <RegistrationFormInput
            type="email"
            name="email"
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            value={formik.values.email}
            placeholder={t("email")}
            label={t("email")}
            touched={formik?.touched?.email}
            error={formik?.errors?.email ? t(`${formik?.errors?.email}`) : undefined}
          />
          <Button
            variant={ButtonVariant.PRIMARY}
            size={ButtonSize.LARGE}
            onClick={() => null}
            className='w-full'
          >
            {t('reset')}
          </Button>


          <div className='w-full flex absolute bottom-0 justify-center mt-40 text-base text-grey-shade'>{t('no-account')} <a href={"/sign-up"} className='text-Blueberry-dark-default ml-1'>{t('register')}</a></div>
        </form>
      }
    </>
  )
}
