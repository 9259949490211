import { useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_USER_ACCOUNT_INFO } from "../../services/graphql/fleet/queries";
import { User } from "../../@types";
import { TRIGGER_SIMULATION_UPDATES } from "../../services/graphql/common/mutations";

export const useFetchAccountInfo = () => {

    const { user, setUser } = useContext(AuthContext);
    
    const [triggerSimulationUpdates] = useMutation(TRIGGER_SIMULATION_UPDATES);

    const [getUserAccountInfo] = useLazyQuery(GET_USER_ACCOUNT_INFO, {
        onCompleted: (data) => {
            setUser((user: User) => ({ ...user, accountInfo: data?.userAccountInfo }))
        },
        onError: (error) => console.log("ERROR>>>", error),
    });

    useEffect(() => {
        if (user && !user.accountInfo) {
            getUserAccountInfo();
            triggerSimulationUpdates();
        }
    }, [user])

} 