import { AuthContextProvider } from './contexts/AuthContext';
import { LayoutContextProvider } from './contexts/LayoutContext';
import { APIClientsProviders } from './configs';
import { CustomToaster } from './common/components/CustomToaster';
import { ElectrifyUserTypeGateway } from './products';

function App() {

    return (
        <>
            <CustomToaster />
            <AuthContextProvider>
                <APIClientsProviders>
                    <LayoutContextProvider>
                        <ElectrifyUserTypeGateway />
                    </LayoutContextProvider>
                </APIClientsProviders>
            </AuthContextProvider>
        </>
    );
}

export default App;
