import { useState } from "react";
import { ReactComponent as DropdownIcon } from "../../../../../../../assets/dropdown-icon-grey.svg";
import { ReactComponent as CollapseIcon } from "../../../../../../../assets/dropdown-icon-grey-reverse.svg";

import { ReactComponent as InfoIcon } from "../../../../../../../assets/information-icon.svg";
import { SyncStep } from "../../../../../../../@types";

export function DataUploadHelper({ steps, text } : {steps: SyncStep[], text: string}) {
  const [expandedView, setExpandedView] = useState(false);

  return (
    <div className="border border-solid rounded border-Grey-tint mx-10 p-5 pt-6">
      <div
        className="text-Grey-shade flex justify-between cursor-pointer"
        onClick={() => setExpandedView(!expandedView)}
      >
        <div className="flex items-center">
          <InfoIcon className="mr-2" />
          <h2>{text}</h2>
        </div>
        <div>{expandedView ? <CollapseIcon /> : <DropdownIcon />}</div>
      </div>
      {expandedView
        ? steps.map((s, i) => {
            return (
              <div className="flex justify-between h-36 mt-4">
                <div className="h-30 w-1/6 bg-Grey-tint m-4"> </div>
                <div className="p-4 w-5/6 pr-10">
                  <div className="p-1 w-14 justify-center bg-Grey-tint text-Blueberry-dark-default flex rounded mb-2">
                    {s.name}
                  </div>
                  <div className="text-sm text-Grey-shade">{s.content}</div>
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
}
