import { useContext } from "react";
import { VehicleComparatorContext } from "../../../../../../../../contexts/VehicleComparatorContext";
import { FleetEvFinderAlternativeVehicleCard } from "../../FleetEvFinderVehicleCards/FleetEvFinderAlternativeVehicleCard";
import { AlternativeVehicle } from "../../../../../../../../electrify_frontend_common/types";
import { FavoriteAlternativesList } from "./FavoriteAlternativesList";

import { Filters } from "../../../../../../../../common/components/EVFinder/Versions[B-C]/Filters";
import { Spinner } from "../../../../../../../../electrify_frontend_common/components/Spinner";
import { EvFinderControlPanel } from "../../../../../../../../common/components/EVFinder/Versions[B-C]";
import { PaginationSelector } from "../../../../../../../../electrify_frontend_common/components/EvFinder/PaginationSelector";



export function AlternativeVehicles() {

    const {
        evFinderData,
        alternativeEvsCount,
        filtersHandler,
        setCurrentPage,
        resetFiltersAndRefetch,
        loading,
        sortBy,
        setSortBy,
        currentPage,
        favoritesOnly,
        setFavoritesOnly
    } = useContext(VehicleComparatorContext);




    const alternativeEVs = evFinderData?.vehicleEvAlternatives?.evAlternatives;


    const getVehiclesCount = () => ({
        totalCount:
            alternativeEvsCount?.vehicleEvAlternativesCount?.totalCount,
        favoritesCount:
            alternativeEvsCount?.vehicleEvAlternativesCount?.favoritesCount,
    });



    return (
        < >
            <div className="flex">
                <div className={`h-full ${favoritesOnly ? 'w-full' : 'w-9/12'} `}>
                    <EvFinderControlPanel
                        alternativesCount={getVehiclesCount().totalCount}
                        favoritesCount={getVehiclesCount().favoritesCount}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                        favoritesOnly={favoritesOnly}
                        setFavoritesOnly={setFavoritesOnly}
                    />

                    {loading ? (
                        <Spinner />
                    ) : (
                        <>
                            {favoritesOnly ?
                                <FavoriteAlternativesList />
                                :
                                <div className="grid grid-cols-3 gap-3">
                                    {alternativeEVs
                                        ? alternativeEVs?.map((item: Partial<AlternativeVehicle>, idx: number) => (
                                            
                                            <FleetEvFinderAlternativeVehicleCard
                                                key={`${item._id}`}
                                                alternativeVehicle={item}
                                            />
                                        ))
                                        : null}
                                </div>
                            }
                        </>
                    )}
                </div>

                {!favoritesOnly ? <div className="pl-4 pt-10 w-3/12">
                    <Filters
                        filtersHandler={filtersHandler}
                        resetFilters={resetFiltersAndRefetch}
                    />
                </div> : null}
            </div>
            {!favoritesOnly ? <PaginationSelector
                currentPage={currentPage}
                setPage={setCurrentPage}
                totalPagesCount={evFinderData?.vehicleEvAlternatives?.totalPagesCount || 0}
            /> : null}

        </>
    )


}