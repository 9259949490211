import { useTranslation } from "react-i18next";
import { ReactComponent as CheckIcon } from "../../../../../assets/check.svg";

export const Disclaimer = ({ duration } : {duration: number}) => {

  const {t} = useTranslation("paymentProcess");

  return (
    <div className="mt-2 p-3">
      <div className="flex w-full text-xs text-Grey-dark">
        <CheckIcon className="mr-3 justify-between w-1/4"/> <div className="w-3/4">{t("disclaimer.point-1.part-1")} {duration} {t("disclaimer.point-1.part-2")}</div>
      </div>
      <div className="flex w-full text-xs text-Grey-dark mt-2">
        <CheckIcon className="mr-3 justify-between w-1/4"/> <div className="w-3/4">{t("disclaimer.point-2")}</div>
      </div>
      <div className="flex w-full text-xs text-Grey-dark mt-2">
        <CheckIcon className="mr-3 justify-between w-1/4"/> <div className="w-3/4">{t("disclaimer.point-3")}</div>
      </div>
    </div>
  );
};
