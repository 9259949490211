import { useTranslation } from "react-i18next";

import { useContext, useEffect } from "react";
import { VehicleList } from "./VehicleList";
import { FreeEvFinderContext, FreeEvFinderContextProvider } from "./FreeEvFinderContext";
import { ElectrifyPlannerContextProvider } from "../../../contexts/ElectrificationPlannerContext";
import { useLayout } from "../../../../../hooks/state/useLayout";
import { FavoriteVehiclesList } from "./FavoriteVehicleList/FavoriteVehiclesList";
import { Filters } from "../../../../../electrify_frontend_common/components/EvFinder/Versions[A-B]/Filters";
import { VehicleListAndFiltersWrapper } from "../../../../../common/components/EVFinder/VehicleListAndFiltersWrapper";





export function FreeEvFinder() {


    return (
        <ElectrifyPlannerContextProvider>
            <FreeEvFinderContextProvider>
                <FreeEvFinderContent />
            </FreeEvFinderContextProvider>
        </ElectrifyPlannerContextProvider>
    );


}

function FreeEvFinderContent() {

    const { t, i18n } = useTranslation("evFinder");
    const language = i18n.language;

    const { setHeader, currencySymbol } = useLayout();
    const { favoritesOnly, filters, resetFilters, updateFilters } = useContext(FreeEvFinderContext);

    useEffect(() => {
        setHeader({
            defaultHeaderTitle: `${t("title")}`,
            defaultHeaderDescription: `${t("descriptionAB")}`,
        });
    }, [language]);


    return (
        <>
            {favoritesOnly ?
                <FavoriteVehiclesList /> :
                <VehicleListAndFiltersWrapper
                    vehicleList={<VehicleList />}
                    filters={<Filters
                        filters={filters}
                        updateFilters={updateFilters}
                        resetFilters={resetFilters}
                        backgroundColor="light"
                        currencySymbol={currencySymbol}
                    />} />}
        </>

    );
}