import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserType } from "../../../../enums";

export function useWebsiteRedirectParams() {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Get query parameters
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const product = queryParams.get('selected-product');
      const userType = queryParams.get('user-type');
      if (product) localStorage.setItem('selected-product', product);
      if (userType && userType === UserType.DRIVER || userType === UserType.FLEET) localStorage.setItem('user-type', userType);
      if (userType) {
        navigate(`/sign-up/${userType}`);
        return;
      }
      if (product) navigate("/");
    }

  }, [location.search]);

}