import { Dispatch, ReactElement, SetStateAction, useContext } from "react";
import { LayoutContext } from "../../contexts/LayoutContext";

export const useLayout = (): {
  headerTitle: any;
  headerDescription: any;
  headerOption: any;
  setHeader: ({
    defaultHeaderTitle,
    defaultHeaderDescription,
    defaultHeaderOptions,
  }: {
    defaultHeaderTitle: string;
    defaultHeaderDescription: string;
    defaultHeaderOptions?: ReactElement;
  }) => void;
  currencySymbol: any;
  localeCode: any;
} => {
  
  const {
    headerTitle,
    setHeaderTitle,
    headerDescription,
    setHeaderDescription,
    headerOption,
    setHeaderOption,
    currencySymbol,
    localeCode,
  } = useContext(LayoutContext);

  const setHeader = ({
    defaultHeaderTitle,
    defaultHeaderDescription,
    defaultHeaderOptions,
  }: {
    defaultHeaderTitle: string;
    defaultHeaderDescription: string;
    defaultHeaderOptions?: ReactElement;
  }) => {
    setHeaderTitle(defaultHeaderTitle || "");
    setHeaderDescription(defaultHeaderDescription || "");
    setHeaderOption(defaultHeaderOptions || null);
  };

  return {
    headerTitle,
    headerDescription,
    headerOption,
    setHeader,
    currencySymbol,
    localeCode,
  };
};
