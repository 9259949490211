import { useTranslation } from "react-i18next";
import { useQuery as useRestQuery } from "react-query";
import { useState } from "react";
import { getActiveSubscriptionForVehicle } from "../../../../../../../services/rest/paymentProcess";
import { PaymentProcessModal } from "../../../../../../../common/components/PaymentProcess/PaymentProcessModal";
import { Button } from "../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../../electrify_frontend_common/components/Buttonv2/types";


export default function VehicleTariff({ vehicleId }: { vehicleId: string }) {
  const { t } = useTranslation("editVehicle");

  const [planUpgradeModalOpen, setPlanUpgradeModalOpen] = useState(false);

  const { data: vehicleTariff, refetch } = useRestQuery({
    queryKey: ["tariff", vehicleId],
    queryFn: () => getActiveSubscriptionForVehicle(vehicleId),
  });

  const getVehicleTariff = () => {
    return vehicleTariff?.filter((vt: any) => vt.product == "electrify")[0];
  };

  return (
    <>
      {planUpgradeModalOpen ? (
        <PaymentProcessModal
          open={planUpgradeModalOpen}
          onCancel={() => setPlanUpgradeModalOpen(false)}
          onFinalizeProcess={async () => {
            setPlanUpgradeModalOpen(false);
            refetch();
          }}
          vehicleId={vehicleId}
        />
      ) : null}

      <div className="h-auto pl-8">
        <h1 className="text-xl mb-6">{t("tariff-title")}</h1>
        <>
          <div
            className={`h-auto border solid rounded border-Grey-tint w-1/2 p-6 py-10 ${getVehicleTariff() ? "bg-Grey-tint" : ""
              }`}
          >
            <div
              className={`${getVehicleTariff()?.name
                ? "text-Blueberry-dark-default"
                : "text-Grey-dark"
                } text-xl`}
            >
              {getVehicleTariff()
                ? `${getVehicleTariff()?.name} ${t("plan-info")}`
                : t("no-plan-added")}
            </div>

            <div className="text-xs text-Grey-dark mt-2">
              {getVehicleTariff()
                ? t("tariff-message")
                : t("no-tariff-message")}
            </div>
          </div>

          {/* TODO check button --> Only when no subscription is avalable?? */}

          {getVehicleTariff() ? null : (
            <Button
              className="mt-4"
              variant={ButtonVariant.SECONDRY}
              size={ButtonSize.MEDIUM}
              onClick={() => setPlanUpgradeModalOpen(true)}
            >
              {t("subscribe-vehicle")}
            </Button>
          )}
        </>

      </div>
    </>
  );
}
