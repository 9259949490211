import { useContext } from "react";
import { ArrowButton } from "../../../../../../../../electrify_frontend_common/components/ArrowButton";
import { Button } from "../../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../../../electrify_frontend_common/components/Buttonv2/types";
import { BrandInfo } from "../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/BrandInfo";
import { Metric, MetricWithComparison } from "../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/Metric";
import { SelectedVehicleCard } from "../../../../../../../../electrify_frontend_common/components/VehicleCards/SelectedVehicleCard";
import { DriverEVFinderContext } from "../../contexts/DriverEVFinderContext";
import { useTranslation } from "react-i18next";
import { Price } from "../../../../../../../../electrify_frontend_common/icons/Price";
import FavoriteButton from "../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/FavoriteButton";
import { ElectrificationFactorPlaceholder } from "../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/ElectrificationFactor";
import { AlternativeVehicle } from "../../../../../../../../electrify_frontend_common/types";
import { FuelCostsIcon } from "../../../../../../../../electrify_frontend_common/icons/FuelCostsIcon";
import { Co2EmissionsIcon } from "../../../../../../../../electrify_frontend_common/icons/Co2EmissionsIcon";
import { SimulationVehicleInfo } from "../../../../../../../../@types";
import { useLayout } from "../../../../../../../../hooks/state/useLayout";



export function DriverEvFinderSelectedVehicleCard({
    userVehicle,
    alternativeVehicle,
    onClick,
}: {
    userVehicle: SimulationVehicleInfo,
    alternativeVehicle: Partial<AlternativeVehicle>,
    onClick: () => void,
}) {

    const { t } = useTranslation("evFinder");

    const { localeCode } = useLayout();

    const {
        openEvDetailsModal,
        removeFavoriteVehicle,
        addNewFavoriteVehicle,
        togglingFavoriteEvId
    } = useContext(DriverEVFinderContext);


    return (
        <SelectedVehicleCard
            vehicle={alternativeVehicle}
            moreInfoClick={(e) => openEvDetailsModal(alternativeVehicle.evId || "")}
            leftSideActions={
                <div className="flex w-full px-3 items-center justify-between">
                    <FavoriteButton
                        alternativeVehicle={alternativeVehicle}
                        addToFavorites={() => addNewFavoriteVehicle(alternativeVehicle.evId || "")}
                        removeFromFavorites={() => removeFavoriteVehicle(alternativeVehicle.evId || "")}
                        isFavorited={!!alternativeVehicle.isFavorited}
                        isLoading={togglingFavoriteEvId === alternativeVehicle.evId}
                    />
                    <ArrowButton
                        text={t("vehicleCard.moreInfo")}
                        onClick={() => openEvDetailsModal(alternativeVehicle.evId || "")}
                    />

                </div>
            }
            cardHeader={<div className="flex w-full  justify-between relative">
                <div className="p-3 max-w-[90%]">
                    <BrandInfo
                        vehicleBrand={alternativeVehicle?.make}
                        vehicleModel={alternativeVehicle?.model}
                    />
                </div>
                <ElectrificationFactorPlaceholder
                    electrificationFactor={alternativeVehicle?.electrificationFactor || 0}
                />
            </div>}

            rightSideContent={<div className="flex flex-col justify-between h-full">
                <div className="mt-6">
                    <Metric
                        localeCode={localeCode}
                        icon={<Price className="w-4" />}
                        value={alternativeVehicle.listPrice || 0}
                        unit={"€"}
                        tooltipTitle={t("vehicleCard.tooltips.listPrice")}
                    />
                    <MetricWithComparison
                        localeCode={localeCode}
                        icon={<FuelCostsIcon className="w-4" />}
                        value={alternativeVehicle.yearlyFuelAndEnergyCosts || 0}
                        compareValue={userVehicle.yearlyFuelAndEnergyCosts || 0}
                        unit={`€ / year`}
                        tooltipTitle={t("vehicleCard.tooltips.energyCost")}
                    />
                    <MetricWithComparison
                        localeCode={localeCode}
                        icon={<Co2EmissionsIcon />}
                        value={alternativeVehicle.yearlyCo2Emission || 0}
                        compareValue={userVehicle.yearlyCo2Emission || 0}
                        unit={"kg / year"}
                        tooltipTitle={t("vehicleCard.tooltips.co2Emissions")}
                    />
                </div>
                <div className="mb-4">
                    <Button
                        variant={ButtonVariant.SECONDRY}
                        size={ButtonSize.SMALL}
                        onClick={(e) => {
                            e?.stopPropagation();
                            onClick();
                        }}
                        className="flex w-full justify-center"
                    >
                        {t("vehicleCard.button.changeVehicle")}
                    </Button>
                </div>
            </div>}

        />
    )


}