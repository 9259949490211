import { useTranslation } from "react-i18next";
import { ReactComponent as PetrolIcon } from "../../../../../../../assets/petrol-icon.svg";
import { useMemo } from "react";
import { Vehicle } from "../../../../../../../@types/vehicle";
import { FuelType } from "../../../../../../../@types/vehicle/enums";
import { SettingsInput } from "../../../../../../B2B/Electrify/pages/Settings/FuelAndEnergyDetails/FuelDetails";


export function FuelDetails({
  energyAndFuelDetails,
  vehicle
}: {
  energyAndFuelDetails: any;
  vehicle?: Vehicle
}) {

  const { t } = useTranslation("settingsSimulation");

  const defaultFuelPrice= useMemo(() => {
    return vehicle?.modelInfo?.fuelType == FuelType.PETROL ? energyAndFuelDetails?.defaultPrices?.petrolPrice : energyAndFuelDetails?.defaultPrices?.dieselPrice;
  },[vehicle, energyAndFuelDetails])

  const defaultFuelEmissions = useMemo(()=> {
    return vehicle?.modelInfo?.fuelType == FuelType.PETROL ? energyAndFuelDetails?.defaultPrices?.petrolCo2Emissions : energyAndFuelDetails?.defaultPrices?.dieselCo2Emissions;
  },[vehicle, energyAndFuelDetails])

  if (!energyAndFuelDetails.defaultPrices) return null;

  const priceLabel = `${energyAndFuelDetails.defaultPrices.currencySymbol} / l`;

  return (
    <div className="flex flex-col h-full w-full rounded-md border border-Grey-tint">
      <div className="h-2 bg-Grey-tint" />
      <div className="flex flex-col items-center py-4">
        <PetrolIcon />
        <span className="mt-2 text-lg font-medium">Fuel</span>
      </div>
      <div className="py-4 px-5 flex flex-col pb-6  items-center border-t border-Grey-tint">
        <span className="font-medium text-lg mb-6">
          {t("fuel-energy-table.price")}
        </span>
        <div className="flex w-1/2 justify-between ">
          <SettingsInput
            saveSettings={energyAndFuelDetails.saveFuelSettings}
            label={`${t("fuel-energy-table.fuel.petrol")} / ${t(
              "fuel-energy-table.fuel.diesel"
            )}`}
            units={priceLabel}
            value={energyAndFuelDetails.petrolPrice}
            setValue={energyAndFuelDetails.setPetrolPrice}
            defaultValue={defaultFuelPrice}
          />
        </div>
      </div>
      <div className="py-4 px-5 flex flex-col items-center border-t border-Grey-tint">
        <span className="font-medium text-lg mb-6">
          {t("fuel-energy-table.emissions")}
        </span>
        <div className="flex w-1/2 justify-between">
          <SettingsInput
            saveSettings={energyAndFuelDetails.saveFuelSettings}
            label={`${t("fuel-energy-table.fuel.petrol")} / ${t(
              "fuel-energy-table.fuel.diesel"
            )}`}
            units={"g / l"}
            value={energyAndFuelDetails.petrolCo2}
            setValue={energyAndFuelDetails.setPetrolCo2}
            defaultValue={defaultFuelEmissions}
          />
        </div>
      </div>
    </div>
  );
}
