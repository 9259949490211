import { gql } from "@apollo/client";

export const GET_USER_ACCOUNT_INFO = gql`
  query UserAccountInfo {
    userAccountInfo {
      onboarded
      usableProduct
      dongleRoadMapCompleted
      lastOnboardingStep
      languageCode
      countryCode
      preSelectedProduct
      billingInfo {
        name
        address {
          street
          streetNr
          country
          city
          zip
        }
      }
      electrificationPlannerOnboarding {
        complete
        currentStep
      }
      shippingInfo {
        name
        address {
          street
          streetNr
          country
          city
          zip
        }
      }
    }
  }
`;

export const GET_FLEET_RESULTS_OVERVIEW = gql`
  query GetFleetResultsOverview {
    fleetResultsOverview {
      currentFleet {
        totalVehiclesCount
        vehicleTypes {
          bev
          ice
          phev
        }
        yearlyCo2Emission
        yearlyMileage
        yearlyFuelAndEnergyCosts
      }
      simulatedFleet {
        electrificationFactor
        totalVehiclesCount
        vehicleTypes {
          bev
          ice
          phev
        }
        yearlyCo2Emission
        yearlyCo2EmissionChange
        yearlyFuelAndEnergyCosts
        yearlyFuelAndEnergyCostsChange
      }
    }
  }
`;

export const GET_VEHICLE_DATA = gql`
  query GetVehicleData($id: ID!) {
    vehicle(id: $id) {
      id
      name
      userId
      yearlyTax
      yearlyMaintenanceCost
      yearlyInsuranceCost
      plannedReplacementDate
      originalInfo {
        name
        vehicleNumber
        licensePlate
        driverName
      }
      modelInfo {
        useVin
        vin
        modelId
        brand
        variant
        model
        fuelType
        fuelConsumption {
          combined
          city
          highway
        }
        category
        segment
        bodyStyle
        propulsion
        towHitchPossible
        seatsCount
        loadVolume
        loadWeight
        modelDataFromVin
      }
      
      archived
      deletionRequestedAt
      # subscriptionActive
      # lastUpdated
      analysisPeriod {
        to
        from
      }
      vehicleName
      originalLicensePlate
      vehicleModelName
    }
  }
`;

export const GET_VEHICLES_SUMMARY = gql`
  query GetVehiclesSummary($sortBy: String, $sortAscending: Int) {
    vehiclesSummary(sortBy: $sortBy, sortAscending: $sortAscending) {
      vehicleId
      vehicleName
      vehicleModelName
      totalDistance
      countOfTrips
      earliestTripDate
      latestTripDate
      hasHomeCharging
      archived
      tariffName
      qualityDataAmount
      qualityContinuity
      deletionDate
    }
  }
`;

export const GET_UNSUBSCRIBED_VEHICLES = gql`
  query GetUnsubscribedVehicles {
    unsubscribedVehicles {
      vehicleId
      vehicleName
      vehicleModelName
      countOfTrips
      totalDistance
    }
  }
`;

export const GET_RFERENCE_NUMBER = gql`
  query ReferenceNumber {
    setting {
      referenceNumber
    }
  }
`;

export const GET_VEHICLES_SIMPLE = gql`
  query GetVehiclesSimple {
    vehicles {
      id
    }
  }
`;

export const GET_BILLING_INFO = gql`
  query BillingInfo {
    setting {
      userId
    billingInfo {
      name
      company
      email
      taxId
      address {
        street
        streetNr
        zip
        city
        country
      }
    }
    }
  }
`;

export const GET_ADDRESSES = gql`
  query GetAddresses($vehicleId: ID, $locationType: String!) {
    addresses(vehicleId: $vehicleId, locationType: $locationType) {
      id
      name
      locationType
      street
      streetNr
      zip
      city
      country
      power
      energyPrice
      energyCo2
      lat
      lon
    }
  }
`;

export const GET_VEHICLE_VALID_ANALYSIS_PERIOD = gql`
  query GetVehiclesValidAnalysisPeriod($id: ID!) {
    vehicleSummary(id: $id) {
      earliestTripDate
      latestTripDate
    }
  }
`;

export const GET_SETTING = gql`
  query Setting {
    setting {
      userId
    billingInfo {
      name
      company
      email
      taxId
      address {
        street
        streetNr
        zip
        city
        country
      }
    }
    simulation {
      priceAndCo2 {
        petrol {
          price
          co2
        }
        diesel {
          price
          co2
        }
        charging {
          home {
            price
            co2
          }
          public {
            ac {
              price
            }
            dc {
              price
            }
            co2
          }
        }
      }
    }
    language
    referenceNumber
    }
  }
`;

export const GET_BRANDS = gql`
  query getBrands {
    currentVehicleBrands
  }
`;

export const GET_MODELS = gql`
  query getModels($brand: String!) {
    currentVehicleModels(brand: $brand)
  }
`;

export const GET_VARIANTS = gql`
  query getVariants($brand: String!, $model: String!) {
    currentVehicleVariants(brand: $brand, model: $model)
  }
`;

export const GET_MATCHED_VEHICLES = gql`
  query getVehicles($brand: String!, $model: String!, $range: String!) {
    currentVehicles(brand: $brand, model: $model, range: $range)
  }
`;

export const GET_FLEET_RESULTS_VEHICLE_ANALYSIS = gql`
  query GetFleetResultsVehicleAnalysis {
    fleetResultsVehicleAnalysis {
      vehicleId
      vehicleName
      yearlyMileage
      averageDistance {
          perDay
          perTrip
      }
      drivingShare
    businessUsageShare {
        trips
        distance
    }
    longestDistances {
        perTrip
        perDay
    }
    distanceLengthSegments {
        perTrip {
            to9Km
            from10To49Km
            from50To99Km
            from100To299Km
            from300Km
        } 
        perDay {
            to9Km
            from10To49Km
            from50To99Km
            from100To299Km
            from300Km
        }
    }

  
    }
  }
`;

export const GET_FLEET_RESULTS_LOCATION_ANALYSIS = gql`
  query GetFleetResultsLocationAnalysis {
    fleetResultsLocationAnalysis {
      parkingDurationLocationShares {
        vehicleName
        workplace
        home
        public
        noCharging
        unknown
      }
      fleetAnalysisLocations {
          locationId
          locationName
      staysAndDurationSummary {
          stays {
              to1Day
              from1To2Days
              from2To3Days
              from3To4Days
              from4Days
          }
          duration {
              to1Hour
              from1To3Hours
              from3To5Hours
              from5Hours
          }
      }
      dailyParkingSummary {
          date
          trackedVehicles
          vehiclesPerDay
          maxVehiclesSameTime
      }
      averageVehiclesPerDay {
          latestMonth
          latestYear
      }
  }
    }
  }
`;

export const COUNT_VEHICLE_EV_ALTERNATIVES = gql`
  query CountVehicleEvAlternatives(
    $vehicleId: ID!
  ) {
    vehicleEvAlternativesCount(
      vehicleId: $vehicleId
    ) {
      totalCount
      favoritesCount
    }
  }
`;
