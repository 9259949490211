import { Doughnut } from 'react-chartjs-2';
import type { ChartData, ChartOptions } from 'chart.js';

interface DoughnutChartProps {
    data: ChartData<'doughnut'>;
    middleNumber?: number;
}

export const DoughnutChart = ({ data, middleNumber }: DoughnutChartProps) => {
    const dataset = data.datasets[0].data as number[];
    const totalSum = dataset.reduce((totalValue, currentValue) => totalValue + currentValue, 0);

    return (
        <div className="relative h-full w-full">
            {middleNumber !== undefined && middleNumber !== null ?
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-4xl font-bold text-black">
                    {Math.round((middleNumber / totalSum) * 100)}%
                </div> : null}
            <Doughnut
                options={{
                    cutout: '65%',
                    plugins: {
                        tooltip: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return `${Math.round((tooltipItem.raw as number / totalSum) * 100)}%`;
                                },
                            },
                        },
                        legend: {
                            display: false,
                        },
                    },
                } as ChartOptions<'doughnut'>}
                data={data}
            />
        </div>
    );
};