import { useRef, useState } from "react";
import { useUserData } from "../../../hooks/state/useUserData";
import axios from "axios";
import { useTranslation } from "react-i18next";

type CodedFile = File & { code: string };

export function useFilesManager() {
    
    const { refreshUserAccountInfo } = useUserData();
    const { t } = useTranslation("dataProviderUpload");

    const ACCEPTED_EXTENSIONS = [".xls", ".csv", ".xlsx"];

    const inputRef = useRef<any>(null);
    const [dragActive, setDragActive] = useState(false);
    const [files, setFiles] = useState<CodedFile[]>([]);
    const [failedFiles, setFailedFiles] = useState<{ code: string, cause: string }[]>([]);
    const [uploadStatus, setUploadStatus] = useState<{ status: string, message: string } | null>(null);
    const [successfulFiles, setSuccessfulFiles] = useState<any[]>([]);
    const [uploading, setUploading] = useState(false);


    const handleSubmit = async () => {
        try {
            setFailedFiles([]);
            setUploadStatus(null);
            const filesToUpload: CodedFile[] = files.filter(
                (file: any) => !successfulFiles.includes(file.code)
            );
            const documentToSave = new FormData();
            await Promise.all(filesToUpload.map(async (dt: CodedFile) => {
                documentToSave.append('files', dt);
            }));
            setUploading(true);
            const res = await axios.post(
                process.env.REACT_APP_BACKEND_URL + "/upload", documentToSave, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setSuccessfulFiles([...successfulFiles, ...res.data.successFiles]);
            if (res.data.failedFiles.length > 0) {
                setFailedFiles(res.data.failedFiles);
                setFiles((files: any) => {
                    const failedCodes = res.data.failedFiles.map((file: any) => file.fileCode);
                    const successfullCodes = [...res.data.successFiles, setSuccessfulFiles];
                    const failedFiles = Object.values(files).filter((file: any) => failedCodes.includes(file.code));
                    const successfullFiles = Object.values(files).filter((file: any) => successfullCodes.includes(file.code));
                    const newFiles: any = [...failedFiles, ...successfullFiles];

                    return newFiles;
                });
            } else {
                setUploadStatus({ status: 'Success', message: t("manual-upload.all-files-successfull") })
            }
            setUploading(false);
            refreshUserAccountInfo();
            console.log("RES::::", res);
        } catch (error) {
            console.log("ERROR::", error);
        }
    };

    const addFiles = (newFiles: any) => {
        Object.keys(newFiles).map(async (nf: string) => {
            const file = newFiles[nf];
            const buffer = await file.arrayBuffer();
            const fileHash = await crypto.subtle.digest('SHA-256', buffer);
            const hashArray = Array.from(new Uint8Array(fileHash));
            const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
            newFiles[nf].code = hashHex;
        });
        setFiles([...files, ...newFiles]);
    }

    const removeFile = (idx: number) => {
        const updatedFiles = files.filter((f: File, fIdx: number) => idx != fIdx);
        setFiles([...updatedFiles]);
    }

    const filterFilesByCorrectFormat = (newFiles: any) => {
        // TODO all files must be compliant with the accepted formats
        let correctFormat = true;
        Object.keys(newFiles).forEach((k: string) => {
            const nf = newFiles[k];
            const extension = nf.name.split('.')[nf.name.split('.').length - 1];
            if (!ACCEPTED_EXTENSIONS.includes(`.${extension}`)) {
                correctFormat = false;
                return;
            }
        });
        return correctFormat;
    }

    // handle drag events
    const handleDrag = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        if ((e.type === "dragenter" || e.type === "dragover")) {
            setDragActive(true);
        } else if (e.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = function (e: any) {
        e.preventDefault();
        e.stopPropagation();
        setDragActive(false);
        console.log("Received files: ", e.dataTransfer.files)
        if (e.dataTransfer.files && e.dataTransfer.files[0]) {
            if (filterFilesByCorrectFormat(e.dataTransfer.files)) {
                addFiles(e.dataTransfer.files);
            } else {
                console.log("SOme files are not in the correct format");
            }
        }
    };

    // triggers when file is selected with click
    const handleChange = function (e: any) {
        e.preventDefault();
        if (e.target.files) {
            addFiles(e.target.files);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        inputRef.current.click();
    };


    return {
        acceptedExtensions: ACCEPTED_EXTENSIONS.join(','),
        inputRef,
        dragActive,
        files,
        failedFiles,
        uploadStatus,
        successfulFiles,
        uploading,
        handleSubmit,
        removeFile,
        handleDrag,
        handleDrop,
        handleChange,
        onButtonClick
    }

}