import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Welcome } from "./steps/Welcome";
import { DriverDataUpload } from "./steps/DriverDataUpload";
import { ProgressBar } from "./utils/ProgressBar";
import { VehicleModel } from "./steps/VehicleModel";
import { HomeLocations } from "./steps/HomeLocations";
import { WorkplaceLocations } from "./steps/WorkplaceLocations";
import { BudgetPreferences } from "./steps/BudgetPreferences";
import { ChargingPreferences } from "./steps/ChargingPreferences";
import { TemperaturePreferences } from "./steps/TemperaturePreferences";
import { FinalStep } from "./steps/FinalStep";
import { useMutation, useQuery } from "@apollo/client";

import { CancelConfirmation } from "./utils/CancelConfirmation";

import { useNavigate } from "react-router-dom";
import { useUserData } from "../../../../../hooks/state/useUserData";
import { VehicleWithYearlyStats } from "../../../../../@types/vehicle";
import { GET_DRIVER_VEHICLE } from "../../../../../services/graphql/driver/queries";
import { LEAVE_ONBOARDING, UPDATE_LAST_ONBOARDING_STEP } from "../../../../../services/graphql/driver/mutations";
import { AccountInfo } from "../../../../../@types";
import ModalWrapper from "../../../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../../../electrify_frontend_common/components/ModalWrapper/Headers";
import { ElectrifySubProducts } from "../../../../../enums";

export default function OnboardingModal({
  isOpen,
  startingStep,
  onCancel,
  completeButtonText
}: {
  isOpen: boolean;
  startingStep: number;
  onCancel: () => void;
  goToPageProps?: { action: () => void; text: string };
  completeButtonText?: string;
}) {
  const { t, i18n } = useTranslation("driverOnboarding");

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [step, setStep] = useState(startingStep ? startingStep : 0);
  const navigate = useNavigate();

  const { user } = useUserData();
  const { data, refetch } = useQuery<{ driverVehicle: VehicleWithYearlyStats }>(
    GET_DRIVER_VEHICLE
  );

  const [updateStep] = useMutation(UPDATE_LAST_ONBOARDING_STEP);

  useEffect(() => {
    updateStep({
      variables: { step },
    });
  }, [step]);

  const nextStep = () => {
    setStep((current) => current + 1);
  };

  const previousStep = () => {
    setStep((current) => current - 1);
  };

  const [leaveOnboarding] = useMutation(LEAVE_ONBOARDING, {
    onCompleted: (data: { leaveOnboarding: AccountInfo }) => {
      if (data?.leaveOnboarding?.usableProduct) {
        onCancel();
        navigate('/');
        return
      }
      onCancel();
    },
  });

  const steps = useMemo(() => {
    const allSteps = [
      {
        title: "",
        message: "",
        component: (
          <Welcome
            close={() => setConfirmationOpen(true)}
            nextStep={() => nextStep()}
          />
        ),
        onBack: () => null,
      },
      {
        title: t("onboarding-progress"),
        hideForDongleProduct: true,
        component: (
          <DriverDataUpload
            step={step}
            nextStep={() => nextStep()}
            vehicle={data?.driverVehicle}
            refetchVehicle={refetch}
          />
        ),
        onBack: () => previousStep(),
      },
      {
        title: t("onboarding-progress"),
        component: (
          <VehicleModel
            step={step}
            nextStep={() => nextStep()}
            prevStep={() => previousStep()}
            vehicle={data?.driverVehicle}
            refetchVehicle={refetch}
          />
        ),
        onBack: () => previousStep(),
      },
      {
        title: t("onboarding-progress"),
        component: (
          <HomeLocations
            step={step}
            prevStep={() => previousStep()}
            nextStep={() => nextStep()}
            vehicle={data?.driverVehicle}
          />
        ),
      },
      {
        title: t("onboarding-progress"),
        component: (
          <WorkplaceLocations
            step={step}
            prevStep={() => previousStep()}
            nextStep={() => nextStep()}
            vehicle={data?.driverVehicle}
          />
        ),
      },
      {
        title: t("onboarding-progress"),
        component: (
          <BudgetPreferences
            step={step}
            vehicle={data?.driverVehicle}
            prevStep={() => previousStep()}
            nextStep={() => nextStep()}
          />
        ),
      },
      {
        title: t("onboarding-progress"),
        component: (
          <ChargingPreferences
            step={step}
            prevStep={() => previousStep()}
            nextStep={() => nextStep()}
          />
        ),
      },
      {
        title: t("onboarding-progress"),
        component: (
          <TemperaturePreferences
            step={step}
            prevStep={() => previousStep()}
            nextStep={() => nextStep()}
          />
        ),
      },
      {
        title: t("onboarding-progress"),
        component: <FinalStep
          close={onCancel}
          completeButtonText={completeButtonText}
        />,
      },
    ];

    if (user?.accountInfo?.preSelectedProduct === ElectrifySubProducts.DRIVER_DONGLE) {
      return allSteps.filter((s) => !s.hideForDongleProduct);
    }
    return allSteps;
  }, [user?.accountInfo?.preSelectedProduct, data?.driverVehicle?.modelInfo, step, i18n.language]);

  return (
    <>
      <ModalWrapper
        isOpen={isOpen}
        close={() => setConfirmationOpen(true)}
        className={"max-w-fit"}
      >
        <>
          {step == 0 || step == steps.length - 1 ? null : (
            <>
              <ModalHeader
                title={steps[step].title}
                message={steps[step].message}
                // onBack={steps[step].onBack}
                onCancel={() => setConfirmationOpen(true)}
              />
              <ProgressBar steps={steps} step={step} />
            </>
          )}

          <div className="overflow-y-auto overflow-x-hidden h-full">
            <CancelConfirmation
              isOpen={confirmationOpen}
              setIsOpen={setConfirmationOpen}
              closeOnboarding={leaveOnboarding}
            />

            {steps[step].component}
          </div>
        </>
      </ModalWrapper>
    </>
  );
}
