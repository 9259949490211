import { useContext } from "react";
import ChartButtonHeader from "../../../../../../common/components/Charts/ChartButtonHeader";
import { alternativesSortMap, FreeEvFinderContext } from "../FreeEvFinderContext";
import { useTranslation } from "react-i18next";
import { FreeEvFinderFavoriteVehicleCard } from "./FreeEvFinderFavoriteVehicleCard";
import { OpenEvFinderEvDetails } from "../EvDetailsModal";
import { AlternativeVehicle } from "../../../../../../electrify_frontend_common/types";
import { SelectionSorter } from "../../../../../../electrify_frontend_common/components/EvFinder/Versions[A-B]/components";



export function FavoriteVehiclesList() {

    const {
        evDetailsToCheck,
        setEvDetailsToCheck,
        totalVehiclesCount,
        sortBy,
        updateSortBy,
        favoriteVehiclesList,
        favoriteVehiclesCount,
        favoritesOnly,
        setFavoritesOnly,
    } = useContext(FreeEvFinderContext);

    const { t } = useTranslation("evFinder");


    return (
        <>
            {evDetailsToCheck ?
                <OpenEvFinderEvDetails evDetailsToCheck={evDetailsToCheck} closeModal={() => setEvDetailsToCheck(null)} />
                : null}

            <div className="flex flex-col w-full flex-1 p-6">
                <div className="md:flex  mb-9 md:mb-2 md:items-center justify-between">
                    <ChartButtonHeader
                        button1={{
                            onClick: () => {
                                setFavoritesOnly(false);
                            },
                            label: `${t("headerButtons.allVehicles")}`,
                            vehiclesCount: totalVehiclesCount,
                        }}
                        button2={{
                            onClick: () => {
                                setFavoritesOnly(true);
                            },
                            label: `${t("headerButtons.favoritesOnly")}`,
                            vehiclesCount: favoriteVehiclesCount,
                        }}
                        checkProp={!favoritesOnly}
                    />
                    <div className="ml-4">
                        <SelectionSorter
                            sortBy={sortBy}
                            handleOnChangeSorter={updateSortBy}
                            optionsMap={alternativesSortMap}
                        />
                    </div>

                </div>
                <div className="flex grow overflow-auto w-full py-2">
                    {favoriteVehiclesList?.map((evs: Partial<AlternativeVehicle>) => (
                        <div key={evs.evId} className="mr-4">
                            <FreeEvFinderFavoriteVehicleCard
                                vehicle={evs}
                                onClick={() => setEvDetailsToCheck(evs.evId || null)}
                            />
                        </div>
                    ))}
                </div>
            </div>
        </>

    )


}