import { useTranslation } from "react-i18next";
import { ReactComponent as VehicleIcon } from "../../../../../../../assets/vehicle-icon-black.svg";
import { useLayout } from "../../../../../../../hooks/state/useLayout";
import { VehicleConsumptionsCostsAndEmissions } from "../../../../../../../@types/driver/simulations";

export function Consumptions({
  consumptionCostsAndEmissions,
}: {
  consumptionCostsAndEmissions: VehicleConsumptionsCostsAndEmissions;
}) {
  const { currencySymbol, localeCode } = useLayout();

  const { t } = useTranslation("scenarios");

  return (
    <div className="flex border border-Grey-default rounded w-full mt-8 items-center p-6">
      <div className="flex items-center mr-80">
        <VehicleIcon className="mr-2" />
        <div>{t("consumptions.current-vehicle")}</div>
      </div>
      <div className="flex items-center">
        <div className="text-Grey-dark flex flex-col items-center mr-32">
          <div className=" font-bold text-xs">{t("consumptions.costs")}</div>
          <div>
            <span className="font-black text-lg mr-1">
              {consumptionCostsAndEmissions?.yearlyFuelAndEnergyCosts?.toLocaleString(
                localeCode
              )}
            </span>
            <span>{currencySymbol}</span>
          </div>
        </div>
        <div className="text-Grey-dark flex flex-col items-center">
          <div className="font-bold text-xs">
            {t("consumptions.co2-emissions")}
          </div>
          <div>
            <span className="font-black text-lg mr-1">
              {consumptionCostsAndEmissions?.yearlyCo2Emission?.toLocaleString(
                localeCode
              )}
            </span>
            <span>kg</span>
          </div>
        </div>
      </div>
    </div>
  );
}
