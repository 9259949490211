import { useTranslation } from "react-i18next";
import { useLayout } from "../../../../../hooks/state/useLayout";
import { MakeDropdown } from "../../../../../electrify_frontend_common/components/EvFinder/Versions[A-B]/Filters/components/MakeDropdown";
import { OptionsButton } from "../../../../../electrify_frontend_common/components/EvFinder/FilterComponents/OptionsButton";
import { FilterSection } from "../../../../../electrify_frontend_common/components/EvFinder/FilterComponents/FilterSection";
import { useFilterValues } from "../../../../../electrify_frontend_common/components/EvFinder/FilterComponents/useFilterValues";
import { FilterSlider } from "../../../../../electrify_frontend_common/components/EvFinder/FilterComponents/FilterSlider";
import { CheckboxSection } from "./CheckboxSection";

const { Select, MenuItem, Checkbox, OutlinedInput } = require("@mui/material");

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    autoFocus: false,
};

const DropDownFilter = ({
    availableOptions,
    value,
    title,
    handleOnChangeDropdown,
}: {
    availableOptions: any[],
    value: any,
    title: string,
    handleOnChangeDropdown: (e: any) => void
}) => {


    return (
        <>
            <Select
                multiple
                value={value}
                onChange={handleOnChangeDropdown}
                renderValue={(selected: string[]) =>
                    selected.length > 0 && !selected.includes("All")
                        ? selected.join(", ")
                        : `All ${title}`
                }
                input={<OutlinedInput sx={{ fontSize: "0.75rem" }} />}
                displayEmpty={true}
                MenuProps={MenuProps}
                className="w-full text-xs mb-4"
                label="Select brand"
                defaultChecked={value}
            >
                <MenuItem sx={{ fontSize: "0.75rem" }} key={"All"} value={"All"}>
                    <Checkbox
                        size="small"
                        checked={
                            value.includes("All") &&
                            (value.length === 1 ||
                                value.length === availableOptions.length + 1)
                        }
                    />
                    {"All"}
                </MenuItem>
                {availableOptions?.map((name) => (
                    <MenuItem sx={{ fontSize: "0.75rem" }} key={name} value={name}>
                        <Checkbox
                            size="small"
                            checked={
                                value.includes("All")
                                    ? value.includes(name)
                                        ? false
                                        : true
                                    : value.includes(name)
                            }
                        />
                        {name}
                    </MenuItem>
                ))}
            </Select>
        </>
    );
};


export function Filters({
    filtersHandler,
    resetFilters,
}: {
    filtersHandler: any,
    resetFilters: () => void,
}) {

    const { t } = useTranslation("evFinder");
    const { currencySymbol } = useLayout();

    const {
        priceSliderProperties,
        driveTrainOptions,
        availabilityOptions,
        topSpeedOptions,
        realRangeOptions,
        realConsumptionOptions,
        loadVolumeSliderProperties,
        loadWeightSliderProperties,
        seatsOptions,
    } = useFilterValues();



    const handleFilterChange = ({ key, newValue }: { key: string, newValue: any }) => {
        if (Array.isArray(newValue)) {
            filtersHandler.updateActiveFilters(key, {
                min: newValue[0],
                max: newValue[1],
            });
        } else {
            filtersHandler.updateActiveFilters(key, newValue);
        }
    };



    return (
        <>
            <h4 className="text-Grey-dark font-bold	text-sm mb-8">
                {t("filters.title")}
            </h4>
            <FilterSection title={t("filters.make.label")}>
                <MakeDropdown
                    backgroundColor={"light"}
                    value={filtersHandler?.readFilterValue("brands") || []}
                    updateValue={(value: string[] | null) => {
                        filtersHandler?.updateActiveFilters("brands", value);
                    }}
                />
            </FilterSection>

            <FilterSection title={t("filters.availability.label")}>
                <OptionsButton
                    value={filtersHandler.readFilterValue("availabilityStatus")}
                    multipleOptions={true}
                    onChange={(value: any) => {
                        filtersHandler.updateActiveFilters("availabilityStatus", value);
                    }}
                    options={availabilityOptions}
                    backgroundColor={"light"}
                />
            </FilterSection>

            <FilterSection title={t("filters.listPrice.label")}>
                <FilterSlider
                    onChange={(newValue: number | number[]) => { handleFilterChange({ key: "listPrice", newValue }) }}
                    value={[
                        filtersHandler?.readFilterValue("listPrice")?.min || 0,
                        filtersHandler?.readFilterValue("listPrice")?.max || null,
                    ]}
                    min={priceSliderProperties.min}
                    max={priceSliderProperties.max}
                    step={priceSliderProperties.step}
                    symbol={currencySymbol}
                />
            </FilterSection>


            <FilterSection title={t("filters.loadVolume.label")}>
                <FilterSlider
                    onChange={(newValue: number | number[]) => {
                        handleFilterChange({ key: "loadVolume", newValue })
                    }}
                    value={[
                        filtersHandler?.readFilterValue("loadVolume")?.min || null,
                        filtersHandler?.readFilterValue("loadVolume")?.max || null,
                    ]}
                    min={loadVolumeSliderProperties.min}
                    max={loadVolumeSliderProperties.max}

                    step={loadVolumeSliderProperties.step}
                    symbol={'l'}
                />
            </FilterSection>

            <FilterSection title={t("filters.loadWeight.label")}>
                <FilterSlider
                    onChange={(newValue: number | number[]) => { handleFilterChange({ key: "loadWeight", newValue }) }}
                    value={[
                        filtersHandler?.readFilterValue("loadWeight")?.min || null,
                        filtersHandler?.readFilterValue("loadWeight")?.max || null,
                    ]}
                    min={loadWeightSliderProperties.min}
                    max={loadWeightSliderProperties.max}
                    step={loadWeightSliderProperties.step}
                    symbol={'kg'}
                />
            </FilterSection>


            <FilterSection title={t("filters.topSpeed.label")}>
                <OptionsButton
                    backgroundColor="light"
                    options={topSpeedOptions}
                    value={filtersHandler?.readFilterValue("topSpeed")}
                    onChange={(newValue: 140 | 170 | 200 | 250 | null) => {
                        handleFilterChange({ key: "topSpeed", newValue })
                    }}
                />
            </FilterSection>

            <FilterSection title={t("filters.realRange.label")}>
                <OptionsButton
                    backgroundColor="light"
                    options={realRangeOptions}
                    value={filtersHandler?.readFilterValue("realRange")}
                    onChange={(newValue: 250 | 300 | 400 | 500 | null) => {
                        handleFilterChange({ key: "realRange", newValue })
                    }}
                />
            </FilterSection>

            <FilterSection title={t("filters.realConsumption.label")}>
                <OptionsButton
                    backgroundColor="light"
                    options={realConsumptionOptions}
                    value={filtersHandler?.readFilterValue("realConsumption")}
                    onChange={(newValue: 16 | 18 | 20 | 22 | null) => {
                        handleFilterChange({ key: "realConsumption", newValue })
                    }}
                />
            </FilterSection>

            <FilterSection title={t("filters.seats.label")}>
                <OptionsButton
                    backgroundColor="light"
                    options={seatsOptions}
                    value={filtersHandler?.readFilterValue("seats")}
                    onChange={(newValue: 2 | 4 | 5 | 7 | null) => {
                        handleFilterChange({ key: "seats", newValue })
                    }}
                />
            </FilterSection>

            <FilterSection title={t("filters.drivetrainPropulsion.label")}>
                <OptionsButton
                    backgroundColor="light"
                    options={driveTrainOptions}
                    value={filtersHandler?.readFilterValue("propulsion")}
                    onChange={(newValue: "FWD" | "RWD" | "4x4" | null) => {
                        handleFilterChange({ key: "propulsion", newValue })
                    }}
                />
            </FilterSection>

            <FilterSection title={t("filters.vehicleFeatures.label")}>
                <CheckboxSection filtersHandler={filtersHandler} handleFilterChange={handleFilterChange} />
            </FilterSection>

            <div className="flex w-full justify-center m-2 mt-5">
                <button
                    className={
                        "bg-white text-Blueberry-dark-default hover:bg-Blueberry-light-background w-full rounded py-1"
                    }
                    onClick={() => {
                        resetFilters();
                    }}
                >
                    {t("filters.resetButton")}
                </button>
            </div>
        </>
    );
}
