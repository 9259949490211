import { ReactComponent as WeekdaysIcon } from "../../../../../../../../assets/weekdays.svg";
import { ReactComponent as WeekendsIcon } from "../../../../../../../../assets/weekends.svg";
import { ReactComponent as AlldaysIcon } from "../../../../../../../../assets/all_days.svg";
import { useTranslation } from "react-i18next";

const AverageInfo = ({
  Icon,
  text,
  value,
}: {
  Icon: any;
  text: string;
  value: any;
}) => {
  return (
    <div className="flex py-3">
      <Icon className="mr-4" />
      <div>
        <div className="text-xs font-bold text-Grey-shade">{text}</div>
        <div className="text-Blueberry-dark-default">
          <span style={{ fontWeight: 900 }} className="text-2xl mr-2">
            {value}
          </span>
          km
        </div>
      </div>
    </div>
  );
};

export function DistanceAverageChart({ data }: { data?: any }) {
  const { t } = useTranslation("driverDrivingAnalysis");
  return (
    <div className="h-60 pl-2">
      <AverageInfo
        Icon={WeekendsIcon}
        value={data.weekends}
        text={t("distance-average.weekends")}
      />
      <AverageInfo
        Icon={WeekdaysIcon}
        value={data.weekdays}
        text={t("distance-average.weekdays")}
      />
      <AverageInfo
        Icon={AlldaysIcon}
        value={data.all}
        text={t("distance-average.all-days")}
      />
    </div>
  );
}
