


export function SimulationIcon({color = "black"} : {color?: string}) {

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_666_1660)">
                <path d="M8.875 11.3877C9.56536 11.3877 10.125 10.8281 10.125 10.1377C10.125 9.44734 9.56536 8.8877 8.875 8.8877C8.18464 8.8877 7.625 9.44734 7.625 10.1377C7.625 10.8281 8.18464 11.3877 8.875 11.3877Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.87583 8.88745V5.17578" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M8.87583 12.6377V11.3877" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.125 8.8877C15.8154 8.8877 16.375 8.32805 16.375 7.6377C16.375 6.94734 15.8154 6.3877 15.125 6.3877C14.4346 6.3877 13.875 6.94734 13.875 7.6377C13.875 8.32805 14.4346 8.8877 15.125 8.8877Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.1258 8.8877V12.6394" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M15.1258 5.1377V6.38936" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M13.25 17.625C13.3212 18.9648 13.753 20.2605 14.5 21.375H9.5C10.247 20.2605 10.6788 18.9648 10.75 17.625" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M7.62583 21.375H16.3758" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M2.62583 15.125H21.3758" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M20.1258 2.625H3.87583C3.18548 2.625 2.62583 3.18464 2.62583 3.875V16.375C2.62583 17.0654 3.18548 17.625 3.87583 17.625H20.1258C20.8162 17.625 21.3758 17.0654 21.3758 16.375V3.875C21.3758 3.18464 20.8162 2.625 20.1258 2.625Z" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_666_1660">
                    <rect width="20" height="20" fill="white" transform="translate(2 2)" />
                </clipPath>
            </defs>
        </svg>

    )

}