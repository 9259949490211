import { gql } from "@apollo/client";

export const FINISH_ONBOARDING = gql`
  mutation OnboardUser {
    onboardUser {
      id
    }
  }
`

export const LEAVE_ONBOARDING = gql`
  mutation LeaveOnboarding {
    leaveOnboarding {
      onboarded
      usableProduct
      dongleRoadMapCompleted
      lastOnboardingStep
      languageCode
      countryCode
      preSelectedProduct
      billingInfo {
        name
        address {
          street
          streetNr
          country
          city
          zip
        }
      }
      shippingInfo {
        name
        address {
          street
          streetNr
          country
          city
          zip
        }
      }
    }
  }
`


export const UPDATE_LAST_ONBOARDING_STEP = gql`
  mutation UpdateOnboardingStep($step: Int!) {
    updateOnboardingStep(step: $step) {
      id
    }
  }
`

export const SELECT_SUB_PRODUCT = gql`
  mutation SelectSubProduct($product: String) {
    selectSubProduct(preSelectedProduct: $product) {
      id
    }
  }
`

export const CREATE_OR_UPDATE_VEHICLE = gql`
  mutation CreateOrUpdateVehicle($id: ID, $vehicle: VehicleInput!) {
    createOrUpdateVehicle(id: $id, vehicle: $vehicle) {
      id
      name
      originalInfo {
        name
      }
      modelInfo {
        useVin
        vin
        brand
        model
        variant
        modelId
      }
      
    }
  }
`

export const UPDATE_VEHICLE_SIMULATION_PREFERENCES = gql`
  mutation UpdateVehicleSimulationPreferences($vehicleSimulationPreferences: VehicleSimulationPreferenceInput!) {
    updateVehicleSimulationPreferences(vehicleSimulationPreferences: $vehicleSimulationPreferences) {
      setTemperature
      socBuffer
      setLoadWeight
    }
}`


export const UPDATE_TRIP_SIMULATION_PREFERENCES = gql`
  mutation UpdateTripSimulationPreferences(
    $preference: TripSimulationPreferencesInput!
  ) {
    updateTripSimulationPreferences(preference: $preference) {
        includeExtraStops
        optimizationMethod
    }
  }
`;


export const UPDATE_VEHICLE_MAX_PRICE = gql`
  mutation UpdateVehicleMaxPrice(
    $vehicleSettings: VehicleSettingsInput!
  ) {
    updateVehicleSettings(vehicleSettings: $vehicleSettings) {
      simulation {
        filters {
          latest {
            listPrice {
              max
            }
          }
        }
      }
    }
  }`