import { useTranslation } from "react-i18next";
import { OfferToDisplay } from "../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/OfferBanner";
import { AlternativeVehicle } from "../../../../../../../../electrify_frontend_common/types";
import { BrandInfo } from "../../../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/BrandInfo";
import { ArrowButton } from "../../../../../../../../electrify_frontend_common/components/ArrowButton";
import { VehicleImageWithFallback } from "../../../../../../../../electrify_frontend_common/components/VehicleImageWithFallback";


function CustomOfferBanner({ offerToDisplay }: { offerToDisplay?: OfferToDisplay }) {

    const { t } = useTranslation("evFinder");

    const offerToDisplayExists = !!(offerToDisplay?.price && offerToDisplay?.url);

    return (
        <div className={`h-[34px] max-h-[34px] overflow-hidden flex w-full bg-Blueberry-light-shade text-white
                         cursor-pointer items-center justify-center text-sm absolute bottom-0 left-0 first-letter font-light
                         ${offerToDisplayExists ? 'bg-Blueberry-light-shade   cursor-pointer underline' :
                'bg-Grey-default hover:cursor-not-allowed'}`}
            onClick={(e) => {
                e.stopPropagation();
                if (!offerToDisplayExists) return;
                window.open(offerToDisplay?.url || "", "_blank");
            }}
        >
            {
                offerToDisplayExists ? t("vehicleCard.banner.leasingFrom", {
                    price: offerToDisplay.price
                }) : t("vehicleCard.banner.noOffer")
            }
        </ div>
    )

}

export function SelectedVehicleThumbnail({
    vehicle,
    onMoreInfoClick
}: {
    vehicle: Partial<AlternativeVehicle>,
    onMoreInfoClick: () => void
}) {

    const { t } = useTranslation("evFinder");

    return (
        <div className={` bg-white border border-Grey-tint rounded overflow-hidden relative w-full h-52`}>

            <div className="p-3 max-h-10">
                <BrandInfo
                    vehicleBrand={vehicle?.make}
                    vehicleModel={vehicle?.model}
                />
            </div>

            <div >
                <div className="flex flex-col flex-1 justify-center bg-white items-center h-full w-full">
                    <div className={`pt-2 flex justify-center`}>
                        <VehicleImageWithFallback
                            className="max-h-24"
                            vehicle={vehicle}
                        />
                    </div>
                </div>
            </div>
            <div className="flex w-full px-3 items-center justify-end">
                <ArrowButton
                    text={t("vehicleCard.moreInfo")}
                    onClick={(e) => {
                        e.stopPropagation();
                        onMoreInfoClick();
                    }}
                />
            </div>
            <CustomOfferBanner offerToDisplay={vehicle.offerToDisplay} />

        </div>
    )



}