import { Link } from "react-router-dom";
import { useColors } from "./NavbarItem";
import { useState } from "react";



export function NavbarSubItem({
    to,
    disabled,
    title,
    activeCondition,
}: {
    to: string,
    disabled: boolean,
    title: string,
    activeCondition: boolean,
}) {

    const [hovered, setHovered] = useState(false);


    const { contentStyle, backgroundColor } = useColors({
        disabled,
        hovered,
        activeCondition,
        hasSubMenus: false
    })

    return (
        <>
            <Link to={to} className={`${disabled ? 'pointer-events-none' : ''}`}>
                <div
                    style={contentStyle}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    className={`flex flex-row flex-1 h-full ml-6 p-2 pl-4 items-center rounded-md ${backgroundColor}`}>

                    {title}
                </div>

            </Link>
        </>
    )



}