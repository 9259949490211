
import { useTranslation } from "react-i18next";
import { ReactComponent as SupportIcon } from "../../../../assets/support-icon.svg";

export default function SupportButton ({ className } : {className : string}) {

  const {t} = useTranslation("common");

  return(
    <a
      className={`border max-h-12 border-Grey-tint ${className}`}
      href="mailto:support@chargylize.com?subject=Electrify Support"
    >
      <button className="h-full w-full text-base content-center text-Black-default">
        <div className="flex w-full justify-center items-center">
          <div className="mr-3">
            <SupportIcon/>
          </div>
          <div className="whitespace-nowrap">
            {t("get-support")}
          </div>
        </div>
      </button>
    </a>
  )
}