
export const DegreesToRadians = (degrees: number) => degrees * (Math.PI / 180);


export function Arrow({ maxScore, radius, centroid }: { maxScore: number, radius: number, centroid: { x: number, y: number } }) {

    const width = 20;
    const height = 20;
    const arrowPoints = [
        { x: centroid.x - width / 2, y: centroid.y },
        { x: centroid.x + width / 2, y: centroid.y },
        { x: centroid.x, y: centroid.y - height },
    ];

    const angle = -(2.2 * maxScore) + 200;


    return (
        <polygon
            points={`${arrowPoints[0].x},${arrowPoints[0].y} ${arrowPoints[1].x},${arrowPoints[1].y} ${arrowPoints[2].x},${arrowPoints[2].y}`}
            fill="#2C2C31"

            transform={`translate(${radius * Math.cos(DegreesToRadians(angle))},${-(Math.sin(DegreesToRadians(angle)) * radius)})
             rotate(${-angle + 90} ${centroid.x} ${centroid.y})`}
        />
    )

}

export function BackgroundLines({ radius, centroid }: { radius: number, centroid: { x: number, y: number }, percentage: number }) {

    const degreesToMark = [{ label: "0", value: 200 }, { label: "50", value: 90 }, { label: "75", value: 35 }, { value: 2, label: "90" }, { label: "100", value: -15 }];
    const labelsRadius = radius + 10;

    const getRadius = (value: string) => {
        if (parseInt(value) >= 100) {
            return labelsRadius + 5;
        } else if (parseInt(value) === 50) {
            return labelsRadius - 5;
        }
        return labelsRadius;
    }

    return (

        <g>
            {degreesToMark.map((dm: { label: string, value: number }, idx: number) => <g
                key={idx}>
                <path
                    key={idx}
                    d={`M${centroid.x},${centroid.y} L${centroid.x + (radius * Math.cos(DegreesToRadians(dm.value)))},${centroid.y - (Math.sin(DegreesToRadians(dm.value)) * radius)}`}
                    stroke={"#F7F8FA"}
                    strokeWidth={3}
                />
                <text textAnchor="middle" fontWeight="bold" fontSize="9px">
                    <tspan fill={"#595E69"} fontWeight="bold"
                        x={centroid.x + (getRadius(dm.label) * Math.cos(DegreesToRadians(dm.value - 2)))}
                        y={centroid.y - (Math.sin(DegreesToRadians(dm.value - 3)) * getRadius(dm.label))}>{dm.label}%
                    </tspan>
                </text>
            </g>)}
        </g>

    )
}

export function InnerLine({ radius, centroid }: { radius: number, centroid: { x: number, y: number } }) {

    return (
        <path
            d={`M${centroid.x},${centroid.y} M${centroid.x - (radius * Math.cos(DegreesToRadians(20)))},${centroid.y + (Math.sin(DegreesToRadians(20)) * radius)} 
        A${radius},${radius} 0 1 1 
        ${centroid.x + radius * Math.cos(DegreesToRadians(20))},${centroid.y + (Math.sin(DegreesToRadians(20)) * radius)} M${centroid.x},${centroid.y}
        `}
            fill={"none"}
            stroke={"#a4abbc"}
            strokeWidth={1}
        />
    )
}


export function EvScoreGauge({ radius, centroid }: { radius: number, centroid: { x: number, y: number } }) {

    const slices = [
        { angles: [20, -90], color: "#78194F" },
        { angles: [-90, -35], color: "#961F63" },
        { angles: [-145, -2], color: "#B88198" },
        { angles: [-178, 15], color: "#82BA3A" },
        { angles: [-195, 20], color: "#4ca861" },
    ]

    return (
        <g>
            {slices.map((s, idx: number) => (
                <path
                    key={idx}
                    d={`M${centroid.x},${centroid.y} M${centroid.x - (radius * Math.cos(DegreesToRadians(s.angles[0])))},${centroid.y + (Math.sin(DegreesToRadians(s.angles[0])) * radius)} 
                A${radius},${radius} 0 0 1 
                ${centroid.x + radius * Math.cos(DegreesToRadians(s.angles[1]))},${centroid.y + (Math.sin(DegreesToRadians(s.angles[1])) * radius)} M${centroid.x},${centroid.y}
                `}
                    fill={"none"}
                    stroke={s.color}
                    strokeWidth={16}
                />
            ))}
        </g>
    )
}