import {
  ApolloClient, createHttpLink,
  ApolloLink, InMemoryCache
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { persistCache } from 'apollo3-cache-persist';
import { ApolloProvider } from '@apollo/client';
import { ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { GraphQLErrors } from "@apollo/client/errors";
import { AuthContext } from "../../contexts/AuthContext";
import { Spinner } from "../../electrify_frontend_common/components/Spinner";


const takeActionOnResponse = (graphQLErrors: GraphQLErrors | undefined) => {

  if (graphQLErrors) {
    for (let err of graphQLErrors) {
      console.log("Error: ", err.extensions.exception);
      switch ((err.extensions.exception as any)?.statusCode) {
        case 403: {
          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
          break
        }
        case 401: {
          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
          break
        }
        default: {
          break
        }
      }
      switch (err.extensions.code) {
        case 'UNAUTHENTICATED': {
          localStorage.removeItem('accessToken')
          localStorage.removeItem('refreshToken')
         
          break
        }
        default: {
          break
        }
      }
    }
  }


}


export const GraphQLClientProvider = ({ children }: {  children: ReactElement }) => {

  const { setUser } = useContext(AuthContext);
  
  const [electrifyApolloClient, setElectrifyApolloClient] = useState<any>();

  const setupClient = useCallback(async () => {

    //CACHE
    const cache = new InMemoryCache();

    await persistCache({
      cache,
      storage: window.sessionStorage,
    })

    //LINKS
    const link = createHttpLink({
      uri: process.env.REACT_APP_BACKEND_URL + '/graphql',
    })

    const authLink = setContext((_, { headers }) => {
      const accessToken = localStorage.getItem('accessToken')
      return {
        headers: {
          ...(accessToken && {
            authorization: 'Bearer ' + (accessToken ? `${accessToken}` : ''),
          })
        },
      }
    })

    const errorLink = onError(
      ({ graphQLErrors, networkError, operation, forward }) => {
        
        // takeActionOnResponse(graphQLErrors);
        const unauthorizedResponsed = graphQLErrors?.filter((e) => e.message === "Unauthorized");
        const unauthorized : boolean = !!(unauthorizedResponsed?.length && (unauthorizedResponsed.length > 0));
        if(unauthorized) {
          console.log("=======> Unauthorized");
          localStorage.removeItem('accessToken');
          setUser(null);
          return;
        }
                
        if (networkError) {
          console.log(`[Network error]: ${networkError}`)
        }
      }
    )

    //CLIENT
    const client = new ApolloClient({
      cache: cache,
      link: ApolloLink.from([errorLink, authLink, link]),
      queryDeduplication: false,
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'cache-and-network',
        },
        query: {
          fetchPolicy: 'cache-first',
        },
      },
    })

    return client
  }, [])


  useEffect(() => {
    async function setUpProvider() {
      setElectrifyApolloClient(await setupClient())
    }
    setUpProvider()
    // eslint-disable-next-line
  }, [])

  if (!electrifyApolloClient) return <Spinner />

  return (
    <ApolloProvider client={electrifyApolloClient}>{children}</ApolloProvider>
  )


}