import { useState } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import ChartButtonHeader from "../../../../../../../../common/components/Charts/ChartButtonHeader";
import { NoWorkplaceDataAvailable } from "../NoWorkplaceDataPlaceholder"



export function WorkplaceParkingOccupationMaxVehiclesChart({ data, isModal }: {
    data: any;
    isModal?: boolean;
}) {
    const { t } = useTranslation("parkingAnalysis");

    const [isMonth, setIsMonth] = useState(true);
    const [location, setLocation] = useState(0);

    const { labelsMonth, labelsYear, dataMonth, dataYear } = JSON.parse(JSON.stringify(data));

    const selectedDataSet = isMonth ? dataMonth : dataYear;

    if (!Array.isArray(selectedDataSet) || selectedDataSet.length === 0) return <NoWorkplaceDataAvailable noDataText="No Locations found" />

    const selectedLocationData = selectedDataSet.map((s: any) => s.data)?.[location]
    const locationHasData = selectedLocationData[2].data.length > 0;

    return (
        <div className="flex flex-col h-full">
            <div className="flex justify-between h-10 mb-6">
                <ChartButtonHeader
                    button1={{
                        onClick: () => setIsMonth(true),
                        label: t("workplace-latest-month-button"),
                    }}
                    button2={{
                        onClick: () => setIsMonth(false),
                        label: t("workplace-latest-year-button"),
                    }}
                    checkProp={isMonth}
                />
                <select
                    className="h-10 text-sm rounded"
                    onChange={(e: any) => setLocation(e.target.value)}
                >
                    {selectedDataSet?.map((location: any, i: number) => <option key={i} value={i}>{location?.locationName}</option>)}
                </select>
            </div>
            <span className="mb-6 text-sm">
                {`${t("workplace-vehicles-count-max-vehicles-description")} ${selectedDataSet?.[location]?.locationName}`}
            </span>
            <div
                className="flex-grow flex items-center justify-center"
                style={{ minHeight: isModal ? "500px" : "370px" }}
            >{locationHasData ?
                <Line
                    options={{
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function (tooltipItems) {
                                        return String(tooltipItems.parsed.y);
                                    },
                                },
                            },
                        },
                        elements: {
                            point: { radius: 0 },
                        },
                        scales: {
                            x: {
                                grid: { display: false },
                                ticks: {
                                    maxTicksLimit: 33,
                                    padding: 10,
                                },
                            },
                            y: {
                                border: { display: false },
                                ticks: { precision: 0 },
                            },
                        },
                    }}
                    data={{
                        labels: isMonth ? labelsMonth : labelsYear,
                        datasets: selectedLocationData,
                    }}
                /> : <NoWorkplaceDataAvailable noDataText="No data found for location" />
                }
            </div>
        </div>
    );
}
