export interface AlternativeVehicle {
  _id: string;
  evId: string;
  make: string;
  model: string;
  availabilityStatus: string;
  category: string;
  segment: string;
  bodyStyle: string;
  drivetrainType: string;
  seatsCount: number;
  electrificationFactor: number;
  yearlyFuelAndEnergyCosts: number;
  yearlyCo2Emission: number;
  listPrice: number;
  isSelected: boolean;
  isFavorited: boolean;
  notInPreference: boolean;
  hasHighestScore: boolean;
  hasHighestElectrificationFactor: boolean;
  hasLowestFuelAndEnergyCosts: boolean;
  notRecommended: boolean;
  energyConsumption: number;
  realRangeAverage: number;
  testedRealConsumption100km: number;
  loadWeight: number;
  loadVolume: number;
  propulsion: number;
  towHitchPossible: boolean;
  totalTorque: number;
  acChargeSpeed: number;
  acChargePower: number;
  dcChargeSpeed: number, 
  dcMaximumChargePower: number,
  hasIsoFix: boolean,
  isoFixSeatsCount: number,
  supportsHpc: boolean,
  supportsPnc: boolean,
  supportsV2g: boolean,
  offerToDisplay: { price?: number | null; url?: string | null };
}


export type AlternativeEletricVehicleDetails = {
  vehicleId: string;
  evId: string;
  isFavorited?: boolean;
  category: string;
  bodyStyle: string;
  yearlyEnergyConsumption: number;
  yearlyFuelAndEnergyCosts: number;
  model: string;
  make: string;
  listPrice: number;
  electrificationFactor: number;
  offerToDisplay: {
    url?: string | null;
    price?: number | null;
  };
  evModelData: {
    coreData: {
      availabilityStatus: string;
      acChargePower: number;
      dcMaximumChargePower: number;
    };
    technicalData: {
      acceleration: number;
      topSpeed: number;
      totalPowerKw: number;
      totalPowerHp: number;
      totalTorque: number;
      propulsion: string;
      testedRealConsumption100km: number;
    };
    range: {
      realRangeAverage: number;
      realRangeHighwayWorst: number;
      realRangeCityWorst: number;
      realRangeCombinedWorst: number;
      realRangeHighwayBest: number;
      realRangeCityBest: number;
      realRangeCombinedBest: number;
      wltpRangeWorst: number;
      wltpRangeBest: number;
    };
    battery: {
      nominalBatteryCapacity: number;
      useableBatteryCapacity: number;
      batteryType: string;
    };
    charging: {
      acPlugType: string;
      acPlugLocation: string;
      acChargePower: number;
      acChargeTime: number;
      acChargeSpeed: number;
      dcPlugType: string;
      dcPlugLocation: string;
      dcMaximumChargePower: number;
      dcChargeTime: number;
      dcChargeSpeed: number;
    };
    dimensionsAndWeight: {
      length: number;
      width: number;
      widthWithMirrors: number;
      height: number;
      wheelbase: number;
      weightUnladen: number;
      grossVehicleWeight: number;
      loadWeight: number;
      loadVolume: number;
      loadVolumeMax: number;
      loadVolumeFrunk: number;
    };
    miscellaneous: {
      towHitchPossible: boolean;
      seatsCount: number;
      hasIsoFix: boolean;
      isoFixSeatsCount: number;
      hasRoofRails: boolean;
      turningCircle: number;
    };
  };
};




export type IFilters = {
  brands: string[] | null;
  availabilityStatus: ("PAST" | "CURRENT" | "FUTURE")[] | null;
  listPrice: { min: number | null; max: number | null };
  topSpeed: 140 | 170 | 200 | 250 | null;
  realRange: 250 | 300 | 400 | 500 | null;
  realConsumption: 16 | 18 | 20 | 22 | null;
  loadWeight: 400 | 500 | 600 | 700 | null;
  loadVolume: 350 | 400 | 500 | 700 | null;
  seats: 2 | 4 | 5 | 7 | null;
  "4x4": boolean | null;
  fastCharging: boolean | null;
  towing: boolean | null;
  plugAndCharge: boolean | null;
  vehicleToGrid: boolean | null;
  isofixAvailable: boolean | null;
  roofRails: boolean | null;
};

export type FiltersOptions = {
  label: string;
  value: number | string | boolean;
};

export enum DriveTrainPropulsion {
  FWD = "FWD",
  RWD = "RWD",
  '4x4' = "4x4"
}