import { useTranslation } from "react-i18next";
import CarIconSelector from "../../CarIconSelector";
import { SimulationVehicleInfo } from "../../../../@types";

const NoEvSelectedCard = ({ onClickAlternatives, vehicle } : {onClickAlternatives: ()=> void, vehicle: SimulationVehicleInfo}) => {
  const { t } = useTranslation("fleetSimulation");
  const vehicleInfo = vehicle?.vehicleInfo;

  return (
    <div
      key={"asdf"}
      className="grid grid-cols-2 border border-Grey-tint rounded h-60 overflow-hidden"
      style={{ backgroundColor: "rgb(247, 248, 250)" }}
    >
      <div className="px-6 flex justify-center">
        <img
          //ToDo: Should add a check based on type of vehicle here: M1 vs. N1. But should happen when category is moved to BE
          src={CarIconSelector(vehicleInfo?.modelInfo?.bodyStyle, vehicleInfo?.modelInfo?.category)}
          alt="no-electric-vehicle-selected"
          className="object-contain"
        />
      </div>
      <div className="px-10 py-8">
        <h5 className="text-sm font-medium mb-2">
          {t("no-ev-selected.header")}
        </h5>
        <p className="text-xs text-Grey-shade mb-4">
          {t("no-ev-selected.text")}
        </p>
        <button
          className="text-xs font-medium w-full hover:bg-Grey-dark hover:text-white border border-Grey-tint rounded py-2"
          onClick={onClickAlternatives}
        >
          {t("no-ev-selected.button")}
        </button>
      </div>
    </div>
  );
};

export default NoEvSelectedCard;
