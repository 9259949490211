/* react imports */
import { useState } from "react";

/* third party imports */
import { useTranslation } from "react-i18next";

/* local imports */
import OnboardingModal from "./OnboardingModal";
import { ReactComponent as OnboardingImage } from "../../../../../assets/onboarding.svg";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../../../../hooks/state/useUserData";

export default function Onboarding() {
    
    const { t } = useTranslation("onboarding");
    const navigate = useNavigate();
    const [dataSyncModalOpen, setDataSyncModalOpen] = useState(false);
    const { user } = useUserData();

    return (
        <>
            {dataSyncModalOpen ? (
                <OnboardingModal
                    goToPageProps={{
                        action: ()=> navigate("/vehicles"),
                        text: "Go to vehicles"
                      }}
                      isOpen={dataSyncModalOpen}
                    startingStep={0}
                    onCancel={() => {
                        setDataSyncModalOpen(false);
                    }}
                />
            ) : null}
            <div className="flex justify-center pt-40 px-40 text-center">
                <div className="flex-col">
                    <div className="mb-16 flex justify-center">
                        <OnboardingImage />
                    </div>
                    <p className="text-3xl text-Blueberry-dark-default mb-8">{t(`welcome`)} {user?.name}</p>
                    <p className="text-xl text-Black-default mb-2">{t(`upload-description-1`)}</p>
                    <p className="text-xl text-Grey-dark mb-8">{t(`upload-description-2`)}</p>
                    <div className="flex justify-center">
                        <button
                            className="text-white	px-10 py-6 flex items-center bg-Blueberry-dark-default rounded"
                            onClick={() => setDataSyncModalOpen(true)}
                        >
                            {t(`button.upload-first-vehicle`)}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
