import { useQuery } from "@apollo/client";

import { useState } from "react";
import { SimulationScenarios, VehicleConsumptionsCostsAndEmissions } from "../../../../../../../@types/driver/simulations";
import { GET_SIMULATION_SCENARIOS, GET_VEHICLE_CONSUMPTIONS_COSTS_AND_EMISSIONS } from "../../../../../../../services/graphql/driver/queries";


export function useScenariosData(): { scenariosData: SimulationScenarios | null, vehicleConsumptionsCostsAndEmissions: VehicleConsumptionsCostsAndEmissions } {

    const [scenariosData, setScenariosData] = useState<any>(null);
    const [vehicleConsumptionsCostsAndEmissions, setVehicleConsumptionsCostsAndEmissions] = useState<any>(null);

    useQuery<{ simulationScenarios: SimulationScenarios }>(GET_SIMULATION_SCENARIOS, {
        onCompleted: (data) => {
            if (!data) return;

            setScenariosData(data.simulationScenarios);
        }
    });

    useQuery<{ vehicleConsumptionsCostsAndEmissions: VehicleConsumptionsCostsAndEmissions }>(GET_VEHICLE_CONSUMPTIONS_COSTS_AND_EMISSIONS, {
        onCompleted: (data) => {
            if (!data) return;

            setVehicleConsumptionsCostsAndEmissions(data.vehicleConsumptionsCostsAndEmissions);
        }
    })

    return { scenariosData, vehicleConsumptionsCostsAndEmissions };

}

