/* React Imports */
import { useTranslation } from 'react-i18next'

import SwitzerlandFlagIcon from '../../../../assets/flags/Switzerland.svg';
import AustriaFlagIcon from '../../../../assets/flags/Austria.svg';
import GermanyFlagIcon from '../../../../assets/flags/Germany.svg';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';


/* Local Imports */
function SignupOption({title, description, action, disabled} : {title: string, description : string, action: ()=> void, disabled?: boolean}) {


  return (
    <button className={`text-left w-full rounded mb-6 px-6 py-4 border border-Grey-tint`} disabled={disabled} onClick={action}>
      <p className={`text-sm text-Grey-shade`}>{title}</p>
      <h2 className={`text-2xl ${ disabled ? 'text-Grey-default' : 'text-Blueberry-dark-default'}`}>{description}</h2>
    </button>
  )


}


export default function SignupAccountPicker() {

  const { t } = useTranslation('accountTypeSelection')
  const navigate = useNavigate()

  return (

    <div >
      <div className="mb-10">
        <p className='text-3xl text-Black-default'>{t('title')}</p>
        <p className='text-base text-Grey-shade'>{t('sub-title')}</p>
      </div>

      <div className="mb-10">
        <div className="mb-10">
          <SignupOption title={t('business')} description={t('business-description')} action={() => navigate("/sign-up/electrify_fleet")}/>
          <SignupOption title={t('personal')} description={t('personal-description')} action={() => navigate("/sign-up/electrify_driver")} />
        </div>
      </div>

      <div className="mb-16	p-6 w-full bg-Grey-background">
        <p className='text-base text-Grey-shade mb-2'>{t('operating-in')}</p>

        <div className='flex'>
          <div className='flex align-center mr-2'>
            <img src={GermanyFlagIcon} />
          </div>
          <p className='text-base text-Grey-shade'>{t('germany')}</p>
        </div>
        <div className='flex'>
          <div className='flex align-center mr-2'>
            <img src={AustriaFlagIcon} />
          </div>
          <p className='text-base text-Grey-shade'>{t('austria')}</p>
        </div>
        <div className='flex'>
          <div className='flex align-center mr-2'>
            <img src={SwitzerlandFlagIcon} />
          </div>
          <p className='text-base text-Grey-shade'>{t('switzerland')}</p>
        </div>
      </div>

      <div className='text-center text-base text-grey-shade'>
        {t('already-have-account')}<span className='text-Blueberry-dark-default cursor-pointer font-bold ml-1' onClick={() => navigate("/")}>{t('log-in')}</span>
      </div>

    </div>
  )
}
