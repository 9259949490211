import { ChangeEvent, ReactNode, useMemo, useState } from "react";
import eyeIcon from "../../../../../assets/eye-icon.svg";

export function RegistrationCheckBox({
    name,
    checked,
    touched,
    error,
    handleChange,
    label
}: {
    name: string,
    checked: boolean,
    handleChange: (e: ChangeEvent) => void,
    label: ReactNode,
    error?: string,
    touched?: boolean,
}) {

    const displayError = touched && error;

    return (
        <div className="mb-6">
            <input
                type="checkbox"
                className="mr-4 w-4 h-4 text-Blueberry-dark-default bg-transparent rounded border-Blueberry-dark-default focus:ring-0 focus:outline-offset-0 focus:outline-0 focus:ring-offset-0"
                name={name}
                checked={checked}
                onChange={handleChange}
            />
            {label}
            {displayError ? (
                <p className="text-Red-default mt-1 text-sm">
                    {error}
                </p>
            ) : null}
        </div>
    )


}

export function RegistrationFormInput({
    name,
    type,
    handleChange,
    handleBlur,
    value,
    label,
    error,
    touched,
    placeholder,
    required = true
}: {
    name: string,
    type: string,
    handleChange: (e: ChangeEvent) => void,
    handleBlur: (e: ChangeEvent) => void,
    value: string,
    label: string,
    error?: string,
    touched?: boolean,
    placeholder: string,
    required?: boolean
}) {

    const displayError = touched && error;
    return (
        <div className="mb-6">
            <label className="mb-2 text-base text-Grey-shade">
                {label} {required ? '*' : null}
            </label>
            <input
                name={name}
                className={`border-solid block w-full rounded border-Grey-tint text-lg px-5 py-6 ${displayError ? 'border-Red-default' : ''}`}
                type={type}
                placeholder={placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                value={value}
            />
            {displayError ? (
                <p className="text-Red-default mt-1 text-sm">
                    {error}
                </p>
            ) : null}
        </div>
    )
}

export function PasswordInput({
    name,
    handleChange,
    handleBlur,
    value,
    label,
    error,
    touched,
    placeholder,
    hideStrengthBar = false
}: {
    name: string,
    handleChange: (e: ChangeEvent) => void,
    handleBlur: (e: ChangeEvent) => void,
    value: string,
    label: string,
    error?: string,
    touched?: boolean,
    placeholder: string,
    hideStrengthBar?: boolean
}) {

    const [passwordVisible, setPasswordVisible] = useState(false);
    const displayError = touched && error;

    const strength = computePasswordStrength(value);


    return (
        <div className="mb-6">
            <label className="mb-2 text-base text-Grey-shade">
                {label} *
            </label>
            <div className="flex flex-row">
                <input
                    name={name}
                    placeholder={placeholder}
                    className={`border-solid block w-full rounded border-Grey-tint text-lg px-5 py-6 bg-white" ${displayError ? 'border-Red-default' : ''}`}
                    type={passwordVisible ? "text" : "password"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={value}
                />
                <img
                    src={eyeIcon}
                    alt="password-revealer"
                    className="-ml-10"
                    onClick={() => setPasswordVisible(!passwordVisible)}
                />
            </div>
            {!hideStrengthBar ? <StrengthBar strength={strength} /> : null}
            {displayError ? (
                <p className="text-Red-default mt-1 text-sm">
                    {error}
                </p>
            ) : null}
        </div>
    )

}


export function StrengthBar({ strength }: { strength: number }) {



    const strengthProperties = useMemo(() => {
        const strengthIsBetween = (min: number, max: number) => {
            return strength > min && strength <= max;
        }

        if (strengthIsBetween(0, 0.25)) {
            return { bgColor: 'bg-red-500', textColor: 'text-red-500', text: 'Weak' };
        } else if (strengthIsBetween(0.25, 0.5)) {
            return { bgColor: 'bg-yellow-500', textColor: 'text-yellow-500', text: 'Okay' };
        } else if (strengthIsBetween(0.5, 0.75)) {
            return { bgColor: 'bg-cyan-600', textColor: 'text-cyan-600', text: 'Strong' };
        } else if (strengthIsBetween(0.75, 1)) {
            return { bgColor: 'bg-green-500', textColor: 'text-green-500', text: 'Very strong' };
        } else {
            return { bgColor: 'bg-gray-700', textColor: 'text-gray-700', text: '' };
        }
    }, [strength])

    const bars =
        <>
            <div className={`h-0.5 col-span-1 ${strength > 0 ? `${strengthProperties.bgColor}` : 'bg-gray-600'}`} />
            <div className={`h-0.5 col-span-1 ${strength > 0.25 ? `${strengthProperties.bgColor}` : 'bg-gray-600'}`} />
            <div className={`h-0.5 col-span-1 ${strength > 0.5 ? `${strengthProperties.bgColor}` : 'bg-gray-600'}`} />
            <div className={`h-0.5 col-span-1 ${strength > 0.75 ? `${strengthProperties.bgColor}` : 'bg-gray-600'}`} />
        </>


    return (
        <div className="grid grid-cols-4 gap-1 w-full pt-1">
            {bars}
            <div className="col-span-4 ">
                <div className={`flex w-full justify-end ${strengthProperties.textColor}`}>
                    {strengthProperties.text}
                </div>
            </div>
        </div>
    )

}



export function computePasswordStrength(password: string) {

    const lengthWeight = 0.3;
    const numberOfSpecialCharactersWeight = 0.4;
    const numberOfDigitsWeight = 0.1;
    const numberOfLowerCaseLettersWeight = 0.1;
    const numberOfUpperCaseLettersWeight = 0.1;

    let numberOfSpecialCharacters = 0;
    let numberOfDigits = 0;
    let numberOfLowerCaseLetters = 0;
    let numberOfUpperCaseLetters = 0;

    for (let i = 0; i < password.length; i++) {
        if (password[i].match(/[A-Z]/)) {
            numberOfUpperCaseLetters++;
        } else if (password[i].match(/[a-z]/)) {
            numberOfLowerCaseLetters++;
        } else if (password[i].match(/[0-9]/)) {
            numberOfDigits++;
        } else if (password[i].match(/[^A-Za-z0-9]/)) {
            numberOfSpecialCharacters++;
        }
    }
    const LOWERCASE_THRESHOLD = 4;
    const UPPERCASE_THRESHOLD = 4;
    const DIGITS_THRESHOLD = 4;
    const SPECIAL_CHARACTERS_THRESHOLD = 4;

    const lengthScore = lengthWeight * (password.length / 16) >= lengthWeight ? lengthWeight : lengthWeight * (password.length / 16);
    const numberOfSpecialCharactersScore = numberOfSpecialCharactersWeight * (numberOfSpecialCharacters / SPECIAL_CHARACTERS_THRESHOLD) >= numberOfSpecialCharactersWeight ? numberOfSpecialCharactersWeight : numberOfSpecialCharactersWeight * (numberOfSpecialCharacters / SPECIAL_CHARACTERS_THRESHOLD);
    const numberOfDigitsScore = numberOfDigitsWeight * (numberOfDigits / DIGITS_THRESHOLD) >= numberOfDigitsWeight ? numberOfDigitsWeight : numberOfDigitsWeight * (numberOfDigits / DIGITS_THRESHOLD);
    const numberOfLowerCaseLettersScore = numberOfLowerCaseLettersWeight * (numberOfLowerCaseLetters / LOWERCASE_THRESHOLD) >= numberOfLowerCaseLettersWeight ? numberOfLowerCaseLettersWeight : numberOfLowerCaseLettersWeight * (numberOfLowerCaseLetters / LOWERCASE_THRESHOLD);
    const numberOfUpperCaseLettersScore = numberOfUpperCaseLettersWeight * (numberOfUpperCaseLetters / UPPERCASE_THRESHOLD) >= numberOfUpperCaseLettersWeight ? numberOfUpperCaseLettersWeight : numberOfUpperCaseLettersWeight * (numberOfUpperCaseLetters / UPPERCASE_THRESHOLD);

    const strength = lengthScore + numberOfSpecialCharactersScore + numberOfDigitsScore + numberOfLowerCaseLettersScore + numberOfUpperCaseLettersScore;

    return strength;

}