import { useState } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useLayout } from "../../../../../../../../hooks/state/useLayout";
import useHandleDrivingData from "../../../../../../../../hooks/utils/useHandleDrivingData";
import ChartButtonHeader from "../../../../../../../../common/components/Charts/ChartButtonHeader";


export default function LongestDistanceChart({
    data,
    isModal,
}: {
    data: { day: any; trip: any };
    isModal?: boolean;
}) {
    const { t } = useTranslation("drivingAnalysis");
    const { localeCode } = useLayout();

    const [isTrip, setIsTrip] = useState(true);

    const dataToUse = JSON.parse(JSON.stringify(data));

    const { processDataForChart } = useHandleDrivingData();

    const { tripData, dayData, tripLabels, dayLabels } =
        processDataForChart(dataToUse);

    return (
        <div className="flex flex-col h-full">
            <ChartButtonHeader
                button1={{
                    onClick: () => setIsTrip(true),
                    label: t("per-trip-button"),
                }}
                button2={{
                    onClick: () => setIsTrip(false),
                    label: t("per-day-button"),
                }}
                checkProp={isTrip}
            />
            <span className="my-6 text-sm">{t("longest-distance-description")}</span>
            <div
                className="flex-grow flex items-center justify-center"
                style={{ minHeight: isModal ? "500px" : "370px" }}
            >
                <Bar
                    options={{
                        indexAxis: 'y',
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function (tooltipItems: any) {
                                        return `${parseFloat(tooltipItems.raw).toLocaleString(localeCode)} km (${tooltipItems.dataset.label})`;
                                    }
                                },
                            },
                        },
                        scales: {
                            x: {
                                border: { display: false },
                                ticks: {
                                    callback: function (value: any, index: number) {
                                        return index % 2 === 0 ? `${parseFloat(value).toLocaleString(localeCode)} km` : "";
                                    },
                                    maxTicksLimit: 25,
                                },

                            },
                            y: {
                                border: { display: false },
                                grid: {
                                    drawTicks: false,
                                    drawOnChartArea: false,
                                },
                            },
                        },
                    }}
                    data={{
                        labels: isTrip ? tripLabels : dayLabels,
                        datasets: (isTrip ? tripData : dayData) as any,
                    }}
                />
            </div>
        </div>
    );
}
