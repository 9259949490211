import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { useLayout } from "../../../../../../../hooks/state/useLayout";


export function useTripSimulationLayout() {

    const { t, i18n } = useTranslation("tripSimulation")
    const language = i18n.language;
    const { setHeader } = useLayout()

    useEffect(() => {
        setHeader({
            defaultHeaderTitle: t('title'),
            defaultHeaderDescription: t('description')
        })
    }, [language])
}