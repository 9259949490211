import { useLocation } from "react-router-dom";
import { ElectrifyDriverRoutes } from "../routes";



export function useAvailableByDefaultRoutes() {

    const availableByDefaultRoutes = ElectrifyDriverRoutes.filter((r) => r.availableByDefault).map((r) => r.path.replace("/", ""));
    const location = useLocation();

    const mainPathName = location.pathname.split('/')[1];

    return { 
        isCurrentRouteAvailableByDefault : availableByDefaultRoutes.includes(mainPathName),
        availableByDefaultRoutes
     }

}