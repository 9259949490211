/* local imports */
import ChartWrapper from "../../../../../../../../common/components/Charts/ChartWrapper";
import AverageDistanceGraph from "./AverageDistanceChart";
import { useTranslation } from "react-i18next";

export default function AverageDistance({
    dataTrip,
    dataDay,
    sort,
    openExpanded,
    sortedCrescent,
}: {
    dataTrip: any,
    dataDay: any,
    sort: () => void,
    openExpanded: () => void,
    sortedCrescent?: boolean
}) {
    const { t } = useTranslation("drivingAnalysis");

    return (
        <ChartWrapper
            title={t("average-distance-title")}
            sort={sort}
            expand={openExpanded}
            sortedCrescent={sortedCrescent}
        >
            <AverageDistanceGraph data={{ day: dataDay, trip: dataTrip }} />
        </ChartWrapper>
    );
}
