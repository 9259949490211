import { ReactElement, createContext, useState } from 'react';

export const LayoutContext = createContext<{
	headerTitle: string | null;
	setHeaderTitle: any | null;
	headerDescription: string | null;
	setHeaderDescription: any | null;
	headerOption: any | null;
	setHeaderOption: any | null;
	currencySymbol: string | null;
	setCurrencySymbol: any | null;
	localeCode: string | null;
	setLocaleCode: any | null;
}>({
	headerTitle: null,
	setHeaderTitle: null,
	headerDescription: null,
	setHeaderDescription: null,
	headerOption: null,
	setHeaderOption: null,
	currencySymbol: null,
	setCurrencySymbol: null,
	localeCode: null,
	setLocaleCode: null,
});

export const LayoutContextProvider = ({
	children,
}: {
	children: ReactElement;
}) => {
	const [headerTitle, setHeaderTitle] = useState<string | null>(null);
	const [headerDescription, setHeaderDescription] = useState(null);
	const [headerOption, setHeaderOption] = useState(null);
	const [currencySymbol, setCurrencySymbol] = useState(null);
	const [localeCode, setLocaleCode] = useState(null);

	return (
		<LayoutContext.Provider
			value={{
				headerTitle,
				setHeaderTitle,
				headerDescription,
				setHeaderDescription,
				headerOption,
				setHeaderOption,
				currencySymbol,
				setCurrencySymbol,
				localeCode,
				setLocaleCode,
			}}
		>
			{children}
		</LayoutContext.Provider>
	);
};
