import { ReactElement, useEffect, useState } from "react";
import { DongleOrderedIcon, DonglePluggedIcon, DongleShippedIcon, InitialDataReceived, MinimumRequirementsIcon, MinimumRequirementsMet, OrderCompleted, ShipmentComplete } from "./components";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { getDongleLeasingOrderStatus } from "../../../../../../services/rest/driverDongle";


export interface IMilestoneStep<T> {
    completed: boolean;
    message: string;
    milestoneIcon: ReactElement;
    mainComponentContent: ReactElement;
    nextStepInfo: T | {};
}

export enum MilestoneStepStatus {
    COMPLETED = "completed",
    INCOMPLETE = "incomplete",
    EXPIRED = "expired"
}

class MilestoneStep<T> implements IMilestoneStep<T> {

    private _messages: {
        completed: string,
        incomplete: string,
        expired: string
    } = {
            completed: "",
            incomplete: "",
            expired: ""
        };

    private _additionalData?: T | {};

    private _status: MilestoneStepStatus = MilestoneStepStatus.INCOMPLETE;

    public _milestoneIcon: ({ color }: { color?: string | undefined; }) => ReactElement = ({ color }) => <></>;

    public _mainComponentContent: (props: T | {}) => ReactElement = () => <></>;

    constructor({
        status = MilestoneStepStatus.INCOMPLETE,
        messages,
        milestoneIcon,
        mainComponentContent,
        nextStepInfo = undefined
    }: {
        status?: MilestoneStepStatus,
        messages: { completed: string, incomplete: string, expired: string },
        milestoneIcon: ({ color }: { color?: string }) => ReactElement,
        mainComponentContent: (props: T | {}) => ReactElement,
        nextStepInfo?: T
    }) {
        this._status = status;
        this._messages = messages;
        this._milestoneIcon = milestoneIcon;
        this._mainComponentContent = mainComponentContent;
        this._additionalData = nextStepInfo;
    }


    set status(status: MilestoneStepStatus) {
        this._status = status;
    }

    get status(): MilestoneStepStatus {
        return this._status;
    }

    get completed(): boolean {
        return this._status === 'completed';
    };

    get message(): string {
        return this._messages[this._status];
    }

    get milestoneIcon(): ReactElement {
        return (
            <this._milestoneIcon color={this.completed ? "white" : "#9B0860"} />
        )
    }

    get mainComponentContent(): ReactElement {
        return (
            <this._mainComponentContent {...this.nextStepInfo} />
        )
    }

    get nextStepInfo(): T | {} {
        return this._additionalData || {};
    }

    set nextStepInfo(data: T | {}) {
        this._additionalData = data;
    }

}

export type DongleWelcomeSteps = {
    dongleOrder: MilestoneStep<undefined>,
    dongleShipment: MilestoneStep<{ shipmentDate: string }>,
    dongleInstallation: MilestoneStep<any>,
    minimumRequirements: MilestoneStep<{ missingDays?: number, missingTrips?: number, missingDistance?: number }>
}



export function useDongleProductWelcome() {

    const { t, i18n } = useTranslation("dongleRoadMap")

    const [steps, setSteps] = useState<DongleWelcomeSteps>({
        dongleOrder: new MilestoneStep({
            status: MilestoneStepStatus.COMPLETED,
            messages: {
                completed: "roadmap-steps.order-completed",
                incomplete: "",
                expired: ""
            },
            milestoneIcon: DongleOrderedIcon,
            mainComponentContent: OrderCompleted
        }),
        dongleShipment: new MilestoneStep<{
            shipmentDate: string
        }>({
            messages: {
                completed: "roadmap-steps.shipment.completed",
                incomplete: "roadmap-steps.shipment.incomplete",
                expired: ""
            },
            milestoneIcon: DongleShippedIcon,
            mainComponentContent: ShipmentComplete
        }),
        dongleInstallation: new MilestoneStep<{
            missingDays?: number, missingTrips?: number, missingDistance?: number
        }>({
            messages: {
                incomplete: "roadmap-steps.initial-data.incomplete",
                completed: "roadmap-steps.initial-data.completed",
                expired: "roadmap-steps.initial-data.expired"
            },
            milestoneIcon: DonglePluggedIcon,
            mainComponentContent: InitialDataReceived,
        }),
        minimumRequirements: new MilestoneStep({
            messages: {
                incomplete: "roadmap-steps.minimum-requirements.incomplete",
                completed: "roadmap-steps.minimum-requirements.completed",
                expired: ""
            },
            milestoneIcon: MinimumRequirementsIcon,
            mainComponentContent: MinimumRequirementsMet
        })
    })




    useQuery("dongleOrderStatus", getDongleLeasingOrderStatus, {
        onSuccess: (data) => {
            Object.keys(data).forEach((key) => {
                const step = steps[key as keyof DongleWelcomeSteps];
                step.status = data[key as keyof DongleWelcomeSteps].status;
                step.nextStepInfo = data[key as keyof DongleWelcomeSteps].nextStepInfo;
            })
            setSteps({ ...steps });
        }
    })


    const geCurrentIncompleteStepComponent = (): ReactElement => {

        const stepsArray = Object.values(steps);

        const firstIncompleteStep = stepsArray.findIndex(step => !step.completed);

        if (firstIncompleteStep === -1) return stepsArray[stepsArray.length - 1].mainComponentContent;

        const lastCompleteStep = stepsArray[firstIncompleteStep - 1];

        return lastCompleteStep.mainComponentContent;
    }

    const incrementStep = () => {
        const firstIncompleteStep = Object.values(steps).findIndex(step => !step.completed);
        const lastCompleteStep = Object.values(steps)[firstIncompleteStep];
        lastCompleteStep.status = MilestoneStepStatus.COMPLETED;
        setSteps({ ...steps });
    }


    return {
        steps,
        currentIncompleteStepComponent: geCurrentIncompleteStepComponent(),
        incrementStep
    }

}