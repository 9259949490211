import { Button } from '../../../../../../electrify_frontend_common/components/Buttonv2';
import { ButtonSize, ButtonVariant } from '../../../../../../electrify_frontend_common/components/Buttonv2/types';
import { ReactComponent as PlusIcon } from '../../../../../../assets/plus-icon-purple.svg';


export function NoData({ icon, title, text, description, buttonText, onClick }: { icon: any, title: string, text: string, description: string, buttonText: string, onClick: () => void }) {

    return (
        <div className='flex flex-col w-full bg-Grey-background justify-center items-center mt-9 py-9'>
            <div className='mb-2'>
                {icon}
            </div>
            <div className='text-Blueberry-dark-default text-2xl'>
                {title}
            </div>
            <div className='text-xl w-full flex flex-col items-center px-40 text-center mt-4'>
                {text}
                <div className='text-lg mt-1 text-Grey-dark'>
                    {description}
                </div>
            </div>
            <div className='mt-5'>
                <Button onClick={onClick} size={ButtonSize.MEDIUM} variant={ButtonVariant.SECONDRY}>
                    <div className="flex items-center">
                        <PlusIcon className='mr-2 ' />
                        <span>{buttonText}</span>
                    </div>
                </Button>
            </div>
        </div>
    )

}