import { useMemo } from "react";
import { useUserData } from "../../../../../hooks/state/useUserData";
import { DriverEvFinder } from "./DriverEvFinder";
import { FreeEvFinder } from "../OpenEvFinder";


export function EvFinder() {

    const { user } = useUserData();

    const evFinder = useMemo(() => {

        if (!user?.accountInfo?.usableProduct) {
            return <FreeEvFinder />
        }
        return <DriverEvFinder />
    }, [user?.accountInfo?.onboarded])

    return (
        <>{evFinder}</>
    )

}