import { useTranslation } from "react-i18next";
import WorkplaceParkingOccupationGraph from "./WorkplaceParkingOccupationChart";
import ChartWrapper from "../../../../../../../../common/components/Charts/ChartWrapper";

export default function WorkplaceParkingOccupation({
  dataTime,
  dataStays,
  openExpanded,
} : {
  dataTime : any,
  dataStays : any,
  openExpanded: ()=> void
}) {

  const { t } = useTranslation("parkingAnalysis");

  return (
    <ChartWrapper
      title={t("workplace-parking-occupation-title")}
      expand={openExpanded}
    >
      <WorkplaceParkingOccupationGraph data={{ dataTime, dataStays }} />
    </ChartWrapper>
  );
}
