import { useState } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useLayout } from "../../../../../../../../hooks/state/useLayout";
import useHandleDrivingData from "../../../../../../../../hooks/utils/useHandleDrivingData";
import ChartButtonHeader from "../../../../../../../../common/components/Charts/ChartButtonHeader";



export default function BusinessPrivateChart({
    data,
    isModal,
}: {
    data: any;
    isModal?: boolean;
}) {
    const { t } = useTranslation("drivingAnalysis");
    const { localeCode } = useLayout();

    const [isTrip, setIsTrip] = useState(true);

    const dataToUse = JSON.parse(JSON.stringify(data));

    const { processDataForChart } = useHandleDrivingData();

    const {
        tripData,
        dayData: distanceData,
        tripLabels,
        dayLabels,
    } = processDataForChart(dataToUse);

    return (
        <div className="flex flex-col h-full">
            <ChartButtonHeader
                button1={{
                    onClick: () => setIsTrip(true),
                    label: t("trips-button"),
                }}
                button2={{
                    onClick: () => setIsTrip(false),
                    label: t("distance-button"),
                }}
                checkProp={isTrip}
            />
            <span className="my-6 text-sm">{t("business-private-description")}</span>
            <div
                className="flex-grow flex items-center justify-center"
                style={{ minHeight: isModal ? "500px" : "370px" }}
            >
                <Bar
                    options={{
                        indexAxis: 'y',
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function (tooltipItems: any) {
                                        return `${parseFloat((tooltipItems.raw as number).toFixed(1)).toLocaleString(localeCode)}% (${tooltipItems.dataset.label})`;
                                    },
                                },
                            },
                        },
                        scales: {
                            x: {
                                stacked: true,
                                max: 100,
                                border: { display: false },
                                grid: { drawTicks: false },
                                ticks: {
                                    callback: function (value: any, index: number) {
                                        return index % 2 === 0 ? `${parseFloat((value as number).toFixed(1)).toLocaleString(localeCode)}%` : "";
                                    },
                                    stepSize: 10,
                                },

                            },
                            y: {
                                stacked: true,
                                border: { display: false },
                                grid: {
                                    drawTicks: false,
                                    drawOnChartArea: false,
                                },
                            },
                        },
                    }}
                    data={{
                        labels: isTrip ? tripLabels : dayLabels,
                        datasets: (isTrip ? tripData : distanceData) as any,
                    }}
                />
            </div>
        </div>
    );
}
