import { ReactComponent as NoVehicle } from '../../../../../../assets/no-vehicle.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/edit-icon-white.svg';
import { ReactComponent as CapacityIcon } from '../../../../../../assets/driver_settings/fuel.svg';
import { ReactComponent as RangeIcon } from '../../../../../../assets/driver_settings/range.svg';
import { ReactComponent as FuelIcon } from '../../../../../../assets/driver_settings/capacity.svg';
import { ReactComponent as WhiteWarningIcon } from '../../../../../../assets/warning-white.svg';
import { ReactComponent as WarningIcon } from '../../../../../../assets/warning-large.svg';
import { ReactComponent as CloseIcon } from '../../../../../../assets/close-blueberry.svg';

import { NoData } from '../components';
import { InputTypes, DriverVehicleEditor } from './VehicleEditor';
import { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Vehicle } from '../../../../../../@types/vehicle';
import { CarIconSelector } from '../../../../../../electrify_frontend_common/components/CarIconSelector';
import { BrandInfo } from '../../../../../../electrify_frontend_common/components/VehicleCards/BaseVehicleCard/components/BrandInfo';

enum InfoType {
    FUEL = "FUEL",
    RANGE = "RANGE",
    CAPACITY = "CAPACITY"
}


function InfoSquare({ type, value }: { type: InfoType, value: string | null }) {

    const bgColor = {
        [InfoType.FUEL]: 'bg-black',
        [InfoType.RANGE]: 'bg-Grey-dark',
        [InfoType.CAPACITY]: 'bg-Grey-default'
    }

    const icon = {
        [InfoType.FUEL]: <FuelIcon />,
        [InfoType.RANGE]: <RangeIcon className='w-4' />,
        [InfoType.CAPACITY]: <CapacityIcon />
    }

    const label = {
        [InfoType.FUEL]: "",
        [InfoType.RANGE]: "l",
        [InfoType.CAPACITY]: "l"
    }

    return (
        <div className={`flex flex-col relative h-[75px] w-[75px] items-center justify-center text-white text-sm rounded-md mt-1 mx-1 ${bgColor[type]} px-1`}>
            {icon[type]}
            <div className='mt-1 max-w-10'>{value || "?"} {label[type]}</div>
            {type == InfoType.RANGE ? <div>/100 km</div> : null}

            {!value ? <WhiteWarningIcon className='absolute w-5 top-1 right-1' /> : null}
        </div>
    )
}

function InformationMissingWarning({  onClose, children }: { onClose: () => void, children: ReactElement }) {

    return (
        <div className='w-3/5 mt-6 p-2 border border-Blueberry-dark-default  bg-Blueberry-light-background text-Blueberry-dark-default rounded flex justify-between'>
            <WarningIcon className='mr-4 w-8' />
            {children}
            <CloseIcon className='w-8 ml-4 cursor-pointer' onClick={onClose} />
        </div>
    )

}

export function NoVehicleView({ onClick }: { onClick: () => void }) {

    return (<NoData
        icon={<NoVehicle />}
        title={"No vehicle yet"}
        text='Add your vehicle here'
        description='Your vehicle is used as a reference value and to provide further insights in the e-vehicle simulations.'
        buttonText='Add your vehicle'
        onClick={onClick}
    />)
}

export function VehicleWithoutInfoView({ vehicle, warningOpen, openModal, closeWarning }: { vehicle: Vehicle, warningOpen : boolean, openModal: ()=> void, closeWarning: ()=> void }) {

    const {t} = useTranslation("driverSettings");

    return (
        <>
            <div className='border w-1/3 p-4 border-Grey-default rounded bg-Grey-background flex justify-between'>
                <div>
                    <div className='flex items-center'>
                        <WarningIcon className='mr-2' />
                        <div>
                            <div className='text-sm text-Grey-default '>{t("current-vehicle.unspecified-make")}</div>
                            <div className=''>{t("current-vehicle.unspecified-model")}</div>
                        </div>
                    </div>
                    <div>
                        <img src={CarIconSelector()} />
                    </div>
                </div>
                <div className='flex w-2/5 flex-col justify-between'>
                    <InfoSquare type={InfoType.FUEL} value={null} />
                    <InfoSquare type={InfoType.RANGE} value={null} />
                    <InfoSquare type={InfoType.CAPACITY} value={null} />
                </div>
            </div>
            {
                vehicle.modelInfo?.useVin && warningOpen ? <InformationMissingWarning
                   
                    onClose={closeWarning}
                >
                    <div>
                        {t("current-vehicle.warning-no-model-1")}
                        <span className='cursor-pointer underline' onClick={openModal}> {t("current-vehicle.warning-select-manually")}</span>
                    </div>
                </InformationMissingWarning> : null
            }
        </>
    )

}

export function VehicleInfo({ vehicle, warningOpen, openModal, closeWarning }: { vehicle: Vehicle, warningOpen: boolean, openModal: () => void, closeWarning: () => void }) {
    
    const {t} = useTranslation("driverSettings");
    
    const vehicleHasModel = useMemo(() => {
        return vehicle.modelInfo
    }, [vehicle])

    const fuelConsumption: string | null = useMemo(() => {
        const fuelConsumption = vehicle?.modelInfo?.fuelConsumption;
        if (fuelConsumption?.combined) return fuelConsumption?.combined?.toString();
        if (fuelConsumption?.city && fuelConsumption?.highway)
            return ((fuelConsumption?.city + fuelConsumption?.highway) / 2).toFixed(1);
        return null;
    }, [vehicle?.modelInfo?.fuelConsumption?.combined])

    return (
        <>
            {vehicleHasModel ? 
                <div className='border w-1/3 p-4 border-Grey-default rounded bg-Grey-background flex justify-between h-full'>
                    <div className='h-full'>
                        <BrandInfo
                            vehicleBrand={vehicle?.modelInfo?.brand}
                            vehicleModel={vehicle?.modelInfo?.model}
                        />
                        <div className='flex flex-col flex-1 h-full items-center justify-center'>
                            <img className='mt-8' src={CarIconSelector(vehicle?.modelInfo?.bodyStyle, vehicle?.modelInfo?.category)} />
                        </div>
                    </div>
                    <div className='flex w-2/5 flex-col justify-between'>
                        <InfoSquare type={InfoType.FUEL} value={vehicle?.modelInfo?.fuelType || null} />
                        <InfoSquare type={InfoType.RANGE} value={fuelConsumption} />
                        <InfoSquare type={InfoType.CAPACITY} value={vehicle?.modelInfo?.loadVolume?.toString() || null} />
                    </div>
                </div>
          : <VehicleWithoutInfoView vehicle={vehicle} warningOpen={warningOpen} openModal={openModal} closeWarning={closeWarning}/>}

            {(warningOpen && !vehicle?.modelInfo?.fuelConsumption?.combined) ?
                
                    <InformationMissingWarning onClose={closeWarning} >
                        <div>
                            <div className='mb-4'>
                               {t("current-vehicle.warning-no-fuel-1")}
                            </div>
                            <div>
                            {t("current-vehicle.warning-no-fuel-2")} <span onClick={openModal} className=' cursor-pointer underline'>{t("current-vehicle.warning-select-manually")}</span>
                            </div>
                        </div>

                    </InformationMissingWarning>
               : null
            }
        </>

    )
}

export function CurrentVehicle({ vehicle, refetchVehicle }: { vehicle: Vehicle | undefined, refetchVehicle: () => void }) {

    const [vehicleEditorOpen, setVehicleEditorOpen] = useState(false);
    const [editManual, setEditManual] = useState(false);
    const [warningOpen, setWarningOpen] = useState(true);

    const {t} = useTranslation("driverSettings");

    return (
        <>
            {
                vehicleEditorOpen ?
                    <DriverVehicleEditor
                        defaultInput={editManual ? InputTypes.MANUAL : undefined}
                        vehicle={vehicle}
                        isOpen={vehicleEditorOpen}
                        close={() => {
                            setVehicleEditorOpen(false)
                            refetchVehicle();
                        }}
                    />
                    : null
            }

            <section>
                <div className="flex justify-between items-center mb-6">
                    <h1 className=' text-xl'>{t("current-vehicle.title")}</h1>
                    {vehicle ?
                        <button
                            onClick={() => setVehicleEditorOpen(true)}
                            className='p-3 flex items-center rounded text-white bg-Blueberry-dark-default hover:bg-Blueberry-light-default'>
                            <EditIcon className='mr-2' />
                            <span>{t("current-vehicle.button")}</span>
                        </button>
                        : null}
                </div>
                <div>
                    {vehicle ? <VehicleInfo
                        warningOpen={warningOpen}
                        openModal={() => {
                            setEditManual(true);
                            setVehicleEditorOpen(true);
                        }}
                        closeWarning={() => {
                            setWarningOpen(false)
                            setEditManual(false);
                        }}
                        vehicle={vehicle} /> : <NoVehicleView onClick={() => setVehicleEditorOpen(true)} />}
                </div>
            </section>
        </>
    )

}
