import ChartWrapper from "../../../../../../../../common/components/Charts/ChartWrapper";
import { DistanceShareChart } from "./DistanceShareChart";
import { useTranslation } from "react-i18next";

export function DistanceShare({ openExpanded, weekdays, weekends }: any) {
  const { t } = useTranslation("driverDrivingAnalysis");

  return (
    <ChartWrapper title={t("distance-share.title")} expand={openExpanded}>
      <div className="h-60">
        <DistanceShareChart data={{ weekdays, weekends }} />
      </div>
    </ChartWrapper>
  );
}
