/* React Imports */
import { ReactElement, useState } from "react";

/* Third-party Imports */

/* Local Imports */
import DropdownNavbar from "../DropdownNavbar";

import { ReactComponent as BurguerIcon } from "../../../../assets/burguer-icon.svg";

import { Logo } from "../Logo";
import LanguageDropdown from "./LanguageDropdown";
import UserDropdown from "./UserDropdown";
import SupportButton from "./SupportButton";
import { useLayout } from "../../../../hooks/state/useLayout";

export default function Header({navbar} : {navbar : ReactElement}) {

  const {headerTitle, headerDescription, headerOption} = useLayout();

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <>
      <div className="w-full sm:flex justify-between item-center rounded fit-content bg-white sm:p-8">
        <div className="sm:hidden w-full bg-Grey-background flex items-center justify-between cursor-pointer p-9">
          <Logo />
          <BurguerIcon onClick={() => setDropdownOpen(!dropdownOpen)} />
        </div>

        <div className="flex w-full items-center pr-10">
          <div className="xl:flex w-full justify-between items-center p-4 sm:p-0">
            <div className="flex flex-col">
              <h1 className="text-2xl leading-8	text-Blueberry-dark-default font-normal">
                {headerTitle} 
              </h1>
              {headerDescription ? <p className="text-Grey-shade text-base mt-2">{headerDescription}</p> : <div className="h-8"></div>} {/* TODO Replace for context description!!!!!! */}
            </div>
            <div>
              {headerOption || null}
            </div>
          </div>
        </div>
        <div className="hidden sm:flex items-center">
          <LanguageDropdown />
          <UserDropdown />
          <SupportButton className={`h-14 border-solid rounded px-4 mr-4 w-44 justify-center`} />
        </div>


      </div>
      <div className="px-10">
        <hr className="border-Grey-tint" />
      </div>
      {dropdownOpen && <DropdownNavbar navbar={navbar} setDropdownOpen={setDropdownOpen} />}
    </>
  );
}
