import { ComparisonProp } from "../../../../../../../@types";
import { ComparisonBox } from "../../../../../../../common/components/misc/ComparisonBox";
import { useLayout } from "../../../../../../../hooks/state/useLayout";
import { ReactComponent as ArrowDownIcon } from "../../../../../../../assets/overview-arrow-down.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../../../../assets/overview-arrow-up.svg";


const getcomparisonProps = (
    comparison: string,
    localeCode: string
): ComparisonProp => {
    const val = parseFloat(comparison);
    const displayValue = parseFloat(comparison).toLocaleString(localeCode);

    if (val > 0) {
        return {
            icon: <ArrowUpIcon className="ml-1" />,
            backgroundColor: "bg-Red-default",
            value: `+${displayValue}%`,
        };
    } else if (val < 0) {
        return {
            icon: <ArrowDownIcon className="ml-1" />,
            backgroundColor: "bg-Green-default",
            value: `${displayValue}%`,
        };
    } else {
        return {
            icon: null,
            backgroundColor: "bg-Grey-default",
            value: `${displayValue}%`,
        };
    }
};

export const MetricComparison = ({ icon, title, value, units, comparison, tooltip }: any) => {
    const { localeCode } = useLayout();

    const noComparisonProvided = comparison == null || comparison == undefined;

    return (
        <div className="flex mb-10 items-center">
            <div><img src={icon} alt="dashboard-icon" /></div>
            <div className="ml-4">
                <p className="text-xs	text-Grey-shade font-bold">{title}</p>
                <div className={`text-3xl font-black ${noComparisonProvided ? "text-black" : "text-Blueberry-dark-default"} flex items-center`}>
                    {value?.toLocaleString(localeCode) || ""}{" "}
                    <span className={`text-xl font-light ml-2 ${noComparisonProvided ? "text-black" : "text-Blueberry-dark-default"}`}>{units}</span>
                    <span className="ml-2">{tooltip}</span>
                    {!noComparisonProvided ? <ComparisonBox comparison={comparison} /> : null}
                </div>
            </div>
        </div>
    );
};
