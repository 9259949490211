enum ColorKey {
    RED = "red",
    GREEN = "green",
    BLUE = "blue"
}
type ColorNumberMap = { [key in ColorKey]: number };
type ColorRange = { darkColor: ColorNumberMap, brightColor: ColorNumberMap }

const narrowRange: ColorRange = {
    darkColor: {
        [ColorKey.RED]: 154,
        [ColorKey.GREEN]: 50,
        [ColorKey.BLUE]: 114,
    },
    brightColor: {
        [ColorKey.RED]: 225,
        [ColorKey.GREEN]: 163,
        [ColorKey.BLUE]: 201,
    }
}

const normalRange: ColorRange = {
    darkColor: {
        [ColorKey.RED]: 154,
        [ColorKey.GREEN]: 50,
        [ColorKey.BLUE]: 114,
    },
    brightColor: {
        [ColorKey.RED]: 240,
        [ColorKey.GREEN]: 209,
        [ColorKey.BLUE]: 228,
    }
}

const widerRange: ColorRange = {
    darkColor: {
        [ColorKey.RED]: 120,
        [ColorKey.GREEN]: 25,
        [ColorKey.BLUE]: 79,
    },
    brightColor: {
        [ColorKey.RED]: 250,
        [ColorKey.GREEN]: 216,
        [ColorKey.BLUE]: 237,
    }
}

const colorKeyNames = Object.values(ColorKey)

function calculateColorSteps(countOfSteps: number, brightColor: ColorNumberMap, darkColor: ColorNumberMap): ColorNumberMap {
    const colorSteps: ColorNumberMap = {
        [ColorKey.RED]: 0,
        [ColorKey.GREEN]: 0,
        [ColorKey.BLUE]: 0,
    }

    for (const colorKey of colorKeyNames)
        colorSteps[colorKey] = (brightColor[colorKey] - darkColor[colorKey]) / countOfSteps

    return colorSteps
}

function constructRgbColor(rgbColor: ColorNumberMap, alpha: number): string {
    return alpha < 1 ?
        `rgba(${rgbColor[ColorKey.RED]},${rgbColor[ColorKey.GREEN]},${rgbColor[ColorKey.BLUE]},${alpha})` :
        `rgb(${rgbColor[ColorKey.RED]},${rgbColor[ColorKey.GREEN]},${rgbColor[ColorKey.BLUE]})`
}

function calculateIterColor(colorSteps: ColorNumberMap, darkColor: ColorNumberMap, alpha: number, colorIter: number): string {
    const iterColor: ColorNumberMap = { ...darkColor }

    for (const colorKey of colorKeyNames)
        iterColor[colorKey] = Math.round(darkColor[colorKey] + colorSteps[colorKey] * colorIter)

    return constructRgbColor(iterColor, alpha)
}

export function defineColorScheme(countOfColors: number, alpha: number = 1): string[] {
    if (countOfColors > 1) {
        const { darkColor, brightColor } = countOfColors > 6 ? widerRange : countOfColors > 3 ? normalRange : narrowRange

        const colorScheme: string[] = []
        const colorSteps = calculateColorSteps(countOfColors - 1, brightColor, darkColor)

        for (let i = 0; i < countOfColors; i++)
            colorScheme.push(calculateIterColor(colorSteps, darkColor, alpha, i))

        return colorScheme
    }

    return [constructRgbColor(narrowRange.darkColor, alpha)]
}