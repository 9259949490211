import { Toast, ToastType, Toaster } from "react-hot-toast";
import { EventBanner, EventState } from "../misc/EventBanner";


function SuccessToast() {

    return (
        <EventBanner
            state={EventState.SUCCESS}
            content={<div>Success!!</div>}
        />
    )
}


export function CustomToaster() {

    const mapToastTypeToEventState = (type: ToastType) => {
        switch (type) {
            case "success":
                return EventState.SUCCESS
            case "error":
                return EventState.ERROR
            default:
                return EventState.INFO
            // TODO we must add the remaining necessary states
        }
    }


    return (
        <Toaster
            position="bottom-right"
        >
            {(toast: Toast) => (
                <div className={`${toast.visible ? 'animate-enter' : 'animate-leave'}`}>
                    <EventBanner
                        state={mapToastTypeToEventState(toast.type)}
                        content={<div>{toast.message as any}</div>}
                    />
                </div>
            )}
        </Toaster>
    )


}