import { useMutation } from "react-query"

import { useCallback, useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { ElectrifyPlannerContext } from "../../../../../contexts/ElectrificationPlannerContext";
import { updateDeliveryDate } from "../../../../../../../services/rest/electrificationPlanner";
import { Button } from "../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonVariant } from "../../../../../../../electrify_frontend_common/components/Buttonv2/types";




export function DeliveryDateStep({
    step,
    nextStep,
    prevStep,
}: {
    step: number,
    nextStep: () => void,
    prevStep: () => void,
}) {

    const { t } = useTranslation("electrificationPlanner");

    const defaultDate = useCallback(() => {
        const currentDate = new Date();
        currentDate.setFullYear(currentDate.getFullYear() + 1);
        currentDate.setMonth(currentDate.getMonth() + 1);
        currentDate.setDate(1);
        return currentDate.toISOString().split("T")[0];
    }, [])

    const { electrificationPlanner, refetch } = useContext(ElectrifyPlannerContext);
    const [date, setDate] = useState(electrificationPlanner?.vehicleDeliveryDate?.split("T")[0] || defaultDate);

    const dateUpdateMutation = useMutation(updateDeliveryDate, {
        onSuccess: () => {
            refetch();
            nextStep();
        }
    });

    const setDeliveryDate = () => {
        dateUpdateMutation.mutate({
            deliveryDate: date,
            updateDatesAccordingly: true,
            nextStep: step + 1
        })
    }

    return (
        <div className="flex flex-col p-6 px-10 w-[1000px]">
            <div className="text-lg mb-5 mt-2"><span className="text-Blueberry-dark-default">{t("onboarding.step")} {step}:</span> {t("onboarding.deliveryDateStep.title")}</div>
            <div>{t("onboarding.deliveryDateStep.question")}</div>
            <div className=" text-Grey-shade text-sm">
                {t("onboarding.deliveryDateStep.info")}
            </div>

            <div className="mt-5">
                <div className="text-sm">{t("onboarding.deliveryDateStep.deliveryDate")}</div>
                <input
                    value={date}
                    type="date"
                    min={new Date().toISOString().split("T")[0]}
                    className="w-1/2 h-10 border border-Grey-tint rounded px-2"
                    onChange={(e) => {
                        setDate(e.target.value);
                    }}

                />
            </div>

            <div className="flex w-full justify-between mt-12">
                <Button type="button" variant={ButtonVariant.SECONDRY} onClick={prevStep} className="mr-4">{"Back"}</Button>

                <Button type="submit" onClick={setDeliveryDate}>{"Next"}</Button>
            </div>

        </div>
    )


}