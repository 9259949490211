/* react imports */
import React from "react";

/* third party imports */
import { useTranslation } from "react-i18next";

/* local imports */
import { WorkplaceParkingOccupationMaxVehiclesChart } from "./WorkplaceParkingOccupationMaxVehiclesChart";
import ChartWrapper from "../../../../../../../../common/components/Charts/ChartWrapper";

export default function WorkplaceParkingOccupationMaxVehicles({
    labelsMonth,
    labelsYear,
    dataMonth,
    dataYear,
    openExpanded,
}: {
    labelsMonth: any,
    labelsYear: any,
    dataMonth: any,
    dataYear: any,
    openExpanded: () => void
}) {
    const { t } = useTranslation("parkingAnalysis");

    return (
        <ChartWrapper
            title={t("workplace-vehicles-count-title")}
            expand={openExpanded}
        >
            <WorkplaceParkingOccupationMaxVehiclesChart
                data={{ labelsMonth, labelsYear, dataMonth, dataYear }}
            />
        </ChartWrapper>
    );
}
