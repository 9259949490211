


export function EnergyCost({ color = "black" }: { color?: string }) {

    return (
        <svg width="22" height="40" viewBox="0 0 22 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.2679 18.8483C20.4268 18.59 20.5139 18.294 20.5203 17.9908C20.5266 17.6876 20.452 17.3882 20.3041 17.1234C20.1562 16.8587 19.9403 16.6381 19.6788 16.4846C19.4172 16.3311 19.1195 16.2501 18.8162 16.25H13.0212V1.25L0.774543 21.1533C0.61551 21.4115 0.528281 21.7075 0.521866 22.0106C0.51545 22.3138 0.59008 22.6132 0.738048 22.8778C0.886015 23.1425 1.10196 23.3629 1.36357 23.5162C1.62518 23.6695 1.92298 23.7502 2.22621 23.75H8.02121V38.75L20.2679 18.8483Z" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        </svg>

    )

}