


export function ExpandIcon({ color = "black", className = "" }: { color?: string, className?: string }) {

    return (
        <svg className={className} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon">
                <path id="Vector" d="M13.3327 5.33331L8.25061 10.4149C8.21763 10.4479 8.17846 10.4741 8.13535 10.492C8.09224 10.5099 8.04602 10.5191 7.99935 10.5191C7.95268 10.5191 7.90646 10.5099 7.86335 10.492C7.82023 10.4741 7.78107 10.4479 7.74809 10.4149L2.66602 5.33331" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </g>
        </svg>

    )

}