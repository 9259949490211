import { Chart as ChartJS, ArcElement, BarElement, CategoryScale, Filler, Legend, LineController, LineElement, LinearScale, PointElement, Tooltip } from 'chart.js';
import { defineColorScheme } from './common/components/Charts/ChartColors';

function configureCharts() {
    ChartJS.register(ArcElement, BarElement, CategoryScale, Filler, Legend, LineController, LineElement, LinearScale, PointElement, Tooltip);

    // --> Setting global options for all charts <--
    // General
    ChartJS.defaults.maintainAspectRatio = false;

    // Font
    ChartJS.defaults.font.family = 'Roboto, sans-serif';

    // Color Scheme
    ChartJS.defaults.datasets.line.borderColor = defineColorScheme(1)
    ChartJS.defaults.datasets.line.backgroundColor = defineColorScheme(1, 0.5)
    ChartJS.defaults.datasets.bar.backgroundColor = defineColorScheme(1)

    // Ticks
    ChartJS.defaults.scale.ticks.padding = 10;
    ChartJS.defaults.scale.ticks.color = '#595E69'

    // Tooltips
    ChartJS.defaults.plugins.tooltip.backgroundColor = "rgba(89, 94, 105, 0.9)";
    ChartJS.defaults.plugins.tooltip.titleMarginBottom = 10;
    ChartJS.defaults.plugins.tooltip.padding = 12;
    ChartJS.defaults.plugins.tooltip.cornerRadius = 8;

    // Legend
    ChartJS.defaults.plugins.legend.position = "bottom";
    ChartJS.defaults.plugins.legend.labels.color = "#595E69";
    ChartJS.defaults.plugins.legend.labels.borderRadius = 8;
    ChartJS.defaults.plugins.legend.labels.padding = 24;

    // const legendBackgroundPlugin = {
    //     id: 'legendBackground',
    //     beforeDraw(chart: any) {
    //         const ctx = chart.ctx;
    //         const legend = chart.legend;
    //         if (legend) {
    //             ctx.save();

    //             // Background settings
    //             ctx.fillStyle = '#F7F8FA';
    //             const { left, top, width, height } = legend;
    //             ctx.fillRect(left, top, width, height);

    //             // Border settings
    //             ctx.strokeStyle = '#E1E4EB';
    //             ctx.lineWidth = 1;
    //             ctx.borderRadius = 1;
    //             ctx.strokeRect(left, top, width, height);

    //             ctx.restore();
    //         }
    //     }
    // };

    // ChartJS.register(legendBackgroundPlugin);

    // Animations
    // ChartJS.defaults.animation = {
    //     duration: 1500,
    //     easing: "easeInOutQuart",
    //     delay: 100,
    //     loop: false,
    // };
}

export default configureCharts