import { SimulationVehicleInfo } from "../../@types";



export function extractVehicleIdsFromSummaries(simulationVehicleInfo : SimulationVehicleInfo[]) {

    const simulationsToRequest: any = [];
    simulationVehicleInfo.forEach((vehicle: SimulationVehicleInfo, idx: number) => {
        if (!vehicle.simulationIsReady && vehicle.isVehiclePaid) {
            simulationsToRequest.push(vehicle.vehicleId);
        }
    });
    return simulationsToRequest;
}