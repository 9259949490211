import { Dispatch, SetStateAction } from "react";

import { useTranslation } from "react-i18next";
import ModalWrapper from "../../../../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../../../../electrify_frontend_common/components/ModalWrapper/Headers";
import { Button } from "../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../electrify_frontend_common/components/Buttonv2/types";


export function CancelConfirmation({
  isOpen,
  setIsOpen,
  closeOnboarding,
}: {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  closeOnboarding: () => void;
}) {
  const { t } = useTranslation("driverOnboarding");

  return (
    <ModalWrapper
      isOpen={isOpen}
      close={() => setIsOpen(false)}
      className="w-[1000px]"
    >
      <>
        <ModalHeader
          title={t("cancel-confirmation.title")}
          onCancel={() => setIsOpen(false)}
        />

        <div className="p-10">
          <div>{t("cancel-confirmation.message")}</div>
          <div className="flex mt-10">
            <Button
              className="mr-2.5"
              variant={ButtonVariant.PRIMARY}
              size={ButtonSize.LARGE}
              onClick={() => {
                setIsOpen(false);
              }}
            >
              <div>{t("cancel-confirmation.button-text-continue")}</div>
            </Button>
            <Button
              variant={ButtonVariant.SECONDRY}
              size={ButtonSize.LARGE}
              onClick={() => {
                setIsOpen(false);
                closeOnboarding();
              }}
            >
              <div>{t("cancel-confirmation.button-text-leave")}</div>
            </Button>
          </div>
        </div>
      </>
    </ModalWrapper>
  );
}
