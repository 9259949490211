import { useContext, useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";

import { Welcome } from "./steps/Welcome";
import { ElectrificationPlannerChargingSectionSubSections, ElectrificationPlannerSections, ElectrificationPlannerStepStatus, ElectrificationPlannerVehicleSectionSubSections } from "../../models/Section";
import { QuestionStep } from "./steps/QuestionStep";
import { PercentageProgressBar } from "../../components/ElectrificationProgress";
import { DeliveryDateStep } from "./steps/DeliveryDateStep";
import { Congratulations } from "./steps/Congratulations";
import { ElectrifyPlannerContext } from "../../../../contexts/ElectrificationPlannerContext";
import { IElectrificationPlanner } from "../../../../../../@types/driver/electrificationPlanner";
import ModalWrapper from "../../../../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../../../../electrify_frontend_common/components/ModalWrapper/Headers";
import { CancelConfirmation } from "../../../../Electrify/pages/OnboardingPage/utils/CancelConfirmation";




export default function OnboardingModal({
  isOpen,
  startingStep,
  onCancel,
}: {
  isOpen: boolean;
  startingStep: number;
  onCancel: () => void;
}) {
  const { t, i18n } = useTranslation("electrificationPlanner");

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [step, setStep] = useState(startingStep ? startingStep : 0);
  const { electrificationPlanner, refetch } = useContext(ElectrifyPlannerContext);


  const nextStep = () => {
    setStep((current) => current + 1);
  };

  const previousStep = () => {
    setStep((current) => current - 1);
  };


  const steps = useMemo(() => {
    const allSteps = [
      {
        component: (
          <Welcome
            close={() => setConfirmationOpen(true)}
            nextStep={() => nextStep()}
          />
        ),
        onBack: () => null,
      },
      {
        component: (
          <QuestionStep
            step={step}
            nextStep={(data?: IElectrificationPlanner) => {
              refetch();
              if (data && data[ElectrificationPlannerSections.CHARGING_SECTION][ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING].parkingSituation.data === "streetParkNeeded") {
                setStep(4)
                return;
              }
              nextStep();
            }}
            nextStepToStore={(answer: string) => {
              if (answer === "streetParkNeeded") {
                return 4;
              }
              return step + 1;
            }}
            prevStep={() => previousStep()}
            stepName={"parkingSituation"}
            section={ElectrificationPlannerSections.CHARGING_SECTION}
            subsection={ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING}
          />
        )
      },
      {
        component: (
          <QuestionStep
            step={step}
            nextStep={(data?: IElectrificationPlanner) => {
              refetch();
              if (data && data[ElectrificationPlannerSections.CHARGING_SECTION][ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING].chargingSituation.status === ElectrificationPlannerStepStatus.NOT_APPLICABLE) {
                setStep(4)
                return;
              }
              nextStep();
            }}
            nextStepToStore={(answer: string) => {
              if (answer === "dontWantToInstall" || answer === "notAnOption") {
                return 4;
              }
              return step + 1;
            }}
            prevStep={() => previousStep()}
            stepName={"chargingSituation"}
            section={ElectrificationPlannerSections.CHARGING_SECTION}
            subsection={ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING}
          />
        )
      },
      {
        component: (
          <QuestionStep
            step={step}
            nextStep={() => nextStep()}
            prevStep={() => previousStep()}
            stepName={"employerSituation"}
            section={ElectrificationPlannerSections.CHARGING_SECTION}
            subsection={ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING}
          />
        )
      },
      {
        component: (
          <QuestionStep
            step={step}
            nextStep={() => nextStep()}
            prevStep={() => {
              if (electrificationPlanner && electrificationPlanner[ElectrificationPlannerSections.CHARGING_SECTION] && electrificationPlanner[ElectrificationPlannerSections.CHARGING_SECTION][ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING]?.parkingSituation.data === "streetParkNeeded") {
                setStep(1)
                return;
              } else if (electrificationPlanner && electrificationPlanner[ElectrificationPlannerSections.CHARGING_SECTION] && electrificationPlanner[ElectrificationPlannerSections.CHARGING_SECTION][ElectrificationPlannerChargingSectionSubSections.HOME_CHARGING]?.chargingSituation.status === ElectrificationPlannerStepStatus.NOT_APPLICABLE) {
                setStep(2)
                return;
              }
              previousStep();
            }}
            stepName={"chargingAvailability"}
            section={ElectrificationPlannerSections.CHARGING_SECTION}
            subsection={ElectrificationPlannerChargingSectionSubSections.WORKPLACE_CHARGING}
          />
        )
      },
      {
        component: (
          <DeliveryDateStep
            step={step}
            nextStep={() => nextStep()}
            prevStep={() => previousStep()}
          />
        )
      },
      {
        component: (
          <QuestionStep
            step={step}
            nextStep={() => nextStep()}
            prevStep={() => previousStep()}
            stepName={"ownershipType"}
            section={ElectrificationPlannerSections.VEHICLE_SECTION}
            subsection={ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION}
          />
        )
      },
      {
        component: (
          <QuestionStep
            step={step}
            nextStep={() => nextStep()}
            prevStep={() => previousStep()}
            stepName={"financingType"}
            section={ElectrificationPlannerSections.VEHICLE_SECTION}
            subsection={ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION}
          />
        )
      },
      {
        component: (
          <QuestionStep
            step={step}
            nextStep={() => nextStep()}
            prevStep={() => previousStep()}
            stepName={"vehicleAge"}
            section={ElectrificationPlannerSections.VEHICLE_SECTION}
            subsection={ElectrificationPlannerVehicleSectionSubSections.PREFERENCES_SUB_SECTION}
          />
        )
      },
      {
        component: <Congratulations
        />

      }

    ];
    return allSteps;
  }, [step, electrificationPlanner]);

  return (
    <>
      <ModalWrapper
        isOpen={isOpen}
        close={() => setConfirmationOpen(true)}
        className={"w-fit"}
      >
        <>
          {step == 0 || step === steps?.length - 1 ? null : (
            <>
              <ModalHeader
                title={t("onboarding.title")}
                message={""}
                // onBack={steps[step].onBack}
                onCancel={() => setConfirmationOpen(true)}
              />

            </>
          )}

          <div className="overflow-y-auto overflow-x-hidden h-full">
            <CancelConfirmation
              isOpen={confirmationOpen}
              setIsOpen={setConfirmationOpen}
              closeOnboarding={onCancel}
            />

            {steps[step].component}
          </div>
          {step == 0 || step === steps?.length - 1 ? null : <PercentageProgressBar height={2} percentage={Math.round(((step ) / (steps?.length - 2)) * 100)} />}
        </>
      </ModalWrapper>
    </>
  );
}
