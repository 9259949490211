import { useCallback } from "react";
import { FiltersOptions } from "../../../types";

export function OptionsButton({
  value,
  onChange,
  options,
  backgroundColor,
  multipleOptions,
}: {
  value: any;
  onChange: (value: any) => void;
  options: FiltersOptions[];
  backgroundColor: "dark" | "light";
  multipleOptions?: boolean;
}) {

  const colorScheme = useCallback((optionSelected: boolean) => {
    if (backgroundColor === "dark") {
      return optionSelected ? "bg-white text-black" : ""
    } else {
      return optionSelected ? "bg-Blueberry-dark-default text-white" : "text-black"
    }

  }, [backgroundColor])


  const handleCharge = (option: FiltersOptions) => {
    if (multipleOptions) {
      if(value?.includes(option.value)) {
        onChange(value?.filter((val: any) => val !== option.value));
      } else {
        if(value) return onChange([...value, option.value]);
        onChange([option.value]);
      }
    } else {
      if (option.value === value) {
        onChange(null);
      } else {
        onChange(option.value);
      }
    }

  }

  return (
    <div className="flex w-full text-xs">
      {options.map((option: FiltersOptions, idx: number) => {

        const optionSelected = multipleOptions ? value?.includes(option.value) : value === option.value;

        return (
          <div
            key={idx}
            className={`flex w-full h-12 px-2 items-center justify-center border text-center
             cursor-pointer 
             ${backgroundColor === "dark" ? "" : " border-Grey-default"}
            ${idx == 0 ? "rounded-l" : ""}
            ${idx == options.length - 1 ? "rounded-r " : ""}
            ${idx < options.length - 1 ? "border-r-0" : ""}
            ${colorScheme(optionSelected)}
            `}
            onClick={() => {
              handleCharge(option);
            }}
          >
            {option.label}
          </div>)
      })}
    </div>
  );
}
