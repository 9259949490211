import React, { ReactElement } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowLeft,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import SortButton from "./SortButton";
import ModalWrapper from "../../../electrify_frontend_common/components/ModalWrapper";

const Header = ({ title, onCancel, sortFunction, isCrescent }: { title: string, onCancel: () => void, sortFunction: () => void, isCrescent?: boolean }) => (
  <div
    className="p-10 flex justify-between h-28"
    style={{ boxShadow: "0px 4px 24px 0px #00000014" }}
  >
    <h1 className="text-xl text-Blueberry-dark-default">{title}</h1>
    <div className="flex items-center">
      {sortFunction && (
        <SortButton
          sort={sortFunction}
          hideText={true}
          isCrescent={isCrescent}
        />
      )}
      <div className="ml-10">
        <FontAwesomeIcon
          className="cursor-pointer"
          icon={faTimes}
          onClick={onCancel}
        />
      </div>
    </div>
  </div>
);

const Footer = ({
  currentPage,
  numberOfPages,
  incrementPage,
  decrementPage,
  previousChartName,
  nextChartName,
}: {
  currentPage: number,
  numberOfPages: number,
  incrementPage: () => void,
  decrementPage: () => void,
  previousChartName?: string,
  nextChartName?: string
}) => {
  const { t } = useTranslation("chartWrapper");

  return (
    <div>
      <div className="flex justify-between h-24">
        {currentPage > 0 ? (
          <div
            className="flex flex-row mx-6 my-5 cursor-pointer"
            onClick={decrementPage}
          >
            <div className="mr-4">
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
            <div className="text-right">
              <p className="text-lg">{t("previous-chart")}</p>
              <p className="text-xs">{previousChartName}</p>
            </div>
          </div>
        ) : (
          <div></div>
        )}

        {currentPage < numberOfPages - 1 ? (
          <div
            className="flex flex-row mx-6 my-5 cursor-pointer"
            onClick={incrementPage}
          >
            <div className="text-right">
              <p className="text-lg">{t("next-chart")}</p>
              <p className="text-xs">{nextChartName}</p>
            </div>
            <div className="ml-4">
              <FontAwesomeIcon icon={faArrowRight} size="sm" />
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default function ChartModal({
  title,
  onCancel,
  open,
  chart,
  currentPage,
  numberOfPages,
  incrementPage,
  decrementPage,
  previousChartName,
  nextChartName,
  sortFunction,
  isCrescent,
}: {
  title?: string,
  onCancel: () => void,
  open: boolean,
  chart?: ReactElement,
  currentPage: number,
  numberOfPages: number,
  incrementPage: () => void,
  decrementPage: () => void,
  previousChartName?: string,
  nextChartName?: string,
  sortFunction: () => void,
  isCrescent?: boolean
}) {
  if (!open) return <div></div>;
  return (
    <ModalWrapper
      className="h-full w-8/12"
      outterClassName="pb-2"
      isOpen={open}
      close={onCancel}
    >
      <>
        <Header
          title={title || ""}
          onCancel={onCancel}
          sortFunction={sortFunction}
          isCrescent={isCrescent}
        />

        <div className="px-10 pt-10 pb-5 w-full flex-1 min-h-min overflow-auto">
          {chart}
        </div>

        <Footer
          currentPage={currentPage}
          numberOfPages={numberOfPages}
          incrementPage={incrementPage}
          decrementPage={decrementPage}
          previousChartName={previousChartName}
          nextChartName={nextChartName}
        />
      </>
    </ModalWrapper>
  );
}
