import axios from "axios";
import { IElectrificationPlanner } from "../../../@types/driver/electrificationPlanner";
import { SelectionType } from "../../../products/B2C/ElectrificationPlanner/CheckList/components/Sections/utils";
import { ElectrificationPlannerStepStatus, ElectrificationPlannerStepType } from "../../../products/B2C/ElectrificationPlanner/CheckList/models/Section";
import { IFilters } from "../../../electrify_frontend_common/types";



export const resetElectrificationPlanner = async () => {

    return await axios.put(`${process.env.REACT_APP_BACKEND_URL}/electrification-planner/reset`);

}

export const completeElectrificationPlannerOnboarding = async () => {
    return await axios.put(`${process.env.REACT_APP_BACKEND_URL}/electrification-planner/onboard`);
}

export const updateDeliveryDate = async (input: { deliveryDate: string, updateDatesAccordingly: boolean, nextStep?: number }) => {
    return await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/electrification-planner/delivery-date`, {
        deliveryDate: input.deliveryDate,
        updateDatesAccordingly: input.updateDatesAccordingly,
        nextStep: input.nextStep
    });
}

export const toggleFavoriteStateForVehicle = async (evId: string) => {
    return (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/electrification-planner/favorite-ev`, {
        evId
    })).data;

}

export const getQuestionStructure = async (section: string, subsection: string, step: string, onboarding?: boolean): Promise<{
    selectionType: SelectionType,
    options: { text: string, effect: ElectrificationPlannerStepStatus }[],
    notApplicableExplanationOptions?: { text: string }[],
    question: string,
    type: ElectrificationPlannerStepType,
    data: string | string[] | null,
    dueDate?: string,
    additionalInfo: {
        type: 'guideline' | 'recommendation',
        options: string[]
    }
}> => {
    let requestString = `${process.env.REACT_APP_BACKEND_URL}/electrification-planner/${section}/${subsection}/${step}`;
    if (onboarding) {
        requestString += `?onboarding=true`;
    }
    return (await axios.get(requestString)).data;

}

export const getElectrificationPlanner = async (): Promise<IElectrificationPlanner> => {
    return (await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/electrification-planner`
    )).data;
};


export const updateElectrificationPlanner = async (input: { section: string, subsection: string, data: any, onboardingStep?: number }): Promise<IElectrificationPlanner> => {
    return (await axios.patch(
        `${process.env.REACT_APP_BACKEND_URL}/electrification-planner/${input.section}/${input.subsection}`,
        {
            ...input.data,
            onboardingStep: input.onboardingStep
        }
    )).data;
}

