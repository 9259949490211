import { FunctionComponent, SVGProps } from "react";
import { BatteryLevelChart } from "./BatteryLevelChart";
import { ReactComponent as InformationIcon } from "../../../../../../../assets/information-icon-blueberry.svg";

import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useScenarioLocalCompute } from "../hooks/useScenarioLocalCompute";
import { borderToRemove, rounded } from "../utils";
import { MetricCell } from "./MetricCell";
import { Scenario, VehicleConsumptionsCostsAndEmissions } from "../../../../../../../@types/driver/simulations";
import { useLayout } from "../../../../../../../hooks/state/useLayout";
import { Tooltip } from "../../../../../../../electrify_frontend_common/components/Tooltip";
import { DoughnutChart } from "../../../../../../B2B/Electrify/pages/Overview/ReportPanel/DoughnutChart";
import { defineColorScheme } from "../../../../../../../common/components/Charts/ChartColors";
import { ArrowButton } from "../../../../../../../electrify_frontend_common/components/ArrowButton";


export function ScenarioPanel({
    scenarioIdx,
    scenarioTitle,
    scenarioDescription,
    scenarioTooltip,
    Icon,
    position,
    scenarioData,
    navigationQuery,
    consumptionCostsAndEmissions,
    maxBatteryLevelPercentageAll,
    setMaxBatteryLevelPercentageAll,
}: {
    scenarioData?: Scenario;
    scenarioIdx: number;
    scenarioTitle: string;
    scenarioDescription: string;
    scenarioTooltip: string;
    Icon: FunctionComponent<SVGProps<SVGSVGElement> & { title?: string | undefined }>;
    position?: "FIRST" | "LAST";
    navigationQuery: string;
    consumptionCostsAndEmissions: VehicleConsumptionsCostsAndEmissions;
    maxBatteryLevelPercentageAll: number
    setMaxBatteryLevelPercentageAll: any
}) {
    const { t } = useTranslation("scenarios");
    const navigate = useNavigate();
    const { comparisonMetrics } = useScenarioLocalCompute({
        scenarioData,
        consumptionCostsAndEmissions,
    });

    const { currencySymbol } = useLayout();

    return (
        <div className={`flex flex-col h-full bg-Grey-background`}>
            <div
                className={`h-28 border flex flex-col items-center justify-between p-3 border-Grey-default border-b-0 ${borderToRemove(
                    position
                )} ${rounded(position, "TOP")}`}
            >
                <div className="text-Grey-dark font-bold">{t("scenario")} {scenarioIdx}</div>
                <div className="flex items-center text-lg text-Blueberry-dark-default">
                    <Icon className="mr-2" />{scenarioTitle}
                </div>
                <div className="flex items-center text-sm text-Grey-dark">
                    {scenarioDescription}
                    <Tooltip content={<div className="w-60">{scenarioTooltip}</div>} placement={position === "LAST" ? "left-top" : "right-top"}>
                        <InformationIcon className="ml-1" />
                    </Tooltip>
                </div>
            </div>
            <hr className="border-Grey-tint" />
            <MetricCell
                rawValues={scenarioData?.chargingSessions}
                position={position}
            />
            <hr className="border-Grey-tint" />
            <MetricCell
                rawValues={scenarioData?.chargingSessions?.extra}
                position={position}
            />
            <hr className="border-Grey-tint" />
            <MetricCell
                rawValues={scenarioData?.energyCosts}
                comparisonValue={comparisonMetrics?.energyCostsComparison}
                position={position}
                units={currencySymbol}
            />
            <hr className="border-Grey-tint" />
            <MetricCell
                rawValues={scenarioData?.energyCo2Emissions}
                comparisonValue={comparisonMetrics?.co2EmissionsComparison}
                position={position}
                units={"kg"}
            />
            <hr className="border-Grey-tint" />
            <div
                className={`flex flex-col h-44 py-6 px-12 items-center justify-center border-x border-b border-Grey-default ${borderToRemove(
                    position
                )} ${rounded(position, "BOTTOM")}`}
            >
                <BatteryLevelChart
                    tripsEndSoc={scenarioData?.tripsEndSoc}
                    maxBatteryLevelPercentageAll={maxBatteryLevelPercentageAll}
                    setMaxBatteryLevelPercentageAll={setMaxBatteryLevelPercentageAll}
                />
            </div>
            <hr className="border-Grey-tint" />
            <div
                className={`flex flex-col h-52 p-6 items-center justify-center border-x border-b border-Grey-default ${borderToRemove(
                    position
                )} ${rounded(position, "BOTTOM")}`}
            >
                <DoughnutChart
                    data={{
                        datasets: [
                            {
                                backgroundColor: defineColorScheme(3),
                                data: [
                                    scenarioData?.chargedEnergy?.home,
                                    scenarioData?.chargedEnergy?.workplace,
                                    (scenarioData?.chargedEnergy?.public || 0) + (scenarioData?.chargedEnergy?.extra || 0),
                                ],
                            },
                        ],
                        labels: [t('labels.home'), t('labels.workplace'), t('labels.public')],
                    } as any}
                />
                <ArrowButton
                    onClick={() => navigate(`/simulation/trips?${navigationQuery}`)}
                    text={t("arrow-button-text")}
                    className="mt-5"
                />
            </div>
        </div>
    );
}
