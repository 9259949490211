import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { defineColorScheme } from "../../../../../../../../common/components/Charts/ChartColors";

const keys = ['weekdays', 'weekends']

export function DistanceShareChart({ data }: { data: any }) {
    const { t } = useTranslation("driverDrivingAnalysis");
    const dataToUse = keys.map(key => data[key])

    const totalSum: number = dataToUse.reduce(
        (c: any, v: any) => c + v,
        0
    ) as number;

    const graphData = {
        labels: keys.map(key => t(`distance-share.${key}`)),
        datasets: [
            {
                backgroundColor: defineColorScheme(2),
                data: dataToUse,
            },
        ],
    };

    return (
        <div className="flex flex-col h-full">
            {data ? (
                <Doughnut
                    options={{
                        cutout: '50%',
                        plugins: {
                            tooltip: {
                                callbacks: {
                                    label: function (tooltipItem) {
                                        return `${Math.round((tooltipItem.raw as number / totalSum) * 100)}%`;
                                    },
                                },
                            },
                        },
                    }}
                    data={graphData}
                />
            ) : null}
        </div>
    );
}
