import { Form, Formik } from "formik";
import { ElectrificationPlannerStepModalStructure } from "../../utils";
import { ElectrificationPlannerChargingSectionSubSections, ElectrificationPlannerSections, ElectrificationPlannerSubSections } from "../../../../models/Section";

import { UseMutationResult, useMutation, useQuery } from "react-query";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModalForm, RadioButton } from "../../utils/forms";
import { IElectrificationPlanner } from "../../../../../../../../@types/driver/electrificationPlanner";
import { getQuestionStructure, updateElectrificationPlanner } from "../../../../../../../../services/rest/electrificationPlanner";
import { Button } from "../../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ElectrifyPlannerContext } from "../../../../../../contexts/ElectrificationPlannerContext";
import ModalWrapper from "../../../../../../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../../../../../../electrify_frontend_common/components/ModalWrapper/Headers";




export function ChargingSituationNotApplicableJustification({
    submitMutation,
    formValues,
    sectionName,
    subsectionName,
    stepName,
    explainParent,
}: {
    submitMutation: UseMutationResult<IElectrificationPlanner, unknown, any, unknown>,
    formValues: {
        answer: {
            vehicleOrder: string[]
        },
        dueDate?: string
    },
    sectionName: ElectrificationPlannerSections,
    subsectionName: ElectrificationPlannerSubSections,
    stepName: string,
    explainParent?: {
        sectionName: ElectrificationPlannerSections,
        subsectionName: ElectrificationPlannerSubSections,
        stepName: string
    },
}) {

    const { t } = useTranslation("electrificationPlanner")

    const { data, isLoading } = useQuery<any>(['explanation', stepName], () => getQuestionStructure(
        explainParent ? explainParent.sectionName : sectionName,
        explainParent ? explainParent.subsectionName : subsectionName,
        explainParent ? explainParent.stepName : stepName
    ), {
        cacheTime: 0
    });


    if (isLoading) return null;


    return (
        <ElectrificationPlannerStepModalStructure
            leftSide={<Formik

                initialValues={{
                    notApplicableExplanation: data?.explanation || ""
                }}
                onSubmit={(values) => {
                    submitMutation.mutate({
                        section: sectionName,
                        subsection: subsectionName,
                        data: {
                            answer: formValues.answer,
                            dueDate: formValues.dueDate,
                            additionalValues: {
                                ...values,

                            }
                        }
                    });
                }}
            >
                {({ setFieldValue }) => (
                    <Form className="flex flex-col">
                        <div className="mb-4 text-lg">{t(`chargingSection.subsections.${subsectionName}.justificationModal.question`)}</div>
                        {
                            data?.notApplicableExplanationOptions?.map((value: { text: string }) => (
                                <div onClick={() => setFieldValue("notApplicableExplanation", value.text)} key={value.text} className="flex w-full items-center justify-between">
                                    <RadioButton
                                        key={value.text}
                                        name={"notApplicableExplanation"}
                                        label={t(`${explainParent ? explainParent.sectionName : sectionName}.subsections.${explainParent ? explainParent.subsectionName : subsectionName}.steps.${explainParent ? explainParent.stepName : stepName}.notApplicableExplanationOptions.${value.text}`)}
                                        value={value.text} />
                                </div>
                            ))
                        }
                        <div className="flex w-full justify-start mt-12">
                            <Button className="mr-8" type="submit" onClick={() => null}>{t("misc.notApplicableModal.confirm")}</Button>
                        </div>

                    </Form>)}
            </Formik>}
            rightSide={null}
        />

    )



}


export function NotApplicableWithExplanationModalContent({
    sectionName,
    subsectionName,
    explainParent,
    stepName,
    closeModal,
}: {
    sectionName: ElectrificationPlannerSections,
    subsectionName: ElectrificationPlannerChargingSectionSubSections,
    explainParent?: {
        sectionName: ElectrificationPlannerSections,
        subsectionName: ElectrificationPlannerSubSections,
        stepName: string
    }, // To specify for options fetching when explanation must be given on child modal
    stepName: string,
    closeModal: () => void,
}) {

    const { t } = useTranslation("electrificationPlanner");

    const { refetch } = useContext(ElectrifyPlannerContext);
    const [refinementModalOpen, setRefinementModalOpen] = useState(false);
    const [formValues, setFormValues] = useState<any>(null);

    const mutation = useMutation(async (input: any) => {
        return await updateElectrificationPlanner(input)
    }, {
        onSuccess: () => {
            refetch();
            closeModal();
        }
    })


    return (
        <>
            <ModalWrapper className="w-8/12" close={() => setRefinementModalOpen(false)} isOpen={refinementModalOpen}>
                <>
                    <ModalHeader onCancel={() => setRefinementModalOpen(false)} title={t(`chargingSection.subsections.${subsectionName}.justificationModal.title`)} />
                    <ChargingSituationNotApplicableJustification
                        explainParent={explainParent}
                        stepName={stepName}
                        sectionName={sectionName}
                        subsectionName={subsectionName}
                        submitMutation={mutation}
                        formValues={formValues}
                    />
                </>
            </ModalWrapper>
            <ModalForm
                closeModal={closeModal}
                section={sectionName}
                subsection={subsectionName}
                stepName={stepName}
                alternativeAction={(values) => {
                    if (values[stepName] === "notApplicable") {
                        setFormValues({
                            answer: {
                                ...values,
                            },
                            dueDate: values.dueDate
                        });
                        setRefinementModalOpen(true);
                        return;
                    }
                    mutation.mutate({
                        section: sectionName,
                        subsection: subsectionName,
                        data: {
                            answer: {
                                ...values
                            },
                            dueDate: values.dueDate,

                        }
                    });
                }}
            />
        </>
    )


}