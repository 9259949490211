import { useTranslation } from "react-i18next";
import Input from "../../../../../../../../common/components/misc/Input";
import { CountrySelector } from "../../../../../../../../common/components/CountrySelector";


const LabeledInput = ({ label, type, className, onChange, value, error, errorMessage }: { label: string, type?: string, className?: string, onChange: any, value: any, error?: boolean, errorMessage: string }) => {

    return (
        <div className={`mb-2 text-Grey-dark ${className}`}>
            <label>{label}</label>
            <Input onChange={onChange} type={type ? type : 'text'} value={value} error={error ? true : false} />
            {error ?
                <div className="text-red-500 text-sm">{errorMessage}</div>
                : null}
        </div>
    )
}

export function AddressForm({ value, onChange, errors, resetErrors }:
    { value?: any, onChange: any, errors: { name: boolean, street: boolean, streetNr: boolean, zip: boolean, country?: boolean, city: boolean }, resetErrors: any }) {

    const { t } = useTranslation("driverPaymentSystem");
    const errorMessage = t("address-form.required-field")


    return (
        <>
            <LabeledInput
                onChange={(e: any) => onChange((v: any) => {
                    if (e.target.value) resetErrors('name');
                    return {
                        ...v, name: e.target.value
                    }
                }
                )}
                label={t("address-form.full-name")}
                value={value?.name}
                error={errors.name}
                errorMessage={errorMessage}
            />

            <LabeledInput
                onChange={(e: any) => onChange((v: any) => {
                    if (e.target.value) {
                        resetErrors('street')
                    }
                    return {
                        ...v,
                        address: {
                            ...v.address,
                            street: e.target.value,
                        }
                    }
                })}
                label={t("address-form.address")}
                value={value?.address?.street}
                error={errors.street}
                errorMessage={errorMessage}
            />
            <LabeledInput
                onChange={(e: any) => onChange((v: any) => {
                    if (e.target.value) resetErrors('streetNr')
                    return {
                        ...v,
                        address: {
                            ...v.address,
                            streetNr: e.target.value,
                        },
                    }
                })}
                label={t("address-form.house-number")}
                value={value?.address?.streetNr}
                error={errors.streetNr}
                errorMessage={errorMessage}
            />
            <LabeledInput
                onChange={(e: any) => onChange((v: any) => {
                    return {
                        ...v,
                        address: {
                            ...v.address,
                            others: e.target.value,
                        },
                    }
                })}
                label={t("address-form.others")}
                value={value?.address?.others}
                errorMessage={errorMessage}
            />
            <LabeledInput
                onChange={(e: any) => onChange((v: any) => {
                    if (e.target.value) resetErrors('zip')
                    return {
                        ...v,
                        address: {
                            ...v.address,
                            zip: e.target.value
                        },
                    }
                })}
                label={t("address-form.postal-code")}
                value={value?.address?.zip}
                error={errors.zip}
                errorMessage={errorMessage}
            />

            <LabeledInput
                onChange={(e: any) => onChange((v: any) => {
                    if (e.target.value) resetErrors('city');
                    return {
                        ...v,
                        address: {
                            ...v.address,
                            city: e.target.value,
                        },
                    }
                })}
                label={t("address-form.city")}
                value={value?.address?.city}
                error={errors.city}
                errorMessage={errorMessage}
            />


            <div className="mb-2">
                <CountrySelector
                    handleChange={(e: any) => onChange((v: any) => {
                        if (!e) return v;
                        resetErrors('country')
                        return {
                            ...v,
                            address: {
                                ...v.address,
                                country: e.value
                            },
                        }
                    })}
                    countryList={[{ key: 'Germany', value: 'DE' }]}
                    // { key: 'United kingdom', value: 'GB' } // TODO to be added in the future
                    selectedCountry={value?.address?.country}
                    error={errors.country}
                />
                {errors.country ? <div className="text-sm text-red-500">
                    {errorMessage}
                </div> : null}
            </div>
        </>
    )


}