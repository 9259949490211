import { useTranslation } from "react-i18next";
import { BillingForm } from "../BillingForm";
import ModalWrapper from "../../../electrify_frontend_common/components/ModalWrapper";
import { ModalHeader } from "../../../electrify_frontend_common/components/ModalWrapper/Headers";

export default function BillingInfoModal({ isOpen, onUpdate, onCancel }: { isOpen: boolean; onUpdate: () => void, onCancel: () => void }) {
  const { t } = useTranslation("settingsBilling");

  return (
    <ModalWrapper
      isOpen={isOpen}
      close={onCancel}
      className="w-2/3">
      <>
        <ModalHeader
          title={t("modal-title")}
          message={t("modal-message")}
          onCancel={onCancel}
          isLastStep={true}
          onBack={() => console.log("back")}
        />
        <div className="h-auto overflow-auto">
          <BillingForm onUpdateAction={onUpdate} buttonText={"Confirm"} />
        </div>
      </>
    </ModalWrapper>
  );
}
