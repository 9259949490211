import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import AverageDistanceGraph from "./AverageDistance/AverageDistanceChart";
import BusinessPrivateGraph from "./BusinessPrivate/BusinessPrivateChart";
import DistanceSegmentGraph from "./DistanceSegments/DistanceSegmentChart";
import DrivingParkingGraph from "./DrivingParking/DrivingParkingChart";
import LongestDistanceGraph from "./LongestDistance/LongestDistanceChart";
import YearlyMileageGraph from "./YearlyMileage/YearlyMileageChart";
import ChartModal from "../../../../../../../common/components/Charts/ChartModal";

export function ExpandedDrivingAnalysis({
  expandedMode,
  graphData,
  closeExpanded,
  incrementPage,
  decrementPage,
  numberOfPages,
  sortFunctions,
  sortStates,
} : {
  expandedMode: {open: boolean, page: number},
  graphData: any,
  closeExpanded: ()=> void,
  incrementPage: ()=> void,
  decrementPage: ()=> void,
  numberOfPages: number,
  sortFunctions: any,
  sortStates: any
}) {
  const { t } = useTranslation("drivingAnalysis");

  const [page, setPage] = useState<number | null>(null);

  useEffect(() => {
    setPage(expandedMode?.page);
  }, [expandedMode]);

  const buildExpandedViewContent = (customPage?: number) => {
    const caseVar = customPage ?? page;
    switch (caseVar) {
      case 0:
        return {
          getTitle: () => t("yearly-mileage-title"),
          getChart: () => (
            <YearlyMileageGraph
              data={graphData?.dataSets?.yearlyMileageDataset}
              isModal
            />
          ),
          getSortFunction: () => sortFunctions.yearlyMileage,
          getIsCrescent: () => sortStates.yearlyMileage,
        };
      case 1:
        return {
          getTitle: () => t("average-distance-title"),
          getChart: () => (
            <AverageDistanceGraph
              data={{
                day: graphData?.dataSets?.averageDistancePerDayDatasets,
                trip: graphData?.dataSets?.averageDistancePerTripDatasets,
              }}
              isModal
            />
          ),
          getSortFunction: () => sortFunctions.averageDistance,
          getIsCrescent: () => sortStates.averageDistance,
        };
      case 2:
        return {
          getTitle: () => t("longest-distance-title"),
          getChart: () => (
            <LongestDistanceGraph
              data={{
                day: graphData?.dataSets?.longestDistancesPerDayDatasets,
                trip: graphData?.dataSets?.longestDistancesPerTripDatasets,
              }}
              isModal
            />
          ),
          getSortFunction: () => sortFunctions.longestDistance,
          getIsCrescent: () => sortStates.longestDistance,
        };
      case 3:
        return {
          getTitle: () => t("distance-segments-title"),
          getChart: () => (
            <DistanceSegmentGraph
              data={{
                day: graphData?.dataSets?.distanceSegmentsPerDayDatasets,
                trip: graphData?.dataSets?.distanceSegmentsPerTripDatasets,
              }}
              isModal
            />
          ),
          getSortFunction: () => sortFunctions.distanceSegment,
          getIsCrescent: () => sortStates.distanceSegment,
        };
      case 4:
        return {
          getTitle: () => t("business-private-title"),
          getChart: () => (
            <BusinessPrivateGraph
              data={{
                day: graphData?.dataSets?.businessPrivateDistanceDatasets,
                trip: graphData?.dataSets?.businessPrivatePerTripDatasets,
              }}
              isModal
            />
          ),
          getSortFunction: () => sortFunctions.businessPrivate,
          getIsCrescent: () => sortStates.businessPrivate,
        };
      case 5:
        return {
          getTitle: () => t("driving-parking-title"),
          getChart: () => (
            <DrivingParkingGraph
              data={graphData?.dataSets?.drivingParkingDataset}
              isModal
            />
          ),
          getSortFunction: () => sortFunctions.drivingParking,
          getIsCrescent: () => sortStates.drivingParking,
        };
    }
  };

  return (
    <>
      {page !== null ? (
        <ChartModal
          title={buildExpandedViewContent()?.getTitle()}
          open={expandedMode.open}
          onCancel={() => {
            closeExpanded();
            setPage(null);
          }}
          chart={buildExpandedViewContent()?.getChart()}
          incrementPage={incrementPage}
          decrementPage={decrementPage}
          numberOfPages={numberOfPages}
          previousChartName={buildExpandedViewContent(page - 1)?.getTitle()}
          nextChartName={buildExpandedViewContent(page + 1)?.getTitle()}
          currentPage={page}
          sortFunction={buildExpandedViewContent()?.getSortFunction()}
          isCrescent={buildExpandedViewContent()?.getIsCrescent()}
        />
      ) : null}
    </>
  );
}
