


export function ArrowRightIcon({color = "black", className} : {color?: string, className?: string}) {



    return (
        <svg className={className} width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.25 7.5L4.75 4L1.25 0.499998" stroke={color} strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    )

}