import { useEffect, useRef } from "react";
import SelectedVehicle from "./SelectedVehicle";
import { DriverVehicleAlternatives } from "./DriverVehicleAlternatives";
import { useLocation } from "react-router-dom";
import { SimulationVehicleInfo } from "../../../../../../../@types";
import { Spinner } from "../../../../../../../electrify_frontend_common/components/Spinner";




export function SelectionWrapper({ vehicleLoading, vehicle }: { vehicleLoading: boolean, vehicle: SimulationVehicleInfo }) {

    const location = useLocation();
    const search = location?.search?.split('?')[1];

    const ref = useRef<any>(null);

    useEffect(() => {
        if (search === 'change-vehicle')
            ref.current?.scrollIntoView({ behavior: "smooth" })
    }, [search])

    return (
        <>
            {vehicleLoading || !vehicle ? <Spinner /> :
                <>
                    <SelectedVehicle
                        key={`${vehicle?.vehicleId}`}
                        vehicle={vehicle}
                        onClickAlternatives={() => ref.current?.scrollIntoView({ behavior: "smooth" })}
                    />
                    <div ref={ref}>
                        <DriverVehicleAlternatives
                            vehicle={vehicle}
                        />
                    </div>
                </>
            }
        </>
    )


}