import { useTranslation } from "react-i18next";
import { DataUploadHelper } from "./DataUploadHelper";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../../../../../../../hooks/state/useUserData";
import { SyncStep } from "../../../../../../../@types";
import { FileUploader } from "../../../../../../../common/components/FileUploader";
import { Button } from "../../../../../../../electrify_frontend_common/components/Buttonv2";
import { ButtonSize, ButtonVariant } from "../../../../../../../electrify_frontend_common/components/Buttonv2/types";


export function FleetManualDataUpload({close}: {close: () => void}) {

    const { t } = useTranslation("dataProviderUpload");
    const navigate  = useNavigate();
    const {refreshUserAccountInfo} = useUserData();

    const done = async () => {
        await refreshUserAccountInfo();
        close();
        navigate("/");
    }

    const steps: SyncStep[] = [
        {
            name: "Step 1",
            content: (
                <div>
                    {t("manual-upload.helper-step-1.part-1")}{" "}
                    <a
                        href={t("manual-upload.helper-step-1.part-2-url")}
                        target="_blank"
                        className="underline text-Blueberry-dark-default"
                    >
                        {t("manual-upload.helper-step-1.part-2-text")}
                    </a>{" "}
                    {t("manual-upload.helper-step-1.part-3")}
                </div>
            ),
        },
        {
            name: "Step 2",
            content: (<>
                <div>{t("manual-upload.helper-step-2")}</div>
                <div className="mt-4">{t("manual-upload.helper-step-2-note")}</div>
            </>),
        },
        {
            name: "Step 3",
            content: <div>{t("manual-upload.helper-step-3")}</div>,
        },
    ];

    return (
        <div>
            <div className="mb-10">
                <DataUploadHelper text={t("manual-upload.helper-title")} steps={steps} />
            </div>
            <div className="px-10 pb-5">
                <FileUploader
                    successElement={<div className="flex w-full items-center justify-end mt-4">
                        <Button
                            variant={ButtonVariant.PRIMARY}
                            size={ButtonSize.LARGE}
                            onClick={() => {
                                done();
                            }}>
                            Done
                        </Button>
                    </div>}
                />
            </div>

        </div>
    )

}