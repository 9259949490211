import { useTripSimulationLayout } from "./useTripSimulationLayout";
import { useTripSimulationData } from "./useTripSimulationData";
import { useTripSimulationEffects } from "./useTripSimulationEffects";
import {
  TripSimulationPreferencesState,
  useTripSimulationPreferencesState,
} from "./useTripSimulationPreferencesState";
import { TripSimulationDetail } from "../../../../../../../@types/driver/simulations";

export function useTripSimulation(): {
  tripSimulationPreferencesState: TripSimulationPreferencesState;
  tripSimulationData: TripSimulationDetail[] | undefined;
} {
  useTripSimulationLayout();

  const simulationPreferencesState = useTripSimulationPreferencesState();

  const { tripSimulationData, fetchTripsSimulationDetails } =
    useTripSimulationData(simulationPreferencesState);

  useTripSimulationEffects({
    includeExtraStops: simulationPreferencesState?.includeExtraStops,
    selectedOptimization: simulationPreferencesState?.selectedOptimization,
    fetchTripsSimulationDetails,
  });

  return {
    tripSimulationPreferencesState: {
      ...simulationPreferencesState,
    },
    tripSimulationData,
  };
}
