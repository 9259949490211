import { TableRow, TableCell, createTheme, ThemeProvider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Battery, LocationIcon, TooltipWrappedContent } from "../../components";
import { ReactComponent as WarningIcon } from "../../../../../../../../../assets/warning.svg";
import { TripSimulationDetail } from "../../../../../../../../../@types/driver/simulations";
import { useLayout } from "../../../../../../../../../hooks/state/useLayout";

const theme = createTheme({
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "#bbb",
          },
        },
      },
    },
  },
});

export const TripRow = ({ trip }: { trip: TripSimulationDetail }) => {
  const { t } = useTranslation("tripSimulation");
  const { localeCode } = useLayout();

  const customStyle = trip?.fullyElectrified
    ? ""
    : "text-Blueberry-dark-default";

  return (
    <ThemeProvider theme={theme}>
      <TableRow
        className={`${trip.fullyElectrified ? "" : "bg-Blueberry-light-background"
          } `}
      >
        <TableCell align={"left"} padding={"normal"}>
          <Battery percentage={trip?.startSoc > 0 ? trip?.startSoc : 0} />
        </TableCell>
        <TableCell align={"left"} padding={"normal"}>
          {trip?.fullyElectrified ? (
            trip?.tripNumber || "-"
          ) : (
            <TooltipWrappedContent text={t("trip-not-possible")}>
              <WarningIcon />
            </TooltipWrappedContent>
          )}
        </TableCell>
        <TableCell align={"left"} padding={"normal"} className={customStyle}>
          {new Date(trip?.startDateTime || 0)?.toLocaleDateString(localeCode)}
        </TableCell>
        <TableCell align={"left"} padding={"normal"} className={customStyle}>
          {trip?.duration || "-"}
        </TableCell>
        <TableCell align={"left"} padding={"normal"} className={customStyle}>
          {trip?.distance?.toLocaleString(localeCode, { maximumFractionDigits: 2 }) || "-"}
        </TableCell>
        <TableCell align={"left"} padding={"normal"} className={customStyle}>
          {trip?.startAddress || "-"}
        </TableCell>
        <TableCell align={"left"} padding={"normal"} className={customStyle}>
          {trip?.endAddress || "-"}
        </TableCell>
        <TableCell align={"left"} padding={"normal"}>
          <LocationIcon locationType={trip?.locationType} />
        </TableCell>
        <TableCell align={"left"} padding={"normal"}>
          <Battery
            percentage={trip?.endSoc && trip?.endSoc > 0 ? trip?.endSoc : 0}
          />
        </TableCell>
      </TableRow>
    </ThemeProvider>
  );
};
