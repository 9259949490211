import { useEffect } from "react";


export function useOutsideAlerter(ref: any, action: any) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      
      function handleClickOutside(event: MouseEvent) {
        if (ref.current && !ref.current.contains(event.target)) {
          action && action();
        }
      }
      document.removeEventListener("mousedown", handleClickOutside);
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, action]);
  }
  
  export function useArrayOutsideClickDetector(refs: any[], callback: Function) {
    useEffect(() => {
      function handleClickOutside(event: Event) {
        let count = 0;
        for (const ref of refs) {
          if (ref.current && !ref.current.contains(event.target)) {
            count += 1;
          }
        }
        if (count == refs.length) callback();
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [refs]);
  }